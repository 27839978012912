import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, Checkbox, ErrorBag, ShortText, Space } from "design-system";
import Errors from "../Base/Form/Errors/Errors";
import { post } from "../../utils/api";
import axios, { AxiosError, AxiosResponse } from "axios";
import { INITIAL_RENDER_ONLY } from "../../utils/constants";
import { Logo50 } from "../Base/Logo/Logos";

import styles from "./Login.module.scss";

interface Props extends RouteComponentProps {}

const LoginContainer: React.FunctionComponent<Props> = () => {
	const emailInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const [errors, setErrors] = useState<Array<ErrorBag>>([]);

	useEffect(() => {
		if (!emailInputRef.current) return;

		emailInputRef.current.focus();
	}, INITIAL_RENDER_ONLY);

	function login() {
		post("login", { email, password, rememberMe })
			.then((response: AxiosResponse) => {
				setErrors([]);
				axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data.data.csrfToken;
				window.localStorage.setItem("account_zone", response.data.data.zone);
				window.location.replace("/jobs");
			})
			.catch((error: AxiosError) => {
				if (error.response === undefined) {
					setErrors([
						{
							type: "unknown",
							message:
								"An unexpected error has occurred, please contact us at support@prestavi.com.",
							key: "unknown",
						},
					]);

					return;
				}

				if ((error.response as AxiosResponse).status === 422) {
					if (!error.response) return;

					const errorsBag = error.response.data.errors.map((error: any) => ({
						field: error.key,
						message: error.message[0],
					}));

					setErrors(errorsBag);

					return;
				}

				if (
					(error.response as AxiosResponse).status === 401 ||
					(error.response as AxiosResponse).status === 429
				) {
					if (!error.response) return;

					setErrors([
						{
							key: error.response.data.errors[0].key,
							type: "server",
							message: error.response.data.errors[0].message,
						},
					]);

					return;
				}
			});
	}

	return (
		<div className={styles.LoginPage}>
			<div className={styles.LoginForm}>
				<div className={styles.Header}>{Logo50}</div>
				<Errors bag={errors} className={styles.ErrorMessage} />

				<div className={styles.Inputs}>
					<ShortText
						className={styles.EmailField}
						fullWidth={true}
						label="Email Address"
						theme="inside"
						id="email"
						value={email}
						onChange={(event) => setEmail((event.target as HTMLInputElement).value)}
						onEnterPressed={login}
						forwardRef={emailInputRef}
					/>
					<ShortText
						className={styles.PasswordField}
						fullWidth={true}
						label="Password"
						theme="inside"
						type="password"
						id="password"
						value={password}
						onEnterPressed={login}
						onChange={(event) => setPassword((event.target as HTMLInputElement).value)}
					/>
				</div>
				<div className={styles.Options}>
					<Checkbox
						id="rememberMe"
						isChecked={rememberMe}
						onChange={() => setRememberMe(!rememberMe)}
						label="Remember me"
					/>
					<div className={styles.ForgotPassword}>
						<span>
							<a href="/forgot-password">Forgot your password?</a>
						</span>
					</div>
				</div>
				<Button theme="solid" tone="medium" size="large" fullWidth={true} onClick={login}>
					Log In
				</Button>
			</div>

			<div className={styles.Signup}>
				<h2>Don't have an account yet?</h2>
				<Space amount={16} />

				<Button
					customColorMap={{ background: "#9CB7F7", border: "#9CB7F7", text: "#132859" }}
					onClick={() => window.location.replace("/signup")}
				>
					Get Started
				</Button>

				<div className={styles.Legal}>
					<p>
						© 2023 Prestavi, Inc. All rights reserved.&nbsp;
						{/*<a href="https://prestavi.com/privacy">Privacy</a> and&nbsp;*/}
						{/*<a href="https://prestavi.com/terms">terms.</a>*/}
					</p>
				</div>
			</div>
		</div>
	);
};

export default LoginContainer;
