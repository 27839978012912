import React from "react";
import { Integration, IntegrationEvent, JobAction, LogItem, Workflow } from "design-system";
import { useStore } from "react-redux";
import { InitialState } from "../../../../../initialState";
import ExternalEndpointDetails from "./Details/ExternalEndpointDetails";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const ActionCreated: React.FunctionComponent<Props> = ({ logItem }) => {
	const store = useStore();

	const jobAction = (store.getState() as InitialState).jobsActions[logItem.elementKey] as JobAction;
	const integration = (store.getState() as InitialState).integrations[
		jobAction.action.integrationKey
	] as Integration;

	function buildEventName() {
		if (integration.key === "external-endpoints") {
			return <ExternalEndpointDetails endpointKey={jobAction.action.eventKey} />;
		}

		return (
			<p>
				{
					(
						integration.events.find(
							(event) => event.key === jobAction.action.eventKey
						) as IntegrationEvent
					).name
				}
			</p>
		);
	}

	return (
		<React.Fragment>
			<div className={styles.Indicator}></div>
			<div className={styles.Event}>
				<span>Automation</span>
				<div>
					<img src={integration.logoUrl} width={20} />
					<p>{integration.name}</p>
				</div>
			</div>
			<div className={styles.Details}>
				{jobAction.action.eventKey && (
					<React.Fragment>
						<span>Event</span>
						{buildEventName()}
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

export default ActionCreated;
