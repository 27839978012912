import { normalize, schema } from "normalizr";
import {
	ConditionalContentChild,
	ConditionalContentElementsList,
	ContentCondition,
	ContentConditionsList,
} from "design-system";

export function normalizeBasic(name: string, data: Array<object> | undefined, key: string = "key") {
	if (data === undefined) {
		return {};
	}

	const entity = new schema.Entity(name, {}, { idAttribute: key });
	const entities = [entity];

	return normalize(data, entities).entities[name] || {};
}

const EMPTY_ACTIONS_SCHEMA = {
	actions: {},
	actionConnections: {},
	actionRoutes: {},
};

const EMPTY_STEPS_SCHEMA = {
	steps: {},
	elementConnections: {},
	stepRoutes: {},
	contentConditions: {},
	conditionalContentElements: {},
};

const EMPTY_DELAYS_SCHEMA = {
	delays: {},
	delayConnections: {},
	delayRoutes: {},
};

export function normalizeSteps(stepsData: any) {
	const elementRouteConditions = new schema.Entity("elementRouteConditions", {}, { idAttribute: "key" });

	const routes = new schema.Entity(
		"stepRoutes",
		{
			conditions: [elementRouteConditions],
		},
		{ idAttribute: "key" }
	);

	const connection = new schema.Entity(
		"elementConnections",
		{
			routes: [routes],
		},
		{ idAttribute: "key" }
	);

	let contentConditions: ContentConditionsList = {};
	let conditionalContentElements: ConditionalContentElementsList = {};

	const stepContentElements = new schema.Entity(
		"stepContentElements",
		{},
		{
			idAttribute: "key",
			processStrategy: (value, parent, key) => {
				if (value.type !== "conditional") {
					return value;
				}

				value.details.conditions = value.details.conditions.map((condition: ContentCondition) => {
					contentConditions[condition.key] = condition;

					return condition.key;
				});

				value.details.elements = value.details.elements.map((element: ConditionalContentChild) => {
					conditionalContentElements[element.key] = element;

					return element.key;
				});

				return value;
			},
		}
	);

	const steps = new schema.Entity(
		"steps",
		{
			connectionKey: connection,
			content: [stepContentElements],
		},
		{
			idAttribute: "key",
			processStrategy: (value, parent, key) => {
				let step = {
					...value,
					connectionKey: value.connection,
				};
				delete step["connection"];

				return step;
			},
		}
	);

	let results = normalize(stepsData, [steps]).entities || { ...EMPTY_STEPS_SCHEMA };

	results.conditionalContentConditions = contentConditions;
	results.conditionalContentElements = conditionalContentElements;

	return results;
}

export function normalizeRoles(rolesData: any) {
	const permissions = new schema.Entity("permissions", {}, { idAttribute: "key" });

	const roles = new schema.Entity(
		"roles",
		{
			permissions: [permissions],
		},
		{ idAttribute: "key" }
	);

	return normalize(rolesData, [roles]).entities;
}

export function normalizeJobs(jobsData: any) {
	const jobFields = new schema.Entity(
		"fields",
		{},
		{
			idAttribute: "fieldKey",
			processStrategy: (value) => {
				return { value: value.fieldValue };
			},
		}
	);

	const jobs = new schema.Entity(
		"jobs",
		{
			fields: [jobFields],
		},
		{
			idAttribute: "key",
		}
	);

	const entities = normalize(jobsData, [jobs]).entities;

	// processStrategy won't let us return a single value, it must return an object
	let jobFieldsPrepared: { [key: string]: string } = {};
	if (entities.fields) {
		Object.keys(entities.fields).forEach((jobFieldKey: string) => {
			jobFieldsPrepared[jobFieldKey] = entities.fields[jobFieldKey].value;
		});
	}

	return {
		jobs: entities.jobs,
		jobFields: jobFieldsPrepared,
	};
}

export function normalizeJobStep(jobStepData: any) {
	const userGeneratedContent = new schema.Entity("userGeneratedContent", {}, { idAttribute: "key" });

	const steps = new schema.Entity(
		"steps",
		{
			userGeneratedContent: [userGeneratedContent],
		},
		{
			idAttribute: "key",
		}
	);

	const entities = normalize(jobStepData, [steps]).entities;

	return {
		steps: entities.steps,
		userGeneratedContent: entities.userGeneratedContent,
	};
}

export function normalizeActions(data: any) {
	const actionRouteConditions = new schema.Entity("actionRouteConditions", {}, { idAttribute: "key" });

	const routes = new schema.Entity(
		"actionRoutes",
		{
			conditions: [actionRouteConditions],
		},
		{ idAttribute: "key" }
	);

	const connection = new schema.Entity(
		"actionConnections",
		{
			routes: [routes],
		},
		{ idAttribute: "key" }
	);

	const actions = new schema.Entity(
		"actions",
		{
			connectionKey: connection,
		},
		{
			idAttribute: "key",
			processStrategy: (value, parent, key) => {
				let action = {
					...value,
					connectionKey: value.connection,
				};
				delete action["connection"];

				return action;
			},
		}
	);

	return normalize(data, [actions]).entities || { ...EMPTY_ACTIONS_SCHEMA };
}

export function normalizeDelays(data: any) {
	const routes = new schema.Entity("delayRoutes", {}, { idAttribute: "key" });

	const connection = new schema.Entity(
		"delayConnections",
		{
			routes: [routes],
		},
		{ idAttribute: "key" }
	);

	const delays = new schema.Entity(
		"delays",
		{
			connectionKey: connection,
		},
		{
			idAttribute: "key",
			processStrategy: (value, parent, key) => {
				let delay = {
					...value,
					connectionKey: value.connection,
				};
				delete delay["connection"];

				return delay;
			},
		}
	);

	return normalize(data, [delays]).entities || { ...EMPTY_DELAYS_SCHEMA };
}

export function normalizeExternalEndpoints(data: any) {
	const variables = new schema.Entity("variables", {}, { idAttribute: "key" });

	const endpoints = new schema.Entity(
		"externalEndpoints",
		{
			variables: [variables],
		},
		{ idAttribute: "key" }
	);

	return normalize(data, [endpoints]).entities;
}
