import React, { useState } from "react";
import { isStepLoaded, stepHasContent } from "../../../../../utils/helpers";
import { store } from "../../../../../store";
import { InitialState } from "../../../../../initialState";
import { ConnectionTypes, ElementConnection, Job, JobStep, Spinner, User } from "design-system";
import { Link } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { loadingNextStep, pollForWaitingJobStep } from "../../../jobActions";
import { fetchFromApi, sendRequestToAPI } from "../../../../../sharedActions";
import { JobAPI } from "../../../../../api/JobAPI";
import { WorkflowAPI } from "../../../../../api/WorkflowAPI";

import styles from "./OpenSteps.module.scss";

interface Props {
	jobStep: JobStep;
	job: Job;
}

const Action: React.FunctionComponent<Props> = ({ jobStep, job }) => {
	const steps = useSelector((state: InitialState) => state.steps);
	const [isLoadingStep, setIsLoadingStep] = useState(false);
	const dispatch = useDispatch();

	function calculateIsLocked(jobStep: JobStep, currentUser: User) {
		if (jobStep.assignment.assignees.length === 0) {
			return false;
		}

		return !jobStep.assignment.assignees.includes(currentUser.key);
	}

	function isMultiChoiceConnection() {
		const step = steps[jobStep.step.key];

		if (!step) {
			return false;
		}

		const connection: ElementConnection = store.getState().elementConnections[step.connectionKey];

		return connection.type === ConnectionTypes.MULTIPLE_CHOICE;
	}

	function handleDoneButtonClick() {
		dispatch(loadingNextStep());

		dispatch(sendRequestToAPI(JobAPI.completeStepAndWait(job.key, jobStep.key, [])));
		dispatch(pollForWaitingJobStep(job.key));
	}

	const stepIsLoaded = isStepLoaded(steps, jobStep);

	if (!stepIsLoaded && !isLoadingStep && jobStep !== undefined) {
		setIsLoadingStep(true);
		dispatch(fetchFromApi(WorkflowAPI.loadStep(jobStep.workflowKey, jobStep.step.key)));
	}

	if (!stepIsLoaded) {
		return <Spinner size="small" />;
	}

	const hasContent = stepHasContent(steps, jobStep);
	const isLocked = calculateIsLocked(jobStep, store.getState().me);

	if (hasContent || isMultiChoiceConnection()) {
		if (isLocked) {
			return (
				<div className={styles.Locked}>
					<p>Start Work</p>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="11.915"
						height="18.193"
						viewBox="0 0 11.915 18.193"
						style={{ marginRight: "8px" }}
					>
						<path
							d="M615.932,643.49v-4.337a5.414,5.414,0,0,0-10.829,0v4.337a5.957,5.957,0,1,0,10.829,0Zm-5.414,4.086a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,610.517,647.576Zm3.24-6.6a5.954,5.954,0,0,0-6.48,0v-2a3.24,3.24,0,1,1,6.48,0Z"
							transform="translate(-604.56 -633.741)"
							fill="#8892a8"
						/>
					</svg>
				</div>
			);
		}

		return (
			<Link to={`/jobs/${jobStep.jobKey}/open/${jobStep.key}`}>
				<p>Start Work</p>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20.954"
					height="19.221"
					viewBox="0 0 20.954 19.221"
				>
					<g transform="translate(630.634 835.13) rotate(180)">
						<g transform="translate(609.683 824.185)">
							<path
								d="M629.59,828.364H610.728a1.355,1.355,0,0,1,0-2.638H629.59a1.355,1.355,0,0,1,0,2.638Z"
								transform="translate(-609.684 -825.726)"
								fill="#9cb7f7"
							/>
						</g>
						<g transform="translate(609.68 815.91)">
							<path
								d="M621.752,832.8a1.319,1.319,0,0,1-.851,2.327,1.328,1.328,0,0,1-.843-.3l-9.905-8.312a1.008,1.008,0,0,1-.16-.169,1.152,1.152,0,0,1-.126-.177.064.064,0,0,1-.017-.034.752.752,0,0,1-.067-.143.618.618,0,0,1-.067-.211,1.165,1.165,0,0,1,0-.556.813.813,0,0,1,.084-.253.046.046,0,0,1,.025-.042.462.462,0,0,1,.067-.126.557.557,0,0,1,.1-.143,1.587,1.587,0,0,1,.169-.169l.042-.034,9.855-8.245a1.32,1.32,0,1,1,1.694,2.023l-8.7,7.267Z"
								transform="translate(-609.68 -815.91)"
								fill="#5583ed"
							/>
						</g>
					</g>
				</svg>
			</Link>
		);
	}

	if (!hasContent) {
		if (isLocked) {
			return (
				<div className={styles.Locked}>
					<p>Mark as Done</p>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="11.915"
						height="18.193"
						viewBox="0 0 11.915 18.193"
						style={{ marginRight: "8px" }}
					>
						<path
							d="M615.932,643.49v-4.337a5.414,5.414,0,0,0-10.829,0v4.337a5.957,5.957,0,1,0,10.829,0Zm-5.414,4.086a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,610.517,647.576Zm3.24-6.6a5.954,5.954,0,0,0-6.48,0v-2a3.24,3.24,0,1,1,6.48,0Z"
							transform="translate(-604.56 -633.741)"
							fill="#8892a8"
						/>
					</svg>
				</div>
			);
		}
		return (
			<div className={styles.MarkAsComplete} onClick={handleDoneButtonClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16.944"
					height="12.672"
					viewBox="0 0 16.944 12.672"
				>
					<g transform="translate(-1019.282 -505.74)">
						<path
							d="M1025.542,518.412a1.241,1.241,0,0,1-.79-.284l-5.016-4.123a1.245,1.245,0,0,1,1.581-1.923l4.11,3.379,8.641-9.323a1.245,1.245,0,0,1,1.826,1.692l-9.439,10.183A1.244,1.244,0,0,1,1025.542,518.412Z"
							fill="#265dde"
						/>
					</g>
				</svg>

				<p>Mark as Done</p>
			</div>
		);
	}

	return null;
};

export default Action;
