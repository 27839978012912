import React from "react";
import { JobStep, LogItem, Step, Workflow } from "design-system";
import { useStore } from "react-redux";
import classNames from "classnames";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const WorkflowStarted: React.FunctionComponent<Props> = ({ logItem }) => {
	const store = useStore();
	const workflow = store.getState().workflows[logItem.workflowKey] as Workflow;

	const indicatorClasses = classNames([styles.Indicator, styles.Subworkflow]);
	return (
		<React.Fragment>
			<div className={indicatorClasses}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="31.01"
					height="28.173"
					viewBox="0 0 31.01 28.173"
				>
					<g transform="translate(-398.819 -886.676)">
						<g>
							<path
								d="M423.48,892.653v9.427a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1v-8.15a1,1,0,0,0-1-1H410.04a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1h11.717A1.724,1.724,0,0,1,423.48,892.653Z"
								fill="#5583ed"
							/>
						</g>
						<circle cx="6" cy="6" r="6" transform="translate(398.819 886.676)" fill="#1b3b87" />
						<path
							d="M423.794,914.091l5.83-10.1a1.516,1.516,0,0,0-1.313-2.274h-11.66a1.516,1.516,0,0,0-1.313,2.274l5.83,10.1A1.517,1.517,0,0,0,423.794,914.091Z"
							fill="#9cb7f7"
						/>
					</g>
				</svg>
			</div>
			<div className={styles.Event}>
				<span>Workflow Started</span>
				<p>{workflow ? workflow.name : "Unknown Workflow"}</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default WorkflowStarted;
