import React, { MutableRefObject, useRef } from "react";
import { Job, LogItem, UsersList } from "design-system";
import { useSelector } from "react-redux";
import { InitialState } from "../../../../../initialState";
import OpenStep from "./OpenStep";
import classNames from "classnames";

import styles from "./OpenSteps.module.scss";

interface Props {
	job: Job;
	openSteps: Array<LogItem>;
	users: UsersList;
}

const OpenSteps: React.FunctionComponent<Props> = ({ job, openSteps, users }) => {
	const jobSteps = useSelector((state: InitialState) => state.jobsSteps);
	const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

	const steps = openSteps.map((logItem: LogItem) => {
		const jobStep = jobSteps[logItem.elementKey];
		return <OpenStep key={jobStep.key} job={job} jobStep={jobStep} users={users} logItem={logItem} />;
	});

	const classes = classNames([
		styles.OpenSteps,
		(job.completedAt !== null || steps.length === 0) && styles.Hide,
	]);

	return (
		<div className={classes} ref={ref}>
			<div>
				<p>Open</p>
				{steps}
			</div>
		</div>
	);
};

export default OpenSteps;
