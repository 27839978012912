import {
	CanvasElementType,
	ConditionsSettings,
	CursorTypes,
	ElementConnectionList,
	ElementRoute,
	ElementType,
	LinePosition,
} from "design-system";
import { DiagramObjectController } from "./DiagramObjectController";
import { MousePositionCoordinates } from "./ObjectsCreator";
import { changeCursor, elementHasNotMoved, XYCoords } from "../utils";
import { Diagram } from "../Diagram";
import { store } from "../../../../../store";
import { InitialState } from "../../../../../initialState";
import { moveRouteConditions, selectElement, showConditionalConnectionPanel } from "../../workflowActions";
import { RouteSnapping } from "./RouteSnapping";
import { Routes } from "./Routes";
import throttle from "lodash.throttle";
import { TextRenderEngine } from "../TextRenderEngine";
import { Unsubscribe } from "redux";
import { WorkflowAPI } from "../../../../../api/WorkflowAPI";
import { sendRequestToAPI } from "../../../../../sharedActions";

interface RouteOriginalDestination {
	coordX: number;
	coordY: number;
	width: number;
	height: number;
	key: string;
	type: ElementType;
	direction: LinePosition;
}

export const UNMATCHED_ROUTE_MARKER_WIDTH = 167;
export const UNMATCHED_ROUTE_MARKER_HEIGHT = 40;

interface CollectedUnmatchedRouteMarker {
	routeKey: string;
	settings: ConditionsSettings;
	routeOriginalDestination: RouteOriginalDestination;
}

let elementConnections: ElementConnectionList;

export class UnmatchedRouteMarkers implements DiagramObjectController {
	private Diagram: Diagram;
	private readonly unsubscribeStoreFn: Unsubscribe;
	private collected: { [key: string]: CollectedUnmatchedRouteMarker } = {};

	private mousePositionOffset: XYCoords = { x: 0, y: 0 };
	private selectedMarker: any = null;
	private initialCoords: XYCoords = { x: 0, y: 0 };
	private draggingMarkerKey: string | null = null;
	private preparedToMove: boolean = false;

	private connectorPositionOffsets: { [key: string]: XYCoords } = {};
	private attachedConnectors: { [connectorKey: string]: XYCoords } = {};

	private RouteSnapping: RouteSnapping | null = null;

	private throttledTrackMouseEvent: any;
	private throttledMoveEvent: any;
	private zoomVelocityOffset: { x: number; y: number } = { x: 0, y: 0 };

	constructor(Diagram: Diagram) {
		this.Diagram = Diagram;

		this.throttledMoveEvent = null;
		this.throttledTrackMouseEvent = null;

		this.unsubscribeStoreFn = store.subscribe(this.listen.bind(this));
	}

	queue(route: ElementRoute, routeOriginalDestination: RouteOriginalDestination) {
		this.collected[`${route.key}_unmatched`] = {
			routeKey: route.key,
			settings: route.conditionsSettings as ConditionsSettings,
			routeOriginalDestination,
		};
	}

	clear() {
		this.collected = {};
	}

	cleanup(): void {
		this.unsubscribeStoreFn();
	}

	handleClick(key: string, mousePosition: MousePositionCoordinates): void {
		const routeKey = this.collected[key].routeKey;

		const stepKey = store.getState().elementRoutes[routeKey].from.key;
		store.dispatch(selectElement(stepKey, ElementType.Step));
		store.dispatch(showConditionalConnectionPanel());
		changeCursor(CursorTypes.DEFAULT);
	}

	handleHoverStart(key: string, mousePosition: MousePositionCoordinates): void {
		changeCursor(CursorTypes.POINTER);
	}

	handleHoverEnd(key: string): void {
		changeCursor(CursorTypes.DEFAULT);
	}

	handleMouseDown(key: string, mousePosition: MousePositionCoordinates): void {
		this.preparedToMove = false;
		this.draggingMarkerKey = key;

		this.prepareToMove = this.prepareToMove.bind(this);
		this.trackMouse = this.trackMouse.bind(this);
		this.move = this.move.bind(this);
		this.stopMoving = this.stopMoving.bind(this);

		this.throttledMoveEvent = throttle(this.trackMouse, 40, { leading: true });
		this.throttledTrackMouseEvent = throttle(this.move, 40, { leading: true });

		window.addEventListener("mousemove", this.prepareToMove);
		window.addEventListener("mousemove", this.throttledTrackMouseEvent);
		window.addEventListener("mousemove", this.throttledMoveEvent);
		window.addEventListener("mouseup", this.stopMoving);
	}

	prepareToMove(event: MouseEvent) {
		if (this.preparedToMove || this.draggingMarkerKey === null) {
			return;
		}
		this.preparedToMove = true;
		const mousePosition = this.Diagram.getCanvasMousePosition(event);

		const marker = this.collected[this.draggingMarkerKey];
		const route = store.getState().elementRoutes[marker.routeKey] as ElementRoute;

		const coordX = (route.conditionsSettings as ConditionsSettings).coordX as number;
		const coordY = (route.conditionsSettings as ConditionsSettings).coordY as number;

		this.selectedMarker = {
			routeKey: marker.routeKey,
			coordX,
			coordY,
		};

		this.initialCoords = { x: coordX, y: coordY };

		this.setDependents(route, mousePosition);

		this.mousePositionOffset = {
			x: mousePosition.x - coordX,
			y: mousePosition.y - coordY,
		};

		const speed = this.Diagram.getZoomVelocity();

		this.zoomVelocityOffset = {
			x: Math.round(mousePosition.x * speed),
			y: Math.round(mousePosition.y * speed),
		};

		this.RouteSnapping = new RouteSnapping(
			{
				key: this.selectedMarker.routeKey,
				coordX: this.selectedMarker.coordX,
				coordY: this.selectedMarker.coordY,
			},
			ElementType.UnmatchedRouteMarker,
			null,
			this.Diagram.getWorkflowKey()
		);

		window.removeEventListener("mousemove", this.prepareToMove);
	}

	setDependents(route: ElementRoute, mousePosition: MousePositionCoordinates) {
		const state = store.getState() as InitialState;
		const connectors = state.connectors;

		if (route.to.type === ElementType.Connector) {
			const connectorKey = route.to.key;
			this.attachedConnectors[connectorKey] = {
				x: connectors[connectorKey].coordX,
				y: connectors[connectorKey].coordY,
			};
			this.connectorPositionOffsets[connectorKey] = {
				x: mousePosition.x - this.attachedConnectors[connectorKey].x,
				y: mousePosition.y - this.attachedConnectors[connectorKey].y,
			};
		}
	}

	trackMouse(event: MouseEvent) {
		if (!this.selectedMarker) return;

		const mousePosition = this.Diagram.getCanvasMousePosition(event, this.zoomVelocityOffset);

		this.selectedMarker.coordX = mousePosition.x - this.mousePositionOffset.x;
		this.selectedMarker.coordY = mousePosition.y - this.mousePositionOffset.y;

		// todo snapping not working, need to dig into this
		const snapOffset: XYCoords = (this.RouteSnapping as RouteSnapping).calculateSnapAmount(
			this.selectedMarker.coordX,
			this.selectedMarker.coordY
		);

		this.selectedMarker.coordX += snapOffset.x;
		this.selectedMarker.coordY += snapOffset.y;

		Object.keys(this.attachedConnectors).forEach((connectionKey) => {
			this.attachedConnectors[connectionKey].x =
				mousePosition.x - this.connectorPositionOffsets[connectionKey].x + snapOffset.x;
			this.attachedConnectors[connectionKey].y =
				mousePosition.y - this.connectorPositionOffsets[connectionKey].y + snapOffset.y;
		});
	}

	move(event: MouseEvent) {
		if (!this.selectedMarker) {
			return;
		}

		changeCursor(CursorTypes.GRABBING);

		store.dispatch(
			moveRouteConditions(
				{
					key: this.selectedMarker.routeKey,
					coordX: this.selectedMarker.coordX,
					coordY: this.selectedMarker.coordY,
				},
				this.attachedConnectors
			)
		);
	}

	stopMoving(event: MouseEvent) {
		changeCursor(CursorTypes.DEFAULT);

		window.removeEventListener("mousemove", this.prepareToMove);
		window.removeEventListener("mousemove", this.throttledTrackMouseEvent);
		window.removeEventListener("mousemove", this.throttledMoveEvent);
		window.removeEventListener("mouseup", this.stopMoving);

		if (!this.selectedMarker) return;

		const coordX = Math.round(this.selectedMarker.coordX);
		const coordY = Math.round(this.selectedMarker.coordY);

		if (elementHasNotMoved(this.initialCoords.x, this.initialCoords.y, coordX, coordY)) {
			this.resetProperties();

			return;
		}

		store.dispatch(
			sendRequestToAPI(
				WorkflowAPI.updateRouteConditionsSettings(this.selectedMarker.routeKey, {
					coordX,
					coordY,
				})
			)
		);

		if (Object.keys(this.attachedConnectors).length) {
			store.dispatch(sendRequestToAPI(WorkflowAPI.moveConnectors(this.attachedConnectors)));
		}

		this.resetProperties();
	}

	resetProperties() {
		this.selectedMarker = null;
		this.draggingMarkerKey = null;
		this.preparedToMove = false;
		this.initialCoords = { x: 0, y: 0 };
		this.mousePositionOffset = { x: 0, y: 0 };
		this.attachedConnectors = {};
		this.connectorPositionOffsets = {};
		this.RouteSnapping = null;
		this.throttledMoveEvent = null;
		this.zoomVelocityOffset = { x: 0, y: 0 };
	}

	visualize(marker: CollectedUnmatchedRouteMarker, context: CanvasRenderingContext2D) {
		this.drawMarker(marker, context);
	}

	virtualize(marker: CollectedUnmatchedRouteMarker, context: CanvasRenderingContext2D) {
		context.fillStyle = this.Diagram.getHitGraph().getRgbColorStringFromElementKey(
			`${marker.routeKey}_unmatched`
		);

		context.beginPath();
		context.rect(
			marker.settings.coordX as number,
			marker.settings.coordY as number,
			UNMATCHED_ROUTE_MARKER_WIDTH,
			UNMATCHED_ROUTE_MARKER_HEIGHT
		);
		context.fill();
	}

	drawMarker(marker: CollectedUnmatchedRouteMarker, context: CanvasRenderingContext2D) {
		context.lineWidth = 1;
		context.strokeStyle = "#CACED9";
		context.fillStyle = "#F2F5FA";

		const cornerSharpness = 10;

		const startingX = marker.settings.coordX as number;
		const startingY = (marker.settings.coordY as number) + cornerSharpness;

		context.beginPath();
		context.moveTo(startingX, startingY);

		context.lineTo(startingX + cornerSharpness, startingY - cornerSharpness);

		context.lineTo(
			startingX + UNMATCHED_ROUTE_MARKER_WIDTH - cornerSharpness,
			startingY - cornerSharpness
		);

		context.lineTo(startingX + UNMATCHED_ROUTE_MARKER_WIDTH, startingY);

		context.lineTo(
			startingX + UNMATCHED_ROUTE_MARKER_WIDTH,
			startingY + UNMATCHED_ROUTE_MARKER_HEIGHT - cornerSharpness * 2
		);

		context.lineTo(
			startingX + UNMATCHED_ROUTE_MARKER_WIDTH - cornerSharpness,
			startingY + UNMATCHED_ROUTE_MARKER_HEIGHT - cornerSharpness
		);

		context.lineTo(
			startingX + cornerSharpness,
			startingY + UNMATCHED_ROUTE_MARKER_HEIGHT - cornerSharpness
		);

		context.lineTo(startingX, startingY + UNMATCHED_ROUTE_MARKER_HEIGHT - cornerSharpness * 2);

		context.closePath();
		context.stroke();
		context.fill();

		context.fillStyle = "#44506E";
		context.font = TextRenderEngine.buildFontString("16px");
		context.textBaseline = "middle";
		context.fillText(
			"Unmatched Route",
			(marker.settings.coordX as number) + 16,
			(marker.settings.coordY as number) + UNMATCHED_ROUTE_MARKER_HEIGHT / 2
		);
	}

	drawDiamond(marker: CollectedUnmatchedRouteMarker, context: CanvasRenderingContext2D) {
		const length = 18;

		const startingX = (marker.settings.coordX as number) + UNMATCHED_ROUTE_MARKER_WIDTH;
		const startingY = (marker.settings.coordY as number) - length / 2;

		context.beginPath();
		context.moveTo(startingX, startingY);

		// top left edge
		context.lineTo(startingX - length / 2, startingY + length / 2);

		// bottom left edge
		context.lineTo(startingX, startingY + length);

		// bottom right edge
		context.lineTo(startingX + length / 2, startingY + length / 2);

		// closing the path automatically creates
		// the top right edge
		context.closePath();

		context.fillStyle = "#229EC7";
		context.fill();
	}

	render(): void {
		const context = this.Diagram.getContext();
		const hitGraph = this.Diagram.getHitGraph();
		const Routes = this.Diagram.getObjectsCreator().getByType(CanvasElementType.Routes) as Routes;

		Object.keys(this.collected).forEach((key) => {
			const marker: CollectedUnmatchedRouteMarker = this.collected[key];

			this.visualize(marker, context);
			this.virtualize(marker, hitGraph.getContext());

			Routes.queue({
				key: `${marker.routeKey}_unmatched_line`,
				from: {
					key: marker.routeKey,
					type: ElementType.Conditions,
					height: UNMATCHED_ROUTE_MARKER_HEIGHT,
					width: UNMATCHED_ROUTE_MARKER_WIDTH,
					coordX: marker.settings.coordX as number,
					coordY: marker.settings.coordY as number,
					direction: marker.settings.routeFromDirection,
				},
				to: {
					...marker.routeOriginalDestination,
					direction: marker.settings.routeToDirection,
				},
				label: null,
				conditions: [],
				conditionsSettings: null,
			});
		});
	}

	listen() {
		let prevElementConnections = elementConnections;
		// use selectors
		elementConnections = store.getState().elementConnections;

		if (prevElementConnections !== elementConnections) {
			this.Diagram.render();
		}
	}
}
