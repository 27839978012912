import React from "react";
import { JobAction, Spinner } from "design-system";
import Status from "./Status";
import Date from "./Date";

import styles from "./Action.module.scss";

interface Props {
	jobAction: JobAction;
	startedAt: string;
}

const Details: React.FunctionComponent<Props> = ({ jobAction, startedAt }) => {
	if (!jobAction) {
		return <Spinner size="medium" />;
	}

	return (
		<div className={styles.Details}>
			<div>
				<p className={styles.title}>Status</p>
				<Status jobAction={jobAction} />
			</div>
			<Date status={jobAction.status} startedAt={startedAt} />
		</div>
	);
};

export default Details;
