import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, Card, ShortText, Space } from "design-system";
import { Stages } from "../Menu";
import Adiv from "../../../Animatables/Adiv";

import styles from "../Activation.module.scss";

interface Props extends RouteComponentProps {
	currentStage: Stages;
	invites: Array<string>;
	updateInvite: (index: number, value: string) => void;
	addInvite: () => void;
	saveInvites: () => void;
	skipInvites: () => void;
}

const InviteTeam: React.FunctionComponent<Props> = ({
	currentStage,
	invites,
	updateInvite,
	addInvite,
	saveInvites,
	skipInvites,
}) => {
	if (currentStage !== Stages.Team) return null;

	const inviteInputs = invites.map((invite, index) => {
		return (
			<div className={styles.InviteInput}>
				<ShortText
					key={`invite-${index}`}
					label=""
					id={`invite-${index}`}
					value={invite}
					onChange={(event) => updateInvite(index, (event.target as HTMLInputElement).value)}
				/>
			</div>
		);
	});

	return (
		<div className={styles.InviteTeam}>
			<Adiv className={styles.InviteTeam}>
				<Card maxWidth={500}>
					<div className={styles.SectionHeading}>
						<h2>Invite your team</h2>
						<p>
							People you invite here will join as other administrators. You can change their
							roles and permissions after you’ve activated your account.
						</p>
					</div>

					<div>
						<p>Provide email addresses of people to invite</p>
						<div>{inviteInputs}</div>
						<div onClick={addInvite} className={styles.AddInvite}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.031"
								height="16.027"
								viewBox="0 0 16.031 16.027"
							>
								<g transform="translate(-536.17 -690.37)">
									<path d="M552.2,698.386a1.329,1.329,0,0,1-1.327,1.327h-5.361v5.358a1.327,1.327,0,0,1-2.654,0v-5.358H537.5a1.327,1.327,0,1,1,0-2.654h5.361V691.7a1.327,1.327,0,0,1,2.654,0v5.361h5.361A1.326,1.326,0,0,1,552.2,698.386Z" />
								</g>
							</svg>
							<p>Add another email</p>
						</div>
					</div>
					<Space amount={16} />
					<div>
						<Button
							theme="solid"
							tone="light"
							color="green"
							onClick={saveInvites}
							style={{ marginRight: "12px" }}
						>
							Continue
						</Button>

						<Button theme="solid" tone="light" color="primary" onClick={skipInvites}>
							Skip
						</Button>
					</div>
				</Card>
			</Adiv>
		</div>
	);
};

export default InviteTeam;
