import { InitialState } from "../../initialState";
import { AnyAction } from "redux";
import {
	CREATE_JOB_TITLE,
	DELETE_JOB_TITLE,
	DELETE_ROLE,
	DELETE_USER,
	SET_PERMISSIONS,
	SET_ROLES,
	UPDATE_JOB_TITLE,
	UPDATE_ROLE,
	UPDATE_USER,
	UPDATE_USER_PROFILE,
	UPDATE_USER_PROFILE_IMAGE,
} from "../../actionTypes";
import { normalizeBasic, normalizeRoles } from "../../data/normalize/basic";
import { PermissionCategory } from "design-system";
import { uppercaseFirstLetter } from "../../utils/helpers";

export function users(state: InitialState, action: AnyAction) {
	if (action.type === UPDATE_USER) {
		return {
			...state,
			users: Object.assign({}, state.users, {
				[action.user.key]: action.user,
			}),
		};
	}

	if (action.type === UPDATE_USER_PROFILE) {
		return {
			...state,
			users: Object.assign({}, state.users, {
				[action.user.key]: {
					...state.users[action.user.key],
					...action.user,
				},
			}),
			me: {
				...state.me,
				...action.user,
			},
		};
	}

	if (action.type === SET_ROLES) {
		const entities = normalizeRoles(action.roles);

		return {
			...state,
			roles: entities.roles,
		};
	}

	if (action.type === SET_PERMISSIONS) {
		let categories = action.categories.map((category: PermissionCategory) => {
			return {
				...category,
				name: uppercaseFirstLetter(category.key),
			};
		});

		return {
			...state,
			permissions: normalizeBasic("permissions", action.permissions),
			permissionCategories: normalizeBasic("permissionCategories", categories),
		};
	}

	if (action.type === UPDATE_ROLE) {
		return {
			...state,
			roles: Object.assign({}, state.roles, {
				[action.role.key]: action.role,
			}),
		};
	}

	if (action.type === DELETE_ROLE) {
		let roles = { ...state.roles };

		delete roles[action.roleKey];

		return {
			...state,
			roles,
		};
	}

	if (action.type === DELETE_USER) {
		let users = { ...state.users };

		const date = new Date();
		users[action.userKey].deletedAt = date.toISOString();

		return {
			...state,
			users,
		};
	}

	if (action.type === UPDATE_USER_PROFILE_IMAGE) {
		return {
			...state,
			users: Object.assign({}, state.users, {
				[action.userKey]: {
					...state.users[action.userKey],
					imageUrl: action.url,
				},
			}),
			me: {
				...state.me,
				imageUrl: action.url,
			},
		};
	}

	if (action.type === CREATE_JOB_TITLE || action.type === UPDATE_JOB_TITLE) {
		return {
			...state,
			jobTitles: Object.assign({}, state.jobTitles, {
				[action.jobTitle.key]: action.jobTitle,
			}),
		};
	}

	if (action.type === DELETE_JOB_TITLE) {
		let jobTitles = { ...state.jobTitles };

		delete jobTitles[action.key];

		return {
			...state,
			jobTitles,
		};
	}

	return state;
}
