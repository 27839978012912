import { combineEpics, Epic, ofType } from "redux-observable";
import {
	KB_MOVE_PAGE_ELEMENT,
	KB_MOVE_PAGE_ELEMENT_THROTTLED,
	MOVE_CONTENT_ELEMENT,
	MOVE_CONTENT_ELEMENT_THROTTLED,
} from "../../../actionTypes";
import { map, mergeMap, throttleTime } from "rxjs/operators";

const throttleMoveElement: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(MOVE_CONTENT_ELEMENT),
		throttleTime(200),
		map((action) => {
			return {
				...action,
				type: MOVE_CONTENT_ELEMENT_THROTTLED,
			};
		})
	);
};

const throttleMovePageElement: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(KB_MOVE_PAGE_ELEMENT),
		throttleTime(200),
		map((action) => {
			return {
				...action,
				type: KB_MOVE_PAGE_ELEMENT_THROTTLED,
			};
		})
	);
};

export default combineEpics(throttleMoveElement, throttleMovePageElement);
