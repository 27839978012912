import React from "react";
import { RouteComponentProps } from "@reach/router";

import styles from "./ProfileImage.module.scss";

interface Props extends RouteComponentProps {
	imageUrl: string | null;
	removeImage: () => void;
}

const UploadedImage: React.FunctionComponent<Props> = ({ imageUrl, removeImage }) => {
	return (
		<div className={styles.UploadedImage}>
			{imageUrl && <img src={imageUrl} alt="User Profile Image" />}

			{/* expand to show "Remove" on hover*/}
			<div className={styles.RemoveBtn} onClick={removeImage}>
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
					<g transform="translate(-852.167 -749.9)">
						<circle cx="9" cy="9" r="9" transform="translate(852.167 749.9)" fill="#caced9" />
						<path
							d="M865.556,761.581l-2.729-2.729,2.633-2.632a.492.492,0,0,0,0-.7l0,0-.96-.96a.492.492,0,0,0-.7,0l0,0-2.633,2.632-2.632-2.632a.492.492,0,0,0-.7,0l0,0-.96.96a.492.492,0,0,0-.005.7l.005,0,2.632,2.632-2.728,2.729a.492.492,0,0,0-.005.7l.005,0,.96.96a.491.491,0,0,0,.7,0l.005,0,2.728-2.729,2.729,2.729a.491.491,0,0,0,.7,0l.005,0,.96-.96a.493.493,0,0,0,0-.7Z"
							fill="#98a0b3"
						/>
					</g>
				</svg>
			</div>
		</div>
	);
};

export default UploadedImage;
