import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../../../../../initialState";
import { HiddenOptions, Job, JobComment, LogItem, RichTextViewer } from "design-system";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import moment from "moment";
import OptionsMenu from "./OptionsMenu";
import { deleteComment } from "../../../../../jobActions";
import { sendRequestToAPI } from "../../../../../../../sharedActions";
import { JobAPI } from "../../../../../../../api/JobAPI";

import styles from "./Comment.module.scss";

interface Props extends RouteComponentProps {
	item: LogItem;
	job: Job;
}

const Comment: React.FunctionComponent<Props> = ({ item, job }) => {
	const dispatch = useDispatch();
	const jobComment = useSelector(
		(state: InitialState) => state.jobsComments[job.key][item.elementKey]
	) as JobComment;

	const users = useSelector((state: InitialState) => state.users);

	if (!jobComment) {
		return null;
	}

	const user = users[jobComment.userKey];

	// const isQueuedForAnimation = useSelector((state: InitialState) =>
	// 	state.animationsQueue.includes(item.elementKey)
	// );

	const classes = classNames([styles.Comment]);

	function handleDelete() {
		dispatch(deleteComment(job.key, item.elementKey));
		dispatch(sendRequestToAPI(JobAPI.deleteComment(job.key, item.elementKey)));
	}

	return (
		<div className={classes}>
			<div className={styles.Details}>
				<div>
					<img src={user.imageUrl} width={30} height={30} alt="" />
					<p>
						{user.firstName} {user.lastName}
					</p>
				</div>
				<div>
					<span>{moment(jobComment.createdAt).format("MMMM Do, YYYY, h:mm A")}</span>
					<HiddenOptions>
						<OptionsMenu onDelete={handleDelete} />
					</HiddenOptions>
				</div>
			</div>
			<div className={styles.Message}>
				<RichTextViewer content={jobComment.message} />
			</div>
		</div>
	);
};

export default Comment;
