import React from "react";

import { Mark25 } from "../../Base/Logo/Logos";

import styles from "./Activation.module.scss";

const Header: React.FunctionComponent = () => {
	return (
		<div className={styles.Header}>
			<a href="https://prestavi.com" target="_blank">
				{Mark25}
			</a>
			<p>Activate your Account</p>
		</div>
	);
};

export default Header;
