import { AnyAction } from "redux";
import { CREATE_JOB_STEP, LIVE_APPEND_JOB_LOG_ENTRY, LIVE_CREATE_JOB_STEP } from "../../actionTypes";

export function calculateAnimationReleaseKeys(action: AnyAction) {
	if (action.type === CREATE_JOB_STEP || action.type === LIVE_CREATE_JOB_STEP) {
		return [action.jobStep.key];
	}

	if (action.type === LIVE_APPEND_JOB_LOG_ENTRY) {
		return [action.jobLogItem.elementKey];
	}
}
