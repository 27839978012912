import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Job, LogItem, Me } from "design-system";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import { InitialState } from "../../../../../../../initialState";
import { calculateDelayText } from "../../../../../../../utils/helpers";
// @ts-ignore
import moment from "moment-timezone";

import styles from "./Delay.module.scss";

interface Props extends RouteComponentProps {
	item: LogItem;
	job: Job;
}

const Delay: React.FunctionComponent<Props> = ({ item, job }) => {
	const jobDelay = useSelector((state: InitialState) => state.jobsDelays[item.elementKey]);
	const me = useSelector((state: InitialState) => state.me) as Me;

	if (!jobDelay) {
		return null;
	}

	// const isQueuedForAnimation = useSelector((state: InitialState) =>
	// 	state.animationsQueue.includes(item.elementKey)
	// );

	const classes = classNames([styles.Delay]);

	const endsAt = moment.tz(jobDelay.endsAt, "UTC").tz(me.timezone);
	const now = moment.tz(moment(), "UTC").tz(me.timezone);

	return (
		<div className={classes}>
			<div className={styles.Details}>
				<img src="/images/diagram/delay-icon.svg" width={30} height={30} alt="" />
				<div>
					<p>{calculateDelayText(jobDelay.delay.units, jobDelay.delay.interval)}</p>
					{endsAt.isAfter(now) && (
						<span>
							Ends on {endsAt.format("MMMM Do")} at {endsAt.format("h:mm A")}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default Delay;
