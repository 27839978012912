import React from "react";
import { TOP_NAV_HEIGHT } from "../../../../utils/constants";

import styles from "./JobOverview.module.scss";

interface Props {}

const Main: React.FunctionComponent<Props> = ({ children }) => {
	return (
		<div className={styles.Main} style={{ minHeight: `${window.innerHeight - TOP_NAV_HEIGHT - 1}px` }}>
			{children}
		</div>
	);
};

export default Main;
