import React from "react";
import { ExternalEndpointResponse, ExternalEndpointResponsePrefillField } from "design-system";
import { useSelector } from "react-redux";
import { InitialState } from "../../../../initialState";

import styles from "./ResponseBuilder.module.scss";

interface Props {
	response: ExternalEndpointResponse;
}

const PrefillFields: React.FunctionComponent<Props> = ({ response }) => {
	const fields = useSelector((state: InitialState) => state.formFields);

	if (response.prefillFields.length === 0) {
		return null;
	}

	const prefillFields = response.prefillFields.reduce(
		(results: Array<JSX.Element>, prefillField: ExternalEndpointResponsePrefillField) => {
			const field = fields[prefillField.fieldKey];

			if (!prefillField.fieldKey || !field) {
				return results;
			}

			results.push(
				<p key={field.key} className={styles.PrefillField}>
					{field.name}
				</p>
			);

			return results;
		},
		[]
	);

	return (
		<div className={styles.PrefillFields}>
			<div className={styles.Title}>
				<h2>Fields to Prefill</h2>
			</div>
			<div className={styles.FieldsList}>{prefillFields}</div>
		</div>
	);
};

export default PrefillFields;
