import React from "react";
import { Job, JobStep, User, Ability, Button } from "design-system";
import { canNotAccess } from "../../../../../../../utils/helpers";

interface Props {
	job: Job;
	jobStep: JobStep;
	currentUser: User;
	onEditClick: () => void;
}

function shouldHide(jobStep: JobStep, currentUser: User) {
	if (jobStep.completedBy === null) {
		return false;
	}

	if (jobStep.completedBy.length <= 1) {
		return false;
	}

	return true;
}

const EditButton: React.FunctionComponent<Props> = ({ job, jobStep, currentUser, onEditClick }) => {
	// Note: can't use the hasContent variable that the other buttons can use because
	// when we load the job, only the active steps content is retrieved. That works for
	// the other two buttons, but not for this one.

	if (jobStep.step.deletedAt !== null) {
		return null;
	}

	if (job.completedAt === null && canNotAccess(Ability.ChangeInProgressJob)) {
		return null;
	}

	if (job.completedAt !== null && canNotAccess(Ability.ChangeCompletedJob)) {
		return null;
	}

	if (jobStep.completedAt === null || job.completedAt !== null) {
		return null;
	}

	if (shouldHide(jobStep, currentUser)) {
		return null;
	}

	return (
		<Button size="compact" theme="solid" tone="light" color="primary" onClick={onEditClick}>
			Edit
		</Button>
	);
};

export default EditButton;
