import workflowEpics from "./api/epics/workflows/workflowEpics";
import generalEpics from "./api/epics/general";
import jobEpics from "./api/epics/jobEpics";
import contentElementEpics from "./api/epics/workflows/contentElementEpics";
import accountEpics from "./components/Account/accountEpics";
import viewEpics from "./api/epics/viewsEpics";
import billingEpics from "./api/epics/billing";
import { post } from "./utils/api";
import { InitialState } from "./initialState";
import { Me } from "design-system";
import { timer } from "rxjs";
import { apiRequestFailed } from "./components/Account/accountActions";
import { oneForOne, superviseEpics } from "@mixer/epic-supervisor";
import { store } from "./store";

const options = {
	restart: oneForOne,
	onError: ({ epicName, error }: any, actions: any, state: InitialState, services: any) => {
		store.dispatch(apiRequestFailed(error));

		const me = store.getState().me as Me;

		post("/error-report", {
			user: { key: me.key, email: me.email },
			description: "client",
			error: error,
		});

		return timer(500);
	},
	onRestart: (_: any, actions: any) => {},
};

export const rootEpic = superviseEpics(
	// @ts-ignore
	options,
	generalEpics,
	accountEpics,
	jobEpics,
	workflowEpics,
	contentElementEpics,
	viewEpics,
	billingEpics
);
