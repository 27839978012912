import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import {
	Ability,
	Button,
	Dropdown,
	DropdownItem,
	JobTitlesList,
	LoadState,
	ShortText,
	Space,
	UserBaseSettings,
	UserInvite,
	WorkflowAccessType,
} from "design-system";
import { InitialState } from "../../../../initialState";
import { INITIAL_RENDER_ONLY } from "../../../../utils/constants";
import WorkflowAccess from "./WorkflowAccess";
import { fetchFromApi, sendRequestToAPI } from "../../../../sharedActions";
import { WorkflowAPI } from "../../../../api/WorkflowAPI";
import { createJobTitle, updateUser } from "../../peopleActions";
import { UserAPI } from "../../../../api/UserAPI";
import cuid from "cuid";
import { formatRolesForDropdown, formatSelectedRole } from "./User";
import { canNotAccess, convertToPublishedKey } from "../../../../utils/helpers";
import Unauthorized from "../../../Unauthorized/Unauthorized";
import { store } from "../../../../store";

import styles from "./User.module.scss";

interface Props extends RouteComponentProps {}

const EMPTY_USER_INVITE: UserBaseSettings = {
	key: null,
	firstName: "",
	lastName: "",
	email: "",
	role: {
		key: "",
		name: "",
	},
	jobTitleKey: null,
	status: "P",
	workflowAccess: {
		type: WorkflowAccessType.All,
		workflows: [],
		grantNew: true,
	},
};

const InviteUser: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [user, setUser] = useState<UserInvite>({
		...EMPTY_USER_INVITE,
		key: cuid(),
	});
	const [jobTitle, setJobTitle] = useState<DropdownItem | null>(null);

	const firstNameRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
	const roles = useSelector((state: InitialState) => state.roles);
	const jobTitles = useSelector((state: InitialState) => state.jobTitles);
	const workflowsLoaded = useSelector(
		(state: InitialState) => state.loadStates.workingWorkflows === LoadState.LOADED
	);

	useEffect(() => {
		if (!firstNameRef.current) return;

		firstNameRef.current.focus();

		if (workflowsLoaded) return;

		dispatch(fetchFromApi(WorkflowAPI.unpublishedWorkflows()));
	}, INITIAL_RENDER_ONLY);

	if (canNotAccess(Ability.InviteUser)) {
		return <Unauthorized requiredPermission={"Invite Users"} />;
	}

	function update(property: keyof UserInvite, value: any) {
		setUser({
			...user,
			[property]: value,
		});
	}

	function updateAccessType(type: WorkflowAccessType) {
		setUser({
			...user,
			workflowAccess: {
				...user.workflowAccess,
				type,
			},
		});
	}

	function setAccessWorkflows(workflows: Array<string>) {
		setUser({
			...user,
			workflowAccess: {
				...user.workflowAccess,
				workflows,
			},
		});
	}

	function setAccessWorkflowNewGrant(grantNew: boolean) {
		setUser({
			...user,
			workflowAccess: {
				...user.workflowAccess,
				grantNew,
			},
		});
	}

	function formatJobTitles(jobTitles: JobTitlesList) {
		return Object.keys(jobTitles).map((jobTitleKey) => {
			return {
				label: jobTitles[jobTitleKey].name,
				key: jobTitles[jobTitleKey].key,
			};
		});
	}

	function updateJobTitle(jobTitle: DropdownItem) {
		setJobTitle(jobTitle);
	}

	function addJobTitle(jobTitleName: string) {
		if (!jobTitleName.trim().length) return;

		const jobTitle = {
			key: cuid(),
			name: jobTitleName,
		};

		setJobTitle({
			key: jobTitle.key,
			label: jobTitle.name,
		});

		dispatch(createJobTitle(jobTitle.key, jobTitleName));
		dispatch(sendRequestToAPI(UserAPI.createJobTitle(jobTitle)));
	}

	async function sendInvite(event: React.MouseEvent) {
		const workflows = (store.getState() as InitialState).workflows;
		const nextWorkflowAccess = {
			...user.workflowAccess,
			// In order to prevent showing duplicates in the workflow access component
			// we strip out the published keys, and now we add them back in
			workflows: user.workflowAccess.workflows.reduce((result: Array<string>, workflowKey: string) => {
				const publishedKey = convertToPublishedKey(workflowKey);

				if (workflows.hasOwnProperty(publishedKey)) {
					result.push(publishedKey);
				}

				result.push(workflowKey);

				return result;
			}, []),
		};

		dispatch(
			updateUser({
				...user,
				jobTitleKey: jobTitle === null ? null : jobTitle.key,
				timezone: "",
				imageUrl: "/images/default_profile_image.png",
				workflowAccess: nextWorkflowAccess,
				deletedAt: null,
			})
		);

		dispatch(
			sendRequestToAPI(
				UserAPI.inviteUser({
					...user,
					// @ts-ignore
					jobTitleKey: jobTitle === null ? null : jobTitle.key,
					workflowAccess: nextWorkflowAccess,
				})
			)
		);

		setUser({ ...EMPTY_USER_INVITE });

		await navigate("/manage/users");
	}

	return (
		<div className={styles.InviteUser}>
			<h2>Send an Invite</h2>

			<div>
				<ShortText
					label="First Name"
					id="firstName"
					theme="inside"
					forwardRef={firstNameRef}
					value={user.firstName}
					onChange={(event) => update("firstName", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={16} />

				<ShortText
					label="Last Name"
					id="lastName"
					theme="inside"
					value={user.lastName}
					onChange={(event) => update("lastName", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={16} />

				<ShortText
					label="Email"
					id="email"
					theme="inside"
					value={user.email}
					onChange={(event) => update("email", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={16} />

				<Dropdown
					options={formatJobTitles(jobTitles)}
					onChange={(option: DropdownItem) => updateJobTitle(option)}
					value={jobTitle}
					label="Job Title"
					theme={"inside"}
					placeholder="Select or create a job title"
					creatable={true}
					saveNewOption={(jobTitle) => addJobTitle(jobTitle)}
				/>

				<Space amount={16} />

				<Dropdown
					label="Role"
					theme="inside"
					options={formatRolesForDropdown(roles)}
					value={formatSelectedRole(user.role.key, roles)}
					onChange={(option: DropdownItem) =>
						update("role", { key: option.key, name: option.label })
					}
				/>

				<WorkflowAccess
					workflowAccess={user.workflowAccess}
					updateGrantNewAccess={setAccessWorkflowNewGrant}
					updateType={updateAccessType}
					setAccessWorkflows={setAccessWorkflows}
				/>

				<Space amount={48} />
				<Button theme="solid" tone="dark" color="primary" onClick={sendInvite}>
					Send Invite
				</Button>
			</div>
		</div>
	);
};

export default InviteUser;
