import React, { Fragment, MutableRefObject, useRef, useState } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import Nav from "./Nav";
import { animateMobileMenuVisibility, animateNavItemMarker } from "./animations";
import MobileMenuIcon from "./MobileMenuIcon";
import { AccountMenu, AccountMenuOption, Modal } from "design-system";
import { useDispatch, useSelector } from "react-redux";
import { sendRequestToAPI } from "../../../sharedActions";
import { AccountAPI } from "../../../api/AccountAPI";
import MobileMenu from "../MobileMenu/MobileMenu";
import { InitialState } from "../../../initialState";
import ReactPlayer from "react-player";
import { startIntroVideo, stopIntroVideo } from "../../Account/accountActions";
import { LogoHeader } from "../Logo/Logos";

import styles from "./Header.module.scss";

interface Props extends RouteComponentProps {
	preload: (componentName: string) => void;
}

const Header: React.FunctionComponent<Props> = ({ preload }) => {
	let navItemMarkerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	let mobileMenuRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const [showingMobileMenu, setShowingMobileMenu] = useState(false);
	const playIntroVideo = useSelector((state: InitialState) => state.support.playIntroVideo);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	async function handleMenuOptionClick(option: AccountMenuOption) {
		if (option === AccountMenuOption.Profile) {
			await navigate("/profile");
		}

		if (option === AccountMenuOption.Billing) {
			await navigate("/billing");
		}

		if (option === AccountMenuOption.Support) {
			window.location.href = "https://prestavi.com/contact-us";
		}

		if (option === AccountMenuOption.Logout) {
			dispatch(sendRequestToAPI(AccountAPI.logout()));
		}
	}

	return (
		<Fragment>
			<div className={styles.Header}>
				<div>
					<div className={styles.Logo}>{LogoHeader}</div>

					<Nav
						preload={preload}
						animateNavItemMarker={async (properties) => {
							navItemMarkerRef = await animateNavItemMarker(properties, navItemMarkerRef);
						}}
					/>
				</div>

				<div>
					<div className={styles.IntroVideoBtn} onClick={() => dispatch(startIntroVideo())}>
						<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
							<g transform="translate(-234.275 -204)">
								<circle
									cx="13.001"
									cy="13.001"
									r="13.001"
									transform="translate(234.275 204)"
									fill="#5583ed"
								/>
								<path
									d="M780,1132.446v-11.092c0-1.011.9-1.646,1.617-1.14l9.418,5.541a1.468,1.468,0,0,1,0,2.281l-9.418,5.541C780.9,1134.082,780,1133.458,780,1132.446Z"
									transform="translate(-536.648 -909.895)"
									fill="#f0f3fc"
								/>
							</g>
						</svg>

						<span>Watch Intro</span>
					</div>
					<AccountMenu onOptionClick={handleMenuOptionClick} preload={preload} />
					<MobileMenuIcon
						toggleMobileMenu={() => {
							mobileMenuRef = animateMobileMenuVisibility(showingMobileMenu, mobileMenuRef);
							setShowingMobileMenu(!showingMobileMenu);
						}}
					/>
				</div>
			</div>
			<MobileMenu
				onHide={() => {
					mobileMenuRef = animateMobileMenuVisibility(showingMobileMenu, mobileMenuRef);
					setShowingMobileMenu(!showingMobileMenu);
				}}
				forwardRef={mobileMenuRef}
			/>

			<Modal
				className={styles.ModalVideo}
				maxWidth="1200px"
				show={playIntroVideo}
				toggleModal={() => dispatch(stopIntroVideo())}
			>
				<ReactPlayer
					width="100%"
					height="100%"
					playing={true}
					controls={true}
					url="https://cdn.prestavi.com/Prestavi-Overview-Video.mp4"
					config={{
						file: {
							attributes: { controlsList: "nodownload" },
						},
					}}
				/>
			</Modal>
		</Fragment>
	);
};

export default Header;
