import React from "react";
import classNames from "classnames";
import Step from "./Elements/Step/Step";
import Action from "./Elements/Action/Action";
import Comment from "./Elements/Comment/Comment";
import { Job, LogItem } from "design-system";
import { useSelector } from "react-redux";
import { InitialState } from "../../../../../initialState";
import Delay from "./Elements/Delay/Delay";

import styles from "./History.module.scss";

interface Props {
	job: Job;
	logItem: LogItem;
	expandedElements: Array<string>;
	expandAll: boolean;
	handleClick: (logItem: LogItem) => void;
	collapseElement: (elementKey: string) => void;
}

interface TimelineEventProps {
	item: LogItem;
	job: Job;
	expandAll: boolean;
	isExpanded: boolean;
	onClose: (elementKey: string) => void;
}

const TimelineEventMap: { [key: string]: React.FunctionComponent<TimelineEventProps> } = {
	step: Step,
	action: Action,
	comment: Comment,
	delay: Delay,
};

const HistoryEvent: React.FunctionComponent<Props> = ({
	job,
	logItem,
	expandedElements,
	expandAll,
	handleClick,
	collapseElement,
}) => {
	const TimelineEvent = TimelineEventMap[logItem.elementType];
	const isExpanded = expandedElements.includes(logItem.elementKey) || expandAll;
	const isQueuedForAnimation = useSelector((state: InitialState) =>
		state.animationsQueue.includes(logItem.elementKey)
	);

	const classes = classNames([
		styles.HistoryEvent,
		isExpanded && styles.isExpanded,
		!isExpanded && styles.isCollapsed,
		!isQueuedForAnimation && styles.Show,
	]);

	return (
		<div className={classes} onClick={() => handleClick(logItem)}>
			<TimelineEvent
				key={`${logItem.elementKey}_${logItem.timestamp}`}
				job={job}
				item={logItem}
				expandAll={expandAll}
				isExpanded={isExpanded}
				onClose={collapseElement}
			/>
		</div>
	);
};

export default HistoryEvent;
