import React from "react";
import { Button, Modal } from "design-system";
import { useNavigate } from "@reach/router";

import styles from "./Unauthorized.module.scss";

interface Props {
	requiredPermission: string;
	message?: string;
}

const Unauthorized: React.FunctionComponent<Props> = ({ message, requiredPermission }) => {
	const navigate = useNavigate();

	return (
		<Modal className={styles.Unauthorized} show={true} toggleModal={() => null}>
			<h1>You don't have access to this page.</h1>
			<p>
				If you should have access to this page, speak to your system administrator and ask them to add
				the <b>{requiredPermission}</b> permission to your role.
			</p>
			{message !== undefined && <p>{message}</p>}

			<Button theme="soft" tone="light" color="primary" onClick={() => navigate("/jobs")}>
				Go to Jobs
			</Button>
		</Modal>
	);
};

export default Unauthorized;
