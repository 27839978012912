import React from "react";
import { LogItem } from "design-system";
import { buildUser } from "../LogEventsTable";
import { extractUserFromLog } from "../../../../../utils/helpers";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const UndoJobTaskCompleted: React.FunctionComponent<Props> = ({ logItem }) => {
	const userKey = extractUserFromLog(logItem);

	return (
		<React.Fragment>
			<div className={styles.Indicator}></div>
			<div className={styles.Event}>
				<span>Undo Task Completed</span>

				<p>
					{
						// @ts-ignore
						logItem.details.title
					}
				</p>
			</div>
			<div className={styles.Details}>
				<span>Undo By</span>
				<p>{buildUser(userKey)}</p>
			</div>
		</React.Fragment>
	);
};

export default UndoJobTaskCompleted;
