import React from "react";
import { JobStep, LogItem, Step, Workflow } from "design-system";
import { useStore } from "react-redux";
import classNames from "classnames";
import { extractUserFromLog } from "../../../../../utils/helpers";
import { buildUser } from "../LogEventsTable";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const UserCompletedStep: React.FunctionComponent<Props> = ({ logItem }) => {
	const store = useStore();
	const jobStep = store.getState().jobsSteps[logItem.elementKey] as JobStep;

	const userKey = extractUserFromLog(logItem);

	return (
		<React.Fragment>
			<div className={styles.Indicator}></div>
			<div className={styles.Event}>
				<span>User Completed Step</span>
				<p>{jobStep ? jobStep.step.name : "Unknown Step"}</p>
			</div>
			<div className={styles.Details}>
				<span>Completed By</span>
				<p>{buildUser(userKey)}</p>
			</div>
		</React.Fragment>
	);
};

export default UserCompletedStep;
