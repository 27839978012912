import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../../../../../../initialState";
import { INITIAL_RENDER_ONLY } from "../../../../../../../../utils/constants";
import { fetchFromApi } from "../../../../../../../../sharedActions";
import { DeveloperAPI } from "../../../../../../../../api/DeveloperAPI";
import HeaderPlaceholder from "../HeaderPlaceholder";

import styles from "../Action.module.scss";

interface Props {
	logoUrl: string;
	externalEndpointKey: string;
}

const ExternalEndpointHeader: React.FunctionComponent<Props> = ({ externalEndpointKey, logoUrl }) => {
	const dispatch = useDispatch();
	const endpoint = useSelector((state: InitialState) => state.externalEndpoints[externalEndpointKey]);

	useEffect(() => {
		if (!endpoint) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpoint(externalEndpointKey)));
		}
	}, INITIAL_RENDER_ONLY);

	if (!endpoint) {
		return <HeaderPlaceholder />;
	}

	return (
		<React.Fragment>
			<img src={logoUrl} width={30} />
			<div className={styles.ExternalEndpointHeader}>
				<h4>{endpoint.name}</h4>
			</div>
		</React.Fragment>
	);
};

export default ExternalEndpointHeader;
