import {MutableRefObject} from "react";
import {wait} from "../../../utils/constants";

function isInitialRender(markerRef: MutableRefObject<HTMLDivElement|null>): boolean {
    if (!markerRef.current) return false;

    return !markerRef.current.style.visibility;
}

export function calculateItemMarkerProperties(
    navItemRef: MutableRefObject<HTMLAnchorElement|null>,
): {width: number, xPos: number} {
    if (navItemRef.current === null) return {width: 0, xPos: 0};

    const {width, x} = navItemRef.current.getBoundingClientRect();
    const padding = 24;
    const xPos = Math.round(x - padding);

    return {width, xPos};
}

export async function animateNavItemMarker(properties: {width: number, xPos: number}, markerRef: MutableRefObject<HTMLDivElement | null>) {
    if (!markerRef.current) return markerRef;

    if (isInitialRender(markerRef)) {
        markerRef.current.style.width = `${properties.width}px`;
        markerRef.current.style.transform = `translateX(${properties.xPos}px) translateY(-3px)`;

        await wait(300);
        // gracefully enter from above
        markerRef.current.style.visibility = 'visible';
        markerRef.current.style.transform = `translateX(${properties.xPos}px) translateY(0)`;

        return markerRef;
    }

    markerRef.current.style.visibility = 'visible';
    markerRef.current.style.width = `${properties.width}px`;
    markerRef.current.style.transform = `translateX(${properties.xPos}px)`;

    return markerRef
}

export function animateMobileMenuVisibility(showingMobileMenu: boolean, mobileMenuRef: MutableRefObject<HTMLDivElement | null>): MutableRefObject<HTMLDivElement | null>  {
    if (!mobileMenuRef.current) return mobileMenuRef;

    if (showingMobileMenu) {
        mobileMenuRef.current.style.transform = "translateX(-500px)";
    } else {
        mobileMenuRef.current.style.transform = "translateX(0px)";
    }

    return mobileMenuRef;
}