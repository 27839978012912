import React from "react";
import { Job, JobStatus } from "design-system";
import classNames from "classnames";

import styles from "./Sidebar.module.scss";

interface Props {
	job: Job;
}

const Status: React.FunctionComponent<Props> = ({ job }) => {
	if (job.status === JobStatus.Closed) {
		return <div className={`${styles.JobStatus} ${styles.Closed}`}>Closed</div>;
	}

	if (job.status === JobStatus.Delayed) {
		return <div className={`${styles.JobStatus} ${styles.Delayed}`}>Delayed</div>;
	}

	return <div className={`${styles.JobStatus} ${styles.Open}`}>Open</div>;
};

export default Status;
