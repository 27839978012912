import selectedCategoryKeys, { InitialState } from "../../initialState";
import { AnyAction } from "redux";
import {
	KB_CHANGE_PAGE_MODE,
	KB_CREATE_CATEGORY,
	KB_CREATE_PAGE,
	KB_CREATE_PAGE_ELEMENT,
	KB_DELETE_CATEGORY,
	KB_DELETE_PAGE,
	KB_DELETE_PAGE_ELEMENT,
	KB_DESELECT_ALL_CATEGORY_ROLES,
	KB_DESELECT_PAGE,
	KB_HIDE_ACCESS_CONTROL,
	KB_MOVE_PAGE_ELEMENT_THROTTLED,
	KB_RESET,
	KB_SELECT_ALL_CATEGORY_ROLES,
	KB_SELECT_PAGE,
	KB_SET_PAGE_ELEMENTS,
	KB_SHOW_ACCESS_CONTROL,
	KB_TOGGLE_CATEGORY,
	KB_UPDATE_CATEGORY,
	KB_UPDATE_CATEGORY_ACCESS_TYPE,
	KB_UPDATE_CATEGORY_RESTRICTED_ROLE,
	KB_UPDATE_CATEGORY_RESTRICTED_ROLES,
	KB_UPDATE_CATEGORY_RESTRICTED_WORKFLOWS,
	KB_UPDATE_CATEGORY_WORKFLOW_GRANT_NEW,
	KB_UPDATE_PAGE,
	KB_UPDATE_PAGE_ELEMENT,
	KB_UPDATE_PAGE_ELEMENT_IMAGE_DETAILS,
	SET_KNOWLEDGE_BASE,
	SET_OPEN_JOB_KB,
} from "../../actionTypes";
import { normalizeKnowledgeBase } from "../../data/normalize/knowledgeBase";
import { normalizeBasic } from "../../data/normalize/basic";
import { convertTextContentToRichText } from "../../utils/helpers";
import { RoleAccessType } from "design-system";

export function knowledgeBase(state: InitialState, action: AnyAction) {
	if (action.type === SET_KNOWLEDGE_BASE) {
		const entities = normalizeKnowledgeBase(action.categories);

		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				loaded: true,
			},
			knowledgeBaseCategories: {
				...entities.categories,
				...entities.subCategories,
			},
			knowledgeBasePages: entities.pages,
		};
	}

	if (action.type === SET_OPEN_JOB_KB) {
		const entities = normalizeKnowledgeBase(action.categories);

		if (!Object.keys(entities).length) {
			return state;
		}

		return {
			...state,
			knowledgeBaseCategories: {
				...entities.categories,
				...entities.subCategories,
			},
			knowledgeBasePages: entities.pages,
			openJob: {
				...state.openJob,
				knowledgeBase: {
					...state.openJob.knowledgeBase,
					loadedByWorkflowKey: [
						...state.openJob.knowledgeBase.loadedByWorkflowKey,
						action.workflowKey,
					],
					categoryKeys: Object.keys(entities.categories),
				},
			},
		};
	}

	if (action.type === KB_SET_PAGE_ELEMENTS) {
		const elements = normalizeBasic("elements", action.elements);
		const pageKey = action.pageKey;

		return {
			...state,
			knowledgeBasePageElements: Object.assign(
				{},
				state.knowledgeBasePageElements,
				convertTextContentToRichText(elements)
			),
			knowledgeBasePages: Object.assign({}, state.knowledgeBasePages, {
				[pageKey]: {
					...state.knowledgeBasePages[pageKey],
					loaded: true,
					elements: Object.keys(elements),
				},
			}),
		};
	}

	if (action.type === KB_CREATE_CATEGORY) {
		let nextState = {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				categories: [...state.knowledgeBase.categories, action.category.key],
				selectedCategoryKeys: [...state.knowledgeBase.selectedCategoryKeys, action.category.key],
			},
			knowledgeBaseCategories: Object.assign({}, state.knowledgeBaseCategories, {
				[action.category.key]: action.category,
			}),
		};

		if (action.category.parentKey !== null) {
			nextState.knowledgeBaseCategories = Object.assign({}, nextState.knowledgeBaseCategories, {
				[action.category.parentKey]: {
					...state.knowledgeBaseCategories[action.category.parentKey],
					subCategories: [
						...state.knowledgeBaseCategories[action.category.parentKey].subCategories,
						action.category.key,
					],
				},
			});
		}

		return nextState;
	}

	if (action.type === KB_UPDATE_CATEGORY) {
		return {
			...state,
			knowledgeBaseCategories: Object.assign({}, state.knowledgeBaseCategories, {
				[action.category.key]: action.category,
			}),
		};
	}

	if (action.type === KB_TOGGLE_CATEGORY) {
		if (state.knowledgeBase.selectedCategoryKeys.includes(action.key)) {
			return {
				...state,
				knowledgeBase: {
					...state.knowledgeBase,
					selectedCategoryKeys: [
						...state.knowledgeBase.selectedCategoryKeys.filter(
							(categoryKey) => categoryKey !== action.key
						),
					],
				},
			};
		}

		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				selectedCategoryKeys: [...state.knowledgeBase.selectedCategoryKeys, action.key],
			},
		};
	}

	if (action.type === KB_CREATE_PAGE) {
		const categoryKey = action.page.categoryKey;

		let nextState = {
			...state,
			knowledgeBaseCategories: Object.assign({}, state.knowledgeBaseCategories, {
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					pages: [...state.knowledgeBaseCategories[categoryKey].pages, action.page.key],
				},
			}),
			knowledgeBasePages: Object.assign({}, state.knowledgeBasePages, {
				[action.page.key]: action.page,
			}),
			knowledgeBase: {
				...state.knowledgeBase,
				selectedPageKey: action.page.key,
				selectedAccessControlCategoryKey: null,
				pageMode: "edit",
			},
		};

		if (nextState.knowledgeBase.selectedCategoryKeys.includes(categoryKey) === false) {
			nextState.knowledgeBase.selectedCategoryKeys = [
				...nextState.knowledgeBase.selectedCategoryKeys,
				categoryKey,
			];
		}

		return nextState;
	}

	if (action.type === KB_SELECT_PAGE) {
		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				selectedPageKey: action.pageKey,
				selectedAccessControlCategoryKey: null,
				pageMode: "view",
			},
		};
	}

	if (action.type === KB_DESELECT_PAGE) {
		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				selectedPageKey: null,
				selectedAccessControlCategoryKey: null,
				pageMode: "view",
			},
		};
	}

	if (action.type === KB_UPDATE_PAGE) {
		return {
			...state,
			knowledgeBasePages: Object.assign({}, state.knowledgeBasePages, {
				[action.pageKey]: {
					...state.knowledgeBasePages[action.pageKey],
					...action.attributes,
				},
			}),
		};
	}

	if (action.type === KB_CREATE_PAGE_ELEMENT) {
		return {
			...state,
			knowledgeBasePageElements: Object.assign({}, state.knowledgeBasePageElements, {
				[action.element.key]: action.element,
			}),
			knowledgeBasePages: Object.assign({}, state.knowledgeBasePages, {
				[action.element.pageKey]: {
					...state.knowledgeBasePages[action.element.pageKey],
					elements: [
						...state.knowledgeBasePages[action.element.pageKey].elements,
						action.element.key,
					],
				},
			}),
		};
	}

	if (action.type === KB_UPDATE_PAGE_ELEMENT) {
		return {
			...state,
			knowledgeBasePageElements: Object.assign({}, state.knowledgeBasePageElements, {
				[action.element.key]: action.element,
			}),
		};
	}

	if (action.type === KB_UPDATE_PAGE_ELEMENT_IMAGE_DETAILS) {
		return {
			...state,
			knowledgeBasePageElements: Object.assign({}, state.knowledgeBasePageElements, {
				[action.key]: {
					...state.knowledgeBasePageElements[action.key],
					details: action.details,
				},
			}),
		};
	}

	if (action.type === KB_MOVE_PAGE_ELEMENT_THROTTLED) {
		return {
			...state,
			knowledgeBasePageElements: Object.assign({}, state.knowledgeBasePageElements, {
				[action.elementKey]: {
					...state.knowledgeBasePageElements[action.elementKey],
					order: action.nextPosition,
				},
			}),
		};
	}

	if (action.type === KB_DELETE_PAGE_ELEMENT) {
		let elements = { ...state.knowledgeBasePageElements };

		delete elements[action.elementKey];

		return {
			...state,
			knowledgeBasePageElements: elements,
			knowledgeBasePages: Object.assign({}, state.knowledgeBasePages, {
				[action.pageKey]: {
					...state.knowledgeBasePages[action.pageKey],
					elements: [
						...state.knowledgeBasePages[action.pageKey].elements.filter(
							(key) => key !== action.elementKey
						),
					],
				},
			}),
		};
	}

	if (action.type === KB_DELETE_PAGE) {
		let pages = { ...state.knowledgeBasePages };
		let elements = { ...state.knowledgeBasePageElements };
		const categoryKey = pages[action.pageKey].categoryKey;

		pages[action.pageKey].elements.forEach((elementKey) => {
			delete elements[elementKey];
		});

		delete pages[action.pageKey];

		let nextState = {
			...state,
			knowledgeBaseCategories: Object.assign({}, state.knowledgeBaseCategories, {
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					pages: [
						...state.knowledgeBaseCategories[categoryKey].pages.filter(
							(key) => key !== action.pageKey
						),
					],
				},
			}),
			knowledgeBasePages: pages,
			knowledgeBasePageElements: elements,
		};
		if (nextState.knowledgeBase.selectedPageKey === action.pageKey) {
			nextState.knowledgeBase.selectedPageKey = null;
			nextState.knowledgeBase.pageMode = "view";
		}

		return nextState;
	}

	if (action.type === KB_CHANGE_PAGE_MODE) {
		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				pageMode: state.knowledgeBase.pageMode === "view" ? "edit" : "view",
			},
		};
	}

	if (action.type === KB_DELETE_CATEGORY) {
		let categories = { ...state.knowledgeBaseCategories };
		let pages = { ...state.knowledgeBasePages };
		let elements = { ...state.knowledgeBasePageElements };

		const pageKeys = [...categories[action.key].pages];
		let elementKeys: Array<string> = [];

		pageKeys.forEach((pageKey) => {
			elementKeys.push(...pages[pageKey].elements);
			delete pages[pageKey];
		});

		elementKeys.forEach((elementKey) => {
			delete elements[elementKey];
		});

		if (categories[action.key].parentKey) {
			const parentKey = categories[action.key].parentKey as string;
			categories[parentKey].subCategories = categories[parentKey].subCategories.filter(
				(key) => key !== action.key
			);
		}

		// what about subcategories?
		// not going to worry about this too much, it's just on the client side and will be
		// accurate on a refresh

		delete categories[action.key];

		let nextState = {
			...state,
			knowledgeBaseCategories: categories,
			knowledgeBasePages: pages,
			knowledgeBasePageElements: elements,
		};

		if (
			state.knowledgeBase.selectedPageKey !== null &&
			pageKeys.includes(state.knowledgeBase.selectedPageKey)
		) {
			nextState.knowledgeBase.selectedPageKey = null;
		}

		return nextState;
	}

	if (action.type === KB_RESET) {
		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				selectedCategoryKeys: [],
				selectedPageKey: null,
				selectedAccessControlCategoryKey: null,
				pageMode: "view",
			},
		};
	}

	if (action.type === KB_SHOW_ACCESS_CONTROL) {
		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				selectedAccessControlCategoryKey: action.categoryKey,
			},
		};
	}

	if (action.type === KB_HIDE_ACCESS_CONTROL) {
		return {
			...state,
			knowledgeBase: {
				...state.knowledgeBase,
				selectedAccessControlCategoryKey: null,
			},
		};
	}

	if (action.type === KB_UPDATE_CATEGORY_ACCESS_TYPE) {
		const categoryKey = state.knowledgeBase.selectedAccessControlCategoryKey as string;
		const resourceType: "workflows" | "roles" = action.resourceType;

		return {
			...state,
			knowledgeBaseCategories: Object.assign({}, state.knowledgeBaseCategories, {
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					access: {
						...state.knowledgeBaseCategories[categoryKey].access,
						[resourceType]: {
							...state.knowledgeBaseCategories[categoryKey].access[resourceType],
							type: action.accessType,
						},
					},
				},
			}),
		};
	}

	if (action.type === KB_UPDATE_CATEGORY_RESTRICTED_ROLES) {
		const categoryKey = state.knowledgeBase.selectedAccessControlCategoryKey as string;

		return {
			...state,
			knowledgeBaseCategories: {
				...state.knowledgeBaseCategories,
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					access: {
						...state.knowledgeBaseCategories[categoryKey].access,
						roles: {
							...state.knowledgeBaseCategories[categoryKey].access.roles,
							restrictedTo: action.restrictedRoles,
						},
					},
				},
			},
		};
	}

	if (action.type === KB_UPDATE_CATEGORY_RESTRICTED_ROLE) {
		const categoryKey = state.knowledgeBase.selectedAccessControlCategoryKey as string;
		const roleKey = action.roleKey;

		return {
			...state,
			knowledgeBaseCategories: {
				...state.knowledgeBaseCategories,
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					access: {
						...state.knowledgeBaseCategories[categoryKey].access,
						roles: {
							...state.knowledgeBaseCategories[categoryKey].access.roles,
							restrictedTo: {
								...state.knowledgeBaseCategories[categoryKey].access.roles.restrictedTo,
								[roleKey]: action.restrictedRole,
							},
						},
					},
				},
			},
		};
	}

	if (action.type === KB_SELECT_ALL_CATEGORY_ROLES) {
		return {
			...state,
			knowledgeBaseCategories: {
				...state.knowledgeBaseCategories,
				[action.categoryKey]: {
					...state.knowledgeBaseCategories[action.categoryKey],
					access: {
						...state.knowledgeBaseCategories[action.categoryKey].access,
						roles: {
							...state.knowledgeBaseCategories[action.categoryKey].access.roles,
							type: "all",
							restrictedTo: {},
						},
					},
				},
			},
		};
	}

	if (action.type === KB_DESELECT_ALL_CATEGORY_ROLES) {
		let category = { ...state.knowledgeBaseCategories[action.categoryKey] };

		if (state.knowledgeBaseCategories[action.categoryKey].access.roles.type === "all") {
			category.access.roles.type = RoleAccessType.Restricted;
		}

		category.access.roles.restrictedTo = {};

		Object.keys(state.roles).forEach((roleKey) => {
			if (state.me?.role.key === roleKey) {
				category.access.roles.restrictedTo[roleKey] = {
					view: true,
					edit: true,
					delete: true,
				};
				return;
			}

			category.access.roles.restrictedTo[roleKey] = {
				view: false,
				edit: false,
				delete: false,
			};
		});

		return {
			...state,
			knowledgeBaseCategories: Object.assign({}, state.knowledgeBaseCategories, {
				[action.categoryKey]: category,
			}),
		};
	}

	if (action.type === KB_UPDATE_CATEGORY_RESTRICTED_WORKFLOWS) {
		const categoryKey = action.categoryKey;

		return {
			...state,
			knowledgeBaseCategories: {
				...state.knowledgeBaseCategories,
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					access: {
						...state.knowledgeBaseCategories[categoryKey].access,
						workflows: {
							...state.knowledgeBaseCategories[categoryKey].access.workflows,
							workflows: action.workflows,
						},
					},
				},
			},
		};
	}

	if (action.type === KB_UPDATE_CATEGORY_WORKFLOW_GRANT_NEW) {
		const categoryKey = action.categoryKey;

		return {
			...state,
			knowledgeBaseCategories: {
				...state.knowledgeBaseCategories,
				[categoryKey]: {
					...state.knowledgeBaseCategories[categoryKey],
					access: {
						...state.knowledgeBaseCategories[categoryKey].access,
						workflows: {
							...state.knowledgeBaseCategories[categoryKey].access.workflows,
							grantNew: action.grantNew,
						},
					},
				},
			},
		};
	}

	return state;
}
