import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../../../../../../../initialState";
import {
	JobContentType,
	JobStep,
	JobStepTask,
	LogItem,
	StepContentElement,
	TaskDetails,
} from "design-system";
import Task from "./Task";

import styles from "./Step.module.scss";

interface Props {
	logItem: LogItem;
	jobStep: JobStep;
	contentKeys: Array<string>;
}

const Tasks: React.FunctionComponent<Props> = ({ logItem, jobStep, contentKeys }) => {
	const jobContent = useSelector((state: InitialState) => state.jobsContent[jobStep.jobKey]);
	const stepContentElements = useSelector((state: InitialState) => state.stepContentElements);

	const taskKeys = useMemo(() => {
		if (!jobContent) {
			return [];
		}

		return contentKeys.filter(
			(contentKey: string) => jobContent[contentKey].contentType === JobContentType.Task
		);
	}, [contentKeys]);

	if (taskKeys.length === 0) {
		return null;
	}

	const tasks = taskKeys.map((taskKey: string) => {
		if (!jobContent[taskKey]) {
			return null;
		}

		return (
			<Task
				key={taskKey}
				jobStep={jobStep}
				logItem={logItem}
				task={jobContent[taskKey]}
				stepContentElements={stepContentElements}
			/>
		);
	});

	return (
		<div className={styles.Tasks}>
			<p>Tasks</p>
			<div>{tasks}</div>
		</div>
	);
};

export default Tasks;
