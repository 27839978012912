import React from "react";
import { RouteComponentProps } from "@reach/router";

import styles from "./ProfileImage.module.scss";

interface Props extends RouteComponentProps {}

const defaultProfileImage = (
	<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
		<g transform="translate(-414 -211)">
			<circle cx="37.5" cy="37.5" r="37.5" transform="translate(414 211)" fill="#1b3b87" />
			<path
				d="M1027.646,356.553a37.47,37.47,0,0,1-6.37-3.458q-1.443-.973-2.8-2.084c1.128-12.619,11.475-22.5,24.072-22.5a24.409,24.409,0,0,1,24.025,22.025,37.507,37.507,0,0,1-9.737,6.02,37.624,37.624,0,0,1-29.194,0Z"
				transform="translate(-590.742 -73.5)"
				fill="#5583ed"
			/>
			<circle
				cx="14.256"
				cy="14.256"
				r="14.256"
				transform="translate(437.554 223.397)"
				fill="#d9e4fc"
			/>
		</g>
	</svg>
);

const DefaultImage: React.FunctionComponent<Props> = () => {
	return <div className={styles.DefaultImage}>{defaultProfileImage}</div>;
};

export default DefaultImage;
