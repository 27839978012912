import React from "react";

export const Logo50 = (
	<svg
		id="Group_2894"
		data-name="Group 2894"
		xmlns="http://www.w3.org/2000/svg"
		width="223.51"
		height="40"
		viewBox="0 0 223.51 40"
		style={{ position: "relative", left: "-10px" }}
	>
		<g>
			<g>
				<path
					d="M65.49,39.33V3.106H73c7.37,0,13.973,2.537,13.973,10.72,0,6.938-4.4,11.77-13.925,11.77H70.084V39.33Zm7.465-17.8c5.837,0,9.235-2.966,9.235-7.655,0-4.451-3.35-6.652-7.991-6.652H70.084V21.529Z"
					fill="#132859"
				/>
				<path
					d="M91.524,39.33V15.787h4.211v3.542c.335-1.149,2.345-4.116,5.072-4.116a7.486,7.486,0,0,1,4.02,1.1l-1.675,3.971a4.993,4.993,0,0,0-2.919-1.052c-2.728,0-4.355,3.11-4.5,5.168V39.33Z"
					fill="#132859"
				/>
				<path
					d="M128.562,36.412c-1.34,1.77-5.073,3.588-9.9,3.588-7.178,0-12.345-4.881-12.345-12.3,0-7.321,4.927-12.537,12.105-12.537,7.369,0,10.862,5.79,10.862,12.011V28.42h-18.71a8.889,8.889,0,0,0,.145,1.436c.861,4.737,4.641,6.651,8.852,6.651a10.238,10.238,0,0,0,7.512-2.918Zm-3.541-11.485c-.622-3.588-2.584-6.316-6.7-6.316-3.541,0-6.652,1.914-7.608,6.316Z"
					fill="#132859"
				/>
				<path
					d="M134.068,33.731a9.177,9.177,0,0,0,6.507,2.968c2.3,0,4.307-1.149,4.307-3.877,0-4.354-10.862-4.737-10.862-11.149,0-3.541,2.966-6.556,7.56-6.556a10.693,10.693,0,0,1,7.321,2.728l-1.674,2.966a7.967,7.967,0,0,0-5.121-2.392c-2.488,0-3.684,1.484-3.684,2.967,0,4.545,10.814,4.068,10.814,11.34,0,4.45-3.493,7.274-8.469,7.274a12.6,12.6,0,0,1-8.565-3.253Z"
					fill="#132859"
				/>
				<path
					d="M156.368,39.33v-20h-4.354V15.835h4.354V7.653h4.211v8.182h5.79v3.494h-5.79v20Z"
					fill="#132859"
				/>
				<path
					d="M188.621,36.842C186.564,38.612,183.07,40,178.238,40,171.2,40,168,35.933,168,31.483c0-5.359,5.455-7.609,9.714-7.609a10.563,10.563,0,0,1,6.843,2.3v-2.92c0-2.583-1.962-4.5-5.838-4.5a17.007,17.007,0,0,0-6.556,1.388l-1.387-3.158a19.525,19.525,0,0,1,8.182-1.818c7.7,0,9.666,3.636,9.666,9.091Zm-4.067-6.986a9.6,9.6,0,0,0-6.269-2.393c-3.971,0-5.933,2.058-5.933,4.355,0,2.392,2.1,4.594,6.173,4.594a10.625,10.625,0,0,0,6.029-1.628Z"
					fill="#132859"
				/>
				<path
					d="M202.262,39.809,191.4,15.787h4.594l5.885,13.83a24.092,24.092,0,0,1,1.293,3.54h.047a26.292,26.292,0,0,1,1.244-3.54l5.791-13.83H214.8L204.079,39.809Z"
					fill="#132859"
				/>
				<path
					d="M217.624,8.467A2.866,2.866,0,0,1,220.591,5.5a2.8,2.8,0,0,1,2.919,2.968,2.842,2.842,0,0,1-2.919,2.918,2.882,2.882,0,0,1-2.967-2.918m.814,7.32H222.7V39.33h-4.259Z"
					fill="#132859"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M35.833,38.622a5.309,5.309,0,0,1,.754.43,4.791,4.791,0,0,1,.742.637c.06.063.2.225.3.186s.062-.214.03-.289a1.748,1.748,0,0,0-.477-.645,3.559,3.559,0,0,0-.5-.337c-.065-.038-.432-.188-.3-.308.062-.055.289,0,.368.011a2.307,2.307,0,0,1,1.289.686c.058.062.132.134.214.111.189-.053-.009-.348-.07-.421a2.694,2.694,0,0,0-.4-.4c-.144-.117-.31-.2-.457-.312a6.153,6.153,0,0,0-1.06-.6,8.7,8.7,0,0,0-1.309-.481,1.4,1.4,0,0,1-.8-.67,1.529,1.529,0,0,0-.363-.418,4.541,4.541,0,0,1-.411-.337q-.417-.353-.831-.7l-.927-.791a6.6,6.6,0,0,1-.742-.653c-.155-.18-.1-.326-.063-.535.024-.139.049-.278.073-.417l-1.539-.149c-.027.169-.054.336-.082.5a.465.465,0,0,0,.188.6q.565.538,1.129,1.081l.161.158a10.552,10.552,0,0,0,1,.98,14.1,14.1,0,0,1,1.288,1.141,1.389,1.389,0,0,1,.28.283c-.053.029-.178-.026-.237-.037-.092-.018-.184-.031-.276-.041a13.244,13.244,0,0,0-2.021-.052c-.046,0-.1.012-.118.055a.081.081,0,0,0,.026.083.224.224,0,0,0,.081.042q.233.087.468.167c.139.046.277.04.419.073.1.025.209.046.313.069.424.095.844.2,1.263.322a14.291,14.291,0,0,1,1.787.618c.27.117.543.23.809.357"
					fill="#132859"
				/>
				<path
					d="M41.838,38.284c.674.132,1.345.269,2.026.378a2.52,2.52,0,0,1,.434.13,3.781,3.781,0,0,0,.423.184c.236.05.064-.328-.018-.415a1.126,1.126,0,0,0-.5-.265,7.629,7.629,0,0,0-.817-.206c-.131-.026-.734-.039-.768-.171-.084-.319.723-.2.857-.181A4.074,4.074,0,0,1,44.929,38c.058.032.132.067.185.027.238-.179-.326-.572-.451-.631a4.684,4.684,0,0,0-.91-.259q-.516-.127-1.029-.27a4.93,4.93,0,0,1-1.355-.437,5.993,5.993,0,0,0-1.165-.62c-.756-.293-1.976-.83-2.245-.952-.556-.253-1.094-.557-1.637-.839l-.98-.508-.432-.225a4.585,4.585,0,0,1-.495-.25c-.222-.151-.155-.406-.166-.644,0-.03-.025-.485-.022-.486l-2.44.2a1.348,1.348,0,0,0,.141.719,1.572,1.572,0,0,0,.69.49c.145.077.293.15.443.221l.257.121c.054.026.16.1.22.1l2.1.992,1.957.923a14.849,14.849,0,0,1,1.413.609c.492.3.953.7,1.424,1.041l-1.652-.042a1.771,1.771,0,0,0-.709.044,1.79,1.79,0,0,0-.35.182c-.029.019-.149.088-.15.126,0,.09.573.124.672.133q.414.036.828.083.826.094,1.648.233c.374.063.745.134,1.115.206"
					fill="#132859"
				/>
				<path
					d="M46.363,20.778a13.1,13.1,0,0,1-.988,3.074,13.871,13.871,0,0,1-4.3,4.928,56.8,56.8,0,0,1-5.935,3.27,5.879,5.879,0,0,1-3.123.439c-.683-.1-1.561-.242-1.561.05s-.391-.195-.781,0-.488.779-.83.438-1.024-1.22-1.9-1.318a13.567,13.567,0,0,0-3.708-.634c-.655.016-1.31.011-1.964,0a23.375,23.375,0,0,0-2.412.03.115.115,0,0,1-.052,0,.038.038,0,0,1-.026-.04c0-.017.019-.027.034-.035.212-.115.425-.226.636-.341.094-.051.2-.1.289-.149a.034.034,0,0,0,.017-.036c-.011-.031-.2-.01-.232-.009-.1,0-.2.006-.3.01-.235.01-.47.025-.7.052a3.052,3.052,0,0,0-.455.08,7.133,7.133,0,0,1-1.415.39c-.2-.049.244-.732.244-.732a4.5,4.5,0,0,1-.976.488c-.195,0,0-.537,0-.537-1.464.391-13.663,6.441-14.59,6.49s-1.757-.146-1.074-.732A67.954,67.954,0,0,1,7.57,31.269c2.326-.931,4.594-2.028,6.876-3.059L15.8,27.6c.4-.18.755-.528,1.149-.664a7.519,7.519,0,0,1,1.9-.093q1-.045,1.994-.1,1.994-.119,3.983-.294,4-.353,7.979-.933c.662-.1,1.323-.228,1.986-.305a6.011,6.011,0,0,0,1.368-.173,8.411,8.411,0,0,0,2.763-1.559,8.228,8.228,0,0,0,1.455-1.6,3.613,3.613,0,0,1,.907-1.062,2.712,2.712,0,0,1,1.2-.34,15.441,15.441,0,0,1,3.872.077Z"
					fill="#265dde"
				/>
				<path
					d="M26.527,20.9s-4.432,2.058-5.164,2.643-3.465,2.293-3.953,2.83-.78.83-.78.83.536.488,2.488,0,5.661-.342,7.32-.488,6.343-.683,7.026-.879,3.465-.341,3.9-.683c.771-.6,1.585-1.136,2.335-1.765.569-.478,2.094-1.589,1.885-2.5-.1-.441-.435-.4-.79-.275-.771.281-1.55.543-2.34.764a14.632,14.632,0,0,1-4.749.613,16.142,16.142,0,0,1-4.233-.921c-.339-.114-.676-.234-1.012-.357a3.206,3.206,0,0,0-.964-.344c-.312.02-.69.395-.973.53"
					fill="#1f49ab"
				/>
				<path
					d="M53.6,11.462a1.884,1.884,0,0,1-.261.1,8.508,8.508,0,0,1-2.759.241c-.647.006-1.3.039-1.949,0-.356-.022-.479.425-.629.658a5.362,5.362,0,0,1-.759.854c-.488.488-.538,1.465-.685,2.685a13.908,13.908,0,0,0,.147,2.927,3.967,3.967,0,0,1-.341,1.853c-.147.391-3.124.488-4.2.294a6.549,6.549,0,0,0-3.929.977c-.685.585-3,.047-5.78,0a9.924,9.924,0,0,1-5.026-1.659,15.772,15.772,0,0,0,1.756-.88c1.026-.585,4.588-2.732,5.809-3.612a16.731,16.731,0,0,0,3.147-3.732,4.492,4.492,0,0,0,.6-2.214,14.5,14.5,0,0,0,.015-2.273,12.7,12.7,0,0,0-1.234-3.584,13.723,13.723,0,0,1-.578-1.815,6.139,6.139,0,0,1-.145-.954c-.021-.293.027-.423.339-.257a3.539,3.539,0,0,0,.636.326,3.57,3.57,0,0,0-.362-.622c-.04-.081-.291-.438-.14-.524.051-.029.575.3.585.262C37.9.368,37.808.03,38.03,0a.444.444,0,0,1,.3.123l.878.653A16.423,16.423,0,0,0,40.878,1.9l.422.241c.1.055.275.21.384.22.275.024.108-.461.419-.16,1.3,1.258,2.581,2.545,3.9,3.793,1,.95,2.291,1.559,2.724,2.121s.3.926.3.926l.782.391,2.1.83a7.369,7.369,0,0,1,1.709.659.338.338,0,0,1,.12.408.291.291,0,0,1-.131.133"
					fill="#132859"
				/>
			</g>
		</g>
	</svg>
);

export const Logo30 = (
	<svg xmlns="http://www.w3.org/2000/svg" width="156.474" height="30" viewBox="0 0 156.474 30">
		<g>
			<path
				d="M52.292,26.72V2.838h4.952c4.859,0,9.212,1.672,9.212,7.067,0,4.575-2.9,7.761-9.18,7.761H55.32V26.72Zm4.921-11.736c3.849,0,6.088-1.956,6.088-5.047,0-2.934-2.208-4.386-5.268-4.386H55.32v9.433Z"
				fill="#132859"
			/>
			<path
				d="M69.456,26.72V11.2h2.776v2.335c.221-.757,1.546-2.713,3.344-2.713a4.935,4.935,0,0,1,2.65.726l-1.1,2.618A3.29,3.29,0,0,0,75.2,13.47c-1.8,0-2.871,2.051-2.966,3.407V26.72Z"
				fill="#132859"
			/>
			<path
				d="M93.875,24.8c-.883,1.167-3.344,2.366-6.53,2.366a7.742,7.742,0,0,1-8.14-8.108c0-4.827,3.25-8.266,7.982-8.266,4.859,0,7.161,3.818,7.161,7.919v.82H82.013a5.825,5.825,0,0,0,.1.947c.568,3.123,3.06,4.385,5.836,4.385A6.75,6.75,0,0,0,92.9,22.935Zm-2.334-7.572c-.41-2.366-1.7-4.164-4.417-4.164a4.876,4.876,0,0,0-5.016,4.164Z"
				fill="#132859"
			/>
			<path
				d="M97.5,23.029a6.052,6.052,0,0,0,4.29,1.957c1.515,0,2.84-.758,2.84-2.556,0-2.871-7.162-3.123-7.162-7.351,0-2.335,1.956-4.322,4.985-4.322a7.057,7.057,0,0,1,4.827,1.8l-1.105,1.956a5.247,5.247,0,0,0-3.375-1.577c-1.641,0-2.429.978-2.429,1.956,0,3,7.129,2.682,7.129,7.477,0,2.934-2.3,4.8-5.584,4.8a8.305,8.305,0,0,1-5.646-2.145Z"
				fill="#132859"
			/>
			<path
				d="M112.208,26.72V13.533h-2.871v-2.3h2.871v-5.4h2.776v5.4H118.8v2.3h-3.817V26.72Z"
				fill="#132859"
			/>
			<path
				d="M133.473,25.08a10.449,10.449,0,0,1-6.846,2.082c-4.638,0-6.752-2.682-6.752-5.616,0-3.533,3.6-5.016,6.4-5.016a6.966,6.966,0,0,1,4.511,1.515V16.12c0-1.7-1.294-2.966-3.849-2.966a11.222,11.222,0,0,0-4.322.915l-.915-2.081a12.868,12.868,0,0,1,5.395-1.2c5.079,0,6.373,2.4,6.373,5.993Zm-2.682-4.606a6.327,6.327,0,0,0-4.133-1.577c-2.618,0-3.912,1.356-3.912,2.87,0,1.578,1.388,3.029,4.07,3.029a7,7,0,0,0,3.975-1.073Z"
				fill="#132859"
			/>
			<path
				d="M142.465,27.036,135.3,11.2h3.028l3.881,9.118a15.978,15.978,0,0,1,.852,2.334h.031a17.342,17.342,0,0,1,.82-2.334l3.818-9.118h3l-7.066,15.838Z"
				fill="#132859"
			/>
			<path
				d="M152.594,6.372a1.89,1.89,0,0,1,1.956-1.957,1.847,1.847,0,0,1,1.924,1.957A1.873,1.873,0,0,1,154.55,8.3a1.9,1.9,0,0,1-1.956-1.924m.536,4.827h2.808V26.721H153.13Z"
				fill="#132859"
			/>
			<path
				d="M26.955,29.053a3.932,3.932,0,0,1,.567.324,3.591,3.591,0,0,1,.558.479c.045.047.149.17.227.139s.047-.161.023-.216a1.318,1.318,0,0,0-.359-.486,2.6,2.6,0,0,0-.379-.253c-.049-.029-.325-.142-.224-.232.046-.042.217,0,.277.008a1.729,1.729,0,0,1,.969.516c.044.047.1.1.162.084.142-.04-.008-.262-.053-.317a2.073,2.073,0,0,0-.305-.3c-.108-.088-.233-.151-.344-.233a4.555,4.555,0,0,0-.8-.449,6.491,6.491,0,0,0-.985-.362,1.052,1.052,0,0,1-.6-.5,1.123,1.123,0,0,0-.273-.313,3.767,3.767,0,0,1-.309-.255q-.314-.264-.625-.53l-.7-.594a5.025,5.025,0,0,1-.558-.491c-.117-.136-.074-.246-.047-.4l.054-.314-1.157-.111c-.02.126-.041.253-.061.379a.528.528,0,0,0-.016.255.52.52,0,0,0,.157.195l.849.813.121.119a8.078,8.078,0,0,0,.755.737,10.838,10.838,0,0,1,.969.858,1.119,1.119,0,0,1,.211.213c-.04.022-.134-.019-.179-.028-.068-.013-.138-.023-.207-.03a10.151,10.151,0,0,0-1.52-.039c-.035,0-.077.009-.089.042a.061.061,0,0,0,.02.062.19.19,0,0,0,.06.031c.117.044.235.085.353.125s.208.03.314.056l.236.052c.319.071.635.153.95.241a10.782,10.782,0,0,1,1.344.466c.2.087.409.172.609.268"
				fill="#132859"
			/>
			<path
				d="M31.473,28.8c.507.1,1.012.2,1.524.284a1.982,1.982,0,0,1,.327.1,2.689,2.689,0,0,0,.317.138c.178.038.049-.246-.013-.312a.849.849,0,0,0-.376-.2,5.687,5.687,0,0,0-.614-.155c-.1-.019-.552-.029-.578-.128-.063-.241.543-.151.645-.137a3.094,3.094,0,0,1,1.093.195c.044.024.1.051.139.021.179-.135-.245-.431-.339-.476a3.606,3.606,0,0,0-.684-.194q-.39-.1-.775-.2a3.668,3.668,0,0,1-1.018-.329,4.492,4.492,0,0,0-.877-.466c-.569-.221-1.487-.624-1.689-.716-.418-.191-.823-.42-1.231-.631l-.738-.383-.325-.169a3.5,3.5,0,0,1-.372-.188c-.167-.114-.116-.305-.125-.485L25.747,24l-1.835.147a1.02,1.02,0,0,0,.106.542,1.189,1.189,0,0,0,.519.368c.109.058.221.113.334.166l.193.091c.041.02.12.078.165.078l1.583.747,1.472.694a11.12,11.12,0,0,1,1.063.458c.37.225.717.528,1.071.783l-1.242-.031a1.327,1.327,0,0,0-.534.033,1.311,1.311,0,0,0-.263.136c-.021.015-.112.067-.113.1,0,.067.431.092.5.1q.312.027.623.063.623.071,1.24.175c.281.048.56.1.839.155"
				fill="#132859"
			/>
			<path
				d="M34.876,15.63a9.892,9.892,0,0,1-.743,2.312A10.436,10.436,0,0,1,30.9,21.65a42.794,42.794,0,0,1-4.464,2.459,4.407,4.407,0,0,1-2.35.33c-.513-.073-1.174-.182-1.174.038s-.294-.146-.587,0-.368.586-.624.33-.771-.918-1.432-.992a10.25,10.25,0,0,0-2.79-.477c-.492.012-.985.009-1.477,0a17.416,17.416,0,0,0-1.814.023.085.085,0,0,1-.039,0,.03.03,0,0,1-.021-.03c0-.013.015-.02.026-.026.16-.087.32-.17.479-.257.07-.038.149-.071.217-.112a.025.025,0,0,0,.013-.027c-.008-.024-.151-.007-.175-.007-.075,0-.151,0-.226.008-.178.007-.355.019-.531.039a2.218,2.218,0,0,0-.343.06,5.4,5.4,0,0,1-1.064.294c-.147-.037.184-.551.184-.551a3.389,3.389,0,0,1-.735.367c-.146,0,0-.4,0-.4C10.87,23.008,1.694,27.559,1,27.6s-1.321-.11-.807-.55a50.829,50.829,0,0,1,5.5-3.524c1.75-.7,3.456-1.525,5.173-2.3l1.014-.458c.3-.136.568-.4.865-.5a5.636,5.636,0,0,1,1.426-.071q.75-.034,1.5-.078,1.5-.09,3-.222,3.01-.264,6-.7c.5-.072.994-.171,1.494-.229a4.5,4.5,0,0,0,1.028-.13,6.351,6.351,0,0,0,2.079-1.173,6.2,6.2,0,0,0,1.1-1.2,2.7,2.7,0,0,1,.681-.8,2.045,2.045,0,0,1,.9-.256,11.641,11.641,0,0,1,2.913.058Z"
				fill="#265dde"
			/>
			<path
				d="M19.955,15.722S16.621,17.27,16.07,17.71s-2.606,1.725-2.973,2.129-.587.624-.587.624.4.367,1.872,0a41.98,41.98,0,0,1,5.506-.367c1.248-.11,4.771-.514,5.285-.661a21.179,21.179,0,0,0,2.937-.513c.58-.452,1.192-.855,1.757-1.328.428-.36,1.574-1.195,1.418-1.879-.076-.332-.327-.3-.595-.207-.58.211-1.165.408-1.76.574a11.024,11.024,0,0,1-3.572.462,12.174,12.174,0,0,1-3.185-.693c-.255-.086-.508-.177-.761-.269a2.4,2.4,0,0,0-.725-.258c-.235.014-.519.3-.732.4"
				fill="#1f49ab"
			/>
			<path
				d="M40.32,8.622a1.427,1.427,0,0,1-.2.071,6.368,6.368,0,0,1-2.076.181c-.486.005-.98.03-1.466,0-.268-.017-.36.32-.473.495a4.028,4.028,0,0,1-.571.643c-.367.367-.4,1.1-.515,2.02a10.436,10.436,0,0,0,.11,2.2,2.972,2.972,0,0,1-.257,1.394c-.11.294-2.349.367-3.157.221a4.935,4.935,0,0,0-2.956.735c-.515.44-2.257.035-4.348,0a7.469,7.469,0,0,1-3.781-1.248,11.875,11.875,0,0,0,1.321-.661c.772-.441,3.452-2.056,4.37-2.717a12.586,12.586,0,0,0,2.367-2.808,3.38,3.38,0,0,0,.455-1.666,10.968,10.968,0,0,0,.011-1.71,9.557,9.557,0,0,0-.928-2.7A10.363,10.363,0,0,1,27.8,1.714,4.564,4.564,0,0,1,27.686,1c-.016-.221.02-.319.255-.194a2.666,2.666,0,0,0,.478.245,2.6,2.6,0,0,0-.272-.467c-.03-.061-.219-.33-.1-.395.038-.022.432.225.44.2.028-.107-.041-.362.126-.383a.336.336,0,0,1,.227.092L29.5.585a12.506,12.506,0,0,0,1.255.844l.317.181c.072.042.207.158.289.166.207.018.081-.347.315-.121.981.947,1.942,1.915,2.93,2.854.752.714,1.724,1.172,2.049,1.6s.226.7.226.7l.588.294,1.578.624a5.544,5.544,0,0,1,1.285.5.255.255,0,0,1,.091.307.218.218,0,0,1-.1.1"
				fill="#132859"
			/>
		</g>
	</svg>
);

export const Logo25 = (
	<svg xmlns="http://www.w3.org/2000/svg" width="139.716" height="25" viewBox="0 0 139.716 25">
		<g>
			<path
				d="M40.954,24.582V1.942h4.695c4.605,0,8.732,1.585,8.732,6.7,0,4.337-2.751,7.357-8.7,7.357H43.825v8.584Zm4.665-11.126c3.648,0,5.772-1.854,5.772-4.785,0-2.781-2.094-4.157-4.995-4.157H43.825v8.942Z"
				fill="#132859"
			/>
			<path
				d="M57.225,24.581V9.867h2.632V12.08c.209-.718,1.465-2.572,3.17-2.572a4.682,4.682,0,0,1,2.512.688l-1.047,2.482a3.122,3.122,0,0,0-1.824-.658c-1.7,0-2.722,1.944-2.811,3.23v9.331Z"
				fill="#132859"
			/>
			<path
				d="M80.374,22.757C79.537,23.863,77.2,25,74.183,25a7.339,7.339,0,0,1-7.716-7.686c0-4.576,3.08-7.836,7.567-7.836,4.605,0,6.788,3.619,6.788,7.507v.777H69.129a5.581,5.581,0,0,0,.09.9c.538,2.96,2.9,4.156,5.533,4.156a6.393,6.393,0,0,0,4.695-1.824Zm-2.213-7.178c-.389-2.243-1.615-3.947-4.187-3.947a4.622,4.622,0,0,0-4.755,3.947Z"
				fill="#132859"
			/>
			<path
				d="M83.815,21.082a5.736,5.736,0,0,0,4.067,1.855c1.436,0,2.692-.718,2.692-2.423,0-2.721-6.789-2.961-6.789-6.968,0-2.213,1.854-4.1,4.725-4.1a6.688,6.688,0,0,1,4.576,1.7l-1.047,1.854a4.978,4.978,0,0,0-3.2-1.495c-1.555,0-2.3.927-2.3,1.855,0,2.84,6.759,2.542,6.759,7.087C93.295,23.235,91.112,25,88,25a7.879,7.879,0,0,1-5.353-2.033Z"
				fill="#132859"
			/>
			<path
				d="M97.753,24.581V12.08H95.032V9.9h2.721V4.783h2.632V9.9H104V12.08h-3.618v12.5Z"
				fill="#132859"
			/>
			<path
				d="M117.911,23.026A9.9,9.9,0,0,1,111.421,25c-4.4,0-6.4-2.542-6.4-5.323,0-3.35,3.41-4.756,6.071-4.756a6.606,6.606,0,0,1,4.277,1.436V14.533c0-1.615-1.226-2.812-3.649-2.812a10.643,10.643,0,0,0-4.1.867l-.867-1.973a12.213,12.213,0,0,1,5.114-1.136c4.815,0,6.041,2.272,6.041,5.682Zm-2.542-4.366a6,6,0,0,0-3.918-1.495c-2.482,0-3.708,1.286-3.708,2.721,0,1.5,1.316,2.871,3.858,2.871a6.637,6.637,0,0,0,3.768-1.017Z"
				fill="#132859"
			/>
			<path
				d="M126.436,24.881,119.647,9.867h2.871l3.679,8.644A15.057,15.057,0,0,1,127,20.724h.03a16.37,16.37,0,0,1,.778-2.213l3.618-8.644h2.841l-6.7,15.014Z"
				fill="#132859"
			/>
			<path
				d="M136.038,5.292a1.791,1.791,0,0,1,1.854-1.855,1.751,1.751,0,0,1,1.824,1.855,1.777,1.777,0,0,1-1.824,1.824,1.8,1.8,0,0,1-1.854-1.824m.508,4.575h2.662V24.581h-2.662Z"
				fill="#132859"
			/>
			<path
				d="M22.4,24.138a3.3,3.3,0,0,1,.472.269,3.079,3.079,0,0,1,.463.4c.037.039.123.141.188.116s.039-.134.019-.18a1.092,1.092,0,0,0-.3-.4,2.157,2.157,0,0,0-.315-.21c-.041-.024-.269-.118-.186-.193.039-.034.181,0,.23.007a1.455,1.455,0,0,1,.806.429c.036.039.082.084.134.069.118-.033-.006-.217-.044-.263a1.715,1.715,0,0,0-.253-.251c-.091-.073-.194-.125-.286-.194a3.845,3.845,0,0,0-.663-.372,5.378,5.378,0,0,0-.818-.3.879.879,0,0,1-.5-.419.938.938,0,0,0-.226-.26,3.042,3.042,0,0,1-.257-.212q-.261-.219-.52-.44l-.579-.494a4.186,4.186,0,0,1-.463-.407c-.1-.113-.062-.2-.04-.336l.045-.26-.961-.093-.051.316a.433.433,0,0,0-.013.211.445.445,0,0,0,.13.162l.706.676.1.1a6.6,6.6,0,0,0,.628.613,8.939,8.939,0,0,1,.8.713.868.868,0,0,1,.176.177c-.033.018-.112-.016-.149-.023-.057-.011-.114-.019-.172-.025a8.322,8.322,0,0,0-1.263-.033c-.029,0-.064.008-.074.035a.051.051,0,0,0,.017.051.151.151,0,0,0,.05.027c.1.036.2.07.293.1a2.544,2.544,0,0,0,.261.046c.065.016.131.029.2.044.265.059.528.127.789.2a9.1,9.1,0,0,1,1.117.387c.169.073.34.144.506.223"
				fill="#132859"
			/>
			<path
				d="M26.149,23.927c.421.082.841.168,1.266.236a1.7,1.7,0,0,1,.272.081,2.129,2.129,0,0,0,.264.115c.148.032.04-.2-.012-.259a.688.688,0,0,0-.312-.165,4.533,4.533,0,0,0-.51-.129c-.082-.016-.459-.025-.481-.107-.052-.2.452-.125.536-.113a2.525,2.525,0,0,1,.909.162c.036.02.083.042.116.017.148-.112-.2-.358-.282-.395a2.956,2.956,0,0,0-.569-.162c-.215-.053-.43-.109-.643-.168a3.09,3.09,0,0,1-.847-.274,3.733,3.733,0,0,0-.728-.387c-.473-.183-1.235-.519-1.4-.595-.348-.158-.684-.348-1.024-.524l-.612-.318-.27-.141a2.807,2.807,0,0,1-.31-.156c-.138-.094-.1-.254-.1-.4,0-.018-.016-.3-.014-.3l-1.525.122a.839.839,0,0,0,.088.45.985.985,0,0,0,.431.306c.091.048.184.094.277.138l.161.076c.034.016.1.065.137.065l1.316.62,1.223.577a9.315,9.315,0,0,1,.883.38c.307.187.6.439.89.651L24.24,23.3a.827.827,0,0,0-.661.141c-.018.012-.094.055-.094.079,0,.056.358.077.419.082.173.016.346.033.518.053.344.039.688.088,1.03.145.234.04.466.084.7.129"
				fill="#132859"
			/>
			<path
				d="M28.977,12.986a8.165,8.165,0,0,1-.618,1.921,8.665,8.665,0,0,1-2.687,3.081,35.825,35.825,0,0,1-3.709,2.043,3.671,3.671,0,0,1-1.952.274c-.427-.061-.976-.151-.976.031s-.244-.121-.488,0-.305.488-.518.274-.641-.762-1.19-.823a8.485,8.485,0,0,0-2.318-.4c-.409.01-.818.007-1.227,0a14.29,14.29,0,0,0-1.507.019.073.073,0,0,1-.033,0,.026.026,0,0,1-.017-.026c0-.01.013-.016.022-.021.132-.072.265-.141.4-.213.059-.032.125-.06.181-.093a.02.02,0,0,0,.01-.023c-.006-.02-.125-.006-.145-.006l-.188.007c-.147.006-.294.015-.44.032a1.953,1.953,0,0,0-.285.05,4.534,4.534,0,0,1-.885.244c-.122-.031.153-.458.153-.458a2.811,2.811,0,0,1-.61.305c-.122,0,0-.335,0-.335-.915.244-8.539,4.026-9.119,4.056s-1.1-.091-.671-.457a42.292,42.292,0,0,1,4.575-2.928c1.453-.582,2.871-1.267,4.3-1.912l.843-.381c.249-.113.472-.33.718-.415a4.719,4.719,0,0,1,1.185-.059q.622-.027,1.246-.065,1.247-.073,2.49-.184,2.5-.219,4.987-.583c.413-.06.826-.142,1.241-.19a3.836,3.836,0,0,0,.855-.108,5.3,5.3,0,0,0,1.727-.975,5.147,5.147,0,0,0,.909-1,2.259,2.259,0,0,1,.567-.663,1.686,1.686,0,0,1,.75-.213,9.7,9.7,0,0,1,2.42.048Z"
				fill="#265dde"
			/>
			<path
				d="M16.58,13.062s-2.771,1.286-3.229,1.652-2.165,1.433-2.469,1.769-.488.518-.488.518.335.305,1.556,0a34.927,34.927,0,0,1,4.573-.305c1.037-.091,3.965-.427,4.392-.549a17.459,17.459,0,0,0,2.439-.427c.483-.374.991-.71,1.46-1.1.356-.3,1.308-.993,1.179-1.561-.063-.276-.273-.253-.5-.172-.481.175-.967.339-1.462.477a9.152,9.152,0,0,1-2.968.384,10.146,10.146,0,0,1-2.646-.576c-.212-.071-.422-.147-.633-.223a1.982,1.982,0,0,0-.6-.215c-.2.012-.432.247-.608.331"
				fill="#1f49ab"
			/>
			<path
				d="M33.5,7.164a1.088,1.088,0,0,1-.163.059,5.306,5.306,0,0,1-1.725.151c-.4,0-.814.024-1.218,0-.222-.014-.3.266-.393.412a3.357,3.357,0,0,1-.474.533c-.3.305-.336.916-.428,1.679a8.646,8.646,0,0,0,.092,1.829,2.479,2.479,0,0,1-.214,1.158c-.092.244-1.952.305-2.623.184a4.1,4.1,0,0,0-2.456.61c-.428.366-1.875.029-3.612,0a6.2,6.2,0,0,1-3.142-1.037,9.971,9.971,0,0,0,1.1-.549c.641-.366,2.867-1.708,3.63-2.258A10.44,10.44,0,0,0,23.838,7.6a2.8,2.8,0,0,0,.378-1.383A9.111,9.111,0,0,0,24.225,4.8a7.924,7.924,0,0,0-.771-2.24,8.538,8.538,0,0,1-.361-1.134A3.877,3.877,0,0,1,23,.828c-.013-.183.017-.264.212-.16a2.221,2.221,0,0,0,.4.2,2.263,2.263,0,0,0-.225-.388C23.36.432,23.2.209,23.3.155c.031-.018.359.187.365.164.024-.088-.033-.3.1-.318a.285.285,0,0,1,.189.077l.549.409a10.213,10.213,0,0,0,1.043.7l.263.15c.06.035.172.132.24.138.172.015.068-.288.262-.1.815.786,1.613,1.59,2.434,2.371.625.593,1.432.974,1.7,1.325s.187.579.187.579l.489.245,1.311.518a4.6,4.6,0,0,1,1.068.412.212.212,0,0,1,.075.255.178.178,0,0,1-.082.083"
				fill="#132859"
			/>
		</g>
	</svg>
);

export const LogoHeader = (
	<svg xmlns="http://www.w3.org/2000/svg" width="122.307" height="25" viewBox="0 0 122.307 25">
		<g>
			<path
				d="M43.3,21.388V3.277h3.756c3.684,0,6.986,1.268,6.986,5.359,0,3.47-2.2,5.886-6.962,5.886H45.6v6.866Zm3.732-8.9c2.919,0,4.617-1.483,4.617-3.828,0-2.225-1.674-3.326-3.995-3.326H45.6v7.154Z"
				fill="#132859"
			/>
			<path
				d="M56.314,21.388V9.617h2.1v1.77A3.147,3.147,0,0,1,60.955,9.33a3.748,3.748,0,0,1,2.01.55l-.837,1.986a2.493,2.493,0,0,0-1.46-.527c-1.363,0-2.177,1.556-2.249,2.584v7.465Z"
				fill="#132859"
			/>
			<path
				d="M74.833,19.93c-.669.884-2.536,1.794-4.952,1.794a5.872,5.872,0,0,1-6.173-6.149,5.9,5.9,0,0,1,6.053-6.269c3.685,0,5.431,2.9,5.431,6.006v.622H65.837a4.462,4.462,0,0,0,.072.718c.431,2.368,2.321,3.325,4.426,3.325a5.123,5.123,0,0,0,3.757-1.459Zm-1.77-5.743c-.311-1.794-1.292-3.158-3.35-3.158a3.7,3.7,0,0,0-3.8,3.158Z"
				fill="#132859"
			/>
			<path
				d="M77.586,18.589a4.587,4.587,0,0,0,3.254,1.484,1.9,1.9,0,0,0,2.153-1.938c0-2.177-5.431-2.369-5.431-5.575,0-1.771,1.483-3.278,3.78-3.278A5.349,5.349,0,0,1,85,10.646l-.838,1.483a3.977,3.977,0,0,0-2.56-1.2c-1.244,0-1.842.742-1.842,1.484,0,2.272,5.407,2.033,5.407,5.67,0,2.225-1.746,3.636-4.235,3.636A6.3,6.3,0,0,1,76.653,20.1Z"
				fill="#132859"
			/>
			<path
				d="M88.737,21.388v-10H86.56V9.641h2.177V5.549h2.1V9.641h2.9v1.746h-2.9v10Z"
				fill="#132859"
			/>
			<path
				d="M104.863,20.144a7.925,7.925,0,0,1-5.192,1.579c-3.517,0-5.12-2.034-5.12-4.259,0-2.679,2.727-3.8,4.857-3.8a5.284,5.284,0,0,1,3.421,1.149v-1.46c0-1.292-.981-2.249-2.919-2.249a8.5,8.5,0,0,0-3.278.694l-.693-1.579a9.757,9.757,0,0,1,4.09-.909c3.853,0,4.834,1.818,4.834,4.545Zm-2.034-3.493a4.8,4.8,0,0,0-3.134-1.2c-1.986,0-2.967,1.029-2.967,2.177,0,1.2,1.053,2.3,3.086,2.3a5.314,5.314,0,0,0,3.015-.814Z"
				fill="#132859"
			/>
			<path
				d="M111.683,21.628,106.252,9.617h2.3l2.943,6.915a12.159,12.159,0,0,1,.646,1.77h.023a13.138,13.138,0,0,1,.623-1.77l2.9-6.915h2.272l-5.359,12.011Z"
				fill="#132859"
			/>
			<path
				d="M119.365,5.957a1.432,1.432,0,0,1,1.483-1.484,1.4,1.4,0,0,1,1.459,1.484,1.421,1.421,0,0,1-1.459,1.459,1.44,1.44,0,0,1-1.483-1.459m.406,3.66H121.9V21.388h-2.129Z"
				fill="#132859"
			/>
			<path
				d="M22.463,24.211a3.259,3.259,0,0,1,.473.27,3,3,0,0,1,.464.4c.038.039.124.142.189.116s.04-.134.02-.18a1.1,1.1,0,0,0-.3-.405,2.229,2.229,0,0,0-.316-.211c-.041-.024-.271-.118-.187-.193.039-.035.181,0,.231.006a1.448,1.448,0,0,1,.808.431c.036.039.083.084.134.069.119-.033-.006-.218-.044-.264A1.766,1.766,0,0,0,23.683,24c-.091-.073-.195-.126-.287-.195a3.8,3.8,0,0,0-.665-.373,5.339,5.339,0,0,0-.82-.3.878.878,0,0,1-.5-.42.916.916,0,0,0-.227-.261,3.276,3.276,0,0,1-.258-.213c-.174-.146-.347-.293-.521-.441l-.581-.5a4.2,4.2,0,0,1-.465-.408c-.1-.114-.062-.206-.039-.337l.045-.261L18.4,20.2c-.017.106-.034.211-.052.316a.454.454,0,0,0-.013.213.447.447,0,0,0,.131.162l.708.678.1.1a6.626,6.626,0,0,0,.629.614,9,9,0,0,1,.807.715.9.9,0,0,1,.176.178c-.033.018-.112-.016-.149-.023a1.676,1.676,0,0,0-.173-.025,8.233,8.233,0,0,0-1.266-.033c-.029,0-.065.007-.074.035a.048.048,0,0,0,.016.051.151.151,0,0,0,.05.027c.1.036.2.07.294.1s.174.025.263.046l.2.043c.266.06.529.128.791.2a8.892,8.892,0,0,1,1.121.388c.169.072.34.143.507.223"
				fill="#132859"
			/>
			<path
				d="M26.227,24c.422.082.843.169,1.27.237a1.6,1.6,0,0,1,.272.081,2.39,2.39,0,0,0,.265.116c.148.031.04-.206-.011-.261a.7.7,0,0,0-.314-.165,4.78,4.78,0,0,0-.511-.13c-.083-.016-.461-.024-.482-.107-.053-.2.453-.125.537-.113a2.545,2.545,0,0,1,.912.162c.036.02.083.042.116.017.149-.112-.2-.359-.283-.4a2.935,2.935,0,0,0-.571-.162c-.215-.053-.431-.11-.645-.169a3.07,3.07,0,0,1-.849-.274,3.729,3.729,0,0,0-.73-.389c-.474-.183-1.239-.52-1.407-.6-.349-.159-.687-.35-1.027-.526L22.155,21l-.271-.141a3.121,3.121,0,0,1-.311-.157c-.139-.1-.1-.254-.1-.4,0-.019-.016-.3-.014-.3l-1.53.122a.847.847,0,0,0,.089.452.984.984,0,0,0,.432.307c.091.048.184.094.278.138l.161.076c.034.016.1.065.138.065l1.319.622,1.227.579a9.13,9.13,0,0,1,.886.382c.308.187.6.439.892.652l-1.036-.026a.833.833,0,0,0-.663.141c-.018.013-.094.056-.094.08,0,.056.359.077.421.083.173.015.346.032.519.052.345.039.69.088,1.033.146.234.039.467.084.7.129"
				fill="#132859"
			/>
			<path
				d="M29.063,13.025a8.242,8.242,0,0,1-.619,1.927,8.7,8.7,0,0,1-2.7,3.089,35.714,35.714,0,0,1-3.72,2.05,3.685,3.685,0,0,1-1.958.275c-.428-.061-.979-.152-.979.031s-.245-.122-.489,0-.306.489-.52.275-.643-.765-1.193-.826a8.5,8.5,0,0,0-2.325-.4c-.41.01-.821.007-1.231,0a14.384,14.384,0,0,0-1.512.019.069.069,0,0,1-.032,0,.024.024,0,0,1-.017-.025c0-.011.012-.017.021-.022.133-.072.267-.142.4-.214.059-.032.125-.06.181-.093a.022.022,0,0,0,.011-.023c-.007-.02-.126-.006-.146-.006l-.189.007c-.147.006-.3.015-.442.032a1.977,1.977,0,0,0-.285.05,4.44,4.44,0,0,1-.887.245c-.123-.031.153-.459.153-.459a2.83,2.83,0,0,1-.612.306c-.123,0,0-.336,0-.336C9.058,19.173,1.411,22.966.83,23s-1.1-.092-.673-.459A42.434,42.434,0,0,1,4.745,19.6c1.458-.584,2.88-1.271,4.31-1.918L9.9,17.3c.25-.112.473-.33.72-.415a4.7,4.7,0,0,1,1.188-.059q.625-.028,1.251-.066,1.249-.073,2.5-.184,2.51-.22,5-.585c.415-.061.83-.143,1.245-.191a3.8,3.8,0,0,0,.858-.108,5.3,5.3,0,0,0,1.732-.978,5.15,5.15,0,0,0,.912-1,2.271,2.271,0,0,1,.568-.665,1.7,1.7,0,0,1,.753-.213,9.664,9.664,0,0,1,2.427.048Z"
				fill="#265dde"
			/>
			<path
				d="M16.629,13.1s-2.778,1.29-3.237,1.658S11.22,16.2,10.914,16.533s-.489.519-.489.519.336.306,1.56,0a35.171,35.171,0,0,1,4.588-.305c1.04-.092,3.976-.429,4.405-.551a17.746,17.746,0,0,0,2.447-.429c.483-.376.994-.711,1.464-1.106.356-.3,1.312-1,1.181-1.566-.063-.276-.272-.253-.495-.173-.483.177-.971.342-1.467.479a9.122,9.122,0,0,1-2.977.385,10.16,10.16,0,0,1-2.653-.578c-.213-.071-.424-.147-.635-.224a2.017,2.017,0,0,0-.6-.215c-.2.012-.433.248-.61.332"
				fill="#1f49ab"
			/>
			<path
				d="M33.6,7.185a1.1,1.1,0,0,1-.164.059,5.276,5.276,0,0,1-1.729.151c-.406,0-.817.025-1.222,0-.223-.014-.3.266-.394.412a3.39,3.39,0,0,1-.476.536c-.306.306-.337.918-.43,1.683a8.7,8.7,0,0,0,.093,1.835,2.487,2.487,0,0,1-.214,1.161c-.092.246-1.958.306-2.631.185a4.107,4.107,0,0,0-2.464.612c-.429.367-1.88.029-3.623,0a6.229,6.229,0,0,1-3.151-1.04,9.872,9.872,0,0,0,1.1-.551c.643-.367,2.876-1.713,3.641-2.265A10.449,10.449,0,0,0,23.91,7.626a2.809,2.809,0,0,0,.379-1.388,9.288,9.288,0,0,0,.01-1.425,7.974,7.974,0,0,0-.774-2.247,8.584,8.584,0,0,1-.362-1.137,3.924,3.924,0,0,1-.092-.6c-.012-.183.017-.265.213-.161a2.281,2.281,0,0,0,.4.2,2.255,2.255,0,0,0-.227-.39c-.025-.051-.182-.275-.088-.329.032-.018.361.188.367.165.023-.089-.034-.3.1-.319a.276.276,0,0,1,.189.077l.551.41a10.272,10.272,0,0,0,1.046.7l.264.151c.06.034.172.132.241.138.172.015.068-.289.263-.1.817.789,1.617,1.6,2.441,2.378.627.6,1.436.978,1.707,1.33s.189.581.189.581l.49.245,1.315.52a4.6,4.6,0,0,1,1.071.413.212.212,0,0,1,.075.256.178.178,0,0,1-.082.083"
				fill="#132859"
			/>
		</g>
	</svg>
);

export const Mark50 = (
	<svg xmlns="http://www.w3.org/2000/svg" width="67.401" height="50" viewBox="0 0 67.401 50">
		<g>
			<path
				d="M44.925,48.421a6.76,6.76,0,0,1,.946.539,6.151,6.151,0,0,1,.929.8c.075.079.248.283.378.233s.078-.269.038-.362a2.214,2.214,0,0,0-.6-.81,4.385,4.385,0,0,0-.631-.421c-.082-.049-.541-.237-.374-.386.078-.07.362,0,.461.013a2.9,2.9,0,0,1,.591.166,2.861,2.861,0,0,1,1.025.695c.073.077.167.167.269.139.238-.067-.012-.436-.087-.529a3.552,3.552,0,0,0-.508-.5c-.181-.146-.389-.251-.573-.389a7.609,7.609,0,0,0-1.33-.747,10.85,10.85,0,0,0-1.64-.6,1.752,1.752,0,0,1-1-.84,1.876,1.876,0,0,0-.454-.522,6.133,6.133,0,0,1-.515-.424q-.523-.439-1.042-.883l-1.163-.991a8.236,8.236,0,0,1-.929-.818c-.195-.226-.125-.41-.079-.672l.09-.523L36.8,40.4l-.1.632a.888.888,0,0,0-.026.424.878.878,0,0,0,.262.325l1.415,1.356c.067.066.135.131.2.2a13.283,13.283,0,0,0,1.259,1.23,17.691,17.691,0,0,1,1.614,1.43,1.739,1.739,0,0,1,.352.355c-.066.037-.224-.033-.3-.047-.114-.022-.229-.038-.345-.05a16.743,16.743,0,0,0-2.533-.066c-.059,0-.129.015-.149.07a.1.1,0,0,0,.033.1.288.288,0,0,0,.1.053q.292.109.588.208c.173.059.347.051.524.093.13.031.262.057.393.086.531.119,1.058.256,1.583.4a18.078,18.078,0,0,1,2.24.776c.34.145.682.288,1.015.447"
				fill="#132859"
			/>
			<path
				d="M52.454,48c.845.165,1.686.337,2.541.473a3.37,3.37,0,0,1,.544.164,4.427,4.427,0,0,0,.529.23c.3.064.081-.41-.022-.52a1.42,1.42,0,0,0-.627-.332,9.467,9.467,0,0,0-1.024-.258c-.165-.033-.92-.049-.963-.214-.1-.4.906-.251,1.075-.228a5.121,5.121,0,0,1,1.822.326c.073.039.166.084.232.034.3-.226-.409-.718-.565-.793a5.994,5.994,0,0,0-1.141-.324q-.648-.16-1.29-.338a6.13,6.13,0,0,1-1.7-.549,7.553,7.553,0,0,0-1.462-.777c-.948-.367-2.477-1.04-2.814-1.193-.7-.317-1.372-.7-2.053-1.051l-1.229-.638-.541-.282a6.11,6.11,0,0,1-.621-.313c-.278-.19-.194-.509-.207-.808,0-.038-.032-.608-.029-.609l-3.059.245a1.683,1.683,0,0,0,.178.9,1.971,1.971,0,0,0,.864.613c.182.1.368.189.556.277l.322.152a.958.958,0,0,0,.275.13l2.639,1.245L47.14,44.72a18.484,18.484,0,0,1,1.771.763c.617.375,1.195.88,1.785,1.3l-2.071-.052a2.207,2.207,0,0,0-.889.055,2.246,2.246,0,0,0-.438.227c-.036.025-.188.112-.188.159,0,.113.717.155.841.166q.521.045,1.038.105,1.038.118,2.067.292c.469.079.934.167,1.4.258"
				fill="#132859"
			/>
			<path
				d="M58.127,26.05A16.457,16.457,0,0,1,56.888,29.9,17.393,17.393,0,0,1,51.5,36.083a71.28,71.28,0,0,1-7.441,4.1,7.344,7.344,0,0,1-3.915.55c-.856-.122-1.958-.3-1.958.063s-.489-.244-.978,0-.612.977-1.04.549-1.285-1.529-2.386-1.652-2.142-.856-4.65-.8c-.821.02-1.642.014-2.463,0a29.306,29.306,0,0,0-3.023.039.143.143,0,0,1-.065,0,.052.052,0,0,1-.034-.051c0-.021.025-.034.044-.044.265-.143.532-.282.8-.427.118-.064.249-.119.362-.187a.039.039,0,0,0,.021-.044c-.013-.04-.251-.014-.291-.013l-.377.013c-.3.013-.591.032-.884.066a3.825,3.825,0,0,0-.572.1,8.945,8.945,0,0,1-1.774.489c-.244-.061.306-.918.306-.918a5.649,5.649,0,0,1-1.223.612c-.245,0,0-.673,0-.673-1.836.49-17.13,8.075-18.292,8.137s-2.2-.184-1.346-.918A85.023,85.023,0,0,1,9.491,39.2c2.916-1.167,5.76-2.542,8.621-3.836L19.8,34.6c.5-.226.947-.662,1.441-.832a9.491,9.491,0,0,1,2.377-.118q1.249-.055,2.5-.13,2.5-.15,4.994-.369,5.017-.442,10-1.17c.829-.121,1.658-.286,2.49-.382a7.579,7.579,0,0,0,1.714-.217,10.569,10.569,0,0,0,3.465-1.955,10.291,10.291,0,0,0,1.824-2.007,4.535,4.535,0,0,1,1.136-1.332,3.4,3.4,0,0,1,1.506-.426,19.385,19.385,0,0,1,4.855.1Z"
				fill="#265dde"
			/>
			<path
				d="M33.258,26.2s-5.557,2.58-6.475,3.314-4.343,2.875-4.955,3.548-.979,1.04-.979,1.04.673.612,3.12,0,7.1-.428,9.177-.612,7.953-.856,8.809-1.1,4.344-.428,4.894-.856c.967-.752,1.988-1.424,2.928-2.214.714-.6,2.625-1.991,2.364-3.131-.127-.553-.545-.507-.991-.345-.967.352-1.943.681-2.934.958a18.351,18.351,0,0,1-5.953.768,20.313,20.313,0,0,1-5.308-1.154c-.425-.144-.847-.294-1.269-.448a3.991,3.991,0,0,0-1.208-.431c-.391.024-.865.5-1.22.664"
				fill="#1f49ab"
			/>
			<path
				d="M67.2,14.37a2.353,2.353,0,0,1-.327.119,10.648,10.648,0,0,1-3.46.3c-.811.008-1.633.049-2.443,0-.446-.028-.6.532-.788.825a6.768,6.768,0,0,1-.952,1.07c-.612.613-.675,1.837-.859,3.367a17.392,17.392,0,0,0,.184,3.669,4.962,4.962,0,0,1-.428,2.324c-.184.49-3.916.612-5.262.368a8.214,8.214,0,0,0-4.926,1.225c-.86.733-3.762.059-7.247,0a12.448,12.448,0,0,1-6.3-2.08,19.78,19.78,0,0,0,2.2-1.1c1.287-.734,5.753-3.426,7.283-4.528s2.814-3.12,3.946-4.68a5.633,5.633,0,0,0,.757-2.776,18.322,18.322,0,0,0,.019-2.85C48.485,8,47.615,6.619,47.049,5.132a17.092,17.092,0,0,1-.725-2.275,7.78,7.78,0,0,1-.182-1.2c-.026-.367.034-.531.425-.323a4.384,4.384,0,0,0,.8.409,4.5,4.5,0,0,0-.453-.779c-.051-.1-.365-.55-.176-.658.064-.036.721.376.734.329.047-.177-.068-.6.21-.637a.554.554,0,0,1,.379.154l1.1.819a20.614,20.614,0,0,0,2.093,1.406l.528.3c.121.07.345.264.481.276.346.031.136-.577.526-.2,1.635,1.578,3.236,3.19,4.883,4.756,1.254,1.191,2.873,1.954,3.415,2.658s.376,1.162.376,1.162l.981.491,2.629,1.039a9.208,9.208,0,0,1,2.143.827.423.423,0,0,1,.151.511.37.37,0,0,1-.165.167"
				fill="#132859"
			/>
		</g>
	</svg>
);

export const Mark25 = (
	<svg xmlns="http://www.w3.org/2000/svg" width="33.6" height="24.925" viewBox="0 0 33.6 24.925">
		<g transform="translate(0 0)">
			<g>
				<path
					d="M22.4,24.138a3.3,3.3,0,0,1,.472.269,3.079,3.079,0,0,1,.463.4c.037.039.123.141.188.116s.039-.134.019-.18a1.092,1.092,0,0,0-.3-.4,2.157,2.157,0,0,0-.315-.21c-.041-.024-.269-.118-.186-.193.039-.034.181,0,.23.007a1.455,1.455,0,0,1,.806.429c.036.039.082.084.134.069.118-.033-.006-.217-.044-.263a1.715,1.715,0,0,0-.253-.251c-.091-.073-.194-.125-.286-.194a3.845,3.845,0,0,0-.663-.372,5.378,5.378,0,0,0-.818-.3.879.879,0,0,1-.5-.419.938.938,0,0,0-.226-.26,3.042,3.042,0,0,1-.257-.212q-.261-.219-.52-.44l-.579-.494a4.186,4.186,0,0,1-.463-.407c-.1-.113-.062-.2-.04-.336l.045-.26-.961-.093-.051.316a.433.433,0,0,0-.013.211.445.445,0,0,0,.13.162l.706.676.1.1a6.6,6.6,0,0,0,.628.613,8.939,8.939,0,0,1,.8.713.868.868,0,0,1,.176.177c-.033.018-.112-.016-.149-.023-.057-.011-.114-.019-.172-.025a8.322,8.322,0,0,0-1.263-.033c-.029,0-.064.008-.074.035a.051.051,0,0,0,.017.051.151.151,0,0,0,.05.027c.1.036.2.07.293.1a2.544,2.544,0,0,0,.261.046c.065.016.131.029.2.044.265.059.528.127.789.2a9.1,9.1,0,0,1,1.117.387c.169.073.34.144.506.223"
					fill="#132859"
				/>
				<path
					d="M26.149,23.927c.421.082.841.168,1.266.236a1.7,1.7,0,0,1,.272.081,2.129,2.129,0,0,0,.264.115c.148.032.04-.2-.012-.259a.688.688,0,0,0-.312-.165,4.533,4.533,0,0,0-.51-.129c-.082-.016-.459-.025-.481-.107-.052-.2.452-.125.536-.113a2.525,2.525,0,0,1,.909.162c.036.02.083.042.116.017.148-.112-.2-.358-.282-.395a2.956,2.956,0,0,0-.569-.162c-.215-.053-.43-.109-.643-.168a3.09,3.09,0,0,1-.847-.274,3.733,3.733,0,0,0-.728-.387c-.473-.183-1.235-.519-1.4-.595-.348-.158-.684-.348-1.024-.524l-.612-.318-.27-.141a2.807,2.807,0,0,1-.31-.156c-.138-.094-.1-.254-.1-.4,0-.018-.016-.3-.014-.3l-1.525.122a.839.839,0,0,0,.088.45.985.985,0,0,0,.431.306c.091.048.184.094.277.138l.161.076c.034.016.1.065.137.065l1.316.62,1.223.577a9.315,9.315,0,0,1,.883.38c.307.187.6.439.89.651L24.24,23.3a.827.827,0,0,0-.661.141c-.018.012-.094.055-.094.079,0,.056.358.077.419.082.173.016.346.033.518.053.344.039.688.088,1.03.145.234.04.466.084.7.129"
					fill="#132859"
				/>
				<path
					d="M28.977,12.986a8.165,8.165,0,0,1-.618,1.921,8.665,8.665,0,0,1-2.687,3.081,35.825,35.825,0,0,1-3.709,2.043,3.671,3.671,0,0,1-1.952.274c-.427-.061-.976-.151-.976.031s-.244-.121-.488,0-.305.488-.518.274-.641-.762-1.19-.823a8.485,8.485,0,0,0-2.318-.4c-.409.01-.818.007-1.227,0a14.29,14.29,0,0,0-1.507.019.073.073,0,0,1-.033,0,.026.026,0,0,1-.017-.026c0-.01.013-.016.022-.021.132-.072.265-.141.4-.213.059-.032.125-.06.181-.093a.02.02,0,0,0,.01-.023c-.006-.02-.125-.006-.145-.006l-.188.007c-.147.006-.294.015-.44.032a1.953,1.953,0,0,0-.285.05,4.534,4.534,0,0,1-.885.244c-.122-.031.153-.458.153-.458a2.811,2.811,0,0,1-.61.305c-.122,0,0-.335,0-.335-.915.244-8.539,4.026-9.119,4.056s-1.1-.091-.671-.457a42.292,42.292,0,0,1,4.575-2.928c1.453-.582,2.871-1.267,4.3-1.912l.843-.381c.249-.113.472-.33.718-.415a4.719,4.719,0,0,1,1.185-.059q.622-.027,1.246-.065,1.247-.073,2.49-.184,2.5-.219,4.987-.583c.413-.06.826-.142,1.241-.19a3.836,3.836,0,0,0,.855-.108,5.3,5.3,0,0,0,1.727-.975,5.147,5.147,0,0,0,.909-1,2.259,2.259,0,0,1,.567-.663,1.686,1.686,0,0,1,.75-.213,9.7,9.7,0,0,1,2.42.048Z"
					fill="#265dde"
				/>
				<path
					d="M16.58,13.062s-2.771,1.286-3.229,1.652-2.165,1.433-2.469,1.769-.488.518-.488.518.335.305,1.556,0a34.927,34.927,0,0,1,4.573-.305c1.037-.091,3.965-.427,4.392-.549a17.459,17.459,0,0,0,2.439-.427c.483-.374.991-.71,1.46-1.1.356-.3,1.308-.993,1.179-1.561-.063-.276-.273-.253-.5-.172-.481.175-.967.339-1.462.477a9.152,9.152,0,0,1-2.968.384,10.146,10.146,0,0,1-2.646-.576c-.212-.071-.422-.147-.633-.223a1.982,1.982,0,0,0-.6-.215c-.2.012-.432.247-.608.331"
					fill="#1f49ab"
				/>
				<path
					d="M33.5,7.164a1.088,1.088,0,0,1-.163.059,5.306,5.306,0,0,1-1.725.151c-.4,0-.814.024-1.218,0-.222-.014-.3.266-.393.412a3.357,3.357,0,0,1-.474.533c-.3.305-.336.916-.428,1.679a8.646,8.646,0,0,0,.092,1.829,2.479,2.479,0,0,1-.214,1.158c-.092.244-1.952.305-2.623.184a4.1,4.1,0,0,0-2.456.61c-.428.366-1.875.029-3.612,0a6.2,6.2,0,0,1-3.142-1.037,9.971,9.971,0,0,0,1.1-.549c.641-.366,2.867-1.708,3.63-2.258A10.44,10.44,0,0,0,23.838,7.6a2.8,2.8,0,0,0,.378-1.383A9.111,9.111,0,0,0,24.225,4.8a7.924,7.924,0,0,0-.771-2.24,8.538,8.538,0,0,1-.361-1.134A3.877,3.877,0,0,1,23,.828c-.013-.183.017-.264.212-.16a2.221,2.221,0,0,0,.4.2,2.263,2.263,0,0,0-.225-.388C23.36.432,23.2.209,23.3.155c.031-.018.359.187.365.164.024-.088-.033-.3.1-.318a.285.285,0,0,1,.189.077l.549.409a10.213,10.213,0,0,0,1.043.7l.263.15c.06.035.172.132.24.138.172.015.068-.288.262-.1.815.786,1.613,1.59,2.434,2.371.625.593,1.432.974,1.7,1.325s.187.579.187.579l.489.245,1.311.518a4.6,4.6,0,0,1,1.068.412.212.212,0,0,1,.075.255.178.178,0,0,1-.082.083"
					fill="#132859"
				/>
			</g>
		</g>
	</svg>
);

export const Mark30Greyscale = (
	<svg xmlns="http://www.w3.org/2000/svg" width="40.442" height="30" viewBox="0 0 40.442 30">
		<g>
			<path
				d="M26.955,29.053a3.932,3.932,0,0,1,.567.324,3.591,3.591,0,0,1,.558.479c.045.047.149.17.227.139s.047-.161.023-.216a1.318,1.318,0,0,0-.359-.486,2.6,2.6,0,0,0-.379-.253c-.049-.029-.325-.142-.224-.232.046-.042.217,0,.277.008a1.729,1.729,0,0,1,.969.516c.044.047.1.1.162.084.142-.04-.008-.262-.053-.317a2.073,2.073,0,0,0-.305-.3c-.108-.088-.233-.151-.344-.233a4.555,4.555,0,0,0-.8-.449,6.491,6.491,0,0,0-.985-.362,1.052,1.052,0,0,1-.6-.5,1.123,1.123,0,0,0-.273-.313,3.767,3.767,0,0,1-.309-.255q-.314-.264-.625-.53l-.7-.594a5.025,5.025,0,0,1-.558-.491c-.117-.136-.074-.246-.047-.4l.054-.314-1.157-.111c-.02.126-.041.253-.061.379a.528.528,0,0,0-.016.255.52.52,0,0,0,.157.195l.849.813.121.119a8.078,8.078,0,0,0,.755.737,10.838,10.838,0,0,1,.969.858,1.119,1.119,0,0,1,.211.213c-.04.022-.134-.019-.179-.028-.068-.013-.138-.023-.207-.03a10.151,10.151,0,0,0-1.52-.039c-.035,0-.077.009-.089.042a.061.061,0,0,0,.02.062.19.19,0,0,0,.06.031c.117.044.235.085.353.125s.208.03.314.056l.236.052c.319.071.635.153.95.241a10.782,10.782,0,0,1,1.344.466c.2.087.409.172.609.268"
				fill="#44506e"
			/>
			<path
				d="M31.473,28.8c.507.1,1.012.2,1.524.284a1.982,1.982,0,0,1,.327.1,2.689,2.689,0,0,0,.317.138c.178.038.049-.246-.013-.312a.849.849,0,0,0-.376-.2,5.687,5.687,0,0,0-.614-.155c-.1-.019-.552-.029-.578-.128-.063-.241.543-.151.645-.137a3.094,3.094,0,0,1,1.093.195c.044.024.1.051.139.021.179-.135-.245-.431-.339-.476a3.606,3.606,0,0,0-.684-.194q-.39-.1-.775-.2a3.668,3.668,0,0,1-1.018-.329,4.492,4.492,0,0,0-.877-.466c-.569-.221-1.487-.624-1.689-.716-.418-.191-.823-.42-1.231-.631l-.738-.383-.325-.169a3.5,3.5,0,0,1-.372-.188c-.167-.114-.116-.305-.125-.485L25.747,24l-1.835.147a1.02,1.02,0,0,0,.106.542,1.189,1.189,0,0,0,.519.368c.109.058.221.113.334.166l.193.091c.041.02.12.078.165.078l1.583.747,1.472.694a11.12,11.12,0,0,1,1.063.458c.37.225.717.528,1.071.783l-1.242-.031a1.327,1.327,0,0,0-.534.033,1.311,1.311,0,0,0-.263.136c-.021.015-.112.067-.113.1,0,.067.431.092.5.1q.312.027.623.063.623.071,1.24.175c.281.048.56.1.839.155"
				fill="#44506e"
			/>
			<path
				d="M34.876,15.63a9.892,9.892,0,0,1-.743,2.312A10.436,10.436,0,0,1,30.9,21.65a42.794,42.794,0,0,1-4.464,2.459,4.407,4.407,0,0,1-2.35.33c-.513-.073-1.174-.182-1.174.038s-.294-.146-.587,0-.368.586-.624.33-.771-.918-1.432-.992a10.25,10.25,0,0,0-2.79-.477c-.492.012-.985.009-1.477,0a17.416,17.416,0,0,0-1.814.023.085.085,0,0,1-.039,0,.03.03,0,0,1-.021-.03c0-.013.015-.02.026-.026.16-.087.32-.17.479-.257.07-.038.149-.071.217-.112a.025.025,0,0,0,.013-.027c-.008-.024-.151-.007-.175-.007-.075,0-.151,0-.226.008-.178.007-.355.019-.531.039a2.218,2.218,0,0,0-.343.06,5.4,5.4,0,0,1-1.064.294c-.147-.037.184-.551.184-.551a3.389,3.389,0,0,1-.735.367c-.146,0,0-.4,0-.4C10.87,23.008,1.694,27.559,1,27.6s-1.321-.11-.807-.55a50.829,50.829,0,0,1,5.5-3.524c1.75-.7,3.456-1.525,5.173-2.3l1.014-.458c.3-.136.568-.4.865-.5a5.636,5.636,0,0,1,1.426-.071q.75-.034,1.5-.078,1.5-.09,3-.222,3.01-.264,6-.7c.5-.072.994-.171,1.494-.229a4.5,4.5,0,0,0,1.028-.13,6.351,6.351,0,0,0,2.079-1.173,6.2,6.2,0,0,0,1.1-1.2,2.7,2.7,0,0,1,.681-.8,2.045,2.045,0,0,1,.9-.256,11.641,11.641,0,0,1,2.913.058Z"
				fill="#caced9"
			/>
			<path
				d="M19.955,15.722S16.621,17.27,16.07,17.71s-2.606,1.725-2.973,2.129-.587.624-.587.624.4.367,1.872,0a41.98,41.98,0,0,1,5.506-.367c1.248-.11,4.771-.514,5.285-.661a21.179,21.179,0,0,0,2.937-.513c.58-.452,1.192-.855,1.757-1.328.428-.36,1.574-1.195,1.418-1.879-.076-.332-.327-.3-.595-.207-.58.211-1.165.408-1.76.574a11.024,11.024,0,0,1-3.572.462,12.174,12.174,0,0,1-3.185-.693c-.255-.086-.508-.177-.761-.269a2.4,2.4,0,0,0-.725-.258c-.235.014-.519.3-.732.4"
				fill="#8892a8"
			/>
			<path
				d="M40.328,8.638l-.79.219s-2.644.038-2.956.02c-.268-.017-.361.319-.473.495a4.115,4.115,0,0,1-.571.642c-.367.367-.4,1.1-.516,2.02a10.507,10.507,0,0,0,.111,2.2,2.972,2.972,0,0,1-.257,1.394c-.11.294-2.349.367-3.157.221a4.926,4.926,0,0,0-2.956.735c-.516.44-2.257.035-4.348,0a7.469,7.469,0,0,1-3.781-1.248,12.015,12.015,0,0,0,1.321-.662c.772-.44,3.451-2.055,4.37-2.717a12.563,12.563,0,0,0,2.367-2.808,3.362,3.362,0,0,0,.454-1.665,10.969,10.969,0,0,0,.012-1.71,9.546,9.546,0,0,0-.928-2.7A10.363,10.363,0,0,1,27.8,1.715,4.718,4.718,0,0,1,27.685,1c-.015-.22.021-.318.256-.194a2.638,2.638,0,0,0,.478.246,2.7,2.7,0,0,0-.272-.468c-.03-.061-.219-.329-.1-.394.038-.022.432.225.44.2.028-.107-.041-.361.126-.382a.331.331,0,0,1,.227.092l.66.492a12.343,12.343,0,0,0,1.256.843l.317.182c.072.041.207.158.289.165.207.018.081-.346.315-.12.981.947,1.941,1.914,2.93,2.853.752.715,1.723,1.173,2.049,1.6s.225.7.225.7l.589.294,1.578.624a5.53,5.53,0,0,1,1.285.5.258.258,0,0,1,0,.423"
				fill="#44506e"
			/>
		</g>
	</svg>
);
