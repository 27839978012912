import React from "react";

import styles from "./Generic.module.scss";

interface Props {
	onClick: () => void;
}

const CloseButton: React.FunctionComponent<Props> = ({ onClick }) => {
	return (
		<div className={styles.ExpandBtn} onClick={onClick}>
			<p>Close</p>
			<svg xmlns="http://www.w3.org/2000/svg" width="7.377" height="12.266" viewBox="0 0 7.377 12.266">
				<path
					d="M648.416,775.978h-2.037v6.94a.378.378,0,0,1-.378.377h-1.133a.378.378,0,0,1-.378-.377v-6.94h-2.04c-.541,0-.88-.382-.608-.693l2.981-4.026a.924.924,0,0,1,1.22,0l2.985,4.026C649.3,775.6,648.959,775.978,648.416,775.978Z"
					transform="translate(-641.746 -771.029)"
					fill="#5583ed"
				/>
			</svg>
		</div>
	);
};

export default CloseButton;
