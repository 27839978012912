import React from "react";
import { ExternalEndpointResponse } from "design-system";
import Variable from "./Variable";
import { useDispatch, useSelector } from "react-redux";
import { activateAllResponseVariables } from "../../developerActions";
import { DeveloperAPI } from "../../../../api/DeveloperAPI";
import { sendRequestToAPI } from "../../../../sharedActions";
import { InitialState } from "../../../../initialState";

import styles from "./ResponseBuilder.module.scss";

interface Props {
	endpointKey: string;
	response: ExternalEndpointResponse;
}

const Variables: React.FunctionComponent<Props> = ({ endpointKey, response }) => {
	const dispatch = useDispatch();
	const variables = useSelector((state: InitialState) => state.externalEndpoints[endpointKey].variables);

	function selectAll() {
		dispatch(activateAllResponseVariables(endpointKey));
		dispatch(sendRequestToAPI(DeveloperAPI.activateAllResponseVariables(endpointKey)));
	}
	return (
		<div className={styles.Variables}>
			<div className={styles.Title}>
				<h2>Variables</h2>

				<div className={styles.Options}>
					<span onClick={selectAll}>Select All</span>
				</div>
			</div>

			<div className={styles.List}>
				{response.variables.map((variableKey) => (
					<Variable
						key={variableKey}
						endpointKey={endpointKey}
						responseKey={response.key}
						variable={variables[variableKey]}
					/>
				))}
			</div>
		</div>
	);
};

export default Variables;
