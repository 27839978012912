import React, { MutableRefObject, useRef, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import {
	IntegrationEvent,
	IntegrationList,
	Job,
	JobAction,
	LoadingPlaceholder,
	LogItem,
	Spinner,
} from "design-system";
import CollapsedElement from "../Generic/CollapsedElement";
import ExpandedElement from "../Generic/ExpandedElement";
import CloseButton from "../Generic/CloseButton";
import { InitialState } from "../../../../../../../initialState";
import ExternalEndpointHeader from "./ExternalEndpoints/ExternalEndpointHeader";
import HeaderPlaceholder from "./HeaderPlaceholder";
import AutomationDetailsModal from "./Details/AutomationDetailsModal";
import Header from "./Header";
import Details from "./Details";
import Status from "./Status";

import styles from "./Action.module.scss";

interface Props {
	item: LogItem;
	job: Job;
	expandAll: boolean;
	isExpanded: boolean;
	onClose: (elementKey: string) => void;
}

function calculateIntegration(integrations: IntegrationList, jobAction: JobAction | undefined) {
	if (!jobAction) {
		return <HeaderPlaceholder />;
	}

	const event = integrations[jobAction.action.integrationKey].events.find(
		(event) => event.key === jobAction.action.eventKey
	) as IntegrationEvent;

	const integration = integrations[jobAction.action.integrationKey];

	if (jobAction.action.integrationKey === "external-endpoints") {
		return (
			<ExternalEndpointHeader
				externalEndpointKey={jobAction.action.eventKey}
				logoUrl={integration.logoUrl}
			/>
		);
	}

	return (
		<React.Fragment>
			<img src={integration.logoUrl} />
			<div>
				<h5>{integration.name}</h5>
				<h3>{event && event.name}</h3>
			</div>
		</React.Fragment>
	);
}

const Action: React.FunctionComponent<Props> = ({ item, job, isExpanded, expandAll, onClose }) => {
	const navigate = useNavigate();
	const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const dispatch = useDispatch();
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const jobAction = useSelector((state: InitialState) => state.jobsActions[item.elementKey]) as JobAction;
	const integrations = useSelector((state: InitialState) => state.integrations);
	const isQueuedForAnimation = useSelector((state: InitialState) =>
		state.animationsQueue.includes(item.elementKey)
	);

	const classes = classNames([
		// styles.Event,
		// !isQueuedForAnimation && styles.Show,
		styles.Action,
		isExpanded && styles.isExpanded,
		!isExpanded && styles.isCollapsed,
	]);

	return (
		<div className={classes} ref={ref}>
			<CollapsedElement isCollapsed={!isExpanded}>
				<div className={styles.Integration}>{calculateIntegration(integrations, jobAction)}</div>
				<Status jobAction={jobAction} />
			</CollapsedElement>
			<ExpandedElement isExpanded={isExpanded}>
				<Header
					jobKey={job.key}
					jobAction={jobAction}
					integrations={integrations}
					onClickDetails={() => setShowDetailsModal(true)}
				/>
				<Details jobAction={jobAction} startedAt={item.timestamp} />

				<AutomationDetailsModal
					show={showDetailsModal}
					integrations={integrations}
					jobAction={jobAction}
					onClose={() => setShowDetailsModal(false)}
				/>

				{!expandAll && <CloseButton onClick={() => onClose(item.elementKey)} />}
			</ExpandedElement>
		</div>
	);
};

export default Action;
