import React from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import { Account, AccountStatus, Modal } from "design-system";
import { useLocation } from "@reach/router";
import moment from "moment";
import { Logo25 } from "../../Base/Logo/Logos";

import styles from "./AccountStatuses.module.scss";

interface Props {}

const AccountClosed: React.FunctionComponent<Props> = () => {
	const location = useLocation();

	const account = useSelector((state: InitialState) => state.account) as Account;

	if (account.status !== AccountStatus.Closed) {
		return null;
	}

	if (account.accessEndsOn !== null && moment().isBefore(moment(account.accessEndsOn))) {
		return null;
	}

	if (location.pathname.includes("/billing")) {
		return null;
	}

	return (
		<Modal show={true} toggleModal={() => null} includeBackground={true}>
			<div className={styles.AccountStatusModal}>
				{Logo25}

				<h1>Your account has been closed.</h1>
			</div>
		</Modal>
	);
};

export default AccountClosed;
