import React from "react";
import { Spinner, JobActionStatus, JobAction } from "design-system";
import classNames from "classnames";
import Status from "../Status";
import { convertToMoment } from "../../../../../../../../utils/helpers";

import styles from "./DetailsModal.module.scss";

interface Props {
	jobAction: JobAction;
	logs: Array<any> | null;
	selectedIndex: number;
	changeSelectedRecord: (index: number) => void;
}

const LogRecordAttempts: React.FunctionComponent<Props> = ({
	jobAction,
	logs,
	selectedIndex,
	changeSelectedRecord,
}) => {
	if (!logs) {
		return <Spinner />;
	}

	const records = logs.map((record, index) => {
		const classes = classNames([
			styles.LogRecordAttemptMenuItem,
			selectedIndex === index && styles.Selected,
		]);

		return (
			<div key={record.timestamp} className={classes} onClick={() => changeSelectedRecord(index)}>
				<Status jobAction={jobAction} />

				<span>{convertToMoment(record.timestamp).format("h:mm:ss A")}</span>
			</div>
		);
	});

	return <div className={styles.LogRecordAttempts}>{records}</div>;
};

export default LogRecordAttempts;
