import { InitialState } from "../../initialState";
import { AnyAction } from "redux";
import {
	DELETE_JOB_TABLE_VIEW,
	RENAME_JOB_TABLE_VIEW,
	RESIZE_JOB_TABLE_VIEW_COLUMN,
	SAVE_NEW_JOB_TABLE_VIEW,
	SET_TABLE_DEFAULT_VIEW,
	UPDATE_TABLE_VIEW_ACCESS,
	UPDATE_TABLE_VIEW_COLUMNS,
	UPDATE_TABLE_VIEW_FILTERS,
} from "../../actionTypes";

export function views(state: InitialState, action: AnyAction) {
	if (action.type === SAVE_NEW_JOB_TABLE_VIEW) {
		return {
			...state,
			jobTableViews: Object.assign({}, state.jobTableViews, {
				[action.view.key]: action.view,
			}),
			jobTableViewControls: {
				...state.jobTableViewControls,
				selectedViewKey: action.view.key,
			},
		};
	}

	if (action.type === SET_TABLE_DEFAULT_VIEW) {
		return {
			...state,
			jobTableViewControls: {
				...state.jobTableViewControls,
				defaultViewKey: state.jobTableViewControls.selectedViewKey,
			},
		};
	}
	if (action.type === RENAME_JOB_TABLE_VIEW) {
		return {
			...state,
			jobTableViews: Object.assign({}, state.jobTableViews, {
				[action.viewKey]: {
					...state.jobTableViews[action.viewKey],
					name: action.name,
				},
			}),
		};
	}

	if (action.type === UPDATE_TABLE_VIEW_COLUMNS) {
		return {
			...state,
			jobTableViews: Object.assign({}, state.jobTableViews, {
				[action.viewKey]: {
					...state.jobTableViews[action.viewKey],
					columns: action.columns,
				},
			}),
		};
	}

	if (action.type === UPDATE_TABLE_VIEW_FILTERS) {
		return {
			...state,
			jobTableViews: Object.assign({}, state.jobTableViews, {
				[action.viewKey]: {
					...state.jobTableViews[action.viewKey],
					filters: action.filters,
					loaded: false,
					jobKeys: [],
					pagination: null,
				},
			}),
		};
	}

	if (action.type === UPDATE_TABLE_VIEW_ACCESS) {
		return {
			...state,
			jobTableViews: Object.assign({}, state.jobTableViews, {
				[action.viewKey]: {
					...state.jobTableViews[action.viewKey],
					access: action.access,
				},
			}),
		};
	}

	// if (action.type === LIVE_UPDATE_VIEW) {
	// 	return {
	// 		...state,
	// 		jobTableViews: Object.assign({}, state.jobTableViews, {
	// 			[action.view.key]: {
	// 				...state.jobTableViews[action.view.key],
	// 				name: action.view.name,
	// 			},
	// 		}),
	// 	};
	// }
	//
	// if (action.type === LIVE_UPDATE_VIEW_COLUMNS) {
	// 	return {
	// 		...state,
	// 		jobTableViews: Object.assign({}, state.jobTableViews, {
	// 			[action.viewKey]: {
	// 				...state.jobTableViews[action.viewKey],
	// 				columns: action.columns,
	// 			},
	// 		}),
	// 	};
	// }

	if (action.type === RESIZE_JOB_TABLE_VIEW_COLUMN) {
		const selectedViewKey = state.jobTableViewControls.selectedViewKey as string;
		let columns = [...state.jobTableViews[selectedViewKey].columns].map((column) => {
			if (column.key === action.columnKey) {
				let nextWidth = (column.width += action.changeAmount);
				return {
					...column,
					width: nextWidth >= 100 ? nextWidth : 100,
				};
			}

			return column;
		});

		return {
			...state,
			jobTableViews: Object.assign({}, state.jobTableViews, {
				[selectedViewKey]: {
					...state.jobTableViews[selectedViewKey],
					columns,
				},
			}),
		};
	}

	if (action.type === DELETE_JOB_TABLE_VIEW) {
		let views = {
			...state.jobTableViews,
		};

		delete views[action.viewKey];

		const allViewKey = Object.keys(views).find((viewKey) => viewKey.includes("all_"));

		return {
			...state,
			jobTableViews: Object.assign({}, views),
			jobTableViewControls: {
				...state.jobTableViewControls,
				selectedViewKey: allViewKey,
			},
		};
	}

	return state;
}
