import { MutableRefObject } from "react";
// @ts-ignore
import moment from "moment-timezone";
import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import { shallowEqual } from "fast-equals";
import { XYCoords } from "../components/Workflows/Editor/Canvas/utils";
import {
	Ability,
	CanvasElementMeasurements,
	ConditionalContentElement,
	ConnectionTypes,
	ContentCondition,
	ContentFormDetails,
	Countries,
	CountryRaw,
	DropdownItem,
	ElementConnection,
	ElementType,
	Job,
	JobComment,
	JobCommentsList,
	JobStep,
	JobTableView,
	LinePosition,
	LogItem,
	Me,
	MultipleChoiceConnection,
	RouteCondition,
	Step,
	StepAssignee,
	StepAssigneeType,
	StepContentElementList,
	StepContentType,
	StepsList,
	TextContentElement,
	Tile,
	TileType,
	TimeInterval,
	User,
	ViewAccessRestrictionType,
} from "design-system";
import { AlwaysVisibleContentViewKey, intervalDropdownOptions, PUBLIC_PAGE_URLS } from "./constants";
import { store } from "../store";
import { fetchFromApi, sendRequestToAPI } from "../sharedActions";
import { AccountAPI } from "../api/AccountAPI";
import { InitialState } from "../initialState";
import { Moment } from "moment";
import { FormContentElement } from "design-system/src/types/workflows/steps";
import { determineEnv } from "./api";
import { CanvasElementMeasurer } from "../components/Workflows/Editor/Canvas/CanvasElementMeasurer";
import { updateContentCondition, updateRouteCondition } from "../components/Workflows/Editor/workflowActions";
import { WorkflowAPI } from "../api/WorkflowAPI";

export function amOnUnAuthenticatedPage() {
	return PUBLIC_PAGE_URLS.find((url: string) => {
		const regex = new RegExp(url, "g");

		return window.location.pathname.match(regex);
	});
}

export function uppercaseFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function mergeUnique(array1: Array<string>, array2: Array<string>) {
	return [...new Set([...array1, ...array2])];
}

export function delayedFocus(
	elementRef: MutableRefObject<HTMLInputElement | null>,
	ms = 500
): MutableRefObject<HTMLInputElement | null> {
	setTimeout(() => {
		elementRef.current && elementRef.current.focus();

		return elementRef;
	}, 500);

	return elementRef;
}

export function everyMinute(func: any): NodeJS.Timeout {
	return setInterval(func, 60000);
}

export function calculateDifference(val1: number, val2: number) {
	return val1 / 2 - val2 / 2;
}

export function calculateDistanceBetweenCoords(startCoords: XYCoords, endCoords: XYCoords): XYCoords {
	const distanceX = endCoords.x - startCoords.x;
	const distanceY = endCoords.y - startCoords.y;

	return { x: distanceX, y: distanceY };
}

export function getOppositeDirection(position: LinePosition): LinePosition {
	if (position === LinePosition.Top) return LinePosition.Bottom;
	if (position === LinePosition.Bottom) return LinePosition.Top;
	if (position === LinePosition.Right) return LinePosition.Left;

	return LinePosition.Right;
}

export function capitalize(string: string | null) {
	if (typeof string !== "string") return "";
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function calculateCanvasElementHeight(elementHeight: number | undefined, type: string) {
	const minimumHeightByType: { [type: string]: number } = {
		[ElementType.Step]: 40,
		[ElementType.SubWorkflowConnector]: 40,
		[ElementType.Label]: 14,
	};

	const minHeight = minimumHeightByType[type];

	if (!elementHeight) return minHeight;

	const height = elementHeight > minHeight ? elementHeight : minHeight;

	if (type === ElementType.SubWorkflowConnector) {
		return height + 16; // extra space to account for the "Workflow" label
	}

	return height;
}

export function addPaddingToCanvasElement(
	measurements: CanvasElementMeasurements,
	amount: number
): CanvasElementMeasurements {
	// amount equals how much added to each side
	return {
		width: measurements.width + amount * 2,
		height: measurements.height + amount * 2,
		coordX: measurements.coordX - amount,
		coordY: measurements.coordY - amount,
	};
}

export function calculateCanvasElementWidth(elementWidth: number | undefined, type: ElementType): number {
	const minimumWidthByType: { [type: string]: number } = {
		[ElementType.Step]: 100,
		[ElementType.SubWorkflowConnector]: 200,
	};

	const minWidth = minimumWidthByType[type];

	if (!elementWidth) return minWidth;

	return elementWidth > minWidth ? elementWidth : minWidth;
}

export function formatIntervalForDropdown(interval: TimeInterval | null): DropdownItem | null {
	if (!interval) return null;

	const selectedOption = intervalDropdownOptions.find(
		(option: DropdownItem) => option.key === interval
	) as DropdownItem;

	return {
		key: interval,
		label: selectedOption.label,
	};
}

export function formatAlwaysVisibleContentViewKey(stepKey: string) {
	return `${stepKey}_${AlwaysVisibleContentViewKey}`;
}

export function convertTextContentToRichText(stepContentElements: StepContentElementList) {
	if (!stepContentElements) return {};

	let elements: StepContentElementList = {};

	Object.keys(stepContentElements).forEach((elementKey) => {
		let element = stepContentElements[elementKey];

		if (element.type === StepContentType.Text) {
			const contentState = convertFromRaw((element as TextContentElement).details.content);

			(element as TextContentElement).details.content = EditorState.createWithContent(contentState);
		}

		elements[elementKey] = element;
	});

	return elements;
}

export function convertJobCommentsToRichText(jobComments: JobCommentsList) {
	let prepared: JobCommentsList = {};

	Object.keys(jobComments).forEach((jobCommentKey) => {
		let comment = jobComments[jobCommentKey];

		const contentState = convertFromRaw((comment.message as unknown) as RawDraftContentState);

		comment.message = EditorState.createWithContent(contentState);

		prepared[jobCommentKey] = comment;
	});

	return prepared;
}

export function convertSingleCommentToRichText(comment: JobComment) {
	const contentState = convertFromRaw((comment.message as unknown) as RawDraftContentState);

	comment.message = EditorState.createWithContent(contentState);

	return comment;
}

export function isEqualShallow(objectA: Object, objectB: Object): boolean {
	return shallowEqual(objectA, objectB);
}

export function isDefaultView(viewKey: string, selectedStepKey: string): boolean {
	return viewKey === `${selectedStepKey}_default`;
}

export function isPublishedKey(key: string) {
	return key.startsWith("p");
}

export function isWorkingKey(key: string) {
	return !isPublishedKey(key);
}

export function convertToPublishedKey(key: string) {
	return "p" + key.slice(1);
}

export function determineVisibilityChangeEventName(): [string, string] {
	if (typeof document.hidden !== "undefined") {
		// Opera 12.10 and Firefox 18 and later support
		return ["hidden", "visibilitychange"];
	}

	// @ts-ignore
	if (typeof document.msHidden !== "undefined") {
		return ["msHidden", "msvisibilitychange"];
	}

	// @ts-ignore
	if (typeof document.webkitHidden !== "undefined") {
		return ["webkitHidden", "webkitvisibilitychange"];
	}

	return ["hidden", "visibilitychange"];
}

export function pingOnTabFocus() {
	// when focus returns to the page, make sure we're still authenticated
	const [hidden, visibilityChange] = determineVisibilityChangeEventName();
	document.addEventListener(visibilityChange, () => {
		// @ts-ignore
		if (!document[hidden]) {
			store.dispatch(fetchFromApi(AccountAPI.ping()));
		}
	});
}

export function isFormLayoutTileEmpty(row: number, column: number): boolean {
	const formElement = store.getState().stepContentElements[
		store.getState().editor.contentElementKeyInEditMode as string
	];

	const layout = formElement.details.layout;
	const item = layout[row][column];

	if (!item) return false;

	return item.type === TileType.Empty;
}

export function stepBlueprintIsLoaded(stepKey: string): boolean {
	return (store.getState() as InitialState).steps[stepKey] !== undefined;
}

export function convertToMoment(date: string): Moment {
	const userTimezone = ((store.getState() as InitialState).me as Me).timezone;

	return moment.tz(date, "UTC").tz(userTimezone);
}

export function formatDateForHumans(date: string | null): { date: string; time: string } | null {
	if (!date) {
		return null;
	}

	const userTimezone = ((store.getState() as InitialState).me as Me).timezone;
	const momentDate = moment.tz(date, "UTC").tz(userTimezone);
	// const now = moment().tz(userTimezone);

	// would be good to show this in a tooltip
	// if (momentDate.isSame(now, "day")) {
	// 	return momentDate.fromNow();
	// }

	return {
		date: momentDate.format("MM/DD/YYYY"),
		time: momentDate.format("h:mm A"),
	};
}

export function formatRelativeDate(date: string | null) {
	if (!date) {
		return null;
	}

	const userTimezone = ((store.getState() as InitialState).me as Me).timezone;
	const momentDate = moment.tz(date, "UTC").tz(userTimezone);

	return momentDate.fromNow();
}

export function fullJobIsNotLoaded(jobKey: string): boolean {
	const state = store.getState() as InitialState;

	return !state.jobs[jobKey].loaded;
}
export function jobIsNotLoaded(jobKey: string): boolean {
	const state = store.getState() as InitialState;

	if (!state.jobs[jobKey]) {
		return true;
	}

	if (!state.jobLogs[jobKey]) {
		console.log("hit");
		// This is used for situations where Websockets aren't working and
		// no logs have been appended, but the user should be able to view the overview page.
		return true;
	}

	return !state.jobs[jobKey].loaded;
}

export function extractUserFromLog(logItem: LogItem): string | null {
	if (!logItem.details) return null;
	if (!logItem.details.hasOwnProperty("userKey")) return null;

	return logItem.details.userKey;
}

export function hydrateCountries(countriesRaw: { [key: string]: CountryRaw }) {
	const countries: Countries = {};

	Object.keys(countriesRaw).forEach((countryKey: string) => {
		const { n, ph, tz } = countriesRaw[countryKey];
		countries[countryKey] = {
			name: n,
			alpha3Code: countriesRaw[countryKey]["3"],
			alpha2Code: countriesRaw[countryKey]["2"],
			phoneCode: ph,
			timezones: tz,
		};
	});

	return countries;
}

export function outputStateSlice(slice: { [stateIndex: string]: any }) {
	let stateSlice: { [stateIndex: string]: any } = {};

	Object.keys(slice).forEach((stateIndex) => {
		stateSlice[stateIndex] = slice[stateIndex];
	});

	console.log(JSON.stringify(stateSlice));
}

export function extractFrom(stateObject: { [key: string]: any }, keys: Array<string>) {
	const extracted: { [key: string]: any } = {};
	keys.forEach((key) => {
		extracted[key] = stateObject[key];
	});

	return extracted;
}

export function hasViewEditPermission(view: JobTableView) {
	const me: User = store.getState().me;

	if (view.access.edit.type === ViewAccessRestrictionType.AllRoles) {
		return true;
	}

	if (view.access.edit.type === ViewAccessRestrictionType.Me && me.key === view.creator) {
		return true;
	}

	if (view.access.edit.type === ViewAccessRestrictionType.SelectedRoles) {
		return view.access.edit.roles.includes(me.role.key);
	}

	return false;
}

export function isOnSafari(): boolean {
	return navigator.userAgent.indexOf("Safari") !== -1;
}

export function isStepLoaded(steps: StepsList, jobStep: JobStep) {
	if (!jobStep) {
		return false;
	}

	const step = steps[jobStep.step.key];

	if (!step) {
		return false;
	}

	return true;
}

export function stepHasContent(steps: StepsList, jobStep: JobStep | null) {
	if (!jobStep) {
		return false;
	}

	const step = steps[jobStep.step.key];

	if (!step) {
		// todo
		console.log("no step found");
		return false;
	}

	const stepContentElements = store.getState().stepContentElements;

	if (!stepContentElements) return false;

	return Object.keys(stepContentElements).some(
		(elementKey) => stepContentElements[elementKey].stepKey === step.key
	);
}

export function requiresUserSelection(steps: StepsList, jobStep: JobStep | null) {
	if (!jobStep) {
		return true;
	}

	const step = steps[jobStep.step.key];

	const connection: ElementConnection = store.getState().elementConnections[step.connectionKey];

	if (connection.type === ConnectionTypes.MULTIPLE_CHOICE) {
		return true;
	}

	return false;
}

export function hasAccessToStep(step: Step, job: Job) {
	const me: User = store.getState().me;

	if (step.assignment.assignees.length === 0) {
		return true;
	}

	// what about when assignee is initiator, prev step or person field?
	const systemAssignements = step.assignment.assignees.filter((assignee: StepAssignee) => {
		return assignee.type === StepAssigneeType.System;
	});

	for (let i = 0; i <= systemAssignements.length - 1; i++) {
		if (systemAssignements[i].key === "initiator" && me.key === job.startedBy) {
			return true;
		}

		if (systemAssignements[i].key.startsWith("prevStep_")) {
			// this is complicated to implement because if the user is the completer
			// for the last previous step, then the jobStep->completedBy is empty until
			// a real-time update LIVE_USER_COMPLETED_JOB_STEP, meaning at this point
			// in the execution flow and the current data available
			// we have no way to know if the user should have access based on a prevStep assignment.
			// We likely need to send an API request
		}

		if (systemAssignements[i].key.startsWith("personField_")) {
		}
	}

	return step.assignment.assignees.some((assignee: StepAssignee) => assignee.key === me.key);
}

export function onOpenJobStepPage(jobStepKey: string) {
	const pathname = window.location.pathname;

	if (pathname.includes("/open/")) {
		const segments = pathname.split("/");

		if (!segments[4]) {
			return false;
		}

		if (segments[4] === jobStepKey) {
			return true;
		}
	}

	return false;
}

export function toCamelCase(text: string) {
	text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));

	return text.substr(0, 1).toLowerCase() + text.substr(1);
}

export function canAccess(ability: Ability) {
	const me = (store.getState() as InitialState).me as Me;
	const roles = (store.getState() as InitialState).roles;

	const permissions = roles[me.role.key].permissions;

	return permissions.includes(ability);
}

export function canNotAccess(ability: Ability) {
	return !canAccess(ability);
}

class CodeBlockTheme {}

export const CodeBlockLightTheme: CodeBlockTheme = {
	lineNumberColor: "#98a0b3",
	lineNumberBgColor: "#f0f3fc",
	backgroundColor: "#f0f3fc",
	propertyColor: "#44506e",
	punctuationColor: "#98a0b3",
	stringColor: "#1f49ab",
	booleanColor: "#12668a",
	numberColor: "#1f49ab",
	operatorColor: "#5583ed",
	nameColor: "#1f49ab",
	titleColor: "#1f49ab",
	sectionColor: "#1f49ab",
	metaKeywordColor: "#1f49ab",
	atruleColor: "#1f49ab",
	insertedColor: "#1f49ab",
	metaColor: "#1f49ab",
	symbolColor: "#1f49ab",
	constantColor: "#1f49ab",
	charColor: "#1f49ab",
	attributeColor: "#1b3b87",
	builtInColor: "#1f49ab",
	typeColor: "#1f49ab",
	importantColor: "#1f49ab",
	codeColor: "#1f49ab",

	codeFontSize: 14,
	codeLineHeight: 1.6,
};

export const CodeBlockDarkTheme: CodeBlockTheme = {
	lineNumberColor: "#f0f3fc",
	lineNumberBgColor: "#07163B",
	backgroundColor: "#132859",
	propertyColor: "#f0f3fc",
	punctuationColor: "#9cb7f7",
	booleanColor: "#f0f3fc",
	numberColor: "#f0f3fc",
	stringColor: "#bfd0f7",
	metaColor: "#f0f3fc",
	symbolColor: "#f0f3fc",
	constantColor: "#f0f3fc",
	charColor: "#f0f3fc",
	prologColor: "#f0f3fc",
	literalColor: "#f0f3fc",
	attributeColor: "#f0f3fc",
	builtInColor: "#f0f3fc",
	operatorColor: "#f0f3fc",
	typeColor: "#f0f3fc",
	importantColor: "#f0f3fc",
	codeColor: "#f0f3fc",
	sectionColor: "#f0f3fc",
	codeFontSize: 14,
	codeLineHeight: 1.6,
};

export function getRandomIntBetween(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function findSelectedDropdownItem(
	options: Array<DropdownItem>,
	selected: string
): DropdownItem | null {
	const item = options.find((option) => option.key === selected);

	if (!item) {
		return null;
	}

	return item;
}

export function isValidJson(string: string) {
	try {
		JSON.parse(string);
	} catch (e) {
		return false;
	}
	return true;
}

export function isAllJobsView(viewKey: string | null) {
	if (!viewKey) {
		return false;
	}

	return viewKey.includes("all_");
}

export function isInsideConditionalElement() {
	return (store.getState() as InitialState).editor.parentConditionalElementKeyInEditMode !== null;
}

export function isNotConditionalGroupElement(key: string) {
	return (store.getState() as InitialState).stepContentElements[key].type !== StepContentType.Conditional;
}

export function getPrefilledFields() {
	return (store.getState() as InitialState).openJob.prefilledFields;
}

export function isMobileViewport() {
	return window.innerWidth <= 500;
}

export function calculateJobStepElementKey(elementKey: string, jobStep: JobStep, activeUser: User | null) {
	let key = elementKey;

	if (!jobStep) {
		// public
		return key;
	}

	if (jobStep.assignment.requirements.minimumCompleters > 1) {
		key = `${elementKey}_${(activeUser as User).key}`;
	}

	return key;
}

export function convertToPixels(num: number): string {
	return `${num}px`;
}

export function stepWasCompletedBefore(jobKey: string, jobStepKey: string) {
	const userKey = store.getState().me.key;
	const logs = store.getState().jobLogs[jobKey];

	if (!logs) {
		return false;
	}

	const results = logs.find((logItem: LogItem) => {
		if (
			logItem.event === "user_step_completed" &&
			logItem.elementKey === jobStepKey &&
			logItem.details?.userKey === userKey
		) {
			return true;
		}

		return logItem.event === "step_completed" && logItem.elementKey === jobStepKey;
	});

	if (!results) {
		return false;
	}

	return results.elementKey;
}

export function hasLoadedStepBlueprint(jobStepKey: string) {
	const stepKey = store.getState().jobsSteps[jobStepKey].step.key;

	return store.getState().steps[stepKey] !== undefined;
}

function deriveFieldKeysFromForm(element: FormContentElement): Array<string> {
	let fieldKeys: Array<string> = [];
	const content = element.details as ContentFormDetails;

	for (let rowIndex = 0; rowIndex < content.layout.length; rowIndex++) {
		const row = content.layout[rowIndex];

		for (let tileIndex = 0; tileIndex < row.length; tileIndex++) {
			const tile: Tile = row[tileIndex];

			if (tile.key !== undefined) {
				fieldKeys.push(tile.key);
			}
		}
	}

	return fieldKeys;
}

export function findAllFieldsFromStepContent(elementKeys: Array<string>) {
	if (!elementKeys.length) {
		return [];
	}

	const stepContentElements: StepContentElementList = store.getState().stepContentElements;
	let fieldKeys: Array<string> = [];

	elementKeys.forEach((elementKey: string) => {
		const element = stepContentElements[elementKey];

		if (element.type === StepContentType.Conditional) {
			fieldKeys = [
				...fieldKeys,
				...findAllFieldsFromStepContent((element as ConditionalContentElement).details.elements),
			];
		}

		if (element.type !== StepContentType.Form) {
			return;
		}

		fieldKeys = [...fieldKeys, ...deriveFieldKeysFromForm(element as FormContentElement)];
	});

	return Array.from(new Set(fieldKeys));
}

export function searchForMultipleChoiceConnectionField(connectionKey: string) {
	const connection: ElementConnection = store.getState().elementConnections[connectionKey];

	if (connection.type !== ConnectionTypes.MULTIPLE_CHOICE) {
		return null;
	}

	return (connection as MultipleChoiceConnection).details.fieldKey;
}

export function calculateCompletedJobStepFields(
	fieldKeys: Array<string>,
	stepConnectionKey: string,
	jobKey: string
): Array<{ fieldKey: string; fieldValue: any }> {
	const multiChoiceConnectionField = searchForMultipleChoiceConnectionField(stepConnectionKey);

	if (multiChoiceConnectionField && !fieldKeys.includes(multiChoiceConnectionField)) {
		fieldKeys.push(multiChoiceConnectionField);
	}

	const jobsFields = store.getState().jobsFields[jobKey];
	const jobFieldKeys = Object.keys(jobsFields);

	if (jobFieldKeys.length === 0) {
		return [];
	}

	const includedFieldKeys = jobFieldKeys.filter((fieldKey: string) => {
		const [key] = fieldKey.split("_");

		return fieldKeys.includes(key);
	});

	let jobStepFields: Array<{ fieldKey: string; fieldValue: string }> = [];

	includedFieldKeys.forEach((jobStepFieldKey: string) => {
		jobStepFields.push({
			fieldKey: jobStepFieldKey,
			fieldValue: jobsFields[jobStepFieldKey],
		});
	});

	return jobStepFields;
}

export function getStripeKey() {
	if (determineEnv() === "prod") {
		return "pk_live_51IXBrQD6HyT8J1DDoeOGCtqqupmcEIGHPbEkv4e1zhPjSIzuSO4SPmB3qiR09zgnYuiadPREbHWGK719wxcUcPqY00oAigHWLL";
	}

	return "pk_test_51IXBrQD6HyT8J1DDSHtQM93J3LqeJkXwf3Q5ZXkePFdmzZvHNuQzl1DtL8Z9887PTtd6dLOwyyp3LLQf4nejxJSn00tJXKkDPX";
}

export enum VariableSelectionScreens {
	index = "index",
	field = "field",
	automation = "automation",
	system = "system",
	integrations = "integrations",
	event = "event",
	variable = "variable",
}

export function buildNewJobAutomationVariables(variables: any, jobAutomationVariables: any) {
	if (Array.isArray(variables) === false) {
		return jobAutomationVariables;
	}

	variables.map((variable: any) => {
		if (!jobAutomationVariables[variable.integrationKey]) {
			jobAutomationVariables[variable.integrationKey] = {
				[variable.eventKey]: {
					[variable.variableKey]: variable.value,
				},
			};
			return;
		}
		if (!jobAutomationVariables[variable.integrationKey][variable.eventKey]) {
			jobAutomationVariables[variable.integrationKey] = {
				...jobAutomationVariables[variable.integrationKey],
				[variable.eventKey]: {
					[variable.variableKey]: variable.value,
				},
			};
			return;
		}

		if (!jobAutomationVariables[variable.integrationKey][variable.eventKey][variable.variableKey]) {
			jobAutomationVariables[variable.integrationKey] = {
				...jobAutomationVariables[variable.integrationKey],
				[variable.eventKey]: {
					...jobAutomationVariables[variable.integrationKey][variable.eventKey],
					[variable.variableKey]: variable.value,
				},
			};
			return;
		}

		jobAutomationVariables[variable.integrationKey][variable.eventKey][variable.variableKey] =
			variable.value;
	});

	return jobAutomationVariables;
}

export function calculateDelayText(units: number, interval: TimeInterval) {
	let intervalText = "Minutes";

	if (interval === TimeInterval.HOURS) {
		intervalText = "Hours";
	}

	if (interval === TimeInterval.DAYS) {
		intervalText = "Days";
	}

	if (interval === TimeInterval.WEEKS) {
		intervalText = "Weeks";
	}

	if (interval === TimeInterval.MONTHS) {
		intervalText = "Months";
	}

	if (units === 1) {
		intervalText = intervalText.slice(0, -1);
	}

	return `${units} ${intervalText} Delay`;
}
