import React, { useState } from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import classNames from "classnames";
import ErrorReportForm from "./ErrorReportForm";

import styles from "./SystemError.module.scss";

interface Props {}

const SystemError: React.FunctionComponent<Props> = () => {
	const system = useSelector((state: InitialState) => state.system);
	const [showErrorReport, setShowErrorReport] = useState(false);

	const classes = classNames([styles.SystemError, system.online === false && styles.Show]);

	return (
		<React.Fragment>
			<ErrorReportForm show={showErrorReport} onClose={() => setShowErrorReport(false)} />
			<div className={classes}>
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="26.945"
						height="26.945"
						viewBox="0 0 26.945 26.945"
					>
						<g transform="translate(-212.024 -965.233)">
							<circle
								cx="13.473"
								cy="13.473"
								r="13.473"
								transform="translate(212.024 965.233)"
								fill="#d95656"
							/>
							<g transform="translate(222.879 969.978)">
								<path
									d="M224.052,981.778h2.327a.379.379,0,0,0,.4-.3l1.123-11.248a.362.362,0,0,0-.4-.347h-4.427a.363.363,0,0,0-.4.344l.977,11.248A.377.377,0,0,0,224.052,981.778Z"
									transform="translate(-222.672 -969.888)"
									fill="#fff"
								/>
								<circle
									cx="2.08"
									cy="2.08"
									r="2.08"
									transform="translate(0.537 13.293)"
									fill="#fff"
								/>
							</g>
						</g>
					</svg>
				</div>
				<div>
					<p>
						An error occurred while trying to connect to the server. Please try refreshing the
						page and if the issue persists contact{" "}
						<a href="mailto:support@prestavi.com">support@prestavi.com</a>.
					</p>
				</div>
				<div className={styles.ReportBtn}>
					<span onClick={() => setShowErrorReport(true)}>Send Error Report</span>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SystemError;
