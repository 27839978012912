export const REQUEST_CSRF_TOKEN = "REQUEST_CSRF_TOKEN"; // get
export const REQUEST_AUTHORIZED_USER = "REQUEST_AUTHORIZED_USER"; // get
export const SET_AUTHORIZED_USER = "SET_AUTHORIZED_USER"; // set
export const USER_NOT_AUTHENTICATED = "USER_NOT_AUTHENTICATED";
export const NO_INTERNET_DETECTED = "NO_INTERNET_DETECTED";
export const RECONNECTED_TO_INTERNET = "RECONNECTED_TO_INTERNET";
export const SYNCHRONOUS_REQUEST_TO_API = "SYNCHRONOUS_REQUEST_TO_API";
export const START_REAL_TIME_UPDATES = "START_REAL_TIME_UPDATES";
export const START_REFRESH_AUTH_FLOW = "START_REFRESH_AUTH_FLOW";
export const STOP_REFRESH_AUTH_FLOW = "STOP_REFRESH_AUTH_FLOW";
export const RECONNECT_REAL_TIME_UPDATES = "RECONNECT_REAL_TIME_UPDATES";
export const STOP_REAL_TIME_UPDATES = "STOP_REAL_TIME_UPDATES";
export const SEND_REQUEST_TO_API = "SEND_REQUEST_TO_API";
export const SEND_CAPTURED_REQUEST_TO_API = "SEND_CAPTURED_REQUEST_TO_API";
export const SEND_DEBOUNCED_REQUEST_TO_API = "SEND_DEBOUNCED_REQUEST_TO_API";
export const RECEIVE_RESPONSE_FROM_API = "RECEIVE_RESPONSE_FROM_API";
export const FETCH_FROM_API = "FETCH_FROM_API";
export const DISTINCT_FETCH_FROM_API = "DISTINCT_FETCH_FROM_API";
export const PONG = "PONG";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const RELEASE_ANIMATIONS = "RELEASE_ANIMATIONS";
export const ADD_CAPTURED_API_RESPONSE = "ADD_CAPTURED_API_RESPONSE";
export const FORGET_CAPTURED_API_RESPONSE = "FORGET_CAPTURED_API_RESPONSE";

export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_SUBSCRIPTION_PAYMENT_METHODS = "SET_SUBSCRIPTION_PAYMENT_METHODS";
export const SET_SUBSCRIPTION_RECEIPTS = "SET_SUBSCRIPTION_RECEIPTS";
export const SET_PRE_UPGRADE_DATA = "SET_PRE_UPGRADE_DATA";
export const UPDATE_PRE_UPGRADE_RESOURCE = "UPDATE_PRE_UPGRADE_RESOURCE";
export const UPDATE_PRE_UPGRADE_DATA = "UPDATE_PRE_UPGRADE_DATA";
export const UNSET_PRE_UPGRADE_DATA = "UNSET_PRE_UPGRADE_DATA";
export const CHANGE_UPGRADE_STAGE = "CHANGE_UPGRADE_STAGE";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SELECTED_BILLING_CYCLE = "UPDATE_SELECTED_BILLING_CYCLE";
export const SET_BILLING_CYCLE_USAGE = "SET_BILLING_CYCLE_USAGE";
export const SET_PRE_UPGRADE_INVOICE = "SET_PRE_UPGRADE_INVOICE";
export const RELOAD_PRE_UPGRADE_INVOICE = "RELOAD_PRE_UPGRADE_INVOICE";
export const SET_RECEIPTS = "SET_RECEIPTS";
export const SET_FULL_RECEIPT = "SET_FULL_RECEIPT";

export const START_INTRO_VIDEO = "START_INTRO_VIDEO";
export const STOP_INTRO_VIDEO = "STOP_INTRO_VIDEO";

export const START_FILE_UPLOAD = "START_FILE_UPLOAD";
export const CANCEL_FILE_UPLOAD = "CANCEL_FILE_UPLOAD";
export const MONITOR_FILE_UPLOAD_PROGRESS = "MONITOR_FILE_UPLOAD_PROGRESS";
export const UPDATE_FILE_UPLOAD_PROGRESS = "UPDATE_FILE_UPLOAD_PROGRESS";
export const FILE_UPLOAD_COMPLETE = "FILE_UPLOAD_COMPLETE";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";

export const REQUEST_APP_ESSENTIALS = "REQUEST_APP_ESSENTIALS";
export const REQUEST_APP_NON_ESSENTIALS = "REQUEST_APP_NON_ESSENTIALS";
export const SET_APP_ESSENTIALS = "SET_APP_ESSENTIALS";
export const SET_FEATURE_VOTES = "SET_FEATURE_VOTES";
export const INITIAL_LOAD_COMPLETE = "INITIAL_LOAD_COMPLETE";
export const EMPTY_SUCCESS_RESPONSE = "EMPTY_SUCCESS_RESPONSE";
export const ADD_FEATURE_ITEM_VOTE = "ADD_FEATURE_ITEM_VOTE";
export const REMOVE_FEATURE_ITEM_VOTE = "REMOVE_FEATURE_ITEM_VOTE";

export const SET_UNPUBLISHED_WORKFLOWS = "SET_UNPUBLISHED_WORKFLOWS";
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const UPDATE_WORKFLOW_STATUS = "UPDATE_WORKFLOW_STATUS";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const COPY_WORKFLOW = "COPY_WORKFLOW";
export const PUBLISH_WORKFLOW = "PUBLISH_WORKFLOW";
export const PUBLISH_WORKFLOW_PENDING = "PUBLISH_WORKFLOW_PENDING";
export const PUBLISH_WORKFLOW_COMPLETE = "PUBLISH_WORKFLOW_COMPLETE";
export const LIVE_WORKFLOW_PUBLISHED = "LIVE_WORKFLOW_PUBLISHED";
export const SET_WORKFLOW_HAS_CHANGED = "SET_WORKFLOW_HAS_CHANGED";
export const UPDATE_WORKFLOW_ROOT = "UPDATE_WORKFLOW_ROOT";
export const UPDATE_WORKFLOW_CONNECTION_ROUTE = "UPDATE_WORKFLOW_CONNECTION_ROUTE";
export const ADD_ELEMENT_TO_PREV_ROUTE = "ADD_ELEMENT_TO_PREV_ROUTE";
export const SHOW_CONDITIONAL_VARIABLE_SELECTION_DIALOG = "SHOW_CONDITIONAL_VARIABLE_SELECTION_DIALOG";
export const CLOSE_CONDITIONAL_VARIABLE_SELECTION_DIALOG = "CLOSE_CONDITIONAL_VARIABLE_SELECTION_DIALOG";
export const UPDATE_SELECTED_EXTRA_OPTIONS_KEY = "UPDATE_SELECTED_EXTRA_OPTIONS_KEY";

export const CREATE_WORKFLOW_CATEGORY = "CREATE_WORKFLOW_CATEGORY";
export const UPDATE_WORKFLOW_CATEGORY = "UPDATE_WORKFLOW_CATEGORY";
export const DELETE_WORKFLOW_CATEGORY = "DELETE_WORKFLOW_CATEGORY";

export const PAN_DIAGRAM = "PAN_DIAGRAM";
export const SELECT_ELEMENT = "SELECT_ELEMENT";
export const DESELECT_ELEMENT = "DESELECT_ELEMENT";
export const SELECT_ROUTE = "SELECT_ROUTE";
export const DESELECT_ROUTE = "DESELECT_ROUTE";
export const UPDATE_SELECTED_STEP_SECTION = "UPDATE_SELECTED_STEP_SECTION";
export const SHOW_MULTI_CONNECTION_SETUP = "SHOW_MULTI_CONNECTION_SETUP";
export const UNSET_PARENT_CONDITION_EDIT_MODE = "UNSET_PARENT_CONDITION_EDIT_MODE";
export const SHOW_FORM_EDITOR_SIDEBAR = "SHOW_FORM_EDITOR_SIDEBAR";
export const ELEMENT_DRAG_BEGIN = "ELEMENT_DRAG_BEGIN";
export const ELEMENT_DRAG_END = "ELEMENT_DRAG_END";
export const ELEMENT_HOVER_BEGIN = "ELEMENT_HOVER_BEGIN";
export const ELEMENT_HOVER_END = "ELEMENT_HOVER_END";
export const UPDATE_EDITOR_ZOOMED_PERCENT = "UPDATE_EDITOR_ZOOMED_PERCENT";
export const RESET_WORKFLOW_EDITOR = "RESET_WORKFLOW_EDITOR";

export const UPDATE_CONTENT_ELEMENT_EDIT_MODE = "UPDATE_CONTENT_ELEMENT_EDIT_MODE";
export const CREATE_CONTENT_ELEMENT = "CREATE_CONTENT_ELEMENT";
export const CREATE_CONDITIONAL_CONTENT_CHILD = "CREATE_CONDITIONAL_CONTENT_CHILD";
export const UPDATE_CONTENT_ELEMENT = "UPDATE_CONTENT_ELEMENT";
export const MOVE_CONTENT_ELEMENT = "MOVE_CONTENT_ELEMENT";
export const MOVE_CONTENT_ELEMENT_THROTTLED = "MOVE_CONTENT_ELEMENT_THROTTLED";
export const DELETE_CONTENT_ELEMENT = "DELETE_CONTENT_ELEMENT";
export const SET_ELEMENT_TO_REORDER = "SET_ELEMENT_TO_REORDER";
export const UPDATE_CONTENT_CONDITION = "UPDATE_CONTENT_CONDITION";
export const ADD_CONTENT_CONDITION = "ADD_CONTENT_CONDITION";
export const DELETE_CONTENT_CONDITION = "DELETE_CONTENT_CONDITION";

export const UPDATE_STEP_CONTENT_IMAGE_DETAILS = "UPDATE_STEP_CONTENT_IMAGE_DETAILS";

export const SELECT_FORM_ELEMENT = "SELECT_FORM_ELEMENT";
export const SELECT_CONDITIONAL_ELEMENT = "SELECT_CONDITIONAL_ELEMENT";
export const CREATE_FIELD = "CREATE_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const DELETE_FIELD = "DELETE_FIELD";
export const UPDATE_FORM_LAYOUT = "UPDATE_FORM_LAYOUT";
export const UPDATE_FORM_DETAILS = "UPDATE_FORM_DETAILS";
export const UPDATE_FORM_ACCESS = "UPDATE_FORM_ACCESS";
export const SELECT_FORM_FIELD = "SELECT_FORM_FIELD";

export const UPDATE_START_MARKER = "UPDATE_START_MARKER";
export const MOVE_START_MARKER = "MOVE_START_MARKER";
export const UPDATE_START_MARKER_ROUTE_DIRECTION = "UPDATE_START_MARKER_ROUTE_DIRECTION";

export const CREATE_CONNECTOR = "CREATE_CONNECTOR";
export const REMOVE_CONNECTOR = "REMOVE_CONNECTOR";
export const MOVE_CONNECTOR = "MOVE_CONNECTOR";

export const CREATE_STEP = "CREATE_STEP";
export const MOVE_STEP = "MOVE_STEP";
export const DELETE_STEP = "DELETE_STEP";
export const UPDATE_STEP_NAME = "UPDATE_STEP_NAME";
export const CHANGE_STEP_CONNECTION_TYPE = "CHANGE_STEP_CONNECTION_TYPE";
export const SELECT_CONNECTION_FIELD = "SELECT_CONNECTION_FIELD";
export const ADD_ROUTE_WITH_CONNECTOR = "ADD_ROUTE_WITH_CONNECTOR";
export const DELETE_ROUTE = "DELETE_ROUTE";
export const REMOVE_ROUTE_CONNECTION = "REMOVE_ROUTE_CONNECTION";
export const ADD_ROUTE_CONDITION = "ADD_ROUTE_CONDITION";
export const UPDATE_ROUTE_CONDITION = "UPDATE_ROUTE_CONDITION";
export const DELETE_ROUTE_CONDITION = "DELETE_ROUTE_CONDITION";
export const DEBOUNCE_ROUTE_CONDITION_UPDATE = "DEBOUNCE_ROUTE_CONDITION_UPDATE";
export const MOVE_ROUTE_CONDITION = "MOVE_ROUTE_CONDITION";
export const UPDATE_ROUTE_CONDITION_DIRECTION = "UPDATE_ROUTE_CONDITION_DIRECTION";
export const UPDATE_ROUTE_CONDITION_JOIN_TYPE = "UPDATE_ROUTE_CONDITION_JOIN_TYPE";
export const MOVE_ROUTE_LABEL = "MOVE_ROUTE_LABEL";
export const SET_USE_CUSTOM_LABEL_POSITION = "SET_USE_CUSTOM_LABEL_POSITION";
export const UPDATE_STEP_ASSIGNMENT_POOL = "UPDATE_STEP_ASSIGNMENT_POOL";
export const UPDATE_STEP_ASSIGNMENT_DISTRIBUTION = "UPDATE_STEP_ASSIGNMENT_DISTRIBUTION";
export const RESET_STEP_ASSIGNMENT_DISTRIBUTION = "RESET_STEP_ASSIGNMENT_DISTRIBUTION";
export const DELETE_STEP_ASSIGNMENT_DISTRIBUTION = "DELETE_STEP_ASSIGNMENT_DISTRIBUTION";

export const CHANGE_STEP_ACCESS = "CHANGE_STEP_ACCESS";
export const CHANGE_TIMEFRAME = "CHANGE_TIMEFRAME";

export const CREATE_FORM_FIELD = "CREATE_FORM_FIELD";

export const CREATE_END = "CREATE_END";
export const REMOVE_END_PIECE = "REMOVE_END_PIECE";
export const MOVE_END_PIECE = "MOVE_END_PIECE";

export const CREATE_ACTION = "CREATE_ACTION";
export const MOVE_ACTION = "MOVE_ACTION";
export const DELETE_ACTION = "DELETE_ACTION";
export const UPDATE_ACTION = "UPDATE_ACTION";
export const CHANGE_ACTION_BUILDER_STAGE = "CHANGE_ACTION_BUILDER_STAGE";

export const CREATE_SUB_WORKFLOW_CONNECTOR = "CREATE_SUB_WORKFLOW_CONNECTOR";
export const MOVE_SUB_WORKFLOW_CONNECTOR = "MOVE_SUB_WORKFLOW_CONNECTOR";
export const UPDATE_SUB_WORKFLOW_CONNECTOR = "UPDATE_SUB_WORKFLOW_CONNECTOR";
export const DELETE_SUB_WORKFLOW_CONNECTOR = "DELETE_SUB_WORKFLOW_CONNECTOR";

export const CREATE_DELAY = "CREATE_DELAY";
export const MOVE_DELAY = "MOVE_DELAY";
export const UPDATE_DELAY = "UPDATE_DELAY";
export const DELETE_DELAY = "DELETE_DELAY";

export const SET_ACCESS_TOKENS = "SET_ACCESS_TOKENS";
export const CREATE_ACCESS_TOKEN = "CREATE_ACCESS_TOKEN";
export const STORE_PARTIAL_ACCESS_TOKEN = "STORE_PARTIAL_ACCESS_TOKEN";
export const DELETE_ACCESS_TOKEN = "DELETE_ACCESS_TOKEN";
export const SET_WEBHOOKS = "SET_WEBHOOKS";
export const CREATE_WEBHOOK = "CREATE_WEBHOOK";
export const UPDATE_WEBHOOK = "UPDATE_WEBHOOK";
export const DELETE_WEBHOOK = "DELETE_WEBHOOK";
export const SET_EXTERNAL_ENDPOINTS = "SET_EXTERNAL_ENDPOINTS";
export const SET_EXTERNAL_ENDPOINT = "SET_EXTERNAL_ENDPOINT";
export const SET_EXTERNAL_ENDPOINT_PARTS = "SET_EXTERNAL_ENDPOINT_PARTS";
export const CREATE_EXTERNAL_ENDPOINT = "EXTERNAL_ENDPOINT";
export const UPDATE_EXTERNAL_ENDPOINT = "UPDATE_EXTERNAL_ENDPOINT";
export const DELETE_EXTERNAL_ENDPOINT = "DELETE_EXTERNAL_ENDPOINT";
export const UPDATE_EXTERNAL_ENDPOINT_PROPERTY = "UPDATE_EXTERNAL_ENDPOINT_PROPERTY";
export const UPDATE_EXTERNAL_ENDPOINT_SETTING = "UPDATE_EXTERNAL_ENDPOINT_SETTING";
export const DELETE_EXTERNAL_ENDPOINT_SETTING = "DELETE_EXTERNAL_ENDPOINT_SETTING";
export const CAPTURE_EXTERNAL_ENDPOINT_RESPONSE = "CAPTURE_EXTERNAL_ENDPOINT_RESPONSE";
export const DELETE_EXTERNAL_ENDPOINT_RESPONSE = "DELETE_EXTERNAL_ENDPOINT_RESPONSE";
export const UPDATE_EXTERNAL_ENDPOINT_RESPONSE_VARIABLE = "UPDATE_EXTERNAL_ENDPOINT_RESPONSE_VARIABLE";
export const ACTIVATE_ALL_EXTERNAL_ENDPOINT_RESPONSE_VARIABLES =
	"ACTIVATE_ALL_EXTERNAL_ENDPOINT_RESPONSE_VARIABLES";
export const UPDATE_SELECTED_EXTERNAL_ENDPOINT_RESPONSE = "UPDATE_SELECTED_EXTERNAL_ENDPOINT_RESPONSE";
export const SET_EXTERNAL_ENDPOINT_RESPONSE = "SET_EXTERNAL_ENDPOINT_RESPONSE";
export const CLEANUP_EXTERNAL_ENDPOINT = "CLEANUP_EXTERNAL_ENDPOINT";

// Hitgraph specific actions
export const ADD_STEP_KEYS = "ADD_STEP_KEYS";
export const ADD_ACTION_KEYS = "ADD_ACTION_KEYS";
export const ADD_DELAY_KEYS = "ADD_DELAY_KEYS";
export const ADD_CONNECTOR_KEYS = "ADD_CONNECTOR_KEYS";
export const ADD_ROUTE_KEYS = "ADD_ROUTE_KEYS";
export const ADD_START_MARKER_KEY = "ADD_START_MARKER_KEY";
export const ADD_END_KEYS = "ADD_END_KEYS";
export const ADD_ROUTE_CONDITION_MARKER = "ADD_ROUTE_CONDITION_MARKER";
export const ADD_SUB_WORKFLOW_KEYS = "ADD_SUB_WORKFLOW_KEYS";

// loading data specific
export const REQUEST_WORKFLOW_PARTS = "REQUEST_WORKFLOW_PARTS";
export const REQUEST_WORKFLOW = "REQUEST_WORKFLOW";
export const REQUEST_FULL_WORKFLOW = "REQUEST_FULL_WORKFLOW";
export const SET_WORKFLOW_PARTS = "SET_WORKFLOW_PARTS";
export const SET_WORKFLOW = "SET_WORKFLOW";
export const SET_FULL_WORKFLOW = "SET_FULL_WORKFLOW";

// jobs
export const LOADING_JOBS = "LOADING_JOBS";
export const JOB_REFRESH_COUNT = "JOB_REFRESH_COUNT";
export const START_MONITORING_FIELD_CHANGES = "START_MONITORING_FIELD_CHANGES";
export const STOP_MONITORING_FIELD_CHANGES = "STOP_MONITORING_FIELD_CHANGES";
export const SET_JOBS = "SET_JOBS";
export const SET_NEW_JOB = "SET_NEW_JOB";
export const SET_JOB = "SET_JOB";
export const STARTING_NEW_JOB = "STARTING_NEW_JOB";
export const SET_FULL_JOB = "SET_FULL_JOB";
export const SET_JOB_STEP = "SET_JOB_STEP";
export const RECEIVE_COMPLETE_STEP_RESPONSE = "RECEIVE_COMPLETE_STEP_RESPONSE";
export const SET_STEP_BLUEPRINT = "SET_STEP_BLUEPRINT";
export const SET_STEP_BLUEPRINTS = "SET_STEP_BLUEPRINTS";
export const SET_ELEMENT_BLUEPRINTS = "SET_ELEMENT_BLUEPRINTS";
export const SET_ACTION_BLUEPRINT = "SET_ACTION_BLUEPRINT";
export const SET_STEP_CONTENT_ELEMENT = "SET_STEP_CONTENT_ELEMENT";
export const SET_FIRST_STEP = "SET_FIRST_STEP";
export const SET_JOB_TABLE_VIEWS = "SET_JOB_TABLE_VIEWS";
export const TOGGLE_JOB_TABLE_VIEW_CONTROL = "TOGGLE_JOB_TABLE_VIEW_CONTROL";
export const LOADING_NEXT_STEP = "LOADING_NEXT_STEP";
export const CLEANUP_OPEN_JOB = "CLEANUP_OPEN_JOB";
export const CLEAR_UNSAVED_FIELDS = "CLEAR_UNSAVED_FIELDS";
export const JOB_STATUS_CHANGE = "JOB_STATUS_CHANGE";
export const DELETE_JOB = "DELETE_JOB";
export const WAIT_FOR_INSTRUCTIONS = "WAIT_FOR_INSTRUCTIONS";
export const START_POLLING_FOR_WAITING_JOB_STEP = "START_POLLING_FOR_WAITING_JOB_STEP";
export const STOP_POLLING_FOR_WAITING_JOB_STEP = "STOP_POLLING_FOR_WAITING_JOB_STEP";
export const POLL_FOR_WAITING_JOB_STEP = "POLL_FOR_WAITING_JOB_STEP";
export const RESET_JOB_STEP_POLLING_COUNT = "RESET_JOB_STEP_POLLING_COUNT";
export const SET_JOB_STEP_INSTRUCTIONS = "SET_JOB_STEP_INSTRUCTIONS";
export const SET_NEXT_JOB_STEP_KEY = "SET_NEXT_JOB_STEP_KEY";
export const CLEAR_JOB_STEP_WAITING = "CLEAR_JOB_STEP_WAITING";
export const MANUALLY_RESEND_ACTION = "MANUALLY_RESEND_ACTION";
export const CANCEL_SCHEDULED_RESEND = "CANCEL_SCHEDULED_RESEND";
export const CREATE_JOB_COMMENT = "CREATE_JOB_COMMENT";
export const EDIT_JOB_COMMENT = "EDIT_JOB_COMMENT";
export const DELETE_JOB_COMMENT = "DELETE_JOB_COMMENT";
export const SET_INCLUDE_KNOWLEDGE_BASE = "SET_INCLUDE_KNOWLEDGE_BASE";
export const UPDATE_OPEN_JOB_KB_SETTINGS = "UPDATE_OPEN_JOB_KB_SETTINGS";
export const SET_OPEN_JOB_KB = "SET_OPEN_JOB_KB";

//views
export const UPDATE_SELECTED_JOB_VIEW = "UPDATE_SELECTED_JOB_VIEW";
export const SAVE_NEW_JOB_TABLE_VIEW = "SAVE_NEW_JOB_TABLE_VIEW";
export const UPDATE_TABLE_VIEW_COLUMNS = "UPDATE_TABLE_VIEW_COLUMNS";
export const UPDATE_TABLE_VIEW_ACCESS = "UPDATE_TABLE_VIEW_ACCESS";
export const UPDATE_TABLE_VIEW_FILTERS = "UPDATE_TABLE_VIEW_FILTERS";
export const SET_TABLE_DEFAULT_VIEW = "SET_TABLE_DEFAULT_VIEW";
export const RENAME_JOB_TABLE_VIEW = "RENAME_JOB_TABLE_VIEW";
export const RESIZE_JOB_TABLE_VIEW_COLUMN = "RESIZE_JOB_TABLE_VIEW_COLUMN";
export const DELETE_JOB_TABLE_VIEW = "DELETE_JOB_TABLE_VIEW";

export const CREATE_JOB_STEP = "CREATE_JOB_STEP";
export const UPDATE_JOB_STEP = "UPDATE_JOB_STEP";
export const UPDATE_JOB_STEP_ASSIGNEES = "UPDATE_JOB_STEP_ASSIGNEES";

export const UPDATE_JOB_FIELD = "UPDATE_JOB_FIELD";
export const SET_FIELDS_TO_PREFILL = "SET_FIELDS_TO_PREFILL";
export const PREFILL_JOB_FIELD = "PREFILL_JOB_FIELD";
export const ADD_FIELD_TO_JOB_STEP = "ADD_FIELD_TO_JOB_STEP";
export const START_UPLOAD_JOB_MEDIA = "START_UPLOAD_JOB_MEDIA";
export const CREATE_JOB_CONTENT_ITEM = "CREATE_JOB_CONTENT_ITEM";
export const UPDATE_JOB_CONTENT_ITEM = "UPDATE_JOB_CONTENT_ITEM";
export const DELETE_JOB_CONTENT_ITEM = "DELETE_JOB_CONTENT_ITEM";
export const DELETE_JOB_UPLOADED_ASSET = "DELETE_JOB_UPLOADED_ASSET";

// users
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_IMAGE = "UPDATE_USER_PROFILE_IMAGE";
export const DELETE_USER = "DELETE_USER";
export const SET_ROLES = "SET_ROLES";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const CREATE_JOB_TITLE = "CREATE_JOB_TITLE";
export const UPDATE_JOB_TITLE = "UPDATE_JOB_TITLE";
export const DELETE_JOB_TITLE = "DELETE_JOB_TITLE";

export const SET_DASHBOARD_VIEWS = "SET_DASHBOARD_VIEWS";
export const SET_DASHBOARD_SETTINGS = "SET_DASHBOARD_SETTINGS";
export const CHANGE_DASHBOARD_VIEW = "CHANGE_DASHBOARD_VIEW";

export const KB_SHOW_ACCESS_CONTROL = "KB_SHOW_ACCESS_CONTROL";
export const KB_HIDE_ACCESS_CONTROL = "KB_HIDE_ACCESS_CONTROL";
export const SET_KNOWLEDGE_BASE = "SET_KNOWLEDGE_BASE";
export const KB_CREATE_CATEGORY = "KB_CREATE_CATEGORY";
export const KB_UPDATE_CATEGORY = "KB_UPDATE_CATEGORY";
export const KB_DELETE_CATEGORY = "KB_DELETE_CATEGORY";
export const KB_CREATE_PAGE = "KB_CREATE_PAGE";
export const KB_SELECT_PAGE = "KB_SELECT_PAGE";
export const KB_DESELECT_PAGE = "KB_DESELECT_PAGE";
export const KB_CREATE_PAGE_ELEMENT = "KB_CREATE_PAGE_ELEMENT";
export const KB_UPDATE_PAGE_ELEMENT = "KB_UPDATE_PAGE_ELEMENT";
export const KB_UPDATE_PAGE = "KB_UPDATE_PAGE";
export const KB_DELETE_PAGE = "KB_DELETE_PAGE";
export const KB_TOGGLE_CATEGORY = "KB_TOGGLE_CATEGORY";
export const KB_SET_PAGE_ELEMENTS = "KB_SET_PAGE_ELEMENTS";
export const KB_UPDATE_PAGE_ELEMENT_IMAGE_DETAILS = "KB_UPDATE_PAGE_ELEMENT_IMAGE_DETAILS";
export const KB_MOVE_PAGE_ELEMENT = "KB_MOVE_PAGE_ELEMENT";
export const KB_MOVE_PAGE_ELEMENT_THROTTLED = "KB_MOVE_PAGE_ELEMENT_THROTTLED";
export const KB_DELETE_PAGE_ELEMENT = "KB_DELETE_PAGE_ELEMENT";
export const KB_CHANGE_PAGE_MODE = "KB_CHANGE_PAGE_MODE";
export const KB_RESET = "KB_RESET";
export const KB_UPDATE_CATEGORY_ACCESS_TYPE = "KB_UPDATE_CATEGORY_ACCESS_TYPE";
export const KB_UPDATE_CATEGORY_WORKFLOW_GRANT_NEW = "KB_UPDATE_CATEGORY_WORKFLOW_GRANT_NEW";
export const KB_UPDATE_CATEGORY_RESTRICTED_ROLE = "KB_UPDATE_CATEGORY_RESTRICTED_ROLE";
export const KB_UPDATE_CATEGORY_RESTRICTED_ROLES = "KB_UPDATE_CATEGORY_RESTRICTED_ROLES";
export const KB_SELECT_ALL_CATEGORY_ROLES = "KB_SELECT_ALL_CATEGORY_ROLES";
export const KB_DESELECT_ALL_CATEGORY_ROLES = "KB_DESELECT_ALL_CATEGORY_ROLES";
export const KB_UPDATE_CATEGORY_RESTRICTED_WORKFLOWS = "KB_UPDATE_CATEGORY_RESTRICTED_WORKFLOWS";

// real time updates
export const LIVE_SET_JOB = "LIVE_SET_JOB";
export const LIVE_JOB_STATUS_UPDATED = "LIVE_JOB_STATUS_UPDATED";
export const LIVE_JOB_UPDATED = "LIVE_JOB_UPDATED";
export const LIVE_CREATE_JOB_STEP = "LIVE_CREATE_JOB_STEP";
export const LIVE_CREATE_JOB_ACTION = "LIVE_CREATE_JOB_ACTION";
export const LIVE_CREATE_JOB_DELAY = "LIVE_CREATE_JOB_DELAY";
export const LIVE_UPDATE_JOB_STEP = "LIVE_UPDATE_JOB_STEP";
export const LIVE_JOB_FIELDS_UPDATED = "LIVE_JOB_FIELDS_UPDATED";
export const LIVE_APPEND_JOB_LOG_ENTRY = "LIVE_APPEND_JOB_LOG_ENTRY";
export const LIVE_ADD_JOB_ACTIVE_ELEMENT = "LIVE_ADD_JOB_ACTIVE_ELEMENT";
export const LIVE_UPDATE_JOB_ACTION = "LIVE_UPDATE_JOB_ACTION";
export const LIVE_REMOVE_JOB_ACTIVE_ELEMENT = "LIVE_REMOVE_JOB_ACTIVE_ELEMENT";
export const LIVE_REMOVE_ALL_JOB_ACTIVE_ELEMENTS = "LIVE_REMOVE_ALL_JOB_ACTIVE_ELEMENTS";
export const LIVE_ADD_JOB_TO_DASHBOARDS = "LIVE_ADD_JOB_TO_DASHBOARDS";
export const LIVE_CLOSE_JOB = "LIVE_CLOSE_JOB";
export const LIVE_REFRESH_JOB_VIEWS = "LIVE_REFRESH_JOB_VIEWS";
export const LIVE_JOB_TIMESTAMP_UPDATED = "LIVE_JOB_TIMESTAMP_UPDATED";
export const LIVE_JOB_STEP_ASSIGNEES_UPDATED = "LIVE_JOB_STEP_ASSIGNEES_UPDATED";
export const LIVE_USER_COMPLETED_JOB_STEP = "LIVE_USER_COMPLETED_JOB_STEP";
export const LIVE_JOB_COMMENT_ADDED = "LIVE_JOB_COMMENT_ADDED";
export const LIVE_ACCOUNT_UPDATED = "LIVE_ACCOUNT_UPDATED";
export const LIVE_LOG_OUT_USER = "LIVE_LOG_OUT_USER";
// export const LIVE_UPDATE_VIEW = "LIVE_UPDATE_VIEW";
// export const LIVE_UPDATE_VIEW_COLUMNS = "LIVE_UPDATE_VIEW_COLUMNS";
