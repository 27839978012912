import React, { useEffect } from "react";
import { Ability, KeyValuePairsInput, LoadState, Spinner } from "design-system";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import { INITIAL_RENDER_ONLY, TOP_NAV_HEIGHT } from "../../../../utils/constants";
import Header from "../Header";
import RawResponsePreview from "../Response/RawResponsePreview";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../../initialState";
import { fetchFromApi, sendDebouncedRequestToAPI, sendRequestToAPI } from "../../../../sharedActions";
import { DeveloperAPI } from "../../../../api/DeveloperAPI";
import { canNotAccess } from "../../../../utils/helpers";
import Unauthorized from "../../../Unauthorized/Unauthorized";
import {
	cleaupExternalEndpoint,
	updateExternalEndpoint,
	updateExternalEndpointProperty,
	updateExternalEndpointSetting,
} from "../../developerActions";
import PayloadOptions from "../PayloadOptions";
import PayloadPreview from "../PayloadPreview";

import styles from "./PayloadBuilder.module.scss";

interface Props extends RouteComponentProps {}

const PayloadBuilder: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const endpoints = useSelector((state: InitialState) => state.externalEndpoints);
	const endpointsLoaded = useSelector(
		(state: InitialState) => state.loadStates.externalEndpoints === LoadState.LOADED
	);

	useEffect(() => {
		if (!endpointsLoaded) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpoints()));
		}

		return function cleanup() {
			dispatch(cleaupExternalEndpoint());
		};
	}, INITIAL_RENDER_ONLY);

	useEffect(() => {
		if (!endpointsLoaded) return;

		if (!endpoints.hasOwnProperty(params.externalEndpointKey)) {
			window.location.replace("/manage/developers/external-endpoints");
		}
	}, [endpointsLoaded]);

	if (canNotAccess(Ability.ManageDeveloperSettings)) {
		return <Unauthorized requiredPermission="Manage developer settings" />;
	}

	if (!endpointsLoaded) {
		return <Spinner />;
	}

	const endpoint = endpoints[params.externalEndpointKey];

	if (endpoint === undefined) {
		return <Spinner />;
	}

	function updateKeyValuePairs(
		settingName: string,
		identifier: "key" | "value",
		index: number,
		value: string
	) {
		if (!endpoint) return;

		if (!endpoint.settings[settingName]) {
			endpoint.settings.customProperties = [{ key: "", value: "" }];
		}

		const update = endpoint.settings[settingName].map((property: any, i: number) => {
			if (index === i) {
				property[identifier] = value;
			}

			return property;
		});

		dispatch(updateExternalEndpointSetting(endpoint.key, settingName, update));

		dispatch(
			sendRequestToAPI(DeveloperAPI.saveExternalEndpointSettings(endpoint.key, settingName, update))
		);
	}

	function addKeyValuePair(settingName: string) {
		if (!endpoint) return;

		const update = (endpoint.settings[settingName] = [
			...endpoint.settings[settingName],
			{ key: "", value: "" },
		]);

		dispatch(updateExternalEndpointSetting(endpoint.key, settingName, update));

		dispatch(
			sendRequestToAPI(DeveloperAPI.saveExternalEndpointSettings(endpoint.key, settingName, update))
		);
	}

	function deleteKeyValuePair(settingName: string, index: number) {
		if (!endpoint) return;

		const update = endpoint.settings[settingName].filter((property: any, i: number) => i !== index);

		dispatch(updateExternalEndpointSetting(endpoint.key, settingName, update));

		dispatch(
			sendRequestToAPI(DeveloperAPI.saveExternalEndpointSettings(endpoint.key, settingName, update))
		);
	}

	function formatCustomProperties(customProperties: undefined | Array<any>) {
		if (!customProperties) {
			return [{ key: "", value: "" }];
		} else if (customProperties.length === 0) {
			return [{ key: "", value: "" }];
		}

		return customProperties;
	}

	function updatePayload(selectedOption: string) {
		if (!endpoint) return;

		let payload = [...endpoint.payload];
		if (endpoint.payload.includes(selectedOption)) {
			payload = endpoint.payload.filter((option: string) => option !== selectedOption);
		} else {
			payload.push(selectedOption);
		}

		dispatch(updateExternalEndpointProperty(endpoint.key, "payload", payload));
		dispatch(
			sendDebouncedRequestToAPI(
				DeveloperAPI.saveExternalEndpointProperty(endpoint.key, "payload", payload)
			)
		);
	}

	return (
		<div className={styles.PayloadBuilder} style={{ minHeight: window.innerHeight - TOP_NAV_HEIGHT }}>
			<div className={styles.Page}>
				<Header
					headerText="Payload Builder"
					tooltipText="Back to External Endpoint"
					onBack={() => navigate(`/manage/developers/external-endpoints/${endpoint.key}`)}
					onDone={() => navigate(`/manage/developers/external-endpoints/${endpoint.key}`)}
				/>

				<div className={styles.Content}>
					<h2>Job Data</h2>
					<span>Choose only the data you need</span>

					<PayloadOptions selectedOptions={endpoint.payload} onClick={updatePayload} />

					<div className={styles.CustomProperties}>
						<h3>Custom Properties</h3>
						<KeyValuePairsInput
							pairs={formatCustomProperties(endpoint.settings.customProperties)}
							withVariableSelection={true}
							onChange={(identifier, index: number, value: string) =>
								updateKeyValuePairs("customProperties", identifier, index, value)
							}
							onAddButtonClick={() => addKeyValuePair("customProperties")}
							onDeletePair={(index: number) => deleteKeyValuePair("customProperties", index)}
						/>
					</div>
				</div>
			</div>
			<PayloadPreview selectedOptions={endpoint.payload} settings={endpoint.settings} />
		</div>
	);
};

export default PayloadBuilder;
