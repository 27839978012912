import React from "react";
import { Button, Integration, IntegrationEvent, IntegrationList, JobAction, Spinner } from "design-system";
import HeaderPlaceholder from "./HeaderPlaceholder";
import ExternalEndpointHeader from "./ExternalEndpoints/ExternalEndpointHeader";

import styles from "./Action.module.scss";

interface Props {
	jobKey: string;
	jobAction: JobAction | undefined;
	integrations: IntegrationList;
	onClickDetails: () => void;
}

function calculateContent(integrations: IntegrationList, jobAction: JobAction | undefined) {
	if (!jobAction) {
		return <HeaderPlaceholder />;
	}

	const event = integrations[jobAction.action.integrationKey].events.find(
		(event) => event.key === jobAction.action.eventKey
	) as IntegrationEvent;

	const integration = integrations[jobAction.action.integrationKey];

	if (integration.key === "external-endpoints") {
		return (
			<ExternalEndpointHeader
				externalEndpointKey={jobAction.action.eventKey}
				logoUrl={integration.logoUrl}
			/>
		);
	}

	return (
		<React.Fragment>
			<img src={integration.logoUrl} />
			<div>
				<h5>{integration.name}</h5>
				<h3>{event && event.name}</h3>
			</div>
		</React.Fragment>
	);
}

function calculateName() {}

const Header: React.FunctionComponent<Props> = ({ jobKey, jobAction, integrations, onClickDetails }) => {
	return (
		<div className={styles.Header}>
			<div className={styles.Integration}>{calculateContent(integrations, jobAction)}</div>
			<div>
				<Button size="compact" theme="solid" tone="light" color="primary" onClick={onClickDetails}>
					Details
				</Button>
			</div>
		</div>
	);
};

export default Header;
