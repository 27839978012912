import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { useDispatch, useSelector, useStore } from "react-redux";
import { InitialState } from "../../../../initialState";
import {
	Ability,
	Button,
	Dropdown,
	DropdownItem,
	JobTitlesList,
	LoadState,
	Me,
	Prompt,
	ShortText,
	Space,
	Tooltip,
	User,
	UserInvite,
	WorkflowAccessType,
} from "design-system";
import { fetchFromApi, sendRequestToAPI } from "../../../../sharedActions";
import { WorkflowAPI } from "../../../../api/WorkflowAPI";
import { INITIAL_RENDER_ONLY } from "../../../../utils/constants";
import WorkflowAccess from "./WorkflowAccess";
import { formatRolesForDropdown, formatSelectedRole } from "./User";
import { createJobTitle, deleteUser, updateUser } from "../../peopleActions";
import { UserAPI } from "../../../../api/UserAPI";
import { canAccess, canNotAccess, convertToPublishedKey } from "../../../../utils/helpers";
import Unauthorized from "../../../Unauthorized/Unauthorized";
import cuid from "cuid";

import styles from "./User.module.scss";

interface Props extends RouteComponentProps {
	getUser: () => User;
}

function calculateInitialJobTitle(user: User, jobTitles: JobTitlesList) {
	if (user.jobTitleKey) {
		const jobTitle = jobTitles[user.jobTitleKey];

		return {
			label: jobTitle.name,
			key: jobTitle.key,
		};
	}

	return null;
}

const EditUser: React.FunctionComponent<Props> = ({ getUser }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const roles = useSelector((state: InitialState) => state.roles);
	const me = useSelector((state: InitialState) => state.me) as Me;
	const workflowsLoaded = useSelector(
		(state: InitialState) => state.loadStates.workingWorkflows === LoadState.LOADED
	);
	const jobTitles = useSelector((state: InitialState) => state.jobTitles);
	const store = useStore();
	const [user, setUser] = useState<User>({ ...getUser() });
	const [jobTitle, setJobTitle] = useState<DropdownItem | null>(calculateInitialJobTitle(user, jobTitles));
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const firstNameRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

	useEffect(() => {
		if (workflowsLoaded) return;

		dispatch(fetchFromApi(WorkflowAPI.unpublishedWorkflows()));
	}, INITIAL_RENDER_ONLY);

	if (canNotAccess(Ability.EditUser)) {
		return <Unauthorized requiredPermission={"Edit a User"} />;
	}

	function update(property: keyof UserInvite, value: any) {
		if (property === "role") {
			setUser({
				...user,
				role: {
					key: value.key,
					name: value.label,
				},
			});

			return;
		}

		setUser({
			...user,
			[property]: value,
		});
	}

	function updateAccessType(type: WorkflowAccessType) {
		setUser({
			...user,
			workflowAccess: {
				...user.workflowAccess,
				type,
			},
		});
	}

	function setAccessWorkflows(workflows: Array<string>) {
		setUser({
			...user,
			workflowAccess: {
				...user.workflowAccess,
				workflows,
			},
		});
	}

	function setAccessWorkflowNewGrant(grantNew: boolean) {
		setUser({
			...user,
			workflowAccess: {
				...user.workflowAccess,
				grantNew,
			},
		});
	}

	function formatJobTitles(jobTitles: JobTitlesList) {
		return Object.keys(jobTitles).map((jobTitleKey) => {
			return {
				label: jobTitles[jobTitleKey].name,
				key: jobTitles[jobTitleKey].key,
			};
		});
	}

	function updateJobTitle(jobTitle: DropdownItem) {
		setJobTitle(jobTitle);
	}

	function addJobTitle(jobTitleName: string) {
		if (!jobTitleName.trim().length) return;

		const jobTitle = {
			key: cuid(),
			name: jobTitleName,
		};

		setJobTitle({
			key: jobTitle.key,
			label: jobTitle.name,
		});

		dispatch(createJobTitle(jobTitle.key, jobTitleName));
		dispatch(sendRequestToAPI(UserAPI.createJobTitle(jobTitle)));
	}

	async function save() {
		const workflows = (store.getState() as InitialState).workflows;
		const nextWorkflowAccess = {
			...user.workflowAccess,
			// In order to prevent showing duplicates in the workflow access component
			// we strip out the published keys, and now we add them back in
			workflows: user.workflowAccess.workflows.reduce((result: Array<string>, workflowKey: string) => {
				const publishedKey = convertToPublishedKey(workflowKey);

				if (workflows.hasOwnProperty(publishedKey)) {
					result.push(publishedKey);
				}

				result.push(workflowKey);

				return result;
			}, []),
		};

		dispatch(
			updateUser({
				...user,
				jobTitleKey: jobTitle === null ? null : jobTitle.key,
				workflowAccess: nextWorkflowAccess,
			})
		);

		dispatch(
			sendRequestToAPI(
				UserAPI.updateUser({
					...user,
					// @ts-ignore
					jobTitleKey: jobTitle === null ? null : jobTitle.key,
					workflowAccess: nextWorkflowAccess,
				})
			)
		);

		await navigate("/manage/users");
	}

	async function handleDelete() {
		setShowDeletePrompt(false);

		dispatch(deleteUser(user.key));
		dispatch(sendRequestToAPI(UserAPI.deleteUser(user.key)));

		await navigate("/manage/users");
	}

	return (
		<div className={styles.EditUser}>
			<h2>Edit User</h2>

			<div>
				<ShortText
					label="First Name"
					id="firstName"
					theme="inside"
					forwardRef={firstNameRef}
					value={user.firstName}
					fullWidth={true}
					onChange={(event) => update("firstName", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={16} />

				<ShortText
					label="Last Name"
					id="lastName"
					theme="inside"
					value={user.lastName}
					fullWidth={true}
					onChange={(event) => update("lastName", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={16} />

				<ShortText
					label="Email"
					id="email"
					theme="inside"
					value={user.email}
					fullWidth={true}
					onChange={(event) => update("email", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={16} />

				<Dropdown
					options={formatJobTitles(jobTitles)}
					onChange={(option: DropdownItem) => updateJobTitle(option)}
					value={jobTitle}
					label="Job Title"
					theme={"inside"}
					placeholder="Select or create a job title"
					creatable={true}
					fullWidth={true}
					saveNewOption={(jobTitle) => addJobTitle(jobTitle)}
				/>

				<Space amount={16} />

				<Dropdown
					label="Role"
					theme="inside"
					options={formatRolesForDropdown(roles)}
					value={formatSelectedRole(user.role.key, roles)}
					fullWidth={true}
					onChange={(option: DropdownItem) => update("role", option)}
				/>

				<WorkflowAccess
					workflowAccess={user.workflowAccess}
					updateGrantNewAccess={setAccessWorkflowNewGrant}
					updateType={updateAccessType}
					setAccessWorkflows={setAccessWorkflows}
				/>

				<Space amount={24} />
				<div className={styles.Actions}>
					<Button theme="solid" tone="dark" color="primary" onClick={save}>
						Save
					</Button>
					{canAccess(Ability.DeleteUser) && me.key !== user.key && (
						<div className={styles.Delete} onClick={() => setShowDeletePrompt(true)}>
							<Tooltip
								id={`delete-user`}
								placement="right"
								trigger={["hover"]}
								overlay={<span>Delete User</span>}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="41"
									height="41"
									viewBox="0 0 41 41"
								>
									<g transform="translate(-170.641 -486.422)">
										<circle
											cx="20"
											cy="20"
											r="20"
											transform="translate(171.141 486.922)"
											fill="#fff"
											stroke="#caced9"
											strokeMiterlimit="10"
											strokeWidth="1"
										/>
										<g transform="translate(183.244 497.954)">
											<path
												d="M195.717,497.031v.877a.685.685,0,0,1-.684.685H194.4v12.77a1.468,1.468,0,0,1-1.465,1.466h-9.848a1.468,1.468,0,0,1-1.466-1.466v-12.77H181a.688.688,0,0,1-.693-.685v-.877a.688.688,0,0,1,.693-.685h3.941s.246-.482.342-.684a.818.818,0,0,1,.685-.386h4.09a.815.815,0,0,1,.684.386c.1.2.342.684.342.684h3.95A.685.685,0,0,1,195.717,497.031Z"
												transform="translate(-180.306 -495.276)"
												fill="#a2a9ba"
											/>
										</g>
									</g>
								</svg>
							</Tooltip>
						</div>
					)}
				</div>
			</div>

			<Prompt
				show={showDeletePrompt}
				onConfirm={handleDelete}
				onCancel={() => setShowDeletePrompt(false)}
			>
				Are you sure you want to delete the user for {user.firstName} {user.lastName}?
			</Prompt>
		</div>
	);
};

export default EditUser;
