import { InitialState } from "../../initialState";
import { NETWORK_FAILURE, SERVER_ERROR, USER_NOT_AUTHENTICATED } from "../../errorTypes";
import {
	ADD_CAPTURED_API_RESPONSE,
	ADD_FEATURE_ITEM_VOTE,
	FILE_UPLOAD_COMPLETE,
	FILE_UPLOAD_FAILED,
	FORGET_CAPTURED_API_RESPONSE,
	INITIAL_LOAD_COMPLETE,
	LIVE_ACCOUNT_UPDATED,
	LIVE_LOG_OUT_USER,
	MONITOR_FILE_UPLOAD_PROGRESS,
	NO_INTERNET_DETECTED,
	RECONNECTED_TO_INTERNET,
	REDIRECT_TO_LOGIN,
	RELEASE_ANIMATIONS,
	REMOVE_FEATURE_ITEM_VOTE,
	SET_APP_ESSENTIALS,
	SET_AUTHORIZED_USER,
	SET_FEATURE_VOTES,
	START_INTRO_VIDEO,
	STOP_INTRO_VIDEO,
	UPDATE_FILE_UPLOAD_PROGRESS,
	UPDATE_SELECTED_JOB_VIEW,
} from "../../actionTypes";
import { Ability, LoadState, Me } from "design-system";

export function account(state: InitialState, action: any) {
	if (action.type === USER_NOT_AUTHENTICATED) {
		return {
			...state,
			bootstrapping: false,
			authenticated: false,
		};
	}

	if (action.type === SERVER_ERROR) {
		return {
			...state,
			system: {
				...state.system,
				online: false,
				capturedError: action.capturedError,
			},
		};
	}

	if (action.type === NETWORK_FAILURE) {
		return {
			...state,
			hasInternet: false,
			online: false,
		};
	}

	if (action.type === NO_INTERNET_DETECTED) {
		return {
			...state,
			hasInternet: false,
		};
	}

	if (action.type === RECONNECTED_TO_INTERNET) {
		return {
			...state,
			hasInternet: true,
		};
	}

	if (action.type === REDIRECT_TO_LOGIN) {
		window.location.replace("/login");
	}

	if (action.type === SET_AUTHORIZED_USER) {
		return {
			...state,
			authenticated: true,
			me: Object.assign({}, action.me),
		};
	}

	if (action.type === SET_APP_ESSENTIALS) {
		// can access not unpublished workflows, then set loading done
		const userRole = (state.me as Me).role.key;
		const canAccessUnpublishedWorkflow = action.roles[userRole].permissions.includes(
			Ability.ViewUnpublishedWorkflow
		);

		return {
			...state,
			account: action.account,
			workflowCategories: action.categories,
			workflows: action.workflows,
			users: action.users,
			formFields: Object.assign({}, state.formFields, action.fields),
			integrations: action.integrations,
			roles: action.roles,
			jobTitles: action.jobTitles,
			loadStates: {
				...state.loadStates,
				publishedWorkflows: LoadState.LOADED,
				workingWorkflows: !canAccessUnpublishedWorkflow ? LoadState.LOADED : LoadState.NOT_LOADED,
			},
		};
	}

	if (action.type === SET_FEATURE_VOTES) {
		return {
			...state,
			potentialFeatures: {
				options: action.options,
				selected: action.votes,
			},
		};
	}

	if (action.type === INITIAL_LOAD_COMPLETE) {
		return {
			...state,
			bootstrapping: false,
		};
	}

	if (action.type === ADD_FEATURE_ITEM_VOTE) {
		return {
			...state,
			potentialFeatures: {
				...state.potentialFeatures,
				selected: [...state.potentialFeatures.selected, action.id],
			},
		};
	}

	if (action.type === REMOVE_FEATURE_ITEM_VOTE) {
		return {
			...state,
			potentialFeatures: {
				...state.potentialFeatures,
				selected: state.potentialFeatures.selected.filter((id) => id !== action.id),
			},
		};
	}

	if (action.type === MONITOR_FILE_UPLOAD_PROGRESS) {
		return {
			...state,
			fileUploadMonitor: {
				...state.fileUploadMonitor,
				[action.key]: {
					progress: null,
					errors: null,
					preview: action.preview,
				},
			},
		};
	}

	if (action.type === UPDATE_FILE_UPLOAD_PROGRESS) {
		return {
			...state,
			fileUploadMonitor: {
				...state.fileUploadMonitor,
				[action.key]: {
					...state.fileUploadMonitor[action.key],
					progress: action.progress,
				},
			},
		};
	}

	if (action.type === FILE_UPLOAD_FAILED) {
		return {
			...state,
			fileUploadMonitor: {
				...state.fileUploadMonitor,
				[action.key]: {
					...state.fileUploadMonitor[action.key],
					errors: action.errors,
				},
			},
		};
	}

	if (action.type === FILE_UPLOAD_COMPLETE) {
		let fileUploadMonitor = Object.assign({}, state.fileUploadMonitor);
		delete fileUploadMonitor[action.key];

		return {
			...state,
			fileUploadMonitor,
		};
	}

	if (action.type === UPDATE_SELECTED_JOB_VIEW) {
		return {
			...state,
		};
	}

	if (action.type === RELEASE_ANIMATIONS) {
		return {
			...state,
			animationsQueue: state.animationsQueue.filter((item) => !action.keys.includes(item)),
		};
	}

	if (action.type === ADD_CAPTURED_API_RESPONSE) {
		const { type, captureKey, ...rest } = action;

		return {
			...state,
			selectedApiResponses: Object.assign({}, state.selectedApiResponses, {
				[captureKey]: { ...rest },
			}),
		};
	}

	if (action.type === FORGET_CAPTURED_API_RESPONSE) {
		let logs = { ...state.selectedApiResponses };

		delete logs[action.key];

		return {
			...state,
			selectedApiResponses: Object.assign({}, logs),
		};
	}

	if (action.type === START_INTRO_VIDEO) {
		return {
			...state,
			support: {
				playIntroVideo: true,
			},
		};
	}

	if (action.type === STOP_INTRO_VIDEO) {
		return {
			...state,
			support: {
				playIntroVideo: false,
			},
		};
	}

	if (action.type === LIVE_ACCOUNT_UPDATED) {
		const { type, ...rest } = action;

		return {
			...state,
			account: {
				...state.account,
				...rest,
			},
		};
	}

	if (action.type === LIVE_LOG_OUT_USER) {
		return {
			...state,
			authenticated: false,
		};
	}

	return state;
}
