import React, { Component, ReactNode } from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";

import styles from "./Animatables.module.scss";

interface Props extends RouteComponentProps {
	className?: string;
	children: ReactNode;
}

class Adiv extends Component<Props> {
	private readonly element: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);

		this.element = React.createRef();
	}

	componentDidMount(): void {
		if (!this.element.current) return;

		this.element.current.animate(
			[
				{ opacity: 0, transform: "translateY(10px)" },
				{ opacity: 1, transform: "translateY(0)" },
			],
			{
				duration: 300,
				easing: "cubic-bezier(0.03, 0.27, 0.32, 1)",
				fill: "forwards",
			}
		);
	}

	// componentWillUnmount(): void {
	// 	if (!this.element.current) return;
	//
	// 	this.element.current.animate(
	// 		[
	// 			{ opacity: "1", transform: "translateY(0)" },
	// 			{ opacity: "0", transform: "translateY(10px)" },
	// 		],
	// 		{
	// 			duration: 300,
	// 			easing: "cubic-bezier(0.03, 0.27, 0.32, 1)",
	// 			fill: "forwards",
	// 		}
	// 	);
	// }

	render() {
		const { className, children } = this.props;

		const classes = classNames([className, styles.Div]);

		return (
			<div className={classes} ref={this.element}>
				{children}
			</div>
		);
	}
}

export default Adiv;
