import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, Card, Space } from "design-system";
import { Stages } from "../Menu";
import Adiv from "../../../Animatables/Adiv";

import styles from "../Activation.module.scss";

interface Props extends RouteComponentProps {
	currentStage: Stages;
	finishActivation: () => void;
}

const ActivationDone: React.FunctionComponent<Props> = ({ currentStage, finishActivation }) => {
	if (currentStage !== Stages.Done) return null;

	return (
		<div className={styles.ActivationDone}>
			<Adiv className={styles.InviteTeam}>
				<Card maxWidth={400}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="43.793"
						height="43.793"
						viewBox="0 0 43.793 43.793"
					>
						<g transform="translate(-928.813 -492.85)">
							<circle
								cx="21.896"
								cy="21.896"
								r="21.896"
								transform="translate(928.813 492.85)"
								fill="#b9e5d6"
							/>
							<path
								d="M935,510.883l8.088,6.65L958.3,501.11"
								transform="translate(4.06 5.425)"
								fill="none"
								stroke="#2a9672"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2.422"
							/>
						</g>
					</svg>

					<Space amount={16} />
					<h2>Your account is now active.</h2>
					<p>You'll all set. Now you're ready to start building your first workflow.</p>
					<Space amount={16} />
					<Button theme="solid" tone="light" color="green" onClick={finishActivation}>
						Let's go!
					</Button>
				</Card>
			</Adiv>
		</div>
	);
};

export default ActivationDone;
