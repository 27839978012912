import React from "react";

import styles from "./Generic.module.scss";

interface Props {
	isCollapsed: boolean;
}

const CollapsedElement: React.FunctionComponent<Props> = ({ isCollapsed, children }) => {
	if (!isCollapsed) {
		return null;
	}

	return <div className={styles.CollapsedElement}>{children}</div>;
};

export default CollapsedElement;
