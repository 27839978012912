import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import Header from "./Header";
import { Button, Card } from "design-system";
import Adiv from "../../Animatables/Adiv";

import styles from "./Activation.module.scss";

interface Props extends RouteComponentProps {
	show: boolean;
}

const InvalidTokenPanel: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.InvalidTokenPanel}>
			<Header />
			<Adiv>
				<Card className={styles.Card} centered={true}>
					<h2>We couldn't activate your account.</h2>
					<p>
						Sorry, it looks like that isn’t a valid activation token. Please try signing up again.
					</p>
					<Button theme="soft" tone="light" onClick={() => navigate("/signup")}>
						Try again
					</Button>
				</Card>
			</Adiv>
		</div>
	);
};

export default InvalidTokenPanel;
