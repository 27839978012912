import React from "react";
import { Card, Dropdown, DropdownItem, Job, Tooltip } from "design-system";
import { useNavigate } from "@reach/router";

import styles from "./JobLogs.module.scss";

interface Props {
	job: Job;
	selectedView: DropdownItem;
	updateView: (view: DropdownItem) => void;
}

const Header: React.FunctionComponent<Props> = ({ job, selectedView, updateView }) => {
	const navigate = useNavigate();

	return (
		<div className={styles.Header}>
			<h2>Job Overview</h2>
			<div>
				<Dropdown
					className={styles.ViewDropdown}
					options={[
						{ label: "Overview", key: "overview" },
						{ label: "Logs", key: "log" },
					]}
					onChange={updateView}
					value={selectedView}
					label=""
				/>

				<Tooltip
					id={`job-overview-close`}
					placement="top"
					trigger={["hover"]}
					overlay={<span>Close</span>}
				>
					<svg
						className={styles.CloseBtn}
						onClick={() => navigate("/jobs")}
						xmlns="http://www.w3.org/2000/svg"
						width="40"
						height="40"
						viewBox="0 0 40 40"
					>
						<g transform="translate(-676 -93)">
							<circle cx="20" cy="20" r="20" transform="translate(676 93)" fill="#e9ebf2" />
							<g transform="translate(685.987 102.333)">
								<path
									d="M665.155,748.385a1.64,1.64,0,0,1-1.107.459,1.564,1.564,0,0,1-1.107-.459l-7.454-7.475-7.538,7.538a1.482,1.482,0,0,1-1.107.459,1.549,1.549,0,0,1-1.107-.459,1.576,1.576,0,0,1,0-2.213l7.538-7.538-7.266-7.266a1.538,1.538,0,0,1,0-2.213,1.575,1.575,0,0,1,2.213,0l7.266,7.266,7.183-7.183a1.575,1.575,0,0,1,2.213,0,1.539,1.539,0,0,1,0,2.213L657.7,738.7l7.454,7.454A1.6,1.6,0,0,1,665.155,748.385Z"
									transform="translate(-645.283 -728.763)"
									fill="#a2a9ba"
								/>
							</g>
						</g>
					</svg>
				</Tooltip>
			</div>
		</div>
	);
};

export default Header;
