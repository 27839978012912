import React, { MutableRefObject, useEffect, useRef } from "react";
import { RouteComponentProps } from "@reach/router";
import { Dropdown, Space, DropdownItem, ShortText, Password } from "design-system";
import { INITIAL_RENDER_ONLY, wait } from "../../../../../utils/constants";
import { findSelectedDropdownItem } from "../../../../../utils/helpers";

const countries = require("../../../../../data/countries.json");
const timezones = require("../../../../../data/timezones.json");

import styles from "../../Activation.module.scss";

interface Props extends RouteComponentProps {
	user: any;
	updateUser: (property: string, value: any) => void;
}

const UserForm: React.FunctionComponent<Props> = ({ user, updateUser }) => {
	const firstNameRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

	useEffect(() => {
		async function focus() {
			await wait(50);

			if (!firstNameRef.current) return;
			firstNameRef.current.focus();
		}

		focus();
	}, INITIAL_RENDER_ONLY);

	return (
		<form className={styles.UserForm}>
			<ShortText
				label="First Name"
				id="firstName"
				theme="inside"
				forwardRef={firstNameRef}
				fullWidth={true}
				value={user.firstName}
				onChange={(event) => updateUser("firstName", (event.target as HTMLInputElement).value)}
			/>

			<Space amount={12} />

			<ShortText
				label="Last Name"
				id="lastName"
				theme="inside"
				fullWidth={true}
				value={user.lastName}
				onChange={(event) => updateUser("lastName", (event.target as HTMLInputElement).value)}
			/>

			<Space amount={12} />

			<Password
				label="Password"
				id="password"
				theme="inside"
				autoComplete={false}
				fullWidth={true}
				value={user.password}
				onChange={(event) => updateUser("password", (event.target as HTMLInputElement).value)}
			/>

			<Space amount={12} />

			<ShortText
				label="Company Name"
				id="company"
				theme="inside"
				fullWidth={true}
				value={user.company}
				onChange={(event) => updateUser("company", (event.target as HTMLInputElement).value)}
			/>

			<Space amount={12} />

			<Dropdown
				options={countries}
				onChange={(country: DropdownItem) => updateUser("country", country)}
				value={user.country}
				theme="inside"
				label="Country"
				searchable={true}
				fullWidth={true}
			/>

			<Space amount={12} />

			<Dropdown
				options={timezones}
				onChange={(timezone: DropdownItem) => updateUser("timezone", timezone.key)}
				value={findSelectedDropdownItem(timezones, user.timezone)}
				label="Timezone"
				searchable={true}
				theme="inside"
				fullWidth={true}
			/>
		</form>
	);
};

export default UserForm;
