import React, { useState } from "react";
import { Button, Modal, Spinner } from "design-system";
import { CodeBlock } from "design-system";
import { useListenForApiResponse } from "../../../utils/hooks/useListenForApiResponse";
import { CAPTURED_TEST_EXTERNAL_ENDPOINT } from "../../../capturedResponseTypes";
import { formatDateForHumans, isValidJson } from "../../../utils/helpers";

import styles from "./ExternalEndpoints.module.scss";

interface Props {
	show: boolean;
	onSend: () => void;
	onClose: () => void;
}

interface Response {
	body: { [key: string]: any };
	status: number;
	timestamp: string;
}

const SendTestModal: React.FunctionComponent<Props> = ({ show, onClose, onSend }) => {
	const [isSending, setIsSending] = useState(false);
	const [response, setResponse] = useState<Response | null>(null);

	useListenForApiResponse(CAPTURED_TEST_EXTERNAL_ENDPOINT, (response) => {
		const dateTime = formatDateForHumans(response.data.timestamp);

		setResponse({
			body: isValidJson(response.data.body)
				? JSON.parse(response.data.body)
				: { response: "Couldn't display the response. Please provide a valid JSON response." },
			status: response.data.status,
			timestamp: `${dateTime?.date} ${dateTime?.time}`,
		});

		setIsSending(false);
	});

	function sendTest() {
		setIsSending(true);

		onSend();
	}

	function handleClose() {
		onClose();
		setIsSending(false);
		setResponse(null);
	}

	return (
		<Modal show={show} maxWidth="625px" toggleModal={() => null} includeBackground={false}>
			<div className={styles.SendTestModal}>
				<div>
					<h1>Send a Test Message</h1>
				</div>

				{isSending && (
					<div className={styles.Loading}>
						<p>Sending Request</p>
						<Spinner size="small" />
					</div>
				)}

				{response !== null && (
					<div className={styles.Response}>
						<div>
							<p>Response</p>
							<span>Received {response.timestamp}</span>
						</div>
						<div className={styles.Code}>
							<h5>Status Code: {response.status}</h5>
							<CodeBlock code={JSON.stringify(response.body, null, 2)} />
						</div>
					</div>
				)}
				<div className={styles.Footer}>
					{response === null && (
						<Button theme="solid" tone="medium" color="primary" onClick={sendTest}>
							Send
						</Button>
					)}
					<span onClick={handleClose}>Close</span>
				</div>
			</div>
		</Modal>
	);
};

export default SendTestModal;
