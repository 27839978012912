import React from "react";
import { LoadingPlaceholder } from "design-system";

interface Props {}

const HeaderPlaceholder: React.FunctionComponent<Props> = () => {
	return (
		<React.Fragment>
			<LoadingPlaceholder width={45} height={40} />
			<div>
				<LoadingPlaceholder width={165} height={23} />
			</div>
		</React.Fragment>
	);
};

export default HeaderPlaceholder;
