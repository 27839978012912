import React, { useEffect } from "react";
import {
	JobStep,
	JobStepTask,
	LoadingPlaceholder,
	LogItem,
	StepContentElement,
	StepContentElementList,
	TaskDetails,
} from "design-system";
import { useDispatch } from "react-redux";
import { INITIAL_RENDER_ONLY } from "../../../../../../../utils/constants";
import { fetchFromApi } from "../../../../../../../sharedActions";
import { WorkflowAPI } from "../../../../../../../api/WorkflowAPI";

import styles from "./Step.module.scss";

interface Props {
	logItem: LogItem;
	jobStep: JobStep;
	task: JobStepTask;
	stepContentElements: StepContentElementList;
}

function calculateTitle(taskBlueprint: StepContentElement | undefined, task: JobStepTask) {
	if (!taskBlueprint && !task.title) {
		return "Missing Task Title";
	}

	if (!taskBlueprint) {
		return task.title;
	}

	return (taskBlueprint.details as TaskDetails).title;
}

const Task: React.FunctionComponent<Props> = ({ logItem, jobStep, task, stepContentElements }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (task.title !== null) {
			return;
		}

		if (stepContentElements[task.key] === undefined) {
			dispatch(
				fetchFromApi(
					WorkflowAPI.loadStepContentElement(logItem.workflowKey, jobStep.step.key, task.key)
				)
			);
		}
	}, INITIAL_RENDER_ONLY);

	if (!stepContentElements[task.key] && !task.title) {
		return (
			<div className={styles.Task}>
				<LoadingPlaceholder width={150} height={18} />
			</div>
		);
	}

	const title = calculateTitle(stepContentElements[task.key], task);

	return (
		<div className={styles.Task}>
			{task.completedAt !== null ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="17.53"
					height="13.112"
					viewBox="0 0 17.53 13.112"
				>
					<path
						d="M30.961,19.064a1.283,1.283,0,0,1-.817-.293L24.954,14.5a1.287,1.287,0,1,1,1.635-1.989l4.253,3.5,8.94-9.647a1.287,1.287,0,1,1,1.889,1.75L31.906,18.652a1.286,1.286,0,0,1-.945.412"
						transform="translate(-24.484 -5.952)"
						fill="#2eb386"
					/>
				</svg>
			) : (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12.342"
					height="13.096"
					viewBox="0 0 12.342 13.096"
				>
					<g>
						<path
							d="M11.936,12.739a1.28,1.28,0,0,1-1.4.25,1.307,1.307,0,0,1-.43-.29l-.32-.35-3.61-3.91-3.62,3.91-.33.35a1.177,1.177,0,0,1-.42.29,1.28,1.28,0,0,1-1.4-.25,1.28,1.28,0,0,1-.13-1.73l.39-.43.49-.52,3.26-3.52L.666,2.489l-.32-.33A1.275,1.275,0,0,1,.426.339a1.3,1.3,0,0,1,1.8.05l.33.36,3.62,3.9,3.61-3.9.33-.36a1.3,1.3,0,0,1,1.8-.05,1.275,1.275,0,0,1,.07,1.82l-.31.33-3.75,4.05,3.26,3.52.49.52.39.43a1.28,1.28,0,0,1-.13,1.73"
							fill="#a2a9ba"
						/>
					</g>
				</svg>
			)}

			<span>{title}</span>
		</div>
	);
};

export default Task;
