import React from "react";
import { Fade, JobStep } from "design-system";

import styles from "./OpenSteps.module.scss";

interface Props {
	jobStep: JobStep;
}

const CompleterCounter: React.FunctionComponent<Props> = ({ jobStep }) => {
	const shouldHide =
		jobStep.assignment.assignees.length <= 1 ||
		jobStep.assignment.requirements.minimumCompleters <= 1 ||
		jobStep.completedAt !== null;
	return (
		<Fade show={!shouldHide}>
			<div className={styles.CompleterCounter}>
				<div className={styles.Title}>To Proceed</div>
				<div className={styles.Count}>
					{jobStep.completedBy !== null && jobStep.completedBy.length}
					<span>/</span>
					{jobStep.assignment.requirements.minimumCompleters}
					<p>Completers</p>
				</div>
			</div>
		</Fade>
	);
};

export default CompleterCounter;
