import React, { MutableRefObject, useEffect, useRef } from "react";

import styles from "./Generic.module.scss";

interface Props {
	isExpanded: boolean;
}

const ExpandedElement: React.FunctionComponent<Props> = ({ isExpanded, children }) => {
	const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

	useEffect(() => {
		if (!ref.current) return;
		if (isExpanded) {
			ref.current.style.visibility = "visible";
			ref.current.style.opacity = "1";
			ref.current.style.transform = "translateY(0)";
		} else {
			ref.current.style.visibility = "hidden";
			ref.current.style.opacity = "0";
			ref.current.style.transform = "translateY(10px)";
		}
	}, [isExpanded]);

	return (
		<div className={styles.ExpandedElement} ref={ref}>
			{isExpanded && children}
		</div>
	);
};

export default ExpandedElement;
