import React, { useEffect, useRef } from "react";
// @ts-ignore
import { Link, RouteComponentProps, useLocation } from "@reach/router";
import { INITIAL_RENDER_ONLY } from "../../../utils/constants";
import { calculateItemMarkerProperties } from "./animations";
import classNames from "classnames";

import styles from "./Header.module.scss";

interface Props extends RouteComponentProps {
	url: string;
	name: string;
	componentName: string;
	animateNavItemMarker: (properties: { width: number; xPos: number }) => void;
	preload: (componentName: string) => void;
}

export function isSelectedNavItem(location: Location, url: string): boolean {
	let [rootPath] = location.pathname.substr(1).split("/");

	if (rootPath.length === 0 || rootPath === "/") {
		// we are at the root /
		return url === "/";
	}

	return url.includes(rootPath);
}

const NavItem: React.FunctionComponent<Props> = ({
	url,
	name,
	componentName,
	animateNavItemMarker,
	preload,
}) => {
	const anchorRef: any = useRef(null);
	const location = useLocation();
	const isSelected = isSelectedNavItem(location, url);

	useEffect(() => {
		if (!isSelected) return;

		animateNavItemMarker(calculateItemMarkerProperties(anchorRef));
	}, INITIAL_RENDER_ONLY);

	const LinkClasses = classNames([isSelected && styles.active]);

	return (
		<div className={styles.Item} onMouseOver={() => preload(componentName)}>
			<Link
				ref={anchorRef}
				to={url}
				onClick={() => animateNavItemMarker(calculateItemMarkerProperties(anchorRef))}
				className={LinkClasses}
			>
				{name}
			</Link>
		</div>
	);
};

export default NavItem;
