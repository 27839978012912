import {
	RECEIVE_COMPLETE_STEP_RESPONSE,
	SET_ACTION_BLUEPRINT,
	SET_ELEMENT_BLUEPRINTS,
	SET_FIRST_STEP,
	SET_FULL_JOB,
	SET_INCLUDE_KNOWLEDGE_BASE,
	SET_JOB_TABLE_VIEWS,
	SET_JOBS,
	SET_STEP_BLUEPRINT,
	SET_STEP_BLUEPRINTS,
} from "../actionTypes";
import { Job, JobComment, JobStatus, Paginator, PersonAssignmentOption } from "design-system";
import { InitialState } from "../initialState";
import { store } from "../store";

export class JobAPI {
	static prepareJob(workflowKey: string) {
		return {
			url: `prepare-job`,
			data: {
				workflowKey,
			},
		};
	}

	static createJob(job: Job, jobStepKey: string | null) {
		return {
			url: `jobs`,
			data: {
				...job,
				jobStepKey,
			},
		};
	}

	static loadJobs(viewKey: string = "init") {
		const state = store.getState() as InitialState;

		let page = 1;

		if (viewKey !== "init" && state.jobTableViews[viewKey].pagination !== null) {
			page = (state.jobTableViews[viewKey].pagination as Paginator).nextPage as number;
		}

		let uri = `jobs/?page=${page}&view=${viewKey}`;

		return {
			url: uri,
			respondWith: SET_JOBS,
		};
	}

	static loadFullJob(jobKey: string) {
		return {
			url: `jobs/${jobKey}/full`,
			respondWith: SET_FULL_JOB,
		};
	}

	static loadActiveStepBlueprintsForJob(jobKey: string) {
		return {
			url: `jobs/${jobKey}/active-step-blueprints`,
			respondWith: SET_STEP_BLUEPRINTS,
		};
	}

	static loadStepBlueprint(jobStepKey: string) {
		return {
			url: `blueprint/steps/${jobStepKey}`,
			respondWith: SET_STEP_BLUEPRINT,
		};
	}

	static loadActionBlueprint(actionKey: string) {
		return {
			url: `actions/${actionKey}`,
			respondWith: SET_ACTION_BLUEPRINT,
		};
	}

	static loadFirstStep(workflowKey: string, jobStepKey: string) {
		return {
			url: `workflows/${workflowKey}/first-step?jobStepKey=${jobStepKey}`,
			respondWith: SET_FIRST_STEP,
		};
	}

	static prefetchNextElements(jobKey: string, jobStepKey: string) {
		return {
			url: `/jobs/${jobKey}/steps/${jobStepKey}/next`,
			respondWith: SET_ELEMENT_BLUEPRINTS,
			method: "GET",
		};
	}

	static loadTableViews() {
		return {
			url: `views/jobs`,
			respondWith: SET_JOB_TABLE_VIEWS,
		};
	}

	static saveStepForm(jobKey: string, jobStepKey: string, stepFormFields: Array<any>) {
		return {
			url: `/jobs/${jobKey}/steps/${jobStepKey}/form`,
			data: {
				formFields: stepFormFields,
			},
		};
	}

	static completeStep(
		jobKey: string,
		jobStepKey: string,
		stepFormFields: Array<any>,
		nextJobStepKey?: string,
		nextStepBlueprintKey?: string
	) {
		return {
			url: `/jobs/${jobKey}/steps/${jobStepKey}/complete`,
			data: {
				nextJobStepKey,
				nextStepBlueprintKey,
				formFields: stepFormFields,
			},
			respondWith: RECEIVE_COMPLETE_STEP_RESPONSE,
		};
	}

	static completeStepAndWait(jobKey: string, jobStepKey: string, stepFormFields: Array<any>) {
		return {
			url: `/jobs/${jobKey}/steps/${jobStepKey}/complete`,
			data: {
				waiting: true,
				formFields: stepFormFields,
			},
		};
	}

	static pollForWaitingJobStep(jobKey: string) {
		return {
			url: `jobs/${jobKey}/poll`,
		};
	}

	static updateJobStepAssignments(
		jobKey: string,
		jobStepKey: string,
		assignees: Array<PersonAssignmentOption>
	) {
		return {
			url: `/jobs/${jobKey}/steps/${jobStepKey}/reassign`,
			data: {
				assignees,
			},
		};
	}

	static prefillJobField(jobKey: string, fields: Array<{ key: string; value: string }>) {
		return {
			url: `/jobs/${jobKey}/prefill-fields`,
			data: {
				fields,
			},
		};
	}

	static updateJobStepTask(
		jobKey: string,
		jobStepKey: string,
		contentKey: string,
		completedAt: string | null
	) {
		return {
			url: `/jobs/${jobKey}/steps/${jobStepKey}/task/${contentKey}`,
			data: {
				completedAt,
			},
		};
	}

	static changeJobStatus(jobKey: string, status: JobStatus) {
		return {
			url: `/jobs/${jobKey}/status`,
			data: {
				status,
			},
		};
	}

	static manuallyResendAction(jobKey: string, jobActionKey: string) {
		return {
			url: `/jobs/${jobKey}/actions/${jobActionKey}/resend`,
			data: {},
		};
	}

	static cancelScheduledResend(jobKey: string, jobActionKey: string) {
		return {
			url: `/jobs/${jobKey}/actions/${jobActionKey}/cancel-scheduled`,
			data: {},
		};
	}

	static deleteJob(jobKey: string) {
		return {
			url: `jobs/${jobKey}`,
			data: {},
			method: "delete",
		};
	}

	static createComment(jobKey: string, comment: JobComment) {
		return {
			url: `/jobs/${jobKey}/comments`,
			data: {
				...comment,
			},
		};
	}

	static editComment(jobKey: string, commentKey: string, message: string) {
		return {
			url: `/jobs/${jobKey}/comments/${commentKey}`,
			data: {
				message,
			},
		};
	}

	static deleteComment(jobKey: string, commentKey: string) {
		return {
			url: `jobs/${jobKey}/comments/${commentKey}`,
			data: {},
			method: "delete",
		};
	}

	static includeKnowledgeBase(workflowKey: string) {
		return {
			url: `include-kb?workflowKey=${workflowKey}`,
			respondWith: SET_INCLUDE_KNOWLEDGE_BASE,
		};
	}

	static exportJobView(viewKey: string) {
		return {
			url: `export/jobs`,
			data: {
				viewKey,
			},
		};
	}
}
