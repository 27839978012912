import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button, Card } from "design-system";
import Adiv from "../../../../Animatables/Adiv";

interface Props extends RouteComponentProps {}

const UserNotFound: React.FunctionComponent<Props> = () => {
	return (
		<div style={{ marginTop: "45px" }}>
			<Adiv>
				<div style={{ maxWidth: "175px", margin: "0 auto 16px auto" }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="100%"
						height="100%"
						viewBox="0 0 306.632 64.573"
					>
						<g id="Group_2568" data-name="Group 2568" transform="translate(-524.743 -130.5)">
							<g id="Group_2566" data-name="Group 2566">
								<path
									id="Path_681"
									data-name="Path 681"
									d="M589.246,164.94a32.276,32.276,0,0,1-56.221,19.421A31.672,31.672,0,0,1,528.9,178.6a26.3,26.3,0,0,1-1.536-3.1,31.619,31.619,0,0,1-2.555-10.724c-.038-.655-.063-1.322-.063-1.976s.025-1.335.063-1.976a32.51,32.51,0,0,1,.868-5.69,31.915,31.915,0,0,1,7.427-14.009c.654-.718,1.334-1.4,2.039-2.052a32.274,32.274,0,0,1,54.106,25.868Z"
									fill="#d9e4fc"
								/>
								<path
									id="Path_682"
									data-name="Path 682"
									d="M622.044,159.146a32.283,32.283,0,0,1-60.219,19.462,31.615,31.615,0,0,1-3.248-8.181H547.116a5.067,5.067,0,0,1-5.066-5.067h0a5.066,5.066,0,0,1,5.066-5.066h10.668a29.171,29.171,0,0,1,.793-5.06H526.228a1.6,1.6,0,0,1-.554-.1,31.915,31.915,0,0,1,7.427-14.009h32.928c.654-.718,1.334-1.4,2.039-2.052a32.283,32.283,0,0,1,53.976,20.074Z"
									fill="#2253c7"
								/>
								<path
									id="Path_683"
									data-name="Path 683"
									d="M548.383,179.929h0a4.432,4.432,0,0,1-4.432,4.432H533.175a.343.343,0,0,1-.256-.114,32.066,32.066,0,0,1-4.026-5.648,28.664,28.664,0,0,1-1.33-2.63.34.34,0,0,1,.312-.472h16.076A4.432,4.432,0,0,1,548.383,179.929Z"
									fill="#2253c7"
								/>
							</g>
							<g id="Group_2567" data-name="Group 2567">
								<path
									id="Path_684"
									data-name="Path 684"
									d="M642.458,141.129h10.575c10.228,0,16.91,5.23,16.91,14.062,0,8.774-6.508,14.527-16.736,14.527h-5.345v14.643h-5.4Zm22.023,14.179c0-5.87-4.242-9.124-10.983-9.124h-5.636v18.537H653.5C660.239,164.721,664.481,161.234,664.481,155.308Z"
									fill="#132859"
								/>
								<path
									id="Path_685"
									data-name="Path 685"
									d="M672.135,152.925h4.242l-.031,4.939h0c2.149-4.067,6.888-5.288,9.677-5.288a11.377,11.377,0,0,1,2.092.174v5.114a13.562,13.562,0,0,0-2.208-.233c-4.184,0-8.427,2.673-8.427,8.833v17.9h-5.345Z"
									fill="#132859"
								/>
								<path
									id="Path_686"
									data-name="Path 686"
									d="M716.384,168.029c0-10.331-6.1-15.8-13.545-15.8-8.136,0-15.158,6.393-15.158,16.563,0,9.7,8.251,15.982,17.6,15.982h9.4v-4.682s-7.047.055-9.4,0c-5.641-.132-11.607-3.455-12.366-9.67h23.295A14.59,14.59,0,0,0,716.384,168.029Zm-23.473-1.63c.759-5.86,5.053-9.525,9.88-9.525,4.294,0,8.3,3.019,8.427,9.525Z"
									fill="#132859"
								/>
								<path
									id="Path_687"
									data-name="Path 687"
									d="M760.153,152.923v4.649h-9.008v27.2h-5.214V169.26l.1-16.337v-7.377h5.117v7.377Z"
									fill="#132859"
								/>
								<path
									id="Path_688"
									data-name="Path 688"
									d="M760.077,168.672c0-10.052,6.914-16.444,14.295-16.444a12.688,12.688,0,0,1,11.214,6.508l-.173-5.811h5.171v31.436h-5.171l.173-5.752a12.636,12.636,0,0,1-11.214,6.45C766.991,185.059,760.077,178.609,760.077,168.672Zm25.336-.058c0-6.74-4.417-11.39-9.937-11.39-5.638,0-9.995,4.65-9.995,11.448,0,6.74,4.357,11.389,9.995,11.389C781,180.061,785.413,175.412,785.413,168.614Z"
									fill="#132859"
								/>
								<path
									id="Path_689"
									data-name="Path 689"
									d="M793.636,152.925h5.694l8.658,25.509h.173l8.66-25.509h5.519l-11.447,31.436h-5.811Z"
									fill="#132859"
								/>
								<path
									id="Path_690"
									data-name="Path 690"
									d="M824.751,142.466a3.313,3.313,0,1,1,3.312,3.253A3.192,3.192,0,0,1,824.751,142.466Zm.64,10.459h5.345v31.436h-5.345Z"
									fill="#132859"
								/>
								<path
									id="Path_691"
									data-name="Path 691"
									d="M724.6,161.233c0,2.4,2.018,3.519,5.279,4.552l.484.145c.388.113.791.243,1.211.356,4.827,1.4,10.244,3.8,10.244,10.124,0,5.763-5.417,8.36-12.681,8.36H719.37v-4.65h10.073c4.1-.08,7.128-1.132,7.128-3.861,0-2.808-2.511-4.485-6.514-5.728a4.3,4.3,0,0,0-.42-.129,4.573,4.573,0,0,0-.5-.161c-5.166-1.566-9.767-3.261-9.767-8.782,0-5.279,4.213-8.54,11.187-8.54h11.3v4.649H730.9c-.194,0-.387,0-.581.017C726.731,157.681,724.6,158.811,724.6,161.233Z"
									fill="#132859"
								/>
							</g>
						</g>
					</svg>
				</div>

				<Card centered={true} maxWidth={550}>
					<h2 style={{ fontSize: "20px" }}>Invalid Join Link</h2>
					<p>
						You should have recieved an email with a link to join. If you've reached this page
						from that link, please ask your account administrator to send you a new link.
					</p>
					<p>or, if you already have an account</p>
					<Button theme="soft" tone="light" onClick={() => navigate("/login")}>
						Log In
					</Button>
				</Card>
			</Adiv>
		</div>
	);
};

export default UserNotFound;
