import { store } from "../../../../store";
import { ViewportBounds } from "./CanvasElement";
import {
	Action,
	CanvasElement,
	CanvasElementType,
	ConditionsSettings,
	ConnectionTypes,
	ContentCondition,
	CursorTypes,
	Delay,
	Directionality, DropdownFieldDetails,
	ElementConnection,
	ElementRoute,
	ElementRoutesList,
	ElementType,
	LinePosition,
	rgbColorString,
	RouteCondition as RouteConditionInterface,
	RouteConditionType,
	Step,
	SubWorkflowConnector,
	VariableType,
} from "design-system";
import { ObjectLookupResult } from "./HitGraph/HitGraph";
import { HitRegion } from "./HitGraph/HitRegion";
import { Diagram } from "./Diagram";
import { Connectors } from "./Objects/Connectors";
import { Connector } from "./Objects/Connector";
import { END_PIECE_HEIGHT, END_PIECE_WIDTH, StartMarkerKey } from "../../../../utils/constants";
import { capitalize, isWorkingKey } from "../../../../utils/helpers";
import { InitialState } from "../../../../initialState";
import { CONDITION_SETUP_OBJECT_HEIGHT, CONDITION_SETUP_OBJECT_WIDTH, RouteCondition } from "./Objects/RouteCondition";
import { Box } from "./Objects/Box";
import { isEndConnectedToElements } from "./DeleteConnection";
import cuid from "cuid";
import { addElementToPrevRoute, moveConnector, removeEndPiece, removeRouteConnection } from "../workflowActions";
import { queueRequestToAPI, sendRequestToAPI } from "../../../../sharedActions";
import { WorkflowAPI } from "../../../../api/WorkflowAPI";
import { JointHitZones } from "./Objects/JointHitZones";
import {
	UNMATCHED_ROUTE_MARKER_HEIGHT,
	UNMATCHED_ROUTE_MARKER_WIDTH,
	UnmatchedRouteMarkers,
} from "./Objects/UnmatchedRouteMarkers";
import moment from "moment";
import { dayOfWeekOptions, dayOptions, monthOptions } from "../Shared/Conditional/systemVariableOptions";

interface RoundedRectangle {
	context: CanvasRenderingContext2D;
	coordX: number;
	coordY: number;
	width: number;
	height: number;
	radius: number;
}

export interface XYCoords {
	x: number;
	y: number;
}

export interface XYPair {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}

export interface SegmentMovement {
	direction: Directionality;
	sign: "+" | "-";
}

export function roundedRectangle({ context, coordX, coordY, width, height, radius }: RoundedRectangle) {
	context.beginPath();
	context.moveTo(coordX + radius, coordY);
	context.lineTo(coordX + width - radius, coordY);

	context.quadraticCurveTo(coordX + width, coordY, coordX + width, coordY + radius);
	context.lineTo(coordX + width, coordY + height - radius);

	context.quadraticCurveTo(coordX + width, coordY + height, coordX + width - radius, coordY + height);
	context.lineTo(coordX + radius, coordY + height);

	context.quadraticCurveTo(coordX, coordY + height, coordX, coordY + height - radius);
	context.lineTo(coordX, coordY + radius);

	context.quadraticCurveTo(coordX, coordY, coordX + radius, coordY);

	context.closePath();
}

export function isNewWorkflow(workflowKey: string): boolean {
	return store.getState().workflows[workflowKey] === undefined;
}

export function inViewportBounds(
	coordX: number,
	coordY: number,
	viewportBounds: ViewportBounds,
	zoomAmount: number
) {
	if (zoomAmount === 1) {
		// don't change the scale when zoomed at 100%
		zoomAmount = 0;
	} else {
		zoomAmount = (zoomAmount - 1) * -1;
	}

	const coords = {
		x: coordX + coordX * zoomAmount,
		y: coordY + coordY * zoomAmount,
	};

	let inBoundsX = false;
	let inBoundsY = false;
	// 100 >= 0 and 100 <= 1200
	if (coords.x >= viewportBounds.x1 && coords.x <= viewportBounds.x2) {
		inBoundsX = true;
	}

	if (coords.y >= viewportBounds.y1 && coords.y <= viewportBounds.y2) {
		inBoundsY = true;
	}

	return inBoundsX && inBoundsY;
}

export function getRandomIntegerBetween(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min + 1) + min);
}

export function extractConnectorFromLookupResults(
	ConnectorLookupResults: { [key: string]: ObjectLookupResult },
	hitRegion: HitRegion,
	diagram: Diagram
): ObjectLookupResult | null {
	const keys = Object.keys(ConnectorLookupResults);

	if (!keys.length) {
		return null;
	}

	if (keys.length === 1) {
		let colorKey = keys[0];

		return ConnectorLookupResults[colorKey];
	}

	const Connectors = diagram.getObjectsCreator().getByType(CanvasElementType.Connectors) as Connectors;

	Object.keys(ConnectorLookupResults).forEach((colorKey: rgbColorString) => {
		const Connector = Connectors.getByKey(ConnectorLookupResults[colorKey].key) as Connector;

		const withinRegion = hitRegion.isWithinRegion(
			Connector.getCoordX() * diagram.getPixelDensity(),
			Connector.getCoordY() * diagram.getPixelDensity()
		);

		if (withinRegion) {
			return ConnectorLookupResults[colorKey];
		}
	});

	return null;
}

export function changeCursor(cursor: CursorTypes) {
	document.body.style.cursor = cursor;
}

export function getRouteDirection(routeKey: string | null | "starter", routes: ElementRoutesList) {
	if (!routeKey) return LinePosition.Top;

	if (routeKey === StartMarkerKey) return LinePosition.Left;

	return routes[routeKey].from.direction;
}

export function fetchElementFromStoreByType(type: ElementType, elementKey: string, workflowKey: string) {
	if (type === ElementType.StartMarker) {
		return store.getState().startMarkers[workflowKey];
	}

	return store.getState()[`${type}s`][elementKey];
}

export function calculateMeasurementsAndCoordsByElementType(type: ElementType, element: CanvasElement) {
	let coordX = 0,
		coordY = 0,
		width = 0,
		height = 0;

	if (type === ElementType.Connector) {
		coordX = element.coordX;
		coordY = element.coordY;
	}

	if (type === ElementType.Step) {
		coordX = element.coordX;
		coordY = element.coordY;
		width = (element as Step).width;
		height = (element as Step).height;
	}

	if (type === ElementType.EndPiece) {
		coordX = element.coordX;
		coordY = element.coordY;
		width = END_PIECE_WIDTH;
		height = END_PIECE_HEIGHT;
	}

	if (type === ElementType.Action) {
		coordX = element.coordX;
		coordY = element.coordY;
		width = (element as Action).width;
		height = (element as Action).height;
	}

	if (type === ElementType.SubWorkflowConnector) {
		const backgroundMargin = 14,
			extraTextOffset = 16;

		coordX = element.coordX - backgroundMargin;
		// show just outside the background
		coordY = element.coordY - backgroundMargin;
		width = (element as SubWorkflowConnector).width + backgroundMargin * 2;
		// include the "workflow" text and background for proper joint placement
		height = (element as SubWorkflowConnector).height + extraTextOffset + backgroundMargin;
	}

	if (type === ElementType.Delay) {
		coordX = element.coordX;
		coordY = element.coordY;
		width = (element as Delay).width;
		height = (element as Delay).height;
	}

	return {
		coordX,
		coordY,
		width,
		height,
	};
}

export function isConnectedToElements(elementKey: string, workflowKey: string): boolean {
	const routes = store.getState().elementRoutes as ElementRoutesList;
	const root = store.getState().workflows[workflowKey].root;

	if (root !== null && root.key === elementKey) {
		// if connected to the Start marker
		return true;
	}

	return Object.keys(routes).some((routeKey) => {
		const route = routes[routeKey];

		if (route.from.key === elementKey && route.to.type !== ElementType.Connector) {
			return true;
		}

		if (route.to.key === elementKey) {
			return true;
		}
	});
}

export function elementHasNotMoved(
	initialCoordX: number,
	initialCoordY: number,
	coordX: number,
	coordY: number
) {
	return initialCoordX - Math.round(coordX) === 0 && initialCoordY - Math.round(coordY) === 0;
}

export function elementWasDeleted(elementType: ElementType, elementKey: string) {
	return store.getState()[`${elementType}s`][elementKey] === undefined;
}

export function getRandomInteger(min: number, max: number): number {
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function assignColorToWorkflow() {
	const colors = [
		"#5583ED",
		"#1F49AB",
		"#2DBAE0",
		"#12668A",
		"#54B997",
		"#24624D",
		"#EA6AA0",
		"#C10F5A",
		"#EAA5C2",
		"#EA6A6F",
		"#D95656",
		"#640F0F",
		"#F5CA64",
		"#D8CB37",
		"#EDC270",
		"#BF6D0A",
		"#7855ED",
		"#1C99E6",
		"#37D8BF",
		"#A0D837",
	];

	// try to assign a unique color
	const workflows = store.getState().workflows;
	const currentColors = Object.keys(workflows).reduce((results: Array<string>, workflowKey: string) => {
		if (!isWorkingKey(workflows[workflowKey].key)) {
			return results;
		}

		results.push(workflows[workflowKey].color);

		return results;
	}, []);

	// try up to 20 times to find a unique color
	let selectedColor = "#5583ED";
	for (let i = 0; i <= 20; i++) {
		const index = getRandomInteger(0, colors.length - 1);
		const color = colors[index];

		if (currentColors.includes(color)) {
			continue;
		}

		selectedColor = color;

		return selectedColor;
	}

	return selectedColor;
}

export function centerTextVertically(containerHeight: number, textHeight: number) {
	return (containerHeight - textHeight) / 2;
}

export function calculateRouteConditionWidth(condition: RouteConditionInterface) {
	const padding = 28;
	const textWidth = Math.max(condition.variable.width as number, condition.value.width as number) + padding;

	return Math.max(265, textWidth);
}

export function calculateRouteConditionHeight(
	condition: RouteConditionInterface,
	totalConditions: number,
	index: number
) {
	function calculateValueLines(condition: RouteConditionInterface) {
		if (
			condition.value.value !== null &&
			(condition.operator === "oneOf" || condition.operator === "notOneOf")
		) {
			const options = condition.value.value.split(",");

			if (options.length >= 10) {
				return 1;
			}
		}

		if (!condition.operator) {
			return 1;
		}

		const emptyOperators = ["isTrue", "isFalse", "isEmpty", "isNotEmpty"];

		if (emptyOperators.includes(condition.operator)) {
			return 1;
		}

		return condition.value.lineOffsets.length;
	}

	let joinerSpace = 0;

	if (totalConditions > 1 && totalConditions !== index + 1) {
		// not the last
		joinerSpace = 36; //joiner = 28 + 8 of space between the joiner and the next condition variable text
	}
	const variableHeight = condition.variable.lineOffsets.length * 14;
	const valueHeight = calculateValueLines(condition) * 20;

	let boxPadding = 0;

	if (index === 0) {
		boxPadding += 15;
	}

	if (index === totalConditions - 1) {
		boxPadding += 15;
	}

	return variableHeight + valueHeight + boxPadding + joinerSpace;
}

export function isRouteConditionsSetup(conditionKeys: Array<string>): boolean {
	return !isRouteConditionsNotSetup(conditionKeys);
}

export function isRouteConditionsNotSetup(conditionKeys: Array<string>): boolean {
	const conditions = (store.getState() as InitialState).elementRouteConditions;

	return conditionKeys.some((conditionKey) => {
		const condition = conditions[conditionKey];

		if (!condition.operator) {
			return true;
		}

		const emptyOperators = ["isTrue", "isFalse", "isEmpty", "isNotEmpty"];

		if (emptyOperators.includes(condition.operator)) {
			return false;
		}

		if (condition.variable.value === null) {
			return true;
		}

		if (condition.value.value === null || condition.value.value.length === 0) {
			return true;
		}

		if (condition.variable.type === VariableType.System) {
			if (condition.variable.value === 'currentTime') {
				function passes(time: any) {
					return !time || time.hour === null || time.minute === null || time.timezone === null;
				}

				if (condition.operator === 'betweenTimes') {
					const times = JSON.parse(condition.value.value);
					if (Array.isArray(times) === false) {
						return false;
					}
					return passes(times[0]) && passes(times[1]);
				}

				const time = JSON.parse(condition.value.value);
				return passes(time);
			}
		}

		return false;
	});
}

/*
	A route can have multiple conditions
	We need to calculate the measurements for the entire "group" of conditions
	so we know where to draw the route lines and draw on the hitgraph.
 */
export function calculateEntireConditionsGroupsMeasurements(route: ElementRoute) {
	const isSetup = isRouteConditionsSetup(route.conditions);

	let measurements = {
		coordX: 0,
		coordY: 0,
		width: 0,
		height: 0,
	};

	const conditionKeys = route.conditions;
	const conditions = (store.getState() as InitialState).elementRouteConditions;

	measurements.coordX = (route.conditionsSettings as ConditionsSettings).coordX as number;
	measurements.coordY = (route.conditionsSettings as ConditionsSettings).coordY as number;

	if (!isSetup) {
		measurements.width = CONDITION_SETUP_OBJECT_WIDTH;
		measurements.height = CONDITION_SETUP_OBJECT_HEIGHT;

		return measurements;
	}

	const { width, height } = conditionKeys.reduce(
		(result: { width: number; height: number }, conditionKey, index) => {
			const condition: RouteConditionInterface = conditions[conditionKey];

			const width = calculateRouteConditionWidth(condition);
			if (width > result.width) {
				result.width = width;
			}
			result.height += calculateRouteConditionHeight(condition, route.conditions.length, index);

			return result;
		},
		{
			width: 0,
			height: 0,
		}
	);

	measurements.width = width;
	measurements.height = height;

	return measurements;
}

export function getNextConnectorCoordsByPosition(
	width: number,
	height: number,
	coordX: number,
	coordY: number,
	direction: LinePosition
) {
	let { x, y } = new Box(width, height, coordX, coordY, direction).calculateCoordsFromDirection();

	if (direction === LinePosition.Top) {
		y = y - 50;
	}

	if (direction === LinePosition.Bottom) {
		y = y + 50;
	}

	if (direction === LinePosition.Left) {
		x = x - 50;
	}

	if (direction === LinePosition.Right) {
		x = x + 50;
	}

	return { x, y };
}

export function calculateDeleteRouteCoords(toElement: any, route: ElementRoute) {
	if (route.to.type === ElementType.EndPiece) {
		return {
			coordX: toElement.coordX + END_PIECE_WIDTH / 2,
			coordY: toElement.coordY,
		};
	}

	if (route.to.direction === LinePosition.Top) {
		return {
			coordX: toElement.coordX + toElement.width / 2,
			coordY: toElement.coordY - 60,
		};
	}

	if (route.to.direction === LinePosition.Bottom) {
		return {
			coordX: toElement.coordX + toElement.width / 2,
			coordY: toElement.coordY + toElement.height / 2 + 60,
		};
	}

	if (route.to.direction === LinePosition.Left) {
		return {
			coordX: toElement.coordX - 60,
			coordY: toElement.coordY + toElement.height / 2,
		};
	}

	return {
		coordX: toElement.coordX + toElement.width + 60,
		coordY: toElement.coordY + toElement.height / 2,
	};
}

export function calculateUserFullNameFromKey(userKey: string | null): string {
	const users = store.getState().users;
	if (!userKey) {
		return "";
	}

	if (userKey === "unassigned") {
		return "Unassigned";
	}

	if (!users.hasOwnProperty(userKey)) {
		return "";
	}

	const user = users[userKey];

	return `${user.firstName} ${user.lastName}`;
}

export function calculateFormattedTimesFromTimeObjects(time: string|null): string {
	if (!time) {
		return "";
	}
	const timeObjects = JSON.parse(time);

	if (Array.isArray(timeObjects) === false) {
		return "";
	}

	return `${timeObjects[0].hour}:${timeObjects[0].minute} ${timeObjects[0].meridiem.toUpperCase()} AND ${timeObjects[1].hour}:${timeObjects[1].minute} ${timeObjects[1].meridiem.toUpperCase()}`
}
export function calculateFormattedTimeFromTimeObject(time: string|null): string {
	if (!time) {
		return "";
	}
	const timeObject = JSON.parse(time);

	return `${timeObject.hour}:${timeObject.minute} ${timeObject.meridiem.toUpperCase()}`
}

export function calculateCurrentMonthSelection(value: string|null, operator: string|null) {
	if (value === null || operator === null) {
		return "";
	}

	if (operator === "oneOf" || operator === "notOneOf") {
		const selectedKeys = value.split(",");
		let selectedOptions: Array<string> = [];

		monthOptions.forEach((option) => {
			if (selectedKeys.includes(option.key)) {
				selectedOptions.push(option.label);
			}
		});

		return selectedOptions.join(", ");
	}

	const option = monthOptions.find((option) => option.key === value);

	if (!option) {
		return "";
	}

	return option.label;
}

export function calculateCurrentDaySelection(value: string|null) {
	if (value === null) {
		return ""
	}

	const option = dayOptions.find((option) => option.key === value);

	if (!option) {
		return "";
	}

	return option.label;
}

export function calculateDayOfWeekSelection(value: string|null, operator: string|null) {
	if (value === null || operator === null) {
		return ""
	}

	if (operator === "oneOf" || operator === "notOneOf") {
		const selectedKeys = value.split(",");
		let selectedOptions: Array<string> = [];

		dayOfWeekOptions.forEach((option) => {
			if (selectedKeys.includes(option.key)) {
				selectedOptions.push(option.label);
			}
		});

		return selectedOptions.join(", ");
	}

	const option = dayOfWeekOptions.find((option) => option.key === value);

	if (!option) {
		return "";
	}

	return option.label;
}

export function manualDisconnectRouteFromElement(
	route: ElementRoute,
	diagram: Diagram,
	dispatchWithHitGraph: any
) {
	const workflowKey = diagram.getWorkflowKey();
	if (route.to.type === ElementType.Connector) return null;

	let toElement = fetchElementFromStoreByType(route.to.type, route.to.key, workflowKey);

	if (route.to.type === ElementType.Action) {
		toElement.width = (toElement as Action).width;
		toElement.height = (toElement as Action).height;
	}

	const { coordX, coordY } = calculateDeleteRouteCoords(toElement, route);

	let connector = {
		key: cuid(),
		coordX: coordX,
		coordY: coordY,
		direction: route.to.direction,
		routeKey: route.key,
	};

	dispatchWithHitGraph(removeRouteConnection(workflowKey, route.key, connector), diagram);

	store.dispatch(queueRequestToAPI(WorkflowAPI.createConnector(workflowKey, connector)));

	store.dispatch(
		queueRequestToAPI(
			WorkflowAPI.updateRouteConnection(workflowKey, route.key, "to", {
				key: connector.key,
				type: ElementType.Connector,
				direction: route.to.direction,
			})
		)
	);

	if (route.to.type === ElementType.EndPiece && !isEndConnectedToElements(route.to.key)) {
		store.dispatch(sendRequestToAPI(WorkflowAPI.deleteEnd(workflowKey, route.to.key)));
		dispatchWithHitGraph(removeEndPiece(route.to.key, workflowKey), diagram);

		(diagram.getObjectsCreator().getByType(CanvasElementType.JointHitZones) as JointHitZones).clear();

		diagram.getSecondaryHitGraph().clearIndexes();
	}
}

export function updateRouteToConnection(
	route: ElementRoute,
	elementKey: string,
	elementType: ElementType,
	direction: LinePosition,
	workflowKey: string
) {
	store.dispatch(addElementToPrevRoute(elementKey, elementType, route, direction));

	store.dispatch(
		queueRequestToAPI(
			WorkflowAPI.updateRouteConnection(workflowKey, route.key, "to", {
				key: elementKey,
				type: elementType,
			})
		)
	);

	if (route.from.type === ElementType.Step) {
		const steps = store.getState().steps;
		const connection: ElementConnection = store.getState().elementConnections[
			steps[route.from.key].connectionKey
		];

		if (connection.type === ConnectionTypes.CONDITIONAL) {
			store.dispatch(
				sendRequestToAPI(
					WorkflowAPI.updateRouteConditionsSettings(route.key, {
						routeToDirection: direction,
					})
				)
			);
		} else {
			store.dispatch(
				queueRequestToAPI(
					WorkflowAPI.updateRouteConnection(workflowKey, route.key, "to", {
						direction,
					})
				)
			);
		}
	}
}

export function updateActionConnectorPosition(
	action: Action,
	width: number,
	logoDimensions: any,
	workflowKey: string
) {
	const connection = (store.getState() as InitialState).elementConnections[
		action.connectionKey
	] as ElementConnection;

	const route = (store.getState() as InitialState).elementRoutes[connection.routes[0]] as ElementRoute;

	if (route.to.type !== ElementType.Connector) {
		return;
	}
	const connector = (store.getState() as InitialState).connectors[route.to.key];

	store.dispatch(
		moveConnector({
			...connector,
			coordX: action.coordX + (width + logoDimensions.width) / 2,
		})
	);

	store.dispatch(
		sendRequestToAPI(
			WorkflowAPI.updateConnector(workflowKey, connector.key, {
				coordX: action.coordX + (width + logoDimensions.width) / 2,
			})
		)
	);
}

export function calculateRouteDestinationElementOverride(
	coordX: number,
	coordY: number,
	width: number,
	height: number,
	route: ElementRoute,
	diagram: Diagram
) {
	if (
		route.conditions.length &&
		(route.conditionsSettings as ConditionsSettings).type === RouteConditionType.Normal
	) {
		const RouteCondition = diagram
			.getObjectsCreator()
			.getByType(CanvasElementType.RouteCondition) as RouteCondition;

		const routeOriginalDestination = {
			coordX,
			coordY,
			width,
			height,
			key: route.to.key,
			type: route.to.type,
			direction: route.from.direction,
		};

		RouteCondition.queue(route, routeOriginalDestination);

		const conditions = RouteCondition.getConditions(route.key);
		const measurements = conditions.measurements;

		return {
			key: `${route.key}_conditions`,
			type: ElementType.Conditions,
			direction: route.to.direction,
			coordX: measurements.coordX,
			coordY: measurements.coordY,
			width: measurements.width,
			height: measurements.height,
		};
	}

	if ((route.conditionsSettings as ConditionsSettings).type === RouteConditionType.Unmatched) {
		const UnmatchedRouteMarkers = diagram
			.getObjectsCreator()
			.getByType(CanvasElementType.UnmatchedRouteMarkers) as UnmatchedRouteMarkers;

		const routeOriginalDestination = {
			coordX,
			coordY,
			width,
			height,
			key: route.to.key,
			type: route.to.type,
			direction: route.from.direction,
		};

		UnmatchedRouteMarkers.queue(route, routeOriginalDestination);

		return {
			key: `${route.key}_unmatched`,
			type: ElementType.UnmatchedRouteMarker,
			direction: route.to.direction,
			coordX: (route.conditionsSettings as ConditionsSettings).coordX as number,
			coordY: (route.conditionsSettings as ConditionsSettings).coordY as number,
			width: UNMATCHED_ROUTE_MARKER_WIDTH,
			height: UNMATCHED_ROUTE_MARKER_HEIGHT,
		};
	}

	return null;
}

export function calculateRouteDestination(
	route: ElementRoute,
	connection: ElementConnection,
	coordX: number,
	coordY: number,
	width: number,
	height: number,
	diagram: Diagram
) {
	if (connection.type === ConnectionTypes.CONDITIONAL) {
		const destinationElementOverride = calculateRouteDestinationElementOverride(
			coordX,
			coordY,
			width,
			height,
			route,
			diagram
		);

		if (destinationElementOverride) {
			return destinationElementOverride;
		}
	}

	return {
		key: route.to.key,
		type: route.to.type,
		direction: route.to.direction,
		coordX,
		coordY,
		width,
		height,
	};
}

export function calculateDateDiagramValue(condition: ContentCondition | RouteConditionInterface) {
	if (
		condition.operator === "inLast" ||
		condition.operator === "inLessThan" ||
		condition.operator === "inMoreThan"
	) {
		if (typeof condition.value.value !== "string") {
			return "Incomplete Setup";
		}
		const parts = condition.value.value.split(".");

		if (parts.length < 2) {
			return "Incomplete Setup";
		}

		return `${parts[0]} ${capitalize(parts[1])}`;
	}

	return moment(condition.value.value).format("MM/DD/Y");
}
