import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Spinner } from "design-system";

import styles from "./ProfileImage.module.scss";

interface Props extends RouteComponentProps {}

const Uploading: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.Uploading}>
			<Spinner size="small-medium" />
		</div>
	);
};

export default Uploading;
