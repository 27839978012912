import React, { MutableRefObject } from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import { wait } from "../../../../utils/constants";
import { ErrorBag } from "design-system";

import styles from "./Errors.module.scss";

interface Props extends RouteComponentProps {
	bag: Array<ErrorBag>;
	className?: string;
	forwardRef?: MutableRefObject<HTMLDivElement | null>;
}

function formatErrors(errors: Element[] | any): JSX.Element {
	if (errors.length === 1) {
		return errors[0];
	}

	return (
		<React.Fragment>
			<p>We couldn't complete the request. Please fix the following issues to proceed:</p>
			<ul>{errors}</ul>
		</React.Fragment>
	);
}

// Note: Must be a class component so we can use PureComponent to only re-render on bag changes
class Errors extends React.PureComponent<Props> {
	private readonly ref: React.RefObject<HTMLDivElement>;
	private readonly errorsSpacing: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);

		this.ref = React.createRef();
		this.errorsSpacing = React.createRef();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
		if (this.props.bag.length === 0 && prevProps.bag.length !== 0) {
			this.hidePanel();
		}
	}

	panelIsVisible() {
		if (!this.ref.current) return false;

		const style = getComputedStyle(this.ref.current);

		return style.visibility !== "hidden";
	}

	hidePanel() {
		if (!this.ref.current || !this.errorsSpacing.current) return;

		this.errorsSpacing.current.animate(
			[{ height: `${this.ref.current.clientHeight}px` }, { height: 0 }],
			{
				duration: 150,
				iterations: 1,
				fill: "forwards",
			}
		);

		this.ref.current.animate([{ visibility: "visible" }, { visibility: "hidden" }], {
			duration: 100,
			iterations: 1,
			fill: "forwards",
		});
	}

	async showWithDelay() {
		await wait(300);

		this.show();
	}

	async show() {
		if (!this.ref.current || !this.errorsSpacing.current) return;

		this.ref.current.style.position = "absolute";

		await wait(50); // for the height to be set

		this.errorsSpacing.current.animate(
			[{ height: 0 }, { height: `${this.ref.current.clientHeight + 24}px` }],
			{
				duration: 200,
				iterations: 1,
				fill: "forwards",
			}
		);

		this.ref.current.animate(
			[
				{ visibility: "hidden", transform: "translateY(15px)" },
				{ visibility: "visible", transform: "translateY(0)" },
			],
			{
				duration: 200,
				iterations: 1,
				fill: "forwards",
				delay: 100,
			}
		);
	}

	hasNoErrors() {
		return this.props.bag.length === 0;
	}

	setVisibility() {
		if (this.hasNoErrors()) return;

		if (this.panelIsVisible()) {
			this.hidePanel();
			this.showWithDelay();

			return;
		}

		this.show();
	}

	render() {
		this.setVisibility();

		const classes = classNames([
			styles.Errors,
			this.props.bag.length > 0 && styles.HasErrors,
			this.props.className,
		]);

		const errors = this.props.bag.map((error: ErrorBag) => {
			if (typeof error.message === "string") {
				if (this.props.bag.length === 1) {
					return <div>{error.message}</div>;
				}

				return <li key={error.key}>{error.message}</li>;
			}

			return error.message.map((m, index) => <li key={index}>{m}</li>);
		});

		return (
			<div className={styles.ErrorsSpacing} ref={this.errorsSpacing}>
				<div className={classes} ref={this.ref}>
					{errors.length > 0 && formatErrors(errors)}
				</div>
			</div>
		);
	}
}

export default Errors;
