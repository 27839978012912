import React, { useEffect } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { Card, DropdownItem, RolesList } from "design-system";
import InviteUser from "./InviteUser";
import EditUser from "./EditUser";
import { INITIAL_RENDER_ONLY } from "../../../../utils/constants";
import { useDispatch, useSelector, useStore } from "react-redux";
import { InitialState } from "../../../../initialState";
import { UserAPI } from "../../../../api/UserAPI";
import { fetchFromApi } from "../../../../sharedActions";
import { isWorkingKey } from "../../../../utils/helpers";

import styles from "./User.module.scss";

interface Props extends RouteComponentProps {}

export function formatRolesForDropdown(roles: RolesList): Array<DropdownItem> {
	// @ts-ignore
	return Object.keys(roles).map((roleKey) => {
		return {
			key: roles[roleKey].key,
			label: roles[roleKey].name,
		};
	});
}

export function formatSelectedRole(selectedRoleKey: string | null, roles: RolesList): DropdownItem | null {
	if (!selectedRoleKey) return null;
	if (!Object.keys(roles).length) return null;

	return {
		label: roles[selectedRoleKey].name,
		key: roles[selectedRoleKey].key as string,
	};
}

const User: React.FunctionComponent<Props> = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const store = useStore();
	const amInviting = params.userKey === "invite";
	const roles = useSelector((state: InitialState) => state.roles);

	useEffect(() => {
		if (!Object.keys(roles).length) {
			dispatch(fetchFromApi(UserAPI.loadRoles()));
		}
	}, INITIAL_RENDER_ONLY);

	function fetchUser() {
		// for the workflow access component, we only want the working workflow keys
		const user = (store.getState() as InitialState).users[params.userKey];

		return {
			...user,
			workflowAccess: {
				...user.workflowAccess,
				workflows: user.workflowAccess.workflows.filter((workflowKey: string) =>
					isWorkingKey(workflowKey)
				),
			},
		};
	}

	return (
		<div className={styles.UserPage}>
			<Card fullWidth={true}>{amInviting ? <InviteUser /> : <EditUser getUser={fetchUser} />}</Card>
		</div>
	);
};

export default User;
