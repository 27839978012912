import { InitialState } from "../../../initialState";
import { AnyAction } from "redux";
import {
	CHANGE_UPGRADE_STAGE,
	RELOAD_PRE_UPGRADE_INVOICE,
	SET_BILLING_CYCLE_USAGE,
	SET_FULL_RECEIPT,
	SET_PRE_UPGRADE_DATA,
	SET_PRE_UPGRADE_INVOICE,
	SET_RECEIPTS,
	SET_SUBSCRIPTION,
	SET_SUBSCRIPTION_PAYMENT_METHODS,
	UNSET_PRE_UPGRADE_DATA,
	UPDATE_PRE_UPGRADE_DATA,
	UPDATE_PRE_UPGRADE_RESOURCE,
	UPDATE_SELECTED_BILLING_CYCLE,
	UPDATE_SUBSCRIPTION,
} from "../../../actionTypes";
import { LoadState, PreUpgradeData, ReceiptsList, Subscription } from "design-system";
import { normalizeBasic } from "../../../data/normalize/basic";

export function billing(state: InitialState, action: AnyAction) {
	const { type, ...rest } = action;

	if (action.type === SET_SUBSCRIPTION) {
		let nextState = { ...state };

		if (action.usage !== undefined) {
			action.usage.cycles = normalizeBasic("cycles", action.usage.cycles);

			Object.keys(action.usage.cycles).forEach((cycleKey) => {
				action.usage.cycles[cycleKey].loaded = cycleKey === action.usage.currentCycleKey;
			});

			nextState.local.billing.selectedBillingCycleKey = action.usage.currentCycleKey;
		}

		if (!nextState.subscription) {
			return {
				...nextState,
				subscription: {
					...rest,
				},
			};
		}

		return {
			...nextState,
			subscription: {
				...rest,
				...nextState.subscription,
				paymentMethods: nextState.subscription.paymentMethods,
			},
		};
	}

	if (action.type === SET_SUBSCRIPTION_PAYMENT_METHODS) {
		const paymentMethods = normalizeBasic("paymentMethods", action.paymentMethods, "id");

		if (!state.subscription) {
			return {
				...state,
				subscription: {
					paymentMethods,
				},
			};
		}

		return {
			...state,
			subscription: {
				...state.subscription,
				paymentMethods,
			},
		};
	}

	if (action.type === SET_PRE_UPGRADE_DATA) {
		return {
			...state,
			preUpgradeData: {
				...state.preUpgradeData,
				...rest,
			},
		};
	}

	if (action.type === SET_PRE_UPGRADE_INVOICE) {
		return {
			...state,
			preUpgradeData: {
				...state.preUpgradeData,
				invoice: action.invoice,
			},
		};
	}

	if (action.type === RELOAD_PRE_UPGRADE_INVOICE) {
		return {
			...state,
			preUpgradeData: {
				...state.preUpgradeData,
				invoice: {
					...(state.preUpgradeData as PreUpgradeData).invoice,
					loadState: LoadState.LOADING,
				},
			},
		};
	}

	if (action.type === UPDATE_PRE_UPGRADE_DATA) {
		return {
			...state,
			preUpgradeData: {
				...state.preUpgradeData,
				[action.property]: action.value,
			},
		};
	}

	if (action.type === UNSET_PRE_UPGRADE_DATA) {
		return {
			...state,
			preUpgradeData: null,
		};
	}

	if (action.type === UPDATE_PRE_UPGRADE_RESOURCE) {
		return {
			...state,
			preUpgradeData: {
				...state.preUpgradeData,
				resources: {
					...(state.preUpgradeData as PreUpgradeData).resources,
					[action.resourceType]: {
						...(state.preUpgradeData as PreUpgradeData).resources[
							action.resourceType as "jobs" | "users"
						],
						selected: action.amount,
					},
				},
			},
		};
	}

	if (action.type === CHANGE_UPGRADE_STAGE) {
		return {
			...state,
			preUpgradeData: {
				...state.preUpgradeData,
				stage: action.stage,
			},
		};
	}

	if (action.type === UPDATE_SUBSCRIPTION) {
		if (action.property === "usage") {
			action.value.cycles = normalizeBasic("cycles", action.value.cycles);

			Object.keys(action.value.cycles).forEach((cycleKey) => {
				action.value.cycles[cycleKey].loaded = cycleKey === action.value.currentCycleKey;
			});
		}

		return {
			...state,
			subscription: {
				...state.subscription,
				[action.property]: action.value,
			},
		};
	}

	if (action.type === UPDATE_SELECTED_BILLING_CYCLE) {
		return {
			...state,
			local: {
				...state.local,
				billing: {
					...state.local.billing,
					selectedBillingCycleKey: action.billingCycleKey,
				},
			},
		};
	}

	if (action.type === SET_BILLING_CYCLE_USAGE) {
		let subscription = { ...state.subscription } as Subscription;
		const { cycleKey, resources } = action;

		subscription.usage.cycles[cycleKey].loaded = true;

		Object.keys(resources).forEach((resourceKey) => {
			subscription.usage.resources[resourceKey].cycles[cycleKey] = resources[resourceKey];
		});

		return {
			...state,
			subscription,
		};
	}

	if (action.type === SET_RECEIPTS) {
		const receipts = normalizeBasic("receipts", action.receipts, "id");

		let nextState = {
			...state,
		};

		if (nextState.subscription === null) {
			// @ts-ignore
			nextState.subscription = {
				receipts,
			};

			nextState.loadStates = {
				...nextState.loadStates,
				receipts: LoadState.LOADED,
			};

			return nextState;
		}

		return {
			...state,
			subscription: {
				...state.subscription,
				receipts: {
					...receipts,
					...(state.subscription as Subscription).receipts,
				},
			},
			loadStates: {
				...state.loadStates,
				receipts: LoadState.LOADED,
			},
		};
	}

	if (action.type === SET_FULL_RECEIPT) {
		let nextState = {
			...state,
		};

		let receipts: ReceiptsList = {};
		if (nextState.subscription === null) {
			// @ts-ignore
			nextState.subscription = {
				receipts: null,
			};
		}

		if ((nextState.subscription as Subscription).receipts) {
			receipts = {
				...(state.subscription as Subscription).receipts,
			};
		}

		receipts[action.receipt.id] = {
			...action.receipt,
			loaded: true,
		};

		(nextState.subscription as Subscription).receipts = receipts;

		return nextState;
	}

	return state;
}
