import React from "react";
import { Job, LogItem, Me, User } from "design-system";
import { InitialState } from "../../../../initialState";
import { extractUserFromLog } from "../../../../utils/helpers";
import { store } from "../../../../store";
// @ts-ignore
import moment from "moment-timezone";
import JobStarted from "./Events/JobStarted";
import StepStarted from "./Events/StepStarted";
import StepCompleted from "./Events/StepCompleted";
import WorkflowStarted from "./Events/WorkflowStarted";
import ActionCreated from "./Events/ActionCreated";
import JobClosed from "./Events/JobClosed";
import WorkflowCompleted from "./Events/WorkflowCompleted";
import NoRouteMatch from "./Events/NoRouteMatch";
import UserCompletedStep from "./Events/UserCompletedStep";
import NoSubworkflowConfigured from "./Events/NoSubworkflowConfigured";
import RouteDirectionChanged from "./Events/RouteDirectionChanged";
import StepBlueprintDeleted from "./Events/StepBlueprintDeleted";
import InfiniteLoopDetected from "./Events/InfiniteLoopDetected";
import JobTaskCompleted from "./Events/JobTaskCompleted";
import UndoJobTaskCompleted from "./Events/UndoJobTaskCompleted";
import InvalidAutomationConfig from "./Events/InvalidAutomationConfig";
import DelayStarted from "./Events/DelayStarted";
import DelayFinished from "./Events/DelayFinished";
import JobReopened from "./Events/JobReopened";

import styles from "./JobLogs.module.scss";

interface Props {
	job: Job;
	workflowKey: string;
	events: Array<LogItem>;
}

const eventMap: { [key: string]: React.FunctionComponent<{ logItem: LogItem }> } = {
	job_started: JobStarted,
	step_started: StepStarted,
	step_completed: StepCompleted,
	workflow_started: WorkflowStarted,
	action_created: ActionCreated,
	delay_started: DelayStarted,
	delay_finished: DelayFinished,
	job_closed: JobClosed,
	workflow_completed: WorkflowCompleted,
	route_direction_changed: RouteDirectionChanged,
	no_route_match_end: NoRouteMatch,
	user_step_completed: UserCompletedStep,
	no_sub_workflow_configured: NoSubworkflowConfigured,
	step_blueprint_deleted: StepBlueprintDeleted,
	infinite_loop_detected: InfiniteLoopDetected,
	job_task_completed: JobTaskCompleted,
	undo_job_task_completed: UndoJobTaskCompleted,
	invalid_automation_config: InvalidAutomationConfig,
	job_reopened: JobReopened,
};

function buildMain(logItem: LogItem) {
	const EventComponent = eventMap[logItem.event];

	if (!EventComponent) {
		console.error(`No event for ${logItem.event}`);
		return (
			<React.Fragment>
				<div className={styles.Indicator}></div>
				<div className={styles.Event}></div>
				<div className={styles.Details}></div>
			</React.Fragment>
		);
	}

	return <EventComponent logItem={logItem} />;
}

function buildDate(timestamp: string) {
	const userTimezone = ((store.getState() as InitialState).me as Me).timezone;
	let momentDate = moment.tz(timestamp, "UTC").tz(userTimezone);

	return (
		<div className={styles.Date}>
			<p>{momentDate.format("MMMM Do YYYY")}</p>
			<span>{momentDate.format("h:mm A")}</span>
		</div>
	);
}

export function buildUser(userKey: string | null): string {
	if (!userKey) {
		return "";
	}

	if (userKey === "api") {
		return "API";
	}

	if (userKey === "public") {
		return "Public";
	}

	const user = store.getState().users[userKey] as User;

	return `${user.firstName} ${user.lastName}`;
}
const LogEventsTable: React.FunctionComponent<Props> = ({ job, workflowKey, events }) => {
	const rows = events.map((logItem: LogItem, index) => {
		return (
			<div className={styles.Row} key={`${logItem.elementKey}-${index}`}>
				{buildMain(logItem)}
				{buildDate(logItem.timestamp)}
			</div>
		);
	});

	return (
		<div className={styles.LogEventsTable}>
			<div className={styles.TableHeader}>
				<div className={styles.Indicator}></div>
				<div className={styles.Event}>Event</div>
				<div className={styles.Details}>Details</div>
				<div className={styles.Date}>Date</div>
			</div>
			<div className={styles.TableRows}>{rows}</div>
		</div>
	);
};

export default LogEventsTable;
