import { InitialState } from "../../initialState";
import {
	CHANGE_DASHBOARD_VIEW,
	LIVE_ADD_JOB_TO_DASHBOARDS,
	SET_DASHBOARD_SETTINGS,
	SET_DASHBOARD_VIEWS,
} from "../../actionTypes";
import { normalizeJobs } from "../../data/normalize/basic";
import { LoadState } from "design-system";

export function dashboard(state: InitialState, action: any) {
	if (action.type === SET_DASHBOARD_VIEWS) {
		// const { jobs, jobFields } = normalizeJobs(action.jobs);

		const dashboardViewData = action.viewData.reduce(
			(results: any, view: any) => {
				results.tallies[view.name] = view.jobs.length;
				results.views[view.name] = view.jobs;

				return results;
			},
			{
				tallies: {},
				views: {},
			}
		);

		return {
			...state,
			loadStates: {
				...state.loadStates,
				dashboard: LoadState.LOADED,
			},
			dashboard: {
				...state.dashboard,
				tallies: dashboardViewData.tallies,
				views: dashboardViewData.views,
			},
			jobs: Object.assign({}, state.jobs, jobs),
			jobsFields: Object.assign({}, state.jobsFields, jobFields),
		};
	}

	if (action.type === SET_DASHBOARD_SETTINGS) {
		return {
			...state,
			dashboard: {
				...state.dashboard,
				settings: {
					columns: action.columns,
				},
			},
		};
	}

	if (action.type === CHANGE_DASHBOARD_VIEW) {
		return {
			...state,
			dashboard: {
				...state.dashboard,
				selectedOption: action.viewKey,
			},
		};
	}

	if (action.type === LIVE_ADD_JOB_TO_DASHBOARDS) {
		if (state.loadStates.dashboard === LoadState.NOT_LOADED) {
			return state;
		}

		let dashboard = Object.assign({}, state.dashboard);

		action.views.forEach((viewKey: string) => {
			if (!dashboard.views[viewKey].includes(action.jobKey)) {
				dashboard.views[viewKey].push(action.jobKey);
			}
		});

		return {
			...state,
			dashboard,
		};
	}

	return state;
}
