import React, { Component } from "react";
import { store } from "../../../../store";
import { Action, CanvasElementType, ElementType, LinePosition, StepContentType } from "design-system";
import {
	calculateDeleteRouteCoords,
	fetchElementFromStoreByType,
	getNextConnectorCoordsByPosition,
} from "./utils";
import cuid from "cuid";
import {
	createConnector,
	removeEndPiece,
	removeRouteConnection,
	updateStartMarker,
	updateWorkflowRoot,
} from "../workflowActions";
import { Diagram } from "./Diagram";
import { queueRequestToAPI, sendRequestToAPI } from "../../../../sharedActions";
import { WorkflowAPI } from "../../../../api/WorkflowAPI";
import { AnyAction } from "redux";
import { END_PIECE_HEIGHT, END_PIECE_WIDTH, StartMarkerKey } from "../../../../utils/constants";
import { InitialState } from "../../../../initialState";
import { START_MARKER_PROPERTIES } from "./Objects/StartMarker";
import { getOppositeDirection } from "../../../../utils/helpers";
import { JointHitZones } from "./Objects/JointHitZones";

interface Props {
	diagram: Diagram;
	workflowKey: string;
}

export function calculateNewConnectorPosition(fromPosition: LinePosition): LinePosition {
	if (fromPosition === LinePosition.Top) {
		return LinePosition.Bottom;
	}

	if (fromPosition === LinePosition.Bottom) {
		return LinePosition.Top;
	}

	if (fromPosition === LinePosition.Left) {
		return LinePosition.Right;
	}

	return LinePosition.Left;
}

export function isEndConnectedToElements(endKey: string) {
	const elementRoutes = (store.getState() as InitialState).elementRoutes;

	let connectedElementKeys: Array<string> = [];
	Object.keys(elementRoutes).forEach((elementRouteKey) => {
		if (elementRoutes[elementRouteKey].to.key === endKey) {
			connectedElementKeys.push(elementRoutes[elementRouteKey].from.key);
		}
	});

	return connectedElementKeys.length > 0;
}

export default class DeleteConnection extends Component<any> {
	constructor(props: Props) {
		super(props);

		this.handleGlobalKeyCommand = this.handleGlobalKeyCommand.bind(this);
		this.stopBackspaceNavigation = this.stopBackspaceNavigation.bind(this);
	}

	componentDidMount(): void {
		window.addEventListener("keyup", this.handleGlobalKeyCommand);
		window.addEventListener("keydown", this.stopBackspaceNavigation);
	}

	componentWillUnmount(): void {
		window.removeEventListener("keyup", this.handleGlobalKeyCommand);
		window.removeEventListener("keydown", this.stopBackspaceNavigation);
	}

	dispatchWithHitGraph(action: AnyAction) {
		const state = store.getState();

		const hitGraph = this.props.diagram.getHitGraph();

		hitGraph.dispatch(action, state);
		store.dispatch(action);
	}

	parseSelectedRouteKey() {
		const selectedRouteKey = store.getState().editor.selectedRouteKey;

		if (!selectedRouteKey) {
			return null;
		}

		// The line (route) for conditions has the key {routeKey}_conditions_line
		// We need to extract the actual routeKey
		const [routeKey] = selectedRouteKey.split("_");

		return routeKey;
	}

	stopBackspaceNavigation(event: KeyboardEvent) {
		if (event.key === "Backspace") {
			if (
				document.activeElement !== null &&
				document.activeElement.nodeName !== "INPUT" &&
				document.activeElement.nodeName !== "TEXTAREA"
			) {
				// firefox prevent navigating backwards on backspace key
				event.preventDefault();
				event.stopPropagation();
			}
		}
	}

	handleDeleteFromStarter() {
		const starter = (store.getState() as InitialState).startMarkers[this.props.workflowKey];

		if (starter.connectorKey !== null) {
			return;
		}

		const { x, y } = getNextConnectorCoordsByPosition(
			START_MARKER_PROPERTIES.width,
			START_MARKER_PROPERTIES.height,
			starter.coordX,
			starter.coordY,
			starter.routeFromDirection
		);

		let connector = {
			key: cuid(),
			coordX: x,
			coordY: y,
			direction: getOppositeDirection(starter.routeFromDirection),
			routeKey: StartMarkerKey,
		};

		this.dispatchWithHitGraph(createConnector(connector, this.props.workflowKey));

		((this.props.diagram as Diagram)
			.getObjectsCreator()
			.getByType(CanvasElementType.JointHitZones) as JointHitZones).clear();

		store.dispatch(updateWorkflowRoot(this.props.workflowKey, ElementType.Connector, connector.key));
		store.dispatch(updateStartMarker(this.props.workflowKey, { connectorKey: connector.key }));

		store.dispatch(queueRequestToAPI(WorkflowAPI.createConnector(this.props.workflowKey, connector)));
		store.dispatch(
			sendRequestToAPI(
				WorkflowAPI.updateWorkflow(this.props.workflowKey, {
					root: { type: ElementType.Connector, key: connector.key },
				})
			)
		);
		store.dispatch(
			queueRequestToAPI(
				WorkflowAPI.updateStartMarker(this.props.workflowKey, { connectorKey: connector.key })
			)
		);
	}

	handleGlobalKeyCommand(event: KeyboardEvent) {
		const { diagram, workflowKey } = this.props;

		if (event.key === "Backspace") {
			const contentElementKeyInEditMode = (store.getState() as InitialState).editor
				.contentElementKeyInEditMode;

			if (contentElementKeyInEditMode !== null) {
				const contentElementType = (store.getState() as InitialState).stepContentElements[
					contentElementKeyInEditMode
				].type;

				if (contentElementType === StepContentType.Text) {
					return;
				}
			}

			const selectedRouteKey = this.parseSelectedRouteKey();

			if (selectedRouteKey !== null) {
				if (selectedRouteKey === StartMarkerKey) {
					this.handleDeleteFromStarter();

					return;
				}

				const routes = store.getState().elementRoutes;

				if (!selectedRouteKey) {
					return;
				}

				const route = routes[selectedRouteKey];

				if (route.to.type === ElementType.Connector) return null;

				let toElement = fetchElementFromStoreByType(route.to.type, route.to.key, workflowKey);

				if (route.to.type === ElementType.Action) {
					toElement.width = (toElement as Action).width;
					toElement.height = (toElement as Action).height;
				}

				if (route.to.type === ElementType.EndPiece) {
					toElement.width = END_PIECE_WIDTH;
					toElement.height = END_PIECE_HEIGHT;
				}

				const { coordX, coordY } = calculateDeleteRouteCoords(toElement, route);

				let connector = {
					key: cuid(),
					coordX,
					coordY,
					direction: route.to.direction,
					routeKey: route.key,
				};

				this.dispatchWithHitGraph(removeRouteConnection(workflowKey, route.key, connector));

				store.dispatch(queueRequestToAPI(WorkflowAPI.createConnector(workflowKey, connector)));

				store.dispatch(
					queueRequestToAPI(
						WorkflowAPI.updateRouteConnection(workflowKey, route.key, "to", {
							key: connector.key,
							type: ElementType.Connector,
							direction: route.to.direction,
						})
					)
				);

				if (route.to.type === ElementType.EndPiece && !isEndConnectedToElements(route.to.key)) {
					store.dispatch(sendRequestToAPI(WorkflowAPI.deleteEnd(workflowKey, route.to.key)));
					this.dispatchWithHitGraph(removeEndPiece(route.to.key, workflowKey));

					((this.props.diagram as Diagram)
						.getObjectsCreator()
						.getByType(CanvasElementType.JointHitZones) as JointHitZones).clear();

					// This is a hack
					// - Only the JointHitZones are currently kept on the secondary hit graph
					// - We continuously calculate the JointHitZones with new colors (bad) instead of
					// queueing up them all at once, like we do for other elements
					// But the problem is that it's hard to send isolated state updates to the secondary hit graph
					// In useDispatchWithHitGraph we can't send all of the updates to both hitgraphs,
					// because then it would trigger additions to the secondary that shouldn't be there...
					// which means that in order to get updates to the secondary hitgraph, they need to be
					// invoked manually. Meaning that every action that adds or removes from the JointHitZones (ie adding/removing most canvas elements)
					// has to invoke the JointHitZone hitgraph update.
					(this.props.diagram as Diagram).getSecondaryHitGraph().clearIndexes();
				}
			}
		}
	}

	render() {
		return null;
	}
}
