import React from "react";
import { RouteComponentProps } from "@reach/router";
import NavItem from "./NavItem";
import styles from "./Header.module.scss";

interface Props extends RouteComponentProps {
	animateNavItemMarker: (properties: { width: number; xPos: number }) => void;
	preload: (componentName: string) => void;
}

const menuItems = [
	// { name: "Dashboard", url: "/" },
	{ name: "Jobs", url: "/jobs", componentName: "JobsList" },
	{ name: "Workflows", url: "/workflows", componentName: "WorkflowsList" },
	{ name: "Manage", url: "/manage", componentName: "ManageOverview" },
];

const Nav: React.FunctionComponent<Props> = ({ animateNavItemMarker, preload }) => {
	return (
		<div className={styles.Nav}>
			{menuItems.map((item, index) => (
				<NavItem
					key={`NavItem-${index}`}
					{...item}
					animateNavItemMarker={animateNavItemMarker}
					preload={preload}
				/>
			))}
		</div>
	);
};

export default Nav;
