import React, { MutableRefObject, useRef, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Job, JobStep, JobTimelineStepForm, LogItem, UserAssignmentsPreview } from "design-system";
import { InitialState } from "../../../../../../../initialState";
import { store } from "../../../../../../../store";
import CollapsedElement from "../Generic/CollapsedElement";
import ExpandedElement from "../Generic/ExpandedElement";
import EditButton from "./EditButton";
import CompletedDate from "./CompletedDate";
import CloseButton from "../Generic/CloseButton";
import StepName from "./StepName";
import Tasks from "./Tasks";
import UploadedAssets from "./UploadedAssets";

import styles from "./Step.module.scss";

interface Props {
	item: LogItem;
	job: Job;
	expandAll: boolean;
	isExpanded: boolean;
	onClose: (elementKey: string) => void;
}

function getCompletedUsersList(jobStep: JobStep) {
	if (!jobStep.completedBy) {
		return [];
	}

	return jobStep.completedBy.map((user) => user.userKey);
}

const Step: React.FunctionComponent<Props> = ({ item, job, isExpanded, expandAll, onClose }) => {
	const jobStep = useSelector((state: InitialState) => state.jobsSteps[item.elementKey]);
	const jobFields = useSelector((state: InitialState) => state.jobsFields[job.key]);

	const navigate = useNavigate();
	const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

	// const extraStyles = calculateStyles(ref, isQueuedForAnimation);

	function handleEditButtonClick() {
		navigate(`/jobs/${job.key}/open/${jobStep.key}`);
	}

	if (jobStep.closedAt !== null) {
		return null;
	}

	const classes = classNames([
		styles.Step,
		jobStep.step.deletedAt !== null && styles.isDeleted,
		isExpanded && styles.isExpanded,
		!isExpanded && styles.isCollapsed,
	]);

	return (
		<div className={classes} ref={ref}>
			<CollapsedElement isCollapsed={!isExpanded}>
				<StepName
					name={jobStep.step.name}
					isDeleted={jobStep.step.deletedAt !== null}
					stepKey={jobStep.step.key}
				/>
				<UserAssignmentsPreview
					users={store.getState().users}
					assignedUserKeys={getCompletedUsersList(jobStep)}
					previewLimit={4}
					renderSingleAsFullUser={jobStep.assignment.assignees.length <= 1}
				/>
			</CollapsedElement>
			<ExpandedElement isExpanded={isExpanded}>
				<div className={styles.Head}>
					<StepName
						name={jobStep.step.name}
						isDeleted={jobStep.step.deletedAt !== null}
						stepKey={jobStep.step.key}
					/>

					<EditButton
						job={job}
						jobStep={jobStep}
						currentUser={store.getState().me}
						onEditClick={handleEditButtonClick}
					/>
				</div>
				<div className={styles.CompletedDetails}>
					<CompletedDate jobStep={jobStep} />
					<div className={styles.CompletedBy}>
						<p className={styles.Title}>Completed By</p>
						<UserAssignmentsPreview
							users={store.getState().users}
							assignedUserKeys={getCompletedUsersList(jobStep)}
							previewLimit={4}
							renderSingleAsFullUser={jobStep.assignment.assignees.length <= 1}
						/>
					</div>
				</div>

				<Tasks logItem={item} jobStep={jobStep} contentKeys={jobStep.userGeneratedContent} />

				{jobStep.fields.length > 0 && (
					<div className={styles.Form}>
						<JobTimelineStepForm
							jobStep={jobStep}
							jobFields={jobFields}
							jobStepFields={jobStep.fields}
						/>
					</div>
				)}

				{jobStep.userGeneratedContent.length > 0 && (
					<UploadedAssets jobKey={jobStep.jobKey} contentKeys={jobStep.userGeneratedContent} />
				)}
				{!expandAll && <CloseButton onClick={() => onClose(item.elementKey)} />}
			</ExpandedElement>
		</div>
	);
};

export default Step;
