import { XYCoords } from "./components/Workflows/Editor/Canvas/utils";
import {
	AccessTokensList,
	Account,
	ActionsList,
	ApiResponseLogs,
	ColumnType,
	ConnectorsList,
	ContentConditionsList,
	Countries,
	DashboardMenuOptions,
	DashboardSortOptions,
	DelaysList,
	DraggedElement,
	ElementConnectionList,
	ElementRoutesList,
	EndList,
	ExternalEndpointList,
	ExternalEndpointResponse,
	FeatureVotes,
	FileUploadMonitor,
	FormFieldsList,
	IntegrationList,
	Job,
	JobAction,
	JobCommentsList,
	JobStep,
	JobTableViewsList,
	JobTitlesList,
	JobUserGeneratedContent,
	KnowledgeBaseCategories,
	KnowledgeBasePageElements,
	KnowledgeBasePages,
	LoadState,
	LogItem,
	Me,
	PermissionCategoriesList,
	PermissionsList,
	PreUpgradeData,
	RolesList,
	RouteConditionList,
	SelectedElement,
	StartMarker,
	StepContentElementList,
	StepsList,
	Subscription,
	SubWorkflowConnectorList,
	TableColumn,
	UsersList,
	WebhooksList,
	WorkflowCategoriesList,
	WorkflowsList,
} from "design-system";
import { hydrateCountries } from "./utils/helpers";

const countries = require("../src/data/countryMap.json");

export interface InitialState {
	authenticated: boolean;
	bootstrapping: boolean;
	hasInternet: boolean;
	superUser: boolean;
	system: {
		online: boolean;
		capturedError: any;
	};
	selectedWorkflowKey: string | null;
	loadStates: {
		accessTokens: LoadState;
		workingWorkflows: LoadState;
		publishedWorkflows: LoadState;
		jobs: LoadState;
		startingNewJob: LoadState;
		openJobNextStep: LoadState;
		publicJob: LoadState;
		dashboard: LoadState;
		webhooks: LoadState;
		externalEndpoints: LoadState;
		receipts: LoadState;
	};
	local: {
		billing: {
			selectedBillingCycleKey: string | null;
		};
		externalEndpoints: {
			selectedResponseKey: string | null;
		};
		workflowsList: {
			selectedExtraOptionsKey: string | null;
		};
	};
	support: {
		playIntroVideo: boolean;
	};
	me: Me | null;
	account: Account | null;
	subscription: Subscription | null;
	preUpgradeData: PreUpgradeData | null;
	potentialFeatures: FeatureVotes;
	editor: {
		publishing: boolean;
		selectedElement: SelectedElement;
		diagramOffset: XYCoords;
		selectedRouteKey: string | null;
		selectedSectionKey: string | null;
		elementKeyToReorder: string | null;
		contentElementKeyInEditMode: string | null;
		parentConditionalElementKeyInEditMode: string | null;
		selectedFormFieldKey: string | null;
		draggingElement: null | DraggedElement;
		hoveredElement: null | SelectedElement;
		zoomedPercent: number;
	};
	accessTokens: AccessTokensList;
	startMarkers: {
		[workflowKey: string]: StartMarker;
	};
	dashboard: {
		selectedOption: DashboardMenuOptions;
		sortBy: DashboardSortOptions;
		settings: {};
		tallies: {
			[viewKey: string]: number;
		};
		views: {
			[viewKey: string]: Array<string>;
		};
	};
	health: {
		available: boolean;
		version: number | null;
	};
	shared: {
		countries: Countries;
	};
	formFields: FormFieldsList;
	integrations: IntegrationList;
	users: UsersList;
	jobTitles: JobTitlesList;
	roles: RolesList;
	permissions: PermissionsList;
	permissionCategories: PermissionCategoriesList;
	selectedJobStepKey: string | null;
	openJob: {
		jobStepKeyWaiting: string | null;
		nextInstructions: string | null;
		nextJobStepKey: string | null;
		pollingTimeStart: number;
		pollingCount: number;
		prefilledFields: Array<{ key: string; value: string }>;
		unsavedFields: { [jobKey: string]: { [jobStepKey: string]: Array<string> } };
		knowledgeBase: {
			include: boolean;
			loadedByWorkflowKey: Array<string>;
			show: boolean;
			selectedPageKey: string | null;
			categoryKeys: Array<string>;
		};
	};
	animationsQueue: Array<string>;
	jobTableViews: JobTableViewsList;
	jobTableViewControls: {
		selectedViewKey: string | null;
		defaultViewKey: string | null;
		showAccessSidebar: boolean;
		showFiltersSidebar: boolean;
		showCreateViewSidebar: boolean;
		showCopyViewSidebar: boolean;
		showColumnsModal: boolean;
		showDeleteViewPrompt: boolean;
		showRenameModal: boolean;
	};
	jobs: { [jobKey: string]: Job };
	jobRefreshCount: number;
	jobLogs: { [jobKey: string]: Array<LogItem> };
	jobsSteps: { [jobStepKey: string]: JobStep };
	jobsActions: { [jobActionKey: string]: JobAction };
	jobsDelays: { [jobDelayKey: string]: any };
	jobsAutomationVariables: {
		[jobKey: string]: {
			[integrationKey: string]: {
				[eventKey: string]: {
					[variableKey: string]: any;
				};
			};
		};
	};
	jobsContent: JobUserGeneratedContent;
	jobsComments: {
		[jobKey: string]: JobCommentsList;
	};
	jobsFields: { [jobKey: string]: { [fieldKey: string]: any } };
	builtInTableColumns: Array<TableColumn>;
	steps: StepsList;
	ends: EndList;
	actions: ActionsList;
	workflows: WorkflowsList;
	workflowCategories: WorkflowCategoriesList;
	subWorkflowConnectors: SubWorkflowConnectorList;
	elementConnections: ElementConnectionList;
	conditionalContentConditions: ContentConditionsList;
	stepContentElements: StepContentElementList;
	elementRoutes: ElementRoutesList;
	connectors: ConnectorsList;
	delays: DelaysList;
	elementRouteConditions: RouteConditionList;
	fileUploadMonitor: FileUploadMonitor;
	knowledgeBase: {
		categories: Array<string>; // ordered
		settings: any;
		loaded: boolean;
		selectedCategoryKeys: Array<string>;
		selectedPageKey: string | null;
		selectedAccessControlCategoryKey: string | null;
		pageMode: "edit" | "view";
	};
	knowledgeBaseCategories: KnowledgeBaseCategories;
	knowledgeBasePages: KnowledgeBasePages;
	knowledgeBasePageElements: KnowledgeBasePageElements;
	selectedApiResponses: ApiResponseLogs;
	webhooks: WebhooksList;
	externalEndpoints: ExternalEndpointList;
	externalEndpointResponses: {
		[key: string]: ExternalEndpointResponse;
	};
}

const initialState: InitialState = {
	authenticated: false,
	bootstrapping: true,
	hasInternet: true,
	system: {
		online: true,
		capturedError: null,
	},
	superUser: false,
	selectedWorkflowKey: null,
	loadStates: {
		accessTokens: LoadState.NOT_LOADED,
		workingWorkflows: LoadState.NOT_LOADED,
		publishedWorkflows: LoadState.NOT_LOADED,
		jobs: LoadState.NOT_LOADED,
		startingNewJob: LoadState.NOT_LOADED,
		openJobNextStep: LoadState.LOADED,
		publicJob: LoadState.NOT_LOADED,
		dashboard: LoadState.NOT_LOADED,
		webhooks: LoadState.NOT_LOADED,
		externalEndpoints: LoadState.NOT_LOADED,
		receipts: LoadState.NOT_LOADED,
	},
	local: {
		billing: {
			selectedBillingCycleKey: null,
		},
		externalEndpoints: {
			selectedResponseKey: null,
		},
		workflowsList: {
			selectedExtraOptionsKey: null,
		},
	},
	me: null,
	account: null,
	subscription: null,
	preUpgradeData: null,
	support: {
		playIntroVideo: false,
	},
	animationsQueue: [],
	potentialFeatures: {
		selected: [],
		options: {},
	},
	fileUploadMonitor: {},
	dashboard: {
		tallies: {},
		views: {},
		settings: {
			columns: {
				builtins: [],
				byWorkflow: {},
			},
		},
		selectedOption: DashboardMenuOptions.Open,
		sortBy: DashboardSortOptions.LAST_UPDATED,
	},
	accessTokens: {},
	editor: {
		publishing: false,
		selectedElement: {
			key: null,
			type: null,
		},
		selectedRouteKey: null,
		selectedSectionKey: null,
		contentElementKeyInEditMode: null,
		parentConditionalElementKeyInEditMode: null,
		selectedFormFieldKey: null,
		elementKeyToReorder: null,
		hoveredElement: null,
		draggingElement: null,
		diagramOffset: { x: 0, y: 0 },
		zoomedPercent: 100,
	},
	startMarkers: {},
	formFields: {},
	integrations: {},
	health: {
		available: true,
		version: null,
	},
	shared: {
		countries: hydrateCountries(countries),
	},
	users: {},
	jobTitles: {},
	roles: {},
	permissions: {},
	permissionCategories: {},
	selectedJobStepKey: null,
	openJob: {
		// changes to this struct should be reflected in CLEANUP_OPEN_JOB
		jobStepKeyWaiting: null,
		nextInstructions: null,
		nextJobStepKey: null,
		pollingCount: 0,
		pollingTimeStart: 0,
		prefilledFields: [],
		unsavedFields: {},
		knowledgeBase: {
			include: false,
			loadedByWorkflowKey: [],
			show: false,
			selectedPageKey: null,
			categoryKeys: [],
		},
	},
	jobTableViews: {},
	jobTableViewControls: {
		selectedViewKey: null, // this will be set automatically when the job list page is opened
		defaultViewKey: null,
		showAccessSidebar: false,
		showFiltersSidebar: false,
		showCreateViewSidebar: false,
		showCopyViewSidebar: false,
		showColumnsModal: false,
		showDeleteViewPrompt: false,
		showRenameModal: false,
	},
	jobs: {},
	jobRefreshCount: 0,
	jobLogs: {},
	jobsSteps: {},
	jobsDelays: {},
	jobsFields: {},
	jobsContent: {},
	jobsComments: {},
	jobsAutomationVariables: {},
	jobsActions: {},
	builtInTableColumns: [
		{
			key: "assignedTo",
			label: "Assigned To",
			type: ColumnType.BuiltIn,
			width: 250,
			order: 1,
		},
		{
			key: "initiator",
			label: "Started By",
			type: ColumnType.BuiltIn,
			width: 250,
			order: 2,
		},
		{
			key: "startedDate",
			label: "Started On",
			type: ColumnType.BuiltIn,
			width: 160,
			order: 3,
		},
		{
			key: "step",
			label: "Current Step",
			type: ColumnType.BuiltIn,
			width: 250,
			order: 4,
		},
		{
			key: "currentWorkflow",
			label: "Current Workflow",
			type: ColumnType.BuiltIn,
			width: 250,
			order: 5,
		},
		{
			key: "workflows",
			label: "Workflows",
			type: ColumnType.BuiltIn,
			width: 250,
			order: 6,
		},
		{
			key: "status",
			label: "Job Status",
			type: ColumnType.BuiltIn,
			width: 200,
			order: 7,
		},
		{
			key: "completedDate",
			label: "Completed At",
			type: ColumnType.BuiltIn,
			width: 200,
			order: 8,
		},
		{
			key: "updatedDate",
			label: "Last Updated",
			type: ColumnType.BuiltIn,
			width: 200,
			order: 9,
		},
	],
	actions: {},
	connectors: {},
	ends: {},
	elementConnections: {},
	elementRoutes: {},
	elementRouteConditions: {},
	steps: {},
	stepContentElements: {},
	conditionalContentConditions: {},
	subWorkflowConnectors: {},
	delays: {},
	workflows: {},
	workflowCategories: {},
	knowledgeBase: {
		categories: [],
		settings: {},
		loaded: false,
		selectedCategoryKeys: [],
		selectedPageKey: null,
		selectedAccessControlCategoryKey: null,
		pageMode: "view",
	},
	knowledgeBaseCategories: {},
	knowledgeBasePages: {},
	knowledgeBasePageElements: {},
	selectedApiResponses: {},
	webhooks: {},
	externalEndpoints: {},
	externalEndpointResponses: {},
};

export { initialState as default };
