import {
	SET_AUTHORIZED_USER,
	REQUEST_APP_ESSENTIALS,
	REQUEST_AUTHORIZED_USER,
	SET_APP_ESSENTIALS,
	INITIAL_LOAD_COMPLETE,
	EMPTY_SUCCESS_RESPONSE,
	START_REAL_TIME_UPDATES,
	REQUEST_APP_NON_ESSENTIALS,
	SET_FEATURE_VOTES,
	RECONNECT_REAL_TIME_UPDATES,
	START_INTRO_VIDEO,
	STOP_INTRO_VIDEO, REQUEST_CSRF_TOKEN,
} from "../../actionTypes";
import {
	ACCOUNT_CLOSED,
	ACCOUNT_SUSPENDED,
	NETWORK_FAILURE,
	SERVER_ERROR,
	TRIAL_EXPIRED,
	USER_NOT_AUTHENTICATED,
	USER_NOT_AUTHORIZED,
} from "../../errorTypes";
import { AxiosError, AxiosResponse } from "axios";
import { normalizeBasic } from "../../data/normalize/basic";
import { User, FormField, WorkflowCategory, Workflow, Role, JobTitle } from "design-system";

export function requestCSRFToken() {
	return {
		type: REQUEST_CSRF_TOKEN,
	};
}

export function requestAuthenticatedUser() {
	return {
		type: REQUEST_AUTHORIZED_USER,
	};
}

export function setAuthenticatedUser(response: AxiosResponse) {
	return {
		type: SET_AUTHORIZED_USER,
		me: response.data.data.me,
	};
}

export function apiRequestFailed(error: AxiosError) {
	if (!error.response) {
		// could be several errors, for example:
		// - net::ERR_NETWORK_CHANGED
		// - net::ERR_INTERNET_DISCONNECTED
		return {
			type: NETWORK_FAILURE,
			capturedError: {
				code: error.code,
				response: "network error - no response provided",
			},
		};
	}

	if (error.response.status === 401) {
		return {
			type: USER_NOT_AUTHENTICATED,
		};
	}

	if (error.response.status === 402 && error.response.data.type === "trial_expired") {
		return {
			type: TRIAL_EXPIRED,
		};
	}

	if (error.response.status === 402 && error.response.data.type === "account_suspended") {
		return {
			type: ACCOUNT_SUSPENDED,
		};
	}

	if (error.response.status === 402 && error.response.data.type === "account_closed") {
		return {
			type: ACCOUNT_CLOSED,
		};
	}

	if (error.response.status === 403) {
		return {
			type: USER_NOT_AUTHORIZED,
		};
	}

	if (error.response.status === 404) {
		return {
			type: "NOT_FOUND",
		};
	}

	// 404 not found? How to customize this per resource? ie they navigate to a job that doesn't exist, return to the jobs index?

	if (error.response.status >= 500) {
		return {
			type: SERVER_ERROR,
			capturedError: {
				code: error.response.status,
				request: {
					url: error.config.url,
					method: error.config.method,
					data: error.config.data,
				},
				response: error.response.data,
			},
		};
	}

	return {
		type: "UNKNOWN_ERROR",
	};
}

export function requestAppEssentials() {
	return {
		type: REQUEST_APP_ESSENTIALS,
	};
}

export function startRealTimeUpdates() {
	return {
		type: START_REAL_TIME_UPDATES,
	};
}

export function reconnectRealTimeUpdates() {
	return {
		type: RECONNECT_REAL_TIME_UPDATES,
	};
}

export function requestAppNonEssentials() {
	return {
		type: REQUEST_APP_NON_ESSENTIALS,
	};
}

export function setAppEssentials(
	accountRaw: any,
	categoriesRaw: Array<WorkflowCategory>,
	workflowsRaw: Array<Workflow>,
	usersRaw: Array<User>,
	fieldsRaw: Array<FormField>,
	integrationsRaw: Array<any>,
	rolesRaw: Array<Role>,
	jobTitlesRaw: Array<JobTitle>
) {
	return {
		type: SET_APP_ESSENTIALS,
		account: accountRaw,
		categories: normalizeBasic("categories", categoriesRaw),
		workflows: normalizeBasic("workflows", workflowsRaw),
		users: normalizeBasic("users", usersRaw),
		fields: normalizeBasic("fields", fieldsRaw),
		integrations: normalizeBasic("integations", integrationsRaw),
		roles: normalizeBasic("roles", rolesRaw),
		jobTitles: normalizeBasic("jobTitles", jobTitlesRaw),
	};
}

export function setFeatureVotes(data: any) {
	return {
		type: SET_FEATURE_VOTES,
		votes: data.votes,
		options: data.options,
	};
}

export function appInitialLoadComplete() {
	return {
		type: INITIAL_LOAD_COMPLETE,
	};
}

export function emptySuccessResponse() {
	return {
		type: EMPTY_SUCCESS_RESPONSE,
	};
}

export function startIntroVideo() {
	return {
		type: START_INTRO_VIDEO,
	};
}

export function stopIntroVideo() {
	return {
		type: STOP_INTRO_VIDEO,
	};
}
