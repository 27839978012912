import { AnyAction } from "redux";
import { trackWorkflowChanges } from "./trackWorkflowChanges";

const middleware = [trackWorkflowChanges];

export function runMiddleware(action: AnyAction) {
	let actions: Array<AnyAction> = [];

	middleware.forEach((fn) => {
		const result: null | AnyAction = fn(action);

		if (result) {
			actions.push(result);
		}
	});

	return actions;
}
