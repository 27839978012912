import React, { useState } from "react";
import { Button, Checkbox, Dropdown, DropdownItem, ShortText } from "design-system";
import { useDispatch } from "react-redux";
import { updateExternalEndpointResponseVariable } from "../../developerActions";
import { DeveloperAPI } from "../../../../api/DeveloperAPI";
import { sendDebouncedRequestToAPI, sendRequestToAPI } from "../../../../sharedActions";
import { ExternalEndpointResponseVariable } from "design-system/src/types/developers";

import styles from "./ResponseBuilder.module.scss";

interface Props {
	endpointKey: string;
	responseKey: string;
	variable: ExternalEndpointResponseVariable;
}

const variableTypes = [
	{ key: "date", label: "Date" },
	{ key: "text", label: "Text" },
	{ key: "email", label: "Email Address" },
	{ key: "number", label: "Number" },
	{ key: "boolean", label: "Boolean" },
];

function formatSelectedVariable(selectedKey: string) {
	return variableTypes.find((type) => type.key === selectedKey) as DropdownItem;
}

function formatExampleValue(value: any) {
	if (value === null) {
		return "null";
	}

	if (value === false) {
		return "false";
	}

	if (value === true) {
		return "true";
	}

	return value;
}

const Variable: React.FunctionComponent<Props> = ({ endpointKey, responseKey, variable }) => {
	const dispatch = useDispatch();
	const [amEditing, setAmEditing] = useState(false);

	function update(variableName: string, value: any) {
		dispatch(updateExternalEndpointResponseVariable(endpointKey, variable.key, variableName, value));

		dispatch(
			sendRequestToAPI(
				DeveloperAPI.updateExternalEndpointResponseVariable(
					endpointKey,
					variable.key,
					variableName,
					value
				)
			)
		);
	}

	function debouncedUpdate(variableName: string, value: any) {
		if (variableName === "key") {
			value = value.replace(" ", ".").trim();
		}

		dispatch(updateExternalEndpointResponseVariable(endpointKey, variable.key, variableName, value));

		dispatch(
			sendDebouncedRequestToAPI(
				DeveloperAPI.updateExternalEndpointResponseVariable(
					endpointKey,
					variable.key,
					variableName,
					value
				)
			)
		);
	}

	if (amEditing) {
		return (
			<div className={styles.Variable}>
				<div className={styles.EditScreen}>
					<div>
						<p>Label</p>
						<ShortText
							label=""
							id="label"
							value={variable.label}
							onChange={(event) =>
								debouncedUpdate("label", (event.target as HTMLInputElement).value)
							}
						/>
					</div>

					<div>
						<p>Key</p>
						<ShortText
							label=""
							id="key"
							value={variable.key}
							onChange={(event) =>
								debouncedUpdate("key", (event.target as HTMLInputElement).value)
							}
						/>
					</div>
				</div>

				<div style={{ marginTop: "12px" }}>
					<Button
						color="primary"
						theme="solid"
						tone="medium"
						size="compact"
						onClick={() => setAmEditing(false)}
					>
						Done
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.Variable}>
			<div className={styles.Details}>
				<div>
					<Checkbox
						id={`variable-${variable.key}`}
						isChecked={variable.active}
						onChange={() => update("active", !variable.active)}
						label={variable.label}
					/>
					{variable.active && (
						<div className={styles.EditBtn} onClick={() => setAmEditing(true)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								style={{ marginLeft: "8px" }}
							>
								<g transform="translate(-629 -1200)">
									<path
										d="M0,12A12,12,0,1,0,12,0,12,12,0,0,0,0,12"
										transform="translate(629 1200)"
										fill="#f0f3fc"
									/>
									<path
										d="M18.376,10.469l-7.981,8.494-2.721.064a.364.364,0,0,1-.369-.355l-.077-2.75,7.981-8.5a1.441,1.441,0,0,1,2.054-.048l1.056,1.014a1.48,1.48,0,0,1,.058,2.076"
										transform="translate(628 1199)"
										fill="#5583ed"
									/>
								</g>
							</svg>
						</div>
					)}
				</div>
				<span>{formatExampleValue(variable.exampleValue)}</span>
			</div>
			{variable.active && (
				<Dropdown
					className={styles.VariableType}
					options={variableTypes}
					theme="slim"
					onChange={(type: DropdownItem) => update("type", type.key)}
					value={formatSelectedVariable(variable.type)}
					label=""
				/>
			)}
		</div>
	);
};

export default Variable;
