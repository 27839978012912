import React from "react";
import { Tooltip } from "design-system";

interface Props {
	stepKey: string;
	name: string | null;
	isDeleted: boolean;
}

const StepName: React.FunctionComponent<Props> = ({ stepKey, name, isDeleted }) => {
	if (isDeleted) {
		return (
			<Tooltip
				id={`deleted_step_${stepKey}`}
				placement="right"
				trigger={["hover"]}
				overlay={<span>This step was deleted from the workflow.</span>}
			>
				<h4>{name}</h4>
			</Tooltip>
		);
	}

	return <h4>{name}</h4>;
};

export default StepName;
