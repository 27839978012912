import React, { MutableRefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import UploadedImage from "./UploadedImage";
import Uploading from "./Uploading";
import DefaultImage from "./DefaultImage";
import { Button } from "design-system";
import { InitialState } from "../../../initialState";
import { useSelector } from "react-redux";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { DEFAULT_IMAGE_NAME } from "../../../utils/constants";

import styles from "./ProfileImage.module.scss";

interface Props extends RouteComponentProps {
	monitorKey?: string;
	imageUrl: string | null;
	className?: string;
	uploadImage: (data: FormData) => Promise<"error" | undefined>;
	removeImage: () => void;
}

enum ImageState {
	hasNoImage = "hasNoImage",
	isUploading = "isUploading",
	hasImage = "hasImage",
}

const ProfileImageEdit: React.FunctionComponent<Props> = ({
	monitorKey,
	imageUrl,
	removeImage,
	uploadImage,
	className,
}) => {
	const fileUploadMonitor = useSelector(
		(state: InitialState) => state.fileUploadMonitor[monitorKey === undefined ? "nomatch" : monitorKey]
	);

	const prevMonitor = usePrevious(fileUploadMonitor);
	const [imageState, setImageState] = useState<ImageState>(
		imageUrl && imageUrl.includes(DEFAULT_IMAGE_NAME) === false
			? ImageState.hasImage
			: ImageState.hasNoImage
	);

	useEffect(() => {
		if (!monitorKey) return;

		if (prevMonitor !== undefined && fileUploadMonitor === undefined) {
			// finished
			setImageState(ImageState.hasImage);
			return;
		}

		if (fileUploadMonitor !== undefined && fileUploadMonitor.errors !== null) {
			setImageState(ImageState.hasNoImage);
			return;
		}

		if (fileUploadMonitor !== undefined) {
			setImageState(ImageState.isUploading);
		}
	}, [fileUploadMonitor]);

	useEffect(() => {
		if (imageUrl && !imageUrl.includes(DEFAULT_IMAGE_NAME) && imageState === ImageState.hasNoImage) {
			setImageState(ImageState.hasImage);
		}
	}, [imageUrl]);

	const uploadControlsRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const fileInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

	const classes = classNames([styles.ProfileImageEdit, styles[imageState], className]);

	function showFileDialog() {
		if (!fileInputRef.current) return;

		fileInputRef.current.click();
	}

	async function startUploadProcess(event: SyntheticEvent) {
		if (!fileInputRef.current) return;
		if (!fileInputRef.current.files) return;

		setImageState(ImageState.isUploading);

		const data = new FormData();

		data.append("profile", fileInputRef.current.files[0]);

		const result = await uploadImage(data);

		if (result === "error") {
			// this is only for flows that don't have access to the store
			// like the public uploads for activating a new account or user
			setImageState(ImageState.hasNoImage);
		} else if (monitorKey === undefined) {
			setImageState(ImageState.hasImage);
		}

		fileInputRef.current.value = "";
	}

	function resetImage() {
		removeImage();

		setImageState(ImageState.hasNoImage);
	}

	return (
		<div className={classes}>
			<div className={styles.Image}>
				<DefaultImage />
				<UploadedImage imageUrl={imageUrl} removeImage={resetImage} />
				<Uploading />
			</div>

			<div className={styles.UploadControls} ref={uploadControlsRef}>
				<Button className={styles.UploadBtn} theme="soft" tone="light" onClick={showFileDialog}>
					Add Profile Image
				</Button>

				<input
					type="file"
					style={{ display: "none" }}
					accept="image/png, image/jpeg"
					multiple={false}
					ref={fileInputRef}
					onChange={startUploadProcess}
				/>
				<span>Maximum size: 10MB</span>
			</div>
			<div className={styles.ChangeImageLink} onClick={showFileDialog}>
				<p>Change Profile Image</p>
			</div>
		</div>
	);
};

export default ProfileImageEdit;
