import React from "react";
import { LogItem } from "design-system";
import classNames from "classnames";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const RouteDirectionChanged: React.FunctionComponent<Props> = ({ logItem }) => {
	return (
		<React.Fragment>
			<div className={classNames([styles.Indicator, styles.Yellow])}></div>
			<div className={styles.Event}>
				<span>Job Change</span>
				<p>Route Direction Changed</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default RouteDirectionChanged;
