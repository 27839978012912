import {
	CanvasElementMeasurements,
	CanvasElementType,
	ConditionsSettings,
	CursorTypes,
	ElementRoute,
	ElementType,
	LinePosition,
	RouteConditionList,
} from "design-system";
import { DiagramObjectController } from "./DiagramObjectController";
import { MousePositionCoordinates } from "./ObjectsCreator";
import {
	calculateEntireConditionsGroupsMeasurements,
	changeCursor,
	elementHasNotMoved,
	isRouteConditionsSetup,
	roundedRectangle,
	XYCoords,
} from "../utils";
import { Diagram } from "../Diagram";
import { store } from "../../../../../store";
import { InitialState } from "../../../../../initialState";
import { SmallTextSizeGuide, TextRenderEngine } from "../TextRenderEngine";
import { moveRouteConditions, selectElement, showConditionalConnectionPanel } from "../../workflowActions";
import { RouteSnapping } from "./RouteSnapping";
import { sendRequestToAPI } from "../../../../../sharedActions";
import { WorkflowAPI } from "../../../../../api/WorkflowAPI";
import { Routes } from "./Routes";
import throttle from "lodash.throttle";
import { RouteConditionRenderer } from "./RouteConditionRenderer";
import { Unsubscribe } from "redux";

interface RouteOriginalDestination {
	coordX: number;
	coordY: number;
	width: number;
	height: number;
	key: string;
	type: ElementType;
	direction: LinePosition;
}

export const CONDITION_SETUP_OBJECT_WIDTH = 180;
export const CONDITION_SETUP_OBJECT_HEIGHT = 95;

interface CollectedCondition {
	routeKey: string;
	conditions: Array<any>;
	settings: ConditionsSettings;
	measurements: { coordX: number; coordY: number; width: number; height: number };
	routeOriginalDestination: RouteOriginalDestination;
}

interface RouteConditionGateway {
	key: string;
	coordX: number;
	coordY: number;
}

let elementRouteConditions: RouteConditionList;

export class RouteCondition implements DiagramObjectController {
	private readonly Diagram: Diagram;
	private readonly unsubscribeStoreFn: Unsubscribe;
	private collected: { [key: string]: CollectedCondition } = {};

	private mousePositionOffset: XYCoords = { x: 0, y: 0 };
	private selectedRoute: RouteConditionGateway | null = null;
	private initialCoords: XYCoords = { x: 0, y: 0 };
	private draggingConditionKey: string | null = null;
	private preparedToMove: boolean = false;

	private connectorPositionOffsets: { [key: string]: XYCoords } = {};
	private attachedConnectors: { [connectorKey: string]: XYCoords } = {};

	private RouteSnapping: RouteSnapping | null = null;

	private throttledTrackMouseEvent: any;
	private throttledMoveEvent: any;
	private zoomVelocityOffset: { x: number; y: number } = { x: 0, y: 0 };

	constructor(Diagram: Diagram) {
		this.Diagram = Diagram;

		this.throttledMoveEvent = null;
		this.throttledTrackMouseEvent = null;

		this.unsubscribeStoreFn = store.subscribe(this.listen.bind(this));
	}

	queue(route: ElementRoute, routeOriginalDestination: RouteOriginalDestination) {
		if (!route.conditionsSettings) {
			return;
		}
		// todo probably need to setup a listener on elementRouteConditions to factor in changes

		this.collected[`${route.key}_conditions`] = {
			routeKey: route.key,
			conditions: route.conditions,
			settings: route.conditionsSettings,
			measurements: calculateEntireConditionsGroupsMeasurements(route),
			routeOriginalDestination,
		};
	}

	getConditions(routeKey: string) {
		return this.collected[`${routeKey}_conditions`];
	}

	clear() {
		this.collected = {};
	}

	cleanup(): void {
		this.unsubscribeStoreFn();
	}

	handleClick(key: string, mousePosition: MousePositionCoordinates): void {
		const routeKey = this.collected[key].routeKey;

		const parent = store.getState().elementRoutes[routeKey].from;

		if (parent.type === ElementType.Step) {
			store.dispatch(selectElement(parent.key, ElementType.Step));
		}

		if (parent.type === ElementType.Action) {
			store.dispatch(selectElement(parent.key, ElementType.Action));
		}

		changeCursor(CursorTypes.DEFAULT);
		store.dispatch(showConditionalConnectionPanel());
	}

	handleHoverStart(key: string, mousePosition: MousePositionCoordinates): void {
		changeCursor(CursorTypes.POINTER);
	}

	handleHoverEnd(key: string): void {
		changeCursor(CursorTypes.DEFAULT);
	}

	handleMouseDown(key: string, mousePosition: MousePositionCoordinates): void {
		this.preparedToMove = false;
		this.draggingConditionKey = key;

		this.prepareToMove = this.prepareToMove.bind(this);
		this.trackMouse = this.trackMouse.bind(this);
		this.move = this.move.bind(this);
		this.stopMoving = this.stopMoving.bind(this);

		this.throttledMoveEvent = throttle(this.trackMouse, 40, { leading: true });
		this.throttledTrackMouseEvent = throttle(this.move, 40, { leading: true });

		window.addEventListener("mousemove", this.prepareToMove);
		window.addEventListener("mousemove", this.throttledTrackMouseEvent);
		window.addEventListener("mousemove", this.throttledMoveEvent);
		window.addEventListener("mouseup", this.stopMoving);
	}

	prepareToMove(event: MouseEvent) {
		if (this.preparedToMove || this.draggingConditionKey === null) {
			return;
		}
		this.preparedToMove = true;
		const mousePosition = this.Diagram.getCanvasMousePosition(event);

		const collectedCondition = this.collected[this.draggingConditionKey];
		const route = store.getState().elementRoutes[collectedCondition.routeKey] as ElementRoute;

		if (!route.conditionsSettings) {
			return;
		}

		const coordX = route.conditionsSettings.coordX as number;
		const coordY = route.conditionsSettings.coordY as number;
		this.selectedRoute = {
			key: route.key,
			coordX,
			coordY,
		};

		this.initialCoords = { x: coordX, y: coordY };

		this.setDependents(route, mousePosition);

		this.mousePositionOffset = {
			x: mousePosition.x - coordX,
			y: mousePosition.y - coordY,
		};

		const speed = this.Diagram.getZoomVelocity();

		this.zoomVelocityOffset = {
			x: Math.round(mousePosition.x * speed),
			y: Math.round(mousePosition.y * speed),
		};

		this.RouteSnapping = new RouteSnapping(
			{
				...this.selectedRoute,
				coordX: this.selectedRoute.coordX,
			},
			ElementType.Conditions,
			null,
			this.Diagram.getWorkflowKey()
		);

		window.removeEventListener("mousemove", this.prepareToMove);
	}

	setDependents(route: ElementRoute, mousePosition: MousePositionCoordinates) {
		const state = store.getState() as InitialState;
		const connectors = state.connectors;

		if (route.to.type === ElementType.Connector) {
			const connectorKey = route.to.key;
			this.attachedConnectors[connectorKey] = {
				x: connectors[connectorKey].coordX,
				y: connectors[connectorKey].coordY,
			};
			this.connectorPositionOffsets[connectorKey] = {
				x: mousePosition.x - this.attachedConnectors[connectorKey].x,
				y: mousePosition.y - this.attachedConnectors[connectorKey].y,
			};
		}
	}

	trackMouse(event: MouseEvent) {
		if (!this.selectedRoute) return;

		const mousePosition = this.Diagram.getCanvasMousePosition(event, this.zoomVelocityOffset);

		this.selectedRoute.coordX = mousePosition.x - this.mousePositionOffset.x;
		this.selectedRoute.coordY = mousePosition.y - this.mousePositionOffset.y;

		const snapOffset: XYCoords = (this.RouteSnapping as RouteSnapping).calculateSnapAmount(
			this.selectedRoute.coordX,
			this.selectedRoute.coordY
		);

		this.selectedRoute.coordX += snapOffset.x;
		this.selectedRoute.coordY += snapOffset.y;

		Object.keys(this.attachedConnectors).forEach((connectionKey) => {
			this.attachedConnectors[connectionKey].x =
				mousePosition.x - this.connectorPositionOffsets[connectionKey].x + snapOffset.x;
			this.attachedConnectors[connectionKey].y =
				mousePosition.y - this.connectorPositionOffsets[connectionKey].y + snapOffset.y;
		});
	}

	move(event: MouseEvent) {
		if (!this.selectedRoute) {
			return;
		}

		changeCursor(CursorTypes.GRABBING);
		store.dispatch(
			moveRouteConditions(this.selectedRoute as RouteConditionGateway, this.attachedConnectors)
		);
	}

	stopMoving(event: MouseEvent) {
		changeCursor(CursorTypes.DEFAULT);

		window.removeEventListener("mousemove", this.prepareToMove);
		window.removeEventListener("mousemove", this.throttledTrackMouseEvent);
		window.removeEventListener("mousemove", this.throttledMoveEvent);
		window.removeEventListener("mouseup", this.stopMoving);

		if (!this.selectedRoute) return;

		const coordX = Math.round(this.selectedRoute.coordX);
		const coordY = Math.round(this.selectedRoute.coordY);

		if (elementHasNotMoved(this.initialCoords.x, this.initialCoords.y, coordX, coordY)) {
			this.resetProperties();

			return;
		}

		store.dispatch(
			sendRequestToAPI(
				WorkflowAPI.updateRouteConditionsSettings(this.selectedRoute.key, {
					coordX,
					coordY,
				})
			)
		);

		if (Object.keys(this.attachedConnectors).length) {
			store.dispatch(sendRequestToAPI(WorkflowAPI.moveConnectors(this.attachedConnectors)));
		}

		this.resetProperties();
	}

	resetProperties() {
		this.selectedRoute = null;
		this.draggingConditionKey = null;
		this.preparedToMove = false;
		this.initialCoords = { x: 0, y: 0 };
		this.mousePositionOffset = { x: 0, y: 0 };
		this.attachedConnectors = {};
		this.connectorPositionOffsets = {};
		this.RouteSnapping = null;
		this.throttledMoveEvent = null;
		this.zoomVelocityOffset = { x: 0, y: 0 };
	}

	visualize(conditions: CollectedCondition, isSetup: boolean, context: CanvasRenderingContext2D) {
		if (isSetup) {
			this.drawConditions(conditions, context);
		} else {
			this.drawIncompleteSetup(conditions.measurements, context);
		}
	}

	virtualize(conditions: CollectedCondition, context: CanvasRenderingContext2D) {
		context.fillStyle = this.Diagram.getHitGraph().getRgbColorStringFromElementKey(
			`${conditions.routeKey}_conditions`
		);

		context.beginPath();
		context.rect(
			conditions.measurements.coordX,
			conditions.measurements.coordY,
			conditions.measurements.width,
			conditions.measurements.height
		);
		context.fill();
	}

	drawConditions(collectedConditions: CollectedCondition, context: CanvasRenderingContext2D) {
		const conditions = (store.getState() as InitialState).elementRouteConditions;

		const conditionsMeasurements: Array<{ x: number; y: number; height: number }> = [];

		this.drawGroupOuterBox(collectedConditions.measurements, context);

		collectedConditions.conditions.forEach((conditionKey, index) => {
			const renderer = new RouteConditionRenderer(
				conditions[conditionKey],
				collectedConditions.settings,
				collectedConditions.measurements,
				index,
				collectedConditions.conditions.length,
				conditionsMeasurements,
				context
			);

			conditionsMeasurements.push({
				...renderer.getConditionMeasurements(),
			});

			renderer.render(this.Diagram);
		});
	}

	drawIncompleteSetup(
		measurements: { coordX: number; coordY: number; width: number; height: number },
		context: CanvasRenderingContext2D
	) {
		// The outer blue box
		context.fillStyle = "#CDEDF7";
		context.lineWidth = 1;
		context.strokeStyle = "#A0DEF2";

		roundedRectangle({
			context,
			...measurements,
			radius: 8,
		});
		context.fill();
		context.stroke();

		// The "Route Condition" text
		new TextRenderEngine(
			"Route Condition",
			{ x: measurements.coordX + 31, y: measurements.coordY + 16 },
			[0]
		)
			.setTextColor("#0E465C")
			.render(context);

		// The white button box
		context.fillStyle = "#FFFFFF";

		const whiteBoxCoordX = measurements.coordX + (measurements.width - 130) / 2;
		roundedRectangle({
			context,
			coordX: whiteBoxCoordX,
			coordY: measurements.coordY + 48,
			height: 30,
			width: 130,
			radius: 16,
		});
		context.fill();

		// The "Finish setup" text
		new TextRenderEngine(
			"Finish Setup",
			{
				x: whiteBoxCoordX + (130 - 84) / 2,
				y: measurements.coordY + 48 + 7,
			},
			[0]
		)
			.setSizing(new SmallTextSizeGuide())
			.setTextColor("#0E465C")
			.render(context);
	}

	drawGroupOuterBox(groupMeasurements: CanvasElementMeasurements, context: CanvasRenderingContext2D) {
		context.lineWidth = 1;
		context.strokeStyle = "#CACED9";
		context.fillStyle = "#F2F5FA";

		const cornerSharpness = 12;

		const startingX = groupMeasurements.coordX;
		const startingY = groupMeasurements.coordY + cornerSharpness;

		context.beginPath();
		context.moveTo(startingX, startingY);

		context.lineTo(startingX + cornerSharpness, startingY - cornerSharpness);

		context.lineTo(startingX + groupMeasurements.width - cornerSharpness, startingY - cornerSharpness);

		context.lineTo(startingX + groupMeasurements.width, startingY);

		context.lineTo(
			startingX + groupMeasurements.width,
			startingY + groupMeasurements.height - cornerSharpness * 2
		);

		context.lineTo(
			startingX + groupMeasurements.width - cornerSharpness,
			startingY + groupMeasurements.height - cornerSharpness
		);

		context.lineTo(startingX + cornerSharpness, startingY + groupMeasurements.height - cornerSharpness);

		context.lineTo(startingX, startingY + groupMeasurements.height - cornerSharpness * 2);

		context.closePath();
		context.stroke();
		context.fill();
	}

	render(): void {
		const context = this.Diagram.getContext();
		const hitGraph = this.Diagram.getHitGraph();
		const Routes = this.Diagram.getObjectsCreator().getByType(CanvasElementType.Routes) as Routes;

		Object.keys(this.collected).forEach((key) => {
			const conditions: CollectedCondition = this.collected[key];
			const isSetup = isRouteConditionsSetup(conditions.conditions);

			this.visualize(conditions, isSetup, context);
			this.virtualize(conditions, hitGraph.getContext());

			Routes.queue({
				key: `${conditions.routeKey}_conditions_line`,
				from: {
					key: conditions.routeKey,
					type: ElementType.Conditions,
					...conditions.measurements,
					coordX: conditions.measurements.coordX,
					direction: conditions.settings.routeFromDirection,
				},
				to: {
					...conditions.routeOriginalDestination,
					direction: conditions.settings.routeToDirection,
				},
				label: null,
				conditions: [],
				conditionsSettings: null,
			});
		});
	}

	listen() {
		let prevElementRouteConditions = elementRouteConditions;
		// use selectors
		elementRouteConditions = store.getState().elementRouteConditions;

		if (prevElementRouteConditions !== elementRouteConditions) {
			this.Diagram.render();
		}
	}
}
