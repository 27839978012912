import React from "react";

export const PreferenceImages = {
	collab: (
		<svg xmlns="http://www.w3.org/2000/svg" width="106.158" height="47.356" viewBox="0 0 106.158 47.356">
			<g transform="translate(-329.001 -219)">
				<circle
					cx="23.528"
					cy="23.528"
					r="23.528"
					transform="translate(329.001 219)"
					fill="#d9e4fc"
				/>
				<path
					d="M23.528,0A23.528,23.528,0,1,1,0,23.528,23.528,23.528,0,0,1,23.528,0Z"
					transform="translate(388.103 219)"
					fill="#cdedf7"
				/>
				<g transform="translate(337 225.729)">
					<path
						d="M1289.463,388.162a24.029,24.029,0,0,1-4.08-2.215q-.924-.624-1.791-1.335a15.451,15.451,0,0,1,30.808-.307,24.049,24.049,0,0,1-24.937,3.856Z"
						transform="translate(-1283.592 -349.95)"
						fill="#5583ed"
					/>
					<circle cx="9.132" cy="9.132" r="9.132" transform="translate(6.287 0)" fill="#5583ed" />
				</g>
				<g transform="translate(396.75 228.158)">
					<path
						d="M1265.257,827.79a22.88,22.88,0,0,1-3.887-2.109q-.881-.594-1.706-1.272a14.718,14.718,0,0,1,29.347-.292,22.906,22.906,0,0,1-23.755,3.673Z"
						transform="translate(-1259.665 -791.39)"
						fill="#229ec7"
					/>
					<circle cx="8.699" cy="8.699" r="8.699" transform="translate(5.989)" fill="#229ec7" />
				</g>
			</g>
		</svg>
	),
	management: (
		<svg xmlns="http://www.w3.org/2000/svg" width="103.7" height="45" viewBox="0 0 103.7 45">
			<g transform="translate(-1623.744 -660.011)">
				<rect
					width="103.7"
					height="18"
					rx="8.607"
					transform="translate(1623.744 660.011)"
					fill="#54b997"
				/>
				<g transform="translate(1623.744 687.011)">
					<rect width="103.7" height="18" rx="8.607" fill="#54b997" />
					<g transform="translate(1.771 1.82)">
						<circle cx="7.18" cy="7.18" r="7.18" fill="#b9e5d6" />
						<g transform="translate(2.7 3.829)">
							<path
								d="M1632.023,702.989a.651.651,0,0,1-.418-.149l-2.654-2.18a.658.658,0,0,1,.835-1.018l2.174,1.787,4.57-4.93a.658.658,0,1,1,.965.894l-4.99,5.386A.656.656,0,0,1,1632.023,702.989Z"
								transform="translate(-1628.711 -696.288)"
								fill="#fff"
							/>
						</g>
					</g>
				</g>
				<g transform="translate(1625.514 661.831)">
					<circle cx="7.18" cy="7.18" r="7.18" fill="#b9e5d6" />
					<g transform="translate(2.7 3.829)">
						<path
							d="M1632.023,672.989a.652.652,0,0,1-.418-.149l-2.654-2.18a.658.658,0,0,1,.835-1.018l2.174,1.787,4.57-4.93a.658.658,0,1,1,.965.894l-4.99,5.386A.656.656,0,0,1,1632.023,672.989Z"
							transform="translate(-1628.711 -666.288)"
							fill="#fff"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	automation: (
		<svg xmlns="http://www.w3.org/2000/svg" width="113.167" height="40.751" viewBox="0 0 113.167 40.751">
			<g transform="translate(-1156.257 -486.975)">
				<g>
					<g>
						<path
							d="M1202.4,506.037h24.034a1.331,1.331,0,0,1,0,2.662H1202.4a1.331,1.331,0,1,1,0-2.662Z"
							fill="#caced9"
						/>
					</g>
					<g>
						<path
							d="M1216.434,497.648a1.321,1.321,0,0,1,.855.312l10,8.388a1.331,1.331,0,0,1-1.71,2.04l-10-8.389a1.331,1.331,0,0,1,.856-2.351Z"
							fill="#a2a9ba"
						/>
					</g>
					<g>
						<path
							d="M1226.429,506.037a1.331,1.331,0,0,1,.854,2.353l-10,8.354a1.332,1.332,0,0,1-1.707-2.044l10-8.354A1.323,1.323,0,0,1,1226.429,506.037Z"
							fill="#a2a9ba"
						/>
					</g>
				</g>
				<circle
					cx="13.216"
					cy="13.216"
					r="13.216"
					transform="translate(1242.992 495.303)"
					fill="#b9e5d6"
				/>
				<path
					d="M1249.173,509.462l4.883,4.014,9.187-9.913"
					fill="none"
					stroke="#2a9672"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2.422"
				/>
				<g>
					<g>
						<path
							d="M1180.557,502.993l-2.921,1.712a11.128,11.128,0,0,0-3.2-1.8l-.045-3.361a.592.592,0,0,0-.586-.583l-6.541.094a.574.574,0,0,0-.576.572v0l.041,3.341a12.075,12.075,0,0,0-1.715.846,12.259,12.259,0,0,0-1.34.924l-3.1-1.761a.585.585,0,0,0-.8.224l0,.007-3.442,6.069a.583.583,0,0,0,.219.792l3.142,1.781a10.735,10.735,0,0,0,.065,3.473l-2.81,1.659a.587.587,0,0,0-.2.8l3.361,5.659a.579.579,0,0,0,.793.2l.007,0,2.8-1.68a11.03,11.03,0,0,0,3.236,1.819l.039,3.374a.586.586,0,0,0,.593.57l6.545-.074a.584.584,0,0,0,.57-.593l-.038-3.374a10.58,10.58,0,0,0,1.68-.814,11.49,11.49,0,0,0,1.348-.937l3.138,1.762a.587.587,0,0,0,.8-.212l3.409-6.071a.584.584,0,0,0-.21-.8h0l-3.161-1.777a10.749,10.749,0,0,0-.078-3.479l2.9-1.707a.59.59,0,0,0,.2-.8l-3.329-5.657A.588.588,0,0,0,1180.557,502.993Zm-3.251,14.084a7.594,7.594,0,1,1-2.867-10.35l.011.007A7.6,7.6,0,0,1,1177.306,517.077Z"
							fill="#8892a8"
						/>
					</g>
					<g>
						<path
							d="M1189.094,493.055a.337.337,0,0,0-.334-.334l-1.929.044a6.392,6.392,0,0,0-1.088-1.784l.914-1.683a.333.333,0,0,0-.131-.45l-3.268-1.793a.333.333,0,0,0-.45.131l-.914,1.668a6.522,6.522,0,0,0-1.073-.058,4.906,4.906,0,0,0-.928.087l-1.059-1.741a.315.315,0,0,0-.428-.129l-.022.013-3.409,2.045a.331.331,0,0,0-.1.456l.005.008,1.059,1.755a6.119,6.119,0,0,0-.928,1.741l-1.871.044a.349.349,0,0,0-.319.348l.087,3.743a.336.336,0,0,0,.334.319l1.871-.044a6.01,6.01,0,0,0,1.1,1.8l-.928,1.683a.333.333,0,0,0,.131.45l3.264,1.784a.329.329,0,0,0,.448-.127l0,0,.928-1.683a5.669,5.669,0,0,0,1.987-.027l1.073,1.755a.316.316,0,0,0,.428.129l.022-.013,3.394-2.06a.315.315,0,0,0,.112-.431l-.012-.019-1.059-1.77a6.33,6.33,0,0,0,.928-1.755l1.915-.029a.329.329,0,0,0,.32-.336v-.012Zm-8.094,5.28a3.22,3.22,0,1,1,3.22-3.22h0a3.213,3.213,0,0,1-3.21,3.216H1181Z"
							fill="#8892a8"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	mapping: (
		<svg xmlns="http://www.w3.org/2000/svg" width="82.902" height="38.581" viewBox="0 0 82.902 38.581">
			<g transform="translate(-1191.679 -608.104)">
				<circle
					cx="8.364"
					cy="8.364"
					r="8.364"
					transform="translate(1257.852 629.956)"
					fill="#5583ed"
				/>
				<circle
					cx="8.364"
					cy="8.364"
					r="8.364"
					transform="translate(1191.679 629.956)"
					fill="#5583ed"
				/>
				<line
					y1="13.488"
					transform="translate(1233.247 624.833)"
					fill="none"
					stroke="#caced9"
					strokeMiterlimit="10"
					strokeWidth="2.94"
				/>
				<circle
					cx="8.364"
					cy="8.364"
					r="8.364"
					transform="translate(1224.882 608.104)"
					fill="#1b3b87"
				/>
				<line
					x2="49.679"
					transform="translate(1208.407 638.32)"
					fill="none"
					stroke="#caced9"
					strokeMiterlimit="10"
					strokeWidth="2.94"
				/>
			</g>
		</svg>
	),
	internalUsers: (
		<svg xmlns="http://www.w3.org/2000/svg" width="52.935" height="49.75" viewBox="0 0 52.935 49.75">
			<g transform="translate(-1283.592 -302.245)">
				<g transform="translate(1310.633 302.245)">
					<path
						d="M1357.37,343.818a17.7,17.7,0,0,1-3.01-1.634c-.455-.307,4.1-8.177,10.053-8.177,5.877,0,10.313,7.8,9.76,8.177a17.747,17.747,0,0,1-16.8,1.633Z"
						transform="translate(-1354.329 -321.866)"
						fill="#9cb7f7"
					/>
					<ellipse
						cx="5.865"
						cy="5.628"
						rx="5.865"
						ry="5.628"
						transform="translate(4.08)"
						fill="#9cb7f7"
					/>
				</g>
				<g transform="translate(1318.575 327.628)">
					<g transform="translate(0 8.253)">
						<path
							d="M1391.717,391.734H1378.3a1.694,1.694,0,0,0-1.693,1.693v11.579a1.694,1.694,0,0,0,1.693,1.694h13.418a1.692,1.692,0,0,0,1.693-1.694V393.427A1.692,1.692,0,0,0,1391.717,391.734Zm1.12,13.273a1.119,1.119,0,0,1-1.12,1.12H1378.3a1.122,1.122,0,0,1-1.12-1.12V393.427a1.122,1.122,0,0,1,1.12-1.12h13.418a1.119,1.119,0,0,1,1.12,1.12Z"
							transform="translate(-1376.032 -391.161)"
							fill="#caced9"
						/>
						<path
							d="M1390.79,390.234h-13.418a2.269,2.269,0,0,0-2.267,2.267V404.08a2.269,2.269,0,0,0,2.267,2.267h13.418a2.269,2.269,0,0,0,2.267-2.267V392.5A2.269,2.269,0,0,0,1390.79,390.234Zm1.12,13.846a1.119,1.119,0,0,1-1.12,1.12h-13.418a1.122,1.122,0,0,1-1.12-1.12V392.5a1.122,1.122,0,0,1,1.12-1.12h13.418a1.119,1.119,0,0,1,1.12,1.12Z"
							transform="translate(-1375.105 -390.234)"
							fill="#f5ca64"
						/>
						<path
							d="M1393.763,394.354v11.579a1.119,1.119,0,0,1-1.12,1.12h-13.418a1.122,1.122,0,0,1-1.12-1.12V394.354a1.122,1.122,0,0,1,1.12-1.12h13.418A1.119,1.119,0,0,1,1393.763,394.354Z"
							transform="translate(-1376.958 -392.087)"
							fill="#f5ca64"
						/>
					</g>
					<circle cx="1.934" cy="1.934" r="1.934" transform="translate(7.042 14.375)" fill="#fff" />
					<path
						d="M1388.244,368.644a6.4,6.4,0,0,0-6.4,6.4v2.3c0,.042,0,.08,0,.122a4.786,4.786,0,0,0,.034.573h2.317a3.647,3.647,0,0,1-.057-.573c0-.042,0-.08,0-.122v-2.3a4.108,4.108,0,1,1,8.215,0v2.3c0,.042,0,.08,0,.122a3.562,3.562,0,0,1-.057.573h2.317a4.712,4.712,0,0,0,.034-.573c0-.042,0-.08,0-.122v-2.3A6.4,6.4,0,0,0,1388.244,368.644Z"
						transform="translate(-1379.268 -368.644)"
						fill="#f5ca64"
					/>
				</g>
				<g transform="translate(1283.592 307.973)">
					<path
						d="M1289.463,388.162a24.029,24.029,0,0,1-4.08-2.215q-.924-.624-1.791-1.335a15.451,15.451,0,0,1,30.808-.307,24.049,24.049,0,0,1-24.937,3.856Z"
						transform="translate(-1283.592 -349.95)"
						fill="#5583ed"
					/>
					<circle cx="9.132" cy="9.132" r="9.132" transform="translate(6.287 0)" fill="#5583ed" />
				</g>
			</g>
		</svg>
	),
	externalUsers: (
		<svg xmlns="http://www.w3.org/2000/svg" width="65.873" height="50.435" viewBox="0 0 65.873 50.435">
			<g transform="translate(-1169.353 -733.554)">
				<g transform="translate(1169.727 733.888)">
					<path
						d="M1277.991,807.636"
						transform="translate(-1238.567 -780.781)"
						fill="none"
						stroke="#f8dea1"
						strokeMiterlimit="10"
						strokeWidth="0.617"
					/>
					<path
						d="M1211.972,746.715q-.2.463-.423.916a5.2,5.2,0,0,1-2.7,2.819,5.837,5.837,0,0,1-2.294.228,17.5,17.5,0,0,0-2.411-.195,3.179,3.179,0,0,0-2.641,2.44,12.521,12.521,0,0,0-.5,2.164,13.711,13.711,0,0,0-.251,2.548,1.779,1.779,0,0,0,.3,1.016,2.035,2.035,0,0,0,.817.61,7.962,7.962,0,0,0,2.137.595,30.754,30.754,0,0,1,4.4.614l.031.008a.566.566,0,0,1,.294.877,19.864,19.864,0,0,1-1.345,1.519,15.123,15.123,0,0,0-1.882,2.485,32.654,32.654,0,0,0-1.509,2.919,3.073,3.073,0,0,0-.462,1.468,5.789,5.789,0,0,0,.5,1.573c.22.572.437,1.146.657,1.718a4.073,4.073,0,0,0,.723,1.364,2.213,2.213,0,0,0,2.179.7,5.4,5.4,0,0,0,2.079-1.217,23.436,23.436,0,0,1-39.267-22.347,22.818,22.818,0,0,1,2.086-5.489,8.807,8.807,0,0,0,.514,4.527,1.309,1.309,0,0,0,.336.646,1.376,1.376,0,0,0,1.115.141,17.827,17.827,0,0,1,3.012-.193c.557.011,1.281.2,1.306.761a1.421,1.421,0,0,1-.38.811,5.782,5.782,0,0,0-.715,3.607c.245,5.377,2.1,10.534,3.928,15.6a1.847,1.847,0,0,0,.365.684,1.887,1.887,0,0,0,1.786.208,1.428,1.428,0,0,0,.927-.473,1.5,1.5,0,0,0,.18-.744,26.214,26.214,0,0,1,1.521-6.233,4.98,4.98,0,0,1,.69-1.494,6.81,6.81,0,0,1,1.171-1.049,18.948,18.948,0,0,0,4.036-5.24c.388-.649.757-1.538.242-2.092a2.977,2.977,0,0,0-.694-.443,10.8,10.8,0,0,1-1.569-1.538,6.064,6.064,0,0,0-2.556-1.26,15.83,15.83,0,0,0-3.671-.56,3.4,3.4,0,0,1-1.524-.265,4.6,4.6,0,0,1-1.312-1.584,11.277,11.277,0,0,1-.939-1.293c-.7-1.368-.056-3.014.591-4.411a2.776,2.776,0,0,1,.452-.751,2.827,2.827,0,0,1,.924-.562q1.113-.5,2.191-1.086a3.079,3.079,0,0,0,1.122-.875,3.953,3.953,0,0,0,.433-1.9,8.143,8.143,0,0,1,1.776-4.494,23.6,23.6,0,0,1,16.089,1.986l-.193.489a6.024,6.024,0,0,0,.045,4.058,21.366,21.366,0,0,0,.912,2.33,3.24,3.24,0,0,0,1.345,1.573c1.4.788,3.008.473,4.523.409a4.381,4.381,0,0,1,1.128.043c.222.052.531.145.634.373C1212.368,746.024,1212.08,746.466,1211.972,746.715Z"
						transform="translate(-1169.727 -733.888)"
						fill="#a0def2"
						stroke="#a0def2"
						strokeMiterlimit="10"
						strokeWidth="0.617"
					/>
					<path
						d="M1262.852,765.9a.579.579,0,0,0-.3-.9l-.008,0a30.762,30.762,0,0,0-4.4-.614,7.967,7.967,0,0,1-2.137-.595,2.03,2.03,0,0,1-.816-.61,1.782,1.782,0,0,1-.3-1.016,13.711,13.711,0,0,1,.251-2.548,12.5,12.5,0,0,1,.5-2.164,3.179,3.179,0,0,1,2.641-2.44,17.5,17.5,0,0,1,2.411.195,5.839,5.839,0,0,0,2.295-.228,5.2,5.2,0,0,0,2.7-2.819q.224-.454.423-.916c.108-.249.4-.69.273-.964-.1-.228-.413-.321-.634-.373a4.376,4.376,0,0,0-1.127-.043c-1.515.064-3.128.379-4.523-.409a3.24,3.24,0,0,1-1.345-1.573,21.416,21.416,0,0,1-.912-2.33,6.027,6.027,0,0,1-.045-4.058l.191-.482a3.029,3.029,0,0,0,.568.333c.28.149.58.3.829.441.035.021.068.039.1.06a.86.86,0,0,1,.081.05,25.54,25.54,0,0,1,4.62,3.735c.091.1.182.191.272.288.01.011.021.021.029.031.07.077.141.153.209.23a.225.225,0,0,1,.022.025c.092.1.18.2.27.3s.153.178.228.267q.678.808,1.285,1.673l.2.292q.308.457.6.929c.166.274.327.551.483.833l.13.236c.062.116.125.234.185.353.05.1.1.195.149.294a.1.1,0,0,0,.01.021,23.917,23.917,0,0,1,2.434,9.742c0,.116.006.234.008.35a.178.178,0,0,1,0,.046c0,.1,0,.193,0,.29v.021c0,.1,0,.207,0,.309s0,.22-.006.33c0,.172-.01.342-.019.512a.819.819,0,0,1-.006.083c0,.128-.012.257-.023.383-.006.1-.015.2-.023.3-.015.18-.035.361-.054.541-.029.259-.064.52-.1.784-.019.133-.04.263-.062.394-.015.1-.031.195-.05.29-.05.3-.106.591-.164.877-.025.129-.049.253-.076.377s-.052.249-.079.369c-.064.276-.137.55-.211.821a.416.416,0,0,0-.017.052c-.249.812-.582,1.625-.879,2.421a22.436,22.436,0,0,1-1.215,2.589.022.022,0,0,1-.006.015c-.294.526-.611,1.04-.947,1.542-.3.431-.626.85-.941,1.241-.392.491-.765.987-1.163,1.453-.01.013-.023.025-.035.04q-.376.414-.775.813l-.019.019c-.4.369-.807.8-1.275,1.167a1.021,1.021,0,0,1-.7.264,3.261,3.261,0,0,1-2.277-.915,4.079,4.079,0,0,1-.724-1.364c-.219-.572-.437-1.146-.657-1.718a5.791,5.791,0,0,1-.5-1.573,3.072,3.072,0,0,1,.463-1.468,32.591,32.591,0,0,1,1.509-2.919,15.113,15.113,0,0,1,1.882-2.485A19.948,19.948,0,0,0,1262.852,765.9Z"
						transform="translate(-1223.87 -738.418)"
						fill="#54b997"
						stroke="#54b997"
						strokeMiterlimit="10"
						strokeWidth="0.617"
					/>
					<path
						d="M1196.9,758.653a18.952,18.952,0,0,1-4.036,5.24,6.813,6.813,0,0,0-1.172,1.049,4.982,4.982,0,0,0-.69,1.495,26.219,26.219,0,0,0-1.521,6.233,1.5,1.5,0,0,1-.18.744,1.428,1.428,0,0,1-.927.473,1.887,1.887,0,0,1-1.786-.208,1.842,1.842,0,0,1-.365-.684c-1.832-5.064-3.683-10.221-3.928-15.6a5.782,5.782,0,0,1,.715-3.607,1.422,1.422,0,0,0,.379-.811c-.025-.557-.748-.75-1.306-.761a17.839,17.839,0,0,0-3.012.193,1.376,1.376,0,0,1-1.115-.141,1.307,1.307,0,0,1-.336-.646,8.808,8.808,0,0,1-.514-4.527,22.866,22.866,0,0,1,14.487-11.373c.257-.066.516-.129.773-.185a8.144,8.144,0,0,0-1.776,4.494,3.953,3.953,0,0,1-.433,1.9,3.079,3.079,0,0,1-1.122.875q-1.076.581-2.191,1.086a2.824,2.824,0,0,0-.924.562,2.768,2.768,0,0,0-.452.75c-.647,1.4-1.3,3.043-.591,4.411a11.326,11.326,0,0,0,.939,1.294,4.6,4.6,0,0,0,1.312,1.584,3.4,3.4,0,0,0,1.524.265,15.826,15.826,0,0,1,3.671.56,6.063,6.063,0,0,1,2.556,1.26,10.806,10.806,0,0,0,1.569,1.538,2.982,2.982,0,0,1,.694.443C1197.661,757.115,1197.292,758,1196.9,758.653Z"
						transform="translate(-1174.336 -734.938)"
						fill="#54b997"
						stroke="#54b997"
						strokeMiterlimit="10"
						strokeWidth="0.617"
					/>
				</g>
				<g transform="translate(1199.463 739.061)">
					<path
						d="M1258.2,824.765a27.179,27.179,0,0,1-4.737-2.83q-1.073-.8-2.079-1.706c.839-10.329,8.532-18.419,17.9-18.419,9.249,0,16.866,7.886,17.864,18.027a28.386,28.386,0,0,1-2.5,2.1,27.191,27.191,0,0,1-4.736,2.83,25.626,25.626,0,0,1-21.708,0Z"
						transform="translate(-1251.386 -782.25)"
						fill="#fff"
					/>
					<circle cx="11.94" cy="11.94" r="11.94" transform="translate(6.567)" fill="#fff" />
				</g>
				<g transform="translate(1202.478 742.563)">
					<path
						d="M1265.257,827.79a22.88,22.88,0,0,1-3.887-2.109q-.881-.594-1.706-1.272a14.718,14.718,0,0,1,29.347-.292,22.906,22.906,0,0,1-23.755,3.673Z"
						transform="translate(-1259.665 -791.39)"
						fill="#1f49ab"
					/>
					<circle cx="8.699" cy="8.699" r="8.699" transform="translate(5.989)" fill="#1f49ab" />
				</g>
			</g>
		</svg>
	),
	bothUsers: (
		<svg xmlns="http://www.w3.org/2000/svg" width="72.097" height="45.828" viewBox="0 0 72.097 45.828">
			<g transform="translate(-764 -642)">
				<g transform="translate(791.041 642)">
					<path
						d="M1357.37,343.818a17.7,17.7,0,0,1-3.01-1.634c-.455-.307,4.1-8.177,10.053-8.177,5.877,0,10.313,7.8,9.76,8.177a17.747,17.747,0,0,1-16.8,1.633Z"
						transform="translate(-1354.329 -321.866)"
						fill="#9cb7f7"
					/>
					<ellipse
						cx="5.865"
						cy="5.628"
						rx="5.865"
						ry="5.628"
						transform="translate(4.08)"
						fill="#9cb7f7"
					/>
				</g>
				<g transform="translate(764 647.729)">
					<path
						d="M1289.463,388.162a24.029,24.029,0,0,1-4.08-2.215q-.924-.624-1.791-1.335a15.451,15.451,0,0,1,30.808-.307,24.049,24.049,0,0,1-24.937,3.856Z"
						transform="translate(-1283.592 -349.95)"
						fill="#5583ed"
					/>
					<circle cx="9.132" cy="9.132" r="9.132" transform="translate(6.287 0)" fill="#5583ed" />
				</g>
				<g transform="translate(806.75 649.158)">
					<path
						d="M1265.257,827.79a22.88,22.88,0,0,1-3.887-2.109q-.881-.594-1.706-1.272a14.718,14.718,0,0,1,29.347-.292,22.906,22.906,0,0,1-23.755,3.673Z"
						transform="translate(-1259.665 -791.39)"
						fill="#1f49ab"
					/>
					<circle cx="8.699" cy="8.699" r="8.699" transform="translate(5.989)" fill="#1f49ab" />
				</g>
			</g>
		</svg>
	),
};
