import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import SignupView from "./SignupView";
import { get, post } from "../../utils/api";
import { INITIAL_RENDER_ONLY } from "../../utils/constants";
import { DropdownItem, ErrorBag } from "design-system";
import { AxiosResponse } from "axios";

interface Props extends RouteComponentProps {}

function isInValidEmail(email: string): boolean {
	const validate = require("validate.js");

	return validate({ email }, { email: { email: true } });
}

export const SERVER_REGIONS = {
	au: { name: "Australia", iso: "au" },
	us: { name: "The United States", iso: "us" },
	eu: { name: "European Union", iso: "eu" },
};

const SignupContainer: React.FunctionComponent<Props> = () => {
	const [email, setEmail] = useState("");
	const [region, setRegion] = useState<DropdownItem | null>(null);
	const [errors, setErrors] = useState<Array<ErrorBag>>([]);
	const [processing, setProcessing] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [step, setStep] = useState(1);

	const emailInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
	const validationErrorPanelRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
	const loadingScreenRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

	useEffect(() => {
		if (!emailInputRef.current) return;

		emailInputRef.current.focus();
	});

	useEffect(() => {
		// get("geoip-lookup").then((response) => {
		// 	const regionId = (response as AxiosResponse).data.region;
		// 	// @ts-ignore
		// 	const region = SERVER_REGIONS[regionId];
		//
		// 	setRegion({
		// 		label: (
		// 			<React.Fragment>
		// 				<span className={`flag-icon flag-icon-${region.iso}`}></span>&nbsp;&nbsp;{region.name}
		// 			</React.Fragment>
		// 		),
		// 		key: region.iso,
		// 	});
		// });
	}, INITIAL_RENDER_ONLY);

	function getStarted() {
		setAttempts(attempts + 1);

		if (isInValidEmail(email)) {
			setErrors([
				{
					key: "email",
					type: "validation",
					message: "Please enter a valid email address to continue.",
				},
			]);

			return;
		}

		setProcessing(true);
		setErrors([]);

		post("/account/new", { email, region: region === null ? "us" : region.key }).then((response) => {
			if ((response as AxiosResponse).data.status !== "ok") {
				// set error
				setProcessing(false);
				setErrors([
					{
						key: "server",
						type: "server",
						message: "Something went wrong. Please try again or reach out to us.",
					},
				]);

				return;
			}

			if (!loadingScreenRef.current) return;

			loadingScreenRef.current.style.opacity = "95%";
			setProcessing(false);
			loadingScreenRef.current.style.opacity = "0";
			setStep(2);
		});
	}

	return (
		<SignupView
			email={{
				value: email,
				set: setEmail,
				ref: emailInputRef,
			}}
			region={{
				value: region,
				set: setRegion,
			}}
			step={step}
			errors={{
				bag: errors,
				ref: validationErrorPanelRef,
			}}
			processing={processing}
			loadingScreenRef={loadingScreenRef}
			getStarted={getStarted}
		/>
	);
};

export default SignupContainer;
