import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { INITIAL_RENDER_ONLY, wait } from "../../../../../utils/constants";
import classNames from "classnames";
import { Button, Card, Dropdown, DropdownItem, ErrorBag, ShortText, Space, Spinner } from "design-system";
import ProfileImageEdit from "../../../../Base/ProfileImage/ProfileImageEdit";
import { determineURL, post, upload } from "../../../../../utils/api";
import axios from "axios";
import { ServerMap } from "../../../../../utils/serverMap";
import Adiv from "../../../../Animatables/Adiv";
import { forgetSavedApiResponse, sendCapturedRequestToAPI } from "../../../../../sharedActions";
import { UserAPI } from "../../../../../api/UserAPI";
import { CAPTURED_ACTIVATE_USER } from "../../../../../capturedResponseTypes";
import { useListenForApiResponse } from "../../../../../utils/hooks/useListenForApiResponse";
import { useDispatch } from "react-redux";
import Errors from "../../../../Base/Form/Errors/Errors";
import UserNotFound from "./UserNotFound";
import { Logo30 } from "../../../../Base/Logo/Logos";
import styles from "./Join.module.scss";

const timezones = require("../../../../../data/timezones.json");

interface Props extends RouteComponentProps {}

export interface PendingUser {
	key: string | null;
	imageUrl: null | string;
	firstName: string;
	lastName: string;
	password: string;
	timezone: string;
}

const UserInviteJoin: React.FunctionComponent<Props> = () => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [user, setUser] = useState<PendingUser>({
		key: null,
		imageUrl: "",
		firstName: "",
		lastName: "",
		password: "",
		timezone: "",
	});
	const [processing, setProcessing] = useState(false);
	const [amUploadingImage, setAmUploadingImage] = useState(false);
	const [token, setToken] = useState("");
	const [zone, setZone] = useState("");
	const [timezone, setTimezone] = useState<DropdownItem | null>(null);
	const [profileErrors, setProfileErrors] = useState<Array<ErrorBag>>([]);
	const [uploadErrors, setUploadErrors] = useState<Array<ErrorBag>>([]);

	useEffect(() => {
		const params = new URL(window.location.href).searchParams;

		async function getUser() {
			const token = params.get("token");

			if (!token) {
				setLoading(false);
				return;
			}

			const serverCode = token.slice(-3);
			const zone = ServerMap.find(serverCode);
			setZone(zone);
			setToken(token);

			const url = determineURL({ zone });

			await axios({
				method: "get",
				url: `${url}/pending-user?token=${token}`,
				withCredentials: true,
			})
				.then((response) => {
					if (response.data.data.user) {
						setUser(response.data.data.user);
					}

					setLoading(false);
				})
				.catch((error) => {
					if (error.response.status === 404) {
						setLoading(false);
						return;
					}
				});
		}

		getUser();
	}, INITIAL_RENDER_ONLY);

	useListenForApiResponse(CAPTURED_ACTIVATE_USER, (response) => {
		dispatch(forgetSavedApiResponse(CAPTURED_ACTIVATE_USER));
		setProcessing(false);

		if (response.status === "ok") {
			// todo eventually replace with /welcome for onboarding
			window.localStorage.setItem("account_zone", response.data.zone);
			window.location.replace("/jobs");

			return;
		}

		if (response.errors.length > 0) {
			setProfileErrors(response.errors);

			return;
		}
	});

	function join() {
		if (amUploadingImage) {
			return;
		}

		setProcessing(true);

		dispatch(
			sendCapturedRequestToAPI(
				UserAPI.activateUser({
					...user,
					timezone: timezone !== null ? timezone.key : null,
					token,
				}),
				CAPTURED_ACTIVATE_USER
			)
		);
	}

	async function uploadImage(data: FormData) {
		setAmUploadingImage(true);
		setUploadErrors([]);

		const response = await upload(`/pending-user/profile-image/upload?token=${token}`, data, {
			zone,
		});
		await wait(500);

		if (response.status === "error") {
			setAmUploadingImage(false);
			setUploadErrors(response.errors);

			return "error";
		}

		let updatedUser: PendingUser = {
			...user,
		};

		updatedUser.imageUrl = response.data.url;
		setUser(updatedUser);
		setAmUploadingImage(false);
	}

	function removeImage() {
		post("/pending-user/profile-image/reset", { token }, { zone });

		let updatedUser: PendingUser = { ...user };

		updatedUser.imageUrl = null;
		setUser(updatedUser);
	}

	function updateUser(property: string, value: any) {
		let updatedUser = { ...user };

		// @ts-ignore
		updatedUser[property] = value;

		// @ts-ignore
		setUser(updatedUser);
	}

	if (loading && !user.key) {
		return (
			<div>
				<Spinner size="fullscreen" />
			</div>
		);
	}

	if (!user.key) {
		return <UserNotFound />;
	}

	const classes = classNames([styles.UserInviteJoin]);

	return (
		<Adiv className={classes}>
			<div style={{ maxWidth: "175px", margin: "0 auto 24px auto" }}>
				<a href="https://prestavi.com" target="_blank">
					{Logo30}
				</a>
			</div>
			<Card maxWidth={500}>
				<div className={styles.SectionHeading}>
					<h2>Welcome to Prestavi</h2>
					<p>Complete the form below and you’ll be ready to join.</p>
				</div>
				<Space amount={16} />

				<ProfileImageEdit
					imageUrl={user.imageUrl}
					uploadImage={uploadImage}
					removeImage={removeImage}
				/>

				<Space amount={32} />

				<Errors bag={uploadErrors} />

				<ShortText
					label="First Name"
					id="firstName"
					theme="inside"
					fullWidth={true}
					value={user.firstName}
					onChange={(event) => updateUser("firstName", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={12} />

				<ShortText
					label="Last Name"
					id="lastName"
					theme="inside"
					fullWidth={true}
					value={user.lastName}
					onChange={(event) => updateUser("lastName", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={12} />

				<ShortText
					label="Password"
					id="password"
					theme="inside"
					type="password"
					fullWidth={true}
					value={user.password}
					onChange={(event) => updateUser("password", (event.target as HTMLInputElement).value)}
				/>

				<Space amount={12} />

				<Dropdown
					options={timezones}
					onChange={(timezone: DropdownItem) => setTimezone(timezone)}
					value={timezone}
					label="Timezone"
					searchable={true}
					theme="inside"
					fullWidth={true}
				/>

				<Space amount={24} />

				<Errors bag={profileErrors} />

				<Button theme="solid" locked={amUploadingImage} tone="light" color="green" onClick={join}>
					{processing ? <Spinner color="white" /> : "Join"}
				</Button>
			</Card>
		</Adiv>
	);
};

export default UserInviteJoin;
