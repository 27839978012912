import React from "react";
import { LogItem, Workflow } from "design-system";
import { useStore } from "react-redux";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const JobStarted: React.FunctionComponent<Props> = ({ logItem }) => {
	const store = useStore();

	const workflow = store.getState().workflows[logItem.workflowKey] as Workflow;

	return (
		<React.Fragment>
			<div className={styles.Indicator}></div>
			<div className={styles.Event}>
				<span>Job Started</span>
				<p>{workflow ? workflow.name : "Unknown Workflow"}</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default JobStarted;
