import {
	SET_ACCESS_TOKENS,
	SET_EXTERNAL_ENDPOINT,
	SET_EXTERNAL_ENDPOINT_PARTS,
	SET_EXTERNAL_ENDPOINTS,
	SET_WEBHOOKS,
} from "../actionTypes";
import { AccessToken, Webhook } from "design-system/src/types/developers";
import {
	activateAllResponseVariables,
	updateExternalEndpointResponseVariable,
} from "../components/Developers/developerActions";

export class DeveloperAPI {
	static fetchAccessTokens() {
		return {
			url: "access-tokens",
			respondWith: SET_ACCESS_TOKENS,
		};
	}

	static createAccessToken(token: AccessToken) {
		return {
			url: "access-tokens",
			data: {
				token,
			},
		};
	}

	static deleteAccessToken(key: string) {
		return {
			url: `access-tokens/${key}`,
			method: "delete",
			data: {},
		};
	}

	static saveWebhook(webhook: Webhook) {
		return {
			url: "webhooks",
			data: {
				...webhook,
			},
		};
	}

	static loadWebhooks() {
		return {
			url: `webhooks`,
			respondWith: SET_WEBHOOKS,
		};
	}

	static deleteWebhook(webhookKey: string) {
		return {
			url: `webhooks/${webhookKey}`,
			method: "delete",
			data: {},
		};
	}

	static sendWebhookTest(webhookKey: string) {
		return {
			url: `webhooks/${webhookKey}/test`,
			data: {},
			respondWith: "",
		};
	}

	static loadExternalEndpoints() {
		return {
			url: `external-endpoints`,
			respondWith: SET_EXTERNAL_ENDPOINTS,
		};
	}

	static loadExternalEndpoint(externalEndpointKey: string) {
		return {
			url: `external-endpoints/${externalEndpointKey}`,
			respondWith: SET_EXTERNAL_ENDPOINT,
		};
	}

	static loadExternalEndpointParts(endpointKey: string) {
		return {
			url: `external-endpoints/${endpointKey}/parts`,
			respondWith: SET_EXTERNAL_ENDPOINT_PARTS,
		};
	}

	static saveExternalEndpointProperty(endpointKey: string, property: string, change: any) {
		return {
			url: `external-endpoints/${endpointKey}`,
			data: {
				property,
				change,
			},
		};
	}

	static saveExternalEndpointSettings(endpointKey: string, settingName: string, change: any) {
		return {
			url: `external-endpoints/${endpointKey}/settings/${settingName}`,
			data: {
				change,
			},
		};
	}

	static deleteExternalEndpointSettings(endpointKey: string, settingName: string) {
		return {
			url: `external-endpoints/${endpointKey}/settings/${settingName}`,
			method: "delete",
			data: {},
		};
	}

	static captureExternalEndpointResponse(endpointKey: string) {
		return {
			url: `external-endpoints/${endpointKey}/capture`,
			data: {},
		};
	}

	static deleteExternalEndpointResponse(endpointKey: string, responseKey: string) {
		return {
			url: `external-endpoints/${endpointKey}/responses/${responseKey}`,
			method: "delete",
			data: {},
		};
	}

	static deleteExternalEndpoint(endpointKey: string) {
		return {
			url: `external-endpoints/${endpointKey}`,
			method: "delete",
			data: {},
		};
	}

	static sendExternalEndpointTest(endpointKey: string) {
		return {
			url: `external-endpoints/${endpointKey}/test`,
			data: {},
			respondWith: "",
		};
	}

	static updateExternalEndpointResponseVariable(
		endpointKey: string,
		variableKey: string,
		variableName: string,
		value: any
	) {
		return {
			url: `external-endpoints/${endpointKey}/variables/${variableKey}`,
			data: {
				variableName,
				value,
			},
		};
	}

	static activateAllResponseVariables(endpointKey: string) {
		return {
			url: `external-endpoints/${endpointKey}/activate-all-variables`,
			data: {},
		};
	}
}
