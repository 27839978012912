import React from "react";
import { JobStep } from "design-system";
import { convertToMoment } from "../../../../../../../utils/helpers";

import styles from "./Step.module.scss";

interface Props {
	jobStep: JobStep;
}

const CompletedDate: React.FunctionComponent<Props> = ({ jobStep }) => {
	const completedAt = convertToMoment(jobStep.completedAt as string);

	return (
		<div className={styles.CompletedDate}>
			<p className={styles.Title}>Completed On</p>
			{completedAt && completedAt.format("MMM Do")}
			&nbsp;at&nbsp;
			{completedAt && completedAt.format("h:mm A")}
		</div>
	);
};

export default CompletedDate;
