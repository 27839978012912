import React, { MutableRefObject } from "react";
import { useNavigate } from "@reach/router";
import { sendRequestToAPI } from "../../../sharedActions";
import { AccountAPI } from "../../../api/AccountAPI";
import { useDispatch } from "react-redux";
import { Logo30 } from "../Logo/Logos";

import styles from "./MobileMenu.module.scss";

interface Props {
	forwardRef: MutableRefObject<HTMLDivElement | null>;
	onHide: () => void;
}

const MobileMenu: React.FunctionComponent<Props> = ({ forwardRef, onHide }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	function logout() {
		dispatch(sendRequestToAPI(AccountAPI.logout()));
	}

	async function goTo(page: string) {
		await navigate(page);

		onHide();
	}
	return (
		<div className={styles.MobileMenu} ref={forwardRef} style={{ height: `${window.innerHeight}px` }}>
			<div className={styles.Main}>
				<div className={styles.Head}>
					<div className={styles.Logo}>{Logo30}</div>
					<svg
						className={styles.CloseBtn}
						onClick={onHide}
						xmlns="http://www.w3.org/2000/svg"
						width="40"
						height="40"
						viewBox="0 0 40 40"
					>
						<g transform="translate(-676 -93)">
							<circle cx="20" cy="20" r="20" transform="translate(676 93)" fill="#e9ebf2" />
							<g transform="translate(685.987 102.333)">
								<path
									d="M665.155,748.385a1.64,1.64,0,0,1-1.107.459,1.564,1.564,0,0,1-1.107-.459l-7.454-7.475-7.538,7.538a1.482,1.482,0,0,1-1.107.459,1.549,1.549,0,0,1-1.107-.459,1.576,1.576,0,0,1,0-2.213l7.538-7.538-7.266-7.266a1.538,1.538,0,0,1,0-2.213,1.575,1.575,0,0,1,2.213,0l7.266,7.266,7.183-7.183a1.575,1.575,0,0,1,2.213,0,1.539,1.539,0,0,1,0,2.213L657.7,738.7l7.454,7.454A1.6,1.6,0,0,1,665.155,748.385Z"
									transform="translate(-645.283 -728.763)"
									fill="#a2a9ba"
								/>
							</g>
						</g>
					</svg>
				</div>

				<div className={styles.Options}>
					<div className={styles.Option} onClick={() => goTo("/jobs")}>
						Jobs
					</div>
					<div className={styles.Option}>
						<div onClick={() => goTo("/workflows")}>Manage</div>
						<div className={styles.Options}>
							<div className={styles.Option} onClick={() => goTo("/workflows")}>
								Workflows
							</div>

							<div className={styles.Option}>
								<div onClick={() => goTo("/manage/users")}>People</div>
								<div className={styles.Options}>
									<div className={styles.Option} onClick={() => goTo("/manage/users")}>
										Users
									</div>
									<div className={styles.Option} onClick={() => goTo("/manage/roles")}>
										Roles
									</div>
								</div>
							</div>

							<div className={styles.Option}>
								<div onClick={() => goTo("/manage/developers")}>Developers</div>
								<div className={styles.Options}>
									<div className={styles.Option} onClick={() => goTo("/manage/developers")}>
										API Keys
									</div>
									<div
										className={styles.Option}
										onClick={() => goTo("/manage/developers/webhooks")}
									>
										Webhooks
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.Footer}>
				<div className={styles.Options}>
					<div className={styles.Option} onClick={() => goTo("/profile")}>
						Profile
					</div>
					<div className={styles.Option} onClick={() => goTo("/billing")}>
						Billing
					</div>
				</div>
				<div>
					<div className={styles.Option}>
						<div onClick={logout} className={styles.Logout}>
							Logout
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="19.898"
								height="14.463"
								viewBox="0 0 19.898 14.463"
								style={{ marginLeft: "8px" }}
							>
								<g transform="translate(744.041 479.53) rotate(180)">
									<g transform="translate(724.144 471.294)">
										<path
											d="M743.049,476.868H725.136a.992.992,0,0,1,0-1.984h17.913a.992.992,0,0,1,0,1.984Z"
											transform="translate(-724.144 -474.884)"
											fill="#bfd0f7"
										/>
									</g>
									<g transform="translate(724.143 471.294)">
										<path
											d="M732.586,483.12a.985.985,0,0,1-.637-.232l-7.451-6.252a.992.992,0,1,1,1.275-1.52l7.451,6.253a.992.992,0,0,1-.638,1.752Z"
											transform="translate(-724.144 -474.884)"
											fill="#5583ed"
										/>
									</g>
									<g transform="translate(724.143 465.067)">
										<path
											d="M725.136,473.278a.992.992,0,0,1-.637-1.754l7.451-6.226a.992.992,0,1,1,1.272,1.523l-7.45,6.226A.988.988,0,0,1,725.136,473.278Z"
											transform="translate(-724.143 -465.067)"
											fill="#5583ed"
										/>
									</g>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;
