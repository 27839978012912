import React from "react";
import { LogItem } from "design-system";
import classNames from "classnames";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const WorkflowCompleted: React.FunctionComponent<Props> = ({ logItem }) => {
	const indicatorClasses = classNames([styles.Indicator, styles.Green]);
	return (
		<React.Fragment>
			<div className={indicatorClasses}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="26.432"
					height="26.432"
					viewBox="0 0 26.432 26.432"
				>
					<circle cx="13.216" cy="13.216" r="13.216" fill="#b9e5d6" />
					<path
						d="M934.995,507.009l4.882,4.014,9.187-9.913"
						transform="translate(-928.813 -492.85)"
						fill="none"
						stroke="#2a9672"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2.422"
					/>
				</svg>
			</div>
			<div className={styles.Event}>
				<span></span>
				<p>Workflow Completed</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default WorkflowCompleted;
