import React, { useEffect, useState } from "react";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Card, Dropdown, DropdownItem, LogItem, Spinner } from "design-system";
import { InitialState } from "../../../../initialState";
import { fetchFromApi } from "../../../../sharedActions";
import { JobAPI } from "../../../../api/JobAPI";
import { INITIAL_RENDER_ONLY } from "../../../../utils/constants";
import Header from "./Header";
import { jobIsNotLoaded } from "../../../../utils/helpers";
import LogEventsTable from "./LogEventsTable";
import { usePrevious } from "../../../../utils/hooks/usePrevious";

import styles from "./JobLogs.module.scss";

interface Props extends RouteComponentProps {}

const LogViewOption: DropdownItem = { label: "Logs", key: "log" };

const JobLogs: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const store = useStore();

	const [selectedView, setSelectedView] = useState<DropdownItem>(LogViewOption);
	const [selectedWorkflow, setSelectedWorkflow] = useState<DropdownItem | null>(null);

	const workflows = useSelector((state: InitialState) => state.workflows);
	const jobs = useSelector((state: InitialState) => state.jobs);
	const jobLogs = useSelector((state: InitialState) => state.jobLogs[params.jobKey]);
	const prevJobLogs = usePrevious(jobLogs);
	const job = jobs[params.jobKey];

	function updateView(view: DropdownItem) {
		if (view.key === "overview") {
			navigate(`/jobs/${params.jobKey}`);

			return;
		}

		setSelectedView(view);
	}

	function updateWorkflow(workflow: DropdownItem) {
		setSelectedWorkflow(workflow);
	}

	useEffect(() => {
		if (jobIsNotLoaded(params.jobKey)) {
			dispatch(fetchFromApi(JobAPI.loadFullJob(params.jobKey)));
		}
	}, INITIAL_RENDER_ONLY);

	useEffect(() => {
		if (prevJobLogs !== undefined || jobLogs === undefined) {
			return;
		}

		const workflowKey = jobLogs[0].workflowKey;

		setSelectedWorkflow({
			key: workflowKey,
			label: store.getState().workflows[workflowKey].name,
		});
	}, [jobLogs]);

	if (!job || !job.loaded || !selectedWorkflow) {
		return (
			<div>
				<Spinner size="small-medium" />
			</div>
		);
	}

	let workflowKeysOrdered: Array<string> = [];
	let eventsByWorkflow: { [workflowKey: string]: Array<LogItem> } = {};

	jobLogs.forEach((logItem: LogItem) => {
		if (logItem.workflowKey !== null && !workflows[logItem.workflowKey]) {
			return;
		}

		if (!logItem.workflowKey) {
			Object.keys(eventsByWorkflow).forEach((workflowKey) => {
				eventsByWorkflow[workflowKey].push(logItem);
			});

			return;
		}

		if (!eventsByWorkflow[logItem.workflowKey]) {
			eventsByWorkflow[logItem.workflowKey] = [];
		}
		eventsByWorkflow[logItem.workflowKey].push(logItem);

		if (!workflowKeysOrdered.includes(logItem.workflowKey)) {
			workflowKeysOrdered.push(logItem.workflowKey);
		}
	});

	return (
		<div className={styles.JobLogs}>
			<Card maxWidth={850} centered={true}>
				<Header job={job} selectedView={selectedView} updateView={updateView} />
				<div className={styles.WorkflowSelection}>
					{workflowKeysOrdered.length > 1 && (
						<Dropdown
							className={styles.WorkflowDropdown}
							options={workflowKeysOrdered.map((workflowKey: string) => ({
								key: workflowKey,
								label: store.getState().workflows[workflowKey].name,
							}))}
							onChange={updateWorkflow}
							value={selectedWorkflow}
							label=""
						/>
					)}
				</div>

				<LogEventsTable
					key={selectedWorkflow.key}
					job={job}
					workflowKey={selectedWorkflow.key as string}
					events={eventsByWorkflow[selectedWorkflow.key]}
				/>
			</Card>
		</div>
	);
};

export default JobLogs;
