import React from "react";
import { ExternalEndpointResponse, Me, Spinner, Tooltip } from "design-system";
// @ts-ignore
import moment from "moment-timezone";
import { store } from "../../../../store";
import { InitialState } from "../../../../initialState";

import styles from "./ResponseBuilder.module.scss";

interface Props {
	response: ExternalEndpointResponse;
	isLoading: boolean;
	onDelete: (responseKey: string) => void;
}

const SelectedResponseInputValue: React.FunctionComponent<Props> = ({ response, isLoading, onDelete }) => {
	if (isLoading) {
		return <Spinner />;
	}

	const userTimezone = ((store.getState() as InitialState).me as Me).timezone;
	let date = moment.tz(response.createdAt, "UTC").tz(userTimezone);

	return (
		<div className={styles.SelectedResponseInputValue}>
			<p>Response {response.version}</p>
			<span>
				Recorded on&nbsp;
				{date.format("MMMM Do, YYYY")}
				&nbsp;at&nbsp;
				{date.format("h:mm A")}
			</span>

			<Tooltip
				id={`delete-response`}
				placement="top"
				trigger={["hover"]}
				overlay={<span>Delete Response</span>}
			>
				<div
					onClick={(event) => {
						onDelete(response.key);
					}}
					onMouseUp={(event) => {
						event.preventDefault();
						event.stopPropagation();
					}}
					onMouseDown={(event) => {
						event.preventDefault();
						event.stopPropagation();
					}}
				>
					<svg
						className={styles.Trash}
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="23"
						viewBox="0 0 25 23"
					>
						<g transform="translate(-162.998 -90.839)">
							<g transform="translate(163.158 91)">
								<rect
									width="25"
									height="23"
									rx="4"
									transform="translate(-0.16 -0.161)"
									fill="#f0f3fc"
								/>
								<g transform="translate(7.144 5.33)">
									<g transform="translate(0 0)">
										<path
											d="M10.552,1.2v.6a.47.47,0,0,1-.468.468H9.651v8.744a1,1,0,0,1-1,1H1.9a1,1,0,0,1-1-1V2.271H.475A.47.47,0,0,1,0,1.8V1.2A.47.47,0,0,1,.475.733h2.7S3.341.4,3.407.264A.558.558,0,0,1,3.876,0h2.8a.56.56,0,0,1,.469.264c.066.138.234.469.234.469h2.7a.469.469,0,0,1,.468.468"
											transform="translate(0 0)"
											fill="#5583ed"
										/>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</div>
			</Tooltip>
		</div>
	);
};

export default SelectedResponseInputValue;
