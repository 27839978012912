import React from "react";
import { LogItem } from "design-system";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const JobReopened: React.FunctionComponent<Props> = ({ logItem }) => {
	return (
		<React.Fragment>
			<div className={styles.Indicator}></div>
			<div className={styles.Event}>
				<span></span>
				<p>Job Reopened</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default JobReopened;
