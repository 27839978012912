import React, { useEffect } from "react";
import { RouteComponentProps, useParams, Link } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../../initialState";
import { fetchFromApi } from "../../../../sharedActions";
import { BillingAPI } from "../../../../api/BillingAPI";
import { INITIAL_RENDER_ONLY } from "../../../../utils/constants";
import { Button, PaymentCard, Receipt as ReceiptInterface, Spinner } from "design-system";
import moment from "moment";

const countries = require("../../../../data/countries.json");
import styles from "./Receipts.module.scss";

interface Props extends RouteComponentProps {}

function parseCountryCode(countryCode: string) {
	if (!countryCode) {
		return null;
	}

	const country = countries.find((country: any) => country.key === countryCode);

	return {
		key: country.key,
		label: country.label,
	};
}

const Receipt: React.FunctionComponent<Props> = () => {
	const subscription = useSelector((state: InitialState) => state.subscription);
	const receipts = useSelector((state: InitialState) =>
		state.subscription === null ? null : state.subscription.receipts
	);
	const account = useSelector((state: InitialState) => state.account);
	const dispatch = useDispatch();
	const params = useParams();

	const receiptId = params.receiptId;

	const isNotLoaded =
		subscription === null ||
		receipts === null ||
		receipts[receiptId] === undefined ||
		receipts[receiptId].loaded === false;

	useEffect(() => {
		if (isNotLoaded) {
			dispatch(fetchFromApi(BillingAPI.loadReceipt(receiptId)));
		}
	}, INITIAL_RENDER_ONLY);

	if (isNotLoaded) {
		return (
			<div
				style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "32px" }}
			>
				<Spinner size="medium" />
			</div>
		);
	}

	// @ts-ignore
	const receipt = receipts[receiptId] as ReceiptInterface;

	return (
		<div className={styles.Container}>
			<div className={styles.Back}>
				<Link to="/billing/receipts">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="14.758"
						height="9.815"
						viewBox="0 0 14.758 9.815"
					>
						<g transform="translate(0 0)">
							<g transform="translate(0 0)">
								<path
									d="M14.023,9.063H.736a.676.676,0,1,1,0-1.347H14.023a.676.676,0,1,1,0,1.347"
									transform="translate(0 -3.489)"
									fill="#9cb7f7"
								/>
								<path
									d="M6.736,8.627a.636.636,0,0,1,.093.948.789.789,0,0,1-1.035.088L.3,5.439a.478.478,0,0,1-.126-.11.214.214,0,0,1-.044-.055.473.473,0,0,1-.055-.088.042.042,0,0,1-.011-.022.27.27,0,0,1-.033-.093.579.579,0,0,1,.049-.466.327.327,0,0,1,.049-.082c.011-.016.027-.033.038-.049a.535.535,0,0,1,.1-.093L.3,4.36,5.794.159A.78.78,0,0,1,6.83.241a.632.632,0,0,1-.093.948L1.883,4.9Z"
									transform="translate(-0.002 0)"
									fill="#5583ed"
								/>
							</g>
						</g>
					</svg>
					All Receipts
				</Link>
			</div>
			<div className={styles.Receipt}>
				<div className={styles.Header}>
					<div>
						<h1>Receipt</h1>
						<Button tone="light" theme="solid" onClick={() => window.print()}>
							Print
						</Button>
					</div>
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="140"
							height="23.838"
							viewBox="0 0 140 23.838"
						>
							<g id="Group_2875" data-name="Group 2875" transform="translate(-631.066 -65.077)">
								<g
									id="Group_2565"
									data-name="Group 2565"
									transform="translate(674.223 65.173)"
								>
									<path
										id="Path_611"
										data-name="Path 611"
										d="M663.263,65.909h5.421c5.243,0,8.669,2.709,8.669,7.287,0,4.546-3.337,7.526-8.579,7.526h-2.74V88.31h-2.77Zm11.29,7.347c0-3.041-2.175-4.727-5.631-4.727h-2.889v9.6h2.889C672.378,78.133,674.553,76.327,674.553,73.256Z"
										transform="translate(-663.263 -64.926)"
										fill="#132859"
									/>
									<path
										id="Path_612"
										data-name="Path 612"
										d="M674.994,70.613h2.175l-.016,2.531h0a5.694,5.694,0,0,1,4.96-2.71,5.753,5.753,0,0,1,1.072.089v2.621a7.071,7.071,0,0,0-1.132-.119,4.176,4.176,0,0,0-4.318,4.528v9.175h-2.741Z"
										transform="translate(-659.781 -63.344)"
										fill="#132859"
									/>
									<path
										id="Path_613"
										data-name="Path 613"
										d="M695.853,78.4c0-5.3-3.128-8.1-6.943-8.1-4.17,0-7.77,3.277-7.77,8.491,0,4.973,4.229,8.192,9.02,8.192h4.816v-2.4s-3.613.027-4.816,0c-2.892-.067-5.951-1.771-6.34-4.958h11.942A7.425,7.425,0,0,0,695.853,78.4Zm-12.033-.836a5.235,5.235,0,0,1,5.065-4.882c2.2,0,4.253,1.548,4.32,4.882Z"
										transform="translate(-657.957 -63.385)"
										fill="#132859"
									/>
									<path
										id="Path_614"
										data-name="Path 614"
										d="M711.457,71.482v2.412h-4.618V88h-2.673V79.956l.05-8.474V67.655h2.623v3.827Z"
										transform="translate(-651.124 -64.408)"
										fill="#132859"
									/>
									<path
										id="Path_615"
										data-name="Path 615"
										d="M709.758,78.726c0-5.153,3.544-8.43,7.328-8.43a6.5,6.5,0,0,1,5.749,3.337l-.088-2.979H725.4V86.769h-2.651l.088-2.949a6.475,6.475,0,0,1-5.749,3.307C713.3,87.127,709.758,83.82,709.758,78.726Zm12.989-.03c0-3.456-2.264-5.838-5.094-5.838-2.891,0-5.125,2.382-5.125,5.868,0,3.455,2.234,5.838,5.125,5.838C720.482,84.564,722.747,82.181,722.747,78.7Z"
										transform="translate(-649.464 -63.385)"
										fill="#132859"
									/>
									<path
										id="Path_616"
										data-name="Path 616"
										d="M723.024,70.572h2.919l4.439,13.077h.088l4.44-13.077h2.828l-5.868,16.115h-2.979Z"
										transform="translate(-645.527 -63.303)"
										fill="#132859"
									/>
									<path
										id="Path_617"
										data-name="Path 617"
										d="M735.324,66.836a1.7,1.7,0,1,1,1.7,1.686A1.645,1.645,0,0,1,735.324,66.836Zm.328,5.418h2.74V88.535h-2.74Z"
										transform="translate(-641.877 -65.151)"
										fill="#132859"
									/>
									<path
										id="Path_618"
										data-name="Path 618"
										d="M696.347,74.831c0,1.233,1.035,1.8,2.706,2.334l.248.074c.2.058.406.124.621.183,2.474.72,5.251,1.945,5.251,5.19,0,2.954-2.776,4.285-6.5,4.285h-5.006V84.514h5.164c2.1-.041,3.653-.581,3.653-1.98,0-1.439-1.286-2.3-3.339-2.936a2.162,2.162,0,0,0-.215-.066,2.119,2.119,0,0,0-.257-.083c-2.648-.8-5.006-1.672-5.006-4.5,0-2.706,2.159-4.378,5.734-4.378h5.793v2.383h-5.619c-.1,0-.2,0-.3.008C697.441,73.011,696.347,73.59,696.347,74.831Z"
										transform="translate(-654.24 -63.304)"
										fill="#132859"
									/>
								</g>
								<g
									id="Group_2566"
									data-name="Group 2566"
									transform="translate(631.066 65.077)"
								>
									<path
										id="Path_619"
										data-name="Path 619"
										d="M654.282,77.6a11.73,11.73,0,0,1-10.949,10.938,11.62,11.62,0,0,1-10.772-5.973,9.406,9.406,0,0,1-.552-1.127,11.561,11.561,0,0,1-.919-3.9c-.014-.238-.023-.481-.023-.719s.009-.485.023-.718a11.76,11.76,0,0,1,.313-2.069,11.637,11.637,0,0,1,2.673-5.1c.235-.261.48-.508.734-.745a11.491,11.491,0,0,1,7.878-3.117A11.7,11.7,0,0,1,654.282,77.6Z"
										transform="translate(-631.066 -65.077)"
										fill="#d9e4fc"
									/>
									<path
										id="Path_620"
										data-name="Path 620"
										d="M666.01,75.491a11.7,11.7,0,0,1-11.548,13.065,11.719,11.719,0,0,1-11.295-8.963h-4.125a1.832,1.832,0,0,1-1.823-1.842h0a1.833,1.833,0,0,1,1.823-1.842h3.84a10.633,10.633,0,0,1,.285-1.839H631.523a.574.574,0,0,1-.2-.037,11.637,11.637,0,0,1,2.673-5.1h11.851c.236-.261.48-.508.734-.745a11.5,11.5,0,0,1,9.055-3.054A11.7,11.7,0,0,1,666.01,75.491Z"
										transform="translate(-630.989 -65.076)"
										fill="#2253c7"
									/>
									<path
										id="Path_621"
										data-name="Path 621"
										d="M639.344,79.161h0a1.6,1.6,0,0,1-1.6,1.6H633.87a.125.125,0,0,1-.092-.041,11.539,11.539,0,0,1-1.448-2.032,10.426,10.426,0,0,1-.479-.947.122.122,0,0,1,.113-.17h5.785A1.594,1.594,0,0,1,639.344,79.161Z"
										transform="translate(-630.836 -61.177)"
										fill="#2253c7"
									/>
								</g>
							</g>
						</svg>
					</div>
				</div>

				<div className={styles.InvoiceOverview}>
					<div>
						<p>
							<strong>Invoice Number</strong>
						</p>
						{receipt.startPeriod !== null && <p>Billing Period</p>}
						<p>Date Paid</p>
						<p>Payment Method</p>
					</div>
					<div>
						<p>
							<strong>{receipt.number}</strong>
						</p>
						{receipt.startPeriod !== null && (
							<p>
								{moment(receipt.startPeriod).format("MMMM Do, YYYY")}
								&nbsp; - &nbsp;
								{moment(receipt.endPeriod).format("MMMM Do, YYYY")}
							</p>
						)}
						<p>{moment(receipt.datePaid).format("MMMM Do, YYYY")}</p>
						<p>
							<span className={styles.Brand}>
								{(receipt.paymentMethod as PaymentCard).brand}
							</span>{" "}
							- {(receipt.paymentMethod as PaymentCard).last4}
						</p>
					</div>
				</div>

				<div className={styles.Addresses}>
					<div>
						<p>
							<strong>Prestavi, Inc.</strong>
						</p>
						<p>19215 SE 34th Street</p>
						<p>Suite 106, PMB 526</p>
						<p>Camas, WA 98607</p>
						<p>United States</p>
					</div>
					<div>
						<p>
							<strong>Bill To</strong>
						</p>
						<p>{account?.name}</p>
						<p>{account?.address?.line1}</p>
						{account?.address?.line2 && <p>{account?.address?.line2}</p>}
						<p>
							{account?.address?.city}, {account?.address?.state}
						</p>
						<p>{parseCountryCode(account?.address?.country as string)?.label}</p>
					</div>
				</div>

				<div className={styles.Table}>
					<div className={styles.Row}>
						<span>Description</span>
						<span>Qty</span>
						<span>Unit Price</span>
						<span>Amount</span>
					</div>

					{receipt.lineItems.map((lineItem) => (
						<div className={styles.Row}>
							<span>Prestavi Subscription - {lineItem.label}</span>
							<span>{lineItem.quantity}</span>
							<span className={styles.UnitPrice}>{lineItem.amount}</span>
							<span>{lineItem.amount.formatted}</span>
						</div>
					))}
				</div>

				<div className={styles.Totals}>
					<div>
						<p>Subtotal</p>
						<p>{receipt.subtotal}</p>
					</div>

					<div>
						{receipt.tax !== null && <p>Tax</p>}
						{receipt.tax !== null && <p>{receipt.tax}</p>}
					</div>
					<div>
						<p>Total</p>
						<p>{receipt.total}</p>
					</div>
					<div>
						<p>
							<strong>Amount Paid</strong>
						</p>
						<p>
							<strong>{receipt.amountPaid}</strong>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Receipt;
