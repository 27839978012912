import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Button, FieldMode, LongText, Modal, Space } from "design-system";
import { store } from "../../../store";
import { InitialState } from "../../../initialState";
import { post } from "../../../utils/api";
import { AxiosResponse } from "axios";

import styles from "./SystemError.module.scss";

interface Props {
	show: boolean;
	onClose: () => void;
}

const ErrorReportForm: React.FunctionComponent<Props> = ({ show, onClose }) => {
	const state = store.getState() as InitialState;
	const [description, setDescription] = useState("");
	const formRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const successMessageRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

	useEffect(() => {}, []);

	function send() {
		const payload = {
			user: {
				key: state.me?.key,
				name: `${state.me?.firstName} ${state.me?.lastName}`,
				email: state.me?.email,
			},
			description,
			error: state.system.capturedError,
		};

		post("/error-report", payload)
			.then((response) => {
				if ((response as AxiosResponse).data.status === "ok") {
					setDescription("");

					if (!formRef.current || !successMessageRef.current) return;

					console.log("hit");
					formRef.current.style.visibility = "hidden";
					formRef.current.style.position = "absolute";
					successMessageRef.current.style.position = "relative";
					successMessageRef.current.style.visibility = "visible";
					successMessageRef.current.style.transform = "translateY(0)";
					// show success message and prompt to refresh the page
				}
			})
			.catch((error) => {});
	}

	return (
		<Modal
			show={show}
			toggleModal={onClose}
			includeBackground={false}
			className={styles.ErrorReportModal}
		>
			<div ref={formRef} className={styles.ErrorReportForm}>
				<div className={styles.Header}>
					<h2>Send an error report</h2>
					<span>Any information you can provide will help us solve this issue faster.</span>
				</div>
				<LongText
					id="description"
					value={description}
					fullWidth={true}
					onChange={(event) => setDescription((event.target as HTMLTextAreaElement).value)}
					label="What you were doing right before the error occurred?"
				/>

				<Space amount={16} />

				<Button size="standard" tone="medium" theme="solid" onClick={() => send()}>
					Send
				</Button>
			</div>
			<div ref={successMessageRef} className={styles.SuccessMessage}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="36.385"
					height="36.385"
					viewBox="0 0 36.385 36.385"
				>
					<g transform="translate(-1242.992 -495.303)">
						<circle
							cx="18.193"
							cy="18.193"
							r="18.193"
							transform="translate(1242.992 495.303)"
							fill="#b9e5d6"
						/>
						<path
							d="M1249.173,511.683l6.722,5.525,12.646-13.646"
							transform="translate(2.327 3.11)"
							fill="none"
							stroke="#2a9672"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2.422"
						/>
					</g>
				</svg>

				<p>Thank you, we've received your report.</p>

				<Space amount={16} />

				<Button size="standard" tone="light" theme="solid" onClick={() => window.location.reload()}>
					Reload the page
				</Button>
			</div>
		</Modal>
	);
};

export default ErrorReportForm;
