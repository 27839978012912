import { NO_INTERNET_DETECTED, RECONNECTED_TO_INTERNET } from "../../../actionTypes";

export function lostInternetConnection() {
	return {
		type: NO_INTERNET_DETECTED,
	};
}

export function reconnectedToInternet() {
	return {
		type: RECONNECTED_TO_INTERNET,
	};
}
