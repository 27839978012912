import React from "react";
import classNames from "classnames";

import styles from "./Webhooks.module.scss";

interface Props {
	selectedOptions: Array<string>;
	onClick: (option: string) => void;
}

const options = [
	{ key: "jobKey", label: "Job Key" },
	{ key: "startedBy", label: "Started By" },
	{ key: "startedAt", label: "Started At" },
	{ key: "workflows", label: "Workflows" },
	{ key: "completedAt", label: "Completed At" },
	{ key: "fields", label: "Fields" },
	{ key: "logs", label: "Logs" },
];
const PayloadOptions: React.FunctionComponent<Props> = ({ selectedOptions, onClick }) => {
	const content = options.map((option) => {
		const selected = selectedOptions.includes(option.key);

		const classes = classNames([styles.PayloadOption, selected && styles.Selected]);
		return (
			<div className={classes} onClick={() => onClick(option.key)} key={option.key}>
				{selected && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="11.116"
						height="9.432"
						viewBox="0 0 11.116 9.432"
					>
						<path
							d="M803.72,333.707l3.047,3.013,5.734-7.44"
							transform="translate(-802.548 -328.117)"
							fill="none"
							stroke="#2253c7"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.658"
						/>
					</svg>
				)}

				{option.label}
			</div>
		);
	});

	return <div className={styles.PayloadOptions}>{content}</div>;
};

export default PayloadOptions;
