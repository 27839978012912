import React, { useEffect, useState } from "react";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import {
	Ability,
	Button,
	Checkbox,
	Dropdown,
	HiddenOptions,
	KeyValuePairsInput,
	LoadState,
	Prompt,
	ShortText,
	Space,
	Spinner,
	Tooltip,
	Webhook,
} from "design-system";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import PayloadOptions from "./PayloadOptions";
import PayloadPreview from "./PayloadPreview";
import { deleteWebhook, updateWebhook } from "../developerActions";
import { fetchFromApi, sendCapturedRequestToAPI, sendRequestToAPI } from "../../../sharedActions";
import { DeveloperAPI } from "../../../api/DeveloperAPI";
import { INITIAL_RENDER_ONLY, TOP_NAV_HEIGHT, wait } from "../../../utils/constants";
import SendTestModal from "./SendTestModal";
import { canNotAccess } from "../../../utils/helpers";
import Unauthorized from "../../Unauthorized/Unauthorized";
import { CAPTURED_TEST_WEBHOOK } from "../../../capturedResponseTypes";
import FailedNotificationSetup from "./FailedNotificationSetup";

import styles from "./Webhooks.module.scss";

interface Props extends RouteComponentProps {}

function prepareSelectedEvent(event: string) {
	if (event === "jobCompleted") {
		return { key: "jobCompleted", label: "Job Completed" };
	}

	return { key: "action", label: "Triggered via Action" };
}

function prepareTempWebhook(webhook: Webhook | null) {
	if (!webhook) {
		return null;
	}

	let customProperties = webhook.settings.customProperties;
	if (!customProperties) {
		webhook.settings.customProperties = [{ key: "", value: "" }];
	} else if (customProperties.length === 0) {
		webhook.settings.customProperties = [{ key: "", value: "" }];
	}

	return webhook;
}

const WebhookSetup: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const webhooks = useSelector((state: InitialState) => state.webhooks);
	const webhooksLoaded = useSelector(
		(state: InitialState) => state.loadStates.webhooks === LoadState.LOADED
	);
	const [tempWebhook, setTempWebhook] = useState<Webhook | null>(
		prepareTempWebhook(webhooks[params.webhookKey])
	);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const [showSendTestModal, setShowSendTestModal] = useState(false);

	useEffect(() => {
		if (!webhooksLoaded) {
			dispatch(fetchFromApi(DeveloperAPI.loadWebhooks()));
		}
	}, INITIAL_RENDER_ONLY);

	useEffect(() => {
		if (!webhooksLoaded) return;

		if (!webhooks.hasOwnProperty(params.webhookKey)) {
			window.location.replace("/manage/developers/webhooks");
		}

		setTempWebhook(prepareTempWebhook(webhooks[params.webhookKey]));
	}, [webhooksLoaded]);

	if (canNotAccess(Ability.ManageDeveloperSettings)) {
		return <Unauthorized requiredPermission="Manage developer settings" />;
	}

	function updatePayload(selectedOption: string) {
		if (!tempWebhook) return;

		if (tempWebhook.payload.includes(selectedOption)) {
			setTempWebhook({
				...tempWebhook,
				payload: tempWebhook.payload.filter((option: string) => option !== selectedOption),
			});
			return;
		}

		setTempWebhook({
			...tempWebhook,
			payload: [...tempWebhook.payload, selectedOption],
		});
	}

	function updateKeyValuePairs(
		settingName: string,
		identifier: "key" | "value",
		index: number,
		value: string
	) {
		if (!tempWebhook) return;

		const updated = tempWebhook.settings[settingName].map((property: any, i: number) => {
			if (index === i) {
				property[identifier] = value;
			}

			return property;
		});

		setTempWebhook({
			...tempWebhook,
			settings: {
				...tempWebhook.settings,
				[settingName]: updated,
			},
		});
	}

	function addKeyValuePair(settingName: string) {
		if (!tempWebhook) return;

		setTempWebhook({
			...tempWebhook,
			settings: {
				...tempWebhook.settings,
				[settingName]: [...tempWebhook.settings[settingName], { key: "", value: "" }],
			},
		});
	}

	function deleteKeyValuePair(settingName: string, index: number) {
		if (!tempWebhook) return;

		setTempWebhook({
			...tempWebhook,
			settings: {
				...tempWebhook.settings,
				[settingName]: tempWebhook.settings[settingName].filter(
					(property: any, i: number) => i !== index
				),
			},
		});
	}

	function toggleSettingCheckbox(settingName: string) {
		if (!tempWebhook) return;

		if (tempWebhook.settings.hasOwnProperty(settingName)) {
			// then remove it
			let nextSettings = { ...tempWebhook.settings };
			delete nextSettings[settingName];

			if (settingName === "autoRetryFails" && nextSettings.hasOwnProperty("failureNotifications")) {
				nextSettings.failureNotifications.maxAttemptsOnly = false;
			}

			setTempWebhook({
				...tempWebhook,
				settings: nextSettings,
			});

			return;
		}

		if (settingName === "autoRetryFails") {
			setTempWebhook({
				...tempWebhook,
				settings: {
					...tempWebhook.settings,
					autoRetryFails: {
						delay: 1,
						maxAttempts: 3,
					},
				},
			});

			return;
		}

		if (settingName === "customHeaders") {
			setTempWebhook({
				...tempWebhook,
				settings: {
					...tempWebhook.settings,
					customHeaders: [{ key: "", value: "" }],
				},
			});
		}

		if (settingName === "failureNotifications") {
			setTempWebhook({
				...tempWebhook,
				settings: {
					...tempWebhook.settings,
					failureNotifications: {
						maxAttemptsOnly: false,
						subscribers: [{ type: null, recipient: null }],
					},
				},
			});
		}
	}

	function updateAutoRetryFailsSetting(property: string, value: any) {
		if (!tempWebhook) return;

		if (isNaN(value)) {
			return;
		}

		if (property === "delay" && value > 180) {
			value = 180;
		}

		if (property === "maxAttempts" && value > 10) {
			value = 10;
		}

		setTempWebhook({
			...tempWebhook,
			settings: {
				...tempWebhook.settings,
				autoRetryFails: {
					...tempWebhook.settings.autoRetryFails,
					[property]: value,
				},
			},
		});
	}

	function updateFailedNotificationSettings(property: string, value: any) {
		if (!tempWebhook) return;

		setTempWebhook({
			...tempWebhook,
			settings: {
				...tempWebhook.settings,
				failureNotifications: {
					...tempWebhook.settings.failureNotifications,
					[property]: value,
				},
			},
		});
	}

	async function save() {
		if (!tempWebhook) return;

		let webhook: Webhook = {
			...tempWebhook,
			settings: {
				...tempWebhook.settings,
				// filter out the empty properties
				customProperties: tempWebhook.settings.customProperties.filter(
					(customProperty: { key: string; value: string }, i: number) =>
						customProperty.key !== "" && customProperty.value !== ""
				),
			},
		};

		if (webhook.settings.customProperties.length === 0) {
			delete webhook.settings.customProperties;
		}

		dispatch(updateWebhook(webhook));
		dispatch(sendRequestToAPI(DeveloperAPI.saveWebhook(webhook)));
	}

	async function done() {
		await save();
		await navigate(`/manage/developers/webhooks`);
	}

	async function sendTest() {
		if (!tempWebhook) return;

		await save();

		// Make sure the webhook saves before we send the test
		await wait(1500);

		dispatch(
			sendCapturedRequestToAPI(DeveloperAPI.sendWebhookTest(tempWebhook.key), CAPTURED_TEST_WEBHOOK)
		);
	}

	async function handleDelete() {
		if (!tempWebhook) return;

		dispatch(sendRequestToAPI(DeveloperAPI.deleteWebhook(tempWebhook.key)));
		dispatch(deleteWebhook(tempWebhook.key));

		await navigate(`/manage/developers/webhooks`);
	}

	if ((!tempWebhook && !webhooksLoaded) || !tempWebhook) {
		return <Spinner />;
	}

	return (
		<div className={styles.WebhookSetup} style={{ minHeight: window.innerHeight - TOP_NAV_HEIGHT }}>
			<div className={styles.Page}>
				<div className={styles.Header}>
					<div>
						<Tooltip
							id={`back-to-webhooks`}
							placement="top"
							trigger={["hover"]}
							overlay={<span>Back to Webhooks</span>}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="27"
								height="27"
								viewBox="0 0 27 27"
								onClick={() => navigate("/manage/developers/webhooks")}
							>
								<g transform="translate(-203 -72)">
									<g
										transform="translate(203 72)"
										fill="#fff"
										stroke="#e9ebf2"
										strokeWidth="1"
									>
										<circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
										<circle cx="13.5" cy="13.5" r="13" fill="none" />
									</g>
									<g transform="translate(207.302 79.873)">
										<g transform="translate(0 5.482)">
											<path
												d="M752.246,501.163H735.009a.877.877,0,1,1,0-1.747h17.237a.877.877,0,1,1,0,1.747Z"
												transform="translate(-734.055 -499.416)"
												fill="#caced9"
											/>
										</g>
										<g transform="translate(0.003)">
											<path
												d="M742.792,502.891a.825.825,0,0,1,.121,1.229,1.024,1.024,0,0,1-1.343.114l-7.127-5.478a.622.622,0,0,1-.163-.142.281.281,0,0,1-.057-.071.611.611,0,0,1-.071-.114.054.054,0,0,1-.014-.028.35.35,0,0,1-.043-.121.751.751,0,0,1,.064-.6.424.424,0,0,1,.064-.107c.014-.021.036-.043.05-.064a.7.7,0,0,1,.128-.121l.043-.028,7.127-5.45a1.011,1.011,0,0,1,1.343.107.82.82,0,0,1-.121,1.229l-6.3,4.817Z"
												transform="translate(-734.059 -491.7)"
												fill="#a2a9ba"
											/>
										</g>
									</g>
								</g>
							</svg>
						</Tooltip>
						<h1>Webhook Setup</h1>
					</div>
					<div className={styles.Actions}>
						<HiddenOptions>
							<div className="WebhooksHiddenActions">
								<p onClick={() => setShowConfirmDeleteDialog(true)}>Delete</p>
								<p onClick={() => setShowSendTestModal(true)}>Send Test</p>
							</div>
						</HiddenOptions>
						<Button
							style={{ marginLeft: "8px" }}
							theme="solid"
							tone="medium"
							color="primary"
							onClick={done}
						>
							Save
						</Button>
					</div>
				</div>

				<div className={styles.Basics}>
					<ShortText
						label="Name"
						id="name"
						value={tempWebhook.name}
						onChange={(event) =>
							setTempWebhook({
								...tempWebhook,
								name: (event.target as HTMLInputElement).value,
							})
						}
					/>
					<Space amount={16} />

					<Dropdown
						theme="standard"
						options={[
							{ key: "action", label: "Triggered via Action" },
							// { key: "jobCompleted", label: "Job Completed" },
						]}
						onChange={(option) =>
							setTempWebhook({
								...tempWebhook,
								event: option.key,
							})
						}
						value={prepareSelectedEvent(tempWebhook.event)}
						label="Event"
					/>

					<Space amount={24} />
					<ShortText
						label="Secure URL"
						subLabel="(https required)"
						className={styles.Url}
						id="url"
						value={tempWebhook.url}
						onChange={(event) =>
							setTempWebhook({
								...tempWebhook,
								url: (event.target as HTMLInputElement).value,
							})
						}
					/>

					<Space amount={16} />
				</div>

				<div className={styles.PayloadBuilder}>
					<h2>Payload Builder</h2>
					<span>Choose only the data you need</span>

					<PayloadOptions selectedOptions={tempWebhook.payload} onClick={updatePayload} />

					<div className={styles.CustomProperties}>
						<h3>Custom Properties</h3>
						<KeyValuePairsInput
							pairs={tempWebhook.settings.customProperties}
							onChange={(identifier, index: number, value: string) =>
								updateKeyValuePairs("customProperties", identifier, index, value)
							}
							onAddButtonClick={() => addKeyValuePair("customProperties")}
							onDeletePair={(index: number) => deleteKeyValuePair("customProperties", index)}
						/>
					</div>
				</div>

				<div className={styles.AdditionalOptions}>
					<h2>Additional Options</h2>

					<div className={styles.Retry}>
						<Checkbox
							id="auto-retry"
							isChecked={tempWebhook.settings.hasOwnProperty("autoRetryFails")}
							onChange={() => toggleSettingCheckbox("autoRetryFails")}
							label="Automatically retry failures"
						/>

						{tempWebhook.settings.hasOwnProperty("autoRetryFails") && (
							<div className={styles.RetrySettings}>
								<div className={styles.Delay}>
									<ShortText
										label="Retry Delay"
										id="delay"
										value={tempWebhook.settings.autoRetryFails.delay}
										onChange={(event) =>
											updateAutoRetryFailsSetting(
												"delay",
												(event.target as HTMLInputElement).value
											)
										}
									/>
									<span>minutes</span>
								</div>
								<ShortText
									label="Max Attempts"
									id="max"
									value={tempWebhook.settings.autoRetryFails.maxAttempts}
									onChange={(event) =>
										updateAutoRetryFailsSetting(
											"maxAttempts",
											(event.target as HTMLInputElement).value
										)
									}
								/>
							</div>
						)}
					</div>

					<div className={styles.Notifications}>
						<Checkbox
							id="failed-notifications"
							isChecked={tempWebhook.settings.hasOwnProperty("failureNotifications")}
							onChange={() => toggleSettingCheckbox("failureNotifications")}
							label="Send email notification upon failure"
						/>

						{tempWebhook.settings.hasOwnProperty("failureNotifications") && (
							<FailedNotificationSetup
								{...tempWebhook.settings.failureNotifications}
								autoRetryFails={tempWebhook.settings.hasOwnProperty("autoRetryFails")}
								maxAttemptsCount={
									tempWebhook.settings.hasOwnProperty("autoRetryFails")
										? tempWebhook.settings.autoRetryFails.maxAttempts
										: null
								}
								onUpdate={updateFailedNotificationSettings}
							/>
						)}
					</div>

					<div className={styles.Headers}>
						<Checkbox
							id="custom-headers"
							isChecked={tempWebhook.settings.hasOwnProperty("customHeaders")}
							onChange={() => toggleSettingCheckbox("customHeaders")}
							label="Include custom request headers"
						/>

						{tempWebhook.settings.hasOwnProperty("customHeaders") && (
							<div className={styles.HeadersInput}>
								<KeyValuePairsInput
									pairs={tempWebhook.settings.customHeaders}
									onChange={(identifier, index: number, value: string) =>
										updateKeyValuePairs("customHeaders", identifier, index, value)
									}
									onAddButtonClick={() => addKeyValuePair("customHeaders")}
									onDeletePair={(index: number) =>
										deleteKeyValuePair("customHeaders", index)
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<PayloadPreview selectedOptions={tempWebhook.payload} settings={tempWebhook.settings} />

			<Prompt
				show={showConfirmDeleteDialog}
				onConfirm={handleDelete}
				onCancel={() => setShowConfirmDeleteDialog(false)}
			>
				<p>Are you sure you want to delete this Webhook?</p>
			</Prompt>

			<SendTestModal
				show={showSendTestModal}
				onSend={sendTest}
				onClose={() => setShowSendTestModal(false)}
			/>
		</div>
	);
};

export default WebhookSetup;
