import React from "react";
import classNames from "classnames";
import { JobAction, JobActionStatus, Spinner } from "design-system";

import styles from "./Action.module.scss";

interface Props {
	jobAction: JobAction;
}

const statusMap = {
	[JobActionStatus.Processing]: (
		<div className={styles.Processing}>
			<Spinner size="small" color="neutral" /> <span>Processing</span>
		</div>
	),
	[JobActionStatus.Done]: "Done",
	[JobActionStatus.Failed]: "Failed",
	[JobActionStatus.Received]: "Received",
};

const Status: React.FunctionComponent<Props> = ({ jobAction }) => {
	if (!jobAction) {
		return (
			<div className={styles.Status}>
				<Spinner size="small" />
			</div>
		);
	}

	const status = jobAction.status;

	const classes = classNames([
		styles.Status,
		status === JobActionStatus.Done && styles.Done,
		status === JobActionStatus.Failed && styles.Failed,
		status === JobActionStatus.Received && styles.Received,
	]);

	return <div className={classes}>{statusMap[jobAction.status]}</div>;
};

export default Status;
