import { applyMiddleware, compose, createStore, Store } from "redux";
import initialState, { InitialState } from "./initialState";
import { rootReducer } from "./rootReducer";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./rootEpic";
import { determineEnv } from "./utils/api";

function buildMiddleware(epicMiddleware: any) {
	if (determineEnv() !== "prod") {
		// @ts-ignore
		const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

		return composeEnhancers(applyMiddleware(epicMiddleware));
	}

	return applyMiddleware(epicMiddleware);
}

export default function configureStore(): Store {
	const epicMiddleware = createEpicMiddleware();

	const store = createStore(rootReducer, initialState as InitialState, buildMiddleware(epicMiddleware));

	epicMiddleware.run(rootEpic);

	return store;
}

export const store = configureStore();
