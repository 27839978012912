import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, Card, ErrorBag, Password, Space } from "design-system";
import { post } from "../../../utils/api";
import Errors from "../../Base/Form/Errors/Errors";
import classNames from "classnames";
import { Logo30 } from "../../Base/Logo/Logos";

import styles from "./ResetPassword.module.scss";

interface Props extends RouteComponentProps {}

const ResetPassword: React.FunctionComponent<Props> = () => {
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState<Array<ErrorBag>>([]);
	const passwordInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

	useEffect(() => {
		if (!passwordInputRef.current) return;

		passwordInputRef.current.focus();
	}, []);

	function send() {
		const params = new URL(window.location.href).searchParams;

		post("/reset-password", { password, token: params.get("token") })
			.then((response) => {
				setPassword("");
				setErrors([]);
				window.location.replace("/login");
			})
			.catch((error) => {
				setErrors(error.response.data.errors);
			});
	}

	const formClasses = classNames([styles.Form]);
	return (
		<div className={styles.ResetPassword}>
			<div className={styles.Logo} style={{ position: "relative", left: "-6px" }}>
				{Logo30}
			</div>
			<Card maxWidth={500} centered={true} className={formClasses}>
				<div className={styles.Header}>
					<h1>Reset your password</h1>
					<p>Please choose a new password for your account.</p>
				</div>
				<Space amount={24} />

				<Password
					label="New Password"
					id="password"
					theme="inside"
					value={password}
					autoComplete={false}
					forwardRef={passwordInputRef}
					onChange={(event) => setPassword((event.target as HTMLInputElement).value)}
				/>

				{errors && (
					<React.Fragment>
						<Space amount={16} />
						<Errors bag={errors} />
					</React.Fragment>
				)}

				<Space amount={16} />

				<Button theme="solid" tone="medium" color="primary" onClick={send}>
					Send
				</Button>
			</Card>
		</div>
	);
};

export default ResetPassword;
