import React, { useState } from "react";
import { Button, IntegrationList, JobAction, JobActionStatus, Modal } from "design-system";
import LogRecord from "./LogRecord";
import LogRecordAttempts from "./LogRecordAttempts";
import { useDispatch } from "react-redux";
import { useParams } from "@reach/router";
import { cancelScheduledResend, manuallyResendAction } from "../../../../../../jobActions";
import { sendRequestToAPI } from "../../../../../../../../sharedActions";
import { JobAPI } from "../../../../../../../../api/JobAPI";

import styles from "./DetailsModal.module.scss";

interface Props {
	show: boolean;
	jobAction: JobAction;
	integrations: IntegrationList;
	onClose: () => void;
}

export function convertCodeToStatus(statusCode: number) {
	if (statusCode === 200) {
		return JobActionStatus.Done;
	}

	if (statusCode === 202) {
		return JobActionStatus.Received;
	}

	if (statusCode > 202) {
		return JobActionStatus.Failed;
	}

	return JobActionStatus.Processing;
}

const AutomationDetailsModal: React.FunctionComponent<Props> = ({
	show,
	jobAction,
	integrations,
	onClose,
}) => {
	const [selectedLogRecordIndex, setSelectedLogRecordIndex] = useState(0);
	const dispatch = useDispatch();
	const params = useParams();

	function handleManualResend() {
		dispatch(manuallyResendAction(jobAction.key));
		dispatch(sendRequestToAPI(JobAPI.manuallyResendAction(params.jobKey, jobAction.key)));
		onClose();
	}

	function handleCancelScheduledResend() {
		dispatch(cancelScheduledResend(jobAction.key));
		dispatch(sendRequestToAPI(JobAPI.cancelScheduledResend(params.jobKey, jobAction.key)));
	}

	if (!jobAction) {
		return null;
	}

	return (
		<Modal
			maxWidth="800px"
			show={show}
			toggleModal={onClose}
			className={styles.DetailsModal}
			includeBackground={false}
		>
			<div className={styles.Content}>
				<div className={styles.AttemptsMenu}>
					<h5>Log</h5>
					<LogRecordAttempts
						jobAction={jobAction}
						logs={jobAction.logs}
						selectedIndex={selectedLogRecordIndex}
						changeSelectedRecord={(index) => setSelectedLogRecordIndex(index)}
					/>
				</div>
				<LogRecord
					jobAction={jobAction}
					logs={jobAction.logs}
					integration={integrations[jobAction.action.integrationKey]}
					selectedIndex={selectedLogRecordIndex}
					cancelScheduledResend={handleCancelScheduledResend}
					onClose={onClose}
				/>

				{jobAction.status === JobActionStatus.Failed && (
					<div className={styles.Resend} onClick={handleManualResend}>
						<Button theme="solid" tone="light" color="primary" onClick={() => null}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.511"
								height="18"
								viewBox="0 0 16.511 18"
							>
								<g transform="translate(832.42 -609.68) rotate(90)">
									<g transform="translate(609.683 823.018)">
										<path
											d="M626.783,827.992h-16.2a1.164,1.164,0,0,1,0-2.266h16.2a1.164,1.164,0,0,1,0,2.266Z"
											transform="translate(-609.684 -825.726)"
											fill="#9cb7f7"
										/>
									</g>
									<g transform="translate(609.68 815.91)">
										<path
											d="M620.05,830.422a1.133,1.133,0,0,1-.731,2,1.141,1.141,0,0,1-.724-.261l-8.509-7.14a.861.861,0,0,1-.138-.145.982.982,0,0,1-.109-.152.055.055,0,0,1-.015-.029.64.64,0,0,1-.058-.123.53.53,0,0,1-.058-.181,1,1,0,0,1,0-.478.7.7,0,0,1,.072-.217.04.04,0,0,1,.022-.036.4.4,0,0,1,.058-.109.477.477,0,0,1,.087-.123,1.363,1.363,0,0,1,.145-.145l.036-.029,8.465-7.082a1.133,1.133,0,1,1,1.456,1.738l-7.473,6.242Z"
											transform="translate(-609.68 -815.91)"
											fill="#5583ed"
										/>
									</g>
								</g>
							</svg>
							Resend Now
						</Button>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default AutomationDetailsModal;
