import React, { useEffect } from "react";
import { InitialState } from "../../../../../../initialState";
import { useDispatch, useSelector } from "react-redux";
import { DeveloperAPI } from "../../../../../../api/DeveloperAPI";
import { fetchFromApi } from "../../../../../../sharedActions";

interface Props {
	endpointKey: string;
}

const ExternalEndpointDetails: React.FunctionComponent<Props> = ({ endpointKey }) => {
	const dispatch = useDispatch();
	const endpoint = useSelector((state: InitialState) => state.externalEndpoints[endpointKey]);

	useEffect(() => {
		if (!endpoint) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpoint(endpointKey)));
		}
	});

	if (!endpoint) {
		return null;
	}

	return <p>{endpoint.name}</p>;
};

export default ExternalEndpointDetails;
