import { XYCoords } from "../utils";
import { LinePosition } from "design-system";

export class Box {
	private readonly width: number;
	private readonly height: number;
	// these coords represent the top left corner of the box.
	private coordX: number;
	private coordY: number;
	private readonly direction: LinePosition;

	constructor(width: number, height: number, coordX: number, coordY: number, direction: LinePosition) {
		this.width = width;
		this.height = height;
		this.coordX = coordX;
		this.coordY = coordY;
		this.direction = direction;
	}

	getWidth() {
		return this.width;
	}

	getHeight() {
		return this.height;
	}

	getCoordX() {
		return this.coordX;
	}

	getCoordY() {
		return this.coordY;
	}

	getDirection() {
		return this.direction;
	}

	setCoordX(coordX: number) {
		this.coordX = coordX;
	}

	setCoordY(coordY: number) {
		this.coordY = coordY;
	}

	/*
	 * calculate the centered point along the top of the box from the top left coordinates
	 */
	top(): XYCoords {
		return {
			x: this.coordX + this.width / 2,
			y: this.coordY,
		};
	}

	/*
	 * calculate the centered point along the bottom of the box from the top left coordinates
	 */
	bottom(): XYCoords {
		return {
			x: this.coordX + this.width / 2,
			y: this.coordY + this.height,
		};
	}

	/*
	 * calculate the centered point along the left of the box from the top left coordinates
	 */
	left(): XYCoords {
		return {
			x: this.coordX,
			y: this.coordY + this.height / 2,
		};
	}

	/*
	 * calculate the centered point along the right of the box from the top left coordinates
	 */
	right(): XYCoords {
		return {
			x: this.coordX + this.width,
			y: this.coordY + this.height / 2,
		};
	}

	calculateCoordsFromDirection(): XYCoords {
		return this[this.direction]();
	}
}
