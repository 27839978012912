import React from "react";
import { RouteComponentProps } from "@reach/router";
import { canNotAccess } from "../../../utils/helpers";
import { Ability } from "design-system";
import Unauthorized from "../../Unauthorized/Unauthorized";

import styles from "./DeveloperLogs.module.scss";

interface Props extends RouteComponentProps {}

const DeveloperLogs: React.FunctionComponent<Props> = () => {
	if (canNotAccess(Ability.ManageDeveloperSettings)) {
		return <Unauthorized requiredPermission="Manage developer settings" />;
	}

	return <div className={styles.DeveloperLogs}>logsh ere</div>;
};

export default DeveloperLogs;
