import { AnyAction } from "redux";
import { convertToRaw, EditorState } from "draft-js";
import {
	Action,
	ConditionalContentChild,
	Connector,
	ContentCondition,
	ContentFormDetails,
	Delay,
	ElementConnection,
	ElementRoute,
	End,
	FormField,
	PersonAssignmentOption,
	RouteCondition,
	RouteConnection,
	StepContentType,
	SubWorkflowConnector,
	WorkflowCategory,
	XYCoords,
} from "design-system";
import { post } from "../utils/api";
import {
	SET_STEP_BLUEPRINT,
	SET_STEP_CONTENT_ELEMENT,
	SET_UNPUBLISHED_WORKFLOWS,
	UPDATE_STEP_CONTENT_IMAGE_DETAILS,
} from "../actionTypes";
import {
	EveryoneDistributionSettings,
	RoundRobinDistributionSettings,
} from "design-system/src/types/workflows/steps";
import { AssignmentDistributionType } from "design-system";
import { isInsideConditionalElement, isNotConditionalGroupElement } from "../utils/helpers";
import { store } from "../store";
import { InitialState } from "../initialState";

export class WorkflowAPI {
	static unpublishedWorkflows() {
		return {
			url: `workflows?type=unpublished`,
			respondWith: SET_UNPUBLISHED_WORKFLOWS,
		};
	}

	static createCategory(key: string, name: string) {
		return {
			url: `categories`,
			data: {
				key,
				name,
			},
		};
	}

	static updateCategory(key: string, name: string) {
		return {
			url: `categories/${key}`,
			data: {
				name,
			},
		};
	}

	static deleteCategory(key: string) {
		return {
			url: `categories/${key}`,
			data: {},
			method: "delete",
		};
	}

	static createStep(
		step: any,
		connection: any,
		route: ElementRoute,
		connector: Connector,
		workflowKey: string
	) {
		return {
			url: `workflows/${workflowKey}/steps`,
			data: {
				step,
				connection,
				route,
				connector,
			},
		};
	}

	static loadStep(workflowKey: string, stepKey: string) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}`,
			respondWith: SET_STEP_BLUEPRINT,
		};
	}

	static updateStep(workflowKey: string, stepKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}`,
			data: changes,
		};
	}

	static deleteStep(workflowKey: string, stepKey: string) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}`,
			data: {},
			method: "delete",
		};
	}

	static createEnd(workflowKey: string, endPiece: End) {
		return {
			url: `workflows/${workflowKey}/ends`,
			data: endPiece,
		};
	}

	static updateEnd(workflowKey: string, endKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/ends/${endKey}`,
			data: changes,
		};
	}

	static deleteEnd(workflowKey: string, endKey: string) {
		return {
			url: `workflows/${workflowKey}/ends/${endKey}`,
			data: {},
			method: "delete",
		};
	}

	static createSubWorkflow(workflowKey: string, subWorkflow: SubWorkflowConnector) {
		return {
			url: `workflows/${workflowKey}/subworkflows`,
			data: subWorkflow,
		};
	}

	static updateSubWorkflow(workflowKey: string, subWorkflowKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/subworkflows/${subWorkflowKey}`,
			data: changes,
		};
	}

	static deleteSubWorkflow(workflowKey: string, subWorkflowKey: string) {
		return {
			url: `workflows/${workflowKey}/subworkflows/${subWorkflowKey}`,
			data: {},
			method: "delete",
		};
	}

	static createDelay(
		workflowKey: string,
		delay: Delay,
		connection: ElementConnection,
		route: ElementRoute,
		connector: Connector
	) {
		return {
			url: `workflows/${workflowKey}/delays`,
			data: {
				delay,
				connection,
				route,
				connector,
			},
		};
	}

	static updateDelay(workflowKey: string, delayKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/delays/${delayKey}`,
			data: changes,
		};
	}

	static deleteDelay(workflowKey: string, delayKey: string) {
		return {
			url: `workflows/${workflowKey}/delays/${delayKey}`,
			data: {},
			method: "delete",
		};
	}

	static updateWorkflow(workflowKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}`,
			data: changes,
		};
	}

	static createRoute(workflowKey: string, route: any) {
		return {
			url: `workflows/${workflowKey}/routes`,
			data: route,
		};
	}

	static updateRoute(workflowKey: string, routeKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/routes/${routeKey}`,
			data: changes,
		};
	}

	static updateRouteConnection(
		workflowKey: string,
		routeKey: string,
		direction: "to" | "from",
		changes: any
	) {
		return {
			url: `workflows/${workflowKey}/routes/${routeKey}/connections/${direction}`,
			data: changes,
		};
	}

	static deleteRouteCondition(workflowKey: string, routeKey: string, conditionKey: string) {
		return {
			url: `workflows/${workflowKey}/routes/${routeKey}/conditions/${conditionKey}`,
			method: "delete",
			data: {},
		};
	}

	static bulkDeleteRoutes(workflowKey: string, routeKeys: Array<string>) {
		return {
			url: `workflows/${workflowKey}/routes`,
			method: "delete",
			data: {
				keys: routeKeys,
			},
		};
	}

	static createRouteCondition(routeKey: string, condition: RouteCondition, workflowKey: string) {
		return {
			url: `workflows/${workflowKey}/routes/${routeKey}/conditions`,
			data: condition,
		};
	}

	static updateRouteCondition(routeKey: string, conditionKey: string, workflowKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/routes/${routeKey}/conditions/${conditionKey}`,
			data: changes,
		};
	}

	static updateRouteConditionsSettings(routeKey: string, changes: any) {
		return {
			url: `routes/${routeKey}/conditions-settings`,
			data: changes,
		};
	}

	static moveRouteConditions(conditions: { [routeKey: string]: XYCoords }) {
		return {
			url: `move-route-conditions`,
			data: {
				conditions,
			},
		};
	}

	static updateStepAccess(workflowKey: string, stepKey: string, type: string) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/access`,
			data: {
				type,
			},
		};
	}

	static createStepContentElement(content: any, stepKey: string, workflowKey: string) {
		let details = { ...content.details };

		if (content.type === StepContentType.Text) {
			const editorState = details.content as EditorState;

			details.content = convertToRaw(editorState.getCurrentContent());
		}

		if (isInsideConditionalElement()) {
			const conditionalElementKey = (store.getState() as InitialState).editor
				.parentConditionalElementKeyInEditMode;
			return {
				url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/content`,
				data: {
					...content,
					details,
				},
			};
		}

		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/content`,
			data: {
				...content,
				details,
			},
		};
	}

	// todo remove after views are gone
	static createConditionalContentChild(
		workflowKey: string,
		conditionalElementKey: string,
		content: ConditionalContentChild
	) {
		let details = { ...content.details };

		if (content.type === StepContentType.Text) {
			const editorState = details.content as EditorState;

			details.content = convertToRaw(editorState.getCurrentContent());
		}
		return {
			url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/content`,
			data: {
				...content,
				details,
			},
		};
	}

	static updateStepContentElement(stepKey: string, contentKey: string, workflowKey: string, changes: any) {
		if (isInsideConditionalElement() && isNotConditionalGroupElement(contentKey)) {
			const conditionalElementKey = (store.getState() as InitialState).editor
				.parentConditionalElementKeyInEditMode;
			return {
				url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/content/${contentKey}`,
				data: changes,
			};
		}

		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/content/${contentKey}`,
			data: changes,
		};
	}

	static updateStepContentElementOrder(
		workflowKey: string,
		stepKey: string,
		orderedElementKeys: Array<string>
	) {
		if (isInsideConditionalElement()) {
			const conditionalElementKey = (store.getState() as InitialState).editor
				.parentConditionalElementKeyInEditMode;
			return {
				url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/reorder-elements`,
				data: {
					orderedElementKeys,
				},
			};
		}

		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/reorder-elements`,
			data: {
				orderedElementKeys,
			},
		};
	}

	static uploadStepContentImage(
		stepKey: string,
		contentKey: string,
		workflowKey: string,
		formData: FormData
	) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/content/${contentKey}/image`,
			data: {
				key: contentKey,
				formData,
			},
			respondWith: UPDATE_STEP_CONTENT_IMAGE_DETAILS,
		};
	}

	static deleteStepContentElement(stepKey: string, contentKey: string, workflowKey: string) {
		if (isInsideConditionalElement() && isNotConditionalGroupElement(contentKey)) {
			const conditionalElementKey = (store.getState() as InitialState).editor
				.parentConditionalElementKeyInEditMode;
			return {
				url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/content/${contentKey}`,
				method: "delete",
			};
		}

		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/content/${contentKey}`,
			method: "delete",
		};
	}

	static loadStepContentElement(workflowKey: string, stepKey: string, elementKey: string) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/content/${elementKey}`,
			respondWith: SET_STEP_CONTENT_ELEMENT,
		};
	}

	static addContentCondition(
		workflowKey: string,
		conditionalElementKey: string,
		condition: ContentCondition
	) {
		return {
			url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/conditions`,
			data: condition,
		};
	}

	static updateContentCondition(
		workflowKey: string,
		conditionalElementKey: string,
		conditionKey: string,
		changes: any
	) {
		return {
			url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/conditions/${conditionKey}`,
			data: changes,
		};
	}

	static deleteContentCondition(workflowKey: string, conditionalElementKey: string, conditionKey: string) {
		return {
			url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/conditions/${conditionKey}`,
			method: "delete",
			data: {},
		};
	}

	static updateStartMarker(workflowKey: string, startMarker: any) {
		return {
			url: `workflows/${workflowKey}/start-marker`,
			data: startMarker,
		};
	}

	static createConnector(workflowKey: string, connector: any) {
		return {
			url: `workflows/${workflowKey}/connectors`,
			data: connector,
		};
	}

	static updateConnector(workflowKey: string, connectorKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/connectors/${connectorKey}`,
			data: changes,
		};
	}

	static moveConnectors(connectors: { [connectorKey: string]: XYCoords }) {
		return {
			url: `move-connectors`,
			data: {
				connectors,
			},
		};
	}

	static deleteConnector(connectorKey: string, workflowKey: string) {
		return {
			url: `workflows/${workflowKey}/connectors/${connectorKey}`,
			method: "delete",
		};
	}

	static bulkDeleteConnectors(workflowKey: string, connectorKeys: Array<string>) {
		return {
			url: `workflows/${workflowKey}/connectors`,
			method: "delete",
			data: {
				keys: connectorKeys,
			},
		};
	}

	static updateConnection(workflowKey: string, connectionKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/connections/${connectionKey}`,
			data: changes,
		};
	}

	static updateMultiChoiceConnectionField(
		workflowKey: string,
		connectionKey: string,
		nextConnectionDetails: any,
		nextRoutes: any,
		nextConnectors: any
	) {
		return {
			url: `workflows/${workflowKey}/connections/${connectionKey}/update-multi-field`,
			data: {
				nextConnectionDetails,
				nextRoutes,
				nextConnectors,
			},
		};
	}

	static createStartMarker(action: AnyAction) {
		const { connectorKey, coordX, coordY, routeFromDirection, routeToDirection } = action.startMarker;

		return post(`workflows/${action.workflow.key}/start-marker`, {
			connectorKey,
			coordX,
			coordY,
			routeFromDirection,
			routeToDirection,
		});
	}

	static saveConnectorRequest(action: AnyAction) {
		const { key, routeKey, coordX, coordY } = action.connector;

		return post(`workflows/${action.workflow.key}/connectors`, {
			key,
			routeKey,
			coordX,
			coordY,
			direction: action.connector.direction,
		});
	}

	static createField(field: FormField) {
		return {
			url: `fields`,
			data: field,
		};
	}

	static updateField(field: FormField) {
		return {
			url: `fields/${field.key}`,
			data: {
				...field,
				configured: true,
			},
		};
	}

	static deleteField(fieldKey: string) {
		return {
			url: `fields/${fieldKey}`,
			data: {},
			method: "delete",
		};
	}

	static updateStepFormDetails(
		workflowKey: string,
		stepKey: string,
		contentElementKey: string,
		details: ContentFormDetails
	) {
		if (isInsideConditionalElement()) {
			const conditionalElementKey = (store.getState() as InitialState).editor
				.parentConditionalElementKeyInEditMode;
			return {
				url: `workflows/${workflowKey}/conditional-elements/${conditionalElementKey}/content/${contentElementKey}`,
				data: {
					details,
				},
			};
		}

		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/content/${contentElementKey}`,
			data: {
				details: details,
			},
		};
	}

	static updateStepAssignees(
		workflowKey: string,
		stepKey: string,
		assignees: Array<PersonAssignmentOption>
	) {
		return {
			url: `workflows/${workflowKey}/steps/${stepKey}/assignees`,
			data: {
				assignees,
			},
		};
	}

	static updateStepDistribution(
		stepKey: string,
		type: AssignmentDistributionType,
		settings: EveryoneDistributionSettings | RoundRobinDistributionSettings
	) {
		return {
			url: `steps/${stepKey}/distribution`,
			data: {
				type,
				settings,
			},
		};
	}

	static deleteStepDistribution(stepKey: string) {
		return {
			url: `steps/${stepKey}/distribution`,
			data: {},
			method: "delete",
		};
	}

	static createAction(
		workflowKey: string,
		action: Action,
		connection: ElementConnection,
		route: ElementRoute,
		connector: Connector
	) {
		return {
			url: `workflows/${workflowKey}/actions`,
			data: {
				action,
				connection,
				route,
				connector,
			},
		};
	}

	static updateAction(workflowKey: string, actionKey: string, changes: any) {
		return {
			url: `workflows/${workflowKey}/actions/${actionKey}`,
			data: {
				...changes,
			},
		};
	}

	static deleteAction(workflowKey: string, actionKey: string) {
		return {
			url: `workflows/${workflowKey}/actions/${actionKey}`,
			data: {},
			method: "delete",
		};
	}

	static moveRouteLabel(workflowKey: string, routeKey: string, coordX: number, coordY: number) {
		return {
			url: `workflows/${workflowKey}/routes/${routeKey}/label/move`,
			data: {
				coordX,
				coordY,
			},
		};
	}

	static deleteWorkflow(workflowKey: string) {
		return {
			url: `workflows/${workflowKey}`,
			data: {},
			method: "delete",
		};
	}
}
