import { account } from "./components/Account/reducers";
import { users } from "./components/People/reducers";
import { dashboard } from "./components/Dashboard/reducer";
import { workflows } from "./components/Workflows/reducers";
import { editor } from "./components/Workflows/Editor/Canvas/reducers";
import { jobs } from "./components/Jobs/reducer";
import { views } from "./components/Views/reducer";
import { developers } from "./components/Developers/reducers";
import { knowledgeBase } from "./components/KnowledgeBase/reducers";
import { billing } from "./components/Account/Billing/reducers";

/**
 * Our custom combineReducers method passes the entire state object
 * and expects it back in each reducer. This provides the flexibility we need
 * to read from the global state in reducers because of the normalized state of the data.
 */
const combineReducers = (reducers: { [key: string]: any }) => {
	return (state = {}, action: any) => {
		return Object.keys(reducers).reduce((nextState, key) => {
			// each reducer passes the updated state to the next reducer
			nextState = reducers[key](nextState, action);

			return nextState;
		}, state);
	};
};

export const rootReducer = combineReducers({
	account,
	users,
	dashboard,
	workflows,
	editor,
	jobs,
	views,
	developers,
	knowledgeBase,
	billing,
});
