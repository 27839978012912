import { InitialState } from "../../initialState";
import {
	ADD_CONTENT_CONDITION,
	ADD_ELEMENT_TO_PREV_ROUTE,
	ADD_ROUTE_CONDITION,
	ADD_ROUTE_WITH_CONNECTOR,
	CHANGE_STEP_ACCESS,
	CHANGE_STEP_CONNECTION_TYPE,
	CHANGE_TIMEFRAME,
	CREATE_ACTION,
	CREATE_CONDITIONAL_CONTENT_CHILD,
	CREATE_CONNECTOR,
	CREATE_CONTENT_ELEMENT,
	CREATE_DELAY,
	CREATE_END,
	CREATE_FIELD,
	CREATE_FORM_FIELD,
	CREATE_STEP,
	CREATE_SUB_WORKFLOW_CONNECTOR,
	CREATE_WORKFLOW,
	CREATE_WORKFLOW_CATEGORY,
	DELETE_ACTION,
	DELETE_CONTENT_CONDITION,
	DELETE_CONTENT_ELEMENT,
	DELETE_DELAY,
	DELETE_FIELD,
	DELETE_ROUTE,
	DELETE_ROUTE_CONDITION,
	DELETE_STEP,
	DELETE_STEP_ASSIGNMENT_DISTRIBUTION,
	DELETE_SUB_WORKFLOW_CONNECTOR,
	DELETE_WORKFLOW,
	DELETE_WORKFLOW_CATEGORY,
	DESELECT_ELEMENT,
	DESELECT_ROUTE,
	ELEMENT_DRAG_BEGIN,
	ELEMENT_DRAG_END,
	ELEMENT_HOVER_BEGIN,
	ELEMENT_HOVER_END,
	MOVE_ACTION,
	MOVE_CONNECTOR,
	MOVE_CONTENT_ELEMENT_THROTTLED,
	MOVE_DELAY,
	MOVE_END_PIECE,
	MOVE_ROUTE_CONDITION,
	MOVE_ROUTE_LABEL,
	MOVE_START_MARKER,
	MOVE_STEP,
	MOVE_SUB_WORKFLOW_CONNECTOR,
	PAN_DIAGRAM,
	PUBLISH_WORKFLOW_COMPLETE,
	PUBLISH_WORKFLOW_PENDING,
	REMOVE_CONNECTOR,
	REMOVE_END_PIECE,
	REMOVE_ROUTE_CONNECTION,
	RESET_STEP_ASSIGNMENT_DISTRIBUTION,
	RESET_WORKFLOW_EDITOR,
	SELECT_CONDITIONAL_ELEMENT,
	SELECT_CONNECTION_FIELD,
	SELECT_ELEMENT,
	SELECT_FORM_ELEMENT,
	SELECT_FORM_FIELD,
	SELECT_ROUTE,
	SET_ELEMENT_TO_REORDER,
	SET_UNPUBLISHED_WORKFLOWS,
	SET_USE_CUSTOM_LABEL_POSITION,
	SET_WORKFLOW,
	SET_WORKFLOW_HAS_CHANGED,
	SET_WORKFLOW_PARTS,
	SHOW_FORM_EDITOR_SIDEBAR,
	SHOW_MULTI_CONNECTION_SETUP,
	UNSET_PARENT_CONDITION_EDIT_MODE,
	UPDATE_ACTION,
	UPDATE_CONTENT_CONDITION,
	UPDATE_CONTENT_ELEMENT,
	UPDATE_CONTENT_ELEMENT_EDIT_MODE,
	UPDATE_DELAY,
	UPDATE_EDITOR_ZOOMED_PERCENT,
	UPDATE_FIELD,
	UPDATE_FORM_ACCESS,
	UPDATE_FORM_DETAILS,
	UPDATE_FORM_LAYOUT,
	UPDATE_ROUTE_CONDITION,
	UPDATE_ROUTE_CONDITION_DIRECTION,
	UPDATE_ROUTE_CONDITION_JOIN_TYPE,
	UPDATE_SELECTED_EXTRA_OPTIONS_KEY,
	UPDATE_SELECTED_STEP_SECTION,
	UPDATE_START_MARKER,
	UPDATE_START_MARKER_ROUTE_DIRECTION,
	UPDATE_STEP_ASSIGNMENT_DISTRIBUTION,
	UPDATE_STEP_ASSIGNMENT_POOL,
	UPDATE_STEP_CONTENT_IMAGE_DETAILS,
	UPDATE_STEP_NAME,
	UPDATE_SUB_WORKFLOW_CONNECTOR,
	UPDATE_WORKFLOW,
	UPDATE_WORKFLOW_CATEGORY,
	UPDATE_WORKFLOW_CONNECTION_ROUTE,
	UPDATE_WORKFLOW_ROOT,
	UPDATE_WORKFLOW_STATUS,
} from "../../actionTypes";
import { StartMarkerKey } from "../../utils/constants";
import {
	ConditionalContentElement,
	ConditionsSettings,
	ConnectionTypes,
	ElementRoute,
	ElementType,
	LoadState,
	StepContentType,
} from "design-system";
import { convertTextContentToRichText, convertToPublishedKey } from "../../utils/helpers";
import {
	normalizeActions,
	normalizeBasic,
	normalizeDelays,
	normalizeSteps,
} from "../../data/normalize/basic";
import moment from "moment";

export function workflows(state: InitialState, action: any) {
	if (action.type === SET_UNPUBLISHED_WORKFLOWS) {
		let nextWorkflows = { ...state.workflows };

		const unpublishedWorkflows = normalizeBasic("workflows", action.workflows);

		Object.keys(unpublishedWorkflows).forEach((workflowKey) => {
			if (!nextWorkflows[workflowKey]) {
				nextWorkflows[workflowKey] = unpublishedWorkflows[workflowKey];

				return;
			}

			nextWorkflows[workflowKey] = {
				...unpublishedWorkflows[workflowKey],
				...nextWorkflows[workflowKey],
			};
		});

		return {
			...state,
			workflows: nextWorkflows,
			loadStates: {
				...state.loadStates,
				workingWorkflows: LoadState.LOADED,
			},
		};
	}

	if (action.type === SET_WORKFLOW) {
		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflow.key]: {
					...action.workflow,
				},
			}),
		};
	}

	if (action.type === SET_WORKFLOW_PARTS) {
		const {
			steps,
			elementConnections,
			stepRoutes,
			elementRouteConditions,
			stepContentElements,
			conditionalContentConditions,
			conditionalContentElements,
		} = normalizeSteps(action.steps);

		const { actions, actionConnections, actionRoutes, actionRouteConditions } = normalizeActions(
			action.actions
		);

		const { delays, delayConnections, delayRoutes } = normalizeDelays(action.delays);

		const workflowSubWorkflows = normalizeBasic("subWorkflows", action.subWorkflows);
		const workflowConnectors = normalizeBasic("connectors", action.connectors);
		const workflowEnds = normalizeBasic("ends", action.ends);
		const externalEndpoints = normalizeBasic("externalEndpoints", action.externalEndpoints);

		return {
			...state,
			selectedWorkflowKey: action.workflowKey,
			editor: {
				...state.editor,
			},
			startMarkers: Object.assign({}, state.startMarkers, {
				[action.workflowKey]: action.startMarker,
			}),
			connectors: Object.assign({}, state.connectors, workflowConnectors),
			steps: Object.assign({}, state.steps, steps),
			ends: Object.assign({}, state.ends, workflowEnds),
			subWorkflowConnectors: Object.assign({}, state.subWorkflowConnectors, workflowSubWorkflows),
			actions: Object.assign({}, state.actions, actions),
			delays: Object.assign({}, state.delays, delays),
			elementConnections: Object.assign(
				{},
				state.elementConnections,
				elementConnections,
				actionConnections,
				delayConnections
			),
			elementRoutes: Object.assign({}, state.elementRoutes, stepRoutes, actionRoutes, delayRoutes),
			elementRouteConditions: Object.assign(
				{},
				state.elementRouteConditions,
				elementRouteConditions,
				actionRouteConditions
			),
			stepContentElements: Object.assign(
				{},
				state.stepContentElements,
				convertTextContentToRichText(stepContentElements),
				convertTextContentToRichText(conditionalContentElements)
			),
			conditionalContentConditions: Object.assign(
				{},
				state.conditionalContentConditions,
				conditionalContentConditions
			),
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					loaded: true,
					connectors: (workflowConnectors && Object.keys(workflowConnectors)) || [],
					steps: (steps && Object.keys(steps)) || [],
					actions: (actions && Object.keys(actions)) || [],
					subWorkflowConnectors: (workflowSubWorkflows && Object.keys(workflowSubWorkflows)) || [],
					delays: (delays && Object.keys(delays)) || [],
					ends: (workflowEnds && Object.keys(workflowEnds)) || [],
				},
			}),
			externalEndpoints: Object.assign({}, state.externalEndpoints, externalEndpoints),
			loadStates: {
				...state.loadStates,
				publishedWorkflows: LoadState.LOADED,
			},
		};
	}

	if (action.type === CREATE_WORKFLOW) {
		return {
			...state,
			startMarkers: Object.assign({}, state.startMarkers, {
				[action.workflow.key]: action.startMarker,
			}),
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			workflows: Object.assign({}, state.workflows, { [action.workflow.key]: action.workflow }),
		};
	}

	if (action.type === SET_WORKFLOW_HAS_CHANGED) {
		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					unpublishedChanges: true,
				},
			}),
		};
	}

	if (action.type === UPDATE_WORKFLOW_STATUS) {
		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					status: action.status,
				},
			}),
		};
	}

	if (action.type === UPDATE_WORKFLOW) {
		return {
			...state,
			workflows: Object.assign({}, state.workflows, { [action.workflow.key]: action.workflow }),
		};
	}

	if (action.type === PUBLISH_WORKFLOW_PENDING) {
		return {
			...state,
			editor: {
				...state.editor,
				publishing: true,
			},
		};
	}

	if (action.type === PUBLISH_WORKFLOW_COMPLETE) {
		return {
			...state,
			editor: {
				...state.editor,
				publishing: false,
			},
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					unpublishedChanges: false,
				},
			}),
		};
	}

	if (action.type === UPDATE_START_MARKER) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedRouteKey: null,
			},
			startMarkers: Object.assign({}, state.startMarkers, {
				[action.workflowKey]: {
					...state.startMarkers[action.workflowKey],
					...action.startMarker,
				},
			}),
		};
	}

	if (action.type === RESET_WORKFLOW_EDITOR) {
		return {
			...state,
			editor: {
				publishing: false,
				selectedElement: {
					key: null,
					type: null,
				},
				selectedRouteKey: null,
				selectedSectionKey: null,
				contentElementKeyInEditMode: null,
				selectedFormFieldKey: null,
				elementKeyToReorder: null,
				hoveredElement: null,
				draggingElement: null,
				diagramOffset: { x: 0, y: 0 },
				zoomedPercent: 100,
			},
		};
	}

	if (action.type === CREATE_ACTION) {
		const workflow = state.workflows[action.workflowKey];

		const root =
			workflow.root === null && action.connector.routeKey === StartMarkerKey
				? { type: "actions", key: action.action.key }
				: workflow.root;

		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...workflow,
					root,
					connectors: [...workflow.connectors, action.connector.key],
					actions: [...workflow.actions, action.action.key],
				},
			}),
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			actions: Object.assign({}, state.actions, { [action.action.key]: action.action }),
			elementConnections: Object.assign({}, state.elementConnections, {
				[action.connection.key]: action.connection,
			}),
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.route.key]: action.route,
			}),
		};
	}

	if (action.type === DELETE_ACTION) {
		let actions = Object.assign({}, state.actions);
		let elementConnections = Object.assign({}, state.elementConnections);
		let elementRoutes = Object.assign({}, state.elementRoutes);
		let connectors = Object.assign({}, state.connectors);

		const actionElement = actions[action.key];

		let connectorsToDelete: Array<string> = [];

		elementConnections[actionElement.connectionKey].routes.forEach((routeKey: string) => {
			if (elementRoutes[routeKey].to.type === ElementType.Connector) {
				connectorsToDelete.push(elementRoutes[routeKey].to.key);
				delete connectors[elementRoutes[routeKey].to.key];
			}

			delete elementRoutes[routeKey];
		});

		delete elementConnections[actionElement.connectionKey];
		delete actions[action.key];

		const workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				actions: state.workflows[action.workflowKey].actions.filter((key) => key !== action.key),
				connectors: state.workflows[action.workflowKey].connectors.filter(
					(key) => !connectorsToDelete.includes(key)
				),
			},
		});

		let nextState = {
			...state,
			connectors,
			actions,
			elementConnections,
			elementRoutes,
			workflows,
		};

		if (state.editor.selectedElement.key === action.key) {
			nextState.editor = Object.assign({}, state.editor, {
				selectedElement: { key: null, type: null },
			});
		}

		return nextState;
	}

	if (action.type === CREATE_STEP) {
		const workflow = state.workflows[action.workflowKey];

		const root =
			workflow.root === null && action.connector.routeKey === StartMarkerKey
				? { type: "steps", key: action.step.key }
				: workflow.root;

		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...workflow,
					root,
					connectors: [...workflow.connectors, action.connector.key],
					steps: [...workflow.steps, action.step.key],
				},
			}),
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			steps: Object.assign({}, state.steps, { [action.step.key]: action.step }),
			elementConnections: Object.assign({}, state.elementConnections, {
				[action.connection.key]: action.connection,
			}),
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.route.key]: action.route,
			}),
		};
	}

	if (action.type === UPDATE_STEP_NAME) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.key]: {
					...state.steps[action.key],
					name: action.name,
					wordOffsetsMultiLine: action.wordOffsetsMultiLine,
					width: action.width,
					height: action.height,
					coordX: action.coordX,
					coordY: action.coordY,
				},
			}),
		};
	}

	if (action.type === DELETE_STEP) {
		let steps = Object.assign({}, state.steps);
		let elementConnections = Object.assign({}, state.elementConnections);
		let stepContentElements = Object.assign({}, state.stepContentElements);
		let connectors = Object.assign({}, state.connectors);
		let elementRoutes = Object.assign({}, state.elementRoutes);
		let elementRouteConditions = Object.assign({}, state.elementRouteConditions);
		let connectorsToDelete: Array<string> = [];

		const step = steps[action.key];

		elementConnections[step.connectionKey].routes.forEach((routeKey: string) => {
			if (elementRoutes[routeKey].to.type === ElementType.Connector) {
				connectorsToDelete.push(elementRoutes[routeKey].to.key);
				delete connectors[elementRoutes[routeKey].to.key];
			}

			elementRoutes[routeKey].conditions.forEach((conditionKey) => {
				delete elementRouteConditions[conditionKey];
			});

			delete elementRoutes[routeKey];
		});

		delete elementConnections[step.connectionKey];
		delete steps[action.key];

		const workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				steps: state.workflows[action.workflowKey].steps.filter((key) => key !== action.key),
				connectors: state.workflows[action.workflowKey].connectors.filter(
					(key) => !connectorsToDelete.includes(key)
				),
			},
		});

		let nextState = {
			...state,
			connectors,
			steps,
			elementConnections,
			elementRoutes,
			elementRouteConditions,
			stepContentElements,
			workflows,
		};

		if (state.editor.selectedElement.key === action.key) {
			nextState.editor = Object.assign({}, state.editor, {
				selectedElement: { key: null, type: null },
			});
		}

		return nextState;
	}

	if (action.type === REMOVE_ROUTE_CONNECTION) {
		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					connectors: [...state.workflows[action.workflowKey].connectors, action.connector.key],
				},
			}),
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.routeKey]: {
					...state.elementRoutes[action.routeKey],
					to: {
						key: action.connector.key,
						type: "connector",
						direction: action.connector.direction,
					},
				},
			}),
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			editor: {
				...state.editor,
				selectedRouteKey: null,
			},
		};
	}

	if (action.type === CHANGE_STEP_CONNECTION_TYPE) {
		let elementRoutes = Object.assign({}, state.elementRoutes);
		let connectors = Object.assign({}, state.connectors);
		let workflows = Object.assign({}, state.workflows);

		action.routes.forEach((routeKey: string) => {
			const route = state.elementRoutes[routeKey];
			if (route.to.type === ElementType.Connector) {
				delete connectors[route.to.key];

				// remove stale connection keys from workflow
				workflows[action.workflowKey].connectors = workflows[action.workflowKey].connectors.filter(
					(connectorKey) => connectorKey !== route.to.key
				);
			}

			delete elementRoutes[routeKey];
		});

		return {
			...state,
			editor: {
				...state.editor,
				selectedRouteKey: null,
			},
			connectors,
			elementConnections: Object.assign({}, state.elementConnections, {
				[action.connection.key]: action.connection,
			}),
			elementRoutes,
		};
	}

	if (action.type === SELECT_CONNECTION_FIELD) {
		let connectors = Object.assign({}, state.connectors, action.connectors);
		let elementRoutes = Object.assign({}, state.elementRoutes, action.routes);
		let prevRouteKeys = state.elementConnections[action.connection.key].routes;
		let workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				connectors: [
					...state.workflows[action.workflowKey].connectors,
					...Object.keys(action.connectors),
				],
			},
		});

		prevRouteKeys.forEach((routeKey: string) => {
			let route = state.elementRoutes[routeKey];
			if (route.to.type === ElementType.Connector) {
				delete connectors[route.to.key];
				// remove stale connection keys from workflow
				workflows[action.workflowKey].connectors = workflows[action.workflowKey].connectors.filter(
					(connectorKey) => connectorKey !== route.to.key
				);
			}

			delete elementRoutes[routeKey];
		});

		return {
			...state,
			elementConnections: Object.assign({}, state.elementConnections, {
				[action.connection.key]: action.connection,
			}),
			connectors,
			elementRoutes,
			workflows,
		};
	}

	if (action.type === ADD_ELEMENT_TO_PREV_ROUTE) {
		if (action.route.from.type === ElementType.Step) {
			const connection = state.elementConnections[state.steps[action.route.from.key].connectionKey];

			if (connection.type === ConnectionTypes.CONDITIONAL) {
				return {
					...state,
					elementRoutes: Object.assign({}, state.elementRoutes, {
						[action.route.key]: {
							...action.route,
							to: {
								key: action.key,
								type: action.elementType,
								direction: state.elementRoutes[action.route.key].to.direction,
							},
							conditionsSettings: {
								...state.elementRoutes[action.route.key].conditionsSettings,
								routeToDirection: action.nextDirection,
							},
						},
					}),
				};
			}
		}

		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.route.key]: {
					...action.route,
					to: {
						key: action.key,
						type: action.elementType,
						direction: action.nextDirection,
					},
				},
			}),
		};
	}

	if (action.type === UPDATE_WORKFLOW_CONNECTION_ROUTE) {
		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.route.key]: action.route,
			}),
		};
	}

	if (action.type === UPDATE_ROUTE_CONDITION) {
		return {
			...state,
			elementRouteConditions: Object.assign({}, state.elementRouteConditions, {
				[action.condition.key]: action.condition,
			}),
		};
	}

	if (action.type === DELETE_ROUTE_CONDITION) {
		let elementRoutes = { ...state.elementRoutes };
		let elementRouteConditions = { ...state.elementRouteConditions };

		delete elementRouteConditions[action.key];

		elementRoutes[action.routeKey].conditions = elementRoutes[action.routeKey].conditions.filter(
			(key) => key !== action.key
		);

		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, elementRoutes),
			elementRouteConditions: Object.assign({}, elementRouteConditions),
		};
	}

	if (action.type === ADD_ROUTE_CONDITION) {
		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.routeKey]: {
					...state.elementRoutes[action.routeKey],
					conditions: [...state.elementRoutes[action.routeKey].conditions, action.condition.key],
				},
			}),
			elementRouteConditions: Object.assign({}, state.elementRouteConditions, {
				[action.condition.key]: action.condition,
			}),
		};
	}

	if (action.type === DELETE_ROUTE) {
		let elementRoutes = Object.assign({}, state.elementRoutes);
		const elementRoute = elementRoutes[action.routeKey] as ElementRoute;

		let nextState = {
			...state,
		};

		if (elementRoute.conditions.length) {
			let elementRouteConditions = Object.assign({}, state.elementRouteConditions);
			elementRoute.conditions.forEach((conditionKey) => delete elementRouteConditions[conditionKey]);

			nextState.elementRouteConditions = elementRouteConditions;
		}

		if (elementRoute.from.type === ElementType.Step || elementRoute.from.type === ElementType.Action) {
			// @ts-ignore
			const connectionKey = state[`${elementRoute.from.type}s`][elementRoute.from.key].connectionKey;

			nextState.elementConnections = Object.assign({}, state.elementConnections, {
				[connectionKey]: {
					...state.elementConnections[connectionKey],
					routes: state.elementConnections[connectionKey].routes.filter(
						(routeKey) => routeKey !== action.routeKey
					),
				},
			});
		}

		if (elementRoute.to.type === ElementType.Connector) {
			let connectors = Object.assign({}, state.connectors);

			delete connectors[elementRoute.to.key];

			nextState.connectors = connectors;
			nextState.workflows = Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					connectors: state.workflows[action.workflowKey].connectors.filter(
						(connectorKey) => connectorKey !== elementRoute.to.key
					),
				},
			});
		}

		delete elementRoutes[action.routeKey];

		nextState.elementRoutes = elementRoutes;

		return nextState;
	}

	if (action.type === UPDATE_WORKFLOW_ROOT) {
		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					root: action.root,
				},
			}),
		};
	}

	if (action.type === PAN_DIAGRAM) {
		return {
			...state,
			editor: {
				...state.editor,
				diagramOffset: action.offset,
			},
		};
	}

	if (action.type === SELECT_ELEMENT) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedElement: {
					key: action.elementKey,
					type: action.elementType,
				},
				selectedSectionKey: null,
			},
		};
	}

	if (action.type === DESELECT_ELEMENT) {
		if (action.full !== undefined && action.full) {
			// Clicked on the canvas
			return {
				...state,
				editor: {
					...state.editor,
					selectedSectionKey: null,
					contentElementKeyInEditMode: null,
					parentConditionalElementKeyInEditMode: null,
					selectedElement: {
						key: null,
						type: null,
					},
					selectedFormFieldKey: null,
				},
			};
		}

		if (state.editor.parentConditionalElementKeyInEditMode) {
			if (state.editor.selectedSectionKey === "contentForm") {
				return {
					...state,
					editor: {
						...state.editor,
						selectedSectionKey: "conditionalContent",
						contentElementKeyInEditMode: null,
					},
				};
			}

			return {
				...state,
				editor: {
					...state.editor,
					selectedSectionKey: "content",
					parentConditionalElementKeyInEditMode: null,
					contentElementKeyInEditMode: null,
				},
			};
		}

		if (state.editor.selectedSectionKey) {
			let nextSelectedSectionKey = null;

			if (state.editor.selectedSectionKey === "contentForm") {
				nextSelectedSectionKey = "content";
			}

			let nextState = {
				...state,
				editor: {
					...state.editor,
					selectedSectionKey: nextSelectedSectionKey,
					contentElementKeyInEditMode: null,
				},
			};

			// if (
			// 	nextSelectedSectionKey === "content" &&
			// 	state.editor.parentConditionalElementKeyInEditMode !== null
			// ) {
			// 	// @ts-ignore
			// 	nextState.editor.contentElementKeyInEditMode = state.editor.contentElementKeyInEditMode;
			// }

			if (nextSelectedSectionKey === null) {
				nextState.editor.parentConditionalElementKeyInEditMode = null;
			}

			return nextState;
		}

		return {
			...state,
			editor: {
				...state.editor,
				selectedElement: {
					key: null,
					type: null,
				},
				selectedSectionKey: null,
				contentElementKeyInEditMode: null,
				parentConditionalElementKeyInEditMode: null,
			},
		};
	}

	if (action.type === SELECT_ROUTE) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedRouteKey: action.routeKey,
			},
		};
	}

	if (action.type === DESELECT_ROUTE) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedRouteKey: null,
			},
		};
	}

	if (action.type === UPDATE_SELECTED_STEP_SECTION) {
		let nextState = {
			...state,
			editor: {
				...state.editor,
				selectedSectionKey: action.selectedSectionKey,
				selectedFormFieldKey: null,
				contentElementKeyInEditMode: null,
			},
		};

		if (action.selectedSectionKey === "content") {
			nextState.editor.parentConditionalElementKeyInEditMode = null;
		}

		return nextState;
	}

	if (action.type === SHOW_MULTI_CONNECTION_SETUP) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedSectionKey: "connectionMulti",
				selectedElement: {
					key: action.stepKey,
					type: ElementType.Step,
				},
				selectedFormFieldKey: null,
				contentElementKeyInEditMode: null,
				parentConditionalElementKeyInEditMode: null,
			},
		};
	}

	if (action.type === SHOW_FORM_EDITOR_SIDEBAR) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedSectionKey: "contentForm",
			},
		};
	}

	if (action.type === SELECT_CONDITIONAL_ELEMENT) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedSectionKey: "conditionalContent",
				parentConditionalElementKeyInEditMode: action.elementKey,
			},
		};
	}

	if (action.type === ADD_ROUTE_WITH_CONNECTOR) {
		let nextState = {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.route.key]: action.route,
			}),
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					connectors: [...state.workflows[action.workflowKey].connectors, action.connector.key],
				},
			}),
			elementConnections: Object.assign({}, state.elementConnections, {
				[action.connectionKey]: {
					...state.elementConnections[action.connectionKey],
					routes: [...state.elementConnections[action.connectionKey].routes, action.route.key],
				},
			}),
		};

		if (action.condition) {
			nextState.elementRouteConditions = Object.assign({}, state.elementRouteConditions, {
				[action.condition.key]: action.condition,
			});
		}

		return nextState;
	}

	if (action.type === REMOVE_CONNECTOR) {
		let connectors = Object.assign({}, state.connectors);
		delete connectors[action.connectorKey];

		const workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				connectors: state.workflows[action.workflowKey].connectors.filter(
					(key) => key !== action.connectorKey
				),
			},
		});

		return {
			...state,
			connectors,
			workflows,
		};
	}

	if (action.type === CREATE_CONNECTOR) {
		return {
			...state,
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...state.workflows[action.workflowKey],
					connectors: [...state.workflows[action.workflowKey].connectors, action.connector.key],
				},
			}),
		};
	}

	if (action.type === MOVE_CONNECTOR) {
		return {
			...state,
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
		};
	}

	if (action.type === MOVE_STEP || action.type === MOVE_ACTION) {
		let connectors = { ...state.connectors };
		let routes = { ...state.elementRoutes };

		Object.keys(action.connectors).forEach((connectorKey) => {
			connectors[connectorKey] = {
				...connectors[connectorKey],
				coordX: Math.round(action.connectors[connectorKey].x),
				coordY: Math.round(action.connectors[connectorKey].y),
			};
		});

		Object.keys(action.conditions).forEach((routeKey) => {
			routes[routeKey] = {
				...routes[routeKey],
				conditionsSettings: {
					...(routes[routeKey].conditionsSettings as ConditionsSettings),
					coordX: Math.round(action.conditions[routeKey].x),
					coordY: Math.round(action.conditions[routeKey].y),
				},
			};
		});

		let nextState = {
			...state,
			// @ts-ignore
			[action.storeRef]: Object.assign({}, state[action.storeRef], {
				[action.element.key]: action.element,
			}),
			editor: {
				...state.editor,
				draggingElement: {
					...state.editor.draggingElement,
					coords: {
						x: action.element.coordX,
						y: action.element.coordY,
					},
				},
			},
		};

		if (Object.keys(action.connectors).length) {
			nextState.connectors = Object.assign({}, state.connectors, connectors);
		}

		if (Object.keys(action.conditions).length) {
			nextState.elementRoutes = Object.assign({}, state.elementRoutes, routes);
		}

		const connection = { ...state.elementConnections[action.element.connectionKey] };

		if (connection.type === ConnectionTypes.MULTIPLE_CHOICE) {
			// Updates the decision label coords
			nextState.elementConnections = Object.assign({}, state.elementConnections, {
				[action.element.connectionKey]: {
					...connection,
					details: {
						...connection.details,
						coordX: Math.round(
							connection.details.coordX + (action.element.coordX - connection.details.coordX)
						),
						coordY: Math.round(
							connection.details.coordY + (action.element.coordY - connection.details.coordY)
						),
					},
				},
			});
		}

		return nextState;
	}

	if (action.type === CREATE_DELAY) {
		const workflow = state.workflows[action.workflowKey];

		return {
			...state,
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...workflow,
					connectors: [...workflow.connectors, action.connector.key],
					delays: [...workflow.delays, action.delay.key],
				},
			}),
			connectors: Object.assign({}, state.connectors, { [action.connector.key]: action.connector }),
			delays: Object.assign({}, state.delays, { [action.delay.key]: action.delay }),
			elementConnections: Object.assign({}, state.elementConnections, {
				[action.connection.key]: action.connection,
			}),
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.route.key]: action.route,
			}),
		};
	}

	if (action.type === DELETE_DELAY) {
		let delays = Object.assign({}, state.delays);
		let elementConnections = Object.assign({}, state.elementConnections);
		let elementRoutes = Object.assign({}, state.elementRoutes);
		let connectors = Object.assign({}, state.connectors);

		const delayElement = delays[action.key];

		let connectorsToDelete: Array<string> = [];

		// @ts-ignore
		elementConnections[delayElement.connectionKey].routes.forEach((routeKey: string) => {
			if (elementRoutes[routeKey].to.type === ElementType.Connector) {
				connectorsToDelete.push(elementRoutes[routeKey].to.key);
				delete connectors[elementRoutes[routeKey].to.key];
			}

			delete elementRoutes[routeKey];
		});

		// @ts-ignore
		delete elementConnections[delayElement.connectionKey];
		delete delays[action.key];

		const workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				delays: state.workflows[action.workflowKey].delays.filter((key) => key !== action.key),
				connectors: state.workflows[action.workflowKey].connectors.filter(
					(key) => !connectorsToDelete.includes(key)
				),
			},
		});

		let nextState = {
			...state,
			connectors,
			delays,
			elementConnections,
			elementRoutes,
			workflows,
		};

		if (state.editor.selectedElement.key === action.key) {
			nextState.editor = Object.assign({}, state.editor, {
				selectedElement: { key: null, type: null },
			});
		}

		return nextState;
	}

	if (action.type === UPDATE_DELAY) {
		return {
			...state,
			delays: Object.assign({}, state.delays, {
				[action.delay.key]: action.delay,
			}),
		};
	}

	if (action.type === MOVE_DELAY) {
		let connectors = { ...state.connectors };

		Object.keys(action.connectors).forEach((connectorKey) => {
			connectors[connectorKey] = {
				...connectors[connectorKey],
				coordX: Math.round(action.connectors[connectorKey].x),
				coordY: Math.round(action.connectors[connectorKey].y),
			};
		});

		let nextState = {
			...state,
			// @ts-ignore
			[action.storeRef]: Object.assign({}, state[action.storeRef], {
				[action.element.key]: action.element,
			}),
			editor: {
				...state.editor,
				draggingElement: {
					...state.editor.draggingElement,
					coords: {
						x: action.element.coordX,
						y: action.element.coordY,
					},
				},
			},
		};

		if (Object.keys(action.connectors).length) {
			nextState.connectors = Object.assign({}, state.connectors, connectors);
		}

		return nextState;
	}

	if (action.type === MOVE_ROUTE_LABEL) {
		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.routeKey]: {
					...state.elementRoutes[action.routeKey],
					label: {
						...state.elementRoutes[action.routeKey].label,
						coordX: action.coordX,
						coordY: action.coordY,
					},
				},
			}),
		};
	}

	if (action.type === SET_USE_CUSTOM_LABEL_POSITION) {
		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.routeKey]: {
					...state.elementRoutes[action.routeKey],
					label: {
						...state.elementRoutes[action.routeKey].label,
						coordX: action.coordX,
						coordY: action.coordY,
						customPlacement: true,
					},
				},
			}),
		};
	}

	if (action.type === MOVE_ROUTE_CONDITION) {
		let connectors = { ...state.connectors };

		Object.keys(action.connectors).forEach((connectorKey) => {
			connectors[connectorKey] = {
				...connectors[connectorKey],
				coordX: Math.round(action.connectors[connectorKey].x),
				coordY: Math.round(action.connectors[connectorKey].y),
			};
		});

		let nextState = {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.key]: {
					...state.elementRoutes[action.key],
					conditionsSettings: {
						...state.elementRoutes[action.key].conditionsSettings,
						coordX: Math.round(action.coordX),
						coordY: Math.round(action.coordY),
					},
				},
			}),
		};

		if (Object.keys(action.connectors).length) {
			nextState.connectors = Object.assign({}, state.connectors, connectors);
		}

		return nextState;
	}

	if (action.type === UPDATE_ROUTE_CONDITION_DIRECTION) {
		let update =
			action.point === "to"
				? { routeToDirection: action.direction }
				: { routeFromDirection: action.direction };

		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.routeKey]: {
					...state.elementRoutes[action.routeKey],
					conditionsSettings: {
						...state.elementRoutes[action.routeKey].conditionsSettings,
						...update,
					},
				},
			}),
		};
	}

	if (action.type === UPDATE_ROUTE_CONDITION_JOIN_TYPE) {
		return {
			...state,
			elementRoutes: Object.assign({}, state.elementRoutes, {
				[action.routeKey]: {
					...state.elementRoutes[action.routeKey],
					conditionsSettings: {
						...state.elementRoutes[action.routeKey].conditionsSettings,
						joinType: action.joinType,
					},
				},
			}),
		};
	}

	if (action.type === MOVE_SUB_WORKFLOW_CONNECTOR) {
		return {
			...state,
			subWorkflowConnectors: Object.assign({}, state.subWorkflowConnectors, {
				[action.subWorkflow.key]: action.subWorkflow,
			}),
			editor: {
				...state.editor,
				draggingElement: {
					...state.editor.draggingElement,
					coords: {
						x: action.subWorkflow.coordX,
						y: action.subWorkflow.coordY,
					},
				},
			},
		};
	}

	if (action.type === MOVE_START_MARKER) {
		let nextState = {
			...state,
			startMarkers: Object.assign({}, state.startMarkers, {
				[action.workflowKey]: action.startMarker,
			}),
		};

		if (action.connector) {
			nextState.connectors = Object.assign({}, state.connectors, {
				[action.connector.key]: action.connector,
			});
		}

		return nextState;
	}

	if (action.type === UPDATE_START_MARKER_ROUTE_DIRECTION) {
		const { type, ...rest } = action;

		return {
			...state,
			startMarkers: Object.assign({}, state.startMarkers, {
				[action.workflowKey]: {
					...state.startMarkers[action.workflowKey],
					...rest,
				},
			}),
		};
	}

	if (action.type === ELEMENT_DRAG_BEGIN) {
		const { type, ...rest } = action;
		return {
			...state,
			editor: {
				...state.editor,
				draggingElement: rest,
			},
		};
	}

	if (action.type === ELEMENT_DRAG_END) {
		return {
			...state,
			editor: {
				...state.editor,
				draggingElement: null,
			},
		};
	}

	if (action.type === ELEMENT_HOVER_BEGIN) {
		return {
			...state,
			editor: {
				...state.editor,
				hoveredElement: {
					key: action.key,
					type: action.elementType,
				},
			},
		};
	}

	if (action.type === ELEMENT_HOVER_END) {
		return {
			...state,
			editor: {
				...state.editor,
				hoveredElement: null,
			},
		};
	}

	if (action.type === UPDATE_EDITOR_ZOOMED_PERCENT) {
		return {
			...state,
			editor: {
				...state.editor,
				zoomedPercent: action.amount,
			},
		};
	}

	if (action.type === CREATE_FORM_FIELD) {
		return {
			...state,
			formFields: Object.assign({}, state.formFields, { [action.field.key]: action.field }),
		};
	}

	if (action.type === CREATE_FIELD || action.type === UPDATE_FIELD) {
		return {
			...state,
			formFields: Object.assign({}, state.formFields, {
				[action.field.key]: action.field,
			}),
		};
	}

	if (action.type === DELETE_FIELD) {
		let formFields = { ...state.formFields };

		formFields[action.fieldKey].deletedAt = moment().toISOString();

		return {
			...state,
			formFields,
		};
	}

	if (action.type === UPDATE_FORM_DETAILS) {
		const stepElementContentKey = state.editor.contentElementKeyInEditMode as string;

		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[stepElementContentKey]: {
					...state.stepContentElements[stepElementContentKey],
					details: action.details,
				},
			}),
		};
	}

	if (action.type === UPDATE_FORM_LAYOUT) {
		const stepElementContentKey = state.editor.contentElementKeyInEditMode as string;

		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[stepElementContentKey]: {
					...state.stepContentElements[stepElementContentKey],
					details: {
						...state.stepContentElements[stepElementContentKey].details,
						layout: action.layout,
					},
				},
			}),
		};
	}

	if (action.type === UPDATE_FORM_ACCESS) {
		const stepElementContentKey = state.editor.contentElementKeyInEditMode as string;

		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[stepElementContentKey]: {
					...state.stepContentElements[stepElementContentKey],
					details: {
						...state.stepContentElements[stepElementContentKey].details,
						access: action.access,
					},
				},
			}),
		};
	}

	if (action.type === SELECT_FORM_FIELD) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedFormFieldKey: action.fieldKey,
			},
		};
	}

	if (action.type === SELECT_FORM_ELEMENT) {
		return {
			...state,
			editor: {
				...state.editor,
				selectedSectionKey: "contentForm",
				contentElementKeyInEditMode: action.formKey,
			},
		};
	}

	if (action.type === CHANGE_STEP_ACCESS) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.stepKey]: {
					...state.steps[action.stepKey],
					access: action.access,
				},
			}),
		};
	}

	if (action.type === CHANGE_TIMEFRAME) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.stepKey]: {
					...state.steps[action.stepKey],
					timeframe: action.timeframe,
				},
			}),
		};
	}

	if (action.type === CREATE_END) {
		const workflow = state.workflows[action.workflowKey];

		return {
			...state,
			ends: Object.assign({}, state.ends, { [action.end.key]: action.end }),
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...workflow,
					ends: [...workflow.ends, action.end.key],
				},
			}),
		};
	}

	if (action.type === REMOVE_END_PIECE) {
		let endPieces = { ...state.ends };
		delete endPieces[action.key];

		const workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				ends: state.workflows[action.workflowKey].ends.filter((key) => key !== action.key),
			},
		});

		return {
			...state,
			workflows,
			ends: endPieces,
		};
	}

	if (action.type === MOVE_END_PIECE) {
		return {
			...state,
			ends: Object.assign({}, state.ends, { [action.endPiece.key]: action.endPiece }),
			editor: {
				...state.editor,
				draggingElement: {
					...state.editor.draggingElement,
					coords: {
						x: action.endPiece.coordX,
						y: action.endPiece.coordY,
					},
				},
			},
		};
	}

	if (action.type === CREATE_WORKFLOW_CATEGORY || action.type === UPDATE_WORKFLOW_CATEGORY) {
		return {
			...state,
			workflowCategories: Object.assign({}, state.workflowCategories, {
				[action.category.key]: action.category,
			}),
		};
	}

	if (action.type === DELETE_WORKFLOW_CATEGORY) {
		let workflowCategories = { ...state.workflowCategories };

		delete workflowCategories[action.key];

		return {
			...state,
			workflowCategories,
		};
	}

	if (action.type === CREATE_SUB_WORKFLOW_CONNECTOR) {
		const workflow = state.workflows[action.workflowKey];

		return {
			...state,
			subWorkflowConnectors: Object.assign({}, state.subWorkflowConnectors, {
				[action.subWorkflow.key]: action.subWorkflow,
			}),
			workflows: Object.assign({}, state.workflows, {
				[action.workflowKey]: {
					...workflow,
					subWorkflowConnectors: [...workflow.subWorkflowConnectors, action.subWorkflow.key],
				},
			}),
		};
	}

	if (action.type === UPDATE_SUB_WORKFLOW_CONNECTOR) {
		return {
			...state,
			subWorkflowConnectors: Object.assign({}, state.subWorkflowConnectors, {
				[action.subWorkflow.key]: action.subWorkflow,
			}),
		};
	}

	if (action.type === DELETE_SUB_WORKFLOW_CONNECTOR) {
		let subWorkflowConnectors = Object.assign({}, state.subWorkflowConnectors);
		delete subWorkflowConnectors[action.key];

		const workflows = Object.assign({}, state.workflows, {
			[action.workflowKey]: {
				...state.workflows[action.workflowKey],
				subWorkflowConnectors: state.workflows[action.workflowKey].subWorkflowConnectors.filter(
					(key) => key !== action.key
				),
			},
		});

		let nextState = {
			...state,
			subWorkflowConnectors,
			workflows,
		};

		if (state.editor.selectedElement.key === action.key) {
			nextState.editor = Object.assign({}, state.editor, {
				selectedElement: { key: null, type: null },
			});
		}

		return nextState;
	}

	if (action.type === UPDATE_CONTENT_ELEMENT_EDIT_MODE) {
		return {
			...state,
			editor: {
				...state.editor,
				contentElementKeyInEditMode: action.elementKey,
			},
		};
	}

	if (action.type === CREATE_CONTENT_ELEMENT) {
		// need to add to steps content key
		const stepKey = action.contentElement.stepKey;
		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[action.contentElement.key]: action.contentElement,
			}),
			steps: Object.assign({}, state.steps, {
				[stepKey]: {
					...state.steps[stepKey],
					content: [...state.steps[stepKey].content, action.contentElement.key],
				},
			}),
		};
	}

	if (action.type === CREATE_CONDITIONAL_CONTENT_CHILD) {
		return {
			...state,
			stepContentElements: Object.assign(
				{},
				state.stepContentElements,
				{
					[action.conditionalElementKey]: {
						...state.stepContentElements[action.conditionalElementKey],
						details: {
							...state.stepContentElements[action.conditionalElementKey].details,
							elements: [
								// @ts-ignore
								...state.stepContentElements[action.conditionalElementKey].details.elements,
								action.contentElement.key,
							],
						},
					},
				},
				{
					[action.contentElement.key]: action.contentElement,
				}
			),
		};
	}

	if (action.type === UPDATE_CONTENT_ELEMENT) {
		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[action.contentElement.key]: action.contentElement,
			}),
		};
	}

	if (action.type === MOVE_CONTENT_ELEMENT_THROTTLED) {
		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[action.elementKey]: {
					...state.stepContentElements[action.elementKey],
					order: action.nextPosition,
				},
			}),
		};
	}

	if (action.type === UNSET_PARENT_CONDITION_EDIT_MODE) {
		return {
			...state,
			editor: {
				...state.editor,
				parentConditionalElementKeyInEditMode: null,
			},
		};
	}

	if (action.type === DELETE_CONTENT_ELEMENT) {
		let stepContentElements = { ...state.stepContentElements };
		const type = stepContentElements[action.elementKey].type;

		if (
			state.editor.parentConditionalElementKeyInEditMode !== null &&
			type !== StepContentType.Conditional
		) {
			// deleting a content element within a conditional
			const parentKey = state.editor.parentConditionalElementKeyInEditMode;

			delete stepContentElements[action.elementKey];

			stepContentElements[parentKey] = {
				...stepContentElements[parentKey],
				details: {
					...stepContentElements[parentKey].details,
					elements: (
						stepContentElements[parentKey] as ConditionalContentElement
					).details.elements.filter((elementKey) => elementKey !== action.elementKey),
				},
			};

			return {
				...state,
				stepContentElements,
			};
		}

		let conditionalContentConditions = { ...state.conditionalContentConditions };

		if (type === StepContentType.Conditional) {
			(stepContentElements[action.elementKey] as ConditionalContentElement).details.elements.forEach(
				(elementKey) => {
					delete stepContentElements[elementKey];
				}
			);

			(stepContentElements[action.elementKey] as ConditionalContentElement).details.conditions.forEach(
				(conditionKey) => {
					delete conditionalContentConditions[conditionKey];
				}
			);
		}

		delete stepContentElements[action.elementKey];

		const stepKey = action.stepKey;

		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[stepKey]: {
					...state.steps[stepKey],
					content: state.steps[stepKey].content.filter((key) => key !== action.elementKey),
				},
			}),
			stepContentElements,
			conditionalContentConditions,
		};
	}

	if (action.type === SET_ELEMENT_TO_REORDER) {
		return {
			...state,
			editor: {
				...state.editor,
				elementKeyToReorder: action.elementKey,
			},
		};
	}

	if (action.type === ADD_CONTENT_CONDITION) {
		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[action.elementKey]: {
					...state.stepContentElements[action.elementKey],
					details: {
						...state.stepContentElements[action.elementKey].details,
						conditions: [
							// @ts-ignore
							...state.stepContentElements[action.elementKey].details.conditions,
							action.condition.key,
						],
					},
				},
			}),
			conditionalContentConditions: Object.assign({}, state.conditionalContentConditions, {
				[action.condition.key]: action.condition,
			}),
		};
	}

	if (action.type === UPDATE_CONTENT_CONDITION) {
		return {
			...state,
			conditionalContentConditions: Object.assign({}, state.conditionalContentConditions, {
				[action.condition.key]: action.condition,
			}),
		};
	}

	if (action.type === DELETE_CONTENT_CONDITION) {
		let stepContentElements = { ...state.stepContentElements };
		let conditionalContentConditions = { ...state.conditionalContentConditions };

		delete conditionalContentConditions[action.conditionKey];

		(stepContentElements[action.elementKey] as ConditionalContentElement).details.conditions = (
			stepContentElements[action.elementKey] as ConditionalContentElement
		).details.conditions.filter((key: string) => key !== action.conditionKey);

		return {
			...state,
			stepContentElements: Object.assign({}, stepContentElements),
			conditionalContentConditions: Object.assign({}, conditionalContentConditions),
		};
	}

	if (action.type === UPDATE_STEP_CONTENT_IMAGE_DETAILS) {
		return {
			...state,
			stepContentElements: Object.assign({}, state.stepContentElements, {
				[action.key]: {
					...state.stepContentElements[action.key],
					details: action.details,
				},
			}),
		};
	}

	if (action.type === UPDATE_ACTION) {
		return {
			...state,
			actions: Object.assign({}, state.actions, {
				[action.action.key]: action.action,
			}),
		};
	}

	if (action.type === DELETE_WORKFLOW) {
		const publishedVersion = convertToPublishedKey(action.workflowKey);

		let subWorkflowConnectors = { ...state.subWorkflowConnectors };
		const workflows = { ...state.workflows };

		workflows[action.workflowKey] = {
			...state.workflows[action.workflowKey],
			deletedAt: action.deletedAt,
		};

		if (workflows[publishedVersion] !== undefined) {
			workflows[publishedVersion] = {
				...state.workflows[publishedVersion],
				deletedAt: action.deletedAt,
			};

			Object.keys(subWorkflowConnectors).forEach((subWorkflowKey) => {
				if (subWorkflowConnectors[subWorkflowKey].connectedWorkflowKey === publishedVersion) {
					subWorkflowConnectors[subWorkflowKey].connectedWorkflowKey = null;
				}
			});
		}

		return {
			...state,
			workflows,
			subWorkflowConnectors,
		};
	}

	if (action.type === UPDATE_STEP_ASSIGNMENT_POOL) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.stepKey]: {
					...state.steps[action.stepKey],
					assignment: {
						...state.steps[action.stepKey].assignment,
						assignees: action.assignees,
					},
				},
			}),
		};
	}

	if (action.type === RESET_STEP_ASSIGNMENT_DISTRIBUTION) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.stepKey]: {
					...state.steps[action.stepKey],
					assignment: {
						...state.steps[action.stepKey].assignment,
						distribution: {
							type: "all",
							settings: {
								type: "one",
								amount: null,
							},
						},
					},
				},
			}),
		};
	}

	if (action.type === UPDATE_STEP_ASSIGNMENT_DISTRIBUTION) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.stepKey]: {
					...state.steps[action.stepKey],
					assignment: {
						...state.steps[action.stepKey].assignment,
						distribution: {
							type: action.distributionType,
							settings: action.settings,
						},
					},
				},
			}),
		};
	}

	if (action.type === DELETE_STEP_ASSIGNMENT_DISTRIBUTION) {
		return {
			...state,
			steps: Object.assign({}, state.steps, {
				[action.stepKey]: {
					...state.steps[action.stepKey],
					assignment: {
						...state.steps[action.stepKey].assignment,
						distribution: null,
					},
				},
			}),
		};
	}

	if (action.type === UPDATE_SELECTED_EXTRA_OPTIONS_KEY) {
		return {
			...state,
			local: {
				...state.local,
				workflowsList: {
					...state.local.workflowsList,
					selectedExtraOptionsKey: action.workflowKey,
				},
			},
		};
	}

	return state;
}
