import { combineEpics, Epic, ofType } from "redux-observable";
import { UPDATE_PRE_UPGRADE_RESOURCE } from "../../actionTypes";
import { debounceTime, map } from "rxjs/operators";
import { AnyAction } from "redux";
import { PreUpgradeData } from "design-system";
import { sendRequestToAPI } from "../../sharedActions";
import { BillingAPI } from "../BillingAPI";
import { InitialState } from "../../initialState";

const refreshInvoicePrices: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(UPDATE_PRE_UPGRADE_RESOURCE),
		debounceTime(250),
		map((action: AnyAction) => {
			const state = state$.value as InitialState;

			const lineItems: Array<{ id: string; quantity: number }> = [];

			Object.keys((state.preUpgradeData as PreUpgradeData).resources).forEach((id) => {
				lineItems.push({
					id,
					// @ts-ignore
					quantity: state.preUpgradeData.resources[id].selected,
				});
			});

			return sendRequestToAPI(BillingAPI.preparePrices(lineItems));
		})
	);
};

export default combineEpics(refreshInvoicePrices);
