import React, { useState } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { Account, AccountType, Button, Card, Checkbox, LongText, Spinner } from "design-system";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { forgetSavedApiResponse, sendCapturedRequestToAPI } from "../../../sharedActions";
import { CAPTURED_CLOSED_ACCOUNT } from "../../../capturedResponseTypes";
import { useListenForApiResponse } from "../../../utils/hooks/useListenForApiResponse";
import { InitialState } from "../../../initialState";
import { AccountAPI } from "../../../api/AccountAPI";

import styles from "./CloseAccount.module.scss";

interface Props extends RouteComponentProps {}

const experienceOptions = [
	{ key: "technicalIssues", label: "We experienced technical issues" },
	{ key: "notAFit", label: "Prestavi doesn’t fit our needs" },
	{ key: "tooExpensive", label: "Too expensive" },
	{ key: "learningCurve", label: "Too difficult to learn" },
	{ key: "competitor", label: "We are switching to a different solution" },
];

const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const CloseAccount: React.FunctionComponent<Props> = () => {
	const [experiences, setExperiences] = useState<Array<string>>([]);
	const [rating, setRating] = useState<number | null>(null);
	const [comments, setComments] = useState<string>("");
	const [processing, setProcessing] = useState(false);
	const account = useSelector((state: InitialState) => state.account);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useListenForApiResponse(CAPTURED_CLOSED_ACCOUNT, (response) => {
		dispatch(forgetSavedApiResponse(CAPTURED_CLOSED_ACCOUNT));
		setProcessing(false);

		if (response.status === "ok") {
			window.localStorage.removeItem("account_zone");
			window.location.replace("/login");

			return;
		}
	});

	function clickExperienceOption(key: string) {
		if (experiences.includes(key)) {
			setExperiences([...experiences.filter((option) => option != key)]);

			return;
		}

		setExperiences([...experiences, key]);
	}

	function closeAccount() {
		if (processing) {
			return;
		}

		setProcessing(true);

		dispatch(
			sendCapturedRequestToAPI(
				AccountAPI.closeAccount({ experiences, rating, comments }),
				CAPTURED_CLOSED_ACCOUNT
			)
		);
	}

	if ((account as Account).type === AccountType.Paid) {
		window.location.replace("/cancel");

		return null;
	}

	return (
		<div className={styles.CloseAccount}>
			<div className={styles.Header}>
				<h1>Close your Prestavi account</h1>
			</div>
			<Card maxWidth={600}>
				<div className={styles.Question}>
					<p style={{ marginBottom: "16px" }}>
						Which of these describes your experience with Prestavi?
					</p>

					<div>
						{experienceOptions.map((option) => (
							<div className={styles.ExperienceOption}>
								<Checkbox
									id={option.key}
									isChecked={experiences.includes(option.key)}
									onChange={() => clickExperienceOption(option.key)}
									label={option.label}
								/>
							</div>
						))}
					</div>
				</div>

				<div className={styles.Question}>
					<p>Overall, how would you rate your experience with Prestavi?</p>
					<span>With 1 being the lowest rating and 10 being the highest rating</span>

					<div className={styles.Ratings}>
						{ratings.map((ratingOption) => (
							<div
								onClick={() => setRating(ratingOption)}
								className={classNames([ratingOption === rating && styles.Selected])}
							>
								{ratingOption}
							</div>
						))}
					</div>
				</div>

				<div className={styles.Question}>
					<label htmlFor="comments">
						Comments <span>(optional)</span>
					</label>
					<LongText
						id="comments"
						value={comments}
						onChange={(event) => setComments((event.target as HTMLTextAreaElement).value)}
					/>
				</div>

				<div className={styles.RetentionPeriod}>
					<div className={styles.Actions}>
						<Button theme="solid" tone="medium" onClick={closeAccount}>
							{processing ? <Spinner size="small" color="white" /> : "Close Account"}
						</Button>
						<span onClick={() => navigate("/billing")}>Keep my account</span>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default CloseAccount;
