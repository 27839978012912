import { normalize, schema } from "normalizr";

export function normalizeKnowledgeBase(data: any) {
	const pages = new schema.Entity("pages", {}, { idAttribute: "key" });
	const subCategories = new schema.Entity("subCategories", {}, { idAttribute: "key" });

	subCategories.define({
		pages: [pages],
		subCategories: [subCategories],
	});

	const categories = new schema.Entity(
		"categories",
		{
			pages: [pages],
			subCategories: [subCategories],
		},
		{ idAttribute: "key" }
	);

	return normalize(data, [categories]).entities;
}
