import React from "react";
import { Job, LogItem } from "design-system";
import HistoryEvent from "./HistoryEvent";

interface Props {
	job: Job;
	workflowKey: string;
	events: Array<LogItem>;
	expandedElements: Array<string>;
	expandAll: boolean;
	expandElement: (elementKey: string) => void;
	collapseElement: (elementKey: string) => void;
}

const HistoryEventsByWorkflow: React.FunctionComponent<Props> = ({
	job,
	workflowKey,
	events,
	expandAll,
	expandedElements,
	collapseElement,
	expandElement,
}) => {
	function handleClick(logItem: LogItem) {
		if (logItem.event === "comment_created") {
			return;
		}

		if (expandedElements.includes(logItem.elementKey)) {
			return;
		}

		expandElement(logItem.elementKey);
	}

	const timelineEvents = events.map((logItem: LogItem, index) => {
		return (
			<HistoryEvent
				key={`${logItem.elementKey}_${logItem.timestamp}`}
				job={job}
				logItem={logItem}
				expandAll={expandAll}
				expandedElements={expandedElements}
				handleClick={handleClick}
				collapseElement={collapseElement}
			/>
		);
	});

	return <div>{timelineEvents}</div>;
};

export default HistoryEventsByWorkflow;
