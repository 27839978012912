import React from "react";
import { store } from "../../../../../store";
import { Workflow } from "design-system";

import styles from "./History.module.scss";

interface Props {
	workflowsCount: number;
	index: number;
	workflowKey: string;
}

const WorkflowCompletedMarker: React.FunctionComponent<Props> = ({ workflowsCount, index, workflowKey }) => {
	if (workflowsCount <= 1 || index === 0) {
		return null;
	}

	const workflow: Workflow = store.getState().workflows[workflowKey];

	return (
		<div className={styles.WorkflowCompletedMarker}>
			<p>{workflow.name} Complete</p>
		</div>
	);
};

export default WorkflowCompletedMarker;
