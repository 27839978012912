import React from "react";
import { Button, Tooltip } from "design-system";

import styles from "./ExternalEndpoints.module.scss";

interface Props {
	headerText: string;
	tooltipText: string;
	hiddenOptions?: JSX.Element;
	onBack: () => void;
	onDone: () => void;
}

const Header: React.FunctionComponent<Props> = ({
	headerText,
	tooltipText,
	hiddenOptions,
	onBack,
	onDone,
}) => {
	return (
		<div className={styles.Header}>
			<div>
				<Tooltip
					id={`back-to-endpoints`}
					placement="top"
					trigger={["hover"]}
					overlay={<span>{tooltipText}</span>}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="27"
						height="27"
						viewBox="0 0 27 27"
						onClick={onBack}
						className={styles.BackBtn}
					>
						<g transform="translate(-203 -72)">
							<g transform="translate(203 72)" fill="#fff" stroke="#e9ebf2" strokeWidth="1">
								<circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
								<circle cx="13.5" cy="13.5" r="13" fill="none" />
							</g>
							<g transform="translate(207.302 79.873)">
								<g transform="translate(0 5.482)">
									<path
										d="M752.246,501.163H735.009a.877.877,0,1,1,0-1.747h17.237a.877.877,0,1,1,0,1.747Z"
										transform="translate(-734.055 -499.416)"
										fill="#caced9"
									/>
								</g>
								<g transform="translate(0.003)">
									<path
										d="M742.792,502.891a.825.825,0,0,1,.121,1.229,1.024,1.024,0,0,1-1.343.114l-7.127-5.478a.622.622,0,0,1-.163-.142.281.281,0,0,1-.057-.071.611.611,0,0,1-.071-.114.054.054,0,0,1-.014-.028.35.35,0,0,1-.043-.121.751.751,0,0,1,.064-.6.424.424,0,0,1,.064-.107c.014-.021.036-.043.05-.064a.7.7,0,0,1,.128-.121l.043-.028,7.127-5.45a1.011,1.011,0,0,1,1.343.107.82.82,0,0,1-.121,1.229l-6.3,4.817Z"
										transform="translate(-734.059 -491.7)"
										fill="#a2a9ba"
									/>
								</g>
							</g>
						</g>
					</svg>
				</Tooltip>
				<h1>{headerText}</h1>
			</div>
			<div className={styles.Actions}>
				{hiddenOptions}
				<Button
					style={{ marginLeft: "8px" }}
					theme="solid"
					tone="light"
					color="primary"
					size="compact"
					onClick={onDone}
				>
					Done
				</Button>
			</div>
		</div>
	);
};

export default Header;
