export const CAPTURED_NEW_ACCESS_TOKEN = "CAPTURED_NEW_ACCESS_TOKEN";
export const CAPTURED_UPDATE_USER_PROFILE = "CAPTURED_UPDATE_USER_PROFILE";
export const CAPTURED_UPDATE_USER_PASSWORD = "CAPTURED_UPDATE_USER_PASSWORD";
export const CAPTURED_READY_TO_START_JOB = "READY_TO_START_JOB";
export const CAPTURED_TEST_WEBHOOK = "CAPTURED_TEST_WEBHOOK";
export const CAPTURED_TEST_EXTERNAL_ENDPOINT = "CAPTURED_TEST_EXTERNAL_ENDPOINT";
export const CAPTURED_DELETE_ROLE = "CAPTURED_DELETE_ROLE";
export const CAPTURED_ACTIVATE_USER = "CAPTURED_ACTIVATE_USER";
export const CAPTURED_CREATE_WORKFLOW = "CAPTURED_CREATE_WORKFLOW";
export const CAPTURED_DELETE_FIELD = "CAPTURED_DELETE_FIELD";
export const CAPTURED_DELETE_CATEGORY = "CAPTURED_DELETE_CATEGORY";
export const CAPTURED_ACCOUNT_UPGRADE = "CAPTURED_ACCOUNT_UPGRADE";
export const CAPTURED_ACCOUNT_UPGRADE_FINISHED = "CAPTURED_ACCOUNT_UPGRADE_FINISHED";
export const CAPTURED_SUBSCRIPTION_RESOURCE_CHANGED = "CAPTURED_SUBSCRIPTION_RESOURCE_CHANGED";
export const CAPTURED_CANCEL_SUBSCRIPTION = "CAPTURED_CANCEL_SUBSCRIPTION";
export const CAPTURED_CLOSED_ACCOUNT = "CAPTURED_CLOSED_ACCOUNT";
export const CAPTURED_RESUME_SUBSCRIPTION = "CAPTURED_RESUME_SUBSCRIPTION";
export const CAPTURED_UPDATE_BILLING_DETAILS = "CAPTURED_UPDATE_BILLING_DETAILS";
export const CAPTURED_NEW_EXTERNAL_ENDPOINT_RESPONSE = "CAPTURED_NEW_EXTERNAL_ENDPOINT_RESPONSE";
