import React, { useEffect } from "react";
// @ts-ignore
import loadable from "@loadable/component";
import { LocationProvider, Router } from "@reach/router";
import Header from "./Base/Header/Header";
import { INITIAL_RENDER_ONLY } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../initialState";
import { Spinner } from "design-system";
import { requestAuthenticatedUser, requestCSRFToken } from "./Account/accountActions";
import JobLogs from "./Jobs/Review/Logs/JobLogs";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import User from "./People/Users/User/User";
import { pingOnTabFocus } from "../utils/helpers";
import DeveloperLogs from "./Developers/Logs/DeveloperLogs";
import WebhookSetup from "./Developers/Webhooks/WebhookSetup";
import JobOverview from "./Jobs/Review/Overview/JobOverview";
import SystemError from "./Base/System/SystemError";
import { lostInternetConnection, reconnectedToInternet } from "./Base/System/systemActions";
import LostInternetConnection from "./Base/System/LostInternetConnection";
import TrialExpired from "./Account/Statuses/TrialExpired";
import AccountSuspended from "./Account/Statuses/AccountSuspended";
import AccountClosed from "./Account/Statuses/AccountClosed";
import Receipt from "./Account/Billing/Receipts/Receipt";
import CloseAccount from "./Account/Close/CloseAccount";
import ExternalEndpointSetup from "./Developers/ExternalEndpoints/ExternalEndpointSetup";
import ResponseBuilder from "./Developers/ExternalEndpoints/Response/ResponseBuilder";
import PayloadBuilder from "./Developers/ExternalEndpoints/Payload/PayloadBuilder";
import { get } from "../utils/api";
import axios, { AxiosResponse } from "axios";

const Billing = loadable(() => import(/* webpackChunkName: "Billing" */ "./Account/Billing/Billing"));
const CancelSubscription = loadable(() =>
	import(/* webpackChunkName: "CloseAccount" */ "./Account/Close/CancelSubscription")
);
const DeveloperKeys = loadable(() =>
	import(/* webpackChunkName: "DeveloperKeys" */ "./Developers/Keys/DeveloperKeys")
);
const JobsList = loadable(() => import(/* webpackChunkName: "JobsList" */ "./Jobs/List/JobsList"));
const JobStart = loadable(() => import(/* webpackChunkName: "JobStart" */ "./Jobs/Start/JobStart"));
const OpenJob = loadable(() => import(/* webpackChunkName: "OpenJob" */ "./Jobs/Open/OpenJob"));
const UsersList = loadable(() => import(/* webpackChunkName: "UsersList" */ "./People/Users/List/UsersList"));
const RolesList = loadable(() => import(/* webpackChunkName: "RolesList" */ "./People/Roles/List/RolesList"));
const Role = loadable(() => import(/* webpackChunkName: "Role" */ "./People/Roles/Role/Role"));
const Profile = loadable(() => import(/* webpackChunkName: "Profile" */ "./Account/Profile/Profile"));
const WorkflowsList = loadable(() =>
	import(/* webpackChunkName: "WorkflowsList" */ "./Workflows/List/WorkflowsList")
);
const FieldsList = loadable(() => import(/* webpackChunkName: "FieldsList" */ "./Fields/FieldsList"));
const CategoriesList = loadable(() =>
	import(/* webpackChunkName: "CategoriesList" */ "./Categories/CategoriesList")
);
const WorkflowEditorLoader = loadable(() =>
	import(/* webpackChunkName: "WorkflowEditorLoader" */ "./Workflows/Editor/WorkflowEditorLoader")
);
const WorkflowViewerLoader = loadable(() =>
	import(/* webpackChunkName: "WorkflowViewerLoader" */ "./Workflows/Viewer/WorkflowViewerLoader")
);
const WebhooksList = loadable(() =>
	import(/* webpackChunkName: "WebhooksList" */ "./Developers/Webhooks/WebhooksList")
);
const ExternalEndpointsList = loadable(() =>
	import(
		/* webpackChunkName: "ExternalEndpointsList" */ "./Developers/ExternalEndpoints/ExternalEndpointsList"
	)
);
const ExtraPanel = loadable(() => import(/* webpackChunkName: "ExtraPanel" */ "./Account/Extra/ExtraPanel"));
const KnowledgeBase = loadable(() =>
	import(/* webpackChunkName: "KnowledgeBase" */ "./KnowledgeBase/KnowledgeBase")
);
const Integrations = loadable(() =>
	import(/* webpackChunkName: "IntegrationsList" */ "./Integrations/Integrations")
);
const ManageOverview = loadable(() =>
	import(/* webpackChunkName: "ManageOverview" */ "./Manage/ManageOverview")
);

const preloadLookup = {
	Billing,
	DeveloperKeys,
	JobStart,
	JobsList,
	OpenJob,
	Profile,
	RolesList,
	Role,
	UsersList,
	WorkflowsList,
	WebhooksList,
	ExternalEndpointsList,
	FieldsList,
	CategoriesList,
	WorkflowEditorLoader,
	WorkflowViewerLoader,
	KnowledgeBase,
	ExtraPanel,
	Integrations,
	ManageOverview,
	CancelSubscription,
};

const App: React.FunctionComponent = () => {
	const bootstrapping = useSelector((state: InitialState) => state.bootstrapping);
	const authenticated = useSelector((state: InitialState) => state.authenticated);
	const me = useSelector((state: InitialState) => state.me);

	const dispatch = useDispatch();

	if (window.location.pathname === "/") {
		window.location.replace("/jobs");
	}

	if (!authenticated && bootstrapping) {
		dispatch(requestCSRFToken());
		dispatch(requestAuthenticatedUser());
	}

	useEffect(() => {
		pingOnTabFocus();

		window.addEventListener("offline", () => {
			lostInternetConnection();
		});

		window.addEventListener("online", () => {
			reconnectedToInternet();
		});
	}, INITIAL_RENDER_ONLY);

	if (!bootstrapping && !authenticated) {
		window.location.replace("/login");

		return null;
	}

	if (bootstrapping) {
		return (
			<div>
				<Spinner size="fullscreen" />
			</div>
		);
	}

	function preload(component: any) {
		// @ts-ignore
		preloadLookup[component].preload();
	}

	// @ts-ignore
	if (me.super === true) {
		preload("ExtraPanel");
	}

	return (
		<div className="App">
			<DndProvider backend={Backend}>
				<LocationProvider>
					<Header preload={preload} />

					{// @ts-ignore
					me.super && <ExtraPanel />}

					<TrialExpired />
					<AccountSuspended />
					<AccountClosed />
				</LocationProvider>
				<Router>
					<ManageOverview path="/manage" preload={preload} />
					<FieldsList path="/manage/fields" />
					<CategoriesList path="/manage/categories" />

					<UsersList path="/manage/users" />
					<User path="/manage/users/:userKey" />

					<RolesList path="/manage/roles" />
					<Role path="/manage/roles/:roleKey" />

					<DeveloperKeys path="/manage/developers" />
					<DeveloperLogs path="/manage/developers/logs" />
					<WebhooksList path="/manage/developers/webhooks" />
					<WebhookSetup path="/manage/developers/webhooks/:webhookKey" />
					<ExternalEndpointsList path="/manage/developers/external-endpoints" />
					<ExternalEndpointSetup path="/manage/developers/external-endpoints/:externalEndpointKey" />
					<PayloadBuilder path="/manage/developers/external-endpoints/:externalEndpointKey/payload" />
					<ResponseBuilder path="/manage/developers/external-endpoints/:externalEndpointKey/response" />

					<Integrations path="/manage/integrations/*" />

					<WorkflowsList preload={preload} path="workflows" />
					<WorkflowEditorLoader path="/workflows/:workflowKey" />
					<WorkflowViewerLoader path="/workflows/:workflowKey/view" />

					<KnowledgeBase path="/knowledge-base" />
					<KnowledgeBase path="/knowledge-base/pages/:pageKey" />

					<JobsList preload={preload} path="/jobs" />
					<JobStart preload={preload} path="/start" />
					<JobOverview path="/jobs/:jobKey" />
					<JobLogs path="/jobs/:jobKey/log" />

					<OpenJob path="/jobs/:jobKey/open/:jobStepKey" />

					<Profile path="/profile" />
					<Receipt path="/billing/receipts/:receiptId" />
					<Billing path="/billing/*" />

					<CancelSubscription path="/cancel" />
					<CloseAccount path="/close" />
					{/* add 404 */}
				</Router>
			</DndProvider>

			<SystemError />
			<LostInternetConnection />
		</div>
	);
};

export default App;
