import { AnyAction } from "redux";
import { SET_WORKFLOW_HAS_CHANGED } from "../../actionTypes";

export function trackWorkflowChanges(action: AnyAction) {
	if (action.method !== "post" && action.method !== "delete") return null;

	// extract the workflowKey from the URL
	const match = action.url.match(/workflows\/(.[^/]*)/);

	if (!match) return null;
	if (match.length !== 2) return null;

	return {
		type: SET_WORKFLOW_HAS_CHANGED,
		workflowKey: match[1],
	};
}
