import {
	CanvasElementMeasurements,
	ConditionsSettings,
	DropdownFieldDetails,
	ExternalEndpoint,
	FormField,
	RouteCondition as RouteConditionInterface,
	StandardFormFieldType,
	VariableType,
	XYCoords,
} from "design-system";
import {
	calculateCurrentDaySelection,
	calculateCurrentMonthSelection,
	calculateDateDiagramValue, calculateDayOfWeekSelection,
	calculateFormattedTimeFromTimeObject, calculateFormattedTimesFromTimeObjects,
	calculateRouteConditionHeight,
	calculateUserFullNameFromKey,
} from "../utils";
import { ExtraSmallTextSizeGuide, TextRenderEngine, TinyTextSizeGuide } from "../TextRenderEngine";
import { ConditionTypeOperators } from "../../Shared/Conditional/conditionalVariableTypes";
import { store } from "../../../../../store";
import { InitialState } from "../../../../../initialState";
import { ImageLibraryOption } from "./ImageLibrary";
import { Diagram } from "../Diagram";
import { systemOptions } from "../../Shared/Conditional/systemVariableOptions";

const BOX_PADDING = 14;
const SPACE_BETWEEN_VARIABLE_AND_VALUE = 8;
const VARIABLE_LINE_HEIGHT = 12;

export class RouteConditionRenderer {
	private readonly condition: RouteConditionInterface;
	private readonly settings: ConditionsSettings;
	private readonly index: number;
	private readonly totalConditionsCount: number;
	private groupMeasurements: CanvasElementMeasurements;
	private readonly context: CanvasRenderingContext2D;
	private readonly conditionHeight: number;
	private readonly previousConditionsMeasurements: Array<{ x: number; y: number; height: number }>;
	private readonly conditionCoords: XYCoords;
	private operatorWidth: number;

	constructor(
		condition: RouteConditionInterface,
		settings: ConditionsSettings,
		groupMeasurements: CanvasElementMeasurements,
		index: number,
		totalConditionsCount: number,
		previousConditionsCoords: Array<{ x: number; y: number; height: number }>,
		context: CanvasRenderingContext2D
	) {
		this.condition = condition;
		this.settings = settings;
		this.groupMeasurements = groupMeasurements;
		this.index = index;
		this.totalConditionsCount = totalConditionsCount;
		this.context = context;
		this.conditionHeight = calculateRouteConditionHeight(this.condition, totalConditionsCount, index);
		this.previousConditionsMeasurements = previousConditionsCoords;
		this.conditionCoords = {
			x: groupMeasurements.coordX,
			y: this.calculateConditionCoordY(),
		};
		this.operatorWidth = 0;
	}

	calculateConditionCoordY() {
		if (this.index === 0) {
			return this.groupMeasurements.coordY;
		}

		const prevConditionMeasurements = this.previousConditionsMeasurements[this.index - 1];

		return prevConditionMeasurements.height + prevConditionMeasurements.y;
	}

	joiner() {
		if (this.index === this.totalConditionsCount - 1 || this.totalConditionsCount === 1) {
			// last or only one
			return;
		}

		new TextRenderEngine(
			(this.settings.joinType as string).toUpperCase(),
			{
				x: this.conditionCoords.x + 16,
				y: this.conditionCoords.y + this.conditionHeight - 24,
			},
			[0]
		)
			.setSizing(new TinyTextSizeGuide())
			.setTextColor("#636E87")
			.render(this.context);
	}

	parseVariableText(): string {
		if (this.condition.variable.type === VariableType.Field) {
			const fields = (store.getState() as InitialState).formFields;
			const field = fields[this.condition.variable.value as string] as FormField;

			return field.name;
		}

		if (this.condition.variable.type === VariableType.System) {
			const option = systemOptions.find((option) => option.key === this.condition.variable.value);
			if (option) {
				return option.label;
			}
		}

		if (this.condition.variable.type === VariableType.Automation) {
			if (
				this.condition.extraSettings &&
				this.condition.extraSettings.integrationKey &&
				this.condition.extraSettings.integrationKey === "external-endpoints"
			) {
				const endpointKey = this.condition.extraSettings.eventKey;
				const variableKey = this.condition.variable.value;

				const state = store.getState() as InitialState;
				const endpoint: ExternalEndpoint = state.externalEndpoints[endpointKey];
				const variable = endpoint.variables[variableKey as string];

				return variable.label;
			}
		}

		return "";
	}

	variableText() {
		let y = this.index === 0 ? this.conditionCoords.y + BOX_PADDING : this.conditionCoords.y;

		const text = this.parseVariableText();

		new TextRenderEngine(
			text.toUpperCase(),
			{ x: this.conditionCoords.x + 13, y },
			this.condition.variable.lineOffsets
		)
			.setSizing(new ExtraSmallTextSizeGuide())
			.setTextColor("#636E87")
			.render(this.context);
	}

	operator(diagram: Diagram) {
		if (!this.condition.operator) {
			return;
		}

		const operator = ConditionTypeOperators[this.condition.operator as string];

		const emptyOperators = ["isTrue", "isFalse", "isEmpty", "isNotEmpty"];

		if (emptyOperators.includes(this.condition.operator)) {
			return;
		}

		let image = diagram.getImageLibrary().get(operator.key as ImageLibraryOption) as HTMLImageElement;

		if (!image) {
			console.error(`missing image for operator: ${operator}`);
			return;
		}

		this.operatorWidth = image.width + 8;

		let y = this.conditionCoords.y + 10 + SPACE_BETWEEN_VARIABLE_AND_VALUE + 4.5;

		if (this.condition.operator === "equalTo") {
			y += 1.5;
		}

		if (this.index === 0) {
			y += BOX_PADDING;
		}

		this.context.drawImage(image, this.conditionCoords.x + 12, y);
	}

	parseValue(value: string | unknown) {
		if (this.condition.operator === "isTrue") {
			return "Is True";
		}

		if (this.condition.operator === "isFalse") {
			return "Is False";
		}

		if (this.condition.operator === "isEmpty") {
			return "Is Empty";
		}

		if (this.condition.operator === "isNotEmpty") {
			return "Is Not Empty";
		}

		if (this.condition.variable.type === VariableType.System) {
			switch (this.condition.variable.value) {
				case "currentMonth":
					return calculateCurrentMonthSelection(JSON.parse(this.condition.value.value as string).month, this.condition.operator);
				case "currentDay":
					return calculateCurrentDaySelection(JSON.parse(this.condition.value.value as string).day);
				case "currentTime":
					return this.condition.operator === 'betweenTimes' ? calculateFormattedTimesFromTimeObjects(this.condition.value.value) : calculateFormattedTimeFromTimeObject(this.condition.value.value);
				case "dayOfWeek":
					return calculateDayOfWeekSelection(JSON.parse(this.condition.value.value as string).dayOfWeek, this.condition.operator);
			}
		}

		if (this.condition.variable.type === VariableType.Field) {
			const fields = (store.getState() as InitialState).formFields;
			const field = fields[this.condition.variable.value as string] as FormField;

			if (this.condition.operator === "oneOf" || this.condition.operator === "notOneOf") {
				const selectedKeys = (value as string).split(",");

				if (selectedKeys.length >= 10) {
					return `${selectedKeys.length} Options Selected`;
				}

				let selectedOptions: Array<string> = [];

				if (
					field.type === StandardFormFieldType.DROPDOWN ||
					field.type === StandardFormFieldType.RADIO ||
					field.type === StandardFormFieldType.CHECKBOXES
				) {
					(field.details as DropdownFieldDetails).options.forEach((option) => {
						if (selectedKeys.includes(option.key)) {
							selectedOptions.push(option.label);
						}
					});
				}

				if (field.type === StandardFormFieldType.PERSON) {
					selectedKeys.forEach((userKey: string) => {
						const name = calculateUserFullNameFromKey(userKey);
						selectedOptions.push(name);
					});
				}

				return selectedOptions.join(", ");
			}

			// equalTo / notEqualTo
			if (field.type === StandardFormFieldType.DROPDOWN || field.type === StandardFormFieldType.RADIO) {
				const option = (field.details as DropdownFieldDetails).options.find(
					(option) => option.key === value
				);
				if (option) {
					return option.label;
				}
			}

			if (field.type === StandardFormFieldType.PERSON) {
				return calculateUserFullNameFromKey(this.condition.value.value);
			}

			if (field.type === StandardFormFieldType.DATE) {
				return calculateDateDiagramValue(this.condition);
			}
		}

		if (this.condition.variable.type === VariableType.Automation) {
			const extraSettings = this.condition.extraSettings;
			if (
				extraSettings.hasOwnProperty("integrationKey") === false ||
				extraSettings.hasOwnProperty("eventKey") === false
			) {
				return "";
			}

			if (extraSettings.integrationKey === "external-endpoints") {
				const externalEndpoint = (store.getState() as InitialState).externalEndpoints[
					extraSettings.eventKey
				];
				if (
					!externalEndpoint ||
					!externalEndpoint.variables ||
					!Object.keys(externalEndpoint.variables).length
				) {
					return "";
				}

				const variable = externalEndpoint.variables[this.condition.variable.value as string];

				if (!variable) {
					return "";
				}

				if (variable.type === "date") {
					return calculateDateDiagramValue(this.condition);
				}
			}
		}

		if (typeof value === "string") {
			return value;
		}

		return "";
	}

	value() {
		const value = this.parseValue(this.condition.value.value as string);

		let y =
			SPACE_BETWEEN_VARIABLE_AND_VALUE +
			this.conditionCoords.y +
			this.condition.variable.lineOffsets.length * VARIABLE_LINE_HEIGHT;

		if (this.index === 0) {
			y += BOX_PADDING;
		}

		let offsets = this.condition.value.lineOffsets;

		if (value.includes("Options Selected") || offsets.length === 0) {
			// hacky, sometimes the "x options selected" text was being split onto two lines
			// apparently because of the offsets.
			offsets = [0];
		}

		new TextRenderEngine(
			value,
			{
				x: this.conditionCoords.x + 12 + this.operatorWidth,
				y,
			},
			offsets
		)
			.setTextColor("#283759")
			.render(this.context);
	}

	render(diagram: Diagram) {
		this.variableText();
		this.joiner();
		this.operator(diagram);
		this.value();
	}

	getConditionMeasurements() {
		return {
			...this.conditionCoords,
			height: this.conditionHeight,
		};
	}
}
