export interface ConditionTypeOperatorsInterface {
	[key: string]: {
		key: string;
		label: string;
	};
}

export const ConditionTypeOperators: ConditionTypeOperatorsInterface = {
	equalTo: {
		key: "equalTo",
		label: "is equal to",
	},
	notEqualTo: {
		key: "notEqualTo",
		label: "is not equal to",
	},
	lessThan: {
		key: "lessThan",
		label: "is less than",
	},
	lessThanEqualTo: {
		key: "lessThanEqualTo",
		label: "is less than or equal to",
	},
	greaterThan: {
		key: "greaterThan",
		label: "is greater than",
	},
	greaterThanEqualTo: {
		key: "greaterThanEqualTo",
		label: "is greater than or equal to",
	},
	contains: {
		key: "contains",
		label: "contains",
	},
	areaCodeIs: {
		key: "areaCodeIs",
		label: "area code is",
	},
	countryCodeIs: {
		key: "countryCodeIs",
		label: "country code is",
	},
	equalToDate: {
		key: "equalToDate",
		label: "is equal to",
	},
	before: {
		key: "before",
		label: "is before",
	},
	betweenTimes: {
		key: "betweenTimes",
		label: "is between"
	},
	after: {
		key: "after",
		label: "is after",
	},
	inLast: {
		key: "inLast",
		label: "in the last",
	},
	inLessThan: {
		// x days/hours/etc
		key: "inLessThan",
		label: "is in less than",
	},
	inMoreThan: {
		key: "inMoreThan",
		label: "is in more than",
	},
	oneOf: {
		key: "oneOf",
		label: "is one of these",
	},
	notOneOf: {
		key: "notOneOf",
		label: "is not one of these",
	},
	isTrue: {
		key: "isTrue",
		label: "is true",
	},
	isFalse: {
		key: "isFalse",
		label: "is false",
	},
	isEmpty: {
		key: "isEmpty",
		label: "is empty",
	},
	isNotEmpty: {
		key: "isNotEmpty",
		label: "is not empty",
	},
};

export interface ConditionalVariableTypesInterface {
	[key: string]: {
		key: string;
		operators: Array<keyof ConditionTypeOperatorsInterface>;
	} | any;
}

export const VariableTypes: ConditionalVariableTypesInterface = {
	text: {
		key: "text",
		operators: ["equalTo", "notEqualTo", "isEmpty", "isNotEmpty"],
	},
	shortText: {
		key: "shortText",
		operators: ["equalTo", "notEqualTo", "isEmpty", "isNotEmpty"],
	},
	longText: {
		key: "longText",
		operators: ["contains", "isEmpty", "isNotEmpty"],
	},
	phone: {
		key: "phone",
		operators: ["areaCodeIs", "countryCodeIs"],
	},
	email: {
		key: "email",
		operators: ["equalTo", "notEqualTo", "isEmpty", "isNotEmpty"],
	},
	date: {
		key: "date",
		operators: ["equalToDate", "before", "after", "inLast", "inLessThan", "inMoreThan"],
	},
	boolean: {
		key: "boolean",
		operators: ["isTrue", "isFalse"],
	},
	dropdown: {
		key: "dropdown",
		operators: ["equalTo", "notEqualTo", "oneOf", "notOneOf", "isEmpty", "isNotEmpty"],
	},
	radio: {
		key: "radio",
		operators: ["equalTo", "notEqualTo", "oneOf", "notOneOf", "isEmpty", "isNotEmpty"],
	},
	checkboxes: {
		key: "checkboxes",
		operators: ["oneOf", "notOneOf"],
	},
	person: {
		key: "person",
		operators: ["equalTo", "notEqualTo", "oneOf", "notOneOf"],
	},
	number: {
		key: "number",
		operators: [
			"equalTo",
			"notEqualTo",
			"lessThan",
			"greaterThan",
			"lessThanEqualTo",
			"greaterThanEqualTo",
			"isEmpty",
			"isNotEmpty",
		],
	},
	url: {
		key: "url",
		operators: ["contains"],
	},
	custom: {
		currentMonth: {
			key: "dropdown",
			operators: ["equalTo", "notEqualTo", "before", "after", "oneOf", "notOneOf"],
		},
		currentDay: {
			key: "date",
			operators: ["equalToDate", "before", "after"],
		},
		currentTime: {
			key: "currentTime",
			operators: ["before", "after", "betweenTimes"],
		},
		currentDayOfWeek: {
			key: "dropdown",
			operators: ["equalTo", "notEqualTo", "oneOf", "notOneOf"],
		},
	}
};
