import React from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import { Stages } from "../Menu";
import { Button, Card, Space } from "design-system";
import { APP_NAME } from "../../../../utils/constants";
import { AccountPreferences } from "../ActivationContainer";
import { PreferenceImages } from "./Preferences/Images";
import Adiv from "../../../Animatables/Adiv";

import styles from "../Activation.module.scss";

enum UseCaseTypes {
	COLLAB = "COLLAB",
	MANAGE = "MANAGE",
	AUTOMATION = "AUTOMATION",
	MAPPING = "MAPPING",
}

enum UserTypes {
	INTERNAL = "INTERNAL",
	EXTERNAL = "EXTERNAL",
	BOTH = "BOTH",
}

interface Props extends RouteComponentProps {
	currentStage: Stages;
	preferences: AccountPreferences;
	updatePreference: (index: "useCases" | "userType", value: UserTypes | UseCaseTypes) => void;
	savePreferences: () => void;
}

const useCaseOptions = [
	{
		type: UseCaseTypes.COLLAB,
		image: PreferenceImages.collab,
		name: "Work Collaboration",
	},
	{
		type: UseCaseTypes.MANAGE,
		image: PreferenceImages.management,
		name: "Work Management",
	},
	{
		type: UseCaseTypes.AUTOMATION,
		image: PreferenceImages.automation,
		name: "Work Automation",
	},
	{
		type: UseCaseTypes.MAPPING,
		image: PreferenceImages.mapping,
		name: "Process Documentation",
	},
];

const userTypeOptions = [
	{
		type: UserTypes.INTERNAL,
		image: PreferenceImages.internalUsers,
		name: "Internal Users",
	},
	{
		type: UserTypes.EXTERNAL,
		image: PreferenceImages.externalUsers,
		name: "External Users",
	},
	{
		type: UserTypes.BOTH,
		image: PreferenceImages.bothUsers,
		name: "Both",
	},
];

const SetupAccount: React.FunctionComponent<Props> = ({
	currentStage,
	preferences,
	updatePreference,
	savePreferences,
}) => {
	if (currentStage !== Stages.Account) return null;

	const useCases = useCaseOptions.map((useCase, index) => {
		const classes = classNames([
			styles.Preference,
			preferences.useCases.includes(useCase.type) && styles.selected,
		]);

		return (
			<div
				key={`useCase-${index}`}
				className={classes}
				onClick={() => updatePreference("useCases", useCase.type)}
			>
				{useCase.image}
				<p>{useCase.name}</p>
			</div>
		);
	});

	const userTypes = userTypeOptions.map((userType, index) => {
		const classes = classNames([
			styles.Preference,
			preferences.userType === userType.type && styles.selected,
		]);

		return (
			<div
				key={`userType-${index}`}
				className={classes}
				onClick={() => updatePreference("userType", userType.type)}
			>
				{userType.image}
				<p>{userType.name}</p>
			</div>
		);
	});

	const isValid = preferences.useCases.length > 0 && preferences.userType !== null;

	return (
		<Adiv className={styles.SetupAccount}>
			<Card maxWidth={650}>
				<div>
					<div className={styles.SectionHeading}>
						<h2>How do you want to use {APP_NAME}?</h2>
						<p>Choose all options that apply.</p>
					</div>

					<div className={styles.Preferences}>{useCases}</div>

					<Space amount={48} />

					<div className={styles.SectionHeading}>
						<h2>What people will use {APP_NAME}?</h2>
						<p>Internal users represent people within your organization.</p>
					</div>

					<div className={styles.Preferences}>{userTypes}</div>

					<Space amount={16} />
					<Button
						locked={!isValid}
						theme="solid"
						tone="light"
						color="green"
						onClick={() => (isValid ? savePreferences() : null)}
					>
						Continue
					</Button>
				</div>
			</Card>
		</Adiv>
	);
};

export default SetupAccount;
