const index = {
	'4f8': 'us-01',
	'6d3': 'au-01',
	'9r4': 'eu-01',
}

export const ServerMap = {
	find: (code: string): string => {
		// @ts-ignore
		return index[code];
	}
}