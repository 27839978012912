import { PONG, REDIRECT_TO_LOGIN } from "../actionTypes";

export class AccountAPI {
	static ping() {
		return {
			url: "ping",
			respondWith: PONG,
		};
	}

	static saveFeatureVote(id: number) {
		return {
			url: "feature-votes",
			data: {
				id,
			},
		};
	}

	static deleteFeatureVote(id: number) {
		return {
			url: `feature-votes/${id}`,
			method: "delete",
		};
	}

	static logout() {
		return {
			url: "logout",
			data: {},
			respondWith: REDIRECT_TO_LOGIN,
		};
	}

	static refreshAuth() {
		return {
			url: "refresh",
			data: {},
		};
	}

	static closeAccount(survey: any) {
		return {
			url: "account/close",
			data: {
				survey,
			},
		};
	}
}
