import { SET_PERMISSIONS, SET_ROLES, UPDATE_USER_PROFILE_IMAGE } from "../actionTypes";
import { JobTitle, Me, Role, User, UserBaseSettings } from "design-system";

export class UserAPI {
	static inviteUser(user: UserBaseSettings) {
		return {
			url: `invite-user`,
			data: user,
		};
	}

	static updateUser(user: User) {
		return {
			url: `users/${user.key}`,
			data: user,
		};
	}

	static activateUser(properties: { [key: string]: any }) {
		return {
			url: `pending-user/activate`,
			data: {
				...properties,
			},
		};
	}

	static updateUserProfile(user: Me) {
		return {
			url: `users/${user.key}/profile`,
			data: user,
		};
	}

	static updateUserPassword(userKey: string, password: string, password_confirmation: string) {
		return {
			url: `users/${userKey}/change-password`,
			data: {
				password,
				password_confirmation,
			},
		};
	}

	static uploadPendingUserProfileImage(token: string, userKey: string, formData: FormData) {
		return {
			url: `upload-pending-user-profile-image`,
			data: {
				formData,
				key: `${userKey}_profile`,
				token,
			},
			respondWith: UPDATE_USER_PROFILE_IMAGE,
		};
	}

	static uploadUserProfileImage(userKey: string, formData: FormData) {
		return {
			url: `users/${userKey}/profile-image`,
			data: {
				formData,
				key: `${userKey}_profile`,
			},
			respondWith: UPDATE_USER_PROFILE_IMAGE,
		};
	}

	static resetUserProfileImage(userKey: string) {
		return {
			url: `users/${userKey}/profile-image/reset`,
			data: {},
		};
	}

	static loadRoles() {
		return {
			url: `roles`,
			respondWith: SET_ROLES,
		};
	}

	static loadPermissions() {
		return {
			url: `permissions`,
			respondWith: SET_PERMISSIONS,
		};
	}

	static createRole(role: Role) {
		return {
			url: `roles`,
			data: role,
		};
	}

	static updateRole(role: Role) {
		return {
			url: `roles/${role.key}`,
			data: role,
		};
	}

	static deleteRole(roleKey: string) {
		return {
			url: `roles/${roleKey}`,
			data: {},
			method: "delete",
		};
	}

	static deleteUser(userKey: string) {
		return {
			url: `users/${userKey}`,
			data: {},
			method: "delete",
		};
	}

	static createJobTitle(jobTitle: JobTitle) {
		return {
			url: `job-titles`,
			data: jobTitle,
		};
	}

	static updateJobTitle(jobTitle: JobTitle) {
		return {
			url: `job-titles/${jobTitle.key}`,
			data: jobTitle,
		};
	}

	static deleteJobTitle(jobTitleKey: string) {
		return {
			url: `job-titles/${jobTitleKey}`,
			data: {},
			method: "delete",
		};
	}
}
