import React from "react";

const allWorkflows = (
	<svg xmlns="http://www.w3.org/2000/svg" width="154" height="46.053" viewBox="0 0 154 46.053">
		<g id="Group_2239" data-name="Group 2239" transform="translate(-1941.454 -1114.889)">
			<circle
				id="Ellipse_387"
				data-name="Ellipse 387"
				cx="23.026"
				cy="23.026"
				r="23.026"
				transform="translate(1941.454 1114.889)"
				fill="#d9e4fc"
			/>
			<g id="Group_2236" data-name="Group 2236">
				<line
					id="Line_685"
					data-name="Line 685"
					x1="10.24"
					y2="18.056"
					transform="translate(1954.523 1128.888)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<line
					id="Line_686"
					data-name="Line 686"
					x2="9.621"
					y2="18.056"
					transform="translate(1964.817 1128.888)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<circle
					id="Ellipse_388"
					data-name="Ellipse 388"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(1958.745 1122.869)"
					fill="#1b3b87"
				/>
				<circle
					id="Ellipse_389"
					data-name="Ellipse 389"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(1948.505 1140.925)"
					fill="#5583ed"
				/>
				<circle
					id="Ellipse_390"
					data-name="Ellipse 390"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(1968.42 1140.925)"
					fill="#5583ed"
				/>
			</g>
			<circle
				id="Ellipse_391"
				data-name="Ellipse 391"
				cx="23.026"
				cy="23.026"
				r="23.026"
				transform="translate(1995.428 1114.889)"
				fill="#d9e4fc"
			/>
			<g id="Group_2237" data-name="Group 2237">
				<line
					id="Line_687"
					data-name="Line 687"
					x1="10.24"
					y2="18.056"
					transform="translate(2008.497 1128.888)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<line
					id="Line_688"
					data-name="Line 688"
					x2="9.621"
					y2="18.056"
					transform="translate(2018.791 1128.888)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<circle
					id="Ellipse_392"
					data-name="Ellipse 392"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2012.719 1122.869)"
					fill="#1b3b87"
				/>
				<circle
					id="Ellipse_393"
					data-name="Ellipse 393"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2002.478 1140.925)"
					fill="#5583ed"
				/>
				<circle
					id="Ellipse_394"
					data-name="Ellipse 394"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2022.393 1140.925)"
					fill="#5583ed"
				/>
			</g>
			<circle
				id="Ellipse_395"
				data-name="Ellipse 395"
				cx="23.026"
				cy="23.026"
				r="23.026"
				transform="translate(2049.401 1114.889)"
				fill="#d9e4fc"
			/>
			<g id="Group_2238" data-name="Group 2238">
				<line
					id="Line_689"
					data-name="Line 689"
					x1="10.24"
					y2="18.056"
					transform="translate(2062.47 1128.888)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<line
					id="Line_690"
					data-name="Line 690"
					x2="9.621"
					y2="18.056"
					transform="translate(2072.764 1128.888)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<circle
					id="Ellipse_396"
					data-name="Ellipse 396"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2066.692 1122.869)"
					fill="#1b3b87"
				/>
				<circle
					id="Ellipse_397"
					data-name="Ellipse 397"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2056.452 1140.925)"
					fill="#5583ed"
				/>
				<circle
					id="Ellipse_398"
					data-name="Ellipse 398"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2076.367 1140.925)"
					fill="#5583ed"
				/>
			</g>
		</g>
	</svg>
);

const restrictedWorkflows = (
	<svg xmlns="http://www.w3.org/2000/svg" width="154" height="46.053" viewBox="0 0 154 46.053">
		<g id="Group_2235" data-name="Group 2235" transform="translate(-1941.454 -1176.174)">
			<circle
				id="Ellipse_375"
				data-name="Ellipse 375"
				cx="23.026"
				cy="23.026"
				r="23.026"
				transform="translate(1941.454 1176.174)"
				fill="#f2f5fa"
			/>
			<g id="Group_2232" data-name="Group 2232">
				<line
					id="Line_679"
					data-name="Line 679"
					x1="10.24"
					y2="18.056"
					transform="translate(1954.523 1190.173)"
					fill="#9cb7f7"
					stroke="#caced9"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<line
					id="Line_680"
					data-name="Line 680"
					x2="9.621"
					y2="18.056"
					transform="translate(1964.817 1190.173)"
					fill="#9cb7f7"
					stroke="#caced9"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<circle
					id="Ellipse_376"
					data-name="Ellipse 376"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(1958.745 1184.154)"
					fill="#44506e"
				/>
				<circle
					id="Ellipse_377"
					data-name="Ellipse 377"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(1948.505 1202.21)"
					fill="#a2a9ba"
				/>
				<circle
					id="Ellipse_378"
					data-name="Ellipse 378"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(1968.42 1202.21)"
					fill="#a2a9ba"
				/>
			</g>
			<circle
				id="Ellipse_379"
				data-name="Ellipse 379"
				cx="23.026"
				cy="23.026"
				r="23.026"
				transform="translate(2049.401 1176.174)"
				fill="#f2f5fa"
			/>
			<g id="Group_2233" data-name="Group 2233">
				<line
					id="Line_681"
					data-name="Line 681"
					x1="10.24"
					y2="18.056"
					transform="translate(2062.47 1190.173)"
					fill="#9cb7f7"
					stroke="#caced9"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<line
					id="Line_682"
					data-name="Line 682"
					x2="9.621"
					y2="18.056"
					transform="translate(2072.764 1190.173)"
					fill="#9cb7f7"
					stroke="#caced9"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<circle
					id="Ellipse_380"
					data-name="Ellipse 380"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2066.692 1184.154)"
					fill="#44506e"
				/>
				<circle
					id="Ellipse_381"
					data-name="Ellipse 381"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2056.452 1202.21)"
					fill="#a2a9ba"
				/>
				<circle
					id="Ellipse_382"
					data-name="Ellipse 382"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2076.367 1202.21)"
					fill="#a2a9ba"
				/>
			</g>
			<circle
				id="Ellipse_383"
				data-name="Ellipse 383"
				cx="23.026"
				cy="23.026"
				r="23.026"
				transform="translate(1995.428 1176.174)"
				fill="#d9e4fc"
			/>
			<g id="Group_2234" data-name="Group 2234">
				<line
					id="Line_683"
					data-name="Line 683"
					x1="10.24"
					y2="18.056"
					transform="translate(2008.497 1190.173)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<line
					id="Line_684"
					data-name="Line 684"
					x2="9.621"
					y2="18.056"
					transform="translate(2018.791 1190.173)"
					fill="#9cb7f7"
					stroke="#9cb7f7"
					strokeMiterlimit="10"
					strokeWidth="1.368"
				/>
				<circle
					id="Ellipse_384"
					data-name="Ellipse 384"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2012.719 1184.154)"
					fill="#1b3b87"
				/>
				<circle
					id="Ellipse_385"
					data-name="Ellipse 385"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2002.478 1202.21)"
					fill="#5583ed"
				/>
				<circle
					id="Ellipse_386"
					data-name="Ellipse 386"
					cx="6.019"
					cy="6.019"
					r="6.019"
					transform="translate(2022.393 1202.21)"
					fill="#5583ed"
				/>
			</g>
		</g>
	</svg>
);

export { allWorkflows, restrictedWorkflows };
