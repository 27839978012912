import React, { MutableRefObject } from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import { Stages } from "../Menu";
import { Button, Card, ErrorBag, Space, Spinner } from "design-system";
import ProfileImageEdit from "../../../Base/ProfileImage/ProfileImageEdit";
import { ActivationUser } from "../ActivationContainer";
import { APP_NAME } from "../../../../utils/constants";
import UserForm from "./CreateProfile/UserForm";
import Errors from "../../../Base/Form/Errors/Errors";
import Adiv from "../../../Animatables/Adiv";

import styles from "../Activation.module.scss";

interface Props extends RouteComponentProps {
	currentStage: Stages;
	user: ActivationUser;
	uploadErrors: Array<ErrorBag>;
	amUploadingImage: boolean;
	errors: {
		bag: Array<ErrorBag>;
		ref: MutableRefObject<HTMLInputElement | null>;
	};
	stageProcessing: boolean;
	updateUser: (property: string, value: string) => void;
	uploadImage: (data: FormData) => Promise<undefined | "error">;
	removeImage: () => void;
	saveProfile: () => void;
}

const CreateProfile: React.FunctionComponent<Props> = ({
	currentStage,
	user,
	errors,
	amUploadingImage,
	stageProcessing,
	updateUser,
	removeImage,
	uploadImage,
	saveProfile,
	uploadErrors,
}) => {
	if (currentStage !== Stages.Profile) return null;

	const classes = classNames([styles.CreateProfile]);

	function readyToSave() {
		if (stageProcessing) {
			return;
		}

		if (amUploadingImage) {
			return;
		}

		saveProfile();
	}

	return (
		<Adiv className={classes}>
			<Card maxWidth={500}>
				<div className={styles.SectionHeading}>
					<h2>Tell us a bit about you.</h2>
					<p>This will help your teammates recognize you in {APP_NAME}.</p>
				</div>
				<Space amount={32} />

				<Errors bag={uploadErrors} />

				<ProfileImageEdit imageUrl={user.image} uploadImage={uploadImage} removeImage={removeImage} />

				<Space amount={32} />

				<UserForm user={user} updateUser={updateUser} />

				<Space amount={24} />

				<Errors bag={errors.bag} forwardRef={errors.ref} />

				<Button
					theme="solid"
					locked={amUploadingImage}
					tone="light"
					color="green"
					onClick={readyToSave}
				>
					{stageProcessing ? <Spinner color="white" /> : "Continue"}
				</Button>
			</Card>
		</Adiv>
	);
};

export default CreateProfile;
