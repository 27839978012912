import React, { useState } from "react";
import classNames from "classnames";
import {
	JobStep,
	PersonAssignmentOption,
	UsersList,
	StepAssigneeType,
	UserAssignmentsPreview,
	PersonSelectionDialog,
	Ability,
	Fade,
} from "design-system";
import { canAccess } from "../../../../../utils/helpers";

import styles from "./OpenSteps.module.scss";

interface Props {
	jobStep: JobStep;
	users: UsersList;
	onAssignmentsChanged: (assignees: Array<PersonAssignmentOption>) => void;
}

function buildSelectedAssignees(assignees: Array<string>, users: UsersList) {
	return assignees.map((assigneeKey) => {
		const user = users[assigneeKey];

		return {
			key: assigneeKey,
			type: StepAssigneeType.User,
			label: `${user.firstName} ${user.lastName}`,
			imageUrl: user.imageUrl,
		};
	});
}

function buildAssignmentOptions(users: UsersList) {
	return Object.keys(users).map((userKey) => {
		const user = users[userKey];

		return {
			key: user.key,
			label: `${user.firstName} ${user.lastName}`,
			imageUrl: user.imageUrl,
			type: StepAssigneeType.User,
		};
	});
}

const StepAssignees: React.FunctionComponent<Props> = ({ jobStep, users, onAssignmentsChanged }) => {
	const [showAssignmentDialog, setShowAssignmentDialog] = useState(false);
	const hasAbilityToAssign = canAccess(Ability.SetUnassignedAssignment);

	const hide = jobStep.completedAt !== null;

	function changeAssignees(assignees: Array<PersonAssignmentOption>) {
		setShowAssignmentDialog(false);
		onAssignmentsChanged(assignees);
	}

	function showAssigneesPanel() {
		if (!hasAbilityToAssign) {
			return null;
		}

		setShowAssignmentDialog(true);
	}

	const unassignedClasses = classNames([styles.Unassigned, hasAbilityToAssign && styles.canAssign]);
	if (jobStep.assignment.assignees.length === 0) {
		return (
			<Fade show={!hide}>
				<div>
					<div className={unassignedClasses} onClick={showAssigneesPanel}>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
							<g transform="translate(-454 -418)">
								<path
									className={styles.Icon_Bg}
									d="M16,31.594A16.025,16.025,0,0,1,2.733,24.629a15.732,15.732,0,0,1-1.475-2.683,15.664,15.664,0,0,1,0-12.3A15.732,15.732,0,0,1,2.733,6.965,16.107,16.107,0,0,1,27.314,4.627a15.958,15.958,0,0,1,1.954,2.338,15.741,15.741,0,0,1,1.475,2.683,15.664,15.664,0,0,1,0,12.3,15.741,15.741,0,0,1-1.475,2.683A16.043,16.043,0,0,1,16,31.594Zm.132-25.85a5.838,5.838,0,0,0-4.114,1.682,5.72,5.72,0,0,0-1.247,1.826,5.7,5.7,0,0,0,0,4.472,5.72,5.72,0,0,0,1.247,1.826,5.862,5.862,0,0,0,8.228,0,5.722,5.722,0,0,0,1.247-1.826,5.7,5.7,0,0,0,0-4.472,5.722,5.722,0,0,0-1.247-1.826,5.838,5.838,0,0,0-4.114-1.682Z"
									transform="translate(454 418)"
									fill="#44506e"
								/>
								<path
									d="M3.581,11.045A14.618,14.618,0,0,1,1.092,9.683Q.529,9.3,0,8.863A9.575,9.575,0,0,1,9.406,0a9.568,9.568,0,0,1,9.388,8.674,14.717,14.717,0,0,1-1.316,1.009,14.616,14.616,0,0,1-2.489,1.362,14.467,14.467,0,0,1-5.7,1.161A14.463,14.463,0,0,1,3.581,11.045Z"
									transform="translate(460.465 437.794)"
									fill="#8892a8"
									className={styles.Icon_Body}
								/>
								<circle
									className={styles.Icon_Head}
									cx="6.094"
									cy="6.094"
									r="6.094"
									transform="translate(463.953 423.364)"
									fill="#caced9"
								/>
							</g>
						</svg>
						<span>Unassigned</span>
					</div>

					<PersonSelectionDialog
						show={showAssignmentDialog}
						multi={false}
						workflowKey={null}
						options={[{ groupName: "Users", options: buildAssignmentOptions(users) }]}
						selected={buildSelectedAssignees(jobStep.assignment.assignees, users)}
						onSave={(option: PersonAssignmentOption) => changeAssignees([option])}
						onClose={() => setShowAssignmentDialog(false)}
					/>
				</div>
			</Fade>
		);
	}

	const minimumCompleters = jobStep.assignment.requirements.minimumCompleters;

	return (
		<Fade show={!hide}>
			<div className={styles.Assignees}>
				<div className={styles.Title}>
					{minimumCompleters === 1 && jobStep.assignment.assignees.length > 1
						? "Available To"
						: "Assigned To"}

					{canAccess(Ability.ReassignJob) && (
						<div
							onClick={() => setShowAssignmentDialog(true)}
							className={styles.ChangeAssignmentsButton}
						>
							Change
						</div>
					)}
				</div>

				<UserAssignmentsPreview
					users={users}
					assignedUserKeys={jobStep.assignment.assignees}
					previewLimit={4}
					renderSingleAsFullUser={jobStep.assignment.requirements.minimumCompleters <= 1}
				/>

				<PersonSelectionDialog
					show={showAssignmentDialog}
					multi={true}
					options={[{ groupName: "Users", options: buildAssignmentOptions(users) }]}
					selected={buildSelectedAssignees(jobStep.assignment.assignees, users)}
					workflowKey={null}
					onSave={(options: Array<PersonAssignmentOption>) => changeAssignees(options)}
					onClose={() => setShowAssignmentDialog(false)}
				/>
			</div>
		</Fade>
	);
};

export default StepAssignees;
