import { combineEpics, Epic, ofType, StateObservable } from "redux-observable";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
	ADD_CAPTURED_API_RESPONSE,
	CANCEL_FILE_UPLOAD,
	DISTINCT_FETCH_FROM_API,
	FETCH_FROM_API,
	RECONNECT_REAL_TIME_UPDATES,
	SEND_CAPTURED_REQUEST_TO_API,
	SEND_DEBOUNCED_REQUEST_TO_API,
	SEND_REQUEST_TO_API,
	START_FILE_UPLOAD,
	START_REAL_TIME_UPDATES, START_REFRESH_AUTH_FLOW, STOP_REFRESH_AUTH_FLOW,
	SYNCHRONOUS_REQUEST_TO_API,
} from "../../actionTypes";
import {
	catchError,
	concatMap,
	debounceTime,
	delay,
	distinctUntilKeyChanged,
	map,
	mergeMap,
	switchMap,
	takeUntil,
	tap,
} from "rxjs/operators";
import { EMPTY, from, interval, merge, of, Subject } from "rxjs";
import { call, determineURL, get } from "../../utils/api";
import { apiRequestFailed, reconnectRealTimeUpdates } from "../../components/Account/accountActions";
import { AnyAction } from "redux";
import { fromPromise, WebSocketSubject } from "rxjs/internal-compatibility";
import { runMiddleware } from "../middleware/middleware";
import { webSocket } from "rxjs/webSocket";
import {
	fileUploadFailed,
	fileUploadProgressComplete,
	receiveCustomResponseAction,
	sendRequestToAPI,
	updateFileUploadProgress,
} from "../../sharedActions";
import { InitialState } from "../../initialState";
import { getRandomIntBetween } from "../../utils/helpers";
import { AccountAPI } from "../AccountAPI";

// can we create generic middleware that is called for each of these
// that if the middleware passes some checks, it dispatches an extra action?

// separate the specific use case "update a property on a workflow" from the "api updates" logic

const asyncApiCall: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(SYNCHRONOUS_REQUEST_TO_API),
		concatMap((action: AnyAction) =>
			merge(
				of(...runMiddleware(action)),
				fromPromise(call(action.url, action.method, action.data)).pipe(
					mergeMap((response: AxiosResponse) => {
						if (action.hasOwnProperty("respondWith")) {
							return of({ type: action.respondWith, ...response.data.data });
						}

						return EMPTY;
					}),
					catchError((error) => of(apiRequestFailed(error)))
				)
			)
		)
	);
};

const apiCall: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(SEND_REQUEST_TO_API),
		mergeMap((action: AnyAction) =>
			merge(
				of(...runMiddleware(action)),
				from(call(action.url, action.method, action.data)).pipe(
					mergeMap((response) => {
						if (action.hasOwnProperty("respondWith")) {
							return of({ type: action.respondWith, ...response.data.data });
						}

						return EMPTY;
					}),
					catchError((error) => of(apiRequestFailed(error)))
				)
			)
		)
	);
};

const sendCapturedApiCall: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(SEND_CAPTURED_REQUEST_TO_API),
		mergeMap((action: AnyAction) =>
			merge(
				of(...runMiddleware(action)),
				from(call(action.url, action.method, action.data)).pipe(
					map((response) => {
						return {
							type: ADD_CAPTURED_API_RESPONSE,
							captureKey: action.captureKey,
							code: response.data.code,
							data: response.data.data,
							errors: [],
							status: response.data.status,
						};
					}),
					catchError((error: AxiosError) => {
						const upstreamError = apiRequestFailed(error);

						if (upstreamError !== undefined && upstreamError.type !== "UNKNOWN_ERROR") {
							return of(upstreamError);
						}

						const response = error.response as AxiosResponse;

						return of({
							type: ADD_CAPTURED_API_RESPONSE,
							captureKey: action.captureKey,
							code: response.data.code,
							data: {},
							errors: response.data.errors,
							status: response.data.status,
						});
					})
				)
			)
		)
	);
};

const debouncedApiCall: Epic = (action$) => {
	return action$.pipe(
		ofType(SEND_DEBOUNCED_REQUEST_TO_API),
		debounceTime(250),
		mergeMap((action: AnyAction) =>
			merge(
				of(...runMiddleware(action)),
				from(call(action.url, action.method, action.data)).pipe(
					mergeMap((response) => {
						if (action.hasOwnProperty("respondWith")) {
							return of({ type: action.respondWith, ...response.data.data });
						}

						return EMPTY;
					}),
					catchError((error) => of(apiRequestFailed(error)))
				)
			)
		)
	);
};

export const getFromApi: Epic = (action$) => {
	return action$.pipe(
		ofType(FETCH_FROM_API),
		mergeMap((action: AnyAction) =>
			from(get(action.url)).pipe(
				map((response) => {
					return { type: action.respondWith, ...response.data.data };
				}),
				catchError((error) => of(apiRequestFailed(error)))
			)
		)
	);
};

export const getDistinctFromApi: Epic = (action$) => {
	return action$.pipe(
		ofType(DISTINCT_FETCH_FROM_API),
		distinctUntilKeyChanged("url"),
		mergeMap(
			(action: AnyAction) =>
				from(get(action.url)).pipe(
					map((response) => {
						return { type: action.respondWith, ...response.data.data };
					}),
					catchError((error) => of(apiRequestFailed(error)))
				)
			// probably need a retry in here
		)
	);
};

function createSocket() {
	const baseUrl = determineURL().replace("https", "wss");
	// const onOpenSubject = new Subject();
	const onCloseSubject = new Subject();

	webSocketSubject = webSocket({
		url: `${baseUrl}/ws`,
		openObserver: onOpenSubject,
		closeObserver: onCloseSubject,
	});
	return webSocketSubject;
}

let webSocketSubject: WebSocketSubject<{}>;
let onOpenSubject = new Subject();
let onCloseSubject = new Subject();

export const realTimeUpdates: Epic = (action$) => {
	return action$.pipe(
		ofType(START_REAL_TIME_UPDATES),
		switchMap((action) => createSocket().pipe(catchError((e) => of(reconnectRealTimeUpdates()))))
	);
};

const realTimeUpdatesReconnect: Epic = (action$, state$: StateObservable<InitialState>) =>
	action$.pipe(
		ofType(RECONNECT_REAL_TIME_UPDATES),
		// Random so that when the server comes back it's not hit with
		// a flood of requests at the same time
		delay(getRandomIntBetween(5000, 20000)),
		tap(() => console.log("reconnecting...")),
		mergeMap((action) => {
			onCloseSubject.complete();
			webSocketSubject.complete();

			return of({ type: START_REAL_TIME_UPDATES });
		})
	);

const startRefreshAuthFlow: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(START_REFRESH_AUTH_FLOW),
		switchMap(() =>
			interval(1000 * 60 * 15).pipe(
				//
				map(() => sendRequestToAPI(AccountAPI.refreshAuth()))
			)
		),
		takeUntil(action$.ofType(STOP_REFRESH_AUTH_FLOW))
	);
};

const uploadEpic: Epic = (action$) => {
	return action$.pipe(
		ofType(START_FILE_UPLOAD),
		mergeMap((action) => {
			const progressSubject = new Subject();

			return merge(
				fromPromise(
					axios({
						headers: {
							"Content-Type": "multipart/form-data",
						},
						url: `${determineURL()}/${action.url}`,
						method: "POST",
						data: action.data.formData,
						withCredentials: true,
						onUploadProgress: (progressEvent: ProgressEvent) => {
							progressSubject.next(updateFileUploadProgress(action.data.key, progressEvent));
						},
					})
				).pipe(
					mergeMap((response: any) => {
						progressSubject.complete();

						return of(
							receiveCustomResponseAction(action.respondWith, response.data),
							fileUploadProgressComplete(action.data.key)
						);
					}),
					takeUntil(action$.ofType(CANCEL_FILE_UPLOAD)), // CANCEL_FILE_UPLOAD && has correct key
					catchError((error: AxiosError) => {
						return of(fileUploadFailed(action.data.key, error.response?.data.errors));
					})
				),
				progressSubject
			);
		})
	);
};

export default combineEpics(
	realTimeUpdates,
	realTimeUpdatesReconnect,
	startRefreshAuthFlow,
	getFromApi,
	getDistinctFromApi,
	asyncApiCall,
	apiCall,
	debouncedApiCall,
	uploadEpic,
	sendCapturedApiCall
);
