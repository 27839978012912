import React from "react";
import { Tooltip, UserAssignmentsPreview, UsersList } from "design-system";

import styles from "./Sidebar.module.scss";

interface Props {
	userKeys: Array<string>;
	users: UsersList;
}

const Collaborators: React.FunctionComponent<Props> = ({ userKeys, users }) => {
	return (
		<div className={styles.Collaborators}>
			<p className={styles.Title}>Collaborators</p>
			<UserAssignmentsPreview users={users} assignedUserKeys={userKeys} />
		</div>
	);
};

export default Collaborators;
