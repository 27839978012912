import React from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import { Account, AccountStatus, Button, Modal } from "design-system";
import { useLocation } from "@reach/router";
import { Logo25 } from "../../Base/Logo/Logos";

import styles from "./AccountStatuses.module.scss";

interface Props {}

const AccountSuspended: React.FunctionComponent<Props> = () => {
	const location = useLocation();

	const account = useSelector((state: InitialState) => state.account) as Account;

	if (account.status !== AccountStatus.Suspended) {
		return null;
	}

	return (
		<Modal show={true} toggleModal={() => null} includeBackground={true}>
			<div className={styles.AccountStatusModal}>
				{Logo25}

				<h1>Your account has been suspended.</h1>

				<div className={styles.Actions}>
					<Button
						color="primary"
						theme="solid"
						tone="medium"
						size="large"
						onClick={() => window.open("https://prestavi.com/contact-us", "_blank")}
					>
						Contact Support
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default AccountSuspended;
