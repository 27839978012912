import React from "react";
import { CodeBlock } from "design-system";
import { store } from "../../../store";
import { InitialState } from "../../../initialState";
import { toCamelCase } from "../../../utils/helpers";
import { determineURL } from "../../../utils/api";
import { Me } from "design-system";

import styles from "./Webhooks.module.scss";

interface Props {
	selectedOptions: Array<string>;
	settings: { [key: string]: any };
}

const availableProperties: { [key: string]: any } = {
	jobKey: "ckm6ze15q000001mthv6zfl4m",
	startedBy: {
		key: "ckm6zedxb000101mt6g9zhjcq",
		firstName: "Marsha",
		lastName: "Johnson",
	},
	startedAt: "2021-02-24T23:21:07+00:00",
	workflows: [
		{
			key: "ckm70fb7w000001jy2f6m5dhi",
			name: "Example Workflow",
		},
	],
	completedAt: null,
	fields: [
		{
			key: "ckm70g2pg000101jy3qdobl8o",
			name: "Example Field",
			type: "shortText",
			value: "User Defined Value",
		},
		{
			key: "ckmay3q0z000001l0gybagxey",
			name: "Example Dropdown Field",
			type: "dropdown",
			value: "ckhl0ptnp00033h5zay5tguc8",
		},
	],
	logs: [
		{
			workflowKey: "ckm70fb7w000001jy2f6m5dhi",
			elementKey: "ckm70jb7n000201jye3ur98i2",
			elementType: "step",
			event: "step_completed",
			details: {
				userKey: "ckm6zedxb000101mt6g9zhjcq",
			},
			timestamp: "2021-02-24T23:21:07+00:00",
		},
	],
};
function buildCodeBlock(selectedOptions: Array<string>, settings: { [key: string]: any }) {
	const state = store.getState() as InitialState;

	let codeBlock: { [key: string]: any } = {};

	selectedOptions.forEach((option: string) => {
		if (option === "startedBy") {
			const me = state.me as Me;

			codeBlock[option] = {
				key: me.key,
				firstName: me.firstName,
				lastName: me.lastName,
			};

			return;
		}

		codeBlock[option] = availableProperties[option];
	});

	if (settings.hasOwnProperty("customProperties")) {
		const customProperties: Array<{ key: string; value: string }> = settings.customProperties;

		customProperties.forEach((property) => {
			if (property.key === "" || availableProperties.hasOwnProperty(property.key)) {
				// don't allow a user to override a builtin property
				return;
			}

			if (property.value === "true") {
				// @ts-ignore
				property.value = true;
			}

			if (property.value === "false") {
				// @ts-ignore
				property.value = false;
			}

			codeBlock[toCamelCase(property.key)] = property.value;
		});
	}

	codeBlock = Object.fromEntries(Object.entries(codeBlock).sort());

	codeBlock.meta = {
		link: `${determineURL()}/jobs/ckm6ze15q000001mthv6zfl4m/webhooks/ckm8dpfjl000001k11g4fbr06`,
	};

	return JSON.stringify(codeBlock, null, 2);
}

const PayloadPreview: React.FunctionComponent<Props> = ({ selectedOptions, settings }) => {
	const code = buildCodeBlock(selectedOptions, settings);

	return (
		<div className={styles.PayloadPreview}>
			<div className={styles.Header}>
				<h1>Payload Preview</h1>
			</div>

			<div className={styles.Code}>
				<h5>Example Json Data Structure</h5>

				<CodeBlock code={code} />
			</div>
		</div>
	);
};

export default PayloadPreview;
