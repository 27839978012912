import { AnyAction } from "redux";
import {
	AccessType,
	Action,
	AssignmentDistributionType,
	ConditionalContentChild,
	Connector,
	ConnectorsList,
	ContentCondition,
	ContentFormDetails,
	Delay,
	ElementConnection,
	ElementRoute,
	ElementRoutesList,
	ElementType,
	End,
	FormAccess,
	FormField,
	FormLayout,
	LinePosition,
	PersonAssignmentOption,
	RouteCondition,
	StartMarker,
	Step,
	StepContentElement,
	StepTimeframe,
	SubWorkflowConnector,
	Workflow,
} from "design-system";
import {
	ADD_CONTENT_CONDITION,
	ADD_ELEMENT_TO_PREV_ROUTE,
	ADD_FEATURE_ITEM_VOTE,
	ADD_ROUTE_CONDITION,
	ADD_ROUTE_WITH_CONNECTOR,
	CHANGE_ACTION_BUILDER_STAGE,
	CHANGE_STEP_ACCESS,
	CHANGE_STEP_CONNECTION_TYPE,
	CHANGE_TIMEFRAME,
	CLOSE_CONDITIONAL_VARIABLE_SELECTION_DIALOG,
	COPY_WORKFLOW,
	CREATE_ACTION,
	CREATE_CONDITIONAL_CONTENT_CHILD,
	CREATE_CONNECTOR,
	CREATE_CONTENT_ELEMENT,
	CREATE_DELAY,
	CREATE_END,
	CREATE_FIELD,
	CREATE_FORM_FIELD,
	CREATE_STEP,
	CREATE_SUB_WORKFLOW_CONNECTOR,
	CREATE_WORKFLOW,
	CREATE_WORKFLOW_CATEGORY,
	DEBOUNCE_ROUTE_CONDITION_UPDATE,
	DELETE_ACTION,
	DELETE_CONTENT_CONDITION,
	DELETE_CONTENT_ELEMENT,
	DELETE_DELAY,
	DELETE_FIELD,
	DELETE_ROUTE,
	DELETE_ROUTE_CONDITION,
	DELETE_STEP,
	DELETE_STEP_ASSIGNMENT_DISTRIBUTION,
	DELETE_SUB_WORKFLOW_CONNECTOR,
	DELETE_WORKFLOW,
	DELETE_WORKFLOW_CATEGORY,
	DESELECT_ELEMENT,
	DESELECT_ROUTE,
	ELEMENT_DRAG_BEGIN,
	ELEMENT_DRAG_END,
	ELEMENT_HOVER_BEGIN,
	ELEMENT_HOVER_END,
	MOVE_ACTION,
	MOVE_CONNECTOR,
	MOVE_CONTENT_ELEMENT,
	MOVE_DELAY,
	MOVE_END_PIECE,
	MOVE_ROUTE_CONDITION,
	MOVE_ROUTE_LABEL,
	MOVE_START_MARKER,
	MOVE_STEP,
	MOVE_SUB_WORKFLOW_CONNECTOR,
	PAN_DIAGRAM,
	PUBLISH_WORKFLOW,
	REMOVE_CONNECTOR,
	REMOVE_END_PIECE,
	REMOVE_FEATURE_ITEM_VOTE,
	REMOVE_ROUTE_CONNECTION,
	RESET_STEP_ASSIGNMENT_DISTRIBUTION,
	RESET_WORKFLOW_EDITOR,
	SELECT_CONDITIONAL_ELEMENT,
	SELECT_CONNECTION_FIELD,
	SELECT_ELEMENT,
	SELECT_FORM_ELEMENT,
	SELECT_FORM_FIELD,
	SELECT_ROUTE,
	SET_ELEMENT_TO_REORDER,
	SET_FULL_WORKFLOW,
	SET_USE_CUSTOM_LABEL_POSITION,
	SET_WORKFLOW,
	SET_WORKFLOW_PARTS,
	SHOW_CONDITIONAL_VARIABLE_SELECTION_DIALOG,
	SHOW_FORM_EDITOR_SIDEBAR,
	SHOW_MULTI_CONNECTION_SETUP,
	UNSET_PARENT_CONDITION_EDIT_MODE,
	UPDATE_ACTION,
	UPDATE_CONTENT_CONDITION,
	UPDATE_CONTENT_ELEMENT,
	UPDATE_CONTENT_ELEMENT_EDIT_MODE,
	UPDATE_DELAY,
	UPDATE_EDITOR_ZOOMED_PERCENT,
	UPDATE_FIELD,
	UPDATE_FORM_ACCESS,
	UPDATE_FORM_DETAILS,
	UPDATE_FORM_LAYOUT,
	UPDATE_ROUTE_CONDITION,
	UPDATE_ROUTE_CONDITION_DIRECTION,
	UPDATE_ROUTE_CONDITION_JOIN_TYPE,
	UPDATE_SELECTED_EXTRA_OPTIONS_KEY,
	UPDATE_SELECTED_STEP_SECTION,
	UPDATE_START_MARKER,
	UPDATE_START_MARKER_ROUTE_DIRECTION,
	UPDATE_STEP_ASSIGNMENT_DISTRIBUTION,
	UPDATE_STEP_ASSIGNMENT_POOL,
	UPDATE_STEP_NAME,
	UPDATE_SUB_WORKFLOW_CONNECTOR,
	UPDATE_WORKFLOW,
	UPDATE_WORKFLOW_CATEGORY,
	UPDATE_WORKFLOW_CONNECTION_ROUTE,
	UPDATE_WORKFLOW_ROOT,
} from "../../../actionTypes";
import { SectionTypes } from "./StepDetails/StepDetails";
import { XYCoords } from "./Canvas/utils";
import moment from "moment";
import {
	EveryoneDistributionSettings,
	RoundRobinDistributionSettings,
} from "design-system/src/types/workflows/steps";
import { ActionSectionTypes } from "./ActionDetails/ActionDetails";

export function setWorkflow(workflow: Workflow) {
	return {
		type: SET_WORKFLOW,
		workflow,
	};
}

export function setFullWorkflow(
	workflow: Workflow,
	startMarker: StartMarker,
	steps: Array<Step>,
	connectors: Array<Connector>,
	subWorkflows: Array<SubWorkflowConnector>,
	actions: Array<Action>,
	ends: Array<End>,
	delays: Array<Delay>
) {
	return {
		type: SET_FULL_WORKFLOW,
		workflow,
		startMarker,
		steps,
		connectors,
		subWorkflows,
		actions,
		ends,
		delays,
	};
}

export function setWorkflowParts(
	workflowKey: string,
	startMarker: StartMarker,
	steps: Array<Step>,
	connectors: Array<Connector>,
	subWorkflows: Array<SubWorkflowConnector>,
	actions: Array<Action>,
	ends: Array<End>,
	externalEndpoints: any,
	delays: Array<Delay>
) {
	return {
		type: SET_WORKFLOW_PARTS,
		startMarker,
		workflowKey,
		steps,
		connectors,
		subWorkflows,
		actions,
		ends,
		externalEndpoints,
		delays,
	};
}

export function createWorkflowCategory(key: string, name: string) {
	return {
		type: CREATE_WORKFLOW_CATEGORY,
		category: {
			key,
			name,
		},
	};
}

export function updateWorkflowCategory(key: string, name: string) {
	return {
		type: UPDATE_WORKFLOW_CATEGORY,
		category: {
			key,
			name,
		},
	};
}

export function deleteCategory(key: string) {
	return {
		type: DELETE_WORKFLOW_CATEGORY,
		key,
	};
}

export function createWorkflow(workflow: Workflow, connector: Connector, startMarker: any) {
	return {
		type: CREATE_WORKFLOW,
		workflow,
		connector,
		startMarker,
	};
}

export function copyWorkflow(workflowKey: string) {
	return {
		type: COPY_WORKFLOW,
		workflowKey,
	};
}

export function updateWorkflow(workflow: Workflow) {
	return {
		type: UPDATE_WORKFLOW,
		workflow,
	};
}

export function publishWorkflow(workflowKey: string) {
	return {
		type: PUBLISH_WORKFLOW,
		workflowKey,
	};
}

export function updateWorkflowRoot(workflowKey: string, elementType: ElementType, elementKey: string) {
	return {
		type: UPDATE_WORKFLOW_ROOT,
		workflowKey,
		root: {
			type: elementType,
			key: elementKey,
		},
	};
}

export function createAction(
	workflowKey: string,
	action: Action,
	connection: ElementConnection,
	route: any,
	connector: any
) {
	return {
		type: CREATE_ACTION,
		workflowKey,
		action,
		connection,
		route,
		connector,
	};
}

export function updateAction(action: Action) {
	return {
		type: UPDATE_ACTION,
		action,
	};
}

export function createStep(workflowKey: string, step: any, connection: any, route: any, connector: any) {
	return {
		type: CREATE_STEP,
		workflowKey,
		connection,
		route,
		connector,
		step: {
			key: step.key,
			name: step.name,
			coordX: step.coordX,
			coordY: step.coordY,
			width: step.width,
			height: step.height,
			wordOffsetsMultiLine: step.wordOffsetsMultiLine,
			connectionKey: connection.key,
			access: AccessType.Internal,
			assignment: {
				assignees: [],
				// @ts-ignore
				distribution: null,
			},
			content: [],
		},
	};
}

export function updateStepName(
	key: string,
	name: string,
	wordOffsetsMultiLine: Array<number>,
	width: number,
	height: number,
	coordX: number,
	coordY: number
) {
	return {
		type: UPDATE_STEP_NAME,
		key,
		name,
		wordOffsetsMultiLine,
		width,
		height,
		coordX,
		coordY,
	};
}

export function deleteStep(key: string, workflowKey: string) {
	return {
		type: DELETE_STEP,
		key,
		workflowKey,
	};
}

export function deleteAction(key: string, workflowKey: string) {
	return {
		type: DELETE_ACTION,
		key,
		workflowKey,
	};
}

export function deleteSubWorkflowConnector(key: string, workflowKey: string) {
	return {
		type: DELETE_SUB_WORKFLOW_CONNECTOR,
		key,
		workflowKey,
	};
}

export function deleteDelay(key: string, workflowKey: string) {
	return {
		type: DELETE_DELAY,
		key,
		workflowKey,
	};
}

export function removeRouteConnection(workflowKey: string, routeKey: string, connector: Connector) {
	return {
		type: REMOVE_ROUTE_CONNECTION,
		workflowKey,
		routeKey,
		connector,
	};
}

export function removeEndPiece(key: string, workflowKey: string) {
	return {
		type: REMOVE_END_PIECE,
		key,
		workflowKey,
	};
}

export function changeStepConnection(
	workflowKey: string,
	connection: ElementConnection,
	routes: Array<string>
) {
	return {
		type: CHANGE_STEP_CONNECTION_TYPE,
		workflowKey,
		connection,
		routes,
	};
}

export function deleteRoute(routeKey: string, workflowKey: string) {
	return {
		type: DELETE_ROUTE,
		routeKey,
		workflowKey,
	};
}

export function addRouteCondition(routeKey: string, condition: RouteCondition) {
	return {
		type: ADD_ROUTE_CONDITION,
		routeKey,
		condition,
	};
}

export function updateRouteCondition(routeKey: string, condition: RouteCondition) {
	return {
		type: UPDATE_ROUTE_CONDITION,
		routeKey,
		condition,
	};
}

export function deleteRouteCondition(routeKey: string, key: string) {
	return {
		type: DELETE_ROUTE_CONDITION,
		routeKey,
		key,
	};
}

export function debounceRouteConditionUpdate(
	workflowKey: string,
	routeKey: string,
	conditionKey: string,
	value: string
) {
	return {
		type: DEBOUNCE_ROUTE_CONDITION_UPDATE,
		routeKey,
		conditionKey,
		workflowKey,
		value,
	};
}

export function selectConnectionField(
	workflowKey: string,
	connection: ElementConnection,
	routes: ElementRoutesList,
	connectors: ConnectorsList
) {
	return {
		type: SELECT_CONNECTION_FIELD,
		workflowKey,
		connection,
		routes,
		connectors,
	};
}

export function moveStep(
	step: Step,
	connectors: { [connectorKey: string]: XYCoords },
	conditions: { [routeKey: string]: XYCoords }
) {
	return {
		type: MOVE_STEP,
		element: step,
		connectors,
		conditions,
		storeRef: "steps",
	};
}

export function moveAction(
	action: Action,
	connectors: { [connectorKey: string]: XYCoords },
	conditions: { [routeKey: string]: XYCoords }
) {
	return {
		type: MOVE_ACTION,
		element: action,
		connectors,
		conditions,
		storeRef: "actions",
	};
}

export function moveDelay(delay: Delay, connectors: { [connectorKey: string]: XYCoords }) {
	return {
		type: MOVE_DELAY,
		element: delay,
		connectors,
		storeRef: "delays",
	};
}

export function moveSubWorkflow(subWorkflow: SubWorkflowConnector) {
	return {
		type: MOVE_SUB_WORKFLOW_CONNECTOR,
		subWorkflow,
	};
}

export function moveStartMarker(startMarker: StartMarker, connector: Connector | null, workflowKey: string) {
	return {
		type: MOVE_START_MARKER,
		startMarker,
		connector,
		workflowKey,
	};
}

export function moveEndPiece(endPiece: End) {
	return {
		type: MOVE_END_PIECE,
		endPiece,
	};
}

export function updateRouteConditionJoinType(routeKey: string, joinType: string) {
	return {
		type: UPDATE_ROUTE_CONDITION_JOIN_TYPE,
		routeKey,
		joinType,
	};
}

export function moveRouteConditions(
	routeGateway: { key: string; coordX: number; coordY: number },
	connectors: { [connectorKey: string]: XYCoords }
) {
	return {
		type: MOVE_ROUTE_CONDITION,
		...routeGateway,
		connectors,
	};
}

export function moveRouteLabel(routeKey: string, coordX: number, coordY: number) {
	return {
		type: MOVE_ROUTE_LABEL,
		routeKey,
		coordX,
		coordY,
	};
}

export function setUseCustomLabelPosition(routeKey: string, coordX: number, coordY: number) {
	return {
		type: SET_USE_CUSTOM_LABEL_POSITION,
		routeKey,
		coordX,
		coordY,
	};
}

export function updateRouteConditionDirection(
	routeKey: string,
	point: "to" | "from",
	direction: LinePosition
) {
	return {
		type: UPDATE_ROUTE_CONDITION_DIRECTION,
		routeKey,
		point,
		direction,
	};
}

export function updateStartMarkerRouteDirection(
	direction: LinePosition,
	point: "to" | "from",
	workflowKey: string
) {
	let action: AnyAction = {
		type: UPDATE_START_MARKER_ROUTE_DIRECTION,
		workflowKey,
	};

	if (point === "to") {
		action.routeToDirection = direction;
	}

	if (point === "from") {
		action.routeFromDirection = direction;
	}

	return action;
}

export function elementDragBegin(
	coords: XYCoords,
	elementKey: string,
	elementType: ElementType,
	width: number,
	height: number,
	isConnected: boolean
) {
	return {
		type: ELEMENT_DRAG_BEGIN,
		coords,
		elementKey,
		elementType,
		width,
		height,
		isConnected,
	};
}

export function elementDragEnd() {
	return {
		type: ELEMENT_DRAG_END,
	};
}

export function updateEditorZoom(amount: number) {
	return {
		type: UPDATE_EDITOR_ZOOMED_PERCENT,
		amount,
	};
}

// todo might want to have an epic intercept this and prevent it from firing multiple times for the same element
export function elementHoverBegin(key: string, elementType: ElementType) {
	return {
		type: ELEMENT_HOVER_BEGIN,
		key,
		elementType,
	};
}

export function elementHoverEnd() {
	return {
		type: ELEMENT_HOVER_END,
	};
}

export function addElementToPrevRoute(
	key: string,
	type: ElementType,
	route: ElementRoute,
	direction: LinePosition = LinePosition.Top
) {
	return {
		type: ADD_ELEMENT_TO_PREV_ROUTE,
		key,
		elementType: type,
		nextDirection: direction,
		route,
	};
}

export function changeRouteJointDirection(route: ElementRoute) {
	return {
		type: UPDATE_WORKFLOW_CONNECTION_ROUTE,
		route,
	};
}

export function selectElement(elementKey: string, elementType: ElementType) {
	return {
		type: SELECT_ELEMENT,
		elementKey,
		elementType,
	};
}

export function selectRoute(routeKey: string) {
	return {
		type: SELECT_ROUTE,
		routeKey,
	};
}

export function deselectRoute() {
	return {
		type: DESELECT_ROUTE,
	};
}

export function deselectElement(full?: boolean) {
	return {
		type: DESELECT_ELEMENT,
		full,
	};
}

export function resetEditor() {
	return {
		type: RESET_WORKFLOW_EDITOR,
	};
}

export function panDiagram(offset: XYCoords) {
	return {
		type: PAN_DIAGRAM,
		offset,
	};
}

export function unsetParentConditionalEditMode() {
	return {
		type: UNSET_PARENT_CONDITION_EDIT_MODE,
	};
}

export function updateSelectedSection(selectedSectionKey: SectionTypes | ActionSectionTypes | null) {
	return {
		type: UPDATE_SELECTED_STEP_SECTION,
		selectedSectionKey,
	};
}

export function showConditionalConnectionPanel() {
	return {
		type: UPDATE_SELECTED_STEP_SECTION,
		selectedSectionKey: "connectionConditional",
	};
}

export function showMultiConnectionPanel() {
	return {
		type: UPDATE_SELECTED_STEP_SECTION,
		selectedSectionKey: "connectionMulti",
	};
}

export function showMultiConnectionSetup(stepKey: string) {
	return {
		type: SHOW_MULTI_CONNECTION_SETUP,
		stepKey,
	};
}

export function showContentFormPanel() {
	return {
		type: SHOW_FORM_EDITOR_SIDEBAR,
	};
}

export function selectFormElement(formKey: string) {
	return {
		type: SELECT_FORM_ELEMENT,
		formKey,
	};
}

export function selectConditionalElement(elementKey: string) {
	return {
		type: SELECT_CONDITIONAL_ELEMENT,
		elementKey,
	};
}

export function addRouteWithConnector(
	workflowKey: string,
	connectionKey: string,
	route: ElementRoute,
	connector: Connector,
	condition?: RouteCondition
) {
	return {
		type: ADD_ROUTE_WITH_CONNECTOR,
		workflowKey,
		connectionKey,
		route,
		connector,
		condition,
	};
}

export function deleteConnector(connectorKey: string, workflowKey: string) {
	return {
		type: REMOVE_CONNECTOR,
		connectorKey,
		workflowKey,
	};
}

export function moveConnector(connector: any) {
	return {
		type: MOVE_CONNECTOR,
		connector,
	};
}

export function updateStartMarker(workflowKey: string, startMarker: any) {
	return {
		type: UPDATE_START_MARKER,
		workflowKey,
		startMarker,
	};
}

export function createFormField(field: FormField) {
	return {
		type: CREATE_FORM_FIELD,
		field,
	};
}

export function createField(field: FormField) {
	return {
		type: CREATE_FIELD,
		field,
	};
}

export function updateField(field: FormField) {
	return {
		type: UPDATE_FIELD,
		field: {
			...field,
			configured: true,
		},
	};
}

export function deleteField(fieldKey: string) {
	return {
		type: DELETE_FIELD,
		fieldKey,
	};
}

export function updateFormLayout(layout: FormLayout) {
	return {
		type: UPDATE_FORM_LAYOUT,
		layout,
	};
}

export function updateFormAccess(access: FormAccess) {
	return {
		type: UPDATE_FORM_ACCESS,
		access,
	};
}

export function updateFormDetails(details: ContentFormDetails) {
	return {
		type: UPDATE_FORM_DETAILS,
		details,
	};
}

export function selectFormField(fieldKey: string | null) {
	return {
		type: SELECT_FORM_FIELD,
		fieldKey,
	};
}

export function changeStepAccess(stepKey: string, access: AccessType) {
	return {
		type: CHANGE_STEP_ACCESS,
		stepKey,
		access,
	};
}

export function changeTimeframe(stepKey: string, timeframe: StepTimeframe) {
	return {
		type: CHANGE_TIMEFRAME,
		stepKey,
		timeframe,
	};
}

export function createEndPiece(end: End, workflowKey: string) {
	return {
		type: CREATE_END,
		workflowKey,
		end,
	};
}

export function createSubWorkflowConnector(subWorkflow: SubWorkflowConnector, workflowKey: string) {
	return {
		type: CREATE_SUB_WORKFLOW_CONNECTOR,
		subWorkflow,
		workflowKey,
	};
}

export function updateSubWorkflowConnector(subWorkflow: SubWorkflowConnector) {
	return {
		type: UPDATE_SUB_WORKFLOW_CONNECTOR,
		subWorkflow,
	};
}

export function createDelay(
	workflowKey: string,
	delay: Delay,
	connection: ElementConnection,
	route: any,
	connector: any
) {
	return {
		type: CREATE_DELAY,
		workflowKey,
		delay,
		connection,
		route,
		connector,
	};
}

export function updateDelay(delay: Delay) {
	return {
		type: UPDATE_DELAY,
		delay,
	};
}

export function createContentElement(contentElement: StepContentElement) {
	return {
		type: CREATE_CONTENT_ELEMENT,
		contentElement,
	};
}

export function createConditionalContentChild(
	conditionalElementKey: string,
	contentElement: ConditionalContentChild
) {
	return {
		type: CREATE_CONDITIONAL_CONTENT_CHILD,
		conditionalElementKey,
		contentElement,
	};
}

export function updateContentElement(contentElement: StepContentElement) {
	return {
		type: UPDATE_CONTENT_ELEMENT,
		contentElement,
	};
}

export function updateContentElementToEditMode(elementKey: string | null) {
	return {
		type: UPDATE_CONTENT_ELEMENT_EDIT_MODE,
		elementKey,
	};
}

export function updateContentCondition(condition: ContentCondition) {
	return {
		type: UPDATE_CONTENT_CONDITION,
		condition,
	};
}

export function addContentCondition(elementKey: string, condition: ContentCondition) {
	return {
		type: ADD_CONTENT_CONDITION,
		elementKey,
		condition,
	};
}

export function deleteContentCondition(elementKey: string, conditionKey: string) {
	return {
		type: DELETE_CONTENT_CONDITION,
		elementKey,
		conditionKey,
	};
}

export function moveContentElement(elementKey: string, nextPosition: number) {
	return {
		type: MOVE_CONTENT_ELEMENT,
		elementKey,
		nextPosition,
	};
}

export function deleteContentElement(elementKey: string, stepKey: string) {
	return {
		type: DELETE_CONTENT_ELEMENT,
		elementKey,
		stepKey,
	};
}

export function setElementToReorder(elementKey: string | null) {
	return {
		type: SET_ELEMENT_TO_REORDER,
		elementKey,
	};
}

export function updateStepAssignmentPool(stepKey: string, assignees: Array<PersonAssignmentOption>) {
	return {
		type: UPDATE_STEP_ASSIGNMENT_POOL,
		stepKey,
		assignees,
	};
}

export function updateStepAssignmentDistribution(
	stepKey: string,
	distributionType: AssignmentDistributionType,
	settings: EveryoneDistributionSettings | RoundRobinDistributionSettings
) {
	return {
		type: UPDATE_STEP_ASSIGNMENT_DISTRIBUTION,
		stepKey,
		distributionType,
		settings,
	};
}

export function resetStepAssignmentDistribution(stepKey: string) {
	return {
		type: RESET_STEP_ASSIGNMENT_DISTRIBUTION,
		stepKey,
	};
}

export function deleteStepAssignmentDistribution(stepKey: string) {
	return {
		type: DELETE_STEP_ASSIGNMENT_DISTRIBUTION,
		stepKey,
	};
}

export function addFeatureVote(id: number) {
	return {
		type: ADD_FEATURE_ITEM_VOTE,
		id,
	};
}

export function removeFeatureVote(id: number) {
	return {
		type: REMOVE_FEATURE_ITEM_VOTE,
		id,
	};
}

export function createConnector(connector: Connector, workflowKey: string) {
	return {
		type: CREATE_CONNECTOR,
		connector,
		workflowKey,
	};
}

export function deleteWorkflow(workflowKey: string) {
	return {
		type: DELETE_WORKFLOW,
		workflowKey,
		deletedAt: moment().toISOString(),
	};
}

export function updateExtraOptionsKey(workflowKey: string | null) {
	// for workflows list
	return {
		type: UPDATE_SELECTED_EXTRA_OPTIONS_KEY,
		workflowKey,
	};
}
