import React from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import Adiv from "../../Animatables/Adiv";

import styles from "./Activation.module.scss";

interface Props extends RouteComponentProps {
	currentStage: Stages;
}

export enum Stages {
	Profile = 0,
	Account = 1,
	Team = 2,
	Done = 3,
}

const Items = [
	{ stage: Stages.Profile, label: "Create Profile" },
	{ stage: Stages.Account, label: "Setup Account" },
	{
		stage: Stages.Team,
		label: (
			<p>
				Invite your team <span>(optional)</span>
			</p>
		),
	},
	{ stage: Stages.Done, label: "Done" },
];

const Menu: React.FunctionComponent<Props> = ({ currentStage }) => {
	const items = Items.map(({ stage, label }, index) => {
		const classes = classNames([styles.MenuItem, stage === currentStage && styles.active]);

		if (typeof label === "string") {
			return (
				<div key={`ActivationMenu-${index}`} className={classes}>
					<p>{label}</p>
				</div>
			);
		}

		return (
			<div key={`ActivationMenu-${index}`} className={classes}>
				{label}
			</div>
		);
	});

	return (
		<Adiv className={styles.Menu}>
			<div>{items}</div>
		</Adiv>
	);
};

export default Menu;
