import { JobTableView, TableAccessIndex, TableColumn, ViewFiltersList } from "design-system";
import { post } from "../utils/api";

export class ViewAPI {
	static createView(view: JobTableView) {
		return post("views", {
			...view,
		});
	}

	static updateColumns(viewKey: string, columns: Array<TableColumn>) {
		return {
			url: `/views/${viewKey}/columns`,
			data: {
				columns,
			},
		};
	}

	static updateColumnWidth(viewKey: string, columnKey: string, width: number) {
		return {
			url: `/views/${viewKey}/columns/${columnKey}/width`,
			data: {
				width,
			},
		};
	}

	static updateAccess(viewKey: string, access: TableAccessIndex) {
		return {
			url: `/views/${viewKey}/access`,
			data: {
				access,
			},
		};
	}

	static updateFilters(viewKey: string, filters: ViewFiltersList) {
		return post(`/views/${viewKey}/filters`, { filters });
	}

	static setDefaultView(viewKey: string) {
		return {
			url: `/views/${viewKey}/set-default`,
			data: {},
		};
	}

	static renameView(viewKey: string, name: string) {
		return {
			url: `/views/${viewKey}/rename`,
			data: {
				name,
			},
		};
	}

	static deleteView(viewKey: string) {
		return {
			url: `/views/${viewKey}`,
			data: {},
			method: "delete",
		};
	}
}
