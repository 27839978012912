interface ElementConstraint {
	width: number;
	padding: string;
}

export class CanvasElementMeasurer {
	private width: number = 200;
	private fontSize: number = 16;
	private lineHeight: number = 16;

	// todo
	// should probably fix this issue before the MVP is launched because it will be nearly impossible to fix afterwards given the width/height
	// values are stored in the DB.

	// Current situation: The span elements use the parents width of 200px, even if they don't take up the full space
	// Need to do: The commented out code below makes it so that the span works as an inline element and only takes up the space it required
	// However, it doesn't work with the padding, which means that ALL elements (steps, lines, labels, decision indicators, etc) need their width/height
	// calculations adjusted.

	// I've been working on this problem most of the day and need a break!

	private createElements(constraints: ElementConstraint | null) {
		if (constraints && constraints.width) {
			this.width = constraints.width;
		}

		const root = document.getElementById("extra") as HTMLDivElement;
		const container = document.createElement("div");
		container.style.cssText = `position: absolute; width: ${this.width}px;`;
		const element = document.createElement("span");
		element.style.cssText = `position: absolute; line-height:${this.lineHeight}px; font-size:${this.fontSize}px;`;

		container.appendChild(element);
		root.append(container);

		return [element, container];
	}

	private convertWordsToSpans(text: string | null): string {
		if (!text) return "";

		const words: Array<string> = text.split(" ");

		return words.map((word: string) => `<span>${word}</span>`).join(" ");
	}

	/*
		Returns an array of indexes for the first word in each line
   */
	private calculateLineOffsets(element: HTMLSpanElement) {
		const words: HTMLCollection = element.children;

		let lastOffset = 0;
		let lineOffsets: Array<number> = [];

		Array.from(words).forEach((word, index) => {
			const offset = (word as HTMLSpanElement).offsetTop + word.getBoundingClientRect().height;

			if (index === 0) {
				lastOffset = offset;
				lineOffsets.push(index);
			}

			if (offset == lastOffset) {
				return;
			}

			lineOffsets.push(index);
			lastOffset = offset;
		});

		return lineOffsets;
	}

	setContainerWidth(width: number) {
		this.width = width;

		return this;
	}

	setFontSize(fontSize: number) {
		this.fontSize = fontSize;
		this.lineHeight = fontSize;

		return this;
	}

	measure(nextContent: string, constraints: ElementConstraint | null = null) {
		const [element, container] = this.createElements(constraints);

		element.innerHTML = this.convertWordsToSpans(nextContent);

		const properties = {
			// change to offsetWidth when we go to fix this, inline elements have a zero value for clientWidth/height
			width: element.clientWidth,
			height: element.clientHeight,
			lineOffsets: this.calculateLineOffsets(element),
		};

		container.remove();

		return properties;
	}
}
