import React from "react";

import styles from "./Comment.module.scss";

interface Props {
	onDelete: () => void;
}

const OptionsMenu: React.FunctionComponent<Props> = ({ onDelete }) => {
	return (
		<div className={styles.OptionsMenu}>
			<p onClick={onDelete}>Delete</p>
		</div>
	);
};

export default OptionsMenu;
