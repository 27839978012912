import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import {
	Button,
	Job,
	JobStep,
	JobTimelineStepForm,
	LogItem,
	PersonAssignmentOption,
	ShareLink,
	UsersList,
} from "design-system";
import { convertToMoment } from "../../../../../utils/helpers";
import StepAssignees from "./StepAssignees";
import { updateJobStepAssignees } from "../../../jobActions";
import { sendRequestToAPI } from "../../../../../sharedActions";
import { JobAPI } from "../../../../../api/JobAPI";
import { useDispatch, useSelector } from "react-redux";
import Action from "./Action";
import CompleterCounter from "./CompleterCounter";
import { navigate } from "@reach/router";
import { InitialState } from "../../../../../initialState";
import classNames from "classnames";
import { usePrevious } from "../../../../../utils/hooks/usePrevious";

import styles from "./OpenSteps.module.scss";

interface Props {
	job: Job;
	jobStep: JobStep;
	users: UsersList;
	logItem: LogItem;
}

function hasCompletedContent(jobStep: JobStep) {
	if (jobStep.assignment.assignees.length === 0) {
		return false;
	}

	return (
		jobStep.assignment.requirements.minimumCompleters > 1 &&
		jobStep.completedBy !== null &&
		jobStep.completedBy.length > 0 &&
		jobStep.fields.length > 0
	);
}

function animateIn(ref: MutableRefObject<HTMLDivElement | null>) {
	if (!ref.current) return;
	ref.current.style.visibility = "visible";
	ref.current.style.position = "static";
	ref.current.style.opacity = "1";
	ref.current.style.transform = "translateY(0)";
}

function animateOut(ref: MutableRefObject<HTMLDivElement | null>) {
	if (!ref.current) return;
	ref.current.style.visibility = "hidden";
	ref.current.style.position = "absolute";
	ref.current.style.opacity = "0";
	ref.current.style.transform = "translateY(10px)";
	ref.current.style.zIndex = "-1";
}

const OpenStep: React.FunctionComponent<Props> = ({ jobStep, job, users, logItem }) => {
	const dispatch = useDispatch();
	const openStepRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const completedContentRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const startedAtDate = convertToMoment(logItem.timestamp);
	const jobFields = useSelector((state: InitialState) => state.jobsFields[job.key]);
	const [showCompletedContent, setShowCompletedContent] = useState(false);

	const isQueuedForAnimation = useSelector((state: InitialState) =>
		state.animationsQueue.includes(logItem.elementKey)
	);
	const prevIsQueuedForAnimation = usePrevious(isQueuedForAnimation);

	useEffect(() => {
		if (prevIsQueuedForAnimation === false && isQueuedForAnimation) {
			if (!openStepRef.current) return;

			openStepRef.current.classList.add(styles.Hide);
		}
	}, [isQueuedForAnimation]);

	useEffect(() => {
		if (!ref.current) return;

		if (showCompletedContent) {
			animateOut(ref);

			setTimeout(() => {
				animateIn(completedContentRef);
			}, 150);
		} else {
			animateOut(completedContentRef);

			setTimeout(() => {
				animateIn(ref);
			}, 150);
		}
	}, [showCompletedContent]);

	function handleUpdateAssignments(assignees: Array<PersonAssignmentOption>) {
		dispatch(updateJobStepAssignees(jobStep.key, assignees));
		dispatch(sendRequestToAPI(JobAPI.updateJobStepAssignments(job.key, jobStep.key, assignees)));
	}

	const classes = classNames([
		styles.OpenStep,
		showCompletedContent && styles.ShowCompletedContent,
		!isQueuedForAnimation && styles.Show,
	]);

	return (
		<div className={classes} ref={openStepRef}>
			<div className={styles.StepDetails} ref={ref}>
				<div className={styles.BasicDetails}>
					<h3>{jobStep.step.name}</h3>
					<span>
						Started On {startedAtDate.format("MMM Do")}&nbsp;at&nbsp;
						{startedAtDate.format("h:mm A")}
					</span>

					{hasCompletedContent(jobStep) && (
						<div className={styles.ShowCompletedContentBtn}>
							<Button
								theme="soft"
								tone="light"
								size="compact"
								onClick={() => setShowCompletedContent(true)}
							>
								Show more
							</Button>
						</div>
					)}
				</div>
				<div className={styles.Assignees}>
					<StepAssignees
						jobStep={jobStep}
						users={users}
						onAssignmentsChanged={handleUpdateAssignments}
					/>
					<CompleterCounter jobStep={jobStep} />
				</div>
				<div className={styles.Actions}>
					<div>
						<Action jobStep={jobStep} job={job} />
						{jobStep.publicUrl !== null && <ShareLink linkUrl={jobStep.publicUrl} />}
					</div>
				</div>
			</div>
			<div className={styles.CompletedContent} ref={completedContentRef}>
				<div className={styles.BackButon}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="33"
						height="33"
						viewBox="0 0 33 33"
						onClick={() => setShowCompletedContent(false)}
					>
						<g transform="translate(-364 -391)">
							<g transform="translate(364 391)" fill="#fff" stroke="#caced9" strokeWidth="1">
								<circle cx="16.5" cy="16.5" r="16.5" stroke="none" />
								<circle cx="16.5" cy="16.5" r="16" fill="none" />
							</g>
							<g transform="translate(-239.657 -417.631)">
								<g transform="translate(609.683 824.185)">
									<path
										d="M629.59,828.364H610.728a1.355,1.355,0,0,1,0-2.638H629.59a1.355,1.355,0,0,1,0,2.638Z"
										transform="translate(-609.684 -825.726)"
										fill="#caced9"
									/>
								</g>
								<g transform="translate(609.68 815.91)">
									<path
										d="M621.752,832.8a1.319,1.319,0,0,1-.851,2.327,1.328,1.328,0,0,1-.843-.3l-9.905-8.312a1.008,1.008,0,0,1-.16-.169,1.152,1.152,0,0,1-.126-.177.064.064,0,0,1-.017-.034.752.752,0,0,1-.067-.143.618.618,0,0,1-.067-.211,1.165,1.165,0,0,1,0-.556.813.813,0,0,1,.084-.253.046.046,0,0,1,.025-.042.462.462,0,0,1,.067-.126.557.557,0,0,1,.1-.143,1.587,1.587,0,0,1,.169-.169l.042-.034,9.855-8.245a1.32,1.32,0,1,1,1.694,2.023l-8.7,7.267Z"
										transform="translate(-609.68 -815.91)"
										fill="#98a0b3"
									/>
								</g>
							</g>
						</g>
					</svg>
				</div>
				<div>
					<h2>{jobStep.step.name}</h2>
					<JobTimelineStepForm
						jobStep={jobStep}
						jobFields={jobFields}
						jobStepFields={jobStep.fields}
					/>
				</div>
			</div>
		</div>
	);
};

export default OpenStep;
