import {
	AccessToken,
	ExternalEndpointResponseVariable,
	Webhook,
	ExternalEndpointResponse,
} from "design-system/src/types/developers";
import {
	ACTIVATE_ALL_EXTERNAL_ENDPOINT_RESPONSE_VARIABLES,
	CLEANUP_EXTERNAL_ENDPOINT,
	CREATE_ACCESS_TOKEN,
	CREATE_EXTERNAL_ENDPOINT,
	CREATE_WEBHOOK,
	DELETE_ACCESS_TOKEN,
	DELETE_EXTERNAL_ENDPOINT,
	DELETE_EXTERNAL_ENDPOINT_RESPONSE,
	DELETE_EXTERNAL_ENDPOINT_SETTING,
	DELETE_WEBHOOK,
	SET_EXTERNAL_ENDPOINT_RESPONSE,
	STORE_PARTIAL_ACCESS_TOKEN,
	UPDATE_EXTERNAL_ENDPOINT,
	UPDATE_EXTERNAL_ENDPOINT_PROPERTY,
	UPDATE_EXTERNAL_ENDPOINT_RESPONSE_VARIABLE,
	UPDATE_EXTERNAL_ENDPOINT_SETTING,
	UPDATE_SELECTED_EXTERNAL_ENDPOINT_RESPONSE,
	UPDATE_WEBHOOK,
} from "../../actionTypes";

export function createAccessToken(token: AccessToken) {
	return {
		type: CREATE_ACCESS_TOKEN,
		token,
	};
}

export function storePartialAccessToken(token: string, key: string) {
	return {
		type: STORE_PARTIAL_ACCESS_TOKEN,
		token,
		key,
	};
}

export function deleteAccessToken(key: string) {
	return {
		type: DELETE_ACCESS_TOKEN,
		key,
	};
}

export function createWebhook(webhook: Webhook) {
	return {
		type: CREATE_WEBHOOK,
		webhook,
	};
}

export function updateWebhook(webhook: Webhook) {
	return {
		type: UPDATE_WEBHOOK,
		webhook,
	};
}

export function deleteWebhook(webhookKey: string) {
	return {
		type: DELETE_WEBHOOK,
		webhookKey,
	};
}

export function createExternalEndpoint(endpoint: any) {
	return {
		type: CREATE_EXTERNAL_ENDPOINT,
		endpoint,
	};
}

export function updateExternalEndpoint(endpoint: any) {
	return {
		type: UPDATE_EXTERNAL_ENDPOINT,
		endpoint,
	};
}

export function updateExternalEndpointProperty(endpointKey: string, property: string, value: any) {
	return {
		type: UPDATE_EXTERNAL_ENDPOINT_PROPERTY,
		endpointKey,
		property,
		value,
	};
}

export function updateExternalEndpointSetting(endpointKey: string, settingName: string, value: any) {
	return {
		type: UPDATE_EXTERNAL_ENDPOINT_SETTING,
		endpointKey,
		settingName,
		value,
	};
}

export function deleteExternalEndpointSetting(endpointKey: string, settingName: string) {
	return {
		type: DELETE_EXTERNAL_ENDPOINT_SETTING,
		endpointKey,
		settingName,
	};
}

export function deleteExternalEndpoint(endpointKey: string) {
	return {
		type: DELETE_EXTERNAL_ENDPOINT,
		endpointKey,
	};
}

export function updateSelectedExternalEndpointResponse(responseKey: string) {
	return {
		type: UPDATE_SELECTED_EXTERNAL_ENDPOINT_RESPONSE,
		responseKey,
	};
}

export function setExternalEndpointResponse(
	endpointKey: string,
	variables: Array<ExternalEndpointResponseVariable>,
	response: ExternalEndpointResponse
) {
	return {
		type: SET_EXTERNAL_ENDPOINT_RESPONSE,
		endpointKey,
		variables,
		response,
	};
}

export function deleteExternalEndpointResponse(endpointKey: string, responseKey: string) {
	return {
		type: DELETE_EXTERNAL_ENDPOINT_RESPONSE,
		endpointKey,
		responseKey,
	};
}

export function updateExternalEndpointResponseVariable(
	endpointKey: string,
	variableKey: string,
	variableName: string,
	value: any
) {
	return {
		type: UPDATE_EXTERNAL_ENDPOINT_RESPONSE_VARIABLE,
		endpointKey,
		variableKey,
		variableName,
		value,
	};
}

export function activateAllResponseVariables(endpointKey: string) {
	return {
		type: ACTIVATE_ALL_EXTERNAL_ENDPOINT_RESPONSE_VARIABLES,
		endpointKey,
	};
}

export function cleaupExternalEndpoint() {
	return {
		type: CLEANUP_EXTERNAL_ENDPOINT,
	};
}
