import React, { useEffect, useState } from "react";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { DropdownItem, JobElementType, JobStep, LogItem, Spinner } from "design-system";
import { InitialState } from "../../../../initialState";
import Sidebar from "./Sidebar/Sidebar";
import { INITIAL_RENDER_ONLY } from "../../../../utils/constants";
import { extractUserFromLog, jobIsNotLoaded } from "../../../../utils/helpers";
import { clearJobStepWaiting } from "../../jobActions";
import { fetchFromApi } from "../../../../sharedActions";
import { JobAPI } from "../../../../api/JobAPI";
import Main from "./Main";
import OpenSteps from "./OpenSteps/OpenSteps";
import History from "./History/History";

import styles from "./JobOverview.module.scss";

interface Props extends RouteComponentProps {}

const timelineEvents = ["step_started", "action_created", "delay_started", "comment_created"];

const TimelineViewOption: DropdownItem = { label: "Overview", key: "overview" };

const JobOverview: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();

	const [selectedView, setSelectedView] = useState<DropdownItem>(TimelineViewOption);

	const jobs = useSelector((state: InitialState) => state.jobs);
	const jobLogs = useSelector((state: InitialState) => state.jobLogs[params.jobKey]);
	const workflows = useSelector((state: InitialState) => state.workflows);
	const users = useSelector((state: InitialState) => state.users);
	const openJob = useSelector((state: InitialState) => state.openJob);
	const jobSteps = useSelector((state: InitialState) => state.jobsSteps);
	const job = jobs[params.jobKey];

	useEffect(() => {
		if (openJob.nextInstructions !== null) {
			dispatch(clearJobStepWaiting());
		}
	}, [openJob.nextInstructions]);

	useEffect(() => {
		window.scrollTo(0, 0);

		if (jobIsNotLoaded(params.jobKey)) {
			dispatch(fetchFromApi(JobAPI.loadFullJob(params.jobKey)));
			dispatch(fetchFromApi(JobAPI.loadActiveStepBlueprintsForJob(params.jobKey)));
		}
	}, INITIAL_RENDER_ONLY);

	if (!job || !job.loaded || !jobLogs) {
		return (
			<div>
				<Spinner size="small-medium" />
			</div>
		);
	}

	let workflowKeysOrdered: Array<string> = [];
	let eventsByWorkflow: { [workflowKey: string]: Array<LogItem> } = {};
	let openSteps: Array<LogItem> = [];
	let collaborators: Array<string> = [job.startedBy];
	let mostRecentWorkflowKey = "";

	jobLogs.forEach((logItem: LogItem) => {
		if (logItem.workflowKey !== null && !workflows[logItem.workflowKey]) {
			return;
		}

		if (logItem.event === "user_step_completed") {
			const userKey = extractUserFromLog(logItem);
			if (userKey && !collaborators.includes(userKey)) {
				collaborators.push(userKey);
			}
		}

		if (logItem.event === "route_direction_changed" && logItem.elementType === JobElementType.Step) {
			const index = openSteps.findIndex(
				(openStepLogItem) => openStepLogItem.elementKey === logItem.elementKey
			);
			openSteps.splice(index, 1);
		}

		if (!timelineEvents.includes(logItem.event)) return;

		if (logItem.workflowKey && !workflowKeysOrdered.includes(logItem.workflowKey)) {
			workflowKeysOrdered.unshift(logItem.workflowKey);
		}

		if (!eventsByWorkflow[logItem.workflowKey]) {
			eventsByWorkflow[logItem.workflowKey] = [];
		}

		if (logItem.workflowKey === null) {
			// Hack for job_comment_created because it doesn't belong to a specific workflow
			// in the future we might want to allow users to specify a workflow to attach
			// the comment to when there are more then one workflows in a job
			logItem.workflowKey = workflowKeysOrdered[0];
		}

		// Collect the open steps
		if (logItem.elementType === JobElementType.Step && job.completedAt === null) {
			const jobStep: JobStep = jobSteps[logItem.elementKey];

			if (!jobStep) {
				return;
			}

			if (jobStep.completedAt === null) {
				openSteps.push(logItem);

				const userKey = extractUserFromLog(logItem);
				if (userKey && !collaborators.includes(userKey)) {
					collaborators.push(userKey);
				}

				mostRecentWorkflowKey = logItem.workflowKey;

				return;
			}
		}

		eventsByWorkflow[logItem.workflowKey].unshift(logItem);

		const userKey = extractUserFromLog(logItem);
		if (userKey && !collaborators.includes(userKey)) {
			collaborators.push(userKey);
		}

		if (logItem.workflowKey) {
			mostRecentWorkflowKey = logItem.workflowKey;
		}
	});

	function updateView(view: DropdownItem) {
		if (view.key === "log") {
			navigate(`/jobs/${params.jobKey}/log`);

			return;
		}

		setSelectedView(view);
	}

	return (
		<div className={styles.JobOverview}>
			<Sidebar
				selectedView={selectedView}
				updateView={updateView}
				job={job}
				mostRecentWorkflowKey={mostRecentWorkflowKey as string}
				workflowKeys={workflowKeysOrdered}
				workflows={workflows}
				users={users}
				collaborators={collaborators}
			/>
			<Main>
				<OpenSteps job={job} openSteps={openSteps} users={users} />
				<History
					job={job}
					workflowKeys={workflowKeysOrdered}
					eventsByWorkflow={eventsByWorkflow}
					users={users}
				/>
			</Main>
		</div>
	);
};

export default JobOverview;
