import { combineEpics, Epic, ofType } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import { AnyAction } from "redux";
import { concat, EMPTY, from, of } from "rxjs";
import { loadingJobs } from "../../components/Jobs/jobActions";
import { apiRequestFailed } from "../../components/Account/accountActions";
import { ViewAPI } from "../ViewAPI";
import { fetchFromApi, sendRequestToAPI } from "../../sharedActions";
import { JobAPI } from "../JobAPI";
import {
	DELETE_JOB_TABLE_VIEW,
	RENAME_JOB_TABLE_VIEW,
	RESIZE_JOB_TABLE_VIEW_COLUMN,
	SAVE_NEW_JOB_TABLE_VIEW,
	SET_TABLE_DEFAULT_VIEW,
	UPDATE_TABLE_VIEW_ACCESS,
	UPDATE_TABLE_VIEW_COLUMNS,
	UPDATE_TABLE_VIEW_FILTERS,
} from "../../actionTypes";
import { InitialState } from "../../initialState";
import { Account, TableColumn } from "design-system";
import { isAllJobsView } from "../../utils/helpers";

const createView: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(SAVE_NEW_JOB_TABLE_VIEW),
		mergeMap((action) => {
			return concat(
				of(loadingJobs()),
				from(ViewAPI.createView(action.view)).pipe(
					map((response) => fetchFromApi(JobAPI.loadJobs(response.data.data.viewKey))),
					catchError((error) => of(apiRequestFailed(error)))
				)
			);
		})
	);
};

const updateFilters: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(UPDATE_TABLE_VIEW_FILTERS),
		mergeMap((action) => {
			const state = state$.value as InitialState;

			return concat(
				of(loadingJobs()),
				from(ViewAPI.updateFilters(action.viewKey, action.filters)).pipe(
					map((response) =>
						fetchFromApi(JobAPI.loadJobs(state.jobTableViewControls.selectedViewKey as string))
					),
					catchError((error) => of(apiRequestFailed(error)))
				)
			);
		})
	);
};

const deleteView: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(DELETE_JOB_TABLE_VIEW),
		mergeMap((action) => {
			const state = state$.value as InitialState;
			return of(
				sendRequestToAPI(ViewAPI.deleteView(action.viewKey)),
				loadingJobs(),
				fetchFromApi(JobAPI.loadJobs(`all_${(state.account as Account).key}`))
			);
		})
	);
};

const syncViewChangeToAPI: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(
			UPDATE_TABLE_VIEW_COLUMNS,
			UPDATE_TABLE_VIEW_ACCESS,
			SET_TABLE_DEFAULT_VIEW,
			RENAME_JOB_TABLE_VIEW,
			RESIZE_JOB_TABLE_VIEW_COLUMN
		),
		map((action: AnyAction) => {
			const state = state$.value as InitialState;

			if (action.type === UPDATE_TABLE_VIEW_COLUMNS) {
				return sendRequestToAPI(ViewAPI.updateColumns(action.viewKey, action.columns));
			}

			if (
				action.type === RESIZE_JOB_TABLE_VIEW_COLUMN &&
				!isAllJobsView(state.jobTableViewControls.selectedViewKey)
			) {
				const nextColumn = state.jobTableViews[
					state.jobTableViewControls.selectedViewKey as string
				].columns.find((column) => column.key === action.columnKey) as TableColumn;

				return sendRequestToAPI(
					ViewAPI.updateColumnWidth(
						state.jobTableViewControls.selectedViewKey as string,
						action.columnKey,
						nextColumn.width
					)
				);
			}

			if (action.type === UPDATE_TABLE_VIEW_ACCESS) {
				return sendRequestToAPI(ViewAPI.updateAccess(action.viewKey, action.access));
			}

			if (action.type === SET_TABLE_DEFAULT_VIEW) {
				return sendRequestToAPI(
					ViewAPI.setDefaultView(state.jobTableViewControls.selectedViewKey as string)
				);
			}

			if (action.type === RENAME_JOB_TABLE_VIEW) {
				return sendRequestToAPI(ViewAPI.renameView(action.viewKey, action.name));
			}

			return EMPTY;
		})
	);
};

export default combineEpics(createView, updateFilters, syncViewChangeToAPI, deleteView);
