import { InitialState } from "../../initialState";
import {
	ACTIVATE_ALL_EXTERNAL_ENDPOINT_RESPONSE_VARIABLES,
	CLEANUP_EXTERNAL_ENDPOINT,
	CREATE_ACCESS_TOKEN,
	CREATE_EXTERNAL_ENDPOINT,
	CREATE_WEBHOOK,
	DELETE_ACCESS_TOKEN,
	DELETE_EXTERNAL_ENDPOINT,
	DELETE_EXTERNAL_ENDPOINT_RESPONSE,
	DELETE_EXTERNAL_ENDPOINT_SETTING,
	DELETE_WEBHOOK,
	SET_ACCESS_TOKENS,
	SET_EXTERNAL_ENDPOINT,
	SET_EXTERNAL_ENDPOINT_PARTS,
	SET_EXTERNAL_ENDPOINT_RESPONSE,
	SET_EXTERNAL_ENDPOINTS,
	SET_WEBHOOKS,
	STORE_PARTIAL_ACCESS_TOKEN,
	UPDATE_EXTERNAL_ENDPOINT,
	UPDATE_EXTERNAL_ENDPOINT_PROPERTY,
	UPDATE_EXTERNAL_ENDPOINT_RESPONSE_VARIABLE,
	UPDATE_EXTERNAL_ENDPOINT_SETTING,
	UPDATE_SELECTED_EXTERNAL_ENDPOINT_RESPONSE,
	UPDATE_WEBHOOK,
} from "../../actionTypes";
import { normalizeBasic } from "../../data/normalize/basic";
import { LoadState } from "design-system";

export function developers(state: InitialState, action: any) {
	if (action.type === SET_ACCESS_TOKENS) {
		return {
			...state,
			accessTokens: normalizeBasic("accessTokens", action.tokens),
			loadStates: {
				...state.loadStates,
				accessTokens: LoadState.LOADED,
			},
		};
	}

	if (action.type === CREATE_ACCESS_TOKEN) {
		return {
			...state,
			accessTokens: Object.assign({}, state.accessTokens, {
				[action.token.key]: action.token,
			}),
		};
	}

	if (action.type === STORE_PARTIAL_ACCESS_TOKEN) {
		return {
			...state,
			accessTokens: Object.assign({}, state.accessTokens, {
				[action.key]: {
					...state.accessTokens[action.key],
					token: action.token,
				},
			}),
		};
	}

	if (action.type === DELETE_ACCESS_TOKEN) {
		let accessTokens = { ...state.accessTokens };

		delete accessTokens[action.key];

		return {
			...state,
			accessTokens: Object.assign({}, accessTokens),
		};
	}

	if (action.type === SET_EXTERNAL_ENDPOINTS) {
		let existingEndpoints = { ...state.externalEndpoints };
		let endpoints = normalizeBasic("externalEndpoints", action.externalEndpoints);

		Object.keys(existingEndpoints).forEach((endpointKey) => {
			endpoints[endpointKey] = {
				...endpoints[endpointKey],
				...existingEndpoints[endpointKey],
			};
		});

		return {
			...state,
			externalEndpoints: endpoints,
			loadStates: {
				...state.loadStates,
				externalEndpoints: LoadState.LOADED,
			},
		};
	}

	if (action.type === SET_EXTERNAL_ENDPOINT) {
		return {
			...state,
			externalEndpoints: {
				...state.externalEndpoints,
				[action.externalEndpoint.key]: action.externalEndpoint,
			},
		};
	}

	if (action.type === SET_EXTERNAL_ENDPOINT_RESPONSE) {
		const response = action.response;
		const variables = normalizeBasic("variables", action.variables);

		return {
			...state,
			externalEndpoints: {
				...state.externalEndpoints,
				[action.endpointKey]: {
					...state.externalEndpoints[action.endpointKey],
					variables: { ...state.externalEndpoints[action.endpointKey].variables, ...variables },
					responses: [...state.externalEndpoints[action.endpointKey].responses, response.key],
					loaded: true,
				},
			},
			local: {
				...state.local,
				externalEndpoints: {
					...state.local.externalEndpoints,
					selectedResponseKey: response.key,
				},
			},
			externalEndpointResponses: {
				...state.externalEndpointResponses,
				[response.key]: response,
			},
		};
	}

	if (action.type === SET_EXTERNAL_ENDPOINT_PARTS) {
		const responses = normalizeBasic("externalEndpointResponses", action.responses);
		const variables = normalizeBasic("variables", action.variables);

		let selectedResponseKey = null;
		if (action.responses.length > 0) {
			selectedResponseKey = action.responses[0].key;
		}

		return {
			...state,
			externalEndpoints: {
				...state.externalEndpoints,
				[action.endpointKey]: {
					...state.externalEndpoints[action.endpointKey],
					variables,
					responses: Object.keys(responses),
					loaded: true,
				},
			},
			// local: {
			// 	...state.local,
			// 	externalEndpoints: {
			// 		...state.local.externalEndpoints,
			// 		selectedResponseKey,
			// 	},
			// },
			externalEndpointResponses: {
				...state.externalEndpointResponses,
				...responses,
			},
		};
	}

	if (action.type === CREATE_EXTERNAL_ENDPOINT || action.type === UPDATE_EXTERNAL_ENDPOINT) {
		return {
			...state,
			externalEndpoints: Object.assign({}, state.externalEndpoints, {
				[action.endpoint.key]: action.endpoint,
			}),
		};
	}

	if (action.type === UPDATE_EXTERNAL_ENDPOINT_PROPERTY) {
		return {
			...state,
			externalEndpoints: Object.assign({}, state.externalEndpoints, {
				[action.endpointKey]: {
					...state.externalEndpoints[action.endpointKey],
					[action.property]: action.value,
				},
			}),
		};
	}

	if (action.type === UPDATE_EXTERNAL_ENDPOINT_SETTING) {
		return {
			...state,
			externalEndpoints: Object.assign({}, state.externalEndpoints, {
				[action.endpointKey]: {
					...state.externalEndpoints[action.endpointKey],
					settings: {
						...state.externalEndpoints[action.endpointKey].settings,
						[action.settingName]: action.value,
					},
				},
			}),
		};
	}

	if (action.type === DELETE_EXTERNAL_ENDPOINT_SETTING) {
		let endpoint = {
			...state.externalEndpoints[action.endpointKey],
			settings: {
				...state.externalEndpoints[action.endpointKey],
			},
		};

		// @ts-ignore
		delete endpoint.settings[action.settingName];

		return {
			...state,
			externalEndpoints: Object.assign({}, state.externalEndpoints, {
				[endpoint.key]: endpoint,
			}),
		};
	}

	if (action.type === DELETE_EXTERNAL_ENDPOINT) {
		let endpoints = { ...state.externalEndpoints };

		delete endpoints[action.endpointKey];

		return {
			...state,
			externalEndpoints: endpoints,
		};
	}

	if (action.type === UPDATE_SELECTED_EXTERNAL_ENDPOINT_RESPONSE) {
		return {
			...state,
			local: {
				...state.local,
				externalEndpoints: {
					...state.local.externalEndpoints,
					selectedResponseKey: action.responseKey,
				},
			},
		};
	}

	if (action.type === DELETE_EXTERNAL_ENDPOINT_RESPONSE) {
		let externalEndpointResponses = {
			...state.externalEndpointResponses,
		};

		delete externalEndpointResponses[action.responseKey];

		let nextSelectedResponseKey = null;

		let nextEndpoints = {
			...state.externalEndpoints,
			[action.endpointKey]: {
				...state.externalEndpoints[action.endpointKey],
				responses: state.externalEndpoints[action.endpointKey].responses.filter(
					(key) => key !== action.responseKey
				),
			},
		};

		// @ts-ignore
		if (nextEndpoints[action.endpointKey].responses.length > 0) {
			// @ts-ignore
			nextSelectedResponseKey = nextEndpoints[action.endpointKey].responses[0];
		}

		return {
			...state,
			externalEndpoints: nextEndpoints,
			local: {
				...state.local,
				externalEndpoints: {
					...state.local.externalEndpoints,
					selectedResponseKey: nextSelectedResponseKey,
				},
			},
			externalEndpointResponses,
		};
	}

	if (action.type === UPDATE_EXTERNAL_ENDPOINT_RESPONSE_VARIABLE) {
		return {
			...state,
			externalEndpoints: Object.assign({}, state.externalEndpoints, {
				[action.endpointKey]: {
					...state.externalEndpoints[action.endpointKey],
					variables: {
						...state.externalEndpoints[action.endpointKey].variables,
						[action.variableKey]: {
							...state.externalEndpoints[action.endpointKey].variables[action.variableKey],
							[action.variableName]: action.value,
						},
					},
				},
			}),
		};
	}

	if (action.type === ACTIVATE_ALL_EXTERNAL_ENDPOINT_RESPONSE_VARIABLES) {
		let endpoint = {
			...state.externalEndpoints[action.endpointKey],
		};

		Object.keys(endpoint.variables).forEach((variableKey: string) => {
			endpoint.variables[variableKey] = {
				...endpoint.variables[variableKey],
				active: true,
			};
		});

		return {
			...state,
			externalEndpoints: {
				...state.externalEndpoints,
				[action.endpointKey]: endpoint,
			},
		};
	}

	if (action.type === CLEANUP_EXTERNAL_ENDPOINT) {
		return {
			...state,
			local: {
				...state.local,
				externalEndpoints: {
					selectedResponseKey: null,
				},
			},
		};
	}

	if (action.type === SET_WEBHOOKS) {
		return {
			...state,
			webhooks: normalizeBasic("webhooks", action.webhooks),
			loadStates: {
				...state.loadStates,
				webhooks: LoadState.LOADED,
			},
		};
	}

	if (action.type === CREATE_WEBHOOK || action.type === UPDATE_WEBHOOK) {
		return {
			...state,
			webhooks: Object.assign({}, state.webhooks, {
				[action.webhook.key]: action.webhook,
			}),
		};
	}

	if (action.type === DELETE_WEBHOOK) {
		let webhooks = { ...state.webhooks };

		delete webhooks[action.webhookKey];

		return {
			...state,
			webhooks,
		};
	}

	return state;
}
