import React from "react";
import { Button, Modal, Space, Spinner } from "design-system";
import { useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import { Mark30Greyscale } from "../Logo/Logos";

import styles from "./SystemError.module.scss";

interface Props {}

const LostInternetConnection: React.FunctionComponent<Props> = () => {
	const hasInternet = useSelector((state: InitialState) => state.hasInternet);

	return (
		<Modal show={hasInternet === false} toggleModal={() => null}>
			<div className={styles.LostInternetConnection}>
				{Mark30Greyscale}

				<Space amount={16} />
				<div>
					<h2>You're No Longer Connected</h2>
					<p>
						Prestavi requires internet connectivity, please ensure that you're connected to the
						internet and reload the page.
					</p>
				</div>

				<Space amount={16} />
				<Button
					color="primary"
					theme="solid"
					tone="light"
					size="standard"
					onClick={() => window.location.reload()}
				>
					Reload Page
				</Button>
			</div>
		</Modal>
	);
};

export default LostInternetConnection;
