import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../initialState";
import { ApiResponseLogItem } from "design-system";
import { forgetSavedApiResponse } from "../../sharedActions";
import { CAPTURED_UPDATE_BILLING_DETAILS } from "../../capturedResponseTypes";

export function useListenForApiResponse(
	captureKey: string,
	callback: (response: ApiResponseLogItem) => void
) {
	const dispatch = useDispatch();
	const selectedApiResponses = useSelector((state: InitialState) => state.selectedApiResponses);

	useEffect(() => {
		if (selectedApiResponses.hasOwnProperty(captureKey)) {
			const response = selectedApiResponses[captureKey] as ApiResponseLogItem;

			dispatch(forgetSavedApiResponse(captureKey));
			callback(response);
		}
	}, [selectedApiResponses]);
}
