import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/tooltip.css";
import "design-system/dist/main.css";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { store } from "./store";
import { Provider } from "react-redux";
import { calculateRootDOMComponent } from "./utils/rootDomCalculator";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { determineEnv } from "./utils/api";

Sentry.init({
	dsn: "https://67ac0e06501d4cdeb5b6b566620b0802@o450347.ingest.sentry.io/5434741",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.4,
	ignoreErrors: ["ResizeObserver"],
	environment: determineEnv(),
});

ReactDOM.render(
	<Provider store={store}>{calculateRootDOMComponent()}</Provider>,
	document.getElementById("root")
);
