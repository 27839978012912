import { Unsubscribe } from "redux";
import {
	CanvasElementType,
	Connector,
	CursorTypes,
	ElementType,
	StartMarker as StartMarkerInterface,
	Workflow,
} from "design-system";
import { store } from "../../../../../store";
import { Diagram } from "../Diagram";
import {
	changeCursor,
	elementHasNotMoved,
	fetchElementFromStoreByType,
	isNewWorkflow,
	roundedRectangle,
	XYCoords,
} from "../utils";
import { MousePositionCoordinates } from "./ObjectsCreator";
import { DiagramObjectController } from "./DiagramObjectController";
import { ADD_START_MARKER_KEY } from "../../../../../actionTypes";
import { elementDragBegin, elementDragEnd, moveStartMarker, updateStartMarker } from "../../workflowActions";
import { Routes } from "./Routes";
import { RouteSnapping } from "./RouteSnapping";
import { StartMarkerKey } from "../../../../../utils/constants";
import { isEqualShallow } from "../../../../../utils/helpers";
import { WorkflowAPI } from "../../../../../api/WorkflowAPI";
import { sendRequestToAPI } from "../../../../../sharedActions";
import { TextRenderEngine } from "../TextRenderEngine";
import throttle from "lodash.throttle";

export const START_MARKER_PROPERTIES = {
	coordX: 200,
	coordY: 100,
	width: 98,
	height: 36,
	radius: 22,
};

function topRight(coordX: number, width: number) {
	return coordX + width;
}

function verticalCenter(coordY: number, height: number) {
	return coordY + height / 2;
}

let startMarker: StartMarkerInterface;

export class StartMarker implements DiagramObjectController {
	private Diagram: Diagram;
	private readonly unsubscribeStoreFn: Unsubscribe;

	private startMarker: StartMarkerInterface | null = null;
	private initialCoords: XYCoords = { x: 0, y: 0 };
	private mousePositionOffset: XYCoords = { x: 0, y: 0 };
	private connectorPositionOffset: XYCoords = { x: 0, y: 0 };
	private attachedConnector: Connector | null = null;

	private throttledTrackMouseEvent: any;
	private throttledMoveEvent: any;

	private RouteSnapping: RouteSnapping | null = null;
	private zoomVelocityOffset: { x: number; y: number } = { x: 0, y: 0 };

	constructor(Diagram: Diagram) {
		this.Diagram = Diagram;

		this.Diagram.getHitGraph().dispatch({
			type: ADD_START_MARKER_KEY,
		});

		this.throttledMoveEvent = null;
		this.throttledTrackMouseEvent = null;

		this.unsubscribeStoreFn = store.subscribe(this.listen.bind(this));
	}

	handleMouseDown(key: string, mousePosition: MousePositionCoordinates) {
		changeCursor(CursorTypes.GRABBING);
		this.trackMouse = this.trackMouse.bind(this);
		this.move = this.move.bind(this);
		this.stopMoving = this.stopMoving.bind(this);

		this.throttledMoveEvent = throttle(this.trackMouse, 40, { leading: true });
		this.throttledTrackMouseEvent = throttle(this.move, 40, { leading: true });

		window.addEventListener("mousemove", this.throttledTrackMouseEvent);
		window.addEventListener("mousemove", this.throttledMoveEvent);
		window.addEventListener("mouseup", this.stopMoving);

		const startMarker = store.getState().startMarkers[
			this.Diagram.getWorkflowKey()
		] as StartMarkerInterface;

		this.startMarker = { ...startMarker };
		this.initialCoords = { x: startMarker.coordX, y: startMarker.coordY };

		if (startMarker.connectorKey) {
			const connectors = store.getState().connectors;

			this.attachedConnector = connectors[startMarker.connectorKey] as Connector;
			this.connectorPositionOffset = {
				x: mousePosition.x - this.attachedConnector.coordX,
				y: mousePosition.y - this.attachedConnector.coordY,
			};
		}

		this.mousePositionOffset = {
			x: mousePosition.x - this.startMarker.coordX,
			y: mousePosition.y - this.startMarker.coordY,
		};
		const workflow = store.getState().workflows[this.Diagram.getWorkflowKey()] as Workflow;

		const speed = this.Diagram.getZoomVelocity();

		this.zoomVelocityOffset = {
			x: Math.round(mousePosition.x * speed),
			y: Math.round(mousePosition.y * speed),
		};

		this.RouteSnapping = new RouteSnapping(
			this.startMarker,
			ElementType.StartMarker,
			workflow.root,
			this.Diagram.getWorkflowKey()
		);

		store.dispatch(
			elementDragBegin(
				{ x: startMarker.coordX, y: startMarker.coordY },
				StartMarkerKey,
				ElementType.StartMarker,
				START_MARKER_PROPERTIES.width,
				START_MARKER_PROPERTIES.height,
				true
			)
		);
	}

	trackMouse(event: MouseEvent) {
		if (!this.startMarker) return;

		const mousePosition = this.Diagram.getCanvasMousePosition(event, this.zoomVelocityOffset);

		this.startMarker.coordX = mousePosition.x - this.mousePositionOffset.x;
		this.startMarker.coordY = mousePosition.y - this.mousePositionOffset.y;

		const snapOffset: XYCoords = (this.RouteSnapping as RouteSnapping).calculateSnapAmount(
			this.startMarker.coordX,
			this.startMarker.coordY
		);

		this.startMarker.coordX += snapOffset.x;
		this.startMarker.coordY += snapOffset.y;

		if (this.attachedConnector) {
			this.attachedConnector.coordX = mousePosition.x - this.connectorPositionOffset.x + snapOffset.x;
			this.attachedConnector.coordY = mousePosition.y - this.connectorPositionOffset.y + snapOffset.y;
		}
	}

	move(event: MouseEvent) {
		if (!this.startMarker) {
			return;
		}

		store.dispatch(
			moveStartMarker(
				this.startMarker as StartMarkerInterface,
				this.attachedConnector,
				this.Diagram.getWorkflowKey()
			)
		);
	}

	stopMoving(event: MouseEvent) {
		changeCursor(CursorTypes.DEFAULT);

		window.removeEventListener("mousemove", this.throttledTrackMouseEvent);
		window.removeEventListener("mousemove", this.throttledMoveEvent);
		window.removeEventListener("mouseup", this.stopMoving);

		const coordX = Math.round((this.startMarker as StartMarkerInterface).coordX);
		const coordY = Math.round((this.startMarker as StartMarkerInterface).coordY);

		if (elementHasNotMoved(this.initialCoords.x, this.initialCoords.y, coordX, coordY)) {
			store.dispatch(elementDragEnd());
			this.resetProperties();

			return;
		}

		store.dispatch(
			updateStartMarker(this.Diagram.getWorkflowKey(), {
				...this.startMarker,
				coordX,
				coordY,
			})
		);
		store.dispatch(
			sendRequestToAPI(
				WorkflowAPI.updateStartMarker(this.Diagram.getWorkflowKey(), {
					...this.startMarker,
					coordX,
					coordY,
				} as StartMarkerInterface)
			)
		);

		if (this.attachedConnector) {
			store.dispatch(
				sendRequestToAPI(
					WorkflowAPI.updateConnector(this.Diagram.getWorkflowKey(), this.attachedConnector.key, {
						coordX: Math.round(this.attachedConnector.coordX),
						coordY: Math.round(this.attachedConnector.coordY),
					})
				)
			);
		}

		store.dispatch(elementDragEnd());

		this.resetProperties();
	}

	resetProperties() {
		this.startMarker = null;
		this.attachedConnector = null;
		this.connectorPositionOffset = { x: 0, y: 0 };
		this.mousePositionOffset = { x: 0, y: 0 };
		this.RouteSnapping = null;
		this.initialCoords = { x: 0, y: 0 };
		this.throttledMoveEvent = null;
		this.zoomVelocityOffset = { x: 0, y: 0 };
	}

	handleClick() {}

	virtualize(startMarker: StartMarkerInterface, context: CanvasRenderingContext2D) {
		context.fillStyle = this.Diagram.getHitGraph().getRgbColorStringFromElementKey(StartMarkerKey);

		roundedRectangle({
			context,
			coordX: startMarker.coordX,
			coordY: startMarker.coordY,
			width: START_MARKER_PROPERTIES.width,
			height: START_MARKER_PROPERTIES.height,
			radius: START_MARKER_PROPERTIES.radius,
		});
		context.fill();
	}

	handleHoverStart(key: string, mousePosition: MousePositionCoordinates) {}
	handleHoverEnd(key: string) {}

	calculateConnectedElement(startMarker: StartMarkerInterface, workflow: Workflow) {
		if (!workflow.root) {
			if (!startMarker.connectorKey) {
				return { element: null, type: null };
			}

			let connector = store.getState().connectors[startMarker.connectorKey];
			connector.width = 0;
			connector.height = 0;

			return {
				element: connector,
				type: ElementType.Connector,
			};
		}

		let rootElement = fetchElementFromStoreByType(
			workflow.root.type,
			workflow.root.key,
			workflow.key as string
		);

		// if (workflow.root.type === ElementType.Action) {
		// 	rootElement.width = rootElement.details.width;
		// 	rootElement.height = rootElement.details.height;
		// }

		if (workflow.root.type === ElementType.Connector) {
			rootElement.width = 0;
			rootElement.height = 0;
		}

		return {
			element: rootElement,
			type: workflow.root.type,
		};
	}

	render() {
		const workflowKey = this.Diagram.getWorkflowKey();

		if (isNewWorkflow(workflowKey)) return;

		const startMarker: StartMarkerInterface = store.getState().startMarkers[workflowKey];

		const context = this.Diagram.getContext();

		const { height } = START_MARKER_PROPERTIES;
		const workflow = store.getState().workflows[workflowKey];

		this.virtualize(startMarker, this.Diagram.getHitGraph().getContext());

		this.visualizeStartMarker(context, {
			coordX: startMarker.coordX,
			coordY: startMarker.coordY,
			height,
		});

		const { element, type } = this.calculateConnectedElement(startMarker, workflow);

		if (!element || !type) {
			return;
		}

		const Routes = this.Diagram.getObjectsCreator().getByType(CanvasElementType.Routes) as Routes;

		Routes.queue({
			key: `${StartMarkerKey}_line`,
			from: {
				key: StartMarkerKey,
				type: ElementType.StartMarker,
				coordX: startMarker.coordX,
				coordY: startMarker.coordY,
				width: START_MARKER_PROPERTIES.width,
				height: START_MARKER_PROPERTIES.height,
				direction: startMarker.routeFromDirection,
			},
			to: {
				key: element.key,
				type,
				direction: startMarker.routeToDirection,
				coordX: element.coordX,
				coordY: element.coordY,
				width: element.width,
				height: element.height,
			},
			label: null,
			conditions: [],
			conditionsSettings: null,
		});
	}

	visualizeStartMarker(
		context: CanvasRenderingContext2D,
		properties: { coordX: number; coordY: number; height: number }
	) {
		context.fillStyle = "#fff";
		context.strokeStyle = "#E9EBF2";
		context.lineWidth = 1;
		roundedRectangle({
			context,
			...START_MARKER_PROPERTIES,
			coordX: properties.coordX,
			coordY: properties.coordY,
		});
		context.fill();
		context.stroke();

		context.fillStyle = "#44506E";
		context.font = TextRenderEngine.buildFontString("16px");
		context.textBaseline = "middle";
		context.fillText("Start", properties.coordX + 30, properties.coordY + properties.height / 2);
	}

	listen() {
		let previousStartMarker = startMarker;

		startMarker = { ...store.getState().startMarkers[this.Diagram.getWorkflowKey()] };

		if (!isEqualShallow(previousStartMarker, startMarker)) {
			this.Diagram.render();
		}
	}

	cleanup() {
		this.unsubscribeStoreFn();
	}
}
