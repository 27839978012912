import React, { useEffect, useState } from "react";
import { Job, JobComment, JobTimelineCommentEditor, LogItem, Me, UsersList } from "design-system";
import HistoryEventsByWorkflow from "./HistoryEventsByWorkflow";
import classNames from "classnames";
import { store } from "../../../../../store";
import { InitialState } from "../../../../../initialState";
import cuid from "cuid";
import moment from "moment";
import { sendRequestToAPI } from "../../../../../sharedActions";
import { JobAPI } from "../../../../../api/JobAPI";
import { createComment } from "../../../jobActions";
import { useDispatch } from "react-redux";
import { useParams } from "@reach/router";
import WorkflowCompletedMarker from "./WorkflowCompletedMarker";

import styles from "./History.module.scss";

interface Props {
	job: Job;
	workflowKeys: Array<string>;
	eventsByWorkflow: { [workflowKey: string]: Array<LogItem> };
	users: UsersList;
}

const History: React.FunctionComponent<Props> = ({ eventsByWorkflow, workflowKeys, job }) => {
	const dispatch = useDispatch();
	const params = useParams();
	const [expandedElements, setExpandedElements] = useState<Array<string>>([]);
	const [expandAll, setExpandAll] = useState(job.completedAt !== null);

	useEffect(() => {
		if (job.completedAt !== null) {
			setExpandAll(true);
		}
	}, [job.completedAt]);

	function expandElement(elementKey: string) {
		setExpandedElements([...expandedElements, elementKey]);
	}

	function collapseElement(elementKey: string) {
		setExpandedElements([...expandedElements.filter((key) => key !== elementKey)]);
	}

	function expandAllElements() {
		setExpandAll(true);
		setExpandedElements([]);
	}

	function collapseAllElements() {
		setExpandAll(false);
		setExpandedElements([]);
	}

	function saveComment(text: any) {
		const userKey = ((store.getState() as InitialState).me as Me).key;

		const comment: JobComment = {
			key: cuid(),
			userKey,
			jobKey: params.jobKey,
			message: text,
			createdAt: moment().toISOString(),
		};

		dispatch(sendRequestToAPI(JobAPI.createComment(params.jobKey, comment)));
		dispatch(createComment(params.jobKey, comment));
	}

	const expandBtnClasses = classNames([
		styles.ExpandCollapseButton,
		expandAll && styles.Expanded,
		!expandAll && styles.Collapsed,
	]);

	const historyEvents = workflowKeys.map((workflowKey: string, index: number) => {
		return (
			<React.Fragment key={workflowKey}>
				<WorkflowCompletedMarker
					workflowKey={workflowKey}
					workflowsCount={workflowKeys.length}
					index={index}
				/>
				<HistoryEventsByWorkflow
					key={workflowKey}
					job={job}
					workflowKey={workflowKey}
					events={eventsByWorkflow[workflowKey]}
					expandedElements={expandedElements}
					expandAll={expandAll}
					expandElement={expandElement}
					collapseElement={collapseElement}
				/>
			</React.Fragment>
		);
	});

	return (
		<div className={styles.History}>
			<div className={styles.Head}>
				<p>History</p>
				<div
					className={expandBtnClasses}
					onClick={() => (expandAll ? collapseAllElements() : expandAllElements())}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
						<g transform="translate(13 0) rotate(90)">
							<path
								d="M6.223,0A.764.764,0,0,1,6.8.275a1.011,1.011,0,0,1-.009,1.3L2,6.947l4.762,4.777a1.01,1.01,0,0,1,.071,1.3.743.743,0,0,1-1.143.081L.274,7.674A.973.973,0,0,1,0,7.009a.988.988,0,0,1,.241-.681L5.654.265A.759.759,0,0,1,6.223,0Z"
								transform="translate(0 0)"
								fill="#98a0b3"
							/>
						</g>

						<g transform="translate(0 18) rotate(-90)">
							<path
								d="M6.223,0A.764.764,0,0,1,6.8.275a1.011,1.011,0,0,1-.009,1.3L2,6.947l4.762,4.777a1.01,1.01,0,0,1,.071,1.3.743.743,0,0,1-1.143.081L.274,7.674A.973.973,0,0,1,0,7.009a.988.988,0,0,1,.241-.681L5.654.265A.759.759,0,0,1,6.223,0Z"
								transform="translate(0 0)"
								fill="#98a0b3"
							/>
						</g>
					</svg>
				</div>
			</div>
			<JobTimelineCommentEditor onSave={saveComment} />
			<div className={styles.HistoryEvents}>{historyEvents}</div>
		</div>
	);
};

export default History;
