import React from "react";
import { Spinner, CodeBlock } from "design-system";

import styles from "./ResponseBuilder.module.scss";

interface Props {
	headerText: string;
	response: any;
	loading: boolean;
}

function buildResponse(response: any) {
	if (!response) {
		return "Empty Response";
	}

	let codeBlock = JSON.parse(response);

	if (!codeBlock) {
		return "Empty Response";
	}

	codeBlock = Object.fromEntries(Object.entries(codeBlock));

	return JSON.stringify(codeBlock, null, 2);
}

const RawResponsePreview: React.FunctionComponent<Props> = ({ headerText, response, loading }) => {
	return (
		<div className={styles.RawResponsePreview}>
			<div>
				<h1>{headerText}</h1>
			</div>

			<div className={styles.Code}>
				<h5>Json Data Structure</h5>

				{!loading ? (
					<CodeBlock code={buildResponse(response)} />
				) : (
					<Spinner size="small" color="white" />
				)}
			</div>
		</div>
	);
};

export default RawResponsePreview;
