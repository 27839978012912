import { XYCoords } from "./utils";
import { isOnSafari } from "../../../../utils/helpers";
abstract class TextSizeGuide {
	protected fontSize: string = "";
	protected textIndentation: number = 0;
	protected lineHeight: number = 0;

	getFontSize() {
		return this.fontSize;
	}

	getTextIndentation() {
		return this.textIndentation;
	}

	getLineHeight() {
		return this.lineHeight;
	}
}
export class NormalTextSizeGuide extends TextSizeGuide {
	protected fontSize = "16px";
	protected textIndentation = 16;
	protected lineHeight = 20;
}

export class SmallTextSizeGuide extends TextSizeGuide {
	protected fontSize = "14px";
	protected textIndentation = 0;
	protected lineHeight = 18;
}

export class ExtraSmallTextSizeGuide extends TextSizeGuide {
	protected fontSize = "12px";
	protected textIndentation = 0;
	protected lineHeight = 14;
}

export class TinyTextSizeGuide extends TextSizeGuide {
	protected fontSize = "10px";
	protected textIndentation = 0;
	protected lineHeight = 12;
}

export class TextRenderEngine {
	private text: string;
	private coords: XYCoords;
	private offsets: Array<number>;
	private color: string = "#44506E";
	private marginTop: number = 0;
	private sizeGuide: TextSizeGuide;

	constructor(text: string, coords: XYCoords, offsets: Array<number>) {
		this.text = text;
		this.coords = coords;
		this.offsets = offsets;

		this.sizeGuide = new NormalTextSizeGuide();
	}

	setSizing(textSizing: TextSizeGuide) {
		this.sizeGuide = textSizing;

		return this;
	}

	setMarginTop(amount: number) {
		this.marginTop = amount;

		return this;
	}

	setTextColor(hexColorCode: string) {
		this.color = hexColorCode;

		return this;
	}

	static buildFontString(fontSize: string) {
		return `${fontSize} -apple-system, BlinkMacSystemFont, Helvetica, sans-serif`;
	}

	render(context: CanvasRenderingContext2D) {
		context.fillStyle = this.color;
		context.textBaseline = "top";
		context.font = TextRenderEngine.buildFontString(this.sizeGuide.getFontSize());

		let height = this.sizeGuide.getLineHeight();
		const words = this.text.split(" ");

		this.offsets.forEach((startOffset: number, index) => {
			if (this.isLastLine(index)) {
				if (startOffset === 0) {
					this.drawTextLine(context, this.text);

					return;
				}

				// the rest of the words
				const textChunk = words.slice(startOffset, words.length).join(" ");
				this.drawTextLine(context, textChunk, height);

				return;
			}

			const textChunk = words.slice(startOffset, this.offsets[index + 1]).join(" ");

			if (index === 0) {
				height = 0;
			}

			this.drawTextLine(context, textChunk, height);

			height += this.sizeGuide.getLineHeight() - 2;
		});
	}

	drawTextLine(context: CanvasRenderingContext2D, textChunk: string, lineHeight: number = 0) {
		context.fillText(textChunk, this.coords.x, this.coords.y + lineHeight + this.marginTop);
	}

	isLastLine(index: number) {
		return this.offsets[index + 1] === undefined;
	}
}
