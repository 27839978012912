import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import ActivationView from "./ActivationView";
import { INITIAL_RENDER_ONLY, wait } from "../../../utils/constants";
import { determineURL, post, upload } from "../../../utils/api";
import Header from "./Header";
import InvalidTokenPanel from "./InvalidTokenPanel";
import { Stages } from "./Menu";
import { ServerMap } from "../../../utils/serverMap";
import { DropdownItem, ErrorBag, Spinner } from "design-system";
import "moment-timezone";
import axios, { AxiosResponse } from "axios";

import styles from "./Activation.module.scss";

interface Props extends RouteComponentProps {}

export interface ActivationUser {
	image: null | string;
	firstName: string;
	lastName: string;
	password: string;
	company: string;
	timezone: string;
	country: null | DropdownItem;
}

export enum UseCaseTypes {
	COLLAB = "COLLAB",
	MANAGE = "MANAGE",
	AUTOMATION = "AUTOMATION",
	MAPPING = "MAPPING",
}

export enum UserTypes {
	INTERNAL = "INTERNAL",
	EXTERNAL = "EXTERNAL",
	BOTH = "BOTH",
}

export interface AccountPreferences {
	useCases: Array<UseCaseTypes>;
	userType: UserTypes | null;
}

const defaultUser: ActivationUser = {
	image: null,
	firstName: "",
	lastName: "",
	password: "",
	company: "",
	timezone: "",
	country: null,
};

const defaultPreferences: AccountPreferences = {
	useCases: [],
	userType: null,
};

const defaultInvites: Array<string> = ["", "", ""];

const ActivationContainer: React.FunctionComponent<Props> = () => {
	const [token, setToken] = useState("");
	const [zone, setZone] = useState("");
	const [verified, setVerified] = useState(false);
	const [loading, setLoading] = useState(true);
	const [amUploadingImage, setAmUploadingImage] = useState(false);
	const [stageProcessing, setStateProcessing] = useState(false);
	const [currentStage, setCurrentStage] = useState(Stages.Profile);
	const [furthestStage, setFurthestStage] = useState(Stages.Profile);
	const [user, setUser] = useState<ActivationUser>(defaultUser);
	const [preferences, setPreferences] = useState(defaultPreferences);
	const [invites, setInvites] = useState(defaultInvites);
	const [errors, setErrors] = useState<Array<ErrorBag>>([]);
	const [uploadErrors, setUploadErrors] = useState<Array<ErrorBag>>([]);
	const validationErrorPanelRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

	useEffect(() => {
		const params = new URL(window.location.href).searchParams;

		async function verify() {
			const token = params.get("token");

			if (!token) {
				setVerified(false);
				setLoading(false);
				return;
			}

			const serverCode = token.slice(-3);
			const zone = ServerMap.find(serverCode);
			setZone(zone);
			setToken(token);

			const url = determineURL({ zone });

			await axios({
				method: "get",
				url: `${url}/account/setup?token=${token}`,
				withCredentials: true,
			})
				.then((response) => {
					setVerified(true);
					setLoading(false);

					const data = (response as AxiosResponse).data.data;
					setFurthestStage(data.stage);
					setCurrentStage(data.stage);

					if (data.userProfileImage !== null) {
						setUser({
							...user,
							image: data.userProfileImage,
						});
					}
				})
				.catch((error) => {
					if (error.response.status === 404) {
						setVerified(false);
						setLoading(false);
						return;
					}
				});
		}

		verify();
	}, INITIAL_RENDER_ONLY);

	// don't allow users to go back and resubmit
	// that would cause us to create a new account,user,etc.
	// function changeStage(stage: Stages) {
	// 	if (stage > furthestStage) return;
	//
	// 	setCurrentStage(stage);
	// }

	async function uploadImage(data: FormData) {
		setAmUploadingImage(true);
		const response = await upload(`/account/profile-image/upload?token=${token}`, data, { zone });
		await wait(500);

		if (response.status === "error") {
			setUploadErrors(response.errors);
			setAmUploadingImage(false);

			return "error";
		}

		let updatedUser = {
			...user,
		};

		updatedUser.image = response.data.url;
		setUser(updatedUser);
		setAmUploadingImage(false);
	}

	async function removeImage() {
		post("/account/profile-image/reset", { token }, { zone }).catch((error) => {
			console.log(error);
		});

		let updatedUser = { ...user };

		updatedUser.image = null;
		setUser(updatedUser);
	}

	function updateUser(property: string, value: any) {
		let updatedUser = { ...user };

		// @ts-ignore
		updatedUser[property] = value;

		setUser(updatedUser);
	}

	function updatePreference(index: "useCases" | "userType", value: UserTypes | UseCaseTypes) {
		let updatedPreferences = { ...preferences };

		if (index === "userType") {
			updatedPreferences.userType = value as UserTypes;

			setPreferences(updatedPreferences);

			return;
		}

		const useCaseIndex = updatedPreferences.useCases.findIndex((useCase) => value === useCase);
		if (useCaseIndex >= 0) {
			updatedPreferences.useCases.splice(useCaseIndex, 1);
		} else {
			updatedPreferences.useCases.push(value as UseCaseTypes);
		}

		setPreferences(updatedPreferences);
	}

	function updateInvite(index: number, value: string) {
		let updatedInvites = [...invites];

		updatedInvites[index] = value;

		setInvites(updatedInvites);
	}

	function addInvite() {
		let updatedInvites = [...invites];
		updatedInvites.push("");

		setInvites(updatedInvites);
	}

	function saveProfile() {
		const validationErrors: Array<ErrorBag> = [];
		if (!user.firstName.length) {
			validationErrors.push({
				type: "validation",
				key: "firstName",
				message: "Please provide your first name.",
			});
		}

		if (!user.lastName.length) {
			validationErrors.push({
				type: "validation",
				key: "lastName",
				message: "Please provide your last name.",
			});
		}

		if (!user.password.length) {
			validationErrors.push({
				type: "validation",
				key: "password",
				message: "Please provide a password.",
			});
		}

		if (!user.company.length) {
			validationErrors.push({
				type: "validation",
				key: "company",
				message: "Please provide your company name.",
			});
		}

		if (!user.country) {
			validationErrors.push({
				type: "validation",
				key: "country",
				message: "Please provide your country.",
			});
		}

		if (!user.timezone) {
			validationErrors.push({
				type: "validation",
				key: "timezone",
				message: "Please select a timezone.",
			});
		}

		if (validationErrors.length) {
			setErrors(validationErrors);

			return;
		}
		setStateProcessing(true);

		post("/account/profile", { ...user, token, zone }, { zone })
			.then((response) => {
				if ((response as AxiosResponse).data.status === "ok") {
					setErrors([]);
					setStateProcessing(false);
					setCurrentStage(Stages.Account);
					setFurthestStage(Stages.Account);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function savePreferences() {
		setStateProcessing(true);

		post("/account/preferences", { preferences, token }, { zone })
			.then((response) => {
				if ((response as AxiosResponse).data.status === "ok") {
					setStateProcessing(false);
					setCurrentStage(Stages.Team);
					setFurthestStage(Stages.Team);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function saveInvites() {
		// should we do validation on the email addresses?

		const emails = invites.filter((email) => {
			return email.length > 0;
		});

		post("/account/team", { emails, token }, { zone })
			.then((response) => {
				if ((response as AxiosResponse).data.status === "ok") {
					setStateProcessing(false);
					setCurrentStage(Stages.Done);
					setFurthestStage(Stages.Done);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function skipInvites() {
		setCurrentStage(Stages.Done);
		setFurthestStage(Stages.Done);
	}

	function finishActivation() {
		setStateProcessing(true);

		post("/account/ready", { token }, { zone })
			.then((response) => {
				if ((response as AxiosResponse).data.status === "ok") {
					setStateProcessing(false);
					window.location.replace("/workflows");
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	if (loading) {
		return (
			<div>
				<Header />
				<div className={styles.SpinnerBackdrop}>
					<Spinner size="large" />
				</div>
			</div>
		);
	}

	if (!loading && !verified) {
		return <InvalidTokenPanel show={true} />;
	}

	return (
		<ActivationView
			currentStage={currentStage}
			user={user}
			preferences={preferences}
			invites={invites}
			errors={{
				bag: errors,
				ref: validationErrorPanelRef,
			}}
			uploadErrors={uploadErrors}
			amUploadingImage={amUploadingImage}
			stageProcessing={stageProcessing}
			updateUser={updateUser}
			updatePreference={updatePreference}
			updateInvite={updateInvite}
			addInvite={addInvite}
			uploadImage={uploadImage}
			removeImage={removeImage}
			saveProfile={saveProfile}
			savePreferences={savePreferences}
			saveInvites={saveInvites}
			skipInvites={skipInvites}
			finishActivation={finishActivation}
		/>
	);
};

export default ActivationContainer;
