import React from "react";
import { Router } from "@reach/router";
import LoginContainer from "./Login/LoginContainer";
import SignupContainer from "./Signup/SignupContainer";
import ActivationContainer from "./Account/Activation/ActivationContainer";
import UserInviteJoin from "./People/Users/User/Join/UserInviteJoin";
import ForgotPassword from "./Account/ForgotPassword/ForgotPassword";
import ResetPassword from "./Account/ResetPassword/ResetPassword";

const AuthlessEntry: React.FunctionComponent = () => {
	return (
		<div className="App">
			<Router>
				<LoginContainer path="login" />
				<SignupContainer path="signup" />
				<ActivationContainer path="activate" />
				<UserInviteJoin path="join" />
				<ResetPassword path="reset-password" />
				<ForgotPassword path="forgot-password" />
				{/*	 add 404 */}
			</Router>
		</div>
	);
};

export default AuthlessEntry;
