import React from "react";
import { Button, Integration, JobAction, JobActionLog, JobActionStatus, Spinner } from "design-system";
import Status from "../Status";
import { convertCodeToStatus } from "./AutomationDetailsModal";
import { convertToMoment, formatDateForHumans } from "../../../../../../../../utils/helpers";

import styles from "./DetailsModal.module.scss";

interface Props {
	jobAction: JobAction;
	logs: Array<any> | null;
	integration: Integration;
	selectedIndex: number;
	cancelScheduledResend: () => void;
	onClose: () => void;
}

const LogRecord: React.FunctionComponent<Props> = ({
	jobAction,
	logs,
	integration,
	selectedIndex,
	cancelScheduledResend,
	onClose,
}) => {
	if (!logs) {
		return (
			<div className={styles.LogRecord}>
				<Spinner />
			</div>
		);
	}

	const log: JobActionLog = logs[selectedIndex];
	const dateTime = formatDateForHumans(log.timestamp);
	const status: JobActionStatus = convertCodeToStatus(log.status);

	return (
		<div className={styles.LogRecord}>
			<div className={styles.Header}>
				<div className={styles.Icon}>
					<img src={integration.logoUrl} />
				</div>
				<div className={styles.Title}>
					<span>{integration.name}</span>
					<h1>Details</h1>
				</div>
			</div>

			<div className={styles.Details}>
				<div className={styles.Inline}>
					<p>Status</p>
					<Status jobAction={jobAction} />
				</div>
				<div className={styles.Inline}>
					<p>Sent At</p>
					<p className={styles.Subtle}>
						{dateTime?.date}&nbsp;{dateTime?.time}
					</p>
				</div>
				{status === JobActionStatus.Failed && (
					<div className={styles.Reason}>
						<p>Reason</p>
						<p>{log.reason === null ? "No Reason Provided" : log.reason}</p>
					</div>
				)}

				{selectedIndex === 0 && status === JobActionStatus.Failed && log.scheduledAt !== null && (
					<div className={styles.NextAttempt}>
						<p>
							Next Attempt Scheduled for{" "}
							{convertToMoment(log.scheduledAt as string).format("MMMM Do, YYYY, h:mm A")}
						</p>
						<span onClick={cancelScheduledResend}>Cancel</span>
					</div>
				)}

				<div onClick={onClose} style={{ marginTop: "24px" }}>
					<Button theme="solid" tone="light" color="primary" onClick={() => null}>
						Close
					</Button>
				</div>
			</div>
		</div>
	);
};

export default LogRecord;
