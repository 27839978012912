import { DropdownItem, TimeInterval } from "design-system";

export const INITIAL_RENDER_ONLY = [];
export const TOP_NAV_HEIGHT = 55;
export const DETAILS_MENU_HEIGHT = 40;

export const END_PIECE_WIDTH = 85;
export const END_PIECE_HEIGHT = 36;
export const INCOMPLETE_ACTION_WIDTH = 169;
export const CONNECTOR_WIDTH = 35;
export const SINGLE_LINE_OFFSET = [0];

export const FORM_LAYOUT_TILE_HEIGHT = 65;

export const APP_NAME = "Prestavi";
export const WEBSITE_URL = "https://prestavi.com";
export const HOST_NAME = "prestavi";
export const PUBLIC_PAGE_URLS = [
	"/signup",
	"/forgot-password",
	"/reset-password",
	"/login",
	"/public",
	"/activate",
	"/join",
];

export const StartMarkerKey = "starter";
export const AlwaysVisibleContentViewKey = "default";

export const DEFAULT_IMAGE_NAME = "default_profile_image";

export async function wait(ms: number) {
	return new Promise((resolve, reject) => setTimeout(resolve, ms));
}

export function calculateInnerPageHeight() {
	return window.innerHeight - TOP_NAV_HEIGHT;
}

export const intervalDropdownOptions: Array<DropdownItem> = [
	{ label: "Minutes", key: TimeInterval.MINUTES },
	{ label: "Hours", key: TimeInterval.HOURS },
	{ label: "Days", key: TimeInterval.DAYS },
	{ label: "Weeks", key: TimeInterval.WEEKS },
	{ label: "Months", key: TimeInterval.MONTHS },
];
