import React from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import { Account, AccountStatus, AccountType, Button, Modal } from "design-system";
import { navigate, useLocation } from "@reach/router";
import { Logo25 } from "../../Base/Logo/Logos";

import styles from "./AccountStatuses.module.scss";

interface Props {}

const TrialExpired: React.FunctionComponent<Props> = () => {
	const location = useLocation();

	const account = useSelector((state: InitialState) => state.account) as Account;

	if (account.type !== AccountType.Trial) {
		return null;
	}

	if (account.status !== AccountStatus.Expired) {
		return null;
	}

	// is on billing or close account screens
	if (location.pathname.includes("/billing") || location.pathname.includes("/close")) {
		return null;
	}

	return (
		<Modal show={true} toggleModal={() => null} includeBackground={true}>
			<div className={styles.AccountStatusModal}>
				{Logo25}

				<h1>Your trial has ended.</h1>

				<div className={styles.Actions}>
					<Button
						color="primary"
						theme="solid"
						tone="medium"
						size="large"
						onClick={() => navigate(`/billing`)}
					>
						Upgrade Now
					</Button>

					<div
						className={styles.Link}
						onClick={() => window.open("https://prestavi.com/contact-us", "_blank")}
					>
						Contact Support
					</div>

					<div className={styles.Link} onClick={() => navigate(`/close`)}>
						Close Account
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default TrialExpired;
