import React from "react";
import { JobStep, LogItem } from "design-system";
import { useStore } from "react-redux";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const StepStarted: React.FunctionComponent<Props> = ({ logItem }) => {
	const store = useStore();
	const jobStep = store.getState().jobsSteps[logItem.elementKey] as JobStep;

	return (
		<React.Fragment>
			<div className={styles.Indicator}></div>
			<div className={styles.Event}>
				<span>Step Started</span>
				<p>{jobStep ? jobStep.step.name : "Unknown Step"}</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default StepStarted;
