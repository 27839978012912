import React from "react";
import { Checkbox, Dropdown, DropdownItem, ShortText, Space } from "design-system";
import { store } from "../../../store";
import { InitialState } from "../../../initialState";
import classNames from "classnames";

import styles from "./ExternalEndpoints.module.scss";

enum FailedNotificationSubscriberType {
	Initiator = "initiator",
	User = "user",
	Custom = "custom",
}

interface FailedNotificationSubscriber {
	type: FailedNotificationSubscriberType;
	recipient: string;
}

interface Props {
	maxAttemptsOnly: boolean;
	maxAttemptsCount: number | null;
	subscribers: Array<FailedNotificationSubscriber>;
	autoRetryFails: boolean;
	onUpdate: (property: string, value: any) => void;
}

const subscriberTypes = [
	{ key: "initiator", label: "Job Initiator" },
	{ key: "custom", label: "Custom Email Address" },
	{ key: "user", label: "Specific Person" },
];

function calculateSubscriberTypeValue(value: string | null): DropdownItem | null {
	if (value === null) {
		return null;
	}

	const type = subscriberTypes.find((type) => type.key === value);

	if (!type) {
		return null;
	}

	return type;
}

function calculateUserOptions() {
	const users = (store.getState() as InitialState).users;

	return Object.keys(users)
		.sort((keyA, keyB) => {
			const userA = users[keyA];
			const userB = users[keyB];
			return `${userA.firstName}${userA.lastName}`.localeCompare(`${userB.firstName}${userB.lastName}`);
		})
		.map((userKey) => {
			return {
				key: userKey,
				label: `
			${users[userKey].firstName} ${users[userKey].lastName}
			`,
				labelPrefix: (
					<img
						style={{ borderRadius: "50%", marginRight: "0.5rem" }}
						width={28}
						src={users[userKey].imageUrl}
						alt=""
					/>
				),
			};
		});
}

function calculateUserRecipientValue(userKey: string | null) {
	if (!userKey) return null;

	const user = (store.getState() as InitialState).users[userKey];

	return {
		key: userKey,
		label: `
			${user.firstName} ${user.lastName}
			`,
		labelPrefix: (
			<img
				style={{ borderRadius: "50%", marginRight: "0.5rem" }}
				width={28}
				src={user.imageUrl}
				alt=""
			/>
		),
	};
}

const FailedNotificationSetup: React.FunctionComponent<Props> = ({
	maxAttemptsOnly,
	maxAttemptsCount,
	subscribers,
	autoRetryFails,
	onUpdate,
}) => {
	function updateSubscriber(index: number, property: string, value: any) {
		let next = [...subscribers];

		// @ts-ignore
		next[index][property] = value;

		if (property === "type") {
			// @ts-ignore
			next[index]["recipient"] = null;
		}

		onUpdate("subscribers", next);
	}

	function deleteSubscriber(index: number) {
		let next = [...subscribers];

		next.splice(index, 1);

		onUpdate("subscribers", next);
	}

	function addSubscriber() {
		let next = [...subscribers];

		// @ts-ignore
		next.push({ type: null, recipient: null });

		onUpdate("subscribers", next);
	}

	const subscribersHtml = subscribers.map((subscriber, index) => {
		return (
			<div className={styles.Subscriber}>
				<Dropdown
					className={classNames([styles.DropdownThin, styles.SubscriberTypes])}
					options={subscriberTypes}
					onChange={(option: DropdownItem) => updateSubscriber(index, "type", option.key)}
					value={calculateSubscriberTypeValue(subscriber.type)}
					label=""
					placeholder="Select an option"
					theme="standard"
				/>
				{subscriber.type === FailedNotificationSubscriberType.User && (
					<Dropdown
						className={styles.DropdownThin}
						options={calculateUserOptions()}
						searchable={true}
						onChange={(option: DropdownItem) => updateSubscriber(index, "recipient", option.key)}
						value={calculateUserRecipientValue(subscriber.recipient)}
						label=""
						placeholder="Choose someone"
						theme="standard"
					/>
				)}
				{subscriber.type === FailedNotificationSubscriberType.Custom && (
					<ShortText
						label=""
						className={styles.CustomEmail}
						theme="micro"
						placeholder="Enter an email address"
						id={`custom-email-${index}`}
						value={subscriber.recipient === null ? "" : subscriber.recipient}
						onChange={(event) =>
							updateSubscriber(index, "recipient", (event.target as HTMLInputElement).value)
						}
					/>
				)}
				{subscribers.length > 1 && (
					<div className={styles.DeleteButton} onClick={() => deleteSubscriber(index)}>
						<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
							<g transform="translate(-170.642 -486.262)">
								<ellipse
									cx="17.5"
									cy="17.5"
									rx="17.5"
									ry="17.5"
									transform="translate(171.142 486.762)"
									fill="#fff"
									stroke="#caced9"
									strokeMiterlimit="10"
									strokeWidth="1"
								/>
								<g transform="translate(182.61 497.634)">
									<path
										d="M191.8,496.585v.654a.511.511,0,0,1-.51.511h-.472v9.527a1.1,1.1,0,0,1-1.093,1.094h-7.346a1.1,1.1,0,0,1-1.094-1.094v-9.527h-.465a.513.513,0,0,1-.517-.511v-.654a.513.513,0,0,1,.517-.511h2.94s.183-.36.255-.51a.61.61,0,0,1,.511-.288h3.051a.608.608,0,0,1,.51.288c.072.151.255.51.255.51h2.947A.511.511,0,0,1,191.8,496.585Z"
										transform="translate(-180.306 -495.276)"
										fill="#a2a9ba"
									/>
								</g>
							</g>
						</svg>
					</div>
				)}
			</div>
		);
	});

	return (
		<div className={styles.FailureNotificationSetup}>
			{subscribersHtml}

			<div onClick={() => addSubscriber()} className={styles.AddButton}>
				<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
					<g transform="translate(-380 -353)">
						<g transform="translate(380 353)" fill="#fff" stroke="#d9e4fc" strokeWidth="1">
							<circle cx="14" cy="14" r="14" stroke="none" />
							<circle cx="14" cy="14" r="13.5" fill="none" />
						</g>
						<g transform="translate(387.001 360.001)">
							<path
								d="M550.415,697.493a1.18,1.18,0,0,1-1.179,1.179h-4.764v4.761a1.179,1.179,0,0,1-2.358,0v-4.761h-4.764a1.179,1.179,0,1,1,0-2.358h4.764v-4.764a1.179,1.179,0,0,1,2.358,0v4.764h4.764A1.178,1.178,0,0,1,550.415,697.493Z"
								transform="translate(-536.17 -690.37)"
								fill="#265dde"
							/>
						</g>
					</g>
				</svg>
			</div>

			{autoRetryFails && (
				<div>
					<Space amount={16} />

					<Checkbox
						id="max-attempts-only"
						isChecked={maxAttemptsOnly}
						onChange={() => onUpdate("maxAttemptsOnly", !maxAttemptsOnly)}
						label={`Only notify after all ${maxAttemptsCount} attempts have failed.`}
					/>
				</div>
			)}
		</div>
	);
};

export default FailedNotificationSetup;
