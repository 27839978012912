import React, { MutableRefObject } from "react";
import { RouteComponentProps } from "@reach/router";
import Header from "./Header";
import Menu, { Stages } from "./Menu";
import CreateProfile from "./Stages/1_CreateProfile";
import SetupAccount from "./Stages/2_SetupAccount";
import { AccountPreferences, ActivationUser, UseCaseTypes, UserTypes } from "./ActivationContainer";
import InviteTeam from "./Stages/3_InviteTeam";
import ActivationDone from "./Stages/4_Done";
import { ErrorBag } from "design-system";

import styles from "./Activation.module.scss";

interface Props extends RouteComponentProps {
	currentStage: Stages;
	user: ActivationUser;
	preferences: AccountPreferences;
	invites: Array<string>;
	uploadErrors: Array<ErrorBag>;
	amUploadingImage: boolean;
	errors: {
		bag: Array<ErrorBag>;
		ref: MutableRefObject<HTMLInputElement | null>;
	};
	stageProcessing: boolean;
	updateUser: (property: string, value: string) => void;
	updatePreference: (index: "useCases" | "userType", value: UserTypes | UseCaseTypes) => void;
	updateInvite: (index: number, value: string) => void;
	addInvite: () => void;
	uploadImage: (data: FormData) => Promise<undefined | "error">;
	removeImage: () => void;
	saveProfile: () => void;
	savePreferences: () => void;
	saveInvites: () => void;
	skipInvites: () => void;
	finishActivation: () => void;
}

const ActivationView: React.FunctionComponent<Props> = ({
	currentStage,
	user,
	preferences,
	invites,
	errors,
	uploadErrors,
	amUploadingImage,
	stageProcessing,
	updateUser,
	updatePreference,
	updateInvite,
	addInvite,
	uploadImage,
	removeImage,
	saveProfile,
	savePreferences,
	saveInvites,
	skipInvites,
	finishActivation,
}) => {
	return (
		<div className={styles.ActivationLayout}>
			<Header />

			<Menu currentStage={currentStage} />

			<div className={styles.Main}>
				<CreateProfile
					user={user}
					errors={errors}
					uploadErrors={uploadErrors}
					amUploadingImage={amUploadingImage}
					stageProcessing={stageProcessing}
					updateUser={updateUser}
					currentStage={currentStage}
					uploadImage={uploadImage}
					removeImage={removeImage}
					saveProfile={saveProfile}
				/>

				<SetupAccount
					currentStage={currentStage}
					preferences={preferences}
					updatePreference={updatePreference}
					savePreferences={savePreferences}
				/>

				<InviteTeam
					currentStage={currentStage}
					invites={invites}
					updateInvite={updateInvite}
					addInvite={addInvite}
					saveInvites={saveInvites}
					skipInvites={skipInvites}
				/>

				<ActivationDone currentStage={currentStage} finishActivation={finishActivation} />
			</div>
		</div>
	);
};

export default ActivationView;
