import React from "react";
import { LogItem } from "design-system";
import classNames from "classnames";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

const DelayFinished: React.FunctionComponent<Props> = ({ logItem }) => {
	const indicatorClasses = classNames([styles.Indicator]);
	return (
		<React.Fragment>
			<div className={indicatorClasses}>
				<img src="/images/diagram/delay-icon.svg" width={26} />
			</div>
			<div className={styles.Event}>
				<span></span>
				<p>Delay Finished</p>
			</div>
			<div className={styles.Details}></div>
		</React.Fragment>
	);
};

export default DelayFinished;
