import React, { useState } from "react";
import {
	Ability,
	Dropdown,
	DropdownItem,
	HiddenOptions,
	Job,
	JobStatus as JobStatusEnum,
	LoadingPlaceholder,
	Prompt,
	UsersList,
	Workflow,
	WorkflowsList,
} from "design-system";
import Collaborators from "./Collaborators";
import { canAccess, convertToMoment } from "../../../../../utils/helpers";
import { deleteJob, jobStatusChange } from "../../../jobActions";
import { sendRequestToAPI } from "../../../../../sharedActions";
import { JobAPI } from "../../../../../api/JobAPI";
import { useDispatch } from "react-redux";
import { useNavigate } from "@reach/router";
import Status from "./Status";

import styles from "./Sidebar.module.scss";

interface Props {
	selectedView: DropdownItem;
	updateView: (view: DropdownItem) => void;
	workflowKeys: Array<string>;
	workflows: WorkflowsList;
	job: Job;
	collaborators: Array<string>;
	mostRecentWorkflowKey: string;
	users: UsersList;
}

const Sidebar: React.FunctionComponent<Props> = ({
	selectedView,
	updateView,
	job,
	workflowKeys,
	workflows,
	mostRecentWorkflowKey,
	collaborators,
	users,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const startedAtDate = convertToMoment(job.startedAt);

	const mostRecentWorkflow: Workflow = workflows[mostRecentWorkflowKey];

	async function deleteConfirmed() {
		dispatch(deleteJob(job.key));
		dispatch(sendRequestToAPI(JobAPI.deleteJob(job.key)));

		await navigate("/jobs");

		// what if someone else is actively working on this job?
		// need real-time event
	}

	function changeJobStatus(status: JobStatusEnum) {
		dispatch(sendRequestToAPI(JobAPI.changeJobStatus(job.key, status)));

		dispatch(jobStatusChange(job.key, status));
	}

	return (
		<div className={styles.Sidebar}>
			<div className={styles.Top}>
				<div className={styles.Settings}>
					<Dropdown
						className={styles.ViewDropdown}
						options={[
							{ label: "Overview", key: "overview" },
							{ label: "Logs", key: "log" },
						]}
						onChange={updateView}
						value={selectedView}
						label=""
					/>
					{canAccess(Ability.DeleteJob) && (
						<HiddenOptions containerClassName={styles.HiddenSettings}>
							<div className="HiddenSettingsPopover">
								<p onClick={() => setShowDeleteModal(!showDeleteModal)}>Delete</p>
								{job.completedAt === null ? (
									<p onClick={() => changeJobStatus(JobStatusEnum.Closed)}>Close Job</p>
								) : (
									<p onClick={() => changeJobStatus(JobStatusEnum.Open)}>Reopen Job</p>
								)}
							</div>
						</HiddenOptions>
					)}
				</div>
				<div className={styles.WorkflowName}>
					<h2>
						{mostRecentWorkflow !== undefined ? (
							mostRecentWorkflow.name
						) : (
							<LoadingPlaceholder width={250} height={40} />
						)}
					</h2>
					<Status job={job} />
				</div>
			</div>
			<div className={styles.Details}>
				<div className={styles.StartedDate}>
					<p className={styles.Title}>Started</p>

					<span>
						{startedAtDate.format("MMM Do")}
						&nbsp;at&nbsp;
						{startedAtDate.format("h:mm A")}
					</span>
				</div>
				<Collaborators userKeys={collaborators} users={users} />
			</div>

			<Prompt
				show={showDeleteModal}
				onConfirm={deleteConfirmed}
				onCancel={() => setShowDeleteModal(false)}
			>
				Are you sure you wish to delete this job?
			</Prompt>
		</div>
	);
};

export default Sidebar;
