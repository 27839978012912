import {
	CREATE_JOB_TITLE,
	DELETE_JOB_TITLE,
	DELETE_ROLE,
	DELETE_USER,
	UPDATE_JOB_TITLE,
	UPDATE_ROLE,
	UPDATE_USER,
	UPDATE_USER_PROFILE,
	UPDATE_USER_PROFILE_IMAGE,
} from "../../actionTypes";
import { Me, Role } from "design-system";

export function updateUser(user: any) {
	return {
		type: UPDATE_USER,
		user,
	};
}

export function updateUserProfile(user: Me) {
	return {
		type: UPDATE_USER_PROFILE,
		user,
	};
}

export function deleteUser(userKey: string) {
	return {
		type: DELETE_USER,
		userKey,
	};
}

export function updateRole(role: Role) {
	return {
		type: UPDATE_ROLE,
		role,
	};
}

export function deleteRole(roleKey: string) {
	return {
		type: DELETE_ROLE,
		roleKey,
	};
}

export function resetUserProfileImage(userKey: string, url: string) {
	return {
		type: UPDATE_USER_PROFILE_IMAGE,
		userKey,
		url,
	};
}

export function createJobTitle(key: string, name: string) {
	return {
		type: CREATE_JOB_TITLE,
		jobTitle: {
			key,
			name,
		},
	};
}

export function updateJobTitle(key: string, name: string) {
	return {
		type: UPDATE_JOB_TITLE,
		jobTitle: {
			key,
			name,
		},
	};
}

export function deleteJobTitle(key: string) {
	return {
		type: DELETE_JOB_TITLE,
		key,
	};
}
