import { combineEpics, Epic, ofType } from "redux-observable";
import {
	REQUEST_APP_ESSENTIALS,
	REQUEST_APP_NON_ESSENTIALS,
	REQUEST_AUTHORIZED_USER, REQUEST_CSRF_TOKEN,
} from "../../actionTypes";
import { catchError, endWith, map, mergeMap, switchMap } from "rxjs/operators";
import { EMPTY, from, merge, of } from "rxjs";
import { get, post } from "../../utils/api";
import {
	setAuthenticatedUser,
	apiRequestFailed,
	requestAppEssentials,
	setAppEssentials,
	appInitialLoadComplete,
	startRealTimeUpdates,
	requestAppNonEssentials,
	setFeatureVotes,
} from "./accountActions";
import { NETWORK_FAILURE, SERVER_ERROR } from "../../errorTypes";
import { stopPollingForWaitingJobStep } from "../Jobs/jobActions";
import axios, { AxiosResponse } from "axios";

// const loadCSRFToken: Epic = (action$, state$) => {
// 	return action$.pipe(
// 		ofType(REQUEST_CSRF_TOKEN),
// 		mergeMap(() => {
// 			return from(get("csrf-token")).pipe(
// 				mergeMap((response: AxiosResponse) => {
// 					axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data.data.csrfToken;
//
// 					return EMPTY;
// 				})
// 			)
// 		})
// 	)
// }

const fetchMe: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(REQUEST_AUTHORIZED_USER),
		mergeMap(() => {
			return from(get("me")).pipe(
				mergeMap((response) => [
					setAuthenticatedUser(response),
					requestAppEssentials(),
					startRealTimeUpdates(),
					requestAppNonEssentials(),
				]),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const fetchAppEssentials: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(REQUEST_APP_ESSENTIALS),
		switchMap(() => {
			const appEssentialsRequests = [
				get("account"),
				get("categories"),
				get("workflows"),
				get("users"),
				get("fields"),
				get("integrations"),
				get("roles"),
				get("job-titles"),
			];

			return from(Promise.all(appEssentialsRequests)).pipe(
				map(([account, categories, workflows, users, fields, integrations, roles, jobTitles]) =>
					// todo check account status
					setAppEssentials(
						account.data.data.account,
						categories.data.data.categories,
						workflows.data.data.workflows,
						users.data.data.users,
						fields.data.data.fields,
						integrations.data.data.integrations,
						roles.data.data.roles,
						jobTitles.data.data.jobTitles
					)
				),
				catchError((error) => of(apiRequestFailed(error))),
				endWith(appInitialLoadComplete())
			);
		})
	);
};

const fetchAppNonEssentials: Epic = (action$) => {
	return action$.pipe(
		ofType(REQUEST_APP_NON_ESSENTIALS),
		mergeMap(() => {
			return from(get(`feature-votes`)).pipe(
				map((response) => setFeatureVotes(response.data.data)),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const stopPollingOnError: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(SERVER_ERROR, NETWORK_FAILURE),
		map(() => stopPollingForWaitingJobStep())
	);
};

export default combineEpics(fetchMe, fetchAppEssentials, fetchAppNonEssentials, stopPollingOnError);
