import { combineEpics, Epic, ofType } from "redux-observable";
import {
	COPY_WORKFLOW,
	CREATE_WORKFLOW,
	DEBOUNCE_ROUTE_CONDITION_UPDATE,
	LIVE_WORKFLOW_PUBLISHED,
	PUBLISH_WORKFLOW,
	PUBLISH_WORKFLOW_COMPLETE,
	PUBLISH_WORKFLOW_PENDING,
	REQUEST_FULL_WORKFLOW,
	REQUEST_WORKFLOW,
	REQUEST_WORKFLOW_PARTS,
} from "../../../actionTypes";
import { catchError, debounceTime, exhaustMap, map, mergeMap } from "rxjs/operators";
import { from, merge, of } from "rxjs";
import { WorkflowAPI } from "../../WorkflowAPI";
import { apiRequestFailed, emptySuccessResponse } from "../../../components/Account/accountActions";
import { call, get, post } from "../../../utils/api";
import {
	moveConnector,
	setFullWorkflow,
	setWorkflow,
	setWorkflowParts,
	updateRouteCondition,
} from "../../../components/Workflows/Editor/workflowActions";
import { AnyAction } from "redux";
import { InitialState } from "../../../initialState";
import {
	VariableType,
	DropdownFieldDetails,
	ElementType,
	FormField,
	StandardFormFieldType,
} from "design-system";
import { CanvasElementMeasurer } from "../../../components/Workflows/Editor/Canvas/CanvasElementMeasurer";
import { sendRequestToAPI } from "../../../sharedActions";
import {
	calculateCurrentMonthSelection, calculateDayOfWeekSelection,
	calculateEntireConditionsGroupsMeasurements,
	calculateFormattedTimeFromTimeObject,
	calculateFormattedTimesFromTimeObjects,
	calculateUserFullNameFromKey,
} from "../../../components/Workflows/Editor/Canvas/utils";
import { CONNECTOR_WIDTH } from "../../../utils/constants";
import { monthOptions } from "../../../components/Workflows/Editor/Shared/Conditional/systemVariableOptions";

const requestWorkflowParts: Epic = (action$) => {
	return action$.pipe(
		ofType(REQUEST_WORKFLOW_PARTS),
		mergeMap((action) => {
			return from(get(`/workflows/${action.workflowKey}/parts`)).pipe(
				map((response) =>
					setWorkflowParts(
						action.workflowKey,
						response.data.data.startMarker,
						response.data.data.steps,
						response.data.data.connectors,
						response.data.data.subWorkflowConnectors,
						response.data.data.actions,
						response.data.data.ends,
						response.data.data.externalEndpoints,
						response.data.data.delays
					)
				),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const requestFullWorkflow: Epic = (action$) => {
	return action$.pipe(
		ofType(REQUEST_FULL_WORKFLOW),
		mergeMap((action) => {
			return from(get(`/workflows/${action.workflowKey}/full`)).pipe(
				map((response) =>
					setFullWorkflow(
						response.data.data.workflow,
						response.data.data.startMarker,
						response.data.data.steps,
						response.data.data.connectors,
						response.data.data.subWorkflowConnectors,
						response.data.data.actions,
						response.data.data.ends,
						response.data.data.delays
					)
				),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const requestWorkflow: Epic = (action$) => {
	return action$.pipe(
		ofType(REQUEST_WORKFLOW),
		mergeMap((action) => {
			return from(get(`/workflows/${action.workflowKey}`)).pipe(
				map((response) => setWorkflow(response.data.data.workflow)),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const createWorkflow: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(CREATE_WORKFLOW),
		mergeMap((action) => {
			return from(post("workflows", { ...action.workflow })).pipe(
				map(() => action), // pass the action to the next merge map
				catchError((error) => of(apiRequestFailed(error)))
			);
		}),
		// todo if error is catch, its passed as the next action...
		// how to we pull the ripcord and stop all subsequent requests on error?
		mergeMap((action) => from(WorkflowAPI.saveConnectorRequest(action)).pipe(map(() => action))),
		mergeMap((action) =>
			from(WorkflowAPI.createStartMarker(action)).pipe(
				map(() => emptySuccessResponse()),
				catchError((error) => of(apiRequestFailed(error)))
			)
		)
	);
};

const publishWorkflow: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(PUBLISH_WORKFLOW),
		exhaustMap((action: AnyAction) =>
			merge(
				of({ type: PUBLISH_WORKFLOW_PENDING }),
				from(call(`workflows/${action.workflowKey}/publish`)).pipe(
					map(() => ({ type: PUBLISH_WORKFLOW_COMPLETE, workflowKey: action.workflowKey })),
					catchError((error) => of(apiRequestFailed(error)))
				)
			)
		)
	);
};

const copyWorkflow: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(COPY_WORKFLOW),
		mergeMap((action: AnyAction) =>
			from(call(`workflows/${action.workflowKey}/copy`)).pipe(
				mergeMap((response) => {
					return of({ type: REQUEST_WORKFLOW, workflowKey: response.data.data.key });
				}),
				catchError((error) => of(apiRequestFailed(error)))
			)
		)
	);
};

const refreshWorkflow: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(LIVE_WORKFLOW_PUBLISHED),
		mergeMap((action: AnyAction) => {
			const urls: Array<{ workflowKey: string; loaded: boolean }> = action.workflowKeys.map(
				(workflowKey: string) => {
					const state = state$.value as InitialState;

					return {
						workflowKey,
						loaded:
							state.workflows.hasOwnProperty(workflowKey) &&
							state.workflows[workflowKey].loaded,
					};
				}
			);

			return from(urls).pipe(
				map((request: { workflowKey: string; loaded: boolean }) => {
					if (request.loaded) {
						return {
							type: REQUEST_FULL_WORKFLOW,
							workflowKey: request.workflowKey,
						};
					}

					return {
						type: REQUEST_WORKFLOW,
						workflowKey: request.workflowKey,
					};
				}),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

// this could be broken down and cleaned up a bit
const updateRouteConditionValue: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(DEBOUNCE_ROUTE_CONDITION_UPDATE),
		debounceTime(250),
		mergeMap((action) => {
			const state = state$.value as InitialState;

			let condition = { ...state.elementRouteConditions[action.conditionKey] };

			let valueToMeasure = action.value;

			if (condition.variable.type === VariableType.Field) {
				const variableField = state.formFields[condition.variable.value as string] as FormField;

				if (condition.operator === "oneOf" || condition.operator === "notOneOf") {
					const selectedKeys = action.value.split(",");
					let selectedOptions: Array<string> = [];

					if (
						variableField.type === StandardFormFieldType.DROPDOWN ||
						variableField.type === StandardFormFieldType.RADIO ||
						variableField.type === StandardFormFieldType.CHECKBOXES
					) {
						(variableField.details as DropdownFieldDetails).options.forEach((option) => {
							if (selectedKeys.includes(option.key)) {
								selectedOptions.push(option.label);
							}
						});
					}

					if (variableField.type === StandardFormFieldType.PERSON) {
						selectedKeys.forEach((userKey: string) => {
							const name = calculateUserFullNameFromKey(userKey);
							selectedOptions.push(name);
						});
					}

					valueToMeasure = selectedOptions.join(", ");
				}

				if (condition.operator === "equalTo" || condition.operator === "notEqualTo") {
					if (variableField.type === StandardFormFieldType.DROPDOWN) {
						const selectedOption = (variableField.details as DropdownFieldDetails).options.find(
							(option) => option.key === action.value
						);

						if (selectedOption) {
							valueToMeasure = selectedOption.label;
						}
					}

					if (variableField.type === StandardFormFieldType.PERSON) {
						valueToMeasure = calculateUserFullNameFromKey(action.value);
					}
				}
			}

			if (condition.variable.type === VariableType.System) {
				switch (condition.variable.value) {
					case "currentMonth":
						valueToMeasure = calculateCurrentMonthSelection(JSON.parse(condition.value.value as string).month, condition.operator);
						break;
					case "currentTime":
						valueToMeasure = condition.operator === 'betweenTimes' ? calculateFormattedTimesFromTimeObjects(condition.value.value) : calculateFormattedTimeFromTimeObject(condition.value.value)
						break;
					case "dayOfWeek":
						valueToMeasure = calculateDayOfWeekSelection(JSON.parse(condition.value.value as string).dayOfWeek, condition.operator)
						break;
				}
			}

			const variableProperties = new CanvasElementMeasurer().setFontSize(16).measure(valueToMeasure);

			condition.value = {
				value: action.value,
				...variableProperties,
			};

			// If the "to" element connected is a connector, then reset its position
			const connectorUpdates: Array<AnyAction> = [];
			const route = state.elementRoutes[action.routeKey];

			if (route.to.type === ElementType.Connector) {
				const connectorKey = route.to.key;
				let connector = { ...state.connectors[connectorKey] };

				let nextRoute = { ...route };

				let measurements = calculateEntireConditionsGroupsMeasurements(nextRoute);
				// @ts-ignore
				measurements.height = measurements.height + condition.value.height;
				// The value that we just measured isnt included because that utility function uses the state
				// to get the current condition values

				const nextConnectorYCoord = measurements.coordY + measurements.height + 50;

				if (connector.coordY !== nextConnectorYCoord) {
					connector.coordY = nextConnectorYCoord;

					connector.coordX = measurements.coordX + measurements.width / 2 - CONNECTOR_WIDTH;

					connectorUpdates.push(moveConnector(connector));
					connectorUpdates.push(
						sendRequestToAPI(
							WorkflowAPI.updateConnector(action.workflowKey, connector.key, {
								coordX: connector.coordX,
								coordY: connector.coordY,
							})
						)
					);
				}
			}

			return of(
				updateRouteCondition(action.routeKey, condition),
				sendRequestToAPI(
					WorkflowAPI.updateRouteCondition(
						action.routeKey,
						action.conditionKey,
						action.workflowKey,
						{
							value: condition.value.value,
							valueWidth: condition.value.width,
							valueHeight: condition.value.height,
							valueWordOffsetsMultiLine: condition.value.lineOffsets,
						}
					)
				),
				...connectorUpdates
			);
		})
	);
};

export default combineEpics(
	requestWorkflowParts,
	createWorkflow,
	refreshWorkflow,
	requestWorkflow,
	requestFullWorkflow,
	updateRouteConditionValue,
	copyWorkflow,
	publishWorkflow
);
