import {
	CANCEL_SCHEDULED_RESEND,
	CLEANUP_OPEN_JOB,
	CLEAR_JOB_STEP_WAITING,
	CREATE_JOB_STEP,
	DELETE_JOB,
	JOB_REFRESH_COUNT,
	JOB_STATUS_CHANGE,
	LOADING_JOBS,
	LOADING_NEXT_STEP,
	MANUALLY_RESEND_ACTION,
	SET_JOB,
	POLL_FOR_WAITING_JOB_STEP,
	TOGGLE_JOB_TABLE_VIEW_CONTROL,
	UPDATE_JOB_STEP,
	UPDATE_JOB_STEP_ASSIGNEES,
	UPDATE_SELECTED_JOB_VIEW,
	WAIT_FOR_INSTRUCTIONS,
	SET_JOB_STEP_INSTRUCTIONS,
	SET_STEP_BLUEPRINT,
	SET_NEXT_JOB_STEP_KEY,
	SET_JOB_STEP,
	CREATE_JOB_COMMENT,
	EDIT_JOB_COMMENT,
	DELETE_JOB_COMMENT,
	PREFILL_JOB_FIELD,
	SET_FIELDS_TO_PREFILL,
	UPDATE_OPEN_JOB_KB_SETTINGS,
	START_MONITORING_FIELD_CHANGES,
	STOP_MONITORING_FIELD_CHANGES,
	CLEAR_UNSAVED_FIELDS,
	STARTING_NEW_JOB,
	START_POLLING_FOR_WAITING_JOB_STEP,
	STOP_POLLING_FOR_WAITING_JOB_STEP,
	SET_NEW_JOB,
} from "../../actionTypes";
import { Job, JobComment, JobStatus, JobStep, LogItem, PersonAssignmentOption } from "design-system";
import moment from "moment";

export function startingNewJob() {
	return {
		type: STARTING_NEW_JOB,
	};
}

export function setJob(job: Job) {
	return {
		type: SET_JOB,
		job,
	};
}

export function setNewJob(job: Job, logItem: LogItem) {
	return {
		type: SET_NEW_JOB,
		job,
		logItem,
	};
}

export function startLoadingNewJob() {
	return {};
}

export function startMonitoringFieldChanges() {
	return {
		type: START_MONITORING_FIELD_CHANGES,
	};
}

export function stopMonitoringFieldChanges() {
	return {
		type: STOP_MONITORING_FIELD_CHANGES,
	};
}

export function jobStatusChange(jobKey: string, status: JobStatus) {
	return {
		type: JOB_STATUS_CHANGE,
		jobKey,
		completedAt: status === JobStatus.Closed ? moment().toISOString() : null,
	};
}

export function setFieldsToPrefill(fields: Array<{ key: string; value: string }>) {
	return {
		type: SET_FIELDS_TO_PREFILL,
		fields,
	};
}

export function prefillFields(jobKey: string, fields: Array<{ key: string; value: string }>) {
	return {
		type: PREFILL_JOB_FIELD,
		jobKey,
		fields,
	};
}

export function createJobStep(jobStep: JobStep, jobKey: string) {
	return {
		type: CREATE_JOB_STEP,
		jobStep,
		jobKey,
	};
}

export function updateJobStep(jobStepKey: string, changes: any) {
	return {
		type: UPDATE_JOB_STEP,
		jobStepKey,
		changes,
	};
}

export function deleteJob(jobKey: string) {
	return {
		type: DELETE_JOB,
		jobKey,
	};
}

export function updateJobStepAssignees(jobStepKey: string, assignees: Array<PersonAssignmentOption>) {
	return {
		type: UPDATE_JOB_STEP_ASSIGNEES,
		jobStepKey,
		assignees,
	};
}

export function loadingJobs() {
	return {
		type: LOADING_JOBS,
	};
}

export function freshenJobsRelativeDates() {
	// this is used to update the columns that are formatted using relative dates
	return {
		type: JOB_REFRESH_COUNT,
	};
}

export function loadingNextStep() {
	return {
		type: LOADING_NEXT_STEP,
	};
}

export function waitForInstructions(currentJobStepKey: string) {
	return {
		type: WAIT_FOR_INSTRUCTIONS,
		currentJobStepKey,
	};
}

export function setNextJobStepKey(nextJobStepKey: string) {
	return {
		type: SET_NEXT_JOB_STEP_KEY,
		nextJobStepKey,
	};
}

export function startpollingForWaitingJobStep(jobKey: string) {
	return {
		type: START_POLLING_FOR_WAITING_JOB_STEP,
		jobKey,
		startTime: Date.now(),
	};
}

export function stopPollingForWaitingJobStep() {
	return {
		type: STOP_POLLING_FOR_WAITING_JOB_STEP,
	};
}

export function pollForWaitingJobStep(jobKey: string) {
	return {
		type: POLL_FOR_WAITING_JOB_STEP,
		jobKey,
	};
}

export function setStepBlueprint(step: any, formFields: any) {
	return {
		type: SET_STEP_BLUEPRINT,
		step,
		formFields,
	};
}

export function setNextJobStepInstruction(instruction: string) {
	return {
		type: SET_JOB_STEP_INSTRUCTIONS,
		instruction,
	};
}

export function setJobStep(jobKey: string, jobStep: JobStep, variables: any, jobFields: any) {
	return {
		type: SET_JOB_STEP,
		jobKey,
		jobStep,
		variables,
		jobFields,
	};
}

export function clearJobStepWaiting() {
	return {
		type: CLEAR_JOB_STEP_WAITING,
	};
}

export function cleanupOpenJob() {
	return {
		type: CLEANUP_OPEN_JOB,
	};
}

export function clearUnsavedFields() {
	return {
		type: CLEAR_UNSAVED_FIELDS,
	};
}

export function updateSelectedJobView(viewKey: string) {
	return {
		type: UPDATE_SELECTED_JOB_VIEW,
		viewKey,
	};
}

export function toggleJobTableViewControl(settingName: string) {
	return {
		type: TOGGLE_JOB_TABLE_VIEW_CONTROL,
		settingName,
	};
}

export function manuallyResendAction(jobActionKey: string) {
	return {
		type: MANUALLY_RESEND_ACTION,
		jobActionKey,
	};
}

export function cancelScheduledResend(jobActionKey: string) {
	return {
		type: CANCEL_SCHEDULED_RESEND,
		jobActionKey,
	};
}

export function createComment(jobKey: string, comment: JobComment) {
	return {
		type: CREATE_JOB_COMMENT,
		jobKey,
		comment,
	};
}

export function editComment(jobKey: string, commentKey: string, message: string) {
	return {
		type: EDIT_JOB_COMMENT,
		jobKey,
		commentKey,
		message,
	};
}

export function deleteComment(jobKey: string, commentKey: string) {
	return {
		type: DELETE_JOB_COMMENT,
		jobKey,
		commentKey,
	};
}

export function updateOpenJobKbSettings(changes: any) {
	return {
		type: UPDATE_OPEN_JOB_KB_SETTINGS,
		changes,
	};
}
