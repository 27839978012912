import React from "react";
import { Integration, JobAction, LogItem } from "design-system";
import classNames from "classnames";
import { InitialState } from "../../../../../initialState";
import { store } from "../../../../../store";

import styles from "../JobLogs.module.scss";

interface Props {
	logItem: LogItem;
}

function calculateIntegration(integrationKey: string | undefined) {
	if (!integrationKey) {
		return (
			<div>
				<p>No integration selected.</p>
			</div>
		);
	}

	const integration = (store.getState() as InitialState).integrations[integrationKey] as Integration;

	return (
		<div>
			<img src={integration.logoUrl} width={20} />
			<p>{integration.name}</p>
		</div>
	);
}

const InvalidAutomationConfig: React.FunctionComponent<Props> = ({ logItem }) => {
	const indicatorClasses = classNames([styles.Indicator, styles.Red]);

	const t = (store.getState() as InitialState).jobsActions;
	const jobAction = (store.getState() as InitialState).jobsActions[logItem.elementKey] as JobAction;

	return (
		<React.Fragment>
			<div className={indicatorClasses}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="26.432"
					height="26.432"
					viewBox="0 0 26.432 26.432"
				>
					<g transform="translate(-212.024 -965.233)">
						<circle
							cx="13.216"
							cy="13.216"
							r="13.216"
							transform="translate(212.024 965.233)"
							fill="#f7bbbb"
						/>
						<g>
							<path
								d="M224.026,981.552h2.283a.372.372,0,0,0,.395-.29l1.1-11.034a.355.355,0,0,0-.4-.34h-4.343a.356.356,0,0,0-.395.337l.958,11.034A.37.37,0,0,0,224.026,981.552Z"
								fill="#c43535"
							/>
							<circle
								cx="2.041"
								cy="2.041"
								r="2.041"
								transform="translate(223.199 982.928)"
								fill="#c43535"
							/>
						</g>
					</g>
				</svg>
			</div>
			<div className={styles.Event}>
				<span>Automation Failed</span>
				{calculateIntegration(jobAction.action.integrationKey)}
			</div>
			<div className={styles.Details}>
				<p>Invalid Configuration</p>
				{/* eventually add a button that can show a model with details on how it wasnt a match*/}
			</div>
		</React.Fragment>
	);
};

export default InvalidAutomationConfig;
