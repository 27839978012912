import {
	Action,
	CanvasElement,
	ConditionsSettings,
	ConnectionTypes,
	ElementConnection,
	ElementConnectionList,
	ElementRoute,
	ElementRoutesList,
	ElementType,
	LinePosition,
	StartMarker,
	Step,
	SubWorkflowConnector,
	WorkflowRoot,
} from "design-system";
import { store } from "../../../../../store";
import { Box } from "./Box";
import { calculateEntireConditionsGroupsMeasurements, XYCoords } from "../utils";
import { START_MARKER_PROPERTIES } from "./StartMarker";
import { END_PIECE_HEIGHT, END_PIECE_WIDTH, StartMarkerKey } from "../../../../../utils/constants";
import { InitialState } from "../../../../../initialState";
import { UNMATCHED_ROUTE_MARKER_HEIGHT, UNMATCHED_ROUTE_MARKER_WIDTH } from "./UnmatchedRouteMarkers";

function calculateMeasurements(element: CanvasElement, type: ElementType) {
	if (type === ElementType.Action) {
		return {
			width: (element as Action).width,
			height: (element as Action).height,
		};
	}

	if (type === ElementType.Connector) {
		return {
			width: 0,
			height: 0,
		};
	}

	if (type === ElementType.StartMarker) {
		return {
			width: START_MARKER_PROPERTIES.width,
			height: START_MARKER_PROPERTIES.height,
		};
	}

	if (type === ElementType.UnmatchedRouteMarker) {
		return {
			width: UNMATCHED_ROUTE_MARKER_WIDTH,
			height: UNMATCHED_ROUTE_MARKER_HEIGHT,
		};
	}

	if (type === ElementType.Conditions) {
		const route = (store.getState() as InitialState).elementRoutes[element.key];

		const { width, height } = calculateEntireConditionsGroupsMeasurements(route);

		return {
			width,
			height,
		};
	}

	if (type === ElementType.SubWorkflowConnector) {
		return {
			width: (element as SubWorkflowConnector).width,
			height: (element as SubWorkflowConnector).height,
		};
	}

	if (type === ElementType.EndPiece) {
		return {
			width: END_PIECE_WIDTH,
			height: END_PIECE_HEIGHT,
		};
	}

	return {
		width: (element as Step).width,
		height: (element as Step).height,
	};
}

interface RawRouteConnectedToElement {
	elementKeyConnectedToDraggedElement: string;
	direction: "to" | "from";
	orientation: LinePosition;
	type: ElementType;
}

interface ConnectedElement {
	type: ElementType;
	orientation: LinePosition;
	coordX: number;
	coordY: number;
}

const SNAP_SENSITIVITY = 10;

export class RouteSnapping {
	private elementsConnectedToDraggedElement: Array<ConnectedElement> = [];
	private readonly draggedElement: CanvasElement;
	private readonly width: number;
	private readonly height: number;
	private readonly type: ElementType;
	private readonly workflowRoot: WorkflowRoot | null;
	private readonly workflowKey: string;

	constructor(
		element: CanvasElement,
		type: ElementType,
		workflowRoot: WorkflowRoot | null = null,
		workflowKey: string
	) {
		// element could be a step, connector, startMarker, subWorkflow, action
		this.draggedElement = element;
		this.type = type;
		this.workflowKey = workflowKey;

		if (type === ElementType.StartMarker) {
			element.key = StartMarkerKey;
		}

		const { width, height } = calculateMeasurements(element, type);

		this.width = width;
		this.height = height;

		// workflow root is needed when dragging the start marker to determine
		// the connected element
		this.workflowRoot = workflowRoot;

		this.prepareToSnap();
	}

	calculateConnectedToElementProperties(element: any, route: RawRouteConnectedToElement) {
		const elementConnections = store.getState().elementConnections as ElementConnectionList;

		if (route.type === ElementType.Step) {
			const connection = elementConnections[(element as Step).connectionKey] as ElementConnection;

			if (connection.type === ConnectionTypes.MULTIPLE_CHOICE) {
				return {
					coordX: connection.details.coordX,
					coordY: connection.details.coordY,
					width: connection.details.width,
					height: connection.details.height,
				};
			}
		}

		let { width, height } = calculateMeasurements(element, route.type);

		if (route.type === ElementType.Conditions) {
			return {
				coordX: element.coordX,
				coordY: element.coordY,
				width,
				height,
			};
		}

		return {
			coordX: element.coordX,
			coordY: element.coordY,
			width,
			height,
		};
	}

	prepareToSnap() {
		const connectedRoutes = this.findConnectedRoutes(this.draggedElement.key);

		connectedRoutes.forEach((route) => {
			const element = this.fetchElementFromState(route.elementKeyConnectedToDraggedElement, route.type);

			const { coordX, coordY, width, height } = this.calculateConnectedToElementProperties(
				element,
				route
			);
			const relativePositioning = new Box(width, height, coordX, coordY, route.orientation);

			const relativeCoords = relativePositioning.calculateCoordsFromDirection();

			this.elementsConnectedToDraggedElement.push({
				type: route.type,
				orientation: route.orientation,
				coordX: relativeCoords.x,
				coordY: relativeCoords.y,
			});
		});
	}

	buildConnectionRoute(route: ElementRoute): RawRouteConnectedToElement {
		if (route.conditions !== undefined && route.conditions.length) {
			return {
				elementKeyConnectedToDraggedElement: `${route.key}_conditions`,
				direction: "to",
				type: ElementType.Conditions,
				orientation: (route.conditionsSettings as ConditionsSettings).routeToDirection,
			};
		}

		return {
			elementKeyConnectedToDraggedElement: route.from.key,
			direction: "to",
			type: route.from.type,
			orientation: route.to.direction,
		};
	}

	findConnectedRoutes(draggedElementKey: string) {
		const connectionRoutes: Array<RawRouteConnectedToElement> = [];

		let routes = store.getState().elementRoutes as ElementRoutesList;

		if (this.type === ElementType.StartMarker) {
			routes[StartMarkerKey] = this.buildStartMarkerRoute();
		}

		if (this.type === ElementType.Conditions) {
			const route = routes[this.draggedElement.key];

			connectionRoutes.push({
				elementKeyConnectedToDraggedElement: route.from.key,
				direction: "to",
				type: route.from.type,
				orientation: route.to.direction,
			});

			if (route.to.type !== ElementType.Connector) {
				connectionRoutes.push({
					elementKeyConnectedToDraggedElement: route.to.key,
					direction: "from",
					type: route.to.type,
					orientation: (route.conditionsSettings as ConditionsSettings).routeFromDirection,
				});
			}

			return connectionRoutes;
		}

		Object.keys(routes).forEach((routeKey) => {
			const route = routes[routeKey];

			if (route.to.key !== draggedElementKey && route.from.key !== draggedElementKey) return;

			if (route.to.direction === "top" && route.from.direction !== "bottom") return;
			if (route.to.direction === "bottom" && route.from.direction !== "top") return;
			if (route.to.direction === "left" && route.from.direction !== "right") return;
			if (route.to.direction === "right" && route.from.direction !== "left") return;

			if (route.to.key === draggedElementKey) {
				connectionRoutes.push(this.buildConnectionRoute(route));
			}

			// The connector is dragged with the element, so we shouldnt include it
			if (route.from.key === draggedElementKey && route.to.type !== ElementType.Connector) {
				connectionRoutes.push({
					elementKeyConnectedToDraggedElement: route.to.key,
					direction: "from",
					type: route.to.type,
					orientation: route.from.direction,
				});
			}
		});

		return connectionRoutes;
	}

	calculateSnapAmount(nextCoordX: number, nextCoordY: number): XYCoords {
		let snapAmount = { x: 0, y: 0 };

		if (!this.elementsConnectedToDraggedElement.length) return snapAmount;

		this.elementsConnectedToDraggedElement.forEach((connectedElement) => {
			const draggedElementPosition = new Box(
				this.width,
				this.height,
				nextCoordX,
				nextCoordY,
				connectedElement.orientation
			);

			const connectedElementNextCoords = draggedElementPosition.calculateCoordsFromDirection();

			const diffX = connectedElement.coordX - connectedElementNextCoords.x;
			const diffY = connectedElement.coordY - connectedElementNextCoords.y;

			if ((diffX >= 1 && diffX <= SNAP_SENSITIVITY) || (diffX <= -1 && diffX >= -SNAP_SENSITIVITY)) {
				snapAmount.x = diffX;
			}

			if ((diffY >= 1 && diffY <= SNAP_SENSITIVITY) || (diffY <= -1 && diffY >= -SNAP_SENSITIVITY)) {
				snapAmount.y = diffY;
			}
		});

		return snapAmount;
	}

	fetchElementFromState(key: string, type: ElementType): CanvasElement {
		const state = store.getState() as InitialState;
		if (type === ElementType.StartMarker) {
			return state.startMarkers[this.workflowKey];
		}

		if (type === ElementType.Conditions) {
			const [routeKey, _] = key.split("_");

			// calculate offset
			const { coordX, coordY } = state.elementRoutes[routeKey].conditionsSettings as ConditionsSettings;

			return {
				key: routeKey,
				coordX: coordX as number,
				coordY: coordY as number,
			};
		}

		// @ts-ignore
		return state[`${type}s`][key];
	}

	// buildRouteConditionRoute(): ElementRoute {}

	buildStartMarkerRoute(): ElementRoute {
		const startMarker = store.getState().startMarkers[this.workflowKey] as StartMarker;

		let toKey = startMarker.connectorKey as string;
		let toType = ElementType.Connector;

		if (!startMarker.connectorKey) {
			toKey = (this.workflowRoot as WorkflowRoot).key;
			toType = (this.workflowRoot as WorkflowRoot).type;
		}

		return {
			key: StartMarkerKey,
			from: {
				key: StartMarkerKey,
				type: ElementType.StartMarker,
				direction: startMarker.routeFromDirection,
			},
			to: {
				key: toKey,
				type: toType,
				direction: startMarker.routeToDirection,
			},
			label: null,
			conditions: [],
			conditionsSettings: null,
		};
	}
}
