import React, { useEffect, useState } from "react";
import { fetchFromApi, sendCapturedRequestToAPI, sendRequestToAPI } from "../../../../sharedActions";
import { DeveloperAPI } from "../../../../api/DeveloperAPI";
import { INITIAL_RENDER_ONLY, TOP_NAV_HEIGHT } from "../../../../utils/constants";
import { canNotAccess } from "../../../../utils/helpers";
import {
	Ability,
	Dropdown,
	DropdownItem,
	ExternalEndpoint,
	ExternalEndpointResponse,
	LoadState,
	Prompt,
	Spinner,
	Tooltip,
} from "design-system";
import Unauthorized from "../../../Unauthorized/Unauthorized";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../../initialState";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import RawResponsePreview from "./RawResponsePreview";
import Header from "../Header";
import SelectedResponseInputValue from "./SelectedResponseInputValue";
import {
	cleaupExternalEndpoint,
	deleteExternalEndpointResponse,
	setExternalEndpointResponse,
	updateSelectedExternalEndpointResponse,
} from "../../developerActions";
import Variables from "./Variables";
import { useListenForApiResponse } from "../../../../utils/hooks/useListenForApiResponse";
import { CAPTURED_NEW_EXTERNAL_ENDPOINT_RESPONSE } from "../../../../capturedResponseTypes";
import UncapturedResponse from "./UncapturedResponse";
import classNames from "classnames";
import PrefillFields from "./PrefillFields";

import styles from "./ResponseBuilder.module.scss";

interface Props extends RouteComponentProps {}

function buildResponsesDropdown(
	responseKeys: Array<string>,
	responses: { [key: string]: ExternalEndpointResponse }
): Array<DropdownItem> {
	return responseKeys.map((key) => {
		const response = responses[key];

		return {
			key,
			label: `Response ${response.version}`,
		};
	});
}

const ResponseBuilder: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const endpoints = useSelector((state: InitialState) => state.externalEndpoints);
	const endpointsLoaded = useSelector(
		(state: InitialState) => state.loadStates.externalEndpoints === LoadState.LOADED
	);
	const responses = useSelector((state: InitialState) => state.externalEndpointResponses);
	const selectedResponseKey = useSelector(
		(state: InitialState) => state.local.externalEndpoints.selectedResponseKey
	);
	const [loadingNewResponse, setLoadingNewResponse] = useState(false);
	const [showConfirmDeleteResponseDialog, setShowConfirmDeleteResponseDialog] = useState(false);

	const endpoint: ExternalEndpoint = endpoints[params.externalEndpointKey];

	useListenForApiResponse(CAPTURED_NEW_EXTERNAL_ENDPOINT_RESPONSE, (response) => {
		dispatch(
			setExternalEndpointResponse(
				response.data.endpointKey,
				response.data.variables,
				response.data.response
			)
		);

		setLoadingNewResponse(false);
	});

	useEffect(() => {
		if (endpoint && endpoint.responses.length > 0) {
			dispatch(updateSelectedExternalEndpointResponse(endpoint.responses[0]));
		}

		return function cleanup() {
			dispatch(cleaupExternalEndpoint());
		};
	}, INITIAL_RENDER_ONLY);

	useEffect(() => {
		if (!endpoint) {
			return;
		}

		if (endpoint.responses.length > 0 && selectedResponseKey === null) {
			dispatch(updateSelectedExternalEndpointResponse(endpoint.responses[0]));
		}
	}, [endpoint]);

	function getCapturedResponseCode() {
		if (!selectedResponseKey) {
			return null;
		}

		return responses[selectedResponseKey].rawJson;
	}

	function onShowDeleteResponseConfirmation() {
		setShowConfirmDeleteResponseDialog(true);
	}

	function onDeleteResponse() {
		setShowConfirmDeleteResponseDialog(false);
		dispatch(deleteExternalEndpointResponse(endpoint.key, selectedResponseKey as string));
		dispatch(
			sendRequestToAPI(
				DeveloperAPI.deleteExternalEndpointResponse(endpoint.key, selectedResponseKey as string)
			)
		);
	}

	function calculateSelectedResponse(
		responseKey: string | null,
		responses: { [key: string]: ExternalEndpointResponse },
		loadingNewResponse: boolean
	): DropdownItem | null {
		if (!responseKey) {
			return null;
		}

		const response = responses[responseKey];

		return {
			key: responseKey,
			label: (
				<SelectedResponseInputValue
					response={response}
					onDelete={onShowDeleteResponseConfirmation}
					isLoading={loadingNewResponse}
				/>
			),
		};
	}

	useEffect(() => {
		if (!endpointsLoaded) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpoints()));
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpointParts(params.externalEndpointKey)));

			return;
		}

		if (!endpoint.loaded) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpointParts(params.externalEndpointKey)));
		}
	}, INITIAL_RENDER_ONLY);

	useEffect(() => {
		if (!endpointsLoaded) return;

		if (!endpoints.hasOwnProperty(params.externalEndpointKey)) {
			window.location.replace("/manage/developers/external-endpoints");
		}
	}, [endpointsLoaded]);

	if (canNotAccess(Ability.ManageDeveloperSettings)) {
		return <Unauthorized requiredPermission="Manage developer settings" />;
	}

	if (!endpointsLoaded) {
		return <Spinner />;
	}

	if (endpoint === undefined) {
		return <Spinner />;
	}

	function captureResponse() {
		if (loadingNewResponse) {
			return;
		}

		setLoadingNewResponse(true);

		dispatch(
			sendCapturedRequestToAPI(
				DeveloperAPI.captureExternalEndpointResponse(endpoint.key),
				CAPTURED_NEW_EXTERNAL_ENDPOINT_RESPONSE
			)
		);
	}

	return (
		<div className={styles.ResponseBuilder} style={{ minHeight: window.innerHeight - TOP_NAV_HEIGHT }}>
			<div className={styles.Page}>
				<Header
					headerText="Response Variables"
					tooltipText="Back to External Endpoint"
					onBack={() => navigate(`/manage/developers/external-endpoints/${endpoint.key}`)}
					onDone={() => navigate(`/manage/developers/external-endpoints/${endpoint.key}`)}
				/>

				<UncapturedResponse
					endpoint={endpoint}
					loading={loadingNewResponse}
					onStart={captureResponse}
				/>

				<div className={classNames([styles.Main, !endpoint.responses.length && styles.Hide])}>
					<div className={styles.ResponseOptions}>
						<Tooltip
							id={`capture-response`}
							placement="top"
							trigger={["hover"]}
							overlay={<span>Capture New Response</span>}
						>
							<svg
								className={styles.CaptureResponseBtn}
								onClick={captureResponse}
								xmlns="http://www.w3.org/2000/svg"
								width="34"
								height="31"
								viewBox="0 0 34 31"
							>
								<g transform="translate(-211 -145)">
									<rect
										width="34"
										height="31"
										rx="4"
										transform="translate(211 145)"
										fill="#f0f3fc"
									/>
									<g transform="translate(219.262 151.975)">
										<path
											d="M25.157,7.826,23.286.847A.373.373,0,0,0,22.663.68l-5.11,5.108a.374.374,0,0,0,.167.625L24.7,8.282a.373.373,0,0,0,.456-.456"
											transform="translate(-8.908 -0.571)"
											fill="#5583ed"
										/>
										<path
											d="M13.005,2.153A7.882,7.882,0,0,0,1.04,7.065a7.889,7.889,0,0,0,8.19,9.389,7.875,7.875,0,0,0,6.413-4.235"
											transform="translate(-0.88 0.578)"
											fill="none"
											stroke="#5583ed"
											strokeLinecap="round"
											strokeMiterlimit="10"
											strokeWidth="1.5"
										/>
									</g>
								</g>
							</svg>
						</Tooltip>

						<Dropdown
							className={styles.Responses}
							options={buildResponsesDropdown(endpoint.responses, responses)}
							onChange={(reponseOption) =>
								dispatch(updateSelectedExternalEndpointResponse(reponseOption.key))
							}
							value={calculateSelectedResponse(
								selectedResponseKey,
								responses,
								loadingNewResponse
							)}
							theme="standard"
							fullWidth={true}
							label={""}
						/>
					</div>

					{selectedResponseKey !== null && !loadingNewResponse && (
						<Variables endpointKey={endpoint.key} response={responses[selectedResponseKey]} />
					)}

					{selectedResponseKey !== null && (
						<PrefillFields response={responses[selectedResponseKey]} />
					)}
				</div>
			</div>
			<RawResponsePreview
				headerText="Captured Response"
				response={getCapturedResponseCode()}
				loading={loadingNewResponse}
			/>

			<Prompt
				show={showConfirmDeleteResponseDialog}
				onConfirm={onDeleteResponse}
				onCancel={() => setShowConfirmDeleteResponseDialog(false)}
			>
				<p>Are you sure you want to delete this Response?</p>
			</Prompt>
		</div>
	);
};

export default ResponseBuilder;
