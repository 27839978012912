import React, { useEffect, useState } from "react";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import {
	Ability,
	Checkbox,
	Dropdown,
	ExternalEndpoint,
	HiddenOptions,
	KeyValuePairsInput,
	LoadState,
	Prompt,
	ShortText,
	Space,
	Spinner,
	VariableInput,
} from "design-system";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../../initialState";
import {
	cleaupExternalEndpoint,
	deleteExternalEndpoint,
	deleteExternalEndpointSetting,
	updateExternalEndpointProperty,
	updateExternalEndpointSetting,
} from "../developerActions";
import {
	fetchFromApi,
	sendCapturedRequestToAPI,
	sendDebouncedRequestToAPI,
	sendRequestToAPI,
} from "../../../sharedActions";
import { DeveloperAPI } from "../../../api/DeveloperAPI";
import { INITIAL_RENDER_ONLY, TOP_NAV_HEIGHT } from "../../../utils/constants";
import SendTestModal from "./SendTestModal";
import { canNotAccess } from "../../../utils/helpers";
import Unauthorized from "../../Unauthorized/Unauthorized";
import { CAPTURED_TEST_EXTERNAL_ENDPOINT } from "../../../capturedResponseTypes";
import FailedNotificationSetup from "./FailedNotificationSetup";
import Header from "./Header";

import styles from "./ExternalEndpoints.module.scss";

interface Props extends RouteComponentProps {}

const RequestTypeOptions = [
	{ key: "GET", label: "Retrieve (GET)" },
	{ key: "POST", label: "Create (POST)" },
	{ key: "PUT", label: "Update (PUT)" },
	{ key: "DELETE", label: "Delete (DELETE)" },
];

function prepareSelectedEvent(type: string) {
	const match = RequestTypeOptions.find((option) => option.key === type);

	if (!match) {
		return null;
	}

	return match;
}

const ExternalEndpointSetup: React.FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const endpoints = useSelector((state: InitialState) => state.externalEndpoints);
	const endpointsLoaded = useSelector(
		(state: InitialState) => state.loadStates.externalEndpoints === LoadState.LOADED
	);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const [showSendTestModal, setShowSendTestModal] = useState(false);

	useEffect(() => {
		if (!endpointsLoaded) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpoints()));
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpointParts(params.externalEndpointKey)));
		}

		if (endpointsLoaded === true && !endpoint.loaded) {
			dispatch(fetchFromApi(DeveloperAPI.loadExternalEndpointParts(params.externalEndpointKey)));
		}

		return function cleanup() {
			dispatch(cleaupExternalEndpoint());
		};
	}, INITIAL_RENDER_ONLY);

	useEffect(() => {
		if (!endpointsLoaded) return;

		if (!endpoints.hasOwnProperty(params.externalEndpointKey)) {
			window.location.replace("/manage/developers/external-endpoints");
		}
	}, [endpointsLoaded]);

	if (canNotAccess(Ability.ManageDeveloperSettings)) {
		return <Unauthorized requiredPermission="Manage developer settings" />;
	}

	function updateKeyValuePairs(
		settingName: string,
		identifier: "key" | "value",
		index: number,
		value: string
	) {
		const endpoint = endpoints[params.externalEndpointKey];

		if (!endpoint) return;

		const updated = endpoint.settings[settingName].map((property: any, i: number) => {
			if (index === i) {
				property[identifier] = value;
			}

			return property;
		});

		dispatch(updateExternalEndpointSetting(endpoint.key, settingName, updated));

		dispatch(
			sendDebouncedRequestToAPI(
				DeveloperAPI.saveExternalEndpointSettings(endpoint.key, settingName, updated)
			)
		);
	}

	function addKeyValuePair(settingName: string) {
		const endpoint = endpoints[params.externalEndpointKey];

		if (!endpoint) return;

		dispatch(
			updateExternalEndpointSetting(endpoint.key, settingName, [
				...endpoint.settings[settingName],
				{ key: "", value: "" },
			])
		);
	}

	function deleteKeyValuePair(settingName: string, index: number) {
		const endpoint = endpoints[params.externalEndpointKey];

		if (!endpoint) return;

		dispatch(
			updateExternalEndpointSetting(
				endpoint.key,
				settingName,
				endpoint.settings[settingName].filter((property: any, i: number) => i !== index)
			)
		);
	}

	function toggleSettingCheckbox(settingName: string) {
		const endpoint = endpoints[params.externalEndpointKey];

		if (!endpoint) {
			return;
		}

		if (endpoint.settings.hasOwnProperty(settingName)) {
			// then remove it
			dispatch(deleteExternalEndpointSetting(endpoint.key, settingName));

			if (
				settingName === "autoRetryFails" &&
				endpoint.settings.hasOwnProperty("failureNotifications")
			) {
				dispatch(
					updateExternalEndpointSetting(endpoint.key, "failureNotifications", {
						...endpoint.settings.failureNotifications,
						maxAttemptsOnly: false,
					})
				);
			}

			dispatch(
				sendDebouncedRequestToAPI(
					DeveloperAPI.deleteExternalEndpointSettings(endpoint.key, settingName)
				)
			);

			return;
		}

		let setting = null;

		if (settingName === "autoRetryFails") {
			setting = {
				delay: 1,
				maxAttempts: 3,
			};
		}

		if (settingName === "customHeaders") {
			setting = [{ key: "", value: "" }];
		}

		if (settingName === "failureNotifications") {
			setting = {
				maxAttemptsOnly: false,
				subscribers: [{ type: null, recipient: null }],
			};
		}

		dispatch(updateExternalEndpointSetting(endpoint.key, settingName, setting));

		dispatch(
			sendRequestToAPI(DeveloperAPI.saveExternalEndpointSettings(endpoint.key, settingName, setting))
		);
	}

	function updateAutoRetryFailsSetting(property: string, value: any) {
		const endpoint = endpoints[params.externalEndpointKey];

		if (!endpoint) {
			return;
		}

		if (isNaN(value)) {
			return;
		}

		if (property === "delay" && value > 180) {
			value = 180;
		}

		if (property === "maxAttempts" && value > 10) {
			value = 10;
		}

		const update = {
			...endpoint.settings.autoRetryFails,
			[property]: value,
		};

		dispatch(updateExternalEndpointSetting(endpoint.key, "autoRetryFails", update));

		dispatch(
			sendRequestToAPI(
				DeveloperAPI.saveExternalEndpointSettings(endpoint.key, "autoRetryFails", update)
			)
		);
	}

	function updateFailedNotificationSettings(property: string, value: any) {
		const endpoint = endpoints[params.externalEndpointKey];

		if (!endpoint) {
			return;
		}

		const update = {
			...endpoint.settings.failureNotifications,
			[property]: value,
		};

		dispatch(updateExternalEndpointSetting(endpoint.key, "failureNotifications", update));

		dispatch(
			sendRequestToAPI(
				DeveloperAPI.saveExternalEndpointSettings(endpoint.key, "failureNotifications", update)
			)
		);
	}

	function update(property: string, value: string) {
		dispatch(updateExternalEndpointProperty(params.externalEndpointKey, property, value));
		dispatch(
			sendDebouncedRequestToAPI(
				DeveloperAPI.saveExternalEndpointProperty(params.externalEndpointKey, property, value)
			)
		);
	}

	async function done() {
		await navigate(`/manage/developers/external-endpoints`);
	}

	async function sendTest() {
		const endpoint = endpoints[params.externalEndpointKey];

		// check for URL

		dispatch(
			sendCapturedRequestToAPI(
				DeveloperAPI.sendExternalEndpointTest(endpoint.key),
				CAPTURED_TEST_EXTERNAL_ENDPOINT
			)
		);
	}

	async function handleDelete() {
		const endpoint = endpoints[params.externalEndpointKey];

		dispatch(deleteExternalEndpoint(endpoint.key));
		dispatch(sendRequestToAPI(DeveloperAPI.deleteExternalEndpoint(endpoint.key)));

		await navigate(`/manage/developers/external-endpoints`);
	}

	if (!endpointsLoaded) {
		return <Spinner />;
	}

	const endpoint: ExternalEndpoint = endpoints[params.externalEndpointKey];

	if (endpoint === undefined) {
		return <Spinner />;
	}

	return (
		<div className={styles.EndpointSetup} style={{ minHeight: window.innerHeight - TOP_NAV_HEIGHT }}>
			<div className={styles.Page}>
				<Header
					headerText="External Endpoint Setup"
					tooltipText="Back to External Endpoints"
					onBack={() => navigate(`/manage/developers/external-endpoints`)}
					onDone={() => navigate(`/manage/developers/external-endpoints`)}
					hiddenOptions={
						<HiddenOptions>
							<div className="WebhooksHiddenActions">
								<p onClick={() => setShowConfirmDeleteDialog(true)}>Delete</p>
								<p onClick={() => setShowSendTestModal(true)}>Send Test</p>
							</div>
						</HiddenOptions>
					}
				/>

				<div className={styles.Basics}>
					<ShortText
						label="Name"
						id="name"
						value={endpoint.name}
						onChange={(event) => update("name", (event.target as HTMLInputElement).value)}
					/>
					<Space amount={16} />

					<Dropdown
						theme="standard"
						options={RequestTypeOptions}
						onChange={(option) => update("type", option.key)}
						value={prepareSelectedEvent(endpoint.type)}
						label="Type"
					/>

					<Space amount={24} />

					<VariableInput
						label="Secure URL"
						subLabel="(https required)"
						className={styles.Url}
						id="url"
						value={endpoint.url}
						onChange={(value: string) => update("url", value)}
					/>

					<Space amount={16} />

					{endpoint.type !== "GET" && (
						<div className={styles.InnerPrompt}>
							<h2>Request Data</h2>
							<div
								onClick={() =>
									navigate(`/manage/developers/external-endpoints/${endpoint.key}/payload`)
								}
							>
								Open Payload Builder
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="19.145"
									height="12.732"
									viewBox="0 0 19.145 12.732"
								>
									<g transform="translate(19.145 12.732) rotate(180)">
										<g transform="translate(0 5.482)">
											<path
												d="M18.191,1.747H.954A.917.917,0,0,1,0,.874.917.917,0,0,1,.954,0H18.191a.917.917,0,0,1,.954.874A.917.917,0,0,1,18.191,1.747Z"
												fill="#caced9"
											/>
										</g>
										<g transform="translate(0.003)">
											<path
												d="M8.733,11.191a.825.825,0,0,1,.121,1.229,1.024,1.024,0,0,1-1.343.114L.384,7.055a.622.622,0,0,1-.163-.142.281.281,0,0,1-.057-.071.611.611,0,0,1-.071-.114A.054.054,0,0,1,.079,6.7a.35.35,0,0,1-.043-.121.751.751,0,0,1,.064-.6.424.424,0,0,1,.064-.107C.178,5.847.2,5.826.214,5.8a.7.7,0,0,1,.128-.121l.043-.028L7.511.206A1.011,1.011,0,0,1,8.854.312a.82.82,0,0,1-.121,1.229l-6.3,4.817Z"
												fill="#a2a9ba"
											/>
										</g>
									</g>
								</svg>
							</div>
						</div>
					)}

					<div className={styles.InnerPrompt}>
						<h2>Response Variables</h2>
						<div
							onClick={() =>
								navigate(`/manage/developers/external-endpoints/${endpoint.key}/response`)
							}
						>
							Open Response Builder
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="19.145"
								height="12.732"
								viewBox="0 0 19.145 12.732"
							>
								<g transform="translate(19.145 12.732) rotate(180)">
									<g transform="translate(0 5.482)">
										<path
											d="M18.191,1.747H.954A.917.917,0,0,1,0,.874.917.917,0,0,1,.954,0H18.191a.917.917,0,0,1,.954.874A.917.917,0,0,1,18.191,1.747Z"
											fill="#caced9"
										/>
									</g>
									<g transform="translate(0.003)">
										<path
											d="M8.733,11.191a.825.825,0,0,1,.121,1.229,1.024,1.024,0,0,1-1.343.114L.384,7.055a.622.622,0,0,1-.163-.142.281.281,0,0,1-.057-.071.611.611,0,0,1-.071-.114A.054.054,0,0,1,.079,6.7a.35.35,0,0,1-.043-.121.751.751,0,0,1,.064-.6.424.424,0,0,1,.064-.107C.178,5.847.2,5.826.214,5.8a.7.7,0,0,1,.128-.121l.043-.028L7.511.206A1.011,1.011,0,0,1,8.854.312a.82.82,0,0,1-.121,1.229l-6.3,4.817Z"
											fill="#a2a9ba"
										/>
									</g>
								</g>
							</svg>
						</div>
					</div>
				</div>

				<div className={styles.AdditionalOptions}>
					<h2>Additional Options</h2>

					{endpoint.type !== "GET" && (
						<div className={styles.Retry}>
							<Checkbox
								id="auto-retry"
								isChecked={endpoint.settings.hasOwnProperty("autoRetryFails")}
								onChange={() => toggleSettingCheckbox("autoRetryFails")}
								label="Automatically retry failures"
							/>

							{endpoint.settings.hasOwnProperty("autoRetryFails") && (
								<div className={styles.RetrySettings}>
									<div className={styles.Delay}>
										<ShortText
											label="Retry Delay"
											id="delay"
											value={endpoint.settings.autoRetryFails.delay}
											onChange={(event) =>
												updateAutoRetryFailsSetting(
													"delay",
													(event.target as HTMLInputElement).value
												)
											}
										/>
										<span>minutes</span>
									</div>
									<ShortText
										label="Max Attempts"
										id="max"
										value={endpoint.settings.autoRetryFails.maxAttempts}
										onChange={(event) =>
											updateAutoRetryFailsSetting(
												"maxAttempts",
												(event.target as HTMLInputElement).value
											)
										}
									/>
								</div>
							)}
						</div>
					)}

					<div className={styles.Notifications}>
						<Checkbox
							id="failed-notifications"
							isChecked={endpoint.settings.hasOwnProperty("failureNotifications")}
							onChange={() => toggleSettingCheckbox("failureNotifications")}
							label="Send email notification upon failure"
						/>

						{endpoint.settings.hasOwnProperty("failureNotifications") && (
							<FailedNotificationSetup
								{...endpoint.settings.failureNotifications}
								autoRetryFails={endpoint.settings.hasOwnProperty("autoRetryFails")}
								maxAttemptsCount={
									endpoint.settings.hasOwnProperty("autoRetryFails")
										? endpoint.settings.autoRetryFails.maxAttempts
										: null
								}
								onUpdate={updateFailedNotificationSettings}
							/>
						)}
					</div>

					<div className={styles.Headers}>
						<Checkbox
							id="custom-headers"
							isChecked={endpoint.settings.hasOwnProperty("customHeaders")}
							onChange={() => toggleSettingCheckbox("customHeaders")}
							label="Include custom request headers"
						/>

						{endpoint.settings.hasOwnProperty("customHeaders") && (
							<div className={styles.HeadersInput}>
								<KeyValuePairsInput
									pairs={endpoint.settings.customHeaders}
									onChange={(identifier, index: number, value: string) =>
										updateKeyValuePairs("customHeaders", identifier, index, value)
									}
									onAddButtonClick={() => addKeyValuePair("customHeaders")}
									onDeletePair={(index: number) =>
										deleteKeyValuePair("customHeaders", index)
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>

			<Prompt
				show={showConfirmDeleteDialog}
				onConfirm={handleDelete}
				onCancel={() => setShowConfirmDeleteDialog(false)}
			>
				<p>Are you sure you want to delete this External Endpoint?</p>
			</Prompt>

			<SendTestModal
				show={showSendTestModal}
				onSend={sendTest}
				onClose={() => setShowSendTestModal(false)}
			/>
		</div>
	);
};

export default ExternalEndpointSetup;
