import {
	SET_BILLING_CYCLE_USAGE,
	SET_PRE_UPGRADE_DATA,
	SET_SUBSCRIPTION,
	SET_SUBSCRIPTION_PAYMENT_METHODS,
	SET_PRE_UPGRADE_INVOICE,
	SET_RECEIPTS,
	SET_FULL_RECEIPT,
} from "../actionTypes";
import { Address } from "design-system";

export class BillingAPI {
	static loadSubscription() {
		return {
			url: "billing",
			respondWith: SET_SUBSCRIPTION,
		};
	}

	static loadPaymentMethods() {
		return {
			url: "billing/payment-methods",
			respondWith: SET_SUBSCRIPTION_PAYMENT_METHODS,
		};
	}

	static loadPreUpgradeData() {
		return {
			url: "billing/pre-upgrade",
			respondWith: SET_PRE_UPGRADE_DATA,
		};
	}

	static loadUsageForBillingCycle(billingCycleKey: string) {
		return {
			url: `billing/usage/${billingCycleKey}`,
			respondWith: SET_BILLING_CYCLE_USAGE,
		};
	}

	static preparePrices(lineItems: Array<any>) {
		return {
			url: "billing/upgrade/pricing",
			data: {
				lineItems,
			},
			respondWith: SET_PRE_UPGRADE_INVOICE,
		};
	}

	static startUpgrade(address: Address, companyName: string, managementType: string) {
		return {
			url: "billing/upgrade/start",
			data: {
				address,
				companyName,
				managementType,
			},
			respondWith: SET_SUBSCRIPTION,
		};
	}

	static finishUpgrade(lineItems: Array<any>) {
		return {
			url: "billing/upgrade/finish",
			data: {
				lineItems,
			},
		};
	}

	static updateSubscriptionResource(
		resourceType: string,
		updatedBase: number,
		updatedCurrentCycle: number,
		onlyChangeCurrentCycle: boolean
	) {
		return {
			url: `billing/resources/${resourceType}`,
			data: {
				base: updatedBase,
				currentCycle: updatedCurrentCycle,
				onlyChangeCurrentCycle,
			},
		};
	}

	static cancelSubscription(retentionPeriod: any, survey: any) {
		return {
			url: "billing/cancel",
			data: {
				retentionPeriod,
				survey,
			},
		};
	}

	static restartSubscription() {
		return {
			url: "billing/restart",
			data: {},
		};
	}

	static saveBillingDetails(companyName: string, address: Address) {
		return {
			url: "billing/details",
			data: {
				companyName,
				address,
			},
		};
	}

	static loadReceipts() {
		return {
			url: "billing/receipts",
			respondWith: SET_RECEIPTS,
		};
	}

	static loadReceipt(receiptNumber: string) {
		return {
			url: `billing/receipts/${receiptNumber}`,
			respondWith: SET_FULL_RECEIPT,
		};
	}

	static updateManagementType(managementType: "manual" | "auto") {
		return {
			url: "billing/management-type",
			data: {
				managementType,
			},
		};
	}
}
