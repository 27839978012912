import React, { useState } from "react";
import classNames from "classnames";
import { Button, ExternalEndpoint, Space, Spinner } from "design-system";

import styles from "./ResponseBuilder.module.scss";

interface Props {
	endpoint: ExternalEndpoint;
	loading: boolean;
	onStart: () => void;
}

const UncapturedResponse: React.FunctionComponent<Props> = ({ endpoint, loading, onStart }) => {
	function handleStartCapture() {
		if (loading) {
			return;
		}
		onStart();
	}

	const classes = classNames([styles.UncapturedResponse, endpoint.responses.length === 0 && styles.Show]);

	return (
		<div className={classes}>
			<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
				<g transform="translate(-233 -155)">
					<circle cx="20" cy="20" r="20" transform="translate(233 155)" fill="#265dde" />
					<g transform="translate(241.963 162)">
						<g>
							<path
								d="M16.1,10.609l-1.935,1.134a7.375,7.375,0,0,0-2.12-1.191l-.03-2.227a.391.391,0,0,0-.389-.386L7.294,8a.379.379,0,0,0-.381.38L6.939,10.6a7.963,7.963,0,0,0-1.135.56,8.31,8.31,0,0,0-.889.612L2.859,10.6a.388.388,0,0,0-.529.153L.05,14.775a.385.385,0,0,0,.144.524l2.082,1.18a7.142,7.142,0,0,0,.043,2.3l-1.861,1.1a.389.389,0,0,0-.135.531L2.55,24.159a.383.383,0,0,0,.531.135l1.857-1.112a7.275,7.275,0,0,0,2.145,1.2l.025,2.235A.389.389,0,0,0,7.5,27l4.336-.048a.389.389,0,0,0,.378-.394l-.025-2.235a7.073,7.073,0,0,0,1.113-.54,7.718,7.718,0,0,0,.893-.62l2.078,1.167a.387.387,0,0,0,.532-.14l2.259-4.022a.389.389,0,0,0-.14-.533l-2.094-1.176a7.176,7.176,0,0,0-.052-2.306l1.923-1.13a.391.391,0,0,0,.135-.531l-2.206-3.748a.388.388,0,0,0-.531-.135m-2.153,9.33a5.031,5.031,0,1,1-1.893-6.852,5.032,5.032,0,0,1,1.893,6.852"
								fill="#fff"
							/>
							<path
								d="M21.756,4.022a.224.224,0,0,0-.221-.221l-1.279.028a4.22,4.22,0,0,0-.72-1.182l.605-1.114a.22.22,0,0,0-.086-.3L17.892.053a.22.22,0,0,0-.3.086l-.6,1.1a4.294,4.294,0,0,0-.711-.038,3.28,3.28,0,0,0-.615.057L14.961.11a.21.21,0,0,0-.3-.077L12.4,1.388a.22.22,0,0,0-.068.308l.7,1.163a4.04,4.04,0,0,0-.615,1.153l-1.24.029a.231.231,0,0,0-.211.231l.057,2.479a.223.223,0,0,0,.221.212l1.24-.029a3.957,3.957,0,0,0,.731,1.191L12.606,9.24a.221.221,0,0,0,.087.3l2.162,1.182a.217.217,0,0,0,.3-.086l.615-1.115a4.291,4.291,0,0,0,.7.048,4.433,4.433,0,0,0,.615-.067l.711,1.163a.21.21,0,0,0,.3.077l2.249-1.365a.208.208,0,0,0,.067-.3l-.7-1.173a4.225,4.225,0,0,0,.615-1.162l1.268-.02a.217.217,0,0,0,.212-.23Zm-5.363,3.5a2.134,2.134,0,1,1,2.134-2.134A2.129,2.129,0,0,1,16.393,7.52"
								fill="#fff"
							/>
						</g>
					</g>
				</g>
			</svg>

			<h3>Get Started: Capture Response</h3>

			<div>
				<p>
					We'll send a test request to your endpoint to capture the data structure of the response.
				</p>

				<span>URL {endpoint.url}</span>

				<Space amount={32} />

				<Button
					color="primary"
					theme="solid"
					tone="medium"
					size="standard"
					onClick={handleStartCapture}
				>
					{loading ? <Spinner color="white" /> : "Start"}
				</Button>
			</div>
		</div>
	);
};

export default UncapturedResponse;
