import {
	DISTINCT_FETCH_FROM_API,
	FETCH_FROM_API,
	FILE_UPLOAD_COMPLETE,
	FILE_UPLOAD_FAILED,
	FORGET_CAPTURED_API_RESPONSE,
	MONITOR_FILE_UPLOAD_PROGRESS,
	SEND_CAPTURED_REQUEST_TO_API,
	SEND_DEBOUNCED_REQUEST_TO_API,
	SEND_REQUEST_TO_API,
	START_FILE_UPLOAD,
	SYNCHRONOUS_REQUEST_TO_API,
	UPDATE_FILE_UPLOAD_PROGRESS,
} from "./actionTypes";
import { APIRequestObject } from "design-system";
import { AnyAction } from "redux";

export function queueRequestToAPI(APIRequestObject: APIRequestObject) {
	let data: AnyAction = {
		type: SYNCHRONOUS_REQUEST_TO_API,
		url: APIRequestObject.url,
		data: APIRequestObject.data,
		method: APIRequestObject.method || "post",
	};

	if (APIRequestObject.respondWith !== undefined) {
		data.respondWith = APIRequestObject.respondWith;
	}

	return data;
}

export function sendRequestToAPI(APIRequestObject: APIRequestObject) {
	let data: AnyAction = {
		type: SEND_REQUEST_TO_API,
		url: APIRequestObject.url,
		data: APIRequestObject.data,
		method: APIRequestObject.method || "post",
	};

	if (APIRequestObject.respondWith !== undefined) {
		data.respondWith = APIRequestObject.respondWith;
	}

	return data;
}

export function sendCapturedRequestToAPI(APIRequestObject: APIRequestObject, captureKey: string) {
	return {
		type: SEND_CAPTURED_REQUEST_TO_API,
		url: APIRequestObject.url,
		data: APIRequestObject.data,
		method: APIRequestObject.method || "post",
		captureKey,
	};
}

export function uploadFile(APIRequestObject: APIRequestObject) {
	return {
		type: START_FILE_UPLOAD,
		url: APIRequestObject.url,
		data: APIRequestObject.data,
		method: "post",
		respondWith: APIRequestObject.respondWith,
	};
}

export function sendDebouncedRequestToAPI(APIRequestObject: APIRequestObject) {
	return {
		type: SEND_DEBOUNCED_REQUEST_TO_API,
		url: APIRequestObject.url,
		data: APIRequestObject.data,
		method: APIRequestObject.method || "post",
	};
}

export function fetchFromApi(APIRequestObject: APIRequestObject) {
	return {
		type: FETCH_FROM_API,
		url: APIRequestObject.url,
		respondWith: APIRequestObject.respondWith,
	};
}

export function distinctFetchFromApi(APIRequestObject: APIRequestObject) {
	return {
		type: DISTINCT_FETCH_FROM_API,
		url: APIRequestObject.url,
		respondWith: APIRequestObject.respondWith,
	};
}

export function monitorFileUpload(key: string, preview?: string | ArrayBuffer | null) {
	return {
		type: MONITOR_FILE_UPLOAD_PROGRESS,
		key,
		preview,
	};
}

export function updateFileUploadProgress(key: string, progressEvent: ProgressEvent) {
	return {
		type: UPDATE_FILE_UPLOAD_PROGRESS,
		key,
		progress: {
			loaded: progressEvent.loaded,
			total: progressEvent.total,
		},
	};
}

export function fileUploadFailed(key: string, errors: Array<{ type: string; message: string }>) {
	return {
		type: FILE_UPLOAD_FAILED,
		key,
		errors,
	};
}

export function fileUploadProgressComplete(key: string) {
	return {
		type: FILE_UPLOAD_COMPLETE,
		key,
	};
}

export function receiveCustomResponseAction(actionType: string, response: any) {
	return {
		type: actionType,
		...response.data,
	};
}

export function forgetSavedApiResponse(key: string) {
	return {
		type: FORGET_CAPTURED_API_RESPONSE,
		key,
	};
}
