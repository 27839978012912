import React from "react";
import { amOnUnAuthenticatedPage } from "./helpers";
import AuthlessEntry from "../components/AuthlessEntry";
import App from "../components/App";

export function calculateRootDOMComponent() {

	if (amOnUnAuthenticatedPage()) {
		return <AuthlessEntry />;
	}

	return <App />;
}
