import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from "react-redux";
import classNames from "classnames";
import {
	CategorizedCheckboxPanel,
	Checkbox,
	Space,
	WorkflowAccess as WorkflowAccessInterface,
	WorkflowAccessType,
	WorkflowCategoriesList,
	WorkflowsList,
} from "design-system";
import { allWorkflows, restrictedWorkflows } from "./WorkflowAccessTypeImages";
import { InitialState } from "../../../../initialState";
import { isWorkingKey } from "../../../../utils/helpers";

import styles from "./User.module.scss";

interface Props extends RouteComponentProps {
	description?: string;
	workflowAccess: WorkflowAccessInterface;
	updateType: (type: WorkflowAccessType) => void;
	updateGrantNewAccess: (value: boolean) => void;
	setAccessWorkflows: (workflows: Array<string>) => void;
}

const workflowTypes = [
	{
		key: WorkflowAccessType.All,
		text: "All Workflows",
		image: allWorkflows,
	},
	{
		key: WorkflowAccessType.Restricted,
		text: "Specific Workflows",
		image: restrictedWorkflows,
	},
];

function createWorkflowCategoryDictionary(categories: WorkflowCategoriesList, workflows: WorkflowsList) {
	let dictionary: { [key: string]: Array<string> } = {};

	Object.keys(categories).forEach((categoryKey) => {
		dictionary[categoryKey] = [];
	});

	Object.keys(workflows).forEach((workflowKey) => {
		const categoryKey = workflows[workflowKey].categoryKey;

		if (!categoryKey) {
			return;
		}

		dictionary[categoryKey] = [...dictionary[categoryKey], workflowKey];
	});

	return dictionary;
}

function calculateUnpublishedWorkflows(workflows: WorkflowsList) {
	let unpubWorkflows: WorkflowsList = {};

	Object.keys(workflows)
		.filter((workflowKey) => workflows[workflowKey].deletedAt === null)
		.forEach((workflowKey) => {
			if (isWorkingKey(workflowKey)) {
				unpubWorkflows[workflowKey] = workflows[workflowKey];
			}
		});

	return unpubWorkflows;
}

const WorkflowAccess: React.FunctionComponent<Props> = ({
	workflowAccess,
	description,
	updateType,
	updateGrantNewAccess,
	setAccessWorkflows,
}) => {
	const allWorkflows = useSelector((state: InitialState) => state.workflows);
	const categories = useSelector((state: InitialState) => state.workflowCategories);
	const workflows = React.useMemo(() => calculateUnpublishedWorkflows(allWorkflows), [allWorkflows]);

	let dictionary = createWorkflowCategoryDictionary(categories, workflows);

	const workflowAccessTypes = workflowTypes.map((accessType) => {
		const accessTypesClasses = classNames([
			styles.AccessType,
			workflowAccess.type === accessType.key && styles.active,
		]);
		return (
			<div
				key={accessType.key}
				className={accessTypesClasses}
				onClick={() => updateType(accessType.key)}
			>
				{accessType.image}
				<p>{accessType.text}</p>
			</div>
		);
	});

	function isSelected(key: string, type: "category" | "item"): boolean {
		if (type === "item") {
			return workflowAccess.workflows.some((workflow: string) => key === workflow);
		}

		const allWorkflowsInCategory = Object.keys(workflows).filter(
			(workflowKey) => workflows[workflowKey].categoryKey === key
		);

		if (!allWorkflowsInCategory.length) {
			return false;
		}

		return allWorkflowsInCategory.every((workflow) => workflowAccess.workflows.includes(workflow));
	}

	function isPartiallySelected(categoryKey: string): boolean {
		const allWorkflowsInCategory = Object.keys(workflows).filter(
			(workflowKey) => workflows[workflowKey].categoryKey === categoryKey
		);

		return allWorkflowsInCategory.some((workflow) => workflowAccess.workflows.includes(workflow));
	}

	function selectWorkflowCategory(category: string) {
		const categorySelected = isSelected(category, "category");
		const allWorkflowsInCategory = Object.keys(workflows).filter(
			(workflowKey) => workflows[workflowKey].categoryKey === category
		);

		if (!categorySelected) {
			// easy way to merge two arrays together and remove duplicates
			const nextWorkflows = Array.from(
				new Set([...workflowAccess.workflows, ...allWorkflowsInCategory])
			);

			setAccessWorkflows(nextWorkflows);

			return;
		}

		setAccessWorkflows(
			workflowAccess.workflows.filter(
				(workflowKey: string) => !allWorkflowsInCategory.includes(workflowKey)
			)
		);
	}

	function selectWorkflow(workflow: string) {
		let nextWorkflows = [...workflowAccess.workflows];

		if (workflowAccess.workflows.includes(workflow)) {
			setAccessWorkflows(nextWorkflows.filter((key) => key !== workflow));

			return;
		}

		setAccessWorkflows([...nextWorkflows, workflow]);
	}

	function selectAll() {
		const allWorkflows = Object.keys(workflows);

		setAccessWorkflows(allWorkflows);
	}

	function deselectAll() {
		setAccessWorkflows([]);
	}

	return (
		<div className={styles.WorkflowAccess}>
			<h3>Workflow Access</h3>
			<span>{!description ? "This person has access to" : description}</span>

			<div className={styles.AccessTypes}>{workflowAccessTypes}</div>

			{workflowAccess.type === WorkflowAccessType.Restricted && (
				<div>
					<Checkbox
						id="grantNewWorkflows"
						isChecked={workflowAccess.grantNew}
						onChange={() => updateGrantNewAccess(!workflowAccess.grantNew)}
						label="Automatically grant access to all new workflows"
						deactivated={false}
					/>

					<Space amount={32} />
					<CategorizedCheckboxPanel
						label="Choose Workflows"
						pluralItemLabel="workflows"
						categories={categories}
						items={workflows}
						dictionary={dictionary}
						isSelected={isSelected}
						isPartiallySelected={isPartiallySelected}
						selectCategory={selectWorkflowCategory}
						selectItem={selectWorkflow}
						selectAll={selectAll}
						deselectAll={deselectAll}
					/>
				</div>
			)}
		</div>
	);
};

export default WorkflowAccess;
