import React from "react";
import { convertToMoment } from "../../../../../../../utils/helpers";
import { JobActionStatus } from "design-system";

import styles from "./Action.module.scss";

interface Props {
	status: JobActionStatus;
	startedAt: string;
}

const Date: React.FunctionComponent<Props> = ({ status, startedAt }) => {
	let label = "Completed At";

	if (status === JobActionStatus.Received || status === JobActionStatus.Processing) {
		label = "Started On";
	}

	const date = convertToMoment(startedAt);

	return (
		<div>
			<p className={styles.title}>{label}</p>
			{date.format("MMM Do")}
			&nbsp;at&nbsp;
			{date.format("h:mm A")}
		</div>
	);
};

export default Date;
