import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ImageViewer, JobContentType, JobMedia } from "design-system";
import { InitialState } from "../../../../../../../initialState";

import styles from "./Step.module.scss";

interface Props {
	jobKey: string;
	contentKeys: Array<string>;
}

const UploadedAssets: React.FunctionComponent<Props> = ({ jobKey, contentKeys }) => {
	const [showEnlargedImage, setShowEnlargedImage] = useState<null | string>(null);
	const jobContent = useSelector((state: InitialState) => state.jobsContent[jobKey]);

	const mediaKeys = useMemo(() => {
		if (!jobContent) {
			return [];
		}

		return contentKeys.filter(
			(contentKey: string) => jobContent[contentKey].contentType === JobContentType.Media
		);
	}, [contentKeys]);

	const assets = mediaKeys.map((assetKey: string) => {
		const asset = jobContent[assetKey] as JobMedia;

		return (
			<div
				key={assetKey}
				onClick={(event) => {
					event.preventDefault();
					setShowEnlargedImage(assetKey);
				}}
			>
				<img src={asset.url as string} />
			</div>
		);
	});

	return (
		<div>
			{assets.length > 0 && <div className={styles.Assets}>{assets}</div>}

			<ImageViewer
				show={showEnlargedImage !== null}
				onHide={() => setShowEnlargedImage(null)}
				url={
					showEnlargedImage !== null
						? ((jobContent[showEnlargedImage] as JobMedia).url as string)
						: null
				}
			/>
		</div>
	);
};

export default UploadedAssets;
