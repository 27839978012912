import { InitialState } from "../../initialState";
import {
	Account,
	FormField,
	Job,
	JobActionStatus,
	LoadState,
	LogItem,
	Me,
	PersonAssignmentOption,
	StandardFormFieldType,
	ViewFilter,
	ViewFiltersList,
} from "design-system";
import { AnyAction } from "redux";
import {
	ADD_FIELD_TO_JOB_STEP,
	CANCEL_SCHEDULED_RESEND,
	CLEANUP_OPEN_JOB,
	CLEAR_JOB_STEP_WAITING,
	CLEAR_UNSAVED_FIELDS,
	CREATE_JOB_COMMENT,
	CREATE_JOB_CONTENT_ITEM,
	CREATE_JOB_STEP,
	DELETE_JOB,
	DELETE_JOB_COMMENT,
	DELETE_JOB_CONTENT_ITEM,
	JOB_REFRESH_COUNT,
	JOB_STATUS_CHANGE,
	LIVE_ADD_JOB_ACTIVE_ELEMENT,
	LIVE_APPEND_JOB_LOG_ENTRY,
	LIVE_CLOSE_JOB,
	LIVE_CREATE_JOB_ACTION,
	LIVE_CREATE_JOB_STEP,
	LIVE_JOB_COMMENT_ADDED,
	LIVE_JOB_FIELDS_UPDATED,
	LIVE_JOB_STEP_ASSIGNEES_UPDATED,
	LIVE_JOB_TIMESTAMP_UPDATED,
	LIVE_JOB_UPDATED,
	LIVE_REFRESH_JOB_VIEWS,
	LIVE_REMOVE_ALL_JOB_ACTIVE_ELEMENTS,
	LIVE_REMOVE_JOB_ACTIVE_ELEMENT,
	LIVE_SET_JOB,
	LIVE_UPDATE_JOB_ACTION,
	LIVE_UPDATE_JOB_STEP,
	LIVE_USER_COMPLETED_JOB_STEP,
	LOADING_JOBS,
	MANUALLY_RESEND_ACTION,
	POLL_FOR_WAITING_JOB_STEP,
	PREFILL_JOB_FIELD,
	RESET_JOB_STEP_POLLING_COUNT,
	SET_ACTION_BLUEPRINT,
	SET_ELEMENT_BLUEPRINTS,
	SET_FIELDS_TO_PREFILL,
	SET_FIRST_STEP,
	SET_FULL_JOB,
	SET_JOB,
	SET_JOB_STEP,
	SET_JOB_STEP_INSTRUCTIONS,
	SET_JOB_TABLE_VIEWS,
	SET_STEP_CONTENT_ELEMENT,
	SET_JOBS,
	SET_NEW_JOB,
	SET_NEXT_JOB_STEP_KEY,
	SET_STEP_BLUEPRINT,
	SET_STEP_BLUEPRINTS,
	START_POLLING_FOR_WAITING_JOB_STEP,
	STARTING_NEW_JOB,
	STOP_POLLING_FOR_WAITING_JOB_STEP,
	TOGGLE_JOB_TABLE_VIEW_CONTROL,
	UPDATE_JOB_CONTENT_ITEM,
	UPDATE_JOB_FIELD,
	UPDATE_JOB_STEP,
	UPDATE_JOB_STEP_ASSIGNEES,
	UPDATE_OPEN_JOB_KB_SETTINGS,
	UPDATE_SELECTED_JOB_VIEW,
	WAIT_FOR_INSTRUCTIONS,
	LIVE_CREATE_JOB_DELAY,
	LIVE_JOB_STATUS_UPDATED,
} from "../../actionTypes";
import {
	normalizeActions,
	normalizeBasic,
	normalizeJobStep,
	normalizeSteps,
} from "../../data/normalize/basic";
import {
	buildNewJobAutomationVariables,
	convertJobCommentsToRichText,
	convertSingleCommentToRichText,
	convertTextContentToRichText,
	mergeUnique,
} from "../../utils/helpers";

export function jobs(state: InitialState, action: AnyAction) {
	if (action.type === STARTING_NEW_JOB) {
		return {
			...state,
			loadStates: {
				...state.loadStates,
				startingNewJob: LoadState.LOADING,
			},
		};
	}

	if (action.type === SET_JOB) {
		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.job.key]: {
					...action.job,
				},
			}),
			jobsFields: Object.assign({}, state.jobsFields, {
				[action.job.key]: {},
			}),
		};
	}

	if (action.type === SET_NEW_JOB) {
		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.job.key]: {
					...action.job,
				},
			}),
			jobLogs: {
				...state.jobLogs,
				[action.job.key]: [action.logItem],
			},
			jobsFields: Object.assign({}, state.jobsFields, {
				[action.job.key]: {},
			}),
		};
	}

	if (action.type === JOB_STATUS_CHANGE) {
		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					completedAt: action.completedAt,
				},
			}),
		};
	}

	if (action.type === SET_FULL_JOB) {
		const { steps, userGeneratedContent } = normalizeJobStep(action.steps);
		const actions = normalizeBasic("actions", action.actions);
		const delays = normalizeBasic("delays", action.delays);
		const comments = normalizeBasic("comments", action.comments);

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.job.key]: {
					...action.job,
					loaded: true,
				},
			}),
			jobsSteps: Object.assign({}, state.jobsSteps, steps),
			jobsActions: Object.assign({}, state.jobsActions, actions),
			jobsDelays: Object.assign({}, state.jobsDelays, delays),
			jobsFields: Object.assign({}, state.jobsFields, {
				[action.job.key]: Object.assign({}, state.jobsFields[action.job.key], action.content.fields),
			}),
			jobsAutomationVariables: Object.assign({}, state.jobsAutomationVariables, {
				[action.job.key]: action.variables,
			}),
			jobsComments: Object.assign({}, state.jobsComments, {
				[action.job.key]: { ...convertJobCommentsToRichText(comments) },
			}),
			jobsContent: {
				...state.jobsContent,
				[action.job.key]: userGeneratedContent,
			},
			jobLogs: Object.assign({}, state.jobLogs, {
				[action.job.key]: action.logs,
			}),
		};
	}

	if (action.type === SET_JOBS) {
		const jobs = normalizeBasic("jobs", action.jobs);

		let viewKey = action.viewKey;

		if (!viewKey) {
			viewKey = `all_${(state.account as Account).key}`;
		}

		let existingJobKeys: Array<string> = [];
		if (state.jobTableViews[viewKey]) {
			existingJobKeys = state.jobTableViews[viewKey].jobKeys;
		}

		return {
			...state,
			jobs: Object.assign({}, state.jobs, jobs),
			// could this cause a problem because its probably not merging the fields, only the top level job key?
			jobsFields: Object.assign({}, state.jobsFields, action.fields),
			loadStates: {
				...state.loadStates,
				jobs: LoadState.LOADED,
			},
			jobTableViews: {
				...state.jobTableViews,
				[viewKey]: {
					...state.jobTableViews[viewKey],
					loaded: true,
					jobKeys:
						existingJobKeys.length > 0
							? mergeUnique(existingJobKeys, Object.keys(jobs))
							: Object.keys(jobs),
					pagination: action.pagination,
				},
			},
		};
	}

	if (action.type === SET_JOB_STEP) {
		let jobAutomationVariables = buildNewJobAutomationVariables(action.variables, {
			...state.jobsAutomationVariables[action.jobKey],
		});

		let nextState = {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStep.key]: action.jobStep,
			}),
			jobsAutomationVariables: {
				...state.jobsAutomationVariables,
				[action.jobKey]: jobAutomationVariables,
			},
		};

		if (!state.jobsFields[action.jobKey]) {
			nextState.jobsFields = {
				...nextState.jobsFields,
				[action.jobKey]: {
					...action.jobFields,
				},
			};
		} else {
			nextState.jobsFields = {
				...nextState.jobsFields,
				[action.jobKey]: {
					...nextState.jobsFields[action.jobKey],
					...action.jobFields,
				},
			};
		}

		return nextState;
	}

	if (action.type === JOB_REFRESH_COUNT) {
		return {
			...state,
			jobRefreshCount: state.jobRefreshCount + 1,
		};
	}

	if (action.type === SET_ELEMENT_BLUEPRINTS) {
		const {
			steps,
			elementConnections,
			stepRoutes,
			elementRouteConditions,
			stepContentElements,
			conditionalContentConditions,
			conditionalContentElements,
		} = normalizeSteps(action.steps);

		const subWorkflowConnectors = Object.assign(
			{},
			state.subWorkflowConnectors,
			normalizeBasic("subWorkflows", action.subWorkflows)
		);

		const connectors = Object.assign(
			{},
			state.connectors,
			normalizeBasic("connectors", action.connectors)
		);

		const { actions, actionRoutes } = normalizeActions(action.actions);

		const ends = Object.assign({}, state.ends, normalizeBasic("ends", action.ends));

		return {
			...state,
			connectors: Object.assign({}, state.connectors, connectors),
			steps: Object.assign({}, state.steps, steps),
			ends: Object.assign({}, state.ends, ends),
			subWorkflowConnectors: Object.assign({}, state.subWorkflowConnectors, subWorkflowConnectors),
			actions: Object.assign({}, state.actions, actions),
			elementConnections: Object.assign({}, state.elementConnections, elementConnections),
			elementRoutes: Object.assign({}, state.elementRoutes, stepRoutes, actionRoutes),
			elementRouteConditions: Object.assign({}, state.elementRouteConditions, elementRouteConditions),
			stepContentElements: Object.assign(
				{},
				state.stepContentElements,
				convertTextContentToRichText(stepContentElements),
				convertTextContentToRichText(conditionalContentElements)
			),
			conditionalContentConditions: Object.assign(
				{},
				state.conditionalContentConditions,
				conditionalContentConditions
			),
		};
	}

	if (action.type === SET_STEP_BLUEPRINTS) {
		const {
			steps,
			elementConnections,
			stepRoutes,
			elementRouteConditions,
			stepContentElements,
			conditionalContentConditions,
			conditionalContentElements,
		} = normalizeSteps(action.steps);

		return {
			...state,
			steps: Object.assign({}, state.steps, steps),
			elementConnections: Object.assign({}, state.elementConnections, elementConnections),
			elementRoutes: Object.assign({}, state.elementRoutes, stepRoutes),
			elementRouteConditions: Object.assign({}, state.elementRouteConditions, elementRouteConditions),
			stepContentElements: Object.assign(
				{},
				state.stepContentElements,
				convertTextContentToRichText(stepContentElements),
				convertTextContentToRichText(conditionalContentElements)
			),
			conditionalContentConditions: Object.assign(
				{},
				state.conditionalContentConditions,
				conditionalContentConditions
			),
		};
	}

	if (action.type === SET_STEP_CONTENT_ELEMENT) {
		return {
			...state,
			stepContentElements: {
				...state.stepContentElements,
				[action.element.key]: action.element,
			},
		};
	}

	if (action.type === SET_STEP_BLUEPRINT || action.type === SET_FIRST_STEP) {
		const {
			steps,
			elementConnections,
			stepRoutes,
			elementRouteConditions,
			stepContentElements,
			conditionalContentConditions,
			conditionalContentElements,
		} = normalizeSteps({ steps: action.step });

		let nextState = {
			...state,
			steps: Object.assign({}, state.steps, steps),
			elementConnections: Object.assign({}, state.elementConnections, elementConnections),
			elementRoutes: Object.assign({}, state.elementRoutes, stepRoutes),
			elementRouteConditions: Object.assign({}, state.elementRouteConditions, elementRouteConditions),
			stepContentElements: Object.assign(
				{},
				state.stepContentElements,
				convertTextContentToRichText(stepContentElements),
				convertTextContentToRichText(conditionalContentElements)
			),
			conditionalContentConditions: Object.assign(
				{},
				state.conditionalContentConditions,
				conditionalContentConditions
			),
		};

		if (action.hasOwnProperty("formFields")) {
			nextState.formFields = Object.assign(
				{},
				state.formFields,
				normalizeBasic("fields", action.formFields)
			);
		}

		if (action.type === SET_FIRST_STEP) {
			nextState.loadStates = {
				...state.loadStates,
				startingNewJob: LoadState.LOADED,
			};

			nextState.jobsSteps = Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					step: {
						key: action.step.key,
						name: action.step.name,
					},
				},
			});
		}

		return nextState;
	}

	if (action.type === SET_ACTION_BLUEPRINT) {
		const { actions, actionRoutes } = normalizeActions([action.action]);

		return {
			...state,
			actions: Object.assign({}, state.actions, actions),
			elementRoutes: Object.assign({}, state.elementRoutes, actionRoutes),
		};
	}

	if (action.type === SET_JOB_TABLE_VIEWS) {
		let defaultKey = action.defaultKey;

		if (!defaultKey) {
			const allJobsView = action.views.find((viewRaw: any) => viewRaw.key.includes("all_"));

			defaultKey = allJobsView.key;
		}

		let views = action.views.map((viewRaw: any) => {
			let filters: ViewFiltersList = {};
			viewRaw.filters.forEach((filter: ViewFilter) => {
				filters[filter.key] = filter;
			});

			let accessIndex: any = {};
			viewRaw.access.forEach((access: any) => {
				accessIndex[access.permission] = access;
			});

			return {
				...viewRaw,
				...state.jobTableViews[viewRaw.key],
				access: accessIndex,
				filters,
			};
		});

		views = normalizeBasic("views", views);

		return {
			...state,
			jobTableViews: views,
			jobTableViewControls: {
				...state.jobTableViewControls,
				defaultViewKey: defaultKey,
				selectedViewKey: defaultKey,
			},
		};
	}

	if (action.type === TOGGLE_JOB_TABLE_VIEW_CONTROL) {
		return {
			...state,
			jobTableViewControls: {
				...state.jobTableViewControls,
				// @ts-ignore
				[action.settingName]: !state.jobTableViewControls[action.settingName],
			},
		};
	}

	if (action.type === UPDATE_SELECTED_JOB_VIEW) {
		return {
			...state,
			jobTableViewControls: {
				...state.jobTableViewControls,
				selectedViewKey: action.viewKey,
			},
		};
	}

	if (action.type === LOADING_JOBS) {
		return {
			...state,
			loadStates: {
				...state.loadStates,
				jobs: LoadState.LOADING,
			},
		};
	}

	if (action.type === WAIT_FOR_INSTRUCTIONS) {
		return {
			...state,
			openJob: {
				...state.openJob,
				jobStepKeyWaiting: action.currentJobStepKey,
			},
		};
	}

	if (action.type === CLEAR_JOB_STEP_WAITING) {
		return {
			...state,
			openJob: {
				...state.openJob,
				jobStepKeyWaiting: null,
				nextInstructions: null,
				nextJobStepKey: null,
			},
		};
	}

	if (action.type === CLEANUP_OPEN_JOB) {
		return {
			...state,
			loadStates: {
				...state.loadStates,
			},
			openJob: {
				...state.openJob,
				jobStepKeyWaiting: null,
				nextInstructions: null,
				nextJobStepKey: null,
				pollingCount: 0,
				pollingTimeStart: 0,
				prefilledFields: [],
				unsavedFields: {},
				knowledgeBase: {
					...state.openJob.knowledgeBase,
					show: false,
					selectedPageKey: null,
				},
			},
			selectedJobStepKey: null,
			selectedWorkflowKey: null,
		};
	}

	if (action.type === SET_FIELDS_TO_PREFILL) {
		// for setting fields on the job as it starts
		return {
			...state,
			openJob: {
				...state.openJob,
				prefilledFields: action.fields,
			},
		};
	}

	if (action.type === PREFILL_JOB_FIELD) {
		const preparedFields: { [key: string]: string } = {};
		action.fields.forEach((fieldObj: { key: string; value: string }) => {
			if (fieldObj.key === undefined || fieldObj.value === undefined) {
				return;
			}

			const field: FormField = state.formFields[fieldObj.key];

			if (!field) {
				return;
			}

			if (field.type === StandardFormFieldType.PHONE) {
				fieldObj.value = `USA_${fieldObj.value}`;
			}

			preparedFields[fieldObj.key] = fieldObj.value;
		});

		return {
			...state,
			jobsFields: Object.assign({}, state.jobsFields, {
				[action.jobKey]: {
					...state.jobsFields[action.jobKey],
					...preparedFields,
				},
			}),
		};
	}

	if (action.type === CLEAR_UNSAVED_FIELDS) {
		return {
			...state,
			openJob: {
				...state.openJob,
				unsavedFields: {},
			},
		};
	}

	if (action.type === UPDATE_JOB_FIELD) {
		let nextState = {
			...state,
			jobsFields: Object.assign({}, state.jobsFields, {
				[action.jobKey]: {
					...state.jobsFields[action.jobKey],
					[action.fieldKey]: action.fieldValue,
				},
			}),
		};
		const jobKey = action.jobKey;

		if (!(jobKey in nextState.openJob.unsavedFields)) {
			nextState.openJob.unsavedFields[jobKey] = {};
		}

		const jobStepKey = action.jobStepKey;

		if (!(jobStepKey in nextState.openJob.unsavedFields[jobKey])) {
			nextState.openJob.unsavedFields[jobKey][jobStepKey] = [];
		}

		if (!nextState.openJob.unsavedFields[jobKey][jobStepKey].includes(action.fieldKey)) {
			nextState.openJob.unsavedFields[jobKey][jobStepKey].push(action.fieldKey);
		}

		return nextState;
	}

	if (action.type === ADD_FIELD_TO_JOB_STEP) {
		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					fields: [...state.jobsSteps[action.jobStepKey].fields, action.fieldKey],
				},
			}),
		};
	}

	if (action.type === CREATE_JOB_STEP) {
		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStep.key]: action.jobStep,
			}),
			animationsQueue: [...state.animationsQueue, action.jobStep.key],
		};
	}

	if (action.type === UPDATE_JOB_STEP) {
		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					...action.changes,
				},
			}),
		};
	}

	if (action.type === LIVE_SET_JOB) {
		if (state.jobs[action.job.key] !== undefined) {
			return state;
		}

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.job.key]: action.job,
			}),
		};
	}

	if (action.type === LIVE_JOB_UPDATED) {
		if (!state.jobs[action.job.key]) {
			return state;
		}

		const loaded = state.jobs[action.job.key].loaded;

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.job.key]: {
					...state.jobs[action.job.key],
					...action.job,
					// the action.job overrides loaded=true
					loaded,
				},
			}),
		};
	}

	if (action.type === LIVE_UPDATE_JOB_STEP) {
		if (!state.jobs[action.jobStep.jobKey]) {
			return state;
		}

		if (!state.jobs[action.jobStep.jobKey].loaded) {
			return state;
		}

		const { steps, userGeneratedContent } = normalizeJobStep([action.jobStep]);

		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, steps),
			jobsContent: {
				...state.jobsContent,
				[action.jobKey]: userGeneratedContent,
			},
		};
	}

	if (action.type === LIVE_CREATE_JOB_STEP) {
		if (!state.jobs[action.jobStep.jobKey]) {
			return state;
		}

		if (!state.jobs[action.jobStep.jobKey].loaded) {
			return state;
		}

		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStep.key]: action.jobStep,
			}),
			animationsQueue: [...state.animationsQueue, action.jobStep.key],
		};
	}

	if (action.type === MANUALLY_RESEND_ACTION) {
		return {
			...state,
			jobsActions: Object.assign({}, state.jobsActions, {
				[action.jobActionKey]: {
					...state.jobsActions[action.jobActionKey],
					status: JobActionStatus.Processing,
				},
			}),
		};
	}

	if (action.type === CANCEL_SCHEDULED_RESEND) {
		let logs = [...state.jobsActions[action.jobActionKey].logs];

		logs[0].scheduledAt = null;

		return {
			...state,
			jobsActions: Object.assign({}, state.jobsActions, {
				[action.jobActionKey]: {
					...state.jobsActions[action.jobActionKey],
					logs,
				},
			}),
		};
	}

	if (action.type === CREATE_JOB_CONTENT_ITEM) {
		let nextState = {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					userGeneratedContent: [
						...state.jobsSteps[action.jobStepKey].userGeneratedContent,
						action.contentKey,
					],
				},
			}),
		};

		if (!nextState.jobsContent[action.jobKey]) {
			nextState.jobsContent = {
				...nextState.jobsContent,
				[action.jobKey]: {
					[action.contentKey]: action.content,
				},
			};

			return nextState;
		}

		nextState.jobsContent = Object.assign({}, state.jobsContent, {
			[action.jobKey]: {
				...state.jobsContent[action.jobKey],
				[action.contentKey]: action.content,
			},
		});

		return nextState;
	}

	if (action.type === UPDATE_JOB_CONTENT_ITEM) {
		return {
			...state,
			jobsContent: Object.assign({}, state.jobsContent, {
				[action.jobKey]: {
					...state.jobsContent[action.jobKey],
					[action.contentKey]: {
						...state.jobsContent[action.jobKey][action.contentKey],
						...action.changes,
					},
				},
			}),
		};
	}

	if (action.type === START_POLLING_FOR_WAITING_JOB_STEP) {
		return {
			...state,
			openJob: {
				...state.openJob,
				pollingTimeStart: action.startTime,
			},
		};
	}

	if (action.type === STOP_POLLING_FOR_WAITING_JOB_STEP) {
		return {
			...state,
			openJob: {
				...state.openJob,
				pollingTimeStart: 0,
				pollingCount: 0,
			},
		};
	}

	if (action.type === POLL_FOR_WAITING_JOB_STEP) {
		return {
			...state,
			openJob: {
				...state.openJob,
				pollingCount: state.openJob.pollingCount + 1,
			},
		};
	}

	if (action.type === RESET_JOB_STEP_POLLING_COUNT) {
		return {
			...state,
			openJob: {
				...state.openJob,
				pollingCount: 0,
				pollingTimeStart: 0,
			},
		};
	}

	if (action.type === DELETE_JOB_CONTENT_ITEM) {
		let jobsContent = { ...state.jobsContent };
		let jobsSteps = { ...state.jobsSteps };

		Object.keys(jobsSteps).forEach((jobStepKey) => {
			if (jobsSteps[jobStepKey].userGeneratedContent.includes(action.key)) {
				jobsSteps[jobStepKey].userGeneratedContent = jobsSteps[
					jobStepKey
				].userGeneratedContent.filter((contentKey) => contentKey != action.key);
			}
		});

		delete jobsContent[action.jobKey][action.key];

		return {
			...state,
			jobsContent: Object.assign({}, jobsContent),
			jobsSteps: Object.assign({}, jobsSteps),
		};
	}

	if (action.type === DELETE_JOB) {
		const jobKey = action.jobKey;

		let jobs = { ...state.jobs };
		delete jobs[jobKey];

		let jobLogs = { ...state.jobLogs };
		delete jobLogs[jobKey];

		let jobsFields = { ...state.jobsFields };
		delete jobsFields[jobKey];

		let jobsSteps = { ...state.jobsSteps };
		Object.keys(jobsSteps).forEach((jobStepKey: string) => {
			if (jobsSteps[jobStepKey].jobKey === jobKey) {
				delete jobsSteps[jobStepKey];
			}
		});

		let jobsContent = { ...state.jobsContent };
		delete jobsContent[jobKey];

		let jobsActions = { ...state.jobsActions };
		Object.keys(jobsActions).forEach((jobActionKey: string) => {
			if (jobsActions[jobActionKey].jobKey === jobKey) {
				delete jobsActions[jobActionKey];
			}
		});

		let jobTableViews = { ...state.jobTableViews };
		Object.keys(jobTableViews).forEach((viewKey) => {
			jobTableViews[viewKey].jobKeys = jobTableViews[viewKey].jobKeys.filter(
				(jobKeyForView) => jobKey !== jobKeyForView
			);
		});

		return {
			...state,
			jobs,
			jobLogs,
			jobsFields,
			jobsSteps,
			jobsActions,
			jobsContent,
			jobTableViews,
		};
	}

	if (action.type === UPDATE_JOB_STEP_ASSIGNEES) {
		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					assignment: {
						...state.jobsSteps[action.jobStepKey].assignment,
						assignees: action.assignees.map((assignee: PersonAssignmentOption) => assignee.key),
					},
				},
			}),
		};
	}

	if (action.type === SET_NEXT_JOB_STEP_KEY) {
		return {
			...state,
			openJob: {
				...state.openJob,
				nextJobStepKey: action.nextJobStepKey,
			},
		};
	}

	if (action.type === SET_JOB_STEP_INSTRUCTIONS) {
		return {
			...state,
			openJob: {
				...state.openJob,
				nextInstructions: action.instruction,
			},
		};
	}

	if (action.type === CREATE_JOB_COMMENT) {
		let jobsComments = { ...state.jobsComments };

		jobsComments[action.comment.jobKey] = Object.assign({}, jobsComments[action.comment.jobKey], {
			[action.comment.key]: convertSingleCommentToRichText(action.comment),
		});

		return {
			...state,
			jobsComments,
		};
	}

	if (action.type === DELETE_JOB_COMMENT) {
		let jobsComments = { ...state.jobsComments };

		delete jobsComments[action.jobKey][action.commentKey];

		let jobLogs = { ...state.jobLogs };
		jobLogs[action.jobKey] = jobLogs[action.jobKey].filter(
			(element) => element.elementKey !== action.commentKey
		);

		return {
			...state,
			jobsComments,
			jobLogs,
		};
	}

	if (action.type === UPDATE_OPEN_JOB_KB_SETTINGS) {
		return {
			...state,
			openJob: {
				...state.openJob,
				knowledgeBase: {
					...state.openJob.knowledgeBase,
					...action.changes,
				},
			},
		};
	}

	if (action.type === LIVE_JOB_STATUS_UPDATED) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		return {
			...state,
			jobs: {
				...state.jobs,
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					status: action.status,
				},
			},
		};
	}

	if (action.type === LIVE_CREATE_JOB_ACTION) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		if (!state.jobs[action.jobKey].loaded) {
			return state;
		}

		return {
			...state,
			jobsActions: Object.assign({}, state.jobsActions, {
				[action.jobAction.key]: action.jobAction,
			}),
		};
	}

	if (action.type === LIVE_CREATE_JOB_DELAY) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		if (!state.jobs[action.jobKey].loaded) {
			return state;
		}

		return {
			...state,
			jobsDelays: Object.assign({}, state.jobsDelays, {
				[action.jobDelay.key]: action.jobDelay,
			}),
		};
	}

	if (action.type === LIVE_APPEND_JOB_LOG_ENTRY) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		if (!state.jobs[action.jobKey].loaded) {
			return state;
		}

		if (!state.jobLogs[action.jobKey]) {
			return {
				...state,
				jobLogs: Object.assign({}, state.jobLogs, {
					[action.jobKey]: [action.jobLogItem],
				}),
			};
		}

		const eventsCount = state.jobLogs[action.jobKey].length;
		const lastEvent = state.jobLogs[action.jobKey][eventsCount - 1];
		const newEvent: LogItem = action.jobLogItem;

		if (
			newEvent.timestamp === lastEvent.timestamp &&
			newEvent.event === lastEvent.event &&
			newEvent.elementKey === lastEvent.elementKey
		) {
			// Sometimes the real-time causes a duplicate
			return state;
		}

		let nextState = {
			...state,
			jobLogs: Object.assign({}, state.jobLogs, {
				[action.jobKey]: [...state.jobLogs[action.jobKey], action.jobLogItem],
			}),
		};

		if (newEvent.event === "step_completed") {
			nextState.animationsQueue = [...state.animationsQueue, newEvent.elementKey];
		}

		return nextState;
	}

	// jobKey, key, name, elementType (step or workflow)
	if (action.type === LIVE_ADD_JOB_ACTIVE_ELEMENT) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		const index: keyof Job = action.elementType === "step" ? "activeSteps" : "activeWorkflows";

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					[index]: [
						...state.jobs[action.jobKey][index],
						{
							key: action.key,
							name: action.name,
						},
					],
				},
			}),
		};
	}

	// jobKey, key, elementType (step or workflow)
	if (action.type === LIVE_REMOVE_JOB_ACTIVE_ELEMENT) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		const index: keyof Job = action.elementType === "step" ? "activeSteps" : "activeWorkflows";

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					[index]: [
						...state.jobs[action.jobKey][index].filter((element) => element.key !== action.key),
					],
				},
			}),
		};
	}

	if (action.type === LIVE_REMOVE_ALL_JOB_ACTIVE_ELEMENTS) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					activeSteps: [],
					activeWorkflows: [],
				},
			}),
		};
	}

	if (action.type === LIVE_CLOSE_JOB) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					completedAt: action.completedAt,
				},
			}),
		};
	}

	if (action.type === LIVE_JOB_TIMESTAMP_UPDATED) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		return {
			...state,
			jobs: Object.assign({}, state.jobs, {
				[action.jobKey]: {
					...state.jobs[action.jobKey],
					updatedAt: action.updatedAt,
				},
			}),
		};
	}

	if (action.type === LIVE_UPDATE_JOB_ACTION) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		if (!state.jobsActions[action.jobActionKey]) {
			return state;
		}

		let jobAutomationVariables = buildNewJobAutomationVariables(action.variables, {
			...state.jobsAutomationVariables[action.jobKey],
		});

		return {
			...state,
			jobsActions: Object.assign({}, state.jobsActions, {
				[action.jobActionKey]: {
					...state.jobsActions[action.jobActionKey],
					status: action.status,
					logs: [action.log, ...state.jobsActions[action.jobActionKey].logs],
				},
			}),
			jobsAutomationVariables: {
				...state.jobsAutomationVariables,
				[action.jobKey]: jobAutomationVariables,
			},
		};
	}

	if (action.type === LIVE_REFRESH_JOB_VIEWS) {
		// we have a jobKey and an array of viewKeys that it should apply to
		const viewKeys: Array<string> = action.viewKeys;
		const jobKey: string = action.jobKey;

		// Add this regardless if the job has been loaded, on the job list
		// we filter out any that aren't loaded. This way we can always
		// have an up to date list of jobs because sometimes this is run
		// before a new job is added and then it doesn't show up

		// loop through each view and remove/add
		let views = { ...state.jobTableViews };

		Object.keys(views).forEach((viewKey) => {
			const view = views[viewKey];

			if (viewKeys.includes(viewKey)) {
				// it should be there
				if (!view.jobKeys.includes(jobKey)) {
					views[viewKey].jobKeys.push(jobKey);
				}
			} else {
				// it shouldnt be there
				if (view.jobKeys.includes(jobKey)) {
					views[viewKey].jobKeys = views[viewKey].jobKeys.filter((key) => key !== jobKey);
				}
			}
		});

		return {
			...state,
			jobTableViews: Object.assign({}, views),
		};
	}

	if (action.type === LIVE_JOB_STEP_ASSIGNEES_UPDATED) {
		if (!state.jobsSteps[action.jobStepKey]) {
			return state;
		}

		return {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					assignment: {
						...state.jobsSteps[action.jobStepKey].assignment,
						assignees: action.assignees,
					},
				},
			}),
		};
	}

	if (action.type === LIVE_JOB_FIELDS_UPDATED) {
		return {
			...state,
			jobsFields: {
				...state.jobsFields,
				[action.jobKey]: {
					...state.jobsFields[action.jobKey],
					...action.jobFields,
				},
			},
		};
	}

	if (action.type === LIVE_USER_COMPLETED_JOB_STEP) {
		if (
			(state.me as Me).key !== action.userKey &&
			state.jobs[action.jobKey] !== undefined &&
			state.jobsSteps[action.jobStepKey] === undefined
		) {
			// Scenario where the job is shown on a loaded view, but not opened.
			// only update the fields because they could appear on the view
			return {
				...state,
				jobsFields: {
					...state.jobsFields,
					[action.jobKey]: {
						...state.jobsFields[action.jobKey],
						...action.fields,
					},
				},
			};
		}

		if (!state.jobsSteps[action.jobStepKey]) {
			return state;
		}

		let completers = [...(state.jobsSteps[action.jobStepKey].completedBy as Array<any>)];
		const hasCompleter = completers.some((completer) => completer.userKey === action.userKey);

		if (hasCompleter) {
			return state;
		}

		completers.push({
			userKey: action.userKey,
			completedAt: action.timestamp,
		});

		let nextState = {
			...state,
			jobsSteps: Object.assign({}, state.jobsSteps, {
				[action.jobStepKey]: {
					...state.jobsSteps[action.jobStepKey],
					completedBy: completers,
					assignment: {
						...state.jobsSteps[action.jobStepKey].assignment,
						assignees: state.jobsSteps[action.jobStepKey].assignment.assignees.filter(
							(key: string) => key !== action.userKey
						),
					},
				},
			}),
		};

		// The person working on the job could have newer field data than what we
		// have on the server. The field autosave feature only triggers every 5 seconds.
		// Which means that the data stored in the DB could be up to 5 seconds stale.
		//
		// This is fine for updating the data for other people's views, but it would cause the
		// user working on the job to appear to have data erased when it updates to previous value.
		if ((state.me as Me).key !== action.userKey) {
			nextState.jobsFields = {
				...state.jobsFields,
				[action.jobKey]: {
					...state.jobsFields[action.jobKey],
					...action.fields,
				},
			};
		}

		return nextState;
	}

	if (action.type === LIVE_JOB_COMMENT_ADDED) {
		if (!state.jobs[action.jobKey]) {
			return state;
		}

		if (!state.jobs[action.jobKey].loaded) {
			return state;
		}

		let jobsComments = { ...state.jobsComments };

		jobsComments[action.jobKey] = Object.assign({}, jobsComments[action.jobKey], {
			[action.comment.key]: convertSingleCommentToRichText(action.comment),
		});

		return {
			...state,
			jobsComments,
		};
	}

	return state;
}
