import { combineEpics, Epic, ofType } from "redux-observable";
import {
	CLEANUP_OPEN_JOB,
	CREATE_JOB_CONTENT_ITEM,
	CREATE_JOB_STEP,
	DELETE_JOB_UPLOADED_ASSET,
	LIVE_APPEND_JOB_LOG_ENTRY,
	LIVE_CLOSE_JOB,
	LIVE_CREATE_JOB_ACTION,
	LIVE_CREATE_JOB_STEP,
	LIVE_JOB_UPDATED,
	POLL_FOR_WAITING_JOB_STEP,
	PREFILL_JOB_FIELD,
	RECEIVE_COMPLETE_STEP_RESPONSE,
	RELEASE_ANIMATIONS,
	RESET_JOB_STEP_POLLING_COUNT,
	SET_ELEMENT_BLUEPRINTS,
	SET_INCLUDE_KNOWLEDGE_BASE,
	START_MONITORING_FIELD_CHANGES,
	START_UPLOAD_JOB_MEDIA,
	STOP_MONITORING_FIELD_CHANGES,
	UPDATE_JOB_CONTENT_ITEM,
} from "../../actionTypes";
import {
	catchError,
	delay,
	delayWhen,
	ignoreElements,
	map,
	mergeMap,
	switchMap,
	takeUntil,
	tap,
} from "rxjs/operators";
import { AnyAction } from "redux";
import { EMPTY, from, interval, of } from "rxjs";
import { call, get } from "../../utils/api";
import { apiRequestFailed } from "../../components/Account/accountActions";
import { JobAPI } from "../JobAPI";
import { AxiosResponse } from "axios";
import { InitialState } from "../../initialState";
import { queueRequestToAPI, sendRequestToAPI, uploadFile } from "../../sharedActions";
import { navigate } from "@reach/router";
import { onOpenJobStepPage } from "../../utils/helpers";
import {
	clearUnsavedFields,
	pollForWaitingJobStep,
	setJob,
	setJobStep,
	setNextJobStepInstruction,
	setNextJobStepKey,
	setStepBlueprint,
	stopMonitoringFieldChanges,
	updateOpenJobKbSettings,
} from "../../components/Jobs/jobActions";
import { calculateAnimationReleaseKeys } from "../../components/Jobs/utils";
import { JobContentType } from "design-system";

const prefillJobField: Epic = (action$) => {
	return action$.pipe(
		ofType(PREFILL_JOB_FIELD),
		mergeMap((action: AnyAction) => {
			const request = JobAPI.prefillJobField(action.jobKey, action.fields);

			return from(call(request.url, "post", request.data)).pipe(
				catchError((error) => of(apiRequestFailed(error)))
			);
		}),
		ignoreElements()
	);
};

const jobCompletedResponse: Epic = (action$) => {
	return action$.pipe(
		ofType(RECEIVE_COMPLETE_STEP_RESPONSE),
		mergeMap((action) => {
			if (action.hasOwnProperty("nextJobStepKey") && action.hasOwnProperty("jobKey")) {
				console.log(`redirecting to ${action.nextJobStepKey}`);
				// should probably get the current job step key and delete it locally

				setTimeout(() => {
					navigate(`/jobs/${action.jobKey}/open/${action.nextJobStepKey}`, { replace: true });
				}, 500);
			}

			return EMPTY;
		})
	);
};

const updateJobContentItem: Epic = (action$) => {
	return action$.pipe(
		ofType(UPDATE_JOB_CONTENT_ITEM, CREATE_JOB_CONTENT_ITEM),
		mergeMap((action: AnyAction) => {
			if (action.contentType === JobContentType.Task) {
				let completedAt = null;

				if (action.type === UPDATE_JOB_CONTENT_ITEM) {
					completedAt = action.changes.completedAt;
				}
				if (action.type === CREATE_JOB_CONTENT_ITEM) {
					completedAt = action.content.completedAt;
				}

				const request = JobAPI.updateJobStepTask(
					action.jobKey,
					action.jobStepKey,
					action.contentKey,
					completedAt
				);

				return from(call(request.url, "post", request.data)).pipe(
					catchError((error) => of(apiRequestFailed(error)))
				);
			}

			return EMPTY;
		}),
		ignoreElements()
	);
};

const prefetchNextElements: Epic = (action$) => {
	return action$.pipe(
		ofType(LIVE_CREATE_JOB_STEP),
		mergeMap((action: AnyAction) => {
			if (!onOpenJobStepPage(action.jobStep.key)) {
				return EMPTY;
			}

			const request = JobAPI.prefetchNextElements(action.jobStep.jobKey, action.jobStep.key);

			return from(get(request.url)).pipe(
				map((response: AxiosResponse) => ({ type: SET_ELEMENT_BLUEPRINTS, ...response.data.data })),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const loadActionBlueprint: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(LIVE_CREATE_JOB_ACTION),
		mergeMap((action: AnyAction) => {
			const state = state$.value as InitialState;

			if (state.actions[action.jobAction.action.key] !== undefined) {
				return EMPTY;
			}

			const request = JobAPI.loadActionBlueprint(action.jobAction.action.key);

			return from(get(request.url)).pipe(
				map((response: AxiosResponse) => ({ type: request.respondWith, ...response.data.data })),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const uploadJobMedia: Epic = (action$, state$) => {
	// should I split these into different requests?
	return action$.pipe(
		ofType(START_UPLOAD_JOB_MEDIA),
		mergeMap((action) =>
			of(
				uploadFile({
					url: `jobs/${action.jobKey}/media`,
					data: {
						formData: action.formData,
						key: action.item.key,
					},
					respondWith: UPDATE_JOB_CONTENT_ITEM,
				})
				// sendRequestToAPI(JobAPI.addMediaToStep(action.jobKey, action.jobStepKey, action.item.key))
			)
		)
	);
};

const deleteJobUploadedAsset: Epic = (action$) => {
	return action$.pipe(
		ofType(DELETE_JOB_UPLOADED_ASSET),
		mergeMap((action: AnyAction) => {
			return [
				{
					type: "SEND_REQUEST_TO_API",
					url: `jobs/${action.jobKey}/media/${action.key}`,
					data: {},
					method: "delete",
				},
			];
		})
	);
};

const navigateUponJobAbruptlyClosed: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(LIVE_CLOSE_JOB),
		tap((action) => {
			const state = state$.value as InitialState;

			// check that the user hasn't navigated away while waiting for the next step to load
			if (
				window.location.pathname.includes(`/jobs/${action.jobKey}/open`) &&
				state.openJob.jobStepKeyWaiting !== null
			) {
				// The job was abruptly closed, navigate to the job overview page
				navigate(`/jobs/${action.jobKey}`);
			}
		}),
		// todo need to return action to end the loading next step state
		mergeMap(() => {
			return EMPTY;
		})
	);
};

const pollForWaitingJobStepEpic: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(POLL_FOR_WAITING_JOB_STEP),
		delayWhen(() => {
			const state = state$.value as InitialState;
			const pollingCount = state.openJob.pollingCount;

			if (pollingCount === 0) {
				return interval(0);
			}

			if (pollingCount <= 5) {
				return interval(500);
			}

			if (pollingCount > 5 && pollingCount <= 10) {
				return interval(1000);
			}

			if (pollingCount > 10 && pollingCount <= 20) {
				return interval(2000);
			}

			return interval(3000);
		}),
		mergeMap((action: AnyAction) => {
			const state = state$.value as InitialState;
			const request = JobAPI.pollForWaitingJobStep(action.jobKey);

			return from(get(request.url)).pipe(
				mergeMap((response: AxiosResponse) => {
					const startTime = state.openJob.pollingTimeStart;
					const duration = Date.now() - startTime;

					if (state.openJob.pollingTimeStart === 0) {
						// forced stop, probably because of an error
						return EMPTY;
					}

					if (duration >= 60000 && response.data.status === "wait") {
						// todo should show the user a failed message
						return EMPTY;
					}

					if (response.data.status === "wait") {
						return of(pollForWaitingJobStep(action.jobKey));
					}

					let nextActions = [];

					nextActions.push({ type: RESET_JOB_STEP_POLLING_COUNT });

					if (response.data.data.hasOwnProperty("step")) {
						// @ts-ignore
						nextActions.push(
							setStepBlueprint(response.data.data.step, response.data.data.formFields)
						);
						nextActions.push(
							setJobStep(
								response.data.data.jobKey,
								response.data.data.jobStep,
								response.data.data.variables,
								response.data.data.content.fields
							)
						);
						nextActions.push(setNextJobStepKey(response.data.data.jobStep.key));
					}

					return [...nextActions, setNextJobStepInstruction(response.data.data.instruction)];
				}),
				catchError((error) => of(apiRequestFailed(error)))
			);
		})
	);
};

const releaseAnimations: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(CREATE_JOB_STEP, LIVE_CREATE_JOB_STEP, LIVE_APPEND_JOB_LOG_ENTRY),
		delay(500),
		map((action) => {
			const keys = calculateAnimationReleaseKeys(action);

			return {
				type: RELEASE_ANIMATIONS,
				keys,
			};
		})
	);
};

const loadKnowledgeBase: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(SET_INCLUDE_KNOWLEDGE_BASE),
		mergeMap((action) => {
			if (action.include === false) {
				return EMPTY;
			}

			// return action that will flip the switch on the include property
			return [updateOpenJobKbSettings({ include: true })];
		})
	);
};

const watchForNotLoadedJobs: Epic = (action$, state$) => {
	// scenario:
	// user A starts a job and proceeds to a step assigned to a specific role
	// user B doesn't have permission to view all jobs, so they don't see the job user A created
	// until it's assigned to them, then they start to receive real-time updates about the job,
	// but because the store hasn't loaded anything about the job, we ignore those updates.
	return action$.pipe(
		ofType(LIVE_JOB_UPDATED),
		mergeMap((action: AnyAction) => {
			const state = state$.value as InitialState;

			if (state.jobs[action.job.key] !== undefined) {
				return EMPTY;
			}

			return of(setJob(action.job));
		})
	);
};

const monitorJobStepFieldChanges: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(START_MONITORING_FIELD_CHANGES),
		switchMap(() =>
			interval(5000).pipe(
				mergeMap((time) => {
					const state = state$.value as InitialState;

					const urlParts = window.location.pathname.split("/");
					if (urlParts.length < 5 && urlParts[1] !== "jobs" && urlParts[3] !== "open") {
						return EMPTY;
					}

					const jobKey = urlParts[2];

					if (Object.keys(state.openJob.unsavedFields).length === 0) {
						return EMPTY;
					}

					if (Object.keys(state.openJob.unsavedFields).includes(jobKey) === false) {
						return EMPTY;
					}

					let actions = [clearUnsavedFields()];

					Object.keys(state.openJob.unsavedFields[jobKey]).forEach((jobStepKey) => {
						const unsavedFieldKeys = state.openJob.unsavedFields[jobKey][jobStepKey];

						let formFields: Array<{ fieldKey: string; fieldValue: string }> = [];

						unsavedFieldKeys.forEach((fieldKey) => {
							if (
								state.jobsFields.hasOwnProperty(jobKey) &&
								state.jobsFields[jobKey].hasOwnProperty(fieldKey)
							) {
								formFields.push({
									fieldKey,
									fieldValue: state.jobsFields[jobKey][fieldKey],
								});
							}
						});

						actions.push(queueRequestToAPI(JobAPI.saveStepForm(jobKey, jobStepKey, formFields)));
					});

					return actions;
				}),
				takeUntil(action$.ofType(STOP_MONITORING_FIELD_CHANGES))
			)
		)
	);
};

const cleanupOpenJobSideEffects: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(CLEANUP_OPEN_JOB),
		map((action) => stopMonitoringFieldChanges())
	);
};

export default combineEpics(
	jobCompletedResponse,
	pollForWaitingJobStepEpic,
	prefetchNextElements,
	loadActionBlueprint,
	uploadJobMedia,
	monitorJobStepFieldChanges,
	navigateUponJobAbruptlyClosed,
	updateJobContentItem,
	releaseAnimations,
	prefillJobField,
	watchForNotLoadedJobs,
	loadKnowledgeBase,
	deleteJobUploadedAsset,
	cleanupOpenJobSideEffects
);
