import { HOST_NAME } from "./constants";
import axios, { Method } from "axios";

export function determineEnv() {
	if (window.location.host.includes(".dev")) {
		return "dev";
	}

	if (window.location.host.includes("stage")) {
		return "stage";
	}

	return "prod";
}

// export function getCookieByName(name: string) {
// 	const cookies = Object.fromEntries(
// 		document.cookie.split(/; */).map((c) => {
// 			const [key, ...v] = c.split("=");
// 			return [key, decodeURIComponent(v.join("="))];
// 		})
// 	);
//
// 	if (!cookies.hasOwnProperty(name)) {
// 		return null;
// 	}
//
// 	// example us1
// 	return cookies[name];
// }

export function determineURL(options?: Options): string {
	let ZONE = window.localStorage.getItem("account_zone");

	if (options && options.zone) {
		ZONE = options.zone;
	}

	const env = determineEnv();
	const TLD = env === "dev" ? "dev" : "com";
	const PREFIX = env === "stage" ? "staging-api" : "api";

	let URL = `${PREFIX}.${HOST_NAME}.${TLD}`;

	if (ZONE) {
		URL = `${ZONE}.${URL}`;
	}

	return `https://${URL}`;
}

interface Options {
	zone?: string;
	sendAsJson?: boolean;
}

function calculatePayloadDepth(data: any) {
	if (typeof data !== "object") {
		return 1;
	}

	let level = 1;
	for (let key in data) {
		if (!data.hasOwnProperty(key)) continue;

		if (typeof data[key] == "object") {
			const depth = calculatePayloadDepth(data[key]) + 1;
			level = Math.max(depth, level);
		}
	}
	return level;
}

function formatPayload(method: Method, data: any) {
	if (method !== "post") {
		return data;
	}

	// sending POST requests with content-type application/x-www-form-urlencoded makes it
	// so that we don't have to send an OPTIONS request and are less likely to deal with
	// race conditions (particularly in jobs) if an OPTIONS request lags

	// if we use a library like qs to stringify the data into key value pairs, then
	// empty arrays and objects are completely dropped from the payload. Instead convert it
	// to a json string and decode on the server.
	return `data=${encodeURIComponent(JSON.stringify(data))}`;
}

export async function call(uri = "", method: Method = "post", data: any = null, options: Options = {}) {
	const url = determineURL(options);

	let headers: { [key: string]: any } = {};

	return await axios({
		method: method,
		url: `${url}/${uri.charAt(0) === "/" ? uri.substr(1) : uri}`,
		data: formatPayload(method, data),
		headers,
		withCredentials: true,
	}).catch((error) => {
		throw error;
		// if (redirectableStatuses.includes(error.response.status)) {
		// 	checkForCommonFailures(error.response, '/');
		// } else {
		// 	throw error;
		// }
	});
}

export async function post(uri = "", data = {}, options: Options = {}) {
	return call(uri, "post", data, options);
}

export async function remove(uri = "", options: Options = {}) {
	return call(uri, "delete", {}, options);
}

// replace with axios
export async function upload(uri = "", data: FormData, options: Options = {}) {
	const url = determineURL(options);

	// slice off the / if it was included
	uri = uri.charAt(0) === "/" ? uri.substr(1) : uri;

	const response = await fetch(`${url}/${uri}`, {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "same-origin",
		headers: {
			Accept: "application/json",
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: data,
	});

	let body = await response.json();
	body.ok = response.ok;

	return body;
}

export async function get(uri: string, options?: Options) {
	let headers: { [key: string]: any } = {};

	const url = determineURL(options);

	uri = uri.charAt(0) === "/" ? uri.substr(1) : uri;

	return await axios({
		method: "get",
		url: `${url}/${uri}`,
		headers,
		withCredentials: true,
	}).catch((error) => {
		throw error;
		// if (redirectableStatuses.includes(error.response.status)) {
		// 	checkForCommonFailures(error.response, '/');
		// } else {
		// 	throw error;
		// }
	});
}
